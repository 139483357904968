import { ILookupModel } from "../interfaces/ILookup";
import { IOrganizationBaseModel } from "../interfaces/IOrganization";

export enum SignalRMethods {
    SendAlertNotificationUpdates = 'SendAlertNotificationUpdates',
    SendChatMessage = 'SendChatMessage',
    SendCollabReportNotification = 'SendCollabReportNotification',
    SendOpenChatActStatus = 'SendOpenChatActStatus',
    SendQueryExportNotification = 'SendQueryExportNotification',
    SendReportNotification = 'SendReportNotification',
    ZoneChanged = 'ZoneChanged',
}

export interface IEntityConversationMessageModel {
    entityType: ILookupModel;
    entityGuid: string;
    actionTargets: string[];
    message: string;
    timestampUTC: string;
    authorGuid: string;
    authorName: string;
    organization: IOrganizationBaseModel;
}

export interface IOpenChatActModel {
    entityGuid: string;
    loggedInUserHasOpenChatAct: boolean;
}