import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { INoteCategoryTypeModel } from '../../interfaces/INote';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';

interface IDialogProps {
    isShowingModal: boolean;
    handleClose: () => void;
    noteCategoryTypes: INoteCategoryTypeModel[];
}

export class NoteCategoryAdditionalInfoDetailDialog extends React.Component<IDialogProps, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Modal
                centered
                show={this.props.isShowingModal}
                onHide={this.props.handleClose}
                backdrop={false}
            >
                <Modal.Header>
                    <Modal.Title>Note Categories - Additional Information Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {this.props.noteCategoryTypes
                            .filter(
                                (x) =>
                                    (x.assertionDescriptors && x.assertionDescriptors.length > 0) ||
                                    (x.subCategories && x.subCategories.length > 0)
                            )
                            .map((noteCategory: INoteCategoryTypeModel, i: number) => {
                                return (
                                    <li key={i} style={{ padding: '10px' }}>
                                        <label className="control-label">
                                            Category = {noteCategory.displayName!}
                                        </label>
                                        <ul>
                                            {noteCategory.assertionDescriptors &&
                                            noteCategory.assertionDescriptors.length > 0
                                                ? noteCategory.assertionDescriptors!.map(
                                                      (
                                                          ad: IAssertionDescriptorModel,
                                                          j: number
                                                      ) => {
                                                          return (
                                                              <li
                                                                  key={j}
                                                                  style={{
                                                                      paddingTop: '5px',
                                                                      paddingLeft: '15px',
                                                                  }}
                                                              >
                                                                  {ad.displayName!}
                                                              </li>
                                                          );
                                                      }
                                                  )
                                                : null}
                                            {noteCategory.subCategories &&
                                            noteCategory.subCategories.length > 0 ? (
                                                <li
                                                    style={{
                                                        paddingTop: '5px',
                                                        paddingLeft: '15px',
                                                    }}
                                                >
                                                    Sub Category
                                                </li>
                                            ) : null}
                                        </ul>
                                    </li>
                                );
                            })}
                    </ul>
                    <div className="dialog-btn-div margin-top-sm">
                        <button
                            className="btn btn-black float-end"
                            onClick={this.props.handleClose}
                        >
                            OK
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
