import * as React from 'react';

export class AccessDenied extends React.Component<any, any> {
    public render() {
        return (
            <div>
                <h1>Access Denied</h1>
                <hr />
                <p>
                    You have attempted to access a page that you are not authorized to view. If you
                    have any questions, please contact your administrator at{' '}
                    <a href={'mailto:ltm@checkmate.legal'}>ltm@checkmate.legal</a>
                </p>
            </div>
        );
    }
}
