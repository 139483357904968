import { Modal } from 'react-bootstrap';

export interface IQueryTooManyRecordsModalProps {
    open: boolean;
    maxRecordCount: number;
    onHide: () => void;
}

export function QueryTooManyRecordsModal(props: IQueryTooManyRecordsModalProps) {
    return (
        <Modal centered show={props.open} onHide={props.onHide} backdrop={false}>
            <Modal.Header>
                <Modal.Title> Query Results </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <span>
                        Your query returned more than {props.maxRecordCount.toLocaleString('en-US')}{' '}
                        records. The request cannot be completed at this time. If you did not run
                        this query by error, please send an email with the following information to{' '}
                        <a href={'mailto:ltm@checkmate.legal'}>ltm@checkmate.legal</a> and we will
                        perform this query for you.{' '}
                    </span>
                    <br />
                    <ul>
                        <li>- Purpose for the query</li>
                        <li>- When you need the results</li>
                        <li>- Criteria</li>
                        <li>- Display fields</li>
                    </ul>
                </div>
                <div className="dialog-btn-div margin-top-sm">
                    <button className="btn btn-default float-end" onClick={props.onHide}>
                        Close
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
