import * as React from 'react';

import Common from '../../stores/Common';
import { IViewDocumentModel } from '../../interfaces/IDocument';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Stack } from 'react-bootstrap';

export interface IChildDocumentProps {
    canDelete: boolean;
    childDocument: IViewDocumentModel;
    documentGuid: string;
    onDelete: (document: IViewDocumentModel, isChild: boolean) => void;
    onDownload: (event: React.FormEvent<HTMLButtonElement>, document: IViewDocumentModel) => void;
}

export function ChildDocument(props: IChildDocumentProps) {
    const { childDocument } = props;

    return (
        <div style={{ background: '#f3f3f3', padding: '1em', marginBottom: '0.5em' }}>
            <Stack direction="horizontal" gap={5}>
                <div style={{ width: 300 }}>
                    <Link
                        target="_blank"
                        to={LocalRoutes.WeeklyRecap.replace(':guid', props.documentGuid)}
                    >
                        {childDocument.filename}
                    </Link>
                </div>
                <div style={{ width: 115 }}>{Common.dateTimeFormat(childDocument.createdDate)}</div>
                <div style={{ width: 50, textAlign: 'center' }}>
                    {childDocument.isRead && <>Yes</>}
                </div>
                <div style={{ width: 70 }}>{childDocument.type}</div>
                <div
                    className="text-nowrap"
                    style={{
                        display: 'flex',
                        gap: 5,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ width: 20, textAlign: 'center', cursor: 'pointer' }}>
                        <i
                            className="fal fa-lg fa-file-download"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                props.onDownload(e, childDocument)
                            }
                        />
                    </div>
                    {props.canDelete && (
                        <div style={{ width: 20, textAlign: 'center', cursor: 'pointer' }}>
                            <i
                                className="fal fa-lg fa-trash-alt"
                                onClick={() => props.onDelete(childDocument, true)}
                            />
                        </div>
                    )}
                </div>
            </Stack>
        </div>
    );
}
