import { ApplicationHelpType } from '../../../../utilities/Constants';
import CheckmateSelect from '../../../shared/CheckmateSelect';
import { Help } from '../../../shared/Help';
import { IQueryFilterCriteria } from '../../../../interfaces/IQuery';
import { IValidation } from '../../../../interfaces/IError';

export interface ITriggerFieldsProps {
    onTriggerFieldSelected: (optionsList: any) => void;
    availableTriggerFields: IQueryFilterCriteria[];
    selectedTriggerFields: IQueryFilterCriteria[];
    validation: IValidation;
}

export function TriggerFields(props: ITriggerFieldsProps) {
    return <TriggerFieldsSelector {...props} />;
}

function TriggerFieldsSelector(props: ITriggerFieldsProps) {
    return (
        <div className="row mb-2">
            <div className="col-sm-2">
                <label className="text-gray text-lg">Trigger Field*</label>&nbsp;
                <Help
                    type={ApplicationHelpType.Info}
                    title="Trigger Field"
                    helpText="Need info text from Lori"
                />
            </div>
            <div className="col-sm-10">
                <CheckmateSelect
                    isMulti
                    options={[...props.availableTriggerFields]}
                    value={props.selectedTriggerFields}
                    onChange={props.onTriggerFieldSelected}
                />
                <span className="text-danger">{props.validation.criteria}</span>
            </div>
        </div>
    );
}
