import * as React from 'react';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IReportTypeModel } from '../../interfaces/Report/IReport';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import ValidateUtils from '../../shared/validations';
import { MyDocumentsTypesEnum } from '../../utilities/Constants';

const _apiClient = new ApiClient();

interface IReportListHomeState {
    reportTypes: IReportTypeModel[];
    validation: IValidation;
}

export class ReportListHome extends React.Component<any, IReportListHomeState> {
    constructor(props: any) {
        super(props);

        this.state = {
            reportTypes: [],
            validation: {},
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.ReportTypeList, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadReportTypes();
    }

    loadReportTypes = async () => {
        const response = await _apiClient.getReportTypes();

        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        // AR - Temp fix to remove 2 report types from the Report Tool in R2.0.0. Will be revisted very soon (Possibly 2.1.0)
        const reportTypes: IReportTypeModel[] =  response.payload ? response.payload.filter(x => x.id != MyDocumentsTypesEnum.LocalCounselMeetingAgendaReport.Value 
             && x.id !=MyDocumentsTypesEnum.PackageSettlementDetail.Value) : [];

        if (response.payload) {
            this.setState({ reportTypes: reportTypes });
        }
    };

    render() {
        return (
            <div>
                <DocumentTitle title="Report Tool"></DocumentTitle>
                <h1>
                    Report Tool
                </h1>
                <span className="text-danger">{this.state.validation.model}</span>
                <br />
                <ul>
                    {this.state.reportTypes.map((item: IReportTypeModel) => {
                        return (
                            <li key={item.id}>
                                <Link
                                    to={
                                        item.clientRouteUrl ||
                                        LocalRoutes.ReportDetail.replace(':id', item.id.toString())
                                    }
                                >
                                    <span className="btn btn-no-bg text-gray">
                                        <i
                                            className={
                                                item.name == 'PackageSettlements'
                                                    ? 'fal fa-box-usd'
                                                    : 'fal fa-file-chart-column'
                                            }
                                        />
                                        &nbsp;{item.displayName}
                                    </span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
