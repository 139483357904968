import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';
import { EntityTypes } from '../../utilities/Constants';
const _apiClient = new ApiClient();


export const useGetChildDocuments = (reportGuid: string, enabled: boolean) => {
    return useQuery({
        queryKey: ['get-child-documents', { reportGuid }],
        queryFn: async () => {
            const response = await _apiClient.getCollabReportChildren(reportGuid, EntityTypes.Report);

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve Child Documents.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        enabled
    });
};
