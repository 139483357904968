import * as React from 'react';

import Auth from '../../../../stores/authentication';
import Authorization from '../../../../stores/Authorization';
import { IOrganizationRoleModel } from '../../../../interfaces/IUser';
import { useAuthContext } from '../../../../contexts/AuthContext';

export interface IZoneSwitchMenuItemProps {
    onZoneChange: (org: IOrganizationRoleModel, redirectPath?: string) => void;
}

export function ZoneSwitchMenuItem(props: IZoneSwitchMenuItemProps) {
    const { user, isLoggedIn } = useAuthContext();

    const defaultOrg = Auth.getUserDefaultOrganization();
    const defaultOrganizationName = defaultOrg ? defaultOrg.name! : '';

    if (!Authorization.AllowOrganizationSwitch(user)) {
        return null;
    }

    return (
        <div className="zoneContainer">
            <button id="btn-zone" className=" btn-zone-select font-green" data-bs-toggle="dropdown">
                <span style={{ whiteSpace: 'nowrap' }}>
                    ZONE{' '}
                    <span className="horizontal-margin-lg font-green">
                        {' '}
                        {defaultOrganizationName}
                    </span>
                </span>
            </button>
            <div className="dropdown-menu no-margin-top">
                {isLoggedIn && (
                    <div>
                        {user?.organizationRoles?.map((org: IOrganizationRoleModel) => {
                            return (
                                <a
                                    key={org.guid}
                                    onClick={() => props.onZoneChange(org)}
                                    className="dropdown-item"
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {org.shortName} ({org.displayName})
                                </a>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
