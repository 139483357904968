import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import Auth from '../stores/authentication';
import CheckmateApiClient from '../services/apiClient';
import { IApiResponse } from '../tpi.apiclient';
import { ILoginResponse } from '../interfaces/ILoginResponse';
import { ILoginUser } from '../interfaces/IAccount';
import { IUserModel } from '../interfaces/IUser';
import { useLoading } from './LoadingContext';
import useLocalStorage from '../hooks/useLocalStorage';

interface AuthContextType {
    isInitialized: boolean;
    isLoadingUser: boolean;
    isLoggedIn: boolean;
    isLoggingIn: boolean;
    login: (loginUser: ILoginUser) => Promise<IApiResponse<ILoginResponse>>;
    logout: () => void;
    user: IUserModel;
}

const _apiClient = new CheckmateApiClient();

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [user, setUser] = useState<IUserModel>({ active: false });
    const [userId, setUserId, , removeUserId] = useLocalStorage('userId', '');

    const loading = useLoading();

    const login = async (loginUser: ILoginUser) => {
        setIsLoggingIn(true);
        loading.showLoading();
        const response = await _apiClient.login(loginUser);
        loading.hideLoading();
        setIsLoggingIn(false);

        if (!response.httpResponse.ok) {
            setIsLoggedIn(false);
            throw new Error(response.errorMessage);
        }

        const success = response.payload?.success ?? false;

        setIsLoggedIn(success);

        return await new Promise<IApiResponse<ILoginResponse>>((resolve) => {
            getLoggedInUser(success).then((user) => {
                if (user?.profile?.guid) {
                    setUserId(user?.profile?.guid);
                }
                resolve(response);
            });
        });
    };

    const logout = () => {
        setIsLoggedIn(false);
        setUser({ active: false });
        removeUserId();
    };

    const getLoggedInUser = async (run: boolean) => {
        if (!run) {
            return;
        }

        setIsLoadingUser(true);
        const response = await _apiClient.getLoggedInUserInfo();
        setIsLoadingUser(false);

        if (!response.httpResponse.ok) {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
            setIsInitialized(true);
            setUser(response.payload ?? { active: false });
            setUserId(response.payload?.profile?.guid ?? '');
        }

        return response.payload;
    };

    useEffect(() => {
        if (user.active) {
            const defaultOrg = user?.organizationRoles?.find((orgRole) => orgRole.default);

            if (defaultOrg) {
                Auth.setUserDefaultOrganization(defaultOrg.guid!, defaultOrg.shortName!);
            }
        }
    }, [user.active]);

    useEffect(() => {
        if (userId?.length ?? 0 > 0) {
            getLoggedInUser(true);
        } else {
            removeUserId();
            setIsInitialized(true);
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{ isInitialized, isLoadingUser, isLoggingIn, isLoggedIn, login, logout, user }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};

export { AuthProvider, useAuthContext };
