import React from 'react';

export interface IUniqueCaseIdInputProps {
    disabled?: boolean;
    inputName?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    validationError?: string;
}

export function UniqueCaseIdInput(props: IUniqueCaseIdInputProps) {
    return (
        <>
            <input
                className="form-control"
                type="text"
                placeholder="Enter at least 3 characters"
                name={props.inputName}
                disabled={props.disabled}
                value={props.value}
                onChange={props.onChange}
            />
            <span className="text-danger">{props.validationError}</span>
        </>
    );
}
