import { CheckmateDialog } from '../components/shared/dialog';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning';

export function UnsavedChangesBlocker({ when }: { when: boolean }) {
    useUnsavedChangesWarning(when);
    const blocker = useBlocker(when);

    return (
        <CheckmateDialog
            isShowingModal={blocker.state === 'blocked'}
            title={'Unsaved Changes Detected'}
            body={'You have unsaved changes. Are you sure you want to proceed?'}
            handleClose={() => {
                if (typeof blocker.reset === 'function') blocker.reset();
            }}
            handleConfirm={() => {
                if (typeof blocker.proceed === 'function') blocker.proceed();
            }}
            cancelText={'Cancel'}
            confirmText={'Continue'}
            closeButtonClassName="btn btn-default float-end"
            confirmButtonClassName="btn btn-black float-end"
            size="sm"
        />
    );
}
