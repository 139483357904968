import { useParams } from 'react-router-dom';
import { ViewExportReport } from '../../components/report/ViewExportReport';
import { IUserModel } from '../../interfaces/IUser';

function ViewExportReportWrapper(props: { user: IUserModel }) {
    const { id } = useParams();
    return <ViewExportReport id={parseInt(id || '')} user={props.user} />;
}

export default ViewExportReportWrapper;
