import {
    AssertionDescriptorDisplayValueTypeEnum,
    AssertionDescriptorValueSourceTypeEnum,
} from '../../utilities/Constants';
import Common, { EMPTY_VALUE } from '../../stores/Common';
import { ICaseAssertionModel, ICaseModel } from '../../interfaces/ICase';

import CaseFileCheckmateSelectHelper from '../../utilities/CaseFileCheckmateSelectHelper';
import CaseHelper from '../../utilities/CaseHelper';
import CheckmateNSelect from '../shared/CheckmateNSelect';
import CheckmateSelect from '../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { FieldControlWrapper } from './FieldControlWrapper';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IDisplayFieldModel } from '../../interfaces/IDisplaySectionModel';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { ReactElement } from 'react';
import { useCaseFileContext } from '../../contexts/CaseFileContext';

interface IAssertionDescriptorFieldRendererProps {
    displayAsCondensed: boolean;
    field: IDisplayFieldModel;
    tabIndex?: number;
}

export function AssertionDescriptorFieldRenderer({
    displayAsCondensed,
    field,
    tabIndex,
}: IAssertionDescriptorFieldRendererProps) {
    const {
        assertionDescriptors,
        currentCase,
        isReadOnlyMode,
        handleChangeADTextValue,
        handleAssertionDescriptorSearchableSingleSelectChange,
        handleAssertionDescriptorSearchableMultiSelectChange,
        setCurrentNarrativeCaseAssertion,
    } = useCaseFileContext();

    const foundAd = assertionDescriptors.find((ad) => ad.guid === field.assertionDescriptorGuid);
    let additionalStyleClass = '';

    if (foundAd) {
        const matchInCurrentCase = currentCase.caseAssertions?.find(
            (x) => x.assertionDescriptor?.guid === field.assertionDescriptorGuid
        );
        const newCA: ICaseAssertionModel = matchInCurrentCase ?? { assertionDescriptor: foundAd };

        const setStyleAsSystemAutoPopulated = CaseHelper.setStyleAsSystemAutoPopulated(
            newCA,
            currentCase
        );
        additionalStyleClass = setStyleAsSystemAutoPopulated ? ' font-green' : '';
    }

    const fieldInfo = assertionDescriptors.find((ad) => ad.guid === field.assertionDescriptorGuid);

    const caseData = currentCase.caseAssertions?.filter(
        (assertion) => assertion.assertionDescriptor?.guid === field.assertionDescriptorGuid
    );

    const firstCaseData = caseData?.[0];

    let component: ReactElement | null;

    if (!fieldInfo) {
        component = <span style={{ fontWeight: '600', color: 'red' }}>AD Not Found</span>;
    } else if (isReadOnlyMode) {
        if (
            fieldInfo?.valueSourceType?.id ===
            AssertionDescriptorValueSourceTypeEnum.Selection.Value
        ) {
            let label: string | undefined = '';
            if (
                fieldInfo.valueDisplayType?.id ===
                AssertionDescriptorDisplayValueTypeEnum.MultiSelect.Value
            ) {
                label = CaseFileCheckmateSelectHelper.getCheckmateNSelectValue(caseData ?? [])
                    ?.map((data) => data.label)
                    .join(' | ');
            } else {
                label = CaseFileCheckmateSelectHelper.getCheckmateSelectValue(
                    firstCaseData ?? {}
                )?.label;
            }

            component = label ? (
                <span className={additionalStyleClass}>{label}</span>
            ) : (
                <EmptyLabel />
            );
        } else {
            component = firstCaseData?.text ? (
                <span className={additionalStyleClass}>
                    {getFormattedText(firstCaseData?.text, firstCaseData)}
                </span>
            ) : (
                <EmptyLabel />
            );
        }
    } else {
        component = getEditControlForCaseProfileAssertionDescriptors(
            currentCase,
            caseData ?? [],
            fieldInfo,
            handleChangeADTextValue,
            handleAssertionDescriptorSearchableSingleSelectChange,
            handleAssertionDescriptorSearchableMultiSelectChange,
            (ca: ICaseAssertionModel) => setCurrentNarrativeCaseAssertion(ca),
            tabIndex
        ) ?? <></>;
    }

    return (
        <FieldControlWrapper
            component={component}
            displayAsCondensed={displayAsCondensed && isReadOnlyMode}
            labelText={fieldInfo?.displayName || fieldInfo?.name || ''}
            identifier={field.assertionDescriptorGuid}
            help={
                fieldInfo?.helpText
                    ? { text: fieldInfo?.helpText, title: fieldInfo.displayName || '' }
                    : undefined
            }
        />
    );
}

const getFormattedText = (text: string | undefined, caseAssertion: ICaseAssertionModel) => {
    if (!text) return '';

    if (
        caseAssertion.assertionDescriptor?.valueDisplayType?.id ==
        AssertionDescriptorDisplayValueTypeEnum.Currency.Value
    ) {
        return Common.formatCurrency(text);
    }

    if (
        caseAssertion.assertionDescriptor?.valueSourceType?.id ===
        AssertionDescriptorValueSourceTypeEnum.Boolean.Value
    ) {
        if (text) {
            if (text.toUpperCase() === 'TRUE') return 'Yes';
            else if (text.toUpperCase() === 'FALSE') return 'No';
        }
    }

    return text || '';
};

const getEditControlForCaseProfileAssertionDescriptors = (
    currentCase: ICaseModel,
    caseAssertions: ICaseAssertionModel[],
    ad: IAssertionDescriptorModel,
    onChangeADTextValue: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void,
    onSearchableSingleSelectChange: (
        selectedOption: IMultiSelectOptions,
        identifier: string,
        isBoolean?: boolean
    ) => void,
    onSearchableMultiSelectChange: (
        selectedOptions: IMultiSelectOptions[],
        identifier: string
    ) => void,
    onSelectNarrativeCaseAssertion: (ca: ICaseAssertionModel) => void,
    tabIndex?: number
) => {
    const firstCA =
        caseAssertions && (caseAssertions.length ?? 0) > 0
            ? caseAssertions[0]
            : { assertionDescriptor: ad };

    const setStyleAsSystemAutoPopulated = CaseHelper.setStyleAsSystemAutoPopulated(
        firstCA,
        currentCase
    );

    const additionalStyleClass = setStyleAsSystemAutoPopulated ? ' font-green' : '';

    const { valueSourceType, valueDisplayType } = ad;

    const handleNarrativeFieldEdit = (ca: ICaseAssertionModel) => {
        onSelectNarrativeCaseAssertion(ca ?? { assertionDescriptor: ad });
    };

    const singleCaseAssertion = caseAssertions[0];

    if (valueSourceType?.id === AssertionDescriptorValueSourceTypeEnum.Text.Value) {
        if (valueDisplayType?.id === AssertionDescriptorDisplayValueTypeEnum.Narrative.Value) {
            return (
                <button
                    title={singleCaseAssertion?.text}
                    className="btn btn-no-bg"
                    onClick={() => handleNarrativeFieldEdit(singleCaseAssertion)}
                    tabIndex={tabIndex}
                >
                    <i
                        className={
                            (singleCaseAssertion?.text?.length ?? 0) > 0
                                ? 'fal fa-edit color-black'
                                : 'fal fa-edit color-gray'
                        }
                    />
                </button>
            );
        }

        if (valueDisplayType?.id === AssertionDescriptorDisplayValueTypeEnum.DateTime.Value)
            return (
                <input
                    type="date"
                    name={ad.guid}
                    className={
                        'form-control' +
                        (singleCaseAssertion?.text && Common.isValidDate(singleCaseAssertion?.text)
                            ? ''
                            : ' unselectClass')
                    }
                    value={singleCaseAssertion?.text ?? ''}
                    onChange={onChangeADTextValue}
                    style={{ width: '100%' }}
                    tabIndex={tabIndex}
                />
            );

        if (valueDisplayType?.id === AssertionDescriptorDisplayValueTypeEnum.Percentage.Value)
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                        alignItems: 'center',
                    }}
                >
                    <span>%</span>
                    <input
                        type="number"
                        name={ad.guid}
                        className={'form-control' + additionalStyleClass}
                        value={singleCaseAssertion?.text ?? ''}
                        onChange={onChangeADTextValue}
                        style={{ width: 'auto' }}
                        tabIndex={tabIndex}
                    />
                </div>
            );

        if (
            valueDisplayType?.id === AssertionDescriptorDisplayValueTypeEnum.Currency.Value ||
            valueDisplayType?.id === AssertionDescriptorDisplayValueTypeEnum.Number.Value
        )
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                        alignItems: 'center',
                    }}
                >
                    {valueDisplayType?.id ===
                        AssertionDescriptorDisplayValueTypeEnum.Currency.Value && <span>$</span>}
                    <input
                        type="number"
                        name={ad.guid}
                        className={'form-control' + additionalStyleClass}
                        value={singleCaseAssertion?.text ?? ''}
                        onChange={onChangeADTextValue}
                        style={{ width: 'auto' }}
                        tabIndex={tabIndex}
                    />
                </div>
            );

        if (valueDisplayType?.id === AssertionDescriptorDisplayValueTypeEnum.ShortText.Value)
            return (
                <input
                    type="text"
                    name={ad.guid}
                    className={'form-control' + additionalStyleClass}
                    value={singleCaseAssertion?.text ?? ''}
                    onChange={onChangeADTextValue}
                    style={{ width: 'auto' }}
                    tabIndex={tabIndex}
                />
            );

        if (valueDisplayType?.id === AssertionDescriptorDisplayValueTypeEnum.SSN.Value)
            return (
                <input
                    style={{ width: '30%' }}
                    className={'form-control' + additionalStyleClass}
                    type="text"
                    placeholder="XXX-XX-XXXX"
                    maxLength={11}
                    name={ad.guid}
                    value={singleCaseAssertion?.text}
                    onChange={onChangeADTextValue}
                    tabIndex={tabIndex}
                />
            );
    }

    if (valueSourceType?.id === AssertionDescriptorValueSourceTypeEnum.Boolean.Value) {
        return (
            <div style={{ minWidth: 150, maxWidth: 150, width: '100%' }}>
                <CheckmateSelect
                    options={CheckmateSelectHelper.getBooleanOptions()}
                    systemPopulated={setStyleAsSystemAutoPopulated}
                    name={ad.guid!}
                    value={CheckmateSelectHelper.getBooleanValueFromString(
                        singleCaseAssertion?.text
                    )}
                    onChange={(e: any) => onSearchableSingleSelectChange(e, ad.guid!, true)}
                    tabIndex={tabIndex}
                />
            </div>
        );
    }

    if (valueSourceType?.id === AssertionDescriptorValueSourceTypeEnum.Selection.Value) {
        if (ad.valueDisplayType?.id === AssertionDescriptorDisplayValueTypeEnum.MultiSelect.Value) {
            return (
                <div style={{ minWidth: 250, maxWidth: 300, width: '100%' }}>
                    <CheckmateNSelect
                        options={CaseFileCheckmateSelectHelper.getOptionsForCheckmateSelect(
                            ad.assertionDescriptorValues!
                        )}
                        value={CaseFileCheckmateSelectHelper.getCheckmateNSelectValue(
                            caseAssertions
                        )}
                        onChange={(options: IMultiSelectOptions[]) =>
                            onSearchableMultiSelectChange(options, ad.guid!)
                        }
                    />
                </div>
            );
        } else {
            return (
                <div style={{ minWidth: 250, maxWidth: 300, width: '100%' }}>
                    <CheckmateSelect
                        options={CaseFileCheckmateSelectHelper.getOptionsForCheckmateSelect(
                            ad.assertionDescriptorValues!
                        )}
                        systemPopulated={setStyleAsSystemAutoPopulated}
                        name={ad.guid!}
                        value={CaseFileCheckmateSelectHelper.getCheckmateSelectValue(
                            singleCaseAssertion
                        )}
                        onChange={(options: any) =>
                            onSearchableSingleSelectChange(options, ad.guid!)
                        }
                        tabIndex={tabIndex}
                    />
                </div>
            );
        }
    }
};

function EmptyLabel() {
    return <span>{EMPTY_VALUE}</span>;
}
