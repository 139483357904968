import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { JurisdictionStrategyDetail } from '../strategy/JurisdictionStrategyDetail';
import { useParams } from 'react-router-dom';

function JurisdictionStrategyDetailWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return (
        <>
            <DocumentTitle title="Jurisdiction Detail" />
            <JurisdictionStrategyDetail guid={guid} user={props.user} />
        </>
    );
}

export default JurisdictionStrategyDetailWrapper;
