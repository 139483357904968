import { Button, Modal } from 'react-bootstrap';

import Common from '../../../../stores/Common';
import { useEffect } from 'react';
import { useGetReportHistory } from '../../../../shared/react-query-hooks/useGetReportHistory';

export interface IHistoryModalProps {
    onClose: () => void;
    open: boolean;
    reportGuid: string;
}

export function HistoryModal(props: IHistoryModalProps) {
    const { data, isError, isLoading, refetch } = useGetReportHistory(props.reportGuid);

    useEffect(() => {
        if (props.open) {
            refetch();
        }
    }, [props.open]);

    const hasErrors = !isLoading && isError;
    const hasRecords = data?.length ?? 0 > 0;

    return (
        <Modal centered size="lg" show={props.open} onHide={props.onClose} backdrop={false}>
            <Modal.Header>
                <Modal.Title>Report History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {hasErrors ? (
                    <div className="row">
                        <div className="text-danger">Failed to load Report History.</div>
                    </div>
                ) : hasRecords ? (
                    <table className="table mb-4">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>User</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item) => (
                                <tr key={item.user.guid + item.dateUTC}>
                                    <td>{Common.dateTimeFormatToLocal(item.dateUTC)}</td>
                                    <td>
                                        {item.user!.profile!.firstName +
                                            ' ' +
                                            item.user!.profile?.lastName}
                                    </td>
                                    <td>{item.details}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>No history exists for this report.</div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={props.onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
