import ApiClient from '../../../services/apiClient';
import { IEntityConversationCreateModel } from '../../../interfaces/Report/IReport';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useCreateEntityConversationMutation = (onError?: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (newConvo: IEntityConversationCreateModel) => {
            const response = await _apiClient.createEntityConversation(newConvo);

            if (!response?.httpResponse.ok) {
                if (response.errorMessage && response.httpResponse.status == 400) {
                    if (response.errors !== undefined && response.errors[0] !== undefined) {
                        throw new Error(
                            `Failed to create Conversation : ${response.errors[0].message}`
                        );
                    }
                }
                throw new Error('Failed to create Conversation');
            }
            return response.payload;
        },
        onError(error) {
            if (error instanceof Error && typeof onError === 'function') {
                onError(error.message);
            }
        },
    });
