import { ICaseAssertionModel, ICaseModel } from '../../../interfaces/ICase';

import CaseHelper from '../../../utilities/CaseHelper';
import { IAssertionDescriptorModel } from '../../../interfaces/IAssertionDescriptor';
import { IValidation } from '../../../interfaces/IError';
import { Modal } from 'react-bootstrap';
import { ReactNode } from 'react';

export interface IAdditionalFieldsDialogProps {
    assertionDescriptors: IAssertionDescriptorModel[];
    currentCase: ICaseModel;
    getSingleValueEditControl: (caseAssertion: ICaseAssertionModel) => ReactNode;
    onCancelClick: () => void;
    onHide: () => void;
    onOkClick: () => void;
    open: boolean;
    validation: IValidation;
}

export function AdditionalFieldsDialog(props: IAdditionalFieldsDialogProps) {
    return (
        <Modal centered show={props.open} onHide={props.onHide} backdrop={false}>
            <Modal.Header>
                <Modal.Title>Enter Additional Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BodyComponent {...props} />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-orange" onClick={props.onOkClick}>
                    OK
                </button>
                <button className="btn btn-default" onClick={props.onCancelClick}>
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    );
}

function BodyComponent(props: IAdditionalFieldsDialogProps) {
    return (
        <>
            <div>
                <span className="text-danger">{props.validation.AdditionalDates}</span>
            </div>
            {CaseHelper.getAdditionalCaseAssertionFields(
                props.currentCase,
                props.assertionDescriptors
            ).map((item: ICaseAssertionModel) => {
                return (
                    <div className="row mb-2" key={item.assertionDescriptor!.guid}>
                        <div className="col-sm-6">
                            <label>{item.assertionDescriptor!.displayName!}</label>
                        </div>
                        <div className="col-sm-6">{props.getSingleValueEditControl(item)}</div>
                    </div>
                );
            })}
        </>
    );
}
