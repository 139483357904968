/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from 'react';

import {
    AlertListTypeEnum,
    AlertTypesEnum,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    UserRightsEnum,
} from '../../../../utilities/Constants';
import {
    IAlertSummaryModel,
    IAlertUserUpdateModel,
    INoteModel,
} from '../../../../interfaces/INote';

import Auth from '../../../../stores/authentication';
import Authorization from '../../../../stores/Authorization';
import CheckmateApiClient from '../../../../services/apiClient';
import Common from '../../../../stores/Common';
import { HubConnection } from '@microsoft/signalr';
import { IUserModel } from '../../../../interfaces/IUser';
import { IUserTaskListModel } from '../../../../interfaces/ICase';
import { IValidation } from '../../../../interfaces/IError';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { SignalRMethods } from '../../../../utilities/SignalR';
import ValidateUtils from '../../../../shared/validations';
import { cloneDeep } from 'lodash';

const _apiClient = new CheckmateApiClient();

// todo jec: convert to function component and get rid of wrapper

interface ISideNavMenuProps {
    user?: IUserModel;
    isAuthenticated?: boolean;
    alertHubConnection: HubConnection | undefined;
    locationKey: string;
    setIsLocalSwitchZone: () => void;
    showReportCounts?: boolean;
}

interface ITaskBarState {
    showTriggerCount: boolean;
    userTasks: IUserTaskListModel[];
    userNotes: INoteModel[];
    userAlertSummary: IAlertSummaryModel[];
    validation: IValidation;
    pendingResponse: boolean;
}

export class SideNavMenu extends React.Component<ISideNavMenuProps, ITaskBarState> {
    constructor(props: ISideNavMenuProps) {
        super(props);

        const userHasTriggerRight = Authorization.userHasRight(
            UserRightsEnum.CaseTriggerRules,
            props.user ?? { active: false }
        );

        this.state = {
            showTriggerCount: userHasTriggerRight,
            userTasks: [],
            userNotes: [],
            userAlertSummary: [],
            validation: {},
            pendingResponse: false,
        };

        const handleSendAlertNotificationUpdates = (payload: IAlertUserUpdateModel) => {
            const userAlertSummaryCopy = cloneDeep(this.state.userAlertSummary);

            for (let index = 0; index < payload.alertUpdates.length; index++) {
                const orgUpdateElement = payload.alertUpdates[index];

                let matchInUserAlertSummary = userAlertSummaryCopy.find(
                    (x) => x.organization.guid === orgUpdateElement.organization.guid
                );

                if (!matchInUserAlertSummary) {
                    matchInUserAlertSummary = {
                        organization: orgUpdateElement.organization,
                        actCount: 0,
                        reportCount: 0,
                        triggerCount: 0,
                        watchCount: 0,
                    };
                    userAlertSummaryCopy.push(matchInUserAlertSummary);
                }

                if (matchInUserAlertSummary) {
                    for (let index = 0; index < orgUpdateElement.changeByType.length; index++) {
                        const changeByTypeElement = orgUpdateElement.changeByType[index];
                        switch (changeByTypeElement.type) {
                            case AlertTypesEnum.Act:
                                matchInUserAlertSummary.actCount += changeByTypeElement.change;
                                break;
                            case AlertTypesEnum.Watch:
                                matchInUserAlertSummary.watchCount += changeByTypeElement.change;
                                if (matchInUserAlertSummary.watchCount < 0)
                                    matchInUserAlertSummary.watchCount = 0;
                                break;
                            case AlertTypesEnum.CaseTrigger:
                                matchInUserAlertSummary.triggerCount += changeByTypeElement.change;
                                if (matchInUserAlertSummary.triggerCount < 0)
                                    matchInUserAlertSummary.triggerCount = 0;
                                break;
                            case AlertTypesEnum.Report:
                                matchInUserAlertSummary.reportCount += changeByTypeElement.change;
                                if (matchInUserAlertSummary.reportCount < 0)
                                    matchInUserAlertSummary.reportCount = 0;
                                break;
                            default:
                                break;
                        }
                    }
                }
            }

            this.setState({
                userAlertSummary: userAlertSummaryCopy.filter(
                    (x) => x.actCount || x.reportCount || x.triggerCount || x.watchCount
                ),
            });
        };

        props.alertHubConnection?.on(
            SignalRMethods.SendAlertNotificationUpdates,
            handleSendAlertNotificationUpdates
        );
    }

    componentDidMount() {
        if (this.props.isAuthenticated) {
            this.loadAlertBarInfo();
            this.loadTaskBarInfo();
            this.loadUserNoteInfo();
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
            this.loadAlertBarInfo();
            this.loadTaskBarInfo();
            this.loadUserNoteInfo();
        }
    }

    loadTaskBarInfo = async () => {
        this.setState({ pendingResponse: true });

        const response = await _apiClient.getTaskBarInfo();
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ userTasks: response.payload! });
    };

    loadAlertBarInfo = async () => {
        this.setState({ pendingResponse: true });
        const response = await _apiClient.getAlertBarInfo();
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ userAlertSummary: response.payload! });
    };

    loadUserNoteInfo = async () => {
        const response = await _apiClient.getNotes(NoteTypes.Personal);
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ userNotes: response.payload!, pendingResponse: false });
    };

    applyBackgroundColor = (deadlineDate?: string, mustStartByDate?: string) => {
        const today = new Date();
        if (deadlineDate) {
            const deadlineDateValue = new Date(deadlineDate);
            if (today > deadlineDateValue) {
                return 'square-orange';
            }
        }

        if (mustStartByDate) {
            const mustStartByDateValue = new Date(mustStartByDate);
            if (today >= mustStartByDateValue) {
                return 'square-yellow';
            }
        }

        return 'square-none';
    };

    addNote = () => {
        const notes = this.state.userNotes;
        const addNote: INoteModel = {
            status: {
                id: NoteStatusTypes.Open,
            },
            type: {
                id: NoteTypes.Personal,
            },
            purpose: { id: NotePurposeTypesEnum.Info },
        };
        notes.push(addNote);
        this.setState({ userNotes: notes });
    };

    closeNote = async (e: React.FormEvent<HTMLButtonElement>, guid?: string) => {
        e.preventDefault();
        this.setState({ pendingResponse: true });
        if (!guid) {
            // This means you are removing the item that was just added and not yet saved to the DB
            const notes = this.state.userNotes;
            for (let i = 0; i < notes.length; i++) {
                if (!notes[i].guid) {
                    notes.splice(i, 1);
                    this.setState({ userNotes: notes, pendingResponse: false });
                    return;
                }
            }
        }

        // Closing/ Completing an existing note.
        const match = this.state.userNotes.filter((x) => x.guid === guid);
        if (match.length > 0) {
            match[0].status = { id: NoteStatusTypes.Closed };
            const response = await _apiClient.saveNote(match[0]);
            if (response.httpResponse.status === 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                    pendingResponse: false,
                });
                return;
            }

            const userNotes = this.state.userNotes;
            const index = userNotes.findIndex((x) => x.guid === guid);
            if (index >= 0) {
                userNotes.splice(index, 1);
                this.setState({ userNotes: userNotes, pendingResponse: false });
            }
        }
    };

    saveNote = async () => {
        const match = this.state.userNotes.filter((x) => !x.guid);
        if (match.length > 0) {
            const response = await _apiClient.saveNote(match[0]);
            if (response.httpResponse.status === 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                    pendingResponse: false,
                });
                return;
            }

            if (response.payload) {
                const userNotes = this.state.userNotes;
                match[0].guid = response.payload.guid;
                match[0].modifiedDate = response.payload.modifiedDate;
                this.setState({ userNotes: userNotes, pendingResponse: false });
            }
        }
    };

    goTo = async (
        e: React.MouseEvent<HTMLButtonElement>,
        userTask: IUserTaskListModel,
        dest: string
    ) => {
        // Check if the row's ZoneGuid is same as User's default Zone Guid. If not update default zone for user
        const loggedInUserDefaultZone = Auth.getUserDefaultOrganization();
        if (loggedInUserDefaultZone) {
            if (loggedInUserDefaultZone.guid !== userTask.organization.guid) {
                // Call API to Save the switch. Then redirect to home page.
                const response = await _apiClient.setDefaultUserOrganization(
                    userTask?.organization?.guid ?? '',
                    userTask?.organization?.shortName ?? 'AR - TO DO - Somethings Wrong',
                    this.props.locationKey
                );
                if (response.httpResponse.status === 401) {
                    window.location.reload();
                    return;
                }
            }

            let urlToRedirect = '/';
            switch (dest) {
                case 'CaseFile':
                    urlToRedirect = LocalRoutes.CaseFile.replace(':guid?', userTask.caseGuid);
                    break;
                case 'TaskDetail':
                    urlToRedirect = LocalRoutes.CasePlanTaskDetail.replace(
                        ':caseguid',
                        userTask.caseGuid
                    )
                        .replace(':caseplanguid', userTask.casePlanGuid)
                        .replace(':taskguid', userTask.taskGuid);
                    break;
                default:
            }

            window.location.assign(urlToRedirect);
            return;
        }
    };

    onChangeTextValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const notes = this.state.userNotes;
        notes.filter((x) => !x.guid)[0].content = event.target.value;
        this.setState({ userNotes: notes });
    };

    goToAlertList = async (organizationGuid: string, organizationName: string) => {
        const defaultOrg = Auth.getUserDefaultOrganization();
        if (defaultOrg) {
            if (defaultOrg.guid !== organizationGuid) {
                const response = await _apiClient.setDefaultUserOrganization(
                    organizationGuid,
                    organizationName,
                    this.props.locationKey
                );
                if (response.httpResponse.status === 401) {
                    window.location.reload();
                    return;
                }
                if (!response.errorMessage) {
                    window.location.reload();
                }
            }

            window.location.assign(
                LocalRoutes.Alerts.replace(':type', AlertListTypeEnum.MyAlertList.toString())
            );
        }
    };

    onlySummariesWithDataFilter = (alert: IAlertSummaryModel) => {
        return (
            alert.actCount > 0 ||
            alert.reportCount > 0 ||
            alert.triggerCount > 0 ||
            alert.watchCount > 0
        );
    };

    render() {
        return (
            <div className="navbar pt-0">
                {!this.props.isAuthenticated ? null : (
                    <div
                        className="navbar-container"
                        style={{ justifyContent: 'space-between', paddingBottom: '0 !important' }}
                    >
                        <div style={{ minWidth: '100%' }}>
                            <div className="horizontal-padding-only">
                                <span className="bold">ALERTS</span>
                            </div>
                            <hr
                                style={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    opacity: 'inherit',
                                }}
                            ></hr>
                            <table className="alert-summary-table" style={{ minWidth: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className="color-yellow bold col-sm-4 padding-left-5 font-size-xs">
                                            ZONE
                                        </th>
                                        {this.state.showTriggerCount && (
                                            <th className="text-center color-orange bold col-sm-2 font-size-xs">
                                                <i className="fal fa-chevrons-right" />
                                            </th>
                                        )}
                                        {this.props.showReportCounts && (
                                            <th className="text-center color-yellow bold col-sm-2 font-size-xs">
                                                <i className="fal fa-file-chart-column" />
                                            </th>
                                        )}
                                        <th className="text-center color-yellow bold col-sm-2 font-size-xs">
                                            ACT
                                        </th>
                                        <th className="text-center color-yellow bold col-sm-2 font-size-xs">
                                            <i className="fal fa-glasses-alt" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.userAlertSummary
                                        .filter(this.onlySummariesWithDataFilter)
                                        .map((item: IAlertSummaryModel, index: number) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    onClick={(
                                                        e: React.MouseEvent<HTMLTableRowElement>
                                                    ) => {
                                                        this.props.setIsLocalSwitchZone();
                                                        this.goToAlertList(
                                                            item.organization.guid!,
                                                            item.organization!.name!
                                                        );
                                                    }}
                                                >
                                                    <td>
                                                        <span className="font-size-xs padding-left-5">
                                                            {item.organization?.shortName ??
                                                                item.organization?.name}
                                                        </span>
                                                    </td>
                                                    {this.state.showTriggerCount && (
                                                        <td className="text-center col-sm-2 font-size-xs">
                                                            {item.triggerCount > 0 &&
                                                                item.triggerCount}
                                                        </td>
                                                    )}
                                                    {this.props.showReportCounts && (
                                                        <td className="text-center col-sm-2 font-size-xs">
                                                            {item.reportCount > 0 &&
                                                                item.reportCount}
                                                        </td>
                                                    )}
                                                    <td className="text-center col-sm-2 font-size-xs">
                                                        {item.actCount > 0 && item.actCount}
                                                    </td>
                                                    <td className="text-center col-sm-2 font-size-xs">
                                                        {item.watchCount > 0 && item.watchCount}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div className="padding-left-5">
                                <span className="bold">TASKS</span>
                            </div>
                            <hr
                                style={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    opacity: 'inherit',
                                }}
                            ></hr>
                            {this.state.userTasks.filter((x) => x.priority).length > 0 ? (
                                <div>
                                    <span
                                        className="color-yellow bold"
                                        style={{ fontSize: '12px' }}
                                    >
                                        PRIORITY
                                    </span>
                                    &nbsp;
                                    <i className="fal fa-exclamation-circle color-yellow" />
                                    <table
                                        className="table vertical-margin-lg"
                                        style={{ fontSize: '12px' }}
                                    >
                                        <tbody>
                                            {this.state.userTasks
                                                .filter((x) => x.priority)
                                                .map((userTask: IUserTaskListModel) => {
                                                    return (
                                                        <tr key={userTask.taskGuid}>
                                                            <td width="10">
                                                                <div
                                                                    className={this.applyBackgroundColor(
                                                                        userTask.deadlineDate,
                                                                        userTask.mustStartByDate
                                                                    )}
                                                                />
                                                            </td>
                                                            <td width="100" className="hasTooltip">
                                                                <button
                                                                    className="btn btn-no-bg btn-link"
                                                                    style={{ padding: '0px' }}
                                                                    onClick={(
                                                                        e: React.MouseEvent<HTMLButtonElement>
                                                                    ) => {
                                                                        this.goTo(
                                                                            e,
                                                                            userTask,
                                                                            'TaskDetail'
                                                                        );
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: '12px' }}
                                                                    >
                                                                        {
                                                                            userTask.activityType!
                                                                                .displayName
                                                                        }
                                                                    </span>
                                                                </button>
                                                                <div>
                                                                    <ul>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Name:
                                                                            </span>{' '}
                                                                            {userTask.name}
                                                                        </li>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Status:
                                                                            </span>{' '}
                                                                            {userTask.status
                                                                                ? userTask.status
                                                                                      .displayName
                                                                                : ''}
                                                                        </li>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Due date:
                                                                            </span>{' '}
                                                                            {Common.dateFormat(
                                                                                userTask.deadlineDate
                                                                            )}
                                                                        </li>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Estimated hours:
                                                                            </span>{' '}
                                                                            {
                                                                                userTask.estimatedHours
                                                                            }
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td width="80" className="hasTooltip">
                                                                <button
                                                                    className="btn btn-no-bg btn-link"
                                                                    style={{ padding: '0px' }}
                                                                    onClick={(
                                                                        e: React.MouseEvent<HTMLButtonElement>
                                                                    ) => {
                                                                        this.goTo(
                                                                            e,
                                                                            userTask,
                                                                            'CaseFile'
                                                                        );
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: '12px' }}
                                                                    >
                                                                        {userTask.plaintiffLastName}
                                                                    </span>
                                                                </button>
                                                                <div>
                                                                    <ul>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Name:
                                                                            </span>{' '}
                                                                            {userTask.name}
                                                                        </li>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Status:
                                                                            </span>{' '}
                                                                            {userTask.status
                                                                                ? userTask.status
                                                                                      .displayName
                                                                                : ''}
                                                                        </li>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Due date:
                                                                            </span>{' '}
                                                                            {Common.dateFormat(
                                                                                userTask.deadlineDate
                                                                            )}
                                                                        </li>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Estimated hours:
                                                                            </span>{' '}
                                                                            {
                                                                                userTask.estimatedHours
                                                                            }
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td width="40">
                                                                {userTask.organization?.shortName ??
                                                                    'AR - TO DO - Something Wrong'}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                            {this.state.userTasks.filter((x) => !x.priority).length > 0 ? (
                                <div>
                                    <span
                                        className="color-yellow bold"
                                        style={{ fontSize: '12px' }}
                                    >
                                        MY SHORT LIST
                                    </span>
                                    <table
                                        className="table vertical-margin-lg"
                                        style={{ fontSize: '12px' }}
                                    >
                                        <tbody>
                                            {this.state.userTasks
                                                .filter((x) => !x.priority)
                                                .map((userTask: IUserTaskListModel) => {
                                                    return (
                                                        <tr key={userTask.taskGuid}>
                                                            <td width="10">
                                                                <div
                                                                    className={this.applyBackgroundColor(
                                                                        userTask.deadlineDate,
                                                                        userTask.mustStartByDate
                                                                    )}
                                                                />
                                                            </td>
                                                            <td width="100" className="hasTooltip">
                                                                <button
                                                                    className="btn btn-no-bg btn-link"
                                                                    style={{ padding: '0px' }}
                                                                    onClick={(
                                                                        e: React.MouseEvent<HTMLButtonElement>
                                                                    ) => {
                                                                        this.goTo(
                                                                            e,
                                                                            userTask,
                                                                            'TaskDetail'
                                                                        );
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: '12px' }}
                                                                    >
                                                                        {
                                                                            userTask.activityType!
                                                                                .displayName
                                                                        }
                                                                    </span>
                                                                </button>
                                                                <div>
                                                                    <ul>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Name:
                                                                            </span>{' '}
                                                                            {userTask.name}
                                                                        </li>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Status:
                                                                            </span>{' '}
                                                                            {userTask.status
                                                                                ? userTask.status
                                                                                      .displayName
                                                                                : ''}
                                                                        </li>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Due date:
                                                                            </span>{' '}
                                                                            {Common.dateFormat(
                                                                                userTask.deadlineDate
                                                                            )}
                                                                        </li>
                                                                        <li>
                                                                            <span className="bold">
                                                                                Estimated hours:
                                                                            </span>{' '}
                                                                            {
                                                                                userTask.estimatedHours
                                                                            }
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td width="80">
                                                                <button
                                                                    className="btn btn-no-bg btn-link"
                                                                    style={{ padding: '0px' }}
                                                                    onClick={(
                                                                        e: React.MouseEvent<HTMLButtonElement>
                                                                    ) => {
                                                                        this.goTo(
                                                                            e,
                                                                            userTask,
                                                                            'CaseFile'
                                                                        );
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: '12px' }}
                                                                    >
                                                                        {userTask.plaintiffLastName}
                                                                    </span>
                                                                </button>
                                                            </td>
                                                            <td width="40">
                                                                {userTask.organization?.shortName ??
                                                                    'AR - TO DO - Something Wrong'}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}

                            <table className="taskbar-table full-width">
                                <tbody>
                                    <tr>
                                        <td
                                            colSpan={2}
                                            className="color-yellow bold padding-left-5 font-size-xs"
                                        >
                                            PERSONAL
                                        </td>
                                        <td className="text-end">
                                            {this.state.userNotes.filter((x) => !x.guid).length ===
                                                0 && this.state.userNotes.length < 5 ? (
                                                <button
                                                    className="btn btn-no-bg float-end py-0"
                                                    onClick={this.addNote}
                                                >
                                                    <i className="fal fa-plus color-yellow" />
                                                </button>
                                            ) : null}
                                        </td>
                                    </tr>
                                    {this.state.userNotes.map(
                                        (userNote: INoteModel, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td width="20" className="padding-left-5">
                                                        <button
                                                            className="btn btn-no-bg"
                                                            style={{ padding: '0px' }}
                                                            onClick={(
                                                                e: React.MouseEvent<HTMLButtonElement>
                                                            ) => {
                                                                this.closeNote(e, userNote.guid);
                                                            }}
                                                        >
                                                            <i className="fal fa-check color-white" />
                                                        </button>
                                                    </td>
                                                    <td className="padding-left-5">
                                                        {userNote.guid ? (
                                                            <span className="font-size-xs">
                                                                {' '}
                                                                {userNote.content}
                                                            </span>
                                                        ) : (
                                                            <input
                                                                className="form-control full-width"
                                                                type="text"
                                                                value={userNote.content}
                                                                onChange={this.onChangeTextValue}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        {!userNote.guid &&
                                                        userNote.content &&
                                                        userNote.content.length > 0 ? (
                                                            <button
                                                                className="btn btn-no-bg"
                                                                style={{
                                                                    padding: '0px',
                                                                    paddingLeft: '10px',
                                                                }}
                                                                onClick={this.saveNote}
                                                            >
                                                                <i className="fal fa-plus font-green" />
                                                            </button>
                                                        ) : null}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Link to={LocalRoutes.Tutorials}>
                            <i className="fal fa-graduation-cap color-green" />
                            <span className="color-green padding-left-5">TUTORIALS</span>
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}
