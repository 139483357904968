import * as React from 'react';

import { DocumentTypesEnum, EntityTypes, UserRightsEnum } from '../../utilities/Constants';
import { ICaseModel, IDocumentModel } from '../../interfaces/ICase';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ILocalCounselStrategyDetailModel } from '../../interfaces/IStrategy';
import { ILookupModel } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { TextEditor } from '../../components/shared/TextEditor';
import ValidateUtils from '../../shared/validations';

let _apiClient = new ApiClient();

interface IWorkProductProps {
    guid?: string;
    entityguid: string;
    entitytypeid: string;
    documentguid: string;
    parentEntityJson?: string;
    redirectUrl?: string;
    user: IUserModel;
}

interface IWorkProductDetailsState {
    entityGuid: string;
    entityTypeId: number;
    documentGuid?: string;
    validation: IValidation;
    document: IDocumentModel;
    redirect: boolean;
    readOnly: boolean;
    pendingResponse: boolean;
    documentTemplates: ILookupModel[];
    selectedDocumentTemplateId: string;
    parentCase?: ICaseModel;
    parentLocalCounselStrategy?: ILocalCounselStrategyDetailModel;
    redirectUrl?: string;
}

export class WorkProductDetail extends React.Component<
    IWorkProductProps,
    IWorkProductDetailsState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            entityGuid: '',
            entityTypeId: 0,
            validation: {},
            document: {
                documentType: { id: DocumentTypesEnum.WorkProduct.Value },
            },
            redirect: false,
            readOnly: true,
            pendingResponse: true,
            documentTemplates: [],
            selectedDocumentTemplateId: '0',
        };
    }

    componentDidMount() {
        const entityGuid = this.props.entityguid;
        const documentGuid = this.props.documentguid;
        const entityTypeId = parseInt(this.props.entitytypeid);

        if (this.props.parentEntityJson) {
            switch (entityTypeId) {
                case EntityTypes.Case: {
                    const parentCase: ICaseModel = JSON.parse(this.props.parentEntityJson);
                    this.setState({
                        parentCase: parentCase,
                        parentLocalCounselStrategy: undefined,
                    });
                    break;
                }
                case EntityTypes.LocalCounselStrategy: {
                    const parentLocalCounselStrategy: ILocalCounselStrategyDetailModel = JSON.parse(
                        this.props.parentEntityJson
                    );
                    this.setState({
                        parentCase: undefined,
                        parentLocalCounselStrategy: parentLocalCounselStrategy,
                    });
                    break;
                }
                default:
            }
        }

        // TO DO - AUTH
        this.setState(
            {
                entityGuid: entityGuid,
                entityTypeId: entityTypeId,
                documentGuid: documentGuid,
                redirectUrl: this.props.redirectUrl,
            },
            this.loadInitialData
        );
    }

    loadInitialData = async () => {
        if (this.state.documentGuid) {
            this.loadEntityDocumentById(
                this.state.entityGuid,
                this.state.entityTypeId,
                this.state.documentGuid
            );
        } else {
            this.loadDocumentTemplates();
        }
    };

    loadEntityDocumentById = async (entityGuid: string, typeId: number, documentGuid: string) => {
        var response = await _apiClient.getEntityDocumentById(entityGuid, typeId, documentGuid);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        let document: IDocumentModel = response.payload!;
        document.fileName = document.fileName
            ? document.fileName.slice(0, document.fileName.length - 4)
            : '';
        this.setState({ document: document, pendingResponse: false });
    };

    saveDocument = async () => {
        let document = this.state.document;
        let validation = this.state.validation;

        let hasErrors = false;

        // client side validations
        if (!document.content) {
            validation.content = ['Content is required'];
            hasErrors = true;
        }

        if (!document.fileName) {
            validation.name = ['File Name is required'];
            hasErrors = true;
        } else {
            let expression = new RegExp('^[^\\/:"*?<>|]+$');
            if (!expression.test(document.fileName)) {
                validation.name = ['Name is not a valid file name'];
                hasErrors = true;
            }
        }

        if (!document.title) {
            validation.title = ['Title is required'];
            hasErrors = true;
        }

        if (hasErrors) {
            this.setState({ validation: validation });
            return;
        }

        var response = await _apiClient.saveEntityDocument(
            this.state.entityGuid,
            this.state.entityTypeId,
            document
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        // redirect back to list
        if (this.state.redirectUrl) window.location.assign(this.state.redirectUrl);
    };

    editWorkProduct = () => {
        this.setState({ readOnly: false });
    };

    cancelWorkProduct = () => {
        if (this.state.document.guid == undefined) {
            if (this.state.redirectUrl) window.location.assign(this.state.redirectUrl);
        } else {
            if (!this.state.readOnly) {
                window.location.reload();
                return;
            }
            this.setState({ redirect: true });
        }
    };

    changeTextEditor = (content: string) => {
        this.setChangedValue('content', content);
    };

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue(name: string, value: any) {
        let document = this.state.document;
        let selectedDocumentTemplateId = this.state.selectedDocumentTemplateId;
        let validation = this.state.validation;

        switch (name) {
            case 'fileName':
                document.fileName = value;
                validation.name = [document.fileName ? '' : 'File Name is required'];
                break;
            case 'wpTitle':
                document.title = value;
                validation.title = [document.title ? '' : 'Title is required'];
                break;
            case 'desc':
                document.description = value;
                validation.description = [''];
                break;
            case 'content':
                document.content = value;
                validation.content = [document.content ? '' : 'Content is required'];
                break;
            case 'documentTemplate':
                selectedDocumentTemplateId = value;
                validation.selectedDocumentTemplateId = [''];
                break;

            default:
                break;
        }
        this.setState({
            document: document,
            selectedDocumentTemplateId: selectedDocumentTemplateId,
        });
    }

    downloadWorkProduct = async () => {
        let documentGuid = this.state.document.guid ? this.state.document.guid : '';
        var xhr = new XMLHttpRequest();
        xhr.open(
            'GET',
            ApiRoutes.EntityDocumentDownload.replace('{id}', this.state.entityGuid).replace(
                '{documentid}',
                documentGuid
            ),
            true
        );
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/msword');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                var fileName = this.state.document.fileName
                    ? this.state.document.fileName
                    : 'Word Product.doc';
                var blob = xhr.response;

                var contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    let contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (var i = 0; i < contentDispositionItems.length; i++) {
                            let currentItem = contentDispositionItems[i].trim();
                            if (currentItem.includes('filename=')) {
                                var n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length
                                );
                                break;
                            }
                        }
                    }
                }
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                // TODO: display custom validation returned from the api call
                let validation = this.state.validation;
                validation.model = [DisplayMessages.DocumentDownloadError];
                this.setState({ validation: validation, pendingResponse: false });
            }
        };
        xhr.send();
    };

    printWorkProduct = () => {
        let content = this.state.document.content ? this.state.document.content : '';
        let htmlObject = document.getElementById('printWorkProduct');
        if (htmlObject) {
            htmlObject.innerHTML = content;
        }
        window.print();
    };

    loadDocumentTemplates = async () => {
        var response = await _apiClient.getDocumentTemplates();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        this.setState({
            documentTemplates: response.payload!,
            readOnly: false,
            pendingResponse: false,
        });
    };

    applyDocumentTemplate = async () => {
        let selectedDocumentTemplateId = this.state.selectedDocumentTemplateId;
        let validation = this.state.validation;
        let document = this.state.document;

        if (selectedDocumentTemplateId === '0') {
            validation.selectedDocumentTemplateId = ['Select a template before applying'];
            this.setState({ validation: validation });
            return;
        }

        var response = await _apiClient.getDocumentTemplateById(selectedDocumentTemplateId);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        document.content = response.payload!;
        this.setState({ document: document });
    };

    public render() {
        return (
            <div>
                <div className="container-fluid print-hide-display">
                    {this.state.pendingResponse ? <Loader /> : null}
                    <h1>
                        {this.state.parentCase ? (
                            <span>
                                {Authorization.userHasRight(
                                    UserRightsEnum.ViewThreatScore,
                                    this.props.user
                                ) ? (
                                    <span className="btn-green case-score-display">
                                        {this.state.parentCase.threatScore ? (
                                            this.state.parentCase.threatScore
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </span>
                                ) : null}
                                <Link
                                    to={LocalRoutes.CaseFile.replace(
                                        ':guid',
                                        this.state.parentCase.guid!
                                    )}
                                >
                                    {this.state.parentCase.caseName}&nbsp;
                                </Link>
                                {this.state.parentCase && this.state.parentCase.priority ? (
                                    <i className="fal fa-exclamation-circle color-yellow" />
                                ) : null}
                            </span>
                        ) : null}
                        {this.state.parentLocalCounselStrategy ? (
                            <span>
                                <Link
                                    to={LocalRoutes.LocalCounselStrategyDetail.replace(
                                        ':guid',
                                        this.state.parentLocalCounselStrategy.guid!
                                    )}
                                >
                                    {this.state.parentLocalCounselStrategy.name}&nbsp;
                                </Link>
                            </span>
                        ) : null}
                        {!this.state.readOnly ? (
                            <span>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.cancelWorkProduct}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-orange float-end text-gray horizontal-margin"
                                    onClick={this.saveDocument}
                                >
                                    Save
                                </button>
                            </span>
                        ) : null}
                        {this.state.readOnly ? (
                            <span>
                                {this.state.redirectUrl ? (
                                    <span className="btn btn-blue float-end">
                                        <Link
                                            to={{ pathname: this.state.redirectUrl }}
                                            style={{ color: 'white' }}
                                        >
                                            Back
                                        </Link>
                                    </span>
                                ) : null}
                                {this.state.document.guid ? (
                                    <button
                                        className="btn btn-no-bg float-end text-gray"
                                        onClick={this.editWorkProduct}
                                    >
                                        <i className="fal fa-pen" />
                                        &nbsp;Edit
                                    </button>
                                ) : null}
                                <button
                                    className="btn btn-no-bg float-end text-gray"
                                    onClick={this.printWorkProduct}
                                >
                                    <i className="fal fa-print" />
                                    &nbsp;Print
                                </button>
                                {this.state.document.guid ? (
                                    <button
                                        className="btn btn-no-bg float-end text-gray"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            this.downloadWorkProduct();
                                        }}
                                    >
                                        <i className="fal fa-file-download" />
                                        &nbsp;Download
                                    </button>
                                ) : null}
                            </span>
                        ) : null}
                    </h1>
                    <hr className="no-margin" />
                    <div>
                        <span className="text-danger">{this.state.validation.model}</span>
                    </div>
                    {this.state.readOnly == true ? (
                        <div>
                            <div className="form-group">
                                <span className="text-danger">{this.state.validation.model}</span>
                                <label className="control-label" htmlFor="name">
                                    Filename{' '}
                                    <span className="font-weight-normal">
                                        {this.state.document.fileName}
                                    </span>
                                </label>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="title">
                                    Title{' '}
                                    <span className="font-weight-normal">
                                        {this.state.document.title}
                                    </span>
                                </label>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="description">
                                    Description{' '}
                                    <span className="font-weight-normal">
                                        {this.state.document.description}
                                    </span>
                                </label>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="content">
                                    Content
                                </label>
                                <TextEditor
                                    value={this.state.document.content || ''}
                                    onChange={this.changeTextEditor}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    <label htmlFor="name">Filename*</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="fileName"
                                        value={this.state.document.fileName || ''}
                                        onChange={this.changeValue}
                                        style={{ display: 'inline-block' }}
                                    />
                                    <span>.doc</span>
                                    <span className="text-danger">
                                        {this.state.validation.name}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    <label htmlFor="wpTitle">Title*</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="wpTitle"
                                        value={this.state.document.title || ''}
                                        onChange={this.changeValue}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.title}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    <label htmlFor="desc">Description</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="desc"
                                        value={this.state.document.description || ''}
                                        onChange={this.changeValue}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.description}
                                    </span>
                                </div>
                            </div>

                            {this.state.document.guid == undefined &&
                            this.state.documentTemplates.length > 0 ? (
                                <div className="row py-1">
                                    <div className="col-sm-4">
                                        <label htmlFor="documentTemplate">Template</label>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="row no padding">
                                            <div className="col-sm-11">
                                                {/* <select required className="form-select" name="documentTemplate"
                                                    onChange={this.changeValue}
                                                    value={this.state.selectedDocumentTemplateId}>
                                                    <option value="">-- Select --</option>
                                                    {this.state.documentTemplates.map((template: ILookupModel) => {
                                                        return <option key={template.guid} value={template.guid}>{template.displayName}</option>;
                                                    })}
                                                </select> */}
                                            </div>
                                            <div className="col-sm-1">
                                                <button
                                                    className="btn btn-orange float-end text-gray"
                                                    onClick={this.applyDocumentTemplate}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                        <span className="text-danger">
                                            {this.state.validation.selectedDocumentTemplateId}
                                        </span>
                                    </div>
                                </div>
                            ) : null}

                            <div className="form-group">
                                <label className="control-label" htmlFor="content">
                                    Content*
                                </label>
                                <br />
                                <span className="text-danger">{this.state.validation.content}</span>
                                <TextEditor
                                    value={this.state.document.content || ''}
                                    onChange={this.changeTextEditor}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div id="printWorkProduct" className="print-display display-only-on-print"></div>
            </div>
        );
    }
}
