import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';

import { IValidation } from '../../interfaces/IError';
import ValidateUtils from '../../shared/validations';

import { IOrganizationModel, IOrganizationDependencyModel } from '../../interfaces/IOrganization';

import ApiClient from '../../services/apiClient';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { OrganizationDependencyTypesEnum } from '../../utilities/Constants';
import { IUserModel } from '../../interfaces/IUser';
import Authorization from '../../stores/Authorization';

let _apiClient = new ApiClient();

interface IOrganizationDetailProps {
    guid?: string;
    user: IUserModel;
}

interface IOrganizationDetailState {
    organization: IOrganizationModel;
    validation: IValidation;
    redirect: boolean;
}

export class OrganizationDetail extends React.Component<
    IOrganizationDetailProps,
    IOrganizationDetailState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            organization: {},
            validation: {},
            redirect: false,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.OrganizationDetail, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        if (this.props.guid) {
            this.loadOrganizationDetailsById(this.props.guid);
        }
    }

    loadOrganizationDetailsById = async (id: string) => {
        var response = await _apiClient.getOrganizationById(id);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        let organization = response.payload!;
        this.setState({
            organization: organization,
        });
    };

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        let organization = this.state.organization;
        let validation = this.state.validation;

        if (name == 'name') {
            organization.name = value;
            validation.name = [''];
            this.setState({
                organization: organization,
                validation: validation,
            });
        }
    };

    saveDetail = async () => {
        let organization = this.state.organization;

        // temporary fix to eliminate field validation error for primary associated organization
        organization.primaryAssociatedOrganization = undefined;
        if (organization.dependentOrganizations) {
            organization.dependentOrganizations.map(
                (dependentOrg: IOrganizationDependencyModel) => {
                    dependentOrg.primaryAssociatedOrganization = undefined;
                }
            );
        }

        var response = await _apiClient.updateOrganization(organization);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
        } else {
            this.setState({ redirect: true });
        }
    };

    cancelDetail = () => {
        this.setState({ redirect: true });
    };

    getSubscriptionTypeClass = (typeName: string): string => {
        switch (typeName) {
            case OrganizationDependencyTypesEnum.Master.DisplayName:
                return 'far fa-circle';
            case OrganizationDependencyTypesEnum.Associated.DisplayName:
                return 'far fa-adjust';
            case OrganizationDependencyTypesEnum.None.DisplayName:
                return 'far fa-circle';
            default:
                return 'far fa-dot-circle';
        }
    };

    render() {
        if (this.state.redirect) {
            return <Navigate to={LocalRoutes.Zones} />;
        }
        return (
            <>
                <div className="row mb-3">
                    <h1 className="col-sm-6">{this.state.organization.name}</h1>
                    <div className="col-sm-6 float-end">
                        <input
                            type="button"
                            className="btn btn-default float-end"
                            onClick={this.cancelDetail}
                            value="Cancel"
                        />
                        <input
                            type="button"
                            className="btn btn-orange float-end"
                            onClick={this.saveDetail}
                            value="Save"
                        />
                    </div>
                </div>

                <span className="text-danger">{this.state.validation.model}</span>
                <div className="row mb-2">
                    <label className="control-label col-sm-2">Name*</label>
                    <input
                        type="text"
                        name="name"
                        className="form-control col-sm-10"
                        value={this.state.organization.name}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">{this.state.validation.name}</span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2">Type</label>
                    <input
                        className="form-control col-sm-10"
                        type="text"
                        readOnly={true}
                        value={
                            this.state.organization.organizationType
                                ? this.state.organization.organizationType.displayName
                                : ''
                        }
                    />
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="clients">
                        Portfolio(s)
                    </label>
                    {this.state.organization.dependentOrganizations &&
                    this.state.organization.dependentOrganizations.length > 0 ? (
                        <table className="table ms-3">
                            <thead>
                                <tr>
                                    <th>Company</th>
                                    <th>Portfolio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.organization.dependentOrganizations.map(
                                    (organization: IOrganizationModel) => {
                                        return (
                                            <tr key={organization.guid}>
                                                <td className="col-lg-3">
                                                    {
                                                        organization.primaryAssociatedOrganization!
                                                            .name
                                                    }
                                                    &nbsp;
                                                    <i
                                                        className={`fa ${this.getSubscriptionTypeClass(
                                                            organization.primaryAssociatedOrganization!
                                                                .dependencyType!.name!
                                                        )}`}
                                                    />
                                                </td>
                                                <td className="col-lg-2">
                                                    <Link
                                                        to={LocalRoutes.ZoneDetail.replace(
                                                            ':guid',
                                                            organization.guid!
                                                        )}
                                                    >
                                                        {organization.name}
                                                    </Link>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <span>No portfolio exists!</span>
                    )}
                </div>
            </>
        );
    }
}
