import * as React from 'react';

import { DataScopesEnum, EntityTypes, NoteTypes } from '../../utilities/Constants';
import { INoteCategoryTypeModel, INoteModel } from '../../interfaces/INote';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../shared/dialog';
import CheckmateSelect from '../shared/CheckmateSelect';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { IEntityModel } from '../../interfaces/IAssertionDescriptor';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { NoteCategoryAdditionalInfoDetailDialog } from './NoteCategoryAdditionalInfoDetailDialog';
import NoteHelper from '../../utilities/NoteHelper';
import { NoteListTable } from './NoteListTable';
import Sort from '../../stores/Sort';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface INoteListProps {
    user: IUserModel;
    notes?: INoteModel[];
    noteCategoryTypes: INoteCategoryTypeModel[];
    handleAddNote?: () => void;
    handleViewEditNote: (noteGuidToEdit: string, readOnly?: boolean) => void;
    refreshParent: (notes: INoteModel[]) => void;
    title?: string;
    titleClassName?: string;
    // This is for a scenario where user has AddNote Right but not View Note right.
    // Added this initially due to original requirements for Local Counsel where user was able to add a note but not view notes.
    // Needed this because we use the NoteList component to create a new note using the green Add Note button.
    // Hence needed the ability to render the NoteList just with the green add button without the notes listed below.
    allowView: boolean;
    allowEdit: boolean;
    allowAddNew?: boolean;
    allowDelete?: boolean;
    showExpertsColumn?: boolean;
}

interface INoteListState {
    pendingResponse: boolean;
    validation: IValidation;
    notes: INoteModel[];
    showConfirmRemoveDialog: boolean;
    selectedNoteToDelete?: INoteModel;
    selectedNoteCategoryFilters?: IMultiSelectOptions[];
    displayNoteCategoryAdditionalInfo: boolean;
    loggedInUserDataScopeId: number;
}

export class NoteList extends React.Component<INoteListProps, INoteListState> {
    constructor(props: INoteListProps) {
        super(props);
        this.state = {
            pendingResponse: false,
            validation: {},
            showConfirmRemoveDialog: false,
            notes: [],
            selectedNoteCategoryFilters: [],
            displayNoteCategoryAdditionalInfo: false,
            loggedInUserDataScopeId: 0,
        };
    }

    componentDidMount() {
        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );
        if (this.props.notes) {
            this.setState({
                notes: this.props.notes,
                loggedInUserDataScopeId: loggedInUserDataScopeId,
            });
        }
    }

    componentDidUpdate(prevProps: INoteListProps) {
        if (prevProps.notes != this.props.notes) {
            this.setState({
                notes: this.props.notes || [],
            });
        }
    }

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let notes = this.state.notes;

        const caseExpertFilter = (assoc: IEntityModel) =>
            assoc.entityType.id === EntityTypes.CaseExpert;

        const hasExperts = (note: INoteModel) =>
            (note.associations?.filter(caseExpertFilter)?.length ?? 0) > 0;

        if (notes) {
            if (key === 'modifiedDate') {
                notes = notes.sort(Sort.compareDate(key, subKey, order));
            }
            if (key === 'experts') {
                notes = notes.sort((a, b) => {
                    const aHasExperts = hasExperts(a);
                    const bHasExperts = hasExperts(b);

                    if (order === 'asc') {
                        return aHasExperts === bHasExperts ? 0 : aHasExperts ? -1 : 1;
                    } else {
                        return aHasExperts === bHasExperts ? 0 : aHasExperts ? 1 : -1;
                    }
                });
            } else {
                notes = notes.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }

        this.props.refreshParent(notes);
        this.setState({ notes });
    };

    showConfirmRemoveDailog = (note: INoteModel) => {
        this.setState({ showConfirmRemoveDialog: true, selectedNoteToDelete: note });
    };

    clickCancel = () => {
        this.setState({
            showConfirmRemoveDialog: false,
        });
    };

    viewEditNote = async (note: INoteModel, readOnly?: boolean) => {
        this.props.handleViewEditNote(note.guid!, readOnly);
    };

    deleteNote = async () => {
        if (!this.state.selectedNoteToDelete) return;

        let key;
        if (this.state.selectedNoteToDelete.type!.id == NoteTypes.CaseNote) {
            key = this.state.selectedNoteToDelete.caseGuid;
        }

        const response = await _apiClient.deleteNote(this.state.selectedNoteToDelete!.guid!, key);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        const notes = this.state.notes.filter(
            (x) => x.guid !== this.state.selectedNoteToDelete!.guid!
        );
        this.props.refreshParent(notes);
        this.setState({ notes: notes, showConfirmRemoveDialog: false });
    };

    handleNoteCategoriesSelected = (optionsList: any) => {
        this.setState({ selectedNoteCategoryFilters: optionsList });
    };

    filterNotes = () => {
        if (
            this.state.selectedNoteCategoryFilters &&
            this.state.selectedNoteCategoryFilters.length > 0
        ) {
            const filteredNotes: INoteModel[] = [];
            const notes = this.props.notes;

            if (notes) {
                for (let i = 0; i < this.state.selectedNoteCategoryFilters.length; i++) {
                    const categoryTypeIdToMatch = this.state.selectedNoteCategoryFilters[i]!.id!;
                    for (let j = 0; j < notes.length; j++) {
                        const currentNote = notes[j];
                        if (
                            currentNote &&
                            currentNote.categories != undefined &&
                            currentNote.categories.find((x) => x.id == categoryTypeIdToMatch)
                        ) {
                            filteredNotes.push(currentNote);
                        }
                    }
                }
            }

            this.setState({ notes: filteredNotes });
        } else this.setState({ notes: this.props.notes || [] });
    };

    clearFilter = () => {
        if (this.props.notes)
            this.setState({ notes: this.props.notes, selectedNoteCategoryFilters: [] });
        else this.setState({ notes: [], selectedNoteCategoryFilters: [] });
    };

    handleAdd = () => {
        if (this.props.handleAddNote) this.props.handleAddNote();
    };

    exportNotes = async () => {
        this.setState({ pendingResponse: true });

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.ExportNotes, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({ pendingResponse: false });

                let fileName = 'Notes.xlsx';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                const validation = this.state.validation;
                validation.model = [DisplayMessages.UnexpectedError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        const parm = { notes: this.props.notes, searchModel: undefined };
        xhr.send(JSON.stringify(parm));
    };

    public render() {
        const hideCategoryInfoText = !this.props.noteCategoryTypes.some(
            (x) =>
                (x.assertionDescriptors && x.assertionDescriptors.length > 0) ||
                (x.subCategories && x.subCategories.length > 0)
        );

        return (
            <div className="container-fluid" style={{ minHeight: '300px' }}>
                <div className="row mb-3">
                    <h1 className={'col-sm-2 ' + this.props.titleClassName || ''}>
                        {this.props.title}
                    </h1>
                    <div className="col-sm-10">
                        {this.props.allowAddNew ? (
                            <button className="btn-no-bg float-end" onClick={this.handleAdd}>
                                {' '}
                                <span className="btn-green btn float-end btn-icon">
                                    <i className="fal fa-lg fa-plus color-white" />
                                </span>
                            </button>
                        ) : null}
                        <div className="col-sm-4 float-end">
                            {this.props.allowView ? (
                                <div className="case-search-div pe-1" id="caseNotesFilter">
                                    <CheckmateSelect
                                        isMulti={true}
                                        options={NoteHelper.getNoteCategoryTypeOptionsForMultiselect(
                                            this.props.noteCategoryTypes
                                        )}
                                        value={this.state.selectedNoteCategoryFilters}
                                        onChange={this.handleNoteCategoriesSelected}
                                    />
                                </div>
                            ) : null}
                            {this.props.allowView ? (
                                <div className="float-end" style={{ display: 'inline-block' }}>
                                    <button
                                        type="button"
                                        className="btn btn-gray btn-icon float-end"
                                        onClick={this.clearFilter}
                                    >
                                        <i className="fal fa-times color-white" />
                                    </button>
                                    <div style={{ display: 'inline-block' }} className="float-end">
                                        <button
                                            type="submit"
                                            className="btn btn-black btn-icon float-end "
                                            onClick={this.filterNotes}
                                        >
                                            <i className="fal fa-filter color-white" />
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {this.props.allowView && this.state.notes.length > 0 ? (
                            <button
                                className="btn btn-no-bg float-end text-gray"
                                onClick={this.exportNotes}
                            >
                                <i className="fal fa-file-export" />
                                &nbsp;Export
                            </button>
                        ) : null}
                    </div>
                </div>
                <div>
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>
                {this.props.allowView ? (
                    this.state.notes.length > 0 ? (
                        <NoteListTable
                            hideCategoryInfoText={hideCategoryInfoText}
                            notes={this.state.notes}
                            onShowConfirmRemoveDialog={this.showConfirmRemoveDailog}
                            onViewEditNote={this.viewEditNote}
                            onViewNotesCategoryAdditionalInfo={() => {
                                this.setState({
                                    displayNoteCategoryAdditionalInfo: true,
                                });
                            }}
                            showDeleteButton={
                                !!(
                                    this.props.allowDelete &&
                                    this.state.loggedInUserDataScopeId !==
                                        DataScopesEnum.LocalBasic.Value
                                )
                            }
                            showEditButton={this.props.allowEdit}
                            showExpertsColumn={this.props.showExpertsColumn}
                        />
                    ) : (
                        <span>No Notes found!</span>
                    )
                ) : null}

                <CheckmateDialog
                    isShowingModal={this.state.showConfirmRemoveDialog}
                    body="Are you sure you want to delete the selected note?"
                    handleClose={this.clickCancel}
                    handleConfirm={this.deleteNote}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />

                {this.props.allowView && this.state.displayNoteCategoryAdditionalInfo ? (
                    <NoteCategoryAdditionalInfoDetailDialog
                        noteCategoryTypes={this.props.noteCategoryTypes}
                        isShowingModal={true}
                        handleClose={() =>
                            this.setState({ displayNoteCategoryAdditionalInfo: false })
                        }
                    />
                ) : null}
            </div>
        );
    }
}
