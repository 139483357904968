import EntityAssertionControl from '../shared/EntityAssertionControl';
import { IEntityAssertionModel } from '../../interfaces/IAssertionDescriptor';
import { IStrategyPrincipleModel } from '../../interfaces/IStrategy';

export interface IStrategyTopicsProps {
    strategyPrinciples: IStrategyPrincipleModel[];
    isEditMode: boolean;
    narrativeFieldsAsPopups: boolean;
    setStrategyPrinciples: (strategyPrinciples: IStrategyPrincipleModel[]) => void;
    setNarrativeField: (params: {
        entityAssertion: IEntityAssertionModel | null;
        callback?: (entityAssertion: IEntityAssertionModel) => void;
    }) => void;
    topicLabel?: string;
    guidanceLabel?: string;
}

export function StrategyTopics({
    strategyPrinciples,
    isEditMode,
    narrativeFieldsAsPopups,
    setStrategyPrinciples,
    setNarrativeField,
    topicLabel = 'Topic',
    guidanceLabel = 'Guidance',
}: IStrategyTopicsProps) {
    const handleTopicChange = (idx: number, value: IEntityAssertionModel[]) => {
        if (!value) return;

        const existing = strategyPrinciples[idx];
        existing.topic = { ...value[0] };

        setStrategyPrinciples([...strategyPrinciples]);
    };

    const handleOpinionChange = (idx: number, value: IEntityAssertionModel[]) => {
        if (!value) return;

        const existing = strategyPrinciples[idx];
        existing.guidance = { ...value[0] };

        setStrategyPrinciples([...strategyPrinciples]);
    };

    return (
        <table className="table">
            <thead>
                <tr>
                    <th className="col-sm-3">{topicLabel}</th>
                    <th className="col-sm-9">{guidanceLabel}</th>
                </tr>
            </thead>
            <tbody>
                {strategyPrinciples?.map((item, idx) => (
                    <tr key={idx}>
                        <td className="col-sm-3 align-top">
                            <EntityAssertionControl
                                assertionDescriptor={item.topic.assertionDescriptor}
                                entityAssertions={[item.topic]}
                                editMode={isEditMode}
                                refreshChange={(e: IEntityAssertionModel[]) =>
                                    handleTopicChange(idx, e)
                                }
                                narrativeFieldAsPopup={narrativeFieldsAsPopups}
                                handleNarrativeFieldEdit={(entityAssertion) =>
                                    setNarrativeField({
                                        entityAssertion,
                                        callback: (newEntityAssertion) => {
                                            handleTopicChange(idx, [newEntityAssertion]);
                                        },
                                    })
                                }
                            />
                        </td>
                        <td className="col-sm-9 align-top">
                            <EntityAssertionControl
                                assertionDescriptor={item.guidance.assertionDescriptor}
                                entityAssertions={[item.guidance]}
                                editMode={isEditMode}
                                refreshChange={(e: IEntityAssertionModel[]) =>
                                    handleOpinionChange(idx, e)
                                }
                                narrativeFieldAsPopup={narrativeFieldsAsPopups}
                                handleNarrativeFieldEdit={(entityAssertion) => {
                                    setNarrativeField({
                                        entityAssertion,
                                        callback: (newEntityAssertion) => {
                                            handleOpinionChange(idx, [newEntityAssertion]);
                                        },
                                    });
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
