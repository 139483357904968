import { NavLink } from 'react-router-dom';

interface IMenuItemNavLinkWithIcon {
    to: string;
    iconClass: string;
    label: string;
}

export function MenuItemNavLinkWithIcon(props: IMenuItemNavLinkWithIcon) {
    return (
        <NavLink className="dropdown-item" to={props.to}>
            <div className="drop-down-icon-wrapper">
                <i className={`${props.iconClass}`} />
            </div>
            {props.label}
        </NavLink>
    );
}
