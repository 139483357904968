import ApiClient from '../../services/apiClient';
import { useLoading } from '../../contexts/LoadingContext';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useExpertDeleteMutation = (onError: (errorMessage: string) => void) => {
    const loading = useLoading();

    return useMutation({
        mutationFn: async (expertGuid: string) => {
            loading.showLoading();
            const response = await _apiClient.deleteExpert(expertGuid);

            if (!response?.httpResponse.ok) {
                throw new Error('Failed to delete Expert');
            }

            return response;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
        onSettled: () => loading.hideLoading(),
    });
};
