import * as React from 'react';

import { DocumentTitle } from '../../shared/DocumentTitle';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { SideNavMenuItem } from './SideNavMenuItem';
import { TutorialDetails } from '../../../utilities/Constants';

export interface ITutorialsLayoutProps {
    children: React.ReactNode;
}

export function TutorialsLayout(props: ITutorialsLayoutProps) {
    return (
        <>
            <DocumentTitle title="Tutotirals" />
            <div className="container-fluid" style={{ background: 'white' }}>
                <div className="row">
                    <div
                        className="col-sm-2 print-hide-display"
                        style={{ paddingLeft: '15px', paddingRight: '15px' }}
                    >
                        <div className="logo-container">
                            <img
                                className="logo-img rounded p-2"
                                src="/images/CheckmateGreenLogo.svg"
                                alt="Checkmate Logo"
                            />
                        </div>
                    </div>
                    <div
                        className="col-sm-10  print-hide-display"
                        style={{ height: '78px', padding: '0px', background: '#52bdad' }}
                    >
                        <div className="outerContainer">
                            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <div className="innerContainer float-end"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ background: '#52bdad', color: '#ffffff' }}>
                    <div className="col-sm-2">
                        <div
                            className="bold"
                            style={{ paddingTop: '25px', paddingLeft: '18px', fontSize: '15px' }}
                        >
                            <span>TUTORIALS</span>
                        </div>
                        <div className="pt-2" style={{ paddingLeft: '18px' }}>
                            {TutorialDetails.map((details) => (
                                <SideNavMenuItem
                                    key={details.fileName}
                                    label={details.displayName}
                                    url={`${LocalRoutes.Tutorials}${
                                        LocalRoutes.ViewPDF
                                    }/${details.id.toString()}`}
                                    icon={details.icon}
                                />
                            ))}
                        </div>
                    </div>
                    <div
                        className="col-sm-10 main-display"
                        style={{
                            background: '#ffffff',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}
