import * as React from 'react';

import Common from '../../stores/Common';
import { IReportCaseDetailModel } from '../../interfaces/Report/IReportCaseDetailModel';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../shared/SortableHeader';

interface IReportRequestForAuthListProps {
    data: IReportCaseDetailModel[];
}

interface IReportRequestForAuthListState {
    data: IReportCaseDetailModel[];
}

export class ReportRequestForAuthList extends React.Component<
    IReportRequestForAuthListProps,
    IReportRequestForAuthListState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }
    }

    componentDidUpdate(prevProps: IReportRequestForAuthListProps) {
        if (prevProps.data != this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }
    }

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let data = this.state.data;
        if (key.includes('date')) {
            data = data.sort(Sort.compareDate(key, subKey, order));
        } else {
            data = data.sort(Sort.compareValues(key, subKey, order, subGrandKey));
        }

        this.setState({ data: data });
    };

    render() {
        return (
            <table className="table table-sm">
                <thead>
                    <tr style={{ verticalAlign: 'middle' }}>
                        <SortableHeader
                            headerText="Case Name"
                            sortKey="caseName"
                            onSort={this.sortData}
                            thClassName="col-sm-2"
                            noWrap
                        />
                        <SortableHeader
                            headerText="Authority Requested"
                            sortKey="authorityRequested"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                        <SortableHeader
                            headerText="Date Requested"
                            sortKey="authorityRequestedDate"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                        <SortableHeader
                            headerText="Jurisdiction"
                            sortKey="jurisdiction"
                            onSort={this.sortData}
                            thClassName="col-sm-2"
                        />
                        <SortableHeader
                            headerText="Plaintiffs Firm"
                            sortKey="plaintiffsFirm"
                            onSort={this.sortData}
                            thClassName="col-sm-2"
                            noWrap
                        />
                        <SortableHeader
                            headerText="Disease"
                            sortKey="diagnosis"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                        <SortableHeader
                            headerText="Trial Date"
                            sortKey="trialDate"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                            noWrap
                        />
                        <SortableHeader
                            headerText="Demand"
                            sortKey="demand"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                        <SortableHeader
                            headerComponent={
                                <div>
                                    <div style={{ whiteSpace: 'nowrap' }}>Client Exposure</div>
                                    <div>Start Date</div>
                                </div>
                            }
                            sortKey="clientExposureStartDate"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                    </tr>
                </thead>
                <tbody>
                    {this.state.data && this.state.data.length > 0
                        ? this.state.data.map((item: IReportCaseDetailModel, i: number) => {
                              return (
                                  <tr key={i}>
                                      <td className="col-sm-2">
                                          <Link
                                              target="_blank"
                                              to={LocalRoutes.CaseFile.replace(
                                                  ':guid',
                                                  item.caseGuid
                                              )}
                                          >
                                              {item.caseName}
                                          </Link>
                                      </td>
                                      <td className="col-sm-1">
                                          {item.authorityRequested
                                              ? Common.formatCurrency(
                                                    item.authorityRequested.toString()
                                                )
                                              : ''}
                                      </td>
                                      <td className="col-sm-1">
                                          {Common.dateFormat(item.authorityRequestedDate)}
                                      </td>
                                      <td className="col-sm-2">{item.jurisdiction}</td>
                                      <td className="col-sm-2">{item.plaintiffsFirm}</td>
                                      <td className="col-sm-1">{item.diagnosis}</td>
                                      <td className="col-sm-1">
                                          {Common.dateFormat(item.trialDate)}
                                      </td>
                                      <td className="col-sm-1">
                                          {item.demand
                                              ? Common.formatCurrency(item.demand.toString())
                                              : ''}
                                      </td>
                                      <td className="col-sm-1">
                                          {Common.dateFormat(item.clientExposureStartDate)}
                                      </td>
                                  </tr>
                              );
                          })
                        : null}
                </tbody>
            </table>
        );
    }
}
