import { useParams } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { UserDetail } from '../user/UserDetail';

function UserDetailWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return <UserDetail guid={guid} user={props.user} />;
}

export default UserDetailWrapper;
