import * as React from 'react';

import { IOrganizationDependencyModel, IOrganizationModel } from '../../interfaces/IOrganization';
import { Link, Navigate } from 'react-router-dom';
import { OrganizationDependencyTypesEnum, OrganizationTypesEnum } from '../../utilities/Constants';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IOrganizationsListState {
    pendingResponse: boolean;
    OrganizationsExperienceList: IOrganizationModel[];
    validation: IValidation;
    redirect: boolean;
    selectedOrganizationGuid: string;
}

export class OrganizationExperienceList extends React.Component<any, IOrganizationsListState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: true,
            OrganizationsExperienceList: [],
            validation: {},
            redirect: false,
            selectedOrganizationGuid: '',
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.Zones, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadOrganizationsExperience();
    }

    loadOrganizationsExperience = async () => {
        try {
            const res = await _apiClient.getOrganizations(undefined, [
                OrganizationTypesEnum.Portfolio.Value,
            ]);
            if (res.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (res.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                    pendingResponse: false,
                });
                return;
            }
            if (res.payload) {
                this.setState({ OrganizationsExperienceList: res.payload, pendingResponse: false });
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    addOrganization = (e: React.FormEvent<HTMLButtonElement>, organization: IOrganizationModel) => {
        e.preventDefault();
        this.props.addOrganization!(e, organization);
    };

    selectOrganization = (
        e: React.FormEvent<HTMLButtonElement>,
        organization: IOrganizationModel
    ) => {
        e.preventDefault();
        this.props.selectOrganization!(e, organization);
    };

    sortData = (key: string, order: string, subKey?: string) => {
        if (this.state.OrganizationsExperienceList) {
            const OrganizationsExperienceList = this.state.OrganizationsExperienceList.sort(
                Sort.compareValues(key, subKey, order)
            );
            this.setState({ OrganizationsExperienceList: OrganizationsExperienceList });
        }
    };

    redirectToExperienceDetail = (event: React.FormEvent<HTMLTableRowElement>, guid: string) => {
        event.preventDefault();
        this.setState({
            redirect: true,
            selectedOrganizationGuid: guid,
        });
    };

    getSubscriptionTypeClass = (typeName: string): string => {
        switch (typeName) {
            case OrganizationDependencyTypesEnum.Master.DisplayName:
                return 'far fa-circle';
            case OrganizationDependencyTypesEnum.Associated.DisplayName:
                return 'far fa-adjust';
            case OrganizationDependencyTypesEnum.None.DisplayName:
                return 'far fa-circle-notch';
            default:
                return 'far fa-dot-circle';
        }
    };

    render() {
        if (this.state.redirect) {
            return (
                <Navigate
                    to={LocalRoutes.ZoneDetail.replace(
                        ':guid',
                        this.state.selectedOrganizationGuid
                    )}
                />
            );
        }

        if (this.state.pendingResponse) return <Loader />;

        return (
            <>
                <div className="row mb-3">
                    <h1 className="col-sm-6">Zone</h1>
                    <div className="col-sm-6">
                        <Link to={LocalRoutes.ZoneDetail.replace(':guid', '')}>
                            <span className="btn-green btn float-end btn-icon">
                                <i className="fal fa-lg fa-plus color-white" />
                            </span>
                        </Link>
                    </div>
                </div>

                {this.state.OrganizationsExperienceList &&
                this.state.OrganizationsExperienceList.length > 0 ? (
                    <table className="table table-striped organization-experience-table">
                        <thead>
                            <tr style={{ verticalAlign: 'middle' }}>
                                <SortableHeader
                                    headerText="Company"
                                    sortKey="primaryAssociatedOrganization"
                                    subKey="name"
                                    onSort={this.sortData}
                                />
                                <SortableHeader
                                    headerText="Portfolio"
                                    sortKey="name"
                                    onSort={this.sortData}
                                />
                                <th>Firm</th>
                                <th>Insurer</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.OrganizationsExperienceList.map(
                                (organization: IOrganizationModel) => {
                                    return (
                                        <tr
                                            key={organization.guid}
                                            onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
                                                this.redirectToExperienceDetail(
                                                    e,
                                                    organization.guid!
                                                );
                                            }}
                                        >
                                            {organization.primaryAssociatedOrganization ? (
                                                <td>
                                                    <Link
                                                        to={LocalRoutes.OrganizationDetail.replace(
                                                            ':guid',
                                                            organization.primaryAssociatedOrganization!
                                                                .guid!
                                                        )}
                                                    >
                                                        {
                                                            organization.primaryAssociatedOrganization!
                                                                .name!
                                                        }
                                                    </Link>
                                                    &nbsp;
                                                    <i
                                                        className={`fa ${this.getSubscriptionTypeClass(
                                                            organization.primaryAssociatedOrganization!
                                                                .dependencyType!.displayName!
                                                        )}`}
                                                    />
                                                </td>
                                            ) : (
                                                <td />
                                            )}
                                            <td>
                                                <Link
                                                    to={LocalRoutes.ZoneDetail.replace(
                                                        ':guid',
                                                        organization.guid!
                                                    )}
                                                >
                                                    {organization.name}
                                                </Link>
                                            </td>
                                            <td>
                                                <ul style={{ listStyle: 'none' }}>
                                                    {organization.dependentOrganizations
                                                        ? organization.dependentOrganizations
                                                              .filter(
                                                                  (org) =>
                                                                      org.organizationType!.id ==
                                                                      OrganizationTypesEnum.Firm
                                                                          .Value
                                                              )
                                                              .map(
                                                                  (
                                                                      firmOrganization: IOrganizationDependencyModel
                                                                  ) => {
                                                                      return (
                                                                          <li>
                                                                              <Link
                                                                                  to={LocalRoutes.OrganizationDetail.replace(
                                                                                      ':guid',
                                                                                      firmOrganization.guid!
                                                                                  )}
                                                                              >
                                                                                  {
                                                                                      firmOrganization.name
                                                                                  }
                                                                              </Link>
                                                                              &nbsp;
                                                                              <i
                                                                                  className={`fa ${this.getSubscriptionTypeClass(
                                                                                      firmOrganization.dependencyType!
                                                                                          .displayName!
                                                                                  )}`}
                                                                              />
                                                                          </li>
                                                                      );
                                                                  }
                                                              )
                                                        : null}
                                                </ul>
                                            </td>
                                            <td>
                                                <ul style={{ listStyle: 'none' }}>
                                                    {organization.dependentOrganizations
                                                        ? organization.dependentOrganizations
                                                              .filter(
                                                                  (org) =>
                                                                      org.organizationType!.id ==
                                                                      OrganizationTypesEnum.Insurer
                                                                          .Value
                                                              )
                                                              .map(
                                                                  (
                                                                      insurerOrganization: IOrganizationDependencyModel
                                                                  ) => {
                                                                      return (
                                                                          <li>
                                                                              <Link
                                                                                  to={LocalRoutes.OrganizationDetail.replace(
                                                                                      ':guid',
                                                                                      insurerOrganization.guid!
                                                                                  )}
                                                                              >
                                                                                  {
                                                                                      insurerOrganization.name
                                                                                  }
                                                                              </Link>
                                                                              &nbsp;
                                                                              <i
                                                                                  className={`fa ${this.getSubscriptionTypeClass(
                                                                                      insurerOrganization.dependencyType!
                                                                                          .displayName!
                                                                                  )}`}
                                                                              />
                                                                          </li>
                                                                      );
                                                                  }
                                                              )
                                                        : null}
                                                </ul>
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                ) : (
                    <span>No portfolio exists!</span>
                )}
            </>
        );
    }
}
