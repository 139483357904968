import { IDisplayFieldModel, IDisplaySectionModel } from '../../interfaces/IDisplaySectionModel';

import { AccordionWrapper } from './AccordionWrapper';
import { CaseFileSection } from './CaseFileSection';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { SectionRow } from './SectionRow';

interface IDynamicSectionRendererProps {
    displayAsCondensed: boolean;
    numberOfColumns: number;
    rows: IDisplayFieldModel[][];
    section: IDisplaySectionModel;
    user: IUserModel;
    validation: IValidation;
}

export function DynamicSectionRenderer({
    displayAsCondensed,
    numberOfColumns,
    rows,
    section,
    user,
    validation,
}: IDynamicSectionRendererProps) {
    const hasRows = (rows?.length ?? 0) > 0;
    return (
        <>
            {hasRows && (
                <div className="row">
                    {rows.map((fields, idx) => (
                        <SectionRow
                            key={idx}
                            fields={fields}
                            columnCount={numberOfColumns}
                            displayAsCondensed={displayAsCondensed}
                        />
                    ))}
                </div>
            )}
            {section.sections
                ?.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1))
                .map((nestedSection, idx) => {
                    const isLastSection = idx === section.sections?.length - 1;

                    return (
                        <div key={idx} style={{ marginTop: hasRows ? 10 : 'auto' }}>
                            <AccordionWrapper
                                key={idx}
                                accordionIndex={-1}
                                isOpen={true}
                                shouldWrap={true}
                                section={nestedSection}
                                isExpandable={true}
                                isTopLevel={false}
                                isLastSection={isLastSection}
                            >
                                <CaseFileSection
                                    displayAsCondensed={false}
                                    numberOfColumns={2}
                                    section={nestedSection}
                                    user={user}
                                    validation={validation}
                                />
                            </AccordionWrapper>
                        </div>
                    );
                })}
        </>
    );
}
