import * as React from 'react';

import { ApiRoutes } from '../../utilities/ApiRoutes';
import { INoteModel } from '../../interfaces/INote';
import { INoteUISearchModel } from '../../interfaces/IQuery';

interface IExportNotesProps {
    notes: INoteModel[];
    searchModel: INoteUISearchModel;
    handleError?: () => void;
}

interface IExportNotesState {
    pendingResponse: boolean;
}

export class ExportNotes extends React.Component<IExportNotesProps, IExportNotesState> {
    constructor(props: any) {
        super(props);
        this.state = {
            pendingResponse: false,
        };
    }

    exportNotes = async () => {
        this.setState({ pendingResponse: true });

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.ExportNotes, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({ pendingResponse: false });

                let fileName = 'Notes.xlsx';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                this.setState({ pendingResponse: false });
                if (typeof this.props.handleError === 'function') {
                    this.props.handleError();
                }
            }
        };
        const parm = { notes: this.props.notes, searchModel: this.props.searchModel };
        xhr.send(JSON.stringify(parm));
    };

    render() {
        return (
            <button
                className="btn btn-no-bg float-end text-gray horizontal-margin-right-lg"
                onClick={this.exportNotes}
            >
                <i className="fal fa-file-export" />
                &nbsp;Export
            </button>
        );
    }
}
