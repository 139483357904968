import * as React from 'react';

import { IOrganizationModel } from '../../interfaces/IOrganization';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../shared/SortableHeader';

interface IOrganizationsListProps {
    organizationsList?: IOrganizationModel[];
    onOrganizationSelection: (
        e: React.FormEvent<HTMLButtonElement>,
        organization: IOrganizationModel
    ) => void;
}

interface IOrganizationsListState {
    isShowingModal: boolean;
    selectedOrganization?: IOrganizationModel;
    organizationsList?: IOrganizationModel[];
}
export class OrganizationList extends React.Component<
    IOrganizationsListProps,
    IOrganizationsListState
> {
    constructor(props: IOrganizationsListProps) {
        super(props);

        this.state = {
            isShowingModal: false,
            organizationsList: props.organizationsList,
        };
    }
    componentDidUpdate(prevProps: IOrganizationsListProps) {
        if (prevProps.organizationsList !== this.props.organizationsList) {
            this.setState({ organizationsList: this.props.organizationsList });
        }
    }

    selectOrganization = (
        e: React.FormEvent<HTMLButtonElement>,
        organization: IOrganizationModel
    ) => {
        e.preventDefault();
        this.props.onOrganizationSelection!(e, organization);
    };

    ShowDialog = (e: React.FormEvent<HTMLButtonElement>, organization: IOrganizationModel) => {
        e.preventDefault();
        this.setState({
            isShowingModal: true,
            selectedOrganization: organization,
        });
    };

    handleCloseDialog = () => {
        this.setState({ isShowingModal: false });
    };

    sortData = (key: string, order: string, subKey?: string) => {
        if (this.state.organizationsList) {
            const organizationsList = this.state.organizationsList.sort(
                Sort.compareValues(key, subKey, order)
            );
            this.setState({ organizationsList: organizationsList });
        }
    };

    render() {
        return (
            <div>
                {this.state.organizationsList && this.state.organizationsList.length > 0 ? (
                    <table className="table table-striped">
                        <thead>
                            <tr style={{ verticalAlign: 'middle' }}>
                                <SortableHeader
                                    headerText="Portfolio Name"
                                    sortKey="name"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.organizationsList.map(
                                (organization: IOrganizationModel) => {
                                    return (
                                        <tr key={organization.guid}>
                                            <td>{organization.displayName}</td>
                                            <td>
                                                <button
                                                    className="btn btn-default float-end btn-icon"
                                                    onClick={(
                                                        e: React.MouseEvent<HTMLButtonElement>
                                                    ) => {
                                                        this.selectOrganization(e, organization);
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-plus" />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                ) : null}
            </div>
        );
    }
}
