import ApiClient from '../../../../services/apiClient';
import { ICreateCaseExpertModel } from '../../../../interfaces/ICaseExpert';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useCaseExpertCreateMutation = (onError: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (caseExpert: ICreateCaseExpertModel) => {
            const response = await _apiClient.createCaseExpert(caseExpert);

            if (
                response.errors?.length > 0 &&
                response.errors.some((err) => err.indexOf('already exist') > -1)
            ) {
                throw new Error(
                    'Failed to add Expert. This expert is already assigned to this case.'
                );
            }

            if (!response?.httpResponse.ok) {
                throw new Error('Failed to add Expert to the Case');
            }

            return response;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
    });
