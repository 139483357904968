import { CSSProperties, useMemo, useState } from 'react';
import { IWeather, WeatherDisplay } from './WeatherDisplay';

import { DateTimeDisplay } from './DateTimeDisplay';
import { Stack } from 'react-bootstrap';

interface ITimeAndWeatherWidgetProps {
    fontColor?: string;
    fontSize?: string;
    margin?: string;
    padding?: string;
}

export default function TimeAndWeatherWidget({
    fontColor,
    fontSize,
    margin,
    padding,
}: ITimeAndWeatherWidgetProps) {
    const [weather, setWeather] = useState<IWeather | null>(null);

    const styles = useMemo(
        () =>
            ({
                color: fontColor || '#000000',
                fontSize: fontSize || '1.1em',
                margin: margin || '1.5em 2em',
                padding: padding || 0,
                textAlign: 'right' as const,
            } as CSSProperties),
        [fontColor, fontSize, margin, padding]
    );

    return (
        <Stack direction="horizontal" style={styles} gap={2}>
            <div>
                <DateTimeDisplay fontColor={fontColor} />
                {weather?.location && <div>{weather.location}</div>}
            </div>
            {weather && <div className="vr" style={{ margin: '0 0.5em', width: '2px' }}></div>}
            <WeatherDisplay setWeather={setWeather} weather={weather} fontColor={fontColor} />
        </Stack>
    );
}
