import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useCallback, useEffect, useRef, useState } from 'react';

import { DocumentTitle } from '../components/shared/DocumentTitle';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { PDFPageProxy } from 'react-pdf';
import { TutorialDetails } from '../utilities/Constants';
import { useParams } from 'react-router-dom';

function CheckmatePDFViewer() {
    const { id } = useParams();

    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [availWidth, setAvailWidth] = useState<number | undefined>(0);
    const [desiredWidth, setDesiredWidth] = useState<number | undefined>(0);
    const [desiredHeight, setDesiredHeight] = useState<number | undefined>(0);
    const [pdfAspect, setPdfAspect] = useState(1);
    const [pdfWidth, setPdfWidth] = useState(0);
    const containerDiv = useRef<HTMLDivElement>(null);
    const pdfDiv = useRef<HTMLDivElement>(null);
    const buttonDiv = useRef<HTMLDivElement>(null);
    const [showButtons, setShowButtons] = useState(false);

    const calculatePdfConstraints = useCallback(() => {
        const multiplier = 1 / window.devicePixelRatio;

        const elem = containerDiv.current;
        if (!elem || !elem.offsetHeight) {
            return;
        }
        setAvailWidth(elem.offsetWidth);
        const sa = elem.offsetWidth / (elem.offsetHeight - 60);
        if (sa < pdfAspect) {
            setDesiredWidth(elem.offsetWidth * multiplier - 60);
            setDesiredHeight(undefined);
        } else {
            setDesiredWidth(undefined);
            setDesiredHeight(elem.offsetHeight * multiplier - 60);
        }
        window.setTimeout(() => {
            if (buttonDiv.current && pdfDiv.current) {
                buttonDiv.current.style.maxWidth = `${pdfDiv.current.offsetWidth}px`;
            }
        }, 100);
    }, [pdfAspect]);

    useEffect(() => {
        calculatePdfConstraints();
    }, []);

    useEffect(() => {
        if (buttonDiv.current && pdfDiv.current) {
            buttonDiv.current.style.maxWidth = `${pdfDiv.current.offsetWidth}px`;
        }
    }, [pdfWidth, availWidth]);

    useEffect(() => {
        calculatePdfConstraints();
        window.addEventListener('resize', calculatePdfConstraints);
        document.body.style.overflow = 'hidden';
        return () => {
            window.removeEventListener('resize', calculatePdfConstraints);
            document.body.style.overflow = '';
        };
    }, [calculatePdfConstraints, pdfAspect]);

    useEffect(() => {
        setShowButtons(false);
    }, [id]);

    function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy) {
        setNumPages(numPages);
        setPageNumber(1);

        window.setTimeout(() => {
            setShowButtons(true);
        }, 100);
    }

    function onPageLoadSuccess({ width, height }: PDFPageProxy) {
        setPdfAspect(width / height);
        setPdfWidth(width);
    }

    function changePage(offset: number) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function getFileName() {
        const idNum = parseInt(id || '');
        const match = TutorialDetails.find((x) => x.id === idNum);
        if (match) return match.fileName;
        else return '';
    }

    function getPdfName() {
        const idNum = parseInt(id || '');
        const match = TutorialDetails.find((x) => x.id === idNum);
        if (match) return match.displayName;
        else return '';
    }

    if (!id) {
        return null;
    }

    return (
        <>
            <DocumentTitle title={getPdfName() + ' Tutorial'} />
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
                ref={containerDiv}
            >
                <div
                    className="d-flex p-0"
                    style={{ flexGrow: 1, placeItems: 'center', alignSelf: 'center' }}
                    ref={pdfDiv}
                >
                    <Document file={'/pdf/' + getFileName()} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page
                            pageNumber={pageNumber}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            height={desiredHeight}
                            width={desiredWidth}
                            onLoadSuccess={onPageLoadSuccess}
                        />
                    </Document>
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexBasis: '60px',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-end',
                        visibility: showButtons ? 'visible' : 'hidden',
                        width: '100%',
                    }}
                    ref={buttonDiv}
                >
                    <span className="text-gray m-2" style={{ whiteSpace: 'nowrap' }}>
                        Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                    </span>
                    <div
                        className="d-flex"
                        style={{
                            userSelect: 'none',
                        }}
                    >
                        <button
                            className="btn btn-orange text-gray"
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                        >
                            Previous
                        </button>
                        <button
                            className="btn btn-orange text-gray m-0"
                            type="button"
                            disabled={pageNumber >= (numPages || 0)}
                            onClick={nextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CheckmatePDFViewer;
