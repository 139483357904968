import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const e: any = require('@tinymce/tinymce-react');
const Editor = e.Editor;

interface ITextEditorProps {
    value: string;
    disabled: boolean;
    onChange: (value: string) => void;
}

export class TextEditor extends React.Component<ITextEditorProps, unknown> {
    constructor(props: ITextEditorProps) {
        super(props);
        this.state = { content: '' };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    handleEditorChange(content: string) {
        this.props.onChange(content);
    }

    render() {
        return (
            <Editor
                disabled={this.props.disabled}
                apiKey="ylj3hgrhzhr36ggco4lncrpafrx5b7ril6754bjkcnkb90gu"
                value={this.props.value}
                onEditorChange={this.handleEditorChange}
                init={{ plugins: ['link table', 'print'], height: 380 }}
                toolbar={[
                    'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect | link table | print',
                ]}
            />
        );
    }
}
