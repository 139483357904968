import * as React from 'react';

interface IReportViewerProps {
    reportName: string;
    reportParameters: any;
    refreshReport: boolean;
    addSpaceForCommentOnTop?: boolean;
    renderComplete?: (height: number) => void;
    scale?: number;
}

export default class Html5ReportViewer extends React.Component<IReportViewerProps, any> {
    // The componentDidMount() method runs after the component output has been rendered to the DOM.
    componentDidMount() {
        $('#reportViewer1')
            .css('position', 'absolute')
            .telerik_ReportViewer({
                serviceUrl: '/api/reports/',
                reportSource: {
                    report: this.props.reportName,
                    parameters: this.props.reportParameters,
                },
                scale: this.props.scale || 1.0,
                scaleMode: this.props.scale ? 'SPECIFIC' : 'FIT_PAGE_WIDTH',
                viewMode: 'INTERACTIVE',
                printMode: 'SPECIFIC',
                sendEmail: { enabled: false },
                ready: () => {
                    $('.trv-nav').hide();
                    $('.trv-content').css('border', 'none');
                    // Hack to use custom Checkmate Loader instead of inbuilt Telerik Loader
                    const divs = $('.trv-error-pane').first();
                    divs.html('');
                    divs.html(
                        "<div class='k-tooltip-validation k-widget loader' style='margin: 0 auto;top:50%;'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>"
                    );

                    // Disable double click on the Report Viewer
                    const kendoTouch = $('.trv-pages-area').data('kendoTouch');
                    if (kendoTouch) {
                        kendoTouch.unbind('doubletap');
                    }
                },
                renderingEnd: () => {
                    if (this.props.renderComplete)
                        this.props.renderComplete($('#reportViewer1').outerHeight() || 1200);
                },
            });
    }

    componentDidUpdate(prevProps: IReportViewerProps) {
        if (prevProps.refreshReport !== this.props.refreshReport) {
            this.refreshReport();
        }
    }

    refreshReport() {
        const reportViewer = $('#reportViewer1').data('telerik_ReportViewer');
        reportViewer.reportSource({
            report: this.props.reportName,
            parameters: this.props.reportParameters,
        });
        reportViewer.refreshReport();
    }

    render() {
        return (
            <div
                id="reportViewer1"
                style={this.props.addSpaceForCommentOnTop ? { top: '160px' } : {}}
            />
        );
    }
}
