import * as React from 'react';

import Auth from '../../stores/authentication';
import CheckmateApiClient from '../../services/apiClient';
import { CheckmateDialog } from '../../components/shared/dialog';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Navigate } from 'react-router-dom';

const _apiClient = new CheckmateApiClient();

interface ILogoutProps {
    onLogout: () => void;
}

interface ILogoutState {
    redirect: boolean;
    redirectTo: string;
}

export class Logout extends React.Component<ILogoutProps, ILogoutState> {
    constructor(props: any) {
        super(props);

        this.state = {
            redirect: false,
            redirectTo: '/',
        };
    }

    handleConfirm = async () => {
        await _apiClient.logout();
        Auth.clearUserDefaultOrganization();
        this.props.onLogout();
        this.setState({
            redirect: true,
            redirectTo: LocalRoutes.Login,
        });
    };

    handleClose = () => {
        this.setState({
            redirect: true,
            redirectTo: '/',
        });
    };

    public render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirectTo} />;
        } else {
            return (
                <CheckmateDialog
                    isShowingModal={true}
                    body="Are you sure you want to logout?"
                    handleClose={this.handleClose}
                    handleConfirm={this.handleConfirm}
                    confirmText="Yes"
                    confirmButtonClassName="btn btn-black float-end"
                    closeButtonClassName="btn btn-default float-end"
                    dialogClassName="logout-dialog"
                    cancelText="No"
                />
            );
        }
    }
}
