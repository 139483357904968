import {
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptors,
    EntityTypes,
    TaskActivityTypesEnum,
    TaskTypesEnum,
} from '../../utilities/Constants';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { IExpertDetailModel, IGeneralOpinion } from '../../interfaces/IExpert';
import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';

import CheckmateNSelect from '../shared/CheckmateNSelect';
import CheckmateSelect from '../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import EntityAssertionControl from '../shared/EntityAssertionControl';
import { ICheckmateTaskModel } from '../../interfaces/ICase';
import { IEntityAssertionModel } from '../../interfaces/IAssertionDescriptor';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { NarrativeModal } from './NarrativeModal';
import { StrategyTopics } from './StrategyTopics';
import { TaskList } from '../tasks/TaskList';
import UIHelper from '../../utilities/UIHelper';
import { useAssertionDescriptorsLookup } from '../../shared/react-query-hooks/useAssertionDescriptorsLookup';
import { useCaseManagersInZone } from '../../shared/react-query-hooks/useCaseManagersInZone';
import { useExpertDisciplineTypesLookup } from '../../shared/react-query-hooks/useExpertDisciplineTypesLookup';
import { useExpertTypesLookup } from '../../shared/react-query-hooks/useExpertTypesLookup';
import React from 'react';

const entityType = {
    id: EntityTypes.Expert,
    guid: '00000000-0000-0000-0000-000000000000',
} as ILookupModel;

export interface IExpertDetailFormProps {
    expert?: IExpertDetailModel;
    isCreateMode?: boolean;
    isEditMode: boolean;
    onFormDataUpdate: (expert: IExpertDetailModel) => void;
    user: IUserModel;
    validation: IValidation;
}

export function ExpertDetailForm(props: IExpertDetailFormProps) {
    const [lastName, setLastName] = useState<string>(props.expert?.lastName ?? '');
    const [firstName, setFirstName] = useState<string>(props.expert?.firstName ?? '');
    const [credentials, setCredentials] = useState<string>(props.expert?.credentials ?? '');

    const [expertTypeId, setExpertTypeId] = useState<number | null>(
        props.expert?.expertTypeId ?? null
    );
    const [disciplines, setDisciplines] = useState<ILookupModel[]>(props.expert?.disciplines ?? []);
    const [generalOpinions, setGeneralOpinions] = useState<IGeneralOpinion[]>(
        props.expert?.generalOpinions ?? []
    );
    const [contactInformation, setContactInformation] = useState<IEntityAssertionModel | null>(
        props.expert?.contactInformation ?? null
    );
    const [tasks, setTasks] = useState<ICheckmateTaskModel[] | null>(props.expert?.tasks ?? []);
    const [currentNarrativeAssertion, setCurrentNarrativeAssertion] = useState<{
        entityAssertion: IEntityAssertionModel | null;
        callback?: (entityAssertion: IEntityAssertionModel) => void;
    }>({ entityAssertion: null });

    const expertTypesLookup = useExpertTypesLookup();
    const assertionDescriptorsLookup = useAssertionDescriptorsLookup(
        AssertionDescriptorUsageTypesEnum.Expert
    );
    const expertDisciplinesLookup = useExpertDisciplineTypesLookup();
    const caseManagers = useCaseManagersInZone();

    const topicAD = useMemo(() => {
        return assertionDescriptorsLookup.data?.find(
            (ad) => ad.guid === AssertionDescriptors.StrategyTopic.Guid
        );
    }, [assertionDescriptorsLookup]);

    const opinionAD = useMemo(() => {
        return assertionDescriptorsLookup.data?.find(
            (ad) => ad.guid === AssertionDescriptors.StrategyGuidance.Guid
        );
    }, [assertionDescriptorsLookup]);

    const contactInfoAD = useMemo(() => {
        return assertionDescriptorsLookup.data?.find(
            (ad) => ad.guid === AssertionDescriptors.ContactInformation.Guid
        );
    }, [assertionDescriptorsLookup]);

    const expertDisciplineOptions =
        expertDisciplinesLookup.data?.map(UIHelper.lookupModelToMultiSelectOptionMapper) ?? [];

    const expertDisciplineSelectedOptions = expertDisciplineOptions.filter((opt) =>
        disciplines.map((disc) => disc.id).some((discId) => discId === opt.id)
    );

    const handleAddGeneralOpinionClick = () => {
        setGeneralOpinions((prev: IGeneralOpinion[]) => {
            return [
                ...prev,
                {
                    topic: {
                        assertionDescriptor: topicAD,
                        entityType,
                    },
                    opinion: {
                        assertionDescriptor: opinionAD,
                        entityType,
                    },
                } as IGeneralOpinion,
            ];
        });
    };

    // Reset data when isEditMode changes to false
    useEffect(() => {
        if (!props.isEditMode && props.expert) {
            setFirstName(props.expert.firstName);
            setLastName(props.expert.lastName);
            setCredentials(props.expert.credentials);
            setExpertTypeId(props.expert.expertTypeId);
            setDisciplines(props.expert?.disciplines ?? []);
            setGeneralOpinions(props.expert?.generalOpinions ?? []);
            setContactInformation(props.expert?.contactInformation ?? null);
            setTasks(props.expert?.tasks ?? []);
        }
    }, [props.isEditMode, props.expert]);

    useEffect(() => {
        const model = {
            guid: props.expert?.guid,
            firstName,
            lastName,
            credentials,
            expertTypeId,
            disciplines,
            generalOpinions,
            contactInformation:
                contactInformation ??
                ({
                    assertionDescriptor: contactInfoAD,
                    entityType,
                    textValue: '',
                } as IEntityAssertionModel),
            tasks,
        } as IExpertDetailModel;

        props.onFormDataUpdate(model);
    }, [
        firstName,
        lastName,
        credentials,
        expertTypeId,
        disciplines,
        generalOpinions,
        contactInformation,
        tasks
    ]);

    return (
        <>
            <div>
                <div className="row my-2" style={{ alignItems: 'center' }}>
                    <label className="col-sm-2 text-gray">First Name*</label>
                    <div className="col-sm-10">
                        {props.isCreateMode || (props.isEditMode && !props.isCreateMode) ? (
                            <input
                                className="form-control"
                                type="text"
                                name="firstName"
                                value={firstName}
                                maxLength={100}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFirstName(e.target.value)
                                }
                            />
                        ) : (
                            <>{firstName}</>
                        )}
                        {props.validation.firstName && (
                            <span className="text-danger">{props.validation.firstName}</span>
                        )}
                    </div>
                </div>
                <div className="row my-2" style={{ alignItems: 'center' }}>
                    <label className="col-sm-2 text-gray">Last Name*</label>
                    <div className="col-sm-10">
                        {props.isCreateMode || (props.isEditMode && !props.isCreateMode) ? (
                            <input
                                className="form-control"
                                type="text"
                                name="lastName"
                                value={lastName}
                                maxLength={100}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setLastName(e.target.value)
                                }
                            />
                        ) : (
                            <>{lastName}</>
                        )}
                        {props.validation.lastName && (
                            <span className="text-danger">{props.validation.lastName}</span>
                        )}
                    </div>
                </div>
                <div className="row my-2" style={{ alignItems: 'center' }}>
                    <label className="col-sm-2 text-gray">Credentials</label>
                    <div className="col-sm-10">
                        {props.isCreateMode || (props.isEditMode && !props.isCreateMode) ? (
                            <input
                                className="form-control"
                                type="text"
                                name="credentials"
                                value={credentials}
                                maxLength={100}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setCredentials(e.target.value)
                                }
                            />
                        ) : (
                            <>{credentials}</>
                        )}
                        {props.validation.credentials && (
                            <span className="text-danger">{props.validation.credentials}</span>
                        )}
                    </div>
                </div>
                <div className="row my-2" style={{ alignItems: 'center' }}>
                    <label className="col-sm-2 text-gray">Type*</label>
                    <div className="col-sm-10">
                        {props.isCreateMode || (props.isEditMode && !props.isCreateMode) ? (
                            <div style={{ maxWidth: '300px' }}>
                                <CheckmateSelect
                                    onChange={(selectedItem: IMultiSelectOptions) => {
                                        setExpertTypeId(selectedItem?.id);
                                    }}
                                    value={CheckmateSelectHelper.getSelectedValueById(
                                        expertTypesLookup.data ?? [],
                                        expertTypeId ?? 0
                                    )}
                                    options={CheckmateSelectHelper.getLookupOptions(
                                        expertTypesLookup.data ?? []
                                    )}
                                />
                            </div>
                        ) : (
                            <>
                                {
                                    expertTypesLookup?.data?.find(
                                        (lookup) => lookup.id === props.expert?.expertTypeId
                                    )?.displayName
                                }
                            </>
                        )}
                        {props.validation.type && (
                            <span className="text-danger">{props.validation.type}</span>
                        )}
                    </div>
                </div>
                <div className="row my-2" style={{ alignItems: 'center' }}>
                    <label className="col-sm-2 text-gray">Disciplines*</label>
                    <div className="col-sm-10">
                        {props.isCreateMode || (props.isEditMode && !props.isCreateMode) ? (
                            <div style={{ maxWidth: '300px' }}>
                                <CheckmateNSelect
                                    options={expertDisciplineOptions}
                                    value={expertDisciplineSelectedOptions}
                                    onChange={(selectedItems) => setDisciplines(selectedItems)}
                                    placeholder="-- Discipline --"
                                />
                            </div>
                        ) : (
                            <>
                                {props.expert?.disciplines
                                    ?.map((discipline) => discipline.displayName)
                                    ?.join(' | ')}
                            </>
                        )}
                        {props.validation.disciplines && (
                            <span className="text-danger">{props.validation.disciplines}</span>
                        )}
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-sm-2">
                        <label className="text-gray">Principles</label>{' '}
                        {props.isEditMode && (
                            <button
                                className="btn btn-default btn-icon"
                                onClick={handleAddGeneralOpinionClick}
                            >
                                <i className="fal fa-lg fa-plus" />
                            </button>
                        )}
                    </div>
                    <div className="col-sm-10">
                        <StrategyTopics
                            strategyPrinciples={generalOpinions.map((go) => ({
                                correlationKeyId: go.correlationKeyId,
                                topic: go.topic,
                                guidance: go.opinion,
                            }))}
                            setStrategyPrinciples={(strategyPrinciples) =>
                                setGeneralOpinions(() =>
                                    strategyPrinciples.map((sp) => ({
                                        correlationKeyId: sp.correlationKeyId,
                                        topic: sp.topic,
                                        opinion: sp.guidance,
                                    }))
                                )
                            }
                            isEditMode={props.isEditMode}
                            setNarrativeField={setCurrentNarrativeAssertion}
                            narrativeFieldsAsPopups={true}
                            guidanceLabel="Guidance/Position"
                        />
                    </div>
                </div>
                {contactInfoAD && (
                    <div className="row my-2" style={{ alignItems: 'center' }}>
                        <label className="col-sm-2 text-gray">Contact Information</label>
                        <div className="col-sm-10">
                            <EntityAssertionControl
                                assertionDescriptor={contactInfoAD}
                                entityAssertions={[
                                    contactInformation ?? {
                                        assertionDescriptor: contactInfoAD,
                                        entityType,
                                    },
                                ]}
                                editMode={props.isEditMode}
                                refreshChange={() => console.log('refresh change')}
                                handleNarrativeFieldEdit={(entityAssertion) =>
                                    setCurrentNarrativeAssertion({
                                        entityAssertion,
                                        callback: (entityAssertion) => {
                                            setContactInformation(entityAssertion);
                                        },
                                    })
                                }
                                narrativeFieldAsPopup
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="row">
                <TaskList
                    user={props.user}
                    taskType={TaskTypesEnum.StrategyTactics}
                    parentEntityGuid={props.expert?.guid ?? ''}
                    parentEntityType={{ id: EntityTypes.Expert }}
                    defaultTaskActivityTypeId={TaskActivityTypesEnum.AnalysisStrategy}
                    ownerList={caseManagers.data ?? []}
                    readOnly={!props.isEditMode}
                    refreshParent={setTasks}
                    title="Tactics"
                    tasks={tasks ?? []}
                />
            </div>
            {currentNarrativeAssertion.entityAssertion && (
                <NarrativeModal
                    entityAssertion={currentNarrativeAssertion.entityAssertion}
                    onCancelClick={() => setCurrentNarrativeAssertion({ entityAssertion: null })}
                    onSubmitClick={(entityAssertion) => {
                        if (typeof currentNarrativeAssertion.callback === 'function') {
                            currentNarrativeAssertion.callback(entityAssertion);
                        }
                        setCurrentNarrativeAssertion({ entityAssertion: null });
                    }}
                />
            )}
        </>
    );
}
