import { DatabaseAppSettingsEnum, UserRightsEnum } from '../../../../utilities/Constants';

import Authorization from '../../../../stores/Authorization';
import { HubConnection } from '@microsoft/signalr';
import { IUserModel } from '../../../../interfaces/IUser';
import { SideNavMenu } from './SideNavMenu';
import { useGetDbAppSettings } from '../../../../shared/react-query-hooks/useGetDbAppSettings';
import { useLocation } from 'react-router-dom';

interface ISideNavMenuProps {
    user?: IUserModel;
    isAuthenticated?: boolean;
    alertHubConnection: HubConnection | undefined;
    setIsLocalSwitchZone: () => void;
}

export default function SideNavMenuWrapper({
    user,
    isAuthenticated,
    alertHubConnection,
    setIsLocalSwitchZone,
}: ISideNavMenuProps) {
    const { key } = useLocation();

    const appSettings = useGetDbAppSettings();

    const disableCollabReportFeatureSetting = appSettings.data?.find(
        (setting) => setting.name === DatabaseAppSettingsEnum.DisableCollabReportFeature
    );

    const userHasReportRight = Authorization.userHasRight(
        UserRightsEnum.Reports,
        user ?? { active: false }
    );

    const showReportCounts =
        !appSettings.isLoading &&
        userHasReportRight &&
        (disableCollabReportFeatureSetting?.value ?? 'false') === 'false';

    return (
        <SideNavMenu
            user={user}
            isAuthenticated={isAuthenticated}
            alertHubConnection={alertHubConnection}
            locationKey={key}
            setIsLocalSwitchZone={setIsLocalSwitchZone}
            showReportCounts={showReportCounts}
        />
    );
}
