import * as React from 'react';

import {
    ApplicationHelpType,
    CaseFilterType,
    CaseStatusTypesEnum,
    CaseSubStatusTypesEnum,
    DataScopesEnum,
    FavoriteTypesEnum,
    ListMode,
    SortColumns,
    SortDirections,
    UserRightsEnum,
} from '../../utilities/Constants';
import {
    ICaseFilterModel,
    ICaseModel,
    ICaseSearchModel,
    ICaseStatusLookupModel,
} from '../../interfaces/ICase';
import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';

import Authorization from '../../stores/Authorization';
import { CaseEasyUpdate } from './CaseEasyUpdate';
import CheckmateApiClient from '../../services/apiClient';
import CheckmateNSelect from '../../components/shared/CheckmateNSelect';
import { Favorite } from '../../components/shared/Favorite';
import { Help } from '../../components/shared/Help';
import { IFavoriteModel } from '../../interfaces/IFavorite';
import { IOrganizationModel } from '../../interfaces/IOrganization';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import { Table } from 'react-bootstrap';
import UIHelper from '../../utilities/UIHelper';
import ValidateUtils from '../../shared/validations';
import { cloneDeep } from 'lodash';

const _apiClient = new CheckmateApiClient();
const PageSize = 100;

interface ICaseListProps {
    defaultUserOrg: IOrganizationModel;
    refreshIdentifier: string;
    reloadFavorites: () => void;
    user: IUserModel;
    lookups: {
        caseManagers: IUserModel[];
        caseStatuses: ICaseStatusLookupModel[];
        diagnoses: ILookupModel[];
        favorites: IFavoriteModel[];
        jurisdictions: ILookupModel[];
        plaintiffsFirms: ILookupModel[];
        qScores: ILookupModel[];
        usStates: ILookupModel[];
    };
}

interface ICaseListState {
    allowAddNew: boolean;
    caseManagerOptions: IMultiSelectOptions[];
    cases: ICaseModel[];
    caseSearchParameters: ICaseSearchModel;
    caseStatusOptions: IMultiSelectOptions[];
    currentFavoriteDetailsString: string;
    diagnosisOptions: IMultiSelectOptions[];
    easyUpdateAttemptedCaseList: string[];
    easyUpdateCaseGuid?: string;
    easyUpdateMode: boolean;
    enablePlanningExecution?: boolean;
    isSystemAdmin: boolean;
    jurisdictionOptions: IMultiSelectOptions[];
    pendingResponse: boolean;
    plaintiffsFirmOptions: IMultiSelectOptions[];
    qScoreOptions: IMultiSelectOptions[];
    searchText: string;
    selectedCaseManagerOptions: IMultiSelectOptions[];
    selectedDiagnosisOptions: IMultiSelectOptions[];
    selectedJurisdictionOptions: IMultiSelectOptions[];
    selectedPlaintiffsfirmOptions: IMultiSelectOptions[];
    selectedQScoreOptions: IMultiSelectOptions[];
    selectedStatusOptions: IMultiSelectOptions[];
    selectedUSStatesOptions: IMultiSelectOptions[];
    showCaseSearchByNameNumberInfo?: boolean;
    totalCaseCount: number;
    userDefaultSettingsJson?: string;
    usStateOptions: IMultiSelectOptions[];
    validation: IValidation;
}

export class CaseList extends React.Component<ICaseListProps, ICaseListState> {
    constructor(props: ICaseListProps) {
        super(props);

        this.state = {
            cases: [],
            totalCaseCount: 0,
            caseStatusOptions: [],
            diagnosisOptions: [],
            jurisdictionOptions: [],
            plaintiffsFirmOptions: [],
            caseManagerOptions: [],
            usStateOptions: [],
            qScoreOptions: [],
            validation: {},
            caseSearchParameters: {
                statusIds: [CaseStatusTypesEnum.Open.Value],
                subStatusIds: [CaseSubStatusTypesEnum.Active.Value],
                queryModel: {
                    pageNumber: 0,
                    pageSize: PageSize,
                    sortBy: SortColumns.CaseName,
                    sortDirection: SortDirections.Asc,
                },
            }, // Default to Open - Active. AR - TO DO - Need to think of a better way, remove hardcoding
            searchText: '',
            isSystemAdmin: false,
            selectedStatusOptions: [{ id: 1, label: 'Open - Active', value: 'Open - Active' }], // AR - TO DO - Need to think of a better way, remove hardcoding
            selectedDiagnosisOptions: [],
            selectedJurisdictionOptions: [],
            selectedUSStatesOptions: [],
            selectedPlaintiffsfirmOptions: [],
            selectedCaseManagerOptions: [],
            selectedQScoreOptions: [],
            pendingResponse: true,
            allowAddNew: true,
            easyUpdateMode: false,
            easyUpdateAttemptedCaseList: [],
            currentFavoriteDetailsString: '',
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.Cases, this.props.user)) {
            window.location.assign(LocalRoutes.AccessDenied);
        }

        this.loadInitialData();
    }

    loadInitialData = async () => {
        this.hydrateCaseStatusOptions();
        this.hydrateUsStateOptions();
        this.hydrateDiagnosisOptions();
        this.hydrateCaseManagerOptions();
        this.hydrateJurisdictionOptions();
        this.hydrateQScoreOptions();
        this.hydratePlaintiffsfirmOptions();
        this.setEnablePlanningExecution();

        const defaultFavorite = this.props.lookups.favorites.find((favorite) => favorite.default);

        const userDefaultSettingsJson = defaultFavorite?.settingJson;
        this.setState({ userDefaultSettingsJson }, this.loadDefaultView);
    };

    loadDefaultView = async (mode?: ListMode) => {
        if (this.state.userDefaultSettingsJson && mode !== ListMode.SystemDefault) {
            this.updateData(this.state.userDefaultSettingsJson);
        } else {
            this.loadCases(true);
        }
    };

    componentDidUpdate(prevProps: ICaseListProps) {
        if (this.props.refreshIdentifier != prevProps.refreshIdentifier)
            this.setState(
                {
                    cases: [],
                    totalCaseCount: 0,
                    caseStatusOptions: [],
                    diagnosisOptions: [],
                    jurisdictionOptions: [],
                    plaintiffsFirmOptions: [],
                    caseManagerOptions: [],
                    usStateOptions: [],
                    qScoreOptions: [],
                    validation: {},
                    caseSearchParameters: {
                        statusIds: [CaseStatusTypesEnum.Open.Value],
                        subStatusIds: [CaseSubStatusTypesEnum.Active.Value],
                        queryModel: {
                            pageNumber: 0,
                            pageSize: PageSize,
                            sortBy: SortColumns.CaseName,
                            sortDirection: SortDirections.Asc,
                        },
                    },
                    searchText: '',
                    isSystemAdmin: false,
                    selectedStatusOptions: [
                        { id: 1, label: 'Open - Active', value: 'Open - Active' },
                    ],
                    selectedDiagnosisOptions: [],
                    selectedJurisdictionOptions: [],
                    selectedUSStatesOptions: [],
                    selectedPlaintiffsfirmOptions: [],
                    selectedCaseManagerOptions: [],
                    selectedQScoreOptions: [],
                    pendingResponse: true,
                    allowAddNew: true,
                    easyUpdateMode: false,
                    easyUpdateAttemptedCaseList: [],
                    currentFavoriteDetailsString: '',
                },
                this.loadInitialData
            );
    }

    errorMessage = (msg: string) => {
        const validation = this.state.validation;
        validation.errorMessage = [msg];
        this.setState({ validation: validation });
    };

    hydrateCaseManagerOptions = () => {
        const caseManagerOptions = this.props.lookups.caseManagers.map((caseMgr: IUserModel) => ({
            label: caseMgr.profile!.firstName + ' ' + caseMgr.profile!.lastName,
            value:
                caseMgr.guid! +
                (caseMgr.activeZoneDataScope &&
                caseMgr.activeZoneDataScope!.id === DataScopesEnum.LocalBasic.Value
                    ? ' (Local)'
                    : ''),
            id: CaseFilterType.CaseManager.Value,
        }));

        this.setState({ caseManagerOptions });
    };

    hydrateCaseStatusOptions = () => {
        const caseStatusOptions = this.props.lookups.caseStatuses.map((status) => ({
            label: status.name!,
            value: status.name!,
            id: status.id,
        }));

        this.setState({ caseStatusOptions });
    };

    hydrateDiagnosisOptions = () => {
        const diagnosisOptions = this.props.lookups.diagnoses.map((diag) => ({
            label: diag.displayName!,
            value: diag.name!,
            id: CaseFilterType.Diagnosis.Value,
        }));

        this.setState({ diagnosisOptions });
    };

    hydrateJurisdictionOptions = () => {
        const jurisdictionOptions = this.props.lookups.jurisdictions.map(
            (jurisdiction: ILookupModel) => ({
                label: jurisdiction.displayName!,
                value: jurisdiction.name!,
                id: jurisdiction.id,
            })
        );
        this.setState({ jurisdictionOptions });
    };

    hydrateUsStateOptions = () => {
        const usStatesOptions = this.props.lookups.usStates.map((state: ILookupModel) => ({
            label: state.displayName!,
            value: state.name!,
            id: state.id,
        }));
        this.setState({ usStateOptions: usStatesOptions });
    };

    hydratePlaintiffsfirmOptions = () => {
        const plaintiffsFirmOptions = this.props.lookups.plaintiffsFirms.map(
            (firm: ILookupModel) => ({
                label: firm.displayName!,
                value: firm.name!,
                id: firm.id,
            })
        );
        this.setState({ plaintiffsFirmOptions: plaintiffsFirmOptions });
    };

    hydrateQScoreOptions = () => {
        const qScoreOptions = this.props.lookups.qScores.map((qScore: ILookupModel) => ({
            label: qScore.displayName!,
            value: qScore.name!,
            id: qScore.id,
        }));
        this.setState({ qScoreOptions: qScoreOptions });
    };

    setEnablePlanningExecution = async () => {
        let enablePlanningExecution = false;

        if (this.props.defaultUserOrg.enablePlanningExecution) enablePlanningExecution = true;

        if (this.props.user && this.props.user.homeOrganization) {
            if (this.props.user.homeOrganization.guid === this.props.defaultUserOrg.guid) {
                enablePlanningExecution = false; // If user is an employee of the Portfolio organization, Disable Planning & Execution
            } else if (
                this.props.defaultUserOrg.primaryAssociatedOrganization &&
                this.props.defaultUserOrg.primaryAssociatedOrganization.guid ==
                    this.props.user.homeOrganization.guid
            ) {
                enablePlanningExecution = false; // If user is an employee of the Company organization, Disable Planning & Execution
            }
        }

        this.setState({ enablePlanningExecution });
    };

    checkForAtLeastOneFilter = (caseSearchParameters: ICaseSearchModel) => {
        const hasAtLeastOneFilter = (): boolean => {
            return (
                (caseSearchParameters.statusIds?.length ?? 0) > 0 ||
                (caseSearchParameters.subStatusIds?.length ?? 0) > 0 ||
                (caseSearchParameters.filters?.length ?? 0) > 0
            );
        };

        const hasSearchValue = (): boolean => {
            return (caseSearchParameters.searchText?.trim().length ?? 0) > 0;
        };

        return hasAtLeastOneFilter() || hasSearchValue();
    };

    runSearch = (event: React.FormEvent<HTMLButtonElement> | null, searchString?: string) => {
        if (event) event.preventDefault();

        const caseSearchParametersCopy = cloneDeep(this.state.caseSearchParameters);

        if (caseSearchParametersCopy.queryModel) caseSearchParametersCopy.queryModel.pageNumber = 0;
        const statusIds: number[] = [];
        const subStatusIds: number[] = [];

        for (let i = 0; i < this.state.selectedStatusOptions.length; i++) {
            const lookupMatch = this.props.lookups.caseStatuses.find(
                (x) => x.id == this.state.selectedStatusOptions[i].id
            );
            if (lookupMatch) {
                if (lookupMatch.status) statusIds.push(lookupMatch.status.id);
                if (lookupMatch.subStatus) subStatusIds.push(lookupMatch.subStatus.id);
            }
        }

        caseSearchParametersCopy.statusIds = statusIds;
        caseSearchParametersCopy.subStatusIds = subStatusIds;

        caseSearchParametersCopy.filters = this.getCaseFilters();

        if (searchString != undefined) {
            caseSearchParametersCopy.searchText = searchString;
        }

        const hasAtleastOneFilter = this.checkForAtLeastOneFilter(caseSearchParametersCopy);

        if (!hasAtleastOneFilter) {
            this.setState({
                validation: { model: ['Enter at least 1 filter or Search by Name or Number'] },
                pendingResponse: false,
            });
            return;
        }

        this.setState({ caseSearchParameters: caseSearchParametersCopy, validation: {} }, () =>
            this.loadCases(true)
        );
    };

    loadCases = async (refreshCaseCount?: boolean) => {
        this.setState({ pendingResponse: true });

        const res = await _apiClient.getCases(this.state.caseSearchParameters);
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
                cases: [],
            });
            return;
        }
        if (res.payload) {
            this.setState({ cases: res.payload, pendingResponse: false });
        }

        if (refreshCaseCount) this.refreshCaseCount();
    };

    refreshCaseCount = async () => {
        this.setState({ pendingResponse: true });
        const res = await _apiClient.getCaseCount(this.state.caseSearchParameters);
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (res.payload) {
            this.setState({ totalCaseCount: res.payload.data, pendingResponse: false });
        }
    };

    getCaseFilters = (): ICaseFilterModel[] => {
        const caseFilters: ICaseFilterModel[] = [];

        this.state.selectedDiagnosisOptions.forEach((option) => {
            caseFilters.push({
                typeId: CaseFilterType.Diagnosis.Value,
                text: option.value,
            });
        });

        this.state.selectedUSStatesOptions.forEach((option) => {
            caseFilters.push({
                typeId: CaseFilterType.State.Value,
                text: option.value,
            });
        });

        this.state.selectedJurisdictionOptions.forEach((option) => {
            caseFilters.push({
                typeId: CaseFilterType.Jurisdiction.Value,
                text: option.value,
            });
        });

        this.state.selectedPlaintiffsfirmOptions.forEach((option) => {
            caseFilters.push({
                typeId: CaseFilterType.PlaintiffsFirm.Value,
                text: option.value,
            });
        });

        this.state.selectedCaseManagerOptions.forEach((option) => {
            caseFilters.push({
                typeId: CaseFilterType.CaseManager.Value,
                text: option.value,
            });
        });

        this.state.selectedQScoreOptions.forEach((option) => {
            caseFilters.push({
                typeId: CaseFilterType.QScore.Value,
                text: option.value,
            });
        });

        return caseFilters;
    };

    clearFilter = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.resetView(ListMode.SystemDefault);
    };

    resetView = (mode?: ListMode) => {
        const caseSearchParameters = this.state.caseSearchParameters;
        caseSearchParameters.searchText = '';
        caseSearchParameters.filters = [];
        if (caseSearchParameters.queryModel) caseSearchParameters.queryModel.pageNumber = 0;
        caseSearchParameters.statusIds = [CaseStatusTypesEnum.Open.Value];
        caseSearchParameters.subStatusIds = [CaseSubStatusTypesEnum.Active.Value];

        this.setState(
            {
                caseSearchParameters: caseSearchParameters,
                selectedStatusOptions: [{ id: 1, label: 'Open - Active', value: 'Open - Active' }],
                selectedDiagnosisOptions: [],
                selectedJurisdictionOptions: [],
                selectedPlaintiffsfirmOptions: [],
                selectedCaseManagerOptions: [],
                selectedUSStatesOptions: [],
                selectedQScoreOptions: [],
            },
            () => this.loadDefaultView(mode)
        );
    };

    handleCaseSearchCriteriaChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const caseSearchParameters = this.state.caseSearchParameters;
        if (caseSearchParameters.queryModel) caseSearchParameters.queryModel.pageNumber = 0;

        const value = event.target.value;

        switch (event.target.name) {
            case 'caseNameOrNumberSearch':
                caseSearchParameters.searchText = value;
                break;
            default:
                return;
        }

        this.setState({ caseSearchParameters: caseSearchParameters });
    };

    statusOptionsSelected = (optionsList: any) => {
        const caseSearchParameters: ICaseSearchModel = this.state.caseSearchParameters;
        caseSearchParameters.statusIds = [];
        caseSearchParameters.subStatusIds = [];
        if (optionsList && optionsList.length > 0) {
            for (let i = 0; i < optionsList.length; i++) {
                const match = this.props.lookups.caseStatuses.filter(
                    (x) => x.name == optionsList![i].value
                );
                if (match.length > 0) {
                    caseSearchParameters.statusIds.push(match[0].status.id);
                    if (match[0].subStatus)
                        caseSearchParameters.subStatusIds.push(match[0].subStatus.id);
                }
            }
        }
        this.setState({
            selectedStatusOptions: optionsList,
            caseSearchParameters: caseSearchParameters,
        });
    };

    diagnosisOptionsSelected = (optionsList: any) => {
        const caseSearchParameters: ICaseSearchModel = this.state.caseSearchParameters;
        if (caseSearchParameters.filters) {
            caseSearchParameters.filters = caseSearchParameters.filters!.filter(
                (x) => x.typeId != CaseFilterType.Diagnosis.Value
            );
        } else {
            caseSearchParameters.filters = [];
        }
        if (optionsList && optionsList.length > 0) {
            for (let i = 0; i < optionsList.length; i++) {
                const match = this.state.diagnosisOptions.filter(
                    (x) => x.value == optionsList![i].value
                );
                if (match.length > 0) {
                    caseSearchParameters.filters.push({
                        typeId: CaseFilterType.Diagnosis.Value,
                        text: match[0].value,
                    });
                }
            }
        }
        this.setState({
            selectedDiagnosisOptions: optionsList,
            caseSearchParameters: caseSearchParameters,
        });
    };

    jurisdictionOptionsSelected = (optionsList: any) => {
        const paramsCopy = cloneDeep(this.state.caseSearchParameters);
        const filtersCopy = cloneDeep(paramsCopy.filters) ?? [];
        const nonJurisFilters = filtersCopy.filter(
            (x) => x.typeId !== CaseFilterType.Jurisdiction.Value
        );

        optionsList.forEach((option: any) => {
            const match = this.state.jurisdictionOptions.find((x) => x.id === option.id);
            if (match) {
                nonJurisFilters.push({
                    typeId: CaseFilterType.Jurisdiction.Value,
                    text: match.id.toString() ?? '',
                });
            }
        });

        paramsCopy.filters = nonJurisFilters;

        this.setState({
            selectedJurisdictionOptions: optionsList,
            caseSearchParameters: paramsCopy,
        });
    };

    usStatesOptionsSelected = (optionsList: any) => {
        const paramsCopy = cloneDeep(this.state.caseSearchParameters);
        const filtersCopy = cloneDeep(paramsCopy.filters) ?? [];
        const nonStateFilters = filtersCopy.filter((x) => x.typeId !== CaseFilterType.State.Value);

        optionsList.forEach((option: any) => {
            const match = this.props.lookups.usStates.find((x) => x.name === option.value);
            if (match) {
                nonStateFilters.push({
                    typeId: CaseFilterType.State.Value,
                    text: match.name ?? '',
                });
            }
        });

        paramsCopy.filters = nonStateFilters;

        this.setState({
            selectedUSStatesOptions: optionsList,
            caseSearchParameters: paramsCopy,
        });
    };

    qScoreOptionsSelected = (optionsList: any) => {
        const caseSearchParameters: ICaseSearchModel = this.state.caseSearchParameters;
        if (caseSearchParameters.filters) {
            caseSearchParameters.filters = caseSearchParameters.filters!.filter(
                (x) => x.typeId != CaseFilterType.QScore.Value
            );
        } else {
            caseSearchParameters.filters = [];
        }
        if (optionsList && optionsList.length > 0) {
            for (let i = 0; i < optionsList.length; i++) {
                const match = this.state.qScoreOptions.filter(
                    (x) => x.value == optionsList![i].value
                );
                if (match.length > 0) {
                    caseSearchParameters.filters.push({
                        typeId: CaseFilterType.QScore.Value,
                        text: match[0].value,
                    });
                }
            }
        }
        this.setState({
            selectedQScoreOptions: optionsList,
            caseSearchParameters: caseSearchParameters,
        });
    };

    plaintiffsFirmOptionsSelected = (optionsList: any) => {
        const caseSearchParameters: ICaseSearchModel = this.state.caseSearchParameters;
        if (caseSearchParameters.filters) {
            caseSearchParameters.filters = caseSearchParameters.filters!.filter(
                (x) => x.typeId != CaseFilterType.PlaintiffsFirm.Value
            );
        } else {
            caseSearchParameters.filters = [];
        }
        if (optionsList && optionsList.length > 0) {
            for (let i = 0; i < optionsList.length; i++) {
                const match = this.state.plaintiffsFirmOptions.filter(
                    (x) => x.value == optionsList![i].value
                );
                if (match.length > 0) {
                    caseSearchParameters.filters.push({
                        typeId: CaseFilterType.PlaintiffsFirm.Value,
                        text: match[0].value,
                    });
                }
            }
        }
        this.setState({
            selectedPlaintiffsfirmOptions: optionsList,
            caseSearchParameters: caseSearchParameters,
        });
    };

    caseManagerOptionsSelected = (optionsList: any) => {
        const caseSearchParameters: ICaseSearchModel = this.state.caseSearchParameters;
        if (caseSearchParameters.filters) {
            caseSearchParameters.filters = caseSearchParameters.filters!.filter(
                (x) => x.typeId != CaseFilterType.CaseManager.Value
            );
        } else {
            caseSearchParameters.filters = [];
        }
        if (optionsList && optionsList.length > 0) {
            for (let i = 0; i < optionsList.length; i++) {
                const match = this.state.caseManagerOptions.filter(
                    (x) => x.value == optionsList![i].value
                );
                if (match.length > 0) {
                    caseSearchParameters.filters.push({
                        typeId: CaseFilterType.CaseManager.Value,
                        text: match[0].value,
                    });
                }
            }
        }
        this.setState({
            selectedCaseManagerOptions: optionsList,
            caseSearchParameters: caseSearchParameters,
        });
    };

    sortData = (sortBy: string, sortDirection: string) => {
        const caseSearchParameters = this.state.caseSearchParameters;
        if (caseSearchParameters.queryModel) {
            caseSearchParameters.queryModel.sortBy = parseInt(sortBy);
            caseSearchParameters.queryModel.sortDirection =
                sortDirection == 'desc' ? SortDirections.Desc : SortDirections.Asc;
            caseSearchParameters.queryModel.pageNumber = 0;
        }
        this.setState({ caseSearchParameters: caseSearchParameters });
        this.loadCases();
    };

    sortDate = (key: string, order: string, subKey?: string) => {
        this.setState({ pendingResponse: true });

        if (this.state.cases) {
            const cases = this.state.cases.sort(Sort.compareDate(key, subKey, order));
            this.setState({ cases: cases, pendingResponse: false });
        }
    };

    formatDate = (date?: string) => {
        if (date) {
            return date.substring(0, 10);
        }
        return '';
    };

    handleEasyUpdate = (e: React.FormEvent<HTMLButtonElement>, caseGuid: string) => {
        const easyUpdateAttemptedCaseList = this.state.easyUpdateAttemptedCaseList;
        easyUpdateAttemptedCaseList.push(caseGuid);
        this.setState({
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
        });
    };

    easyUpdateAttempted = (caseGuid: string) => {
        return this.state.easyUpdateAttemptedCaseList.filter((x) => x == caseGuid).length > 0;
    };

    easyUpdateDone = (updatedCase: ICaseModel) => {
        const casesCopy = cloneDeep(this.state.cases);
        const foundIdx = casesCopy.findIndex((c) => c.guid === updatedCase.guid);

        if (foundIdx > -1) {
            casesCopy[foundIdx] = JSON.parse(JSON.stringify(updatedCase));
        }

        this.setState({ easyUpdateMode: false, easyUpdateCaseGuid: undefined, cases: casesCopy });
    };

    easyUpdateCancel = () => {
        this.setState({
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
        });
    };

    getStatusText = (status: ILookupModel, subStatus?: ILookupModel) => {
        let text = status.displayName;
        if (subStatus) text += ' - ' + subStatus.displayName;
        return text;
    };

    nextPage = async () => {
        const caseSearchParameters = this.state.caseSearchParameters;
        if (caseSearchParameters.queryModel)
            caseSearchParameters.queryModel.pageNumber =
                caseSearchParameters.queryModel.pageNumber + 1;
        this.setState({ caseSearchParameters: caseSearchParameters }, this.loadCases);
    };

    previousPage = async () => {
        const caseSearchParameters = this.state.caseSearchParameters;
        if (caseSearchParameters.queryModel)
            caseSearchParameters.queryModel.pageNumber =
                caseSearchParameters.queryModel.pageNumber - 1;
        this.setState({ caseSearchParameters: caseSearchParameters }, this.loadCases);
    };

    handleCaseSearchByNameNumberInfoClick = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.setState({ showCaseSearchByNameNumberInfo: true });
    };

    getSelectedFilters = () => {
        let selectedFilters = '';
        if (this.state.selectedStatusOptions.length > 0) {
            selectedFilters += 'STATUS:' + '\n';
            for (let i = 0; i < this.state.selectedStatusOptions.length; i++) {
                selectedFilters += this.state.selectedStatusOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedDiagnosisOptions.length > 0) {
            selectedFilters += 'DISEASE:' + '\n';
            for (let i = 0; i < this.state.selectedDiagnosisOptions.length; i++) {
                selectedFilters += this.state.selectedDiagnosisOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedJurisdictionOptions.length > 0) {
            selectedFilters += 'JURISDICTION:' + '\n';
            for (let i = 0; i < this.state.selectedJurisdictionOptions.length; i++) {
                selectedFilters += this.state.selectedJurisdictionOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedUSStatesOptions.length > 0) {
            selectedFilters += 'STATE:' + '\n';
            for (let i = 0; i < this.state.selectedUSStatesOptions.length; i++) {
                selectedFilters += this.state.selectedUSStatesOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedPlaintiffsfirmOptions.length > 0) {
            selectedFilters += 'PLAINTIFFS FIRM/NATIONAL PLAINTIFFS FIRM:' + '\n';
            for (let i = 0; i < this.state.selectedPlaintiffsfirmOptions.length; i++) {
                selectedFilters += this.state.selectedPlaintiffsfirmOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedCaseManagerOptions.length > 0) {
            selectedFilters += 'A1:' + '\n';
            for (let i = 0; i < this.state.selectedCaseManagerOptions.length; i++) {
                selectedFilters += this.state.selectedCaseManagerOptions[i].label + '\n';
            }
            selectedFilters += '\n';
            selectedFilters += 'A2:' + '\n';
            for (let i = 0; i < this.state.selectedCaseManagerOptions.length; i++) {
                selectedFilters += this.state.selectedCaseManagerOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedQScoreOptions.length > 0) {
            selectedFilters += 'SQ SCORE:' + '\n';
            for (let i = 0; i < this.state.selectedQScoreOptions.length; i++) {
                selectedFilters += this.state.selectedQScoreOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        return selectedFilters;
    };

    selectLabel = (e: any, label: string) => {
        if (!e.value || e.value.length === 0) {
            return <span className="unselectClass css-1v99tuv">--{label}--</span>;
        }

        if (e.value.length === 1) {
            return <span className="css-1v99tuv">{e.value[0].label}</span>;
        } else {
            return <span className="css-1v99tuv">{e.value.length} selected</span>;
        }
    };

    updateData = (settingJson: string, isDefault?: boolean) => {
        const currentCaseSearchParameterString = JSON.stringify(this.state.caseSearchParameters);
        if (currentCaseSearchParameterString.trim() === settingJson.trim()) return;

        const caseSearchParameters: ICaseSearchModel = JSON.parse(settingJson);

        const selectedStatusOptions: IMultiSelectOptions[] = [];
        let selectedDiagnosisOptions: IMultiSelectOptions[] = [];
        let selectedUSStatesOptions: IMultiSelectOptions[] = [];
        let selectedJurisdictionOptions: IMultiSelectOptions[] = [];
        const selectedPlaintiffsfirmOptions: IMultiSelectOptions[] = [];
        const selectedCaseManagerOptions: IMultiSelectOptions[] = [];
        const selectedQScoreOptions: IMultiSelectOptions[] = [];

        if (caseSearchParameters.statusIds) {
            for (let i = 0; i < caseSearchParameters.statusIds.length; i++) {
                const statusId = caseSearchParameters.statusIds[i];
                let match = this.props.lookups.caseStatuses.filter(
                    (x) => x.status.id === statusId && (!x.subStatus || x.subStatus.id === 0)
                );
                if (match.length > 0 && !selectedStatusOptions.find((x) => x.id === match[0].id))
                    selectedStatusOptions.push({
                        label: match[0].name!,
                        value: match[0].name!,
                        id: match[0].id,
                    }); // Use id to hold the actual ICaseStatusLookupModel.Id unlike the other filters

                if (caseSearchParameters.subStatusIds) {
                    for (let j = 0; j < caseSearchParameters.subStatusIds.length; j++) {
                        const subStatusId = caseSearchParameters.subStatusIds[j];
                        match = this.props.lookups.caseStatuses.filter(
                            (x) =>
                                x.status.id === statusId &&
                                x.subStatus &&
                                x.subStatus.id === subStatusId
                        );
                        if (
                            match.length > 0 &&
                            !selectedStatusOptions.find((x) => x.id === match[0].id)
                        )
                            selectedStatusOptions.push({
                                label: match[0].name!,
                                value: match[0].name!,
                                id: match[0].id,
                            }); // Use id to hold the actual ICaseStatusLookupModel.Id unlike the other filters
                    }
                }
            }
        }

        if (caseSearchParameters.filters) {
            const caseFiltersCopy = cloneDeep(caseSearchParameters.filters);

            selectedDiagnosisOptions = caseFiltersCopy
                .filter((x) => x.typeId === CaseFilterType.Diagnosis.Value)
                .filter(
                    (opt) =>
                        !!this.state.diagnosisOptions.find((option) => option.value === opt.text)
                )
                .map(
                    (opt) =>
                        this.state.diagnosisOptions.find((option) => option.value === opt.text)!
                );

            selectedUSStatesOptions = caseFiltersCopy
                .filter((x) => x.typeId === CaseFilterType.State.Value)
                .filter((state) => !!this.state.usStateOptions.find((x) => x.value === state.text))
                .map((state) => this.state.usStateOptions.find((x) => x.value === state.text)!);

            selectedJurisdictionOptions = caseFiltersCopy
                .filter((x) => x.typeId === CaseFilterType.Jurisdiction.Value)
                .filter(
                    (juri) =>
                        !!this.state.jurisdictionOptions.find((x) => x.id === parseInt(juri.text))
                )
                .map(
                    (juri) =>
                        this.state.jurisdictionOptions.find((x) => x.id === parseInt(juri.text))!
                );

            const plaintiffFirms = caseFiltersCopy.filter(
                (x) => x.typeId == CaseFilterType.PlaintiffsFirm.Value
            );
            if (plaintiffFirms && plaintiffFirms.length > 0) {
                for (let i = 0; i < plaintiffFirms.length; i++) {
                    const match = this.state.plaintiffsFirmOptions.filter(
                        (x) => x.value == plaintiffFirms![i].text
                    );
                    if (match.length > 0) {
                        selectedPlaintiffsfirmOptions.push(match[0]);
                    }
                }
            }

            const caseManagers = caseFiltersCopy.filter(
                (x) => x.typeId == CaseFilterType.CaseManager.Value
            );
            if (caseManagers && caseManagers.length > 0) {
                for (let i = 0; i < caseManagers.length; i++) {
                    const match = this.state.caseManagerOptions.filter(
                        (x) => x.value == caseManagers![i].text
                    );
                    if (match.length > 0) {
                        selectedCaseManagerOptions.push(match[0]);
                    }
                }
            }

            const qScores = caseFiltersCopy.filter((x) => x.typeId == CaseFilterType.QScore.Value);
            if (qScores && qScores.length > 0) {
                for (let i = 0; i < qScores.length; i++) {
                    const match = this.state.qScoreOptions.filter(
                        (x) => x.value == qScores![i].text
                    );
                    if (match.length > 0) {
                        selectedQScoreOptions.push(match[0]);
                    }
                }
            }
        }

        this.setState(
            {
                selectedStatusOptions: selectedStatusOptions,
                selectedDiagnosisOptions: selectedDiagnosisOptions,
                selectedUSStatesOptions: selectedUSStatesOptions,
                selectedJurisdictionOptions: selectedJurisdictionOptions,
                selectedPlaintiffsfirmOptions: selectedPlaintiffsfirmOptions,
                selectedCaseManagerOptions: selectedCaseManagerOptions,
                selectedQScoreOptions: selectedQScoreOptions,
                userDefaultSettingsJson: isDefault
                    ? settingJson
                    : this.state.userDefaultSettingsJson,
            },
            () => this.runSearch(null)
        );
    };

    handleFavoriteSelected = (settingJson?: string) => {
        let selectedFilters = '';
        if (settingJson) {
            const caseSearchParameters: ICaseSearchModel = JSON.parse(settingJson);

            if (caseSearchParameters.statusIds) {
                const selectedStatusOptions: IMultiSelectOptions[] = [];
                for (let i = 0; i < caseSearchParameters.statusIds.length; i++) {
                    const statusId = caseSearchParameters.statusIds[i];
                    let match = this.props.lookups.caseStatuses.filter(
                        (x) => x.status.id === statusId && (!x.subStatus || x.subStatus.id === 0)
                    );
                    if (
                        match.length > 0 &&
                        !selectedStatusOptions.find((x) => x.id === match[0].id)
                    )
                        selectedStatusOptions.push({
                            label: match[0].name!,
                            value: match[0].name!,
                            id: match[0].id,
                        }); // Use id to hold the actual ICaseStatusLookupModel.Id unlike the other filters

                    if (caseSearchParameters.subStatusIds) {
                        for (let j = 0; j < caseSearchParameters.subStatusIds.length; j++) {
                            const subStatusId = caseSearchParameters.subStatusIds[j];
                            match = this.props.lookups.caseStatuses.filter(
                                (x) =>
                                    x.status.id === statusId &&
                                    x.subStatus &&
                                    x.subStatus.id === subStatusId
                            );
                            if (
                                match.length > 0 &&
                                !selectedStatusOptions.find((x) => x.id === match[0].id)
                            )
                                selectedStatusOptions.push({
                                    label: match[0].name!,
                                    value: match[0].name!,
                                    id: match[0].id,
                                }); // Use id to hold the actual ICaseStatusLookupModel.Id unlike the other filters
                        }
                    }
                }
                if (selectedStatusOptions.length > 0) {
                    selectedFilters += 'STATUS:' + '\n';
                    for (let i = 0; i < this.state.selectedStatusOptions.length; i++) {
                        selectedFilters += this.state.selectedStatusOptions[i].label + '\n';
                    }
                    selectedFilters += '\n';
                }
            }

            if (caseSearchParameters.filters) {
                const caseFilters: ICaseFilterModel[] = caseSearchParameters.filters;

                const diagnosis = caseFilters.filter(
                    (x) => x.typeId === CaseFilterType.Diagnosis.Value
                );
                if (diagnosis && diagnosis.length > 0) {
                    selectedFilters += 'DISEASE:' + '\n';
                    for (let i = 0; i < diagnosis.length; i++) {
                        const match = this.state.diagnosisOptions.filter(
                            (x) => x.value == diagnosis![i].text
                        );
                        if (match.length > 0) {
                            selectedFilters += match[0].label + '\n';
                        }
                    }
                    selectedFilters += '\n';
                }

                const usStates = caseFilters.filter((x) => x.typeId === CaseFilterType.State.Value);
                if (usStates && usStates.length > 0) {
                    selectedFilters += 'STATE:' + '\n';
                    for (let i = 0; i < usStates.length; i++) {
                        const match = this.state.usStateOptions.filter(
                            (x) => x.value == usStates![i].text
                        );
                        if (match.length > 0) {
                            selectedFilters += match[0].label + '\n';
                        }
                    }
                    selectedFilters += '\n';
                }

                const jurisdictions = caseFilters.filter(
                    (x) => x.typeId === CaseFilterType.Jurisdiction.Value
                );
                if (jurisdictions && jurisdictions.length > 0) {
                    selectedFilters += 'JURISDICTION:' + '\n';
                    for (let i = 0; i < jurisdictions.length; i++) {
                        const match = this.state.jurisdictionOptions.filter(
                            (x) => x.value === jurisdictions![i].text
                        );
                        if (match.length > 0) {
                            selectedFilters += match[0].label + '\n';
                        }
                    }
                    selectedFilters += '\n';
                }

                const plaintiffFirms = caseFilters.filter(
                    (x) => x.typeId === CaseFilterType.PlaintiffsFirm.Value
                );
                if (plaintiffFirms && plaintiffFirms.length > 0) {
                    selectedFilters += 'PLAINTIFFS FIRM/NATIONAL PLAINTIFFS FIRM:' + '\n';
                    for (let i = 0; i < plaintiffFirms.length; i++) {
                        const match = this.state.plaintiffsFirmOptions.filter(
                            (x) => x.value === plaintiffFirms![i].text
                        );
                        if (match.length > 0) {
                            selectedFilters += match[0].label + '\n';
                        }
                    }
                    selectedFilters += '\n';
                }

                const caseManagers = caseFilters.filter(
                    (x) => x.typeId === CaseFilterType.CaseManager.Value
                );
                if (caseManagers && caseManagers.length > 0) {
                    selectedFilters += 'A1:' + '\n';
                    for (let i = 0; i < caseManagers.length; i++) {
                        const match = this.state.caseManagerOptions.filter(
                            (x) => x.value === caseManagers![i].text
                        );
                        if (match.length > 0) {
                            selectedFilters += match[0].label + '\n';
                        }
                    }
                    selectedFilters += '\n';
                }

                const qScores = caseFilters.filter((x) => x.typeId === CaseFilterType.QScore.Value);
                if (qScores && qScores.length > 0) selectedFilters += 'SQ SCORE:' + '\n';
                for (let i = 0; i < qScores.length; i++) {
                    const match = this.state.qScoreOptions.filter(
                        (x) => x.value === qScores![i].text
                    );
                    if (match.length > 0) {
                        selectedFilters += match[0].label + '\n';
                    }
                }
                selectedFilters += '\n';
            }
        }

        this.setState({ currentFavoriteDetailsString: selectedFilters });
    };

    applyFavoriteSelected = (settingJson?: string) => {
        if (settingJson) {
            this.updateData(settingJson);
        }
    };

    handleDefaultFavoriteChange = (settingJson?: string) => {
        this.props.reloadFavorites();
        this.setState({ userDefaultSettingsJson: settingJson });
    };

    handleFavoriteValidateFilters = () => {
        const hasAtleastOneFilter = this.checkForAtLeastOneFilter(this.state.caseSearchParameters);

        if (!hasAtleastOneFilter) {
            this.setState({
                validation: {
                    favorites: ['Enter at least 1 filter'],
                },
                pendingResponse: false,
            });
            return true;
        }

        this.setState({
            validation: {
                favorites: [],
            },
            pendingResponse: false,
        });

        return false;
    };

    render() {
        const showEasyUpdateColumn = Authorization.isAuthorizedToRoute(
            LocalRoutes.CaseEasyUpdate,
            this.props.user
        );

        const showCasePlanColumn =
            this.state.enablePlanningExecution &&
            (Authorization.userHasRight(UserRightsEnum.FullPlanningAndExecution, this.props.user) ||
                Authorization.userHasRight(
                    UserRightsEnum.ReadOnlyPlanningAndExecution,
                    this.props.user
                ) ||
                Authorization.userHasRight(
                    UserRightsEnum.FirmLevelPlanningAndExecution,
                    this.props.user
                ));

        return (
            <div>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, this.props.user) && (
                    <CaseEasyUpdate
                        documentTitle={'Case List | Easy Update'}
                        user={this.props.user}
                        caseGuid={this.state.easyUpdateCaseGuid}
                        onComplete={this.easyUpdateDone}
                        onCancel={this.easyUpdateCancel}
                        hidden={!this.state.easyUpdateMode}
                    />
                )}
                {this.state.easyUpdateMode ? null : this.state.pendingResponse ? (
                    <Loader />
                ) : (
                    <div>
                        <div className="row mb-1 me-2">
                            <h1 className="col-sm-4">Case List</h1>

                            <form className="float-end no-padding col-sm-8 d-flex flex-row-reverse align-items-center">
                                {/* System Admin cannot create new case. Only National Counsel User can create a new case */}
                                {this.state.allowAddNew &&
                                Authorization.userHasRight(
                                    UserRightsEnum.AddNewCase,
                                    this.props.user
                                ) ? (
                                    <Link
                                        to={LocalRoutes.CaseFileNew}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <span className="btn-green btn float-end btn-icon">
                                            <i className="fal fa-lg fa-plus color-white" />
                                        </span>
                                    </Link>
                                ) : null}
                                <button
                                    type="button"
                                    className="btn btn-gray btn-icon float-end"
                                    onClick={this.clearFilter}
                                >
                                    <i className="fal fa-times color-white" />
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-black btn-icon float-end"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        this.runSearch(e);
                                    }}
                                >
                                    <i className="fal fa-search color-white" />
                                </button>
                                <input
                                    className="form-control horizontal-margin-right-sm"
                                    // style={{ width: "50%" }}
                                    type="text"
                                    name="caseNameOrNumberSearch"
                                    placeholder="Search by Name or Number"
                                    value={this.state.caseSearchParameters.searchText}
                                    onChange={this.handleCaseSearchCriteriaChange}
                                />
                                <span>
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Search by Name or Number"
                                        helpText="You can search by Injured Party First Name, Last Name or case Number. For an exact match, you can search using the format: Last Name, First Name (with a space after the last name) ."
                                        additionalStyleClassNames="float-end"
                                    />
                                </span>
                                <Favorite
                                    user={this.props.user}
                                    type={{ id: FavoriteTypesEnum.CaseList }}
                                    currentFavoriteSettingJson={JSON.stringify(
                                        this.state.caseSearchParameters
                                    )}
                                    currentFavoriteDetailsString={
                                        this.state.currentFavoriteDetailsString
                                    }
                                    handleFavoriteSelected={this.handleFavoriteSelected}
                                    applyFavoriteSelected={this.applyFavoriteSelected}
                                    handleDefaultFavoriteChange={this.handleDefaultFavoriteChange}
                                    onValidateFilters={this.handleFavoriteValidateFilters}
                                />
                            </form>
                        </div>
                        <span className="text-danger">{this.state.validation.model}</span>
                        <span className="text-danger">{this.state.validation.favorites}</span>
                        {!this.state.isSystemAdmin ||
                        (this.state.caseSearchParameters.organizationGuid &&
                            this.state.caseSearchParameters.organizationGuid.length > 0) ? (
                            <div>
                                <div className="mt-3">
                                    <div className="row no-padding">
                                        <div className="col flex-grow-0 case-profile-item-div">
                                            <span className="text-gray text-lg">Filter</span>
                                        </div>
                                        <div className="col horizontal-padding">
                                            <CheckmateNSelect
                                                options={this.state.caseStatusOptions}
                                                value={this.state.selectedStatusOptions}
                                                onChange={this.statusOptionsSelected}
                                                placeholder="-- Status --"
                                            />
                                        </div>
                                        <div className="col horizontal-padding">
                                            <CheckmateNSelect
                                                options={this.state.diagnosisOptions}
                                                value={this.state.selectedDiagnosisOptions}
                                                onChange={this.diagnosisOptionsSelected}
                                                placeholder="-- Disease --"
                                            />
                                        </div>
                                        <div className="col horizontal-padding">
                                            <CheckmateNSelect
                                                options={this.state.jurisdictionOptions}
                                                value={this.state.selectedJurisdictionOptions}
                                                onChange={this.jurisdictionOptionsSelected}
                                                placeholder="-- Jurisdiction --"
                                            />
                                        </div>
                                        <div className="col horizontal-padding">
                                            <CheckmateNSelect
                                                options={this.state.usStateOptions}
                                                value={this.state.selectedUSStatesOptions}
                                                onChange={this.usStatesOptionsSelected}
                                                placeholder="-- State --"
                                            />
                                        </div>
                                        <div className="col horizontal-padding">
                                            <CheckmateNSelect
                                                options={this.state.plaintiffsFirmOptions}
                                                value={this.state.selectedPlaintiffsfirmOptions}
                                                onChange={this.plaintiffsFirmOptionsSelected}
                                                placeholder="-- Plaintiffs Firm --"
                                            />
                                        </div>
                                        <div className="col horizontal-padding">
                                            <CheckmateNSelect
                                                options={this.state.caseManagerOptions}
                                                value={this.state.selectedCaseManagerOptions}
                                                onChange={this.caseManagerOptionsSelected}
                                                placeholder="-- A1/A2 --"
                                            />
                                        </div>
                                        <div className="col horizontal-padding">
                                            <CheckmateNSelect
                                                options={this.state.qScoreOptions}
                                                value={this.state.selectedQScoreOptions}
                                                onChange={this.qScoreOptionsSelected}
                                                placeholder="-- SQ Score --"
                                            />
                                        </div>
                                        <div
                                            className="col flex-grow-0"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                display: 'inline-block',
                                                marginRight: '47px',
                                            }}
                                        >
                                            <Help
                                                type={ApplicationHelpType.Check}
                                                title="Selected Filters"
                                                helpText={this.getSelectedFilters()}
                                                additionalStyleClassNames="pull-left"
                                            />
                                            <button
                                                className="btn btn-black btn-icon pull-left"
                                                onClick={(
                                                    e: React.MouseEvent<HTMLButtonElement>
                                                ) => {
                                                    this.runSearch(e);
                                                }}
                                            >
                                                <i className="fal fa-filter color-white" />
                                            </button>
                                            {/* <div className="pull-left horizontal-padding" style={{ marginRight: "30px" }}> */}
                                            <button
                                                className="btn btn-gray btn-icon"
                                                onClick={this.clearFilter}
                                            >
                                                <i className="fal fa-times color-white" />
                                            </button>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                                <Table responsive size="sm" className="mt-3">
                                    <thead>
                                        <tr style={{ verticalAlign: 'middle' }}>
                                            <SortableHeader
                                                headerText="Case Name"
                                                sortKey={SortColumns.CaseName.toString()}
                                                onSort={this.sortData}
                                                thClassName="col-sm-2"
                                                noWrap
                                            />
                                            <SortableHeader
                                                headerText="Case Number"
                                                sortKey={SortColumns.CaseNumber.toString()}
                                                onSort={this.sortData}
                                                thClassName="col-sm-1 text-nowrap"
                                                noWrap
                                            />
                                            <SortableHeader
                                                headerComponent={
                                                    <button className="btn-no-bg" title="Priority">
                                                        <i className="fal fa-exclamation-circle color-yellow" />
                                                    </button>
                                                }
                                                sortKey={SortColumns.Priority.toString()}
                                                onSort={this.sortData}
                                                thClassName="text-center"
                                            />
                                            {Authorization.userHasRight(
                                                UserRightsEnum.ViewPackageSettlement,
                                                this.props.user
                                            ) && (
                                                <SortableHeader
                                                    headerComponent={
                                                        <button
                                                            className="btn-no-bg"
                                                            title="Package Deal"
                                                        >
                                                            <i className="fal fa-box-usd" />
                                                        </button>
                                                    }
                                                    sortKey={SortColumns.PackageSettlement.toString()}
                                                    onSort={this.sortData}
                                                    thClassName="text-center"
                                                />
                                            )}
                                            <SortableHeader
                                                headerText="Status"
                                                sortKey={SortColumns.CaseStatus.toString()}
                                                onSort={this.sortData}
                                            />
                                            <SortableHeader
                                                headerText="Disease"
                                                sortKey={SortColumns.Diagnosis.toString()}
                                                onSort={this.sortData}
                                            />
                                            <SortableHeader
                                                headerText="State"
                                                sortKey={SortColumns.State.toString()}
                                                onSort={this.sortData}
                                            />
                                            <SortableHeader
                                                headerText="SQ Score"
                                                sortKey={SortColumns.QScore.toString()}
                                                onSort={this.sortData}
                                                noWrap
                                            />
                                            {Authorization.userHasRight(
                                                UserRightsEnum.ViewThreatScore,
                                                this.props.user
                                            ) && (
                                                <SortableHeader
                                                    headerText="Threat Score"
                                                    sortKey={SortColumns.ThreatScore.toString()}
                                                    onSort={this.sortData}
                                                    noWrap
                                                />
                                            )}
                                            <SortableHeader
                                                headerText="Trial Date"
                                                sortKey={SortColumns.TrialDate.toString()}
                                                onSort={this.sortData}
                                                noWrap
                                            />
                                            {showEasyUpdateColumn && <th />}
                                            {/* Only checking for specific Planning & Execution Rights. Checking whether the logged in user has access to the case is done prior to here */}
                                            {showCasePlanColumn && (
                                                <th className="text-center">Case Plan</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.cases.map((caseItem: ICaseModel, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {Authorization.isAuthorizedToRoute(
                                                            LocalRoutes.CaseFile,
                                                            this.props.user
                                                        ) ? (
                                                            <Link
                                                                to={LocalRoutes.CaseFile.replace(
                                                                    ':guid',
                                                                    caseItem!.guid!
                                                                )}
                                                                target="_blank"
                                                            >
                                                                {caseItem.caseName}
                                                            </Link>
                                                        ) : (
                                                            caseItem.caseName
                                                        )}
                                                    </td>
                                                    <td>{caseItem!.caseNumber}</td>
                                                    <td>
                                                        {caseItem.priority ? (
                                                            <i className="fal fa-exclamation-circle color-yellow" />
                                                        ) : null}
                                                    </td>
                                                    {Authorization.userHasRight(
                                                        UserRightsEnum.ViewPackageSettlement,
                                                        this.props.user
                                                    ) ? (
                                                        <td>
                                                            {caseItem.packageSettlement &&
                                                            caseItem.packageSettlement!.guid ? (
                                                                <Link
                                                                    target="_blank"
                                                                    to={LocalRoutes.PackageSettlementDetails.replace(
                                                                        ':guid',
                                                                        caseItem!.packageSettlement!
                                                                            .guid!
                                                                    )}
                                                                >
                                                                    <i className="fal fa-box-usd color-black" />
                                                                </Link>
                                                            ) : null}
                                                        </td>
                                                    ) : null}
                                                    <td>
                                                        {caseItem!.caseStatus ? (
                                                            <span>
                                                                {caseItem!.caseStatus!.displayName}
                                                            </span>
                                                        ) : null}
                                                    </td>
                                                    <td>{caseItem!.diagnosis}</td>
                                                    <td>{caseItem!.state}</td>
                                                    <td>{caseItem!.qScore}</td>
                                                    {Authorization.userHasRight(
                                                        UserRightsEnum.ViewThreatScore,
                                                        this.props.user
                                                    ) ? (
                                                        <td>{caseItem!.threatScore}</td>
                                                    ) : null}
                                                    <td>{this.formatDate(caseItem.trialDate)}</td>
                                                    {showEasyUpdateColumn ? (
                                                        <td className="text-center">
                                                            <button
                                                                className="btn-no-bg"
                                                                onClick={(
                                                                    e: React.MouseEvent<HTMLButtonElement>
                                                                ) => {
                                                                    this.handleEasyUpdate(
                                                                        e,
                                                                        caseItem.guid!
                                                                    );
                                                                }}
                                                            >
                                                                <i
                                                                    className={`fal fa-edit ${
                                                                        this.easyUpdateAttempted(
                                                                            caseItem.guid!
                                                                        )
                                                                            ? 'color-gray'
                                                                            : 'color-black'
                                                                    } `}
                                                                />
                                                            </button>
                                                        </td>
                                                    ) : null}
                                                    <td className="font-italic">
                                                        {/* Only checking for specific Planning & Execution Rights. Checking whether the logged in user has access to the case is done prior to here */}
                                                        {this.state.enablePlanningExecution &&
                                                        caseItem.casePlan &&
                                                        (Authorization.userHasRight(
                                                            UserRightsEnum.FullPlanningAndExecution,
                                                            this.props.user
                                                        ) ||
                                                            Authorization.userHasRight(
                                                                UserRightsEnum.ReadOnlyPlanningAndExecution,
                                                                this.props.user
                                                            ) ||
                                                            Authorization.userHasRight(
                                                                UserRightsEnum.FirmLevelPlanningAndExecution,
                                                                this.props.user
                                                            )) ? (
                                                            <Link
                                                                to={LocalRoutes.CasePlan.replace(
                                                                    ':guid',
                                                                    caseItem!.guid!
                                                                )}
                                                            >
                                                                <i className="fal fa-ballot-check color-black" />
                                                            </Link>
                                                        ) : null}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>

                                {this.state.caseSearchParameters.queryModel!.pageNumber + 1 <
                                Math.ceil(this.state.totalCaseCount / PageSize) ? (
                                    <button
                                        className="btn btn-orange float-end text-gray horizontal-margin"
                                        onClick={this.nextPage}
                                    >
                                        Next
                                    </button>
                                ) : null}
                                {this.state.caseSearchParameters.queryModel!.pageNumber > 0 ? (
                                    <button
                                        className="btn btn-orange float-end text-gray horizontal-margin"
                                        onClick={this.previousPage}
                                    >
                                        Previous
                                    </button>
                                ) : null}
                                {Math.ceil(this.state.totalCaseCount / PageSize) > 0 ? (
                                    <span
                                        className="float-end text-gray"
                                        style={{ paddingTop: '10px', paddingRight: '10px' }}
                                    >
                                        Page{' '}
                                        {this.state.caseSearchParameters.queryModel!.pageNumber + 1}{' '}
                                        of {Math.ceil(this.state.totalCaseCount / PageSize)}
                                        {UIHelper.buildPageNumberDetailsString(
                                            this.state.totalCaseCount,
                                            PageSize,
                                            this.state.caseSearchParameters.queryModel
                                        )}{' '}
                                    </span>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        );
    }
}
