import { ApplicationHelpType } from '../../utilities/Constants';
import { Help } from '../shared/Help';
import { ReactElement } from 'react';

interface IFieldControlWrapperProps {
    component: ReactElement;
    displayAsCondensed: boolean;
    hideLabel?: boolean;
    identifier: string;
    labelText: string;
    help?: { title: string; text: string };
}

export function FieldControlWrapper({
    component,
    displayAsCondensed,
    hideLabel,
    identifier,
    labelText,
    help,
}: IFieldControlWrapperProps) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                alignItems: 'center',
                marginBottom: displayAsCondensed ? 0 : '8px',
                width: '100%',
            }}
        >
            {!hideLabel && labelText && (
                <span data-identifier={identifier}>
                    <strong>{labelText}</strong>
                    {help && (
                        <Help
                            type={ApplicationHelpType.Info}
                            title={help.title}
                            helpText={help.text}
                        />
                    )}
                </span>
            )}
            <span style={{ flexGrow: 1 }}>{component}</span>
        </div>
    );
}
