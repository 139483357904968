import * as React from 'react';

import { ICaseAssertionModel } from '../../../interfaces/ICase';
import { IValidation } from '../../../interfaces/IError';
import { Modal } from 'react-bootstrap';

export interface ICaseNarrativeDialogProps {
    assertion: ICaseAssertionModel;
    title: string;
    validation: IValidation;
    onSubmit: (narrativeValue: string) => void;
    onCancel: () => void;
}

export function CaseNarrativeDialog(props: ICaseNarrativeDialogProps) {
    const [narrativeValue, setNarrativeValue] = React.useState<string>(props.assertion?.text ?? '');
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNarrativeValue(e.target.value);
    };

    const handleOkClick = () => {
        props.onSubmit(narrativeValue);
    };

    const handleClearClick = () => {
        setNarrativeValue('');
        textareaRef.current?.focus();
    };

    const handleCancelClick = () => {
        props.onCancel();
    };

    if (!props.assertion) return null;

    return (
        <Modal centered show onHide={handleCancelClick} backdrop={false}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span className="text-danger">{props.validation.model}</span>
                </div>
                <div>
                    <textarea
                        className="form-control"
                        value={narrativeValue}
                        name={props.assertion?.assertionDescriptor?.guid}
                        rows={10}
                        onChange={handleTextAreaChange}
                        ref={textareaRef}
                    />
                </div>
                <div className="dialog-btn-div margin-top-sm">
                    <button className="btn btn-orange float-end" onClick={handleOkClick}>
                        OK
                    </button>
                    <button className="btn btn-default float-end" onClick={handleCancelClick}>
                        Cancel
                    </button>
                    <button className="btn btn-default float-end" onClick={handleClearClick}>
                        Clear
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
