import * as React from 'react';

import { Button, Modal, Stack } from 'react-bootstrap';
import { DateTypesEnum, ExportOptions } from '../../utilities/Constants';
import {
    ICustomReportOption,
    IReportOptions,
    IReportParametersModel,
    IReportTypeModel,
} from '../../interfaces/Report/IReport';
import { cloneDeep, isEmpty } from 'lodash';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import Common from '../../stores/Common';
import { DateRangePicker } from '../../pages/query2/DateRangePicker';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { DocumentTitle } from '../shared/DocumentTitle';
import { ExportFileButton } from '../shared/ExportFileButton';
import Html5ReportViewer from './Html5ReportViewer';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IViewExportReportProps {
    user: IUserModel;
    id?: number;
}

interface IViewExportReportState {
    pendingResponse: boolean;
    validation: IValidation;
    reportTypeDetail: IReportTypeModel;
    reportParameters: IReportParametersModel;
    initReportParameters: IReportParametersModel;
    typeValue?: string;
    addEditComment: boolean;
    showSettingsModal: boolean;
    refreshReport: boolean;
    renderReportViewer?: boolean;
}

export class ViewExportReport extends React.Component<
    IViewExportReportProps,
    IViewExportReportState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: true,
            validation: {},
            reportTypeDetail: { id: 0 },
            reportParameters: {},
            initReportParameters: {},
            addEditComment: false,
            showSettingsModal: true,
            refreshReport: false,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.ReportDetail, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        if (this.props.id) {
            this.loadReportDetail(this.props.id);
        } else {
            window.location.assign('/');
        }
    }

    loadReportDetail = async (typeId: number) => {
        const response = await _apiClient.getReportTypeDetail(typeId);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.httpResponse.status == 403) {
            window.location.assign(LocalRoutes.AccessDenied);
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        if (response.payload) {
            const reportDetails = response.payload ?? { id: 0 };

            const getCustomOptionsFromAvailableOptions = (
                availableOptions: IReportOptions
            ): ICustomReportOption[] => {
                const customOptions = [];

                if (availableOptions.includeNotes) {
                    customOptions.push(ExportOptions.IncludeSignificantChanges);
                }

                if (availableOptions.includeTasks) {
                    customOptions.push(ExportOptions.IncludeTactics);
                }

                return customOptions;
            };

            reportDetails.availableReportOptions = {
                ...reportDetails.availableReportOptions,
                customOptions: getCustomOptionsFromAvailableOptions(
                    reportDetails.availableReportOptions ?? {}
                ),
            };

            const reportParameters: IReportParametersModel = {
                reportType: { id: typeId },
            };

            this.setState(
                {
                    reportTypeDetail: reportDetails,
                    pendingResponse: false,
                    reportParameters: reportParameters,
                    initReportParameters: reportParameters,
                },
                this.autoViewDownloadReport
            );
        }
    };

    handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const reportParametersCopy = cloneDeep(this.state.reportParameters);
        reportParametersCopy.commentText = event.target.value;
        this.setState({ reportParameters: reportParametersCopy });
    };

    cancelSettingsModal = () => {
        this.setState({
            reportParameters: JSON.parse(JSON.stringify(this.state.initReportParameters)),
            showSettingsModal: false,
            validation: {},
            refreshReport: false,
        });
    };

    refreshReport = () => {
        if (
            this.state.reportParameters.date &&
            ((this.state.reportParameters.date.startDate &&
                !this.state.reportParameters.date.endDate) ||
                (!this.state.reportParameters.date.startDate &&
                    this.state.reportParameters.date.endDate))
        ) {
            const validation = this.state.validation;
            validation.dateRange = [DisplayMessages.BothStartAndEndDateRequired];
            this.setState({ validation: validation });
            return;
        }

        this.setState(
            {
                initReportParameters: cloneDeep(this.state.reportParameters),
                refreshReport: false,
                showSettingsModal: false,
                renderReportViewer: true,
            },
            () => {
                // This is to toggle the flag so the child component detects a change and refreshes
                this.setState({ refreshReport: true });
            }
        );
    };

    autoViewDownloadReport = () => {
        if (
            this.state.reportTypeDetail.viewInUI &&
            !isEmpty(this.state.reportTypeDetail.availableReportOptions)
        )
            this.refreshReport();
        else if (
            !this.state.reportTypeDetail.viewInUI &&
            !this.state.reportTypeDetail.allowComments &&
            !this.state.reportTypeDetail.availableReportOptions
        )
            this.setState({ showSettingsModal: true });
    };

    handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>, dateType: number) => {
        const reportParameters = this.state.reportParameters;
        if (!reportParameters.date) reportParameters.date = { type: 0 };
        if (dateType == DateTypesEnum.StartDate) {
            reportParameters.date!.startDate = event.target.value;
            this.setState({ reportParameters: reportParameters });
        } else if (dateType == DateTypesEnum.EndDate) {
            reportParameters.date!.endDate = event.target.value;
            this.setState({ reportParameters: reportParameters });
        }
    };

    handleCheckedChange(event: React.ChangeEvent<HTMLInputElement>) {
        const reportParameters = this.state.reportParameters;
        if (!reportParameters.options) reportParameters.options = {};

        switch (event.target.name) {
            case 'chkIncludePastProjected':
                reportParameters.options.includePastProjected = event.target.checked;
                break;
            case 'chkIncludeNotes':
                reportParameters.options.includeNotes = event.target.checked;
                break;
            case 'chkIncludeTasks':
                reportParameters.options.includeTasks = event.target.checked;
                break;
            case 'chkSaveReport':
                reportParameters.saveReport = event.target.checked;
                break;
            default:
        }

        this.setState({ reportParameters: reportParameters });
    }

    generateAndDownloadReport = async () => {
        const validation: IValidation = {};
        if (
            this.state.reportTypeDetail.availableReportOptions?.includeDateRange &&
            !(
                this.state.reportParameters &&
                this.state.reportParameters.date &&
                ((this.state.reportParameters.date.startDate &&
                    Common.isValidDate(this.state.reportParameters.date.startDate)) ||
                    (this.state.reportParameters.date.endDate &&
                        Common.isValidDate(this.state.reportParameters.date.endDate)))
            )
        ) {
            validation.model = ['Enter a valid date range'];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true, validation: validation });

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.ExportReport, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                const reportParameters = this.state.reportParameters;
                reportParameters.commentText = '';
                this.setState({
                    pendingResponse: false,
                    reportParameters: reportParameters,
                    addEditComment: false,
                    showSettingsModal: false,
                });

                let fileName = 'Report.pdf';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                const validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        xhr.send(JSON.stringify(this.state.reportParameters));
    };

    handleExportError = () => {
        const validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        // const availableOptions = this.state.reportTypeDetail.availableReportOptions ?? {};

        // const {
        //     includeDateRange,
        //     includeNotes,
        //     includePastProjected,
        //     includeStrategyNotes,
        //     includeTasks,
        // } = availableOptions;

        // const hasFilters =
        //     includeDateRange ||
        //     includeNotes ||
        //     includePastProjected ||
        //     includeStrategyNotes ||
        //     includeTasks;

        const defaultShowExport = !this.state.reportTypeDetail.viewInUI;
        const showExportButton = !!this.props.id;

        return (
            <div className="mx-2">
                <DocumentTitle title={this.state.reportTypeDetail.displayName} />

                <Stack
                    direction="horizontal"
                    style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <h1>{this.state.reportTypeDetail.displayName}</h1>
                    <Stack
                        direction="horizontal"
                        style={{
                            alignItems: 'center',
                        }}
                        gap={2}
                    >
                        <CurrentFilters
                            availableReportOptions={
                                this.state.reportTypeDetail.availableReportOptions
                            }
                            reportParameters={this.state.reportParameters}
                        />
                        <div>
                            {showExportButton && this.state.reportTypeDetail.viewInUI && (
                                <ExportFileButton
                                    buttonText="PDF"
                                    cssClasses="btn btn-no-bg text-gray"
                                    defaultOpenModal={defaultShowExport}
                                    reportParameters={this.state.reportParameters}
                                    reportType={{
                                        id: this.props.id!,
                                        availableReportOptions:
                                            this.state.reportTypeDetail.availableReportOptions,
                                    }}
                                    url={'/' + ApiRoutes.ExportReport}
                                />
                            )}

                            {showExportButton && !this.state.reportTypeDetail.viewInUI && (
                                <ExportFileButton
                                    buttonText="Run Report"
                                    cssClasses="btn btn-orange"
                                    defaultOpenModal={
                                        defaultShowExport &&
                                        !this.state.reportTypeDetail.availableReportOptions
                                            ?.includeDateRange
                                    }
                                    hideExportButtonWhenModalOpen
                                    hideIcon
                                    modalTitle="Run Report"
                                    reportParameters={this.state.reportParameters}
                                    reportType={{
                                        id: this.props.id!,
                                        availableReportOptions:
                                            this.state.reportTypeDetail.availableReportOptions,
                                    }}
                                    url={'/' + ApiRoutes.ExportReport}
                                    validateFn={() => {
                                        const validateDateRange = () => {
                                            const isMissingDate =
                                                (this.state.reportTypeDetail.availableReportOptions
                                                    ?.includeDateRange &&
                                                    isEmpty(this.state.reportParameters.date)) ||
                                                !this.state.reportParameters.date;

                                            const isMissingStartDate =
                                                this.state.reportTypeDetail.availableReportOptions
                                                    ?.includeDateRange &&
                                                !this.state.reportParameters.date?.startDate;

                                            const isMissingEndDate =
                                                this.state.reportTypeDetail.availableReportOptions
                                                    ?.includeDateRange &&
                                                !this.state.reportParameters.date?.endDate;

                                            const startDateIsGreaterThanEndDate =
                                                this.state.reportTypeDetail.availableReportOptions
                                                    ?.includeDateRange &&
                                                (this.state.reportParameters.date?.startDate ??
                                                    '') >
                                                    (this.state.reportParameters.date?.endDate ??
                                                        '');

                                            if (isMissingDate) {
                                                this.setState({
                                                    validation: {
                                                        dateRange: ['A date range is required'],
                                                    },
                                                });
                                                return false;
                                            } else if (isMissingStartDate) {
                                                this.setState({
                                                    validation: {
                                                        dateRange: ['A start date is required'],
                                                    },
                                                });
                                                return false;
                                            } else if (isMissingEndDate) {
                                                this.setState({
                                                    validation: {
                                                        dateRange: ['An end date is required'],
                                                    },
                                                });
                                                return false;
                                            } else if (startDateIsGreaterThanEndDate) {
                                                this.setState({
                                                    validation: {
                                                        dateRange: [
                                                            'Start date must be before end date',
                                                        ],
                                                    },
                                                });
                                                return false;
                                            } else {
                                                this.setState({
                                                    validation: {
                                                        dateRange: [],
                                                    },
                                                });
                                            }

                                            return true;
                                        };

                                        let valid = true;

                                        if (
                                            this.state.reportTypeDetail.availableReportOptions
                                                ?.includeDateRange
                                        ) {
                                            valid = validateDateRange();
                                        }

                                        return valid;
                                    }}
                                />
                            )}

                            {this.state.reportTypeDetail.allowComments &&
                                (this.state.addEditComment ? (
                                    <Button
                                        variant="default"
                                        onClick={() => this.setState({ addEditComment: false })}
                                    >
                                        Hide Comments
                                    </Button>
                                ) : (
                                    <Button
                                        variant="default"
                                        onClick={() => this.setState({ addEditComment: true })}
                                    >
                                        Comments
                                    </Button>
                                ))}

                            {showExportButton && this.state.reportTypeDetail.viewInUI && (
                                <Button variant="primary" onClick={this.refreshReport}>
                                    Run Report
                                </Button>
                            )}
                        </div>
                    </Stack>
                </Stack>
                <div>
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>

                {this.state.addEditComment && (
                    <div className="form-group" style={{ paddingLeft: '10px' }}>
                        <label className="control-label mb-2" htmlFor="comments">
                            Comments
                        </label>
                        <textarea
                            className="form-control"
                            value={this.state.reportParameters.commentText}
                            onChange={this.handleCommentChange}
                            rows={3}
                        />
                    </div>
                )}

                <div className="mt-2">
                    {this.state.reportTypeDetail.availableReportOptions?.includeDateRange && (
                        <Stack direction="horizontal" gap={2} style={{ marginRight: '10px' }}>
                            <span>Date Range</span>
                            <DateRangePicker
                                onStartDateChange={(e) =>
                                    this.handleChangeDate(e, DateTypesEnum.StartDate)
                                }
                                onEndDateChange={(e) =>
                                    this.handleChangeDate(e, DateTypesEnum.EndDate)
                                }
                                startDate={
                                    this.state.reportParameters.date?.startDate
                                        ? Common.dateFormat(
                                              this.state.reportParameters.date.startDate
                                          )
                                        : ''
                                }
                                endDate={
                                    this.state.reportParameters.date?.endDate
                                        ? Common.dateFormat(
                                              this.state.reportParameters.date.endDate
                                          )
                                        : ''
                                }
                                startInputFieldName="startDate"
                                endInputFieldName="endDate"
                            />
                            {this.state.validation.dateRange && (
                                <div className="text-danger">{this.state.validation.dateRange}</div>
                            )}
                        </Stack>
                    )}
                </div>

                {this.state.reportTypeDetail.viewInUI &&
                    (this.state.renderReportViewer ||
                        !this.state.reportTypeDetail.availableReportOptions) && (
                        <Html5ReportViewer
                            scale={1.3}
                            addSpaceForCommentOnTop={this.state.addEditComment}
                            reportName={this.state.reportTypeDetail.viewTemplateName!}
                            reportParameters={this.state.reportParameters}
                            refreshReport={this.state.refreshReport}
                        />
                    )}

                <Modal
                    centered
                    // show={this.state.showSettingsModal}
                    onHide={() => {
                        this.setState({ showSettingsModal: false });
                    }}
                    backdrop={false}
                >
                    <Modal.Header>
                        <Modal.Title>Report Settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <span className="text-danger">{this.state.validation.model}</span>
                        </div>
                        {this.state.reportTypeDetail.availableReportOptions ? (
                            <>
                                {this.state.reportTypeDetail.availableReportOptions
                                    .includeDateRange && (
                                    <div className="row mb-3">
                                        <div className="col-sm-1">
                                            <label className="control-label" htmlFor="dateRange">
                                                Date
                                            </label>
                                        </div>
                                        <div className="col-sm-11">
                                            <input
                                                type="date"
                                                name="startDate"
                                                className={
                                                    'form-control d-inline-block horizontal-margin' +
                                                    (this.state.reportParameters.date &&
                                                    this.state.reportParameters.date.startDate &&
                                                    Common.isValidDate(
                                                        this.state.reportParameters.date.startDate
                                                    )
                                                        ? ''
                                                        : ' unselectClass')
                                                }
                                                value={
                                                    this.state.reportParameters.date &&
                                                    this.state.reportParameters.date.startDate
                                                        ? Common.dateFormat(
                                                              this.state.reportParameters.date
                                                                  .startDate
                                                          )
                                                        : ''
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleChangeDate(
                                                        e,
                                                        DateTypesEnum.StartDate
                                                    );
                                                }}
                                            />
                                            <span className="horizontal-margin">to</span>
                                            <input
                                                type="date"
                                                name="endDate"
                                                value={
                                                    this.state.reportParameters.date &&
                                                    this.state.reportParameters.date.endDate
                                                        ? this.state.reportParameters.date.endDate
                                                        : ''
                                                }
                                                className={
                                                    'form-control d-inline-block horizontal-margin' +
                                                    (this.state.reportParameters.date &&
                                                    this.state.reportParameters.date.endDate &&
                                                    Common.isValidDate(
                                                        this.state.reportParameters.date.endDate
                                                    )
                                                        ? ''
                                                        : ' unselectClass')
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleChangeDate(e, DateTypesEnum.EndDate);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.state.reportTypeDetail.availableReportOptions
                                    .includePastProjected && (
                                    <div className="mt-2">
                                        <input
                                            name="chkIncludePastProjected"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={
                                                this.state.reportParameters.options &&
                                                this.state.reportParameters.options
                                                    .includePastProjected
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(e);
                                            }}
                                        />
                                        <span>&nbsp;&nbsp;Include Past Projected</span>
                                    </div>
                                )}
                                {this.state.reportTypeDetail.availableReportOptions
                                    .includeNotes && (
                                    <div className="mt-2">
                                        <input
                                            name="chkIncludeNotes"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={
                                                this.state.reportParameters.options?.includeNotes
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(e);
                                            }}
                                        />
                                        <span>&nbsp;&nbsp;Include Significant Changes</span>
                                    </div>
                                )}
                                {this.state.reportTypeDetail.availableReportOptions
                                    .includeTasks && (
                                    <div className="mt-2">
                                        <input
                                            name="chkIncludeTasks"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={
                                                this.state.reportParameters.options?.includeTasks
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(e);
                                            }}
                                        />
                                        <span>&nbsp;&nbsp;Include Tactics</span>
                                    </div>
                                )}
                            </>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Stack
                            direction="horizontal"
                            style={{ width: '100%', justifyContent: 'space-between' }}
                        >
                            <div>
                                <input
                                    name="chkSaveReport"
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={this.state.reportParameters.saveReport ? true : false}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.handleCheckedChange(e);
                                    }}
                                />
                                <span>&nbsp;&nbsp;Save Report</span>
                            </div>

                            <div>
                                <button
                                    className="btn btn-default"
                                    onClick={this.cancelSettingsModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-orange"
                                    onClick={
                                        this.state.reportTypeDetail.viewInUI
                                            ? this.refreshReport
                                            : this.generateAndDownloadReport
                                    }
                                >
                                    Run
                                </button>
                            </div>
                        </Stack>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

interface ICurrentFiltersProps {
    availableReportOptions?: any;
    reportParameters: IReportParametersModel;
}

function CurrentFilters(props: ICurrentFiltersProps) {
    return (
        <div>
            {props.availableReportOptions?.includePastProjected && (
                <span style={{ fontSize: '12px' }}>(Include Past Projected Settlements)</span>
            )}
        </div>
    );
}
