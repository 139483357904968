import ApiClient from '../../../../services/apiClient';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useCaseExpertDeleteMutation = (onError: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (expertGuid: string) => {
            const response = await _apiClient.deleteCaseExpert(expertGuid);

            if (!response?.httpResponse.ok) {
                throw new Error('Failed to delete Case Expert');
            }

            return response;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
    });
