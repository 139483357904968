import { Button, Stack } from 'react-bootstrap';

import { ILookupModel } from '../../../../interfaces/ILookup';
import { ITimelineReportInputModel } from '../../../../interfaces/Report/ITimelineReport';
import { ReportStatusTypesEnum } from '../../../../utilities/Constants';
import { WeeklyRecapCreateActions } from './WeeklyRecapCreateActions';
import { WeeklyRecapNonReadOnlyActions } from './WeeklyRecapNonReadOnlyActions';
import { WeeklyRecapReadOnlyActions } from './WeeklyRecapReadOnlyActions';

export enum PageActionsType {
    SettingsOnly,
    WeeklyRecapReadOnlyActions,
    WeeklyRecapNonReadOnlyActions,
    WeeklyRecapCreateActions,
}

export interface IWeeklyRecapPageActionsProps {
    enableCollab?: boolean;
    forceHideEditButton: boolean;
    hasChatActs?: boolean;
    hasCollabAct?: boolean;
    hasReviewAct?: boolean;
    hasUnsavedChanges: boolean;
    inputModel: ITimelineReportInputModel;
    isClosingReviewAct?: boolean;
    isPreviewMode: boolean;
    onCancelClick: () => void;
    onChatClick: () => void;
    onCloseReviewActClick: () => void;
    onCollabClick: () => void;
    onCommentsClick: (section?: number) => void;
    onEditClick: () => void;
    onExportError?: () => void;
    onHistoryClick: () => void;
    onPreviewClick: () => void;
    onRefreshClick: () => void;
    onSaveClick: () => void;
    onSaveAsClick: () => void;
    onSettingsClick: () => void;
    pageActionsDisplayType: PageActionsType;
    reportStatus: ILookupModel | undefined;
    userIsReader: boolean;
}

export function WeeklyRecapPageActions(props: IWeeklyRecapPageActionsProps) {
    return (
        <div>
            <div>
                {props.pageActionsDisplayType === PageActionsType.SettingsOnly ? (
                    <button className="btn btn-no-bg text-gray" onClick={props.onSettingsClick}>
                        <i className="fal fa-user-cog" />
                        &nbsp;Settings
                    </button>
                ) : (
                    <Stack direction="horizontal">
                        {props.pageActionsDisplayType ===
                            PageActionsType.WeeklyRecapReadOnlyActions && (
                            <WeeklyRecapReadOnlyActions
                                forceReadonly={props.forceHideEditButton}
                                hasChatActs={props.hasChatActs}
                                hasCollabAct={props.hasCollabAct}
                                hasReviewAct={props.hasReviewAct}
                                hideCollabButton={props.userIsReader || !props.enableCollab}
                                hideHistoryButton={props.userIsReader}
                                inputModel={props.inputModel}
                                isClosingReviewAct={props.isClosingReviewAct}
                                isPublished={
                                    props.reportStatus?.id === ReportStatusTypesEnum.Published
                                }
                                onChatClick={props.onChatClick}
                                onCloseReviewActClick={props.onCloseReviewActClick}
                                onCollabClick={props.onCollabClick}
                                onEditClick={props.onEditClick}
                                onExportError={props.onExportError}
                                onHistoryClick={props.onHistoryClick}
                                onPreviewClick={props.onPreviewClick}
                                previewText={
                                    props.isPreviewMode
                                        ? props.reportStatus?.displayName ?? ''
                                        : 'Preview'
                                }
                            />
                        )}

                        {props.pageActionsDisplayType ===
                            PageActionsType.WeeklyRecapNonReadOnlyActions && (
                            <WeeklyRecapNonReadOnlyActions
                                hasChatActs={props.hasChatActs}
                                hasUnsavedChanges={props.hasUnsavedChanges}
                                inputModel={props.inputModel}
                                isPublished={
                                    props.reportStatus?.id === ReportStatusTypesEnum.Published
                                }
                                onCancelClick={props.onCancelClick}
                                onChatClick={props.onChatClick}
                                onCollabClick={props.onCollabClick}
                                onCommentsClick={() => props.onCommentsClick(0)}
                                onExportError={props.onExportError}
                                onHistoryClick={props.onHistoryClick}
                                onSaveClick={props.onSaveClick}
                                onSettingsClick={props.onSettingsClick}
                            />
                        )}

                        {props.pageActionsDisplayType ===
                            PageActionsType.WeeklyRecapCreateActions && (
                            <WeeklyRecapCreateActions
                                hideSaveAsButton={!props.enableCollab}
                                inputModel={props.inputModel}
                                onCommentsClick={props.onCommentsClick}
                                onExportError={props.onExportError}
                                onSaveAsClick={props.onSaveAsClick}
                                onSettingsClick={props.onSettingsClick}
                            />
                        )}

                        <Button variant="primary" onClick={props.onRefreshClick}>
                            Refresh
                        </Button>
                    </Stack>
                )}
            </div>
        </div>
    );
}
