export default class ParamUtil {
    static getParameterByName(name: string): string {
        let url: string = window.location.href;
        //var results = null;

        name = name.replace(/[[\]]/g, '\\$&');

        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        let results = regex.exec(url);
        //results = regex.exec(url);

        if (!results) return '';
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}
