import * as React from 'react';

interface ITextWithLineBreaksProps {
    text: string;
}

export class TextWithLineBreaks extends React.Component<ITextWithLineBreaksProps, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <span>
                {this.props.text.split('\n').map((item, key) => {
                    return (
                        <span key={key}>
                            {item}
                            <br />
                        </span>
                    );
                })}
            </span>
        );
    }
}
