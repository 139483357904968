import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { LocalCounselStrategyDetail } from '../strategy/LocalCounselStrategyDetail';
import { useParams } from 'react-router-dom';

function LocalCounselStrategyDetailWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return (
        <>
            <DocumentTitle title={'Local Counsel Strategy Detail'} />
            <LocalCounselStrategyDetail guid={guid} user={props.user} />
        </>
    );
}

export default LocalCounselStrategyDetailWrapper;
