import { useEffect, useState } from 'react';

import { ISortState } from '../interfaces/ISortState';
import Sort from '../stores/Sort';

export function useSort<T>(dataToSort: T[], customSort?: (a: T, b: T) => number) {
    const [sort, setSort] = useState<ISortState | null>(null);
    const [sortedData, setSortedData] = useState<T[]>(dataToSort);

    const handleSort = (
        key: string,
        order: string,
        subKey?: string | undefined,
        subGrandKey?: string | undefined
    ) => {
        setSort({
            key,
            order,
            subKey,
            subGrandKey,
        });
    };

    useEffect(() => {
        if (sort) {
            let sortedData: T[] = [];

            if (customSort) {
                sortedData = dataToSort.sort(customSort);
            } else {
                sortedData = dataToSort.sort(
                    Sort.compareValues(sort.key, sort.subKey, sort.order, sort.subGrandKey)
                );
            }

            setSortedData(sortedData);
        } else {
            setSortedData(dataToSort);
        }
    }, [sort, dataToSort]);

    return { handleSort, sortedData };
}
