import { useState } from 'react';

function useLocalStorage<T>(key: string, initialValue: T) {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = (value: T): void => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;

            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
            window.dispatchEvent(new Event('storage'));
        } catch (error) {
            console.log(error);
        }
    };

    const removeItem = (): void => {
        try {
            window.localStorage.removeItem(key);
            window.dispatchEvent(new Event('storage'));
        } catch (error) {
            console.log(error);
        }
    };

    const getValue = (key: string): void => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue, getValue, removeItem] as const;
}

export default useLocalStorage;
