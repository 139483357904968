import ApiClient from '../../services/apiClient';
import { ITriggerRuleSettingsModel } from '../../interfaces/ITriggerRule';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useUpdateTriggerMutation = (onError: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (trigger: ITriggerRuleSettingsModel) => {
            const response = await _apiClient.updateTrigger(trigger);

            if (response && !response?.httpResponse.ok) {
                if (response.httpResponse.status === 400
                ) {
                    throw new Error(`Failed to update Trigger : ${response.errorMessage}`);
                }
                else if (response.httpResponse.status === 403
                ) {
                    throw new Error(`Forbidden to update trigger`);
                }
                throw new Error('Failed to update Trigger');
            }
        return response!.payload!;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
    });
