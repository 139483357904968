import * as React from 'react';

import Authorization from '../../../../stores/Authorization';
import { IOrganizationRoleModel } from '../../../../interfaces/IUser';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { MenuItemNavLinkWithIcon } from '../../MenuItemNavLinkWithIcon';
import { ZoneSwitchMenuItem } from './ZoneSwitchMenuItem';
import { useAuthContext } from '../../../../contexts/AuthContext';

export interface IUserMenuProps {
    onZoneChange: (org: IOrganizationRoleModel, redirectPath?: string) => void;
}

export function UserMenu(props: IUserMenuProps) {
    {
        const { user, isLoggedIn } = useAuthContext();

        const isPlanningExecutionEnabled = () => {
            if (user && user.organizationRoles) {
                for (let i = 0; i < user.organizationRoles.length; i++) {
                    if (user.organizationRoles[i].default) {
                        const defaultOrganization = user.organizationRoles[i];
                        return defaultOrganization.enablePlanningExecution;
                    }
                }
            }
            return false;
        };

        if (!user.profile) {
            return null;
        }

        return (
            <div
                style={{
                    display: 'flex',
                    gap: '7vw',
                }}
            >
                <ZoneSwitchMenuItem onZoneChange={props.onZoneChange} />

                {isLoggedIn && (
                    <div className="innerContainer" style={{ paddingRight: '2.5vw' }}>
                        <button className="btn-black" data-bs-toggle="dropdown">
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <i className="fal fa-user" />
                                &nbsp; Welcome, {user?.profile?.lastName},{' '}
                                {user?.profile?.firstName}
                            </span>
                        </button>
                        <div className="dropdown-menu adjust-right">
                            <div>
                                {Authorization.isAuthorizedToRoute(LocalRoutes.Zones, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-building"
                                        label="ZONES"
                                        to={LocalRoutes.Zones}
                                    />
                                )}
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.AssertionDescriptors,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-list"
                                        label="ASSERTION DESCRIPTORS"
                                        to={LocalRoutes.AssertionDescriptors}
                                    />
                                )}
                                {Authorization.isAuthorizedToRoute(LocalRoutes.Users, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-users"
                                        label="USERS"
                                        to={LocalRoutes.Users}
                                    />
                                )}
                                {isPlanningExecutionEnabled() &&
                                    Authorization.isAuthorizedToRoute(
                                        LocalRoutes.UserZoneProfile,
                                        user
                                    ) && (
                                        <MenuItemNavLinkWithIcon
                                            iconClass="fal fa-user-plus"
                                            label="USER ZONE PROFILE"
                                            to={LocalRoutes.UserZoneProfile}
                                        />
                                    )}
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.CreateInvitation,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-user-plus"
                                        label="USER INVITATION"
                                        to={LocalRoutes.CreateInvitation}
                                    />
                                )}
                                {isPlanningExecutionEnabled() &&
                                    Authorization.isAuthorizedToRoute(
                                        LocalRoutes.UserTasks,
                                        user
                                    ) && (
                                        <MenuItemNavLinkWithIcon
                                            iconClass="fal fa-tasks"
                                            label="MY TASK LIST"
                                            to={LocalRoutes.UserTasks}
                                        />
                                    )}
                                {isPlanningExecutionEnabled() &&
                                    Authorization.isAuthorizedToRoute(
                                        LocalRoutes.TaskBarSetting,
                                        user
                                    ) && (
                                        <MenuItemNavLinkWithIcon
                                            iconClass="fal fa-cog"
                                            label="TASK BAR SETTINGS"
                                            to={LocalRoutes.TaskBarSetting}
                                        />
                                    )}
                                {Authorization.isAuthorizedToRoute(LocalRoutes.Triggers, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-chevrons-right"
                                        label="TRIGGER RULES"
                                        to={LocalRoutes.Triggers}
                                    />
                                )}
                                <MenuItemNavLinkWithIcon
                                    iconClass="fal fa-graduation-cap"
                                    label="TUTORIALS"
                                    to={LocalRoutes.Tutorials}
                                />
                                <MenuItemNavLinkWithIcon
                                    iconClass="fal fa-sign-out-alt"
                                    label="LOGOUT"
                                    to={LocalRoutes.Logout}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
