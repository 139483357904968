import { ApplicationHelpType } from '../../utilities/Constants';
import CheckmateSelect from '../shared/CheckmateSelect';
import { Help } from '../shared/Help';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { IValidation } from '../../interfaces/IError';
import { Modal } from 'react-bootstrap';
import NoteHelper from '../../utilities/NoteHelper';
import React from 'react';

interface IRelatedEntitiesModalProps {
    availableOptions: IMultiSelectOptions[];
    selectedEntityItems: IMultiSelectOptions[];
    title: string;
    validation: IValidation;
    onCancel: () => void;
    onHide: () => void;
    onSubmit: (items: IMultiSelectOptions[]) => void;
}

export function RelatedEntitiesModal({
    availableOptions,
    selectedEntityItems,
    title,
    validation,
    onCancel,
    onHide,
    onSubmit,
}: IRelatedEntitiesModalProps) {
    const [selectedItems, setSelectedItems] =
        React.useState<IMultiSelectOptions[]>(selectedEntityItems);

    const handleSubmit = () => {
        onSubmit(selectedItems);
    };

    return (
        <Modal
            centered
            show
            dialogClassName="official-case-doc-dialog"
            size="lg"
            onHide={onHide}
            backdrop={false}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span className="text-danger">{validation.model}</span>
                </div>

                <div className="form-group">
                    <span className="text-danger">&nbsp;{validation.associations}</span>
                    <div
                        className="mb-4"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ flexGrow: 1 }}>
                            <CheckmateSelect
                                isMulti
                                options={availableOptions || []}
                                value={selectedItems}
                                onChange={setSelectedItems}
                            />
                        </div>
                        <div>
                            {selectedItems.length > 1 && (
                                <Help
                                    type={ApplicationHelpType.Check}
                                    title="Selected Values"
                                    helpText={NoteHelper.getSelectedValuesTextFromMultiSelect(
                                        selectedItems
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Modal.Footer>
                    <button className="btn btn-orange" onClick={handleSubmit}>
                        Ok
                    </button>
                    <button className="btn btn-default" onClick={onCancel}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
}
