import classNames from 'classnames';

export interface IPageHeaderLayoutProps {
    disableBottomPadding?: boolean;
    title: string;
    actions: JSX.Element;
}

export function PageHeaderLayout(props: IPageHeaderLayoutProps) {
    const classes = classNames('row', { 'mb-3': !props.disableBottomPadding });

    return (
        <div className={classes}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h1 className="col-sm-4">{props.title}</h1>
                <div>{props.actions}</div>
            </div>
        </div>
    );
}
