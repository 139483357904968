import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { PlaintiffsFirmStrategyDetail } from '../strategy/PlaintiffsFirmStrategyDetail';
import { useParams } from 'react-router-dom';

function PlaintiffsFirmStrategyDetailWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return (
        <>
            <DocumentTitle title="Plantiffs Firms Detail" />
            <PlaintiffsFirmStrategyDetail guid={guid} user={props.user} />
        </>
    );
}

export default PlaintiffsFirmStrategyDetailWrapper;
