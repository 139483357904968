import ApiClient from '../../../services/apiClient';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useReleaseEntityLeaseMutation = (onError?: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (entityLeaseId: string) => {
            const response = await _apiClient.releaseEntityLease(entityLeaseId);

            if (!response?.httpResponse.ok) {
                if (response.errorMessage && response.httpResponse.status == 400) {
                    if (response.errors !== undefined && response.errors[0] !== undefined) {
                        throw new Error(
                            `Failed to release the current lease : ${response.errors[0].message}`
                        );
                    }
                }
                throw new Error('Failed to release the current lease');
            }
            return response.payload;
        },
        onError(error) {
            if (error instanceof Error && typeof onError === 'function') {
                onError(error.message);
            }
        },
    });
