import { DataScopesEnum, EmptyGuid, EntityTypes, StrategyTypes } from '../../utilities/Constants';
import { INoteCategoryModel, INoteModel } from '../../interfaces/INote';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import Common from '../../stores/Common';
import { IEntityModel } from '../../interfaces/IAssertionDescriptor';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import NoteHelper from '../../utilities/NoteHelper';
import classNames from 'classnames';

interface INoteListTableRowProps {
    easyUpdateIconClass: string;
    item: INoteModel;
    isEmptyEntity: boolean;
    onAddClick?: (caseGuid: string) => void;
    onEasyUpdateClick: (caseGuid: string, noteGuid: string) => void;
    onShowConfirmRemoveDialog: (note: INoteModel) => void;
    onViewEditNote: (note: INoteModel, readOnly?: boolean) => void;
    showDeleteButton?: boolean;
    showEasyUpdateColumn?: boolean;
    showEditButton?: boolean;
    showCaseColumn?: boolean;
    showExpertsColumn?: boolean;
    showTypeColumn?: boolean;
    summaryRowIsExpanded?: boolean;
}

export function NoteListTableRow({
    easyUpdateIconClass,
    item,
    isEmptyEntity,
    onAddClick,
    onEasyUpdateClick,
    onShowConfirmRemoveDialog,
    onViewEditNote,
    showDeleteButton,
    showEditButton,
    showCaseColumn,
    showEasyUpdateColumn,
    showExpertsColumn,
    showTypeColumn,
    summaryRowIsExpanded,
}: INoteListTableRowProps) {
    const experts = item.associations?.filter(
        (assoc) => assoc.entityType?.id === EntityTypes.CaseExpert
    );

    const showAdd =
        isEmptyEntity &&
        typeof onAddClick === 'function' &&
        item?.caseGuid &&
        item?.caseGuid !== EmptyGuid;

    const additionalCols =
        (showCaseColumn ? 1 : 0) + (showExpertsColumn ? 1 : 0) + (showTypeColumn ? 1 : 0);

    const handleEasyUpdateClick = (item: INoteModel) => {
        if (typeof onEasyUpdateClick === 'function' && item.caseGuid && item.guid) {
            onEasyUpdateClick(item.caseGuid, item.guid);
        }
    };

    const handleRowAddClick = () => {
        if (showAdd) {
            onAddClick(item.caseGuid ?? '');
        }
    };

    return (
        <>
            <tr key={item.guid} className={classNames({ 'no-border-bottom': !isEmptyEntity })}>
                {showCaseColumn && (
                    <td>
                        {item.caseGuid && (
                            <Link
                                target="_blank"
                                to={LocalRoutes.CaseFile.replace(':guid', item.caseGuid)}
                            >
                                {item.caseName}
                            </Link>
                        )}
                    </td>
                )}
                {showTypeColumn && <td>{item.type && <>{item.originStrategyTypeName}</>}</td>}
                {showTypeColumn && (
                    <td>
                        {item.type && (
                            <StrategyDetailLink
                                type={item.originStrategyType ?? 0}
                                name={item.originEntityName ?? ''}
                                guid={item.originEntityKey ?? ''}
                            />
                        )}
                    </td>
                )}
                <td>
                    {item.categories?.map((value: INoteCategoryModel, i: number) => {
                        return <div key={i}>{value.fullText}</div>;
                    })}
                </td>
                <td>
                    {item.private && <i className="fal fa-lg fa-location-dot-slash color-gray" />}
                </td>
                <td>
                    {item.allowLocalEdit ||
                    item.actionTarget?.activeZoneDataScope?.id ===
                        DataScopesEnum.LocalBasic.Value ? (
                        <i
                            className={
                                'fal fa-lg fa-map-marker-edit ' +
                                (item.actionTarget?.activeZoneDataScope?.id ===
                                DataScopesEnum.LocalBasic.Value
                                    ? 'color-green'
                                    : 'color-gray')
                            }
                        />
                    ) : null}
                </td>
                <td>
                    {item.modifiedByLocal && (
                        <i className="fal fa-lg fa-map-marker-smile color-gray" />
                    )}
                </td>
                <td>
                    {item.context && item.context.trim().length > 0 && (
                        <i className="fal fa-lg fa-message-dots color-gray" />
                    )}
                </td>
                <td>{item.hasAttachment && <i className="fal fa-lg fa-paperclip color-gray" />}</td>
                {showExpertsColumn && (
                    <td>{experts?.some((e) => e) && <ExpertsTooltip experts={experts} />}</td>
                )}
                <td className="col">{item.purpose?.displayName}</td>
                <td className="col">{NoteHelper.getNoteDecisionText(item)}</td>
                <td className="col">{NoteHelper.getModifiedByName(item.author)}</td>
                <td className="col">
                    {!isEmptyEntity ? Common.dateTimeFormatToLocal(item.modifiedDate) : null}
                </td>
                <td className="table-actions">
                    <div
                        className="text-nowrap"
                        style={{ display: 'flex', gap: 0, justifyContent: 'flex-end' }}
                    >
                        {!isEmptyEntity && (
                            <>
                                <button
                                    className="btn btn-no-bg"
                                    onClick={() => {
                                        onViewEditNote(item, true);
                                    }}
                                >
                                    <i className="fal fa-lg fa-eye" />
                                </button>
                                {showEditButton && (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() => {
                                            onViewEditNote(item);
                                        }}
                                    >
                                        <i className="fal fa-lg fa-pen" />
                                    </button>
                                )}
                                {showDeleteButton && (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() => {
                                            onShowConfirmRemoveDialog(item);
                                        }}
                                    >
                                        <i className="fal fa-lg fa-trash-alt"></i>
                                    </button>
                                )}
                            </>
                        )}

                        {showAdd && (
                            <button
                                className="btn btn-no-bg"
                                style={{ margin: 0 }}
                                onClick={handleRowAddClick}
                            >
                                <i className="fal fa-plus"></i>
                            </button>
                        )}

                        {showEasyUpdateColumn && (
                            <button
                                className="btn btn-no-bg float-end"
                                onClick={() => handleEasyUpdateClick(item)}
                            >
                                <i className={`fal fa-edit ${easyUpdateIconClass}`} />
                            </button>
                        )}
                    </div>
                </td>
            </tr>
            {(item.content?.trim().length ?? 0) > 0 && (
                <SummaryRow
                    colSpan={10 + additionalCols}
                    content={item.content ?? ''}
                    isExpanded={!!summaryRowIsExpanded}
                />
            )}
        </>
    );
}

function ExpertsTooltip({ experts }: { experts: IEntityModel[] }) {
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            <div style={{ textAlign: 'left' }}>
                {experts.map((expert) => (
                    <div key={expert.entityGuid}>{expert.displayName}</div>
                ))}
            </div>
        </Tooltip>
    );

    return (
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
            <i className="fal fa-lg fa-head-side-brain color-gray" />
        </OverlayTrigger>
    );
}

interface ISummaryRowProps {
    colSpan: number;
    content: string;
    isExpanded: boolean;
}

function SummaryRow(props: ISummaryRowProps) {
    const [localIsExpanded, setLocalIsExpanded] = useState<boolean>(props.isExpanded);

    const handleExpandedClick = () => {
        setLocalIsExpanded((prev) => !prev);
    };

    useEffect(() => {
        setLocalIsExpanded(props.isExpanded);
    }, [props.isExpanded]);

    return (
        <tr>
            <td colSpan={props.colSpan} style={{ padding: '0 1em 0 0' }}>
                <div>
                    <button
                        className="btn btn-no-bg"
                        onClick={handleExpandedClick}
                        style={{ marginLeft: -12 }}
                    >
                        <div
                            className="text-gray"
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: '15px',
                                flexDirection: 'row',
                                marginBottom: '3px',
                            }}
                        >
                            <i
                                className={classNames('fa-regular', {
                                    'fa-minus': localIsExpanded,
                                    'fa-plus': !localIsExpanded,
                                })}
                            />
                            <span>Summary</span>
                        </div>
                    </button>
                    <div
                        style={{
                            marginLeft: '28px',
                            visibility: localIsExpanded ? 'visible' : 'collapse',
                            height: localIsExpanded ? 'auto' : 0,
                        }}
                    >
                        <div
                            style={{
                                paddingBottom: '1em',
                            }}
                        >
                            {props.content?.split('\n').map((item, key) => {
                                return (
                                    <span key={key}>
                                        {item.trim()}
                                        <br />
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </td>
            <td />
        </tr>
    );
}

interface IStrategyDetailLinkProps {
    type: number;
    name: string;
    guid: string;
}

function StrategyDetailLink(props: IStrategyDetailLinkProps) {
    const route = (type: number, guid: string): string => {
        switch (type) {
            case StrategyTypes.Jurisdiction.Value:
                return LocalRoutes.JurisdictionStrategyDetail.replace(':guid', guid);
            case StrategyTypes.PlaintiffsFirm.Value:
                return LocalRoutes.PlaintiffFirmStrategyDetail.replace(':guid', guid);
            case StrategyTypes.LocalCounsel.Value:
                return LocalRoutes.LocalCounselStrategyDetail.replace(':guid', guid);
            case StrategyTypes.Projects.Value:
                return LocalRoutes.Projects.replace(':guid', guid);
            case StrategyTypes.Experts.Value:
                return LocalRoutes.ExpertDetail.replace(':id', guid);
            default:
                break;
        }

        return '';
    };

    return (
        <Link target="_blank" to={route(props.type, props.guid)}>
            {props.name}
        </Link>
    );
}
