export const DisplayMessages = {
    "CreateAccount": "Welcome! You have been invited to join Checkmate. Please enter a password to complete your registration.",
    "PasswordRequired": "Complete all required fields",
    "PasswordMatch": "Passwords do not match",
    "PasswordCriteria": "Password doesn't match the required criteria",
    "RegistrationOptionSubtitle": "Choose from the following option(s) to complete your registration",
    "RecoverAccountSubtitle": "Enter your email address to recover your checkmate account",
    "RecoverAccountEmailSent": "An email with steps to recover your account has been successfully sent.",
    "LoginUsernameInfo": "Your username is your email address you used to sign up for Checkmate",
    "ReportCreated": "Your Report Request was successfully created. You will be notified once the report is generated. Do you want to continue to generate another report ?",
    "ReportGenerateError": "Error occured while retrieving the report",
    "CaseOptionError": "At least one case option is required",
    "CaseScopeError": "At least one case Scope is required",
    "StartTrialDateError": "Start trail date format is incorrect",
    "EndTrialDateError": "End trail date format is incorrect",
    "UserProfileCreate": "User profile created successfully",
    "UserProfileUpdate": "User profile updated successfully",
    "CaseDeadlinesUpdate": "case deadlines updated successfully",
    "PrimaryFirmError": "Primary firm is required if secondary firms exists",
    "MasterSubscriptionError": "There should be only one Master -  subscription type per zone",
    "SubscriptionTypeError": "Subscription type is required",
    "DependencyOrgNameError": "Name is required.",
    "DependencyOrgDuplicateNameError": "A organization with the same name already exists.",
    "DocumentDownloadError": "Error occured while downloading a document",
    "TaskBarSettingSaveSuccessful": "Your task bar settings were successfully saved.",
    "BothStartAndEndDateRequired": "A valid date range requires both Start Date and End Date. ",
    "QueryToolMaximumCriteria": "You can only enter a maximum of 10 Criteria at a time; ",
    "QueryToolMaximumColums": "You can only include 10 columns at a time; ",
    "QueryToolAtleastOneRequired": "At least one Criteria should be marked as 'Required'; ",
    "UnexpectedError": "An unexpected error occurred while processing your request. Please try again later. If the issue persists, please contact your administrator.",
    "AcceptTermsOfServiceRequest1": "Patent Pending Copyright 2020 Checkmate Legal Solution, LLC (Checkmate) All Rights Reserved. ",
    "AcceptTermsOfServiceRequest2": "By clicking the 'I Accept' button, you are acknowledging the Checkmate intellectual property notices above and you further agree to refrain from engaging in any unauthorized copying and from committing any acts that would otherwise violate Checkmate's intellectual property rights.",
    "AcceptTermsOfServiceRequiredAccount": "You must accept the User Acknowledgement to create an account.",
    "AcceptTermsOfServiceRequiredLogin": "You must accept the User Acknowledgement to login.",
    "TwoFactorSubtitle": "Enter the code that was just sent to your email address to complete your login.",
    "TwoFactorTokenInvalid": "Two Factor code is invalid. Please attempt to login again",
    "UnexpectedErrorPackageDealQueryNav": "Unhandled Error occured while accessing the Query Tool from Package Deals."
};