import { FieldRenderer } from './FieldRenderer';
import { IDisplayFieldModel } from '../../interfaces/IDisplaySectionModel';

export interface ISectionRowProps {
    columnCount: number;
    displayAsCondensed: boolean;
    fields: IDisplayFieldModel[];
}

export function SectionRow({ columnCount, displayAsCondensed, fields }: ISectionRowProps) {
    return (
        <div className="row">
            {fields
                ?.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1))
                .map((field, fieldIdx) => {
                    return (
                        <div key={fieldIdx} className={columnCount === 3 ? 'col-sm-4' : 'col-sm-6'}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                }}
                            >
                                <FieldRenderer
                                    key={fieldIdx}
                                    field={field}
                                    displayAsCondensed={displayAsCondensed}
                                />
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}
