import {
    BooleanSummary,
    CaseStatusSummary,
    DateRangeSummary,
    SelectedValuesSummary,
    TextSummary,
} from './triggerFieldSummaries';
import {
    IQueryFilterCriteria,
    IQueryFilterCriteriaSelectionValue,
} from '../../../../interfaces/IQuery';

import { CaseFileStaticItems } from '../../../../utilities/Constants';
import { IItemSummaryProps } from './TriggerFieldsCell';
import React from 'react';

export function StaticItemSummary(props: IItemSummaryProps) {
    const findStatus = React.useCallback(
        (value: IQueryFilterCriteriaSelectionValue) => {
            return props.caseStatuses?.find((x) => x.id == value.id);
        },
        [props.caseStatuses]
    );

    const getSummaryComponent = (item: IQueryFilterCriteria) => {
        switch (item.staticItemId) {
            case CaseFileStaticItems.CaseNumber.Id:
            case CaseFileStaticItems.UniqueCaseID.Id:
                return <TextSummary item={item} />;
            case CaseFileStaticItems.CasePriority.Id:
                return <BooleanSummary item={item} />;
            case CaseFileStaticItems.CaseStatusAsOfDate.Id:
            case CaseFileStaticItems.ResolutionDate.Id:
                return <DateRangeSummary item={item} />;
            case CaseFileStaticItems.CaseStatus.Id:
                return <CaseStatusSummary item={item} findStatus={findStatus} />;
            case CaseFileStaticItems.State.Id:
            case CaseFileStaticItems.OtherDefenseCounsels.Id:
            case CaseFileStaticItems.CaseManager.Id:
            case CaseFileStaticItems.AlternateCaseManager.Id:
            case CaseFileStaticItems.LocalCounsel.Id:
            default:
                return <SelectedValuesSummary item={item} />;
        }
    };

    return (
        <>
            <div>
                {props.trigger.label}: {getSummaryComponent(props.trigger)}
            </div>
            {/* <pre>{JSON.stringify(props.trigger, undefined, 2)}</pre> */}
        </>
    );
}
