import {
    AssertionDescriptorValueDisplayTypes,
    CaseFileStaticItems,
    ConditionSelectorEnums,
} from '../../utilities/Constants';

import { ConditionSelector } from '../query/ConditionSelector';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IQueryFilterCriteria } from '../../interfaces/IQuery';

const QueryStaticItemFields = Object.assign({}, CaseFileStaticItems);

const conditionOpts = [
    {
        label: ConditionSelectorEnums.EqualTo,
        value: ConditionSelectorEnums.EqualTo,
    },
    {
        label: ConditionSelectorEnums.NotEqualTo,
        value: ConditionSelectorEnums.NotEqualTo,
    },
    {
        label: ConditionSelectorEnums.Blank,
        value: ConditionSelectorEnums.Blank,
    },
    {
        label: ConditionSelectorEnums.NotBlank,
        value: ConditionSelectorEnums.NotBlank,
    },
    {
        label: ConditionSelectorEnums.Range,
        value: ConditionSelectorEnums.Range,
    },
];

interface IQueryFilterCriteriaSelectedRowProps {
    assertionDescriptors: IAssertionDescriptorModel[];
    helpComponent: JSX.Element | null;
    onConditionOptionChange: (filters: IQueryFilterCriteria[], filterIdx: number) => void;
    onDeleteClick: (item: IQueryFilterCriteria) => void;
    item: IQueryFilterCriteria;
    selectedCriteria: IQueryFilterCriteria[];
    suppressDateRangeCondition?: boolean;
    valuesColumn: JSX.Element | JSX.Element[];
}

export function QueryFilterCriteriaSelectedRow({
    assertionDescriptors,
    helpComponent,
    item,
    onDeleteClick,
    onConditionOptionChange,
    selectedCriteria,
    suppressDateRangeCondition,
    valuesColumn,
}: IQueryFilterCriteriaSelectedRowProps) {
    const assertionDescriptor = getSelectedAssertionDescriptor();

    const handleConditionOptionChange = (selectedItem: any) => {
        const queryFilterCriteriaSelected = [...selectedCriteria];

        const filterIdx =
            queryFilterCriteriaSelected.findIndex((f) => f.value === item.value) ?? -1;

        const filtersCopy = [...queryFilterCriteriaSelected];
        const filterItemToModify = filtersCopy[filterIdx];
        const blankIsSelected = selectedItem?.value === ConditionSelectorEnums.Blank;
        const notBlankIsSelected = selectedItem?.value === ConditionSelectorEnums.NotBlank;

        const conditionValue = blankIsSelected
            ? true
            : notBlankIsSelected
            ? false
            : selectedItem?.value;

        if (blankIsSelected || notBlankIsSelected) {
            filterItemToModify.selectedValues = [];
            filterItemToModify.text = '';
            filterItemToModify.startDate = undefined;
            filterItemToModify.endDate = undefined;
            filterItemToModify.booleanValue = undefined;
            filterItemToModify.numericValueFrom = undefined;
            filterItemToModify.numericValueFromText = '';
            filterItemToModify.numericValueTo = undefined;
            filterItemToModify.numericValueToText = '';
        }
        filterItemToModify.condition = conditionValue;

        onConditionOptionChange(filtersCopy, filterIdx);
    };

    function getSelectedAssertionDescriptor() {
        const assertionDescriptorMatch = assertionDescriptors.find((x) => {
            return (
                !!item.assertionDescriptorGuid &&
                !!x.guid &&
                x.guid.toUpperCase() === item.assertionDescriptorGuid.toUpperCase()
            );
        });
        return assertionDescriptorMatch;
    }

    const isDateFieldSelected =
        assertionDescriptor?.valueDisplayType?.id ===
        AssertionDescriptorValueDisplayTypes.DateTime.Value;

    const showConditionalSelectorDropdown = !(
        item.staticItemId === QueryStaticItemFields.CaseStatusAsOfDate.Id
    );

    let optionsToRender = [...conditionOpts]; // Default options

    if (!showConditionalSelectorDropdown) {
        optionsToRender = optionsToRender.filter(
            (option) => option.value === ConditionSelectorEnums.EqualTo
        );
    }

    if (!isDateFieldSelected || suppressDateRangeCondition) {
        optionsToRender = optionsToRender.filter(
            (opt) => opt.value !== ConditionSelectorEnums.Range
        );
    }

    return (
        <tr key={item.assertionDescriptorGuid ?? item.staticItemId}>
            <td className="pt-1">
                {item!.label}
                {helpComponent}
            </td>
            <td className="px-12 pt-1" style={{ paddingRight: 15 }}>
                <ConditionSelector
                    options={optionsToRender}
                    value={item.condition}
                    onChange={handleConditionOptionChange}
                    disabled={item.readOnly}
                />
            </td>
            <td className="pt-1">{valuesColumn}</td>
            <td width="5%" className="pt-1">
                {!item.readOnly && (
                    <button
                        className="btn btn-gray btn-icon float-end"
                        onClick={() => onDeleteClick(item)}
                    >
                        <i className="fal fa-times color-white" />
                    </button>
                )}
            </td>
        </tr>
    );
}
