import {
    AssertionDescriptorDisplayValueTypeEnum,
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
    EntityTypes,
    LookupDataEnums,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    OrganizationTypesEnum,
    UserRightsEnum,
} from '../../utilities/Constants';
import { ICaseAssertionModel, ICaseModel } from '../../interfaces/ICase';
import {
    ICaseExpertViewDetailModel,
    IViewCaseExpertModelWithDefaultEdit,
} from '../../interfaces/ICaseExpert';
import { INoteCategoryTypeModel, INoteModel } from '../../interfaces/INote';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AdditionalFieldsDialog } from '../../components/case/dialogs/AdditionalFieldsDialog';
import ApiClient from '../../services/apiClient';
import Auth from '../../stores/authentication';
import Authorization from '../../stores/Authorization';
import CaseDataCustomLogicHelper from '../../utilities/CaseDataCustomLogicHelper';
import { CaseExpertEditor } from '../../components/case/static-sections/case-experts/CaseExpertEditor';
import CaseFileCheckmateSelectHelper from '../../utilities/CaseFileCheckmateSelectHelper';
import { CaseFileHeader } from '../../components/case/CaseFileHeader';
import { CaseFileSection } from '../../components/case/CaseFileSection';
import { CaseFileSections } from '../../components/case/CaseFileSections';
import CaseHelper from '../../utilities/CaseHelper';
import { CaseNarrativeDialog } from '../../components/case/dialogs/CaseNarrativeDialog';
import { CheckmateDialog } from '../../components/shared/dialog';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IApiResponse } from '../../tpi.apiclient';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { IOrganizationSettingModel } from '../../interfaces/IOrganization';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import Sort from '../../stores/Sort';
import UIHelper from '../../utilities/UIHelper';
import { UnsavedChangesBlocker } from '../../shared/UnsavedChangesBlocker';
import ValidateUtils from '../../shared/validations';
import _ from 'lodash';
import { cloneDeep } from 'lodash';
import { uniqueId } from 'lodash';
import { useAuthContext } from '../../contexts/AuthContext';
import { useCaseFileContext } from '../../contexts/CaseFileContext';

const _apiClient = new ApiClient();

export function CaseFile() {
    const { guid } = useParams<{ guid: string }>();
    const navigate = useNavigate();
    const { user, isLoggedIn, isLoadingUser } = useAuthContext();

    const {
        assertionDescriptors,
        currentCase,
        currentCaseExpert,
        currentNarrativeCaseAssertion,
        currentNote,
        forceNoteEditorInReadOnlyMode,
        displaySections,
        handleAssertionDescriptorSearchableSingleSelectChange,
        handleChangeADTextValue,
        handleNarrativeChange,
        originalCase,
        setAssertionDescriptors,
        setCaseDisplayCategories,
        setCaseManagers,
        setCaseStatuses,
        setCurrentCase,
        setCurrentCaseExpert,
        setCurrentNarrativeCaseAssertion,
        setCurrentNote,
        setForceNoteEditorInReadOnlyMode,
        setDisplaySections,
        setIsReadOnlyMode,
        setOriginalCase,
        setShowAdditionalFieldsDialog,
        setStaticItems,
        setUser,
        setZoneDefenseCounsels,
        setZoneUsers,
        showAdditionalFieldsDialog,
    } = useCaseFileContext();

    // STATE

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notesRefreshIdentifier, setNotesRefreshIdentifier] = useState<string>(uniqueId);
    const [validation, setValidation] = useState<IValidation>({});
    const [caseNoteCategoryTypes, setCaseNoteCategoryTypes] = useState<INoteCategoryTypeModel[]>(
        []
    );
    const [originalNotes, setOriginalNotes] = useState<INoteModel[]>([]);
    const [, setOrganizationSettings] = useState<IOrganizationSettingModel[]>([]);
    const [enableCaseFileEdit, setEnableCaseFileEdit] = useState<boolean>(false);
    const [enablePlanningExecution, setEnablePlanningExecution] = useState<boolean>(false);
    const [showDeleteCaseDialog, setShowDeleteCaseDialog] = useState<boolean>(false);
    const [showSetPriorityDialog, setShowSetPriorityDialog] = useState<boolean>(false);
    const [showUnsavedChangesOnCancelDialog, setShowUnsavedChangesOnCancelDialog] =
        useState<boolean>(false);

    const isSystemAdmin = user ? Auth.isSystemAdmin(user) : false;

    const differences =
        !_.isEmpty(originalCase) && !_.isEmpty(Object.keys(currentCase))
            ? UIHelper.difference(_.cloneDeep(currentCase), _.cloneDeep(originalCase))
            : {};

    const hasUnsavedChanges = !_.isEmpty(differences);

    // FUNCTIONS

    const loadInitialData = async (caseGuid?: string) => {
        if (!user.active) return;

        setUser(user);

        if (!caseGuid) setIsReadOnlyMode(false);

        let currentCase: ICaseModel = {};
        let enableCaseFileEdit = false;
        let enablePlanningExecution = false;

        if (caseGuid) {
            // Edit existing case
            const caseAPIResponse = await fetchCaseDetailsById(caseGuid);
            if (!caseAPIResponse) {
                const validation: IValidation = {};
                validation.model = ['Case not found!'];
                setValidation(validation);
                return;
            }

            currentCase = caseAPIResponse;

            if (currentCase.organization) {
                if (currentCase.organization.enableCaseFileEdit) enableCaseFileEdit = true;
                if (currentCase.organization.enablePlanningExecution)
                    enablePlanningExecution = true;
            }
        } else {
            // Create New case
            const defaultOrganization = await fetchLoggedInUserDefaultOrganization();
            if (!defaultOrganization) return;
            if (defaultOrganization) {
                currentCase.organization = defaultOrganization;
                if (defaultOrganization.enableCaseFileEdit) enableCaseFileEdit = true;
                if (defaultOrganization.enablePlanningExecution) enablePlanningExecution = true;
            }

            const caseAssertionsForNewCase = await fetchCaseAssertionsForNewCase();
            if (!caseAssertionsForNewCase) return;
            currentCase.caseAssertions = caseAssertionsForNewCase;
        }

        // Custom Logic if Logged In User is an Employee of the Portfolio or Company organization. Always disable case File Edit and PlanningExecution
        const caseBelongsToUserOrg =
            (user.homeOrganization &&
                currentCase.organization!.primaryAssociatedOrganization?.guid ===
                    user.homeOrganization?.guid) ||
            user.homeOrganization!.guid == currentCase.organization!.guid;

        if (isSystemAdmin || caseBelongsToUserOrg) {
            enableCaseFileEdit = false;
            enablePlanningExecution = false;
        }

        setIsLoading(true);
        let hasErrors = false;
        await Promise.all([
            fetchCaseFileModel(),
            fetchCaseFileStaticItems(),
            fetchAssertionDescriptors(),
            fetchCaseStatusLookup(),
            fetchCaseManagers(),
            fetchAllUsersInZone(),
            fetchZoneDefenseCounsels(),
            fetchCaseNoteCategoryTypes(),
            fetchCaseFileDisplayCategories(),
        ])
            .then(
                ([
                    displaySections,
                    caseFileStaticItems,
                    assertionDescriptors,
                    caseStatusLookup,
                    caseManagersInZone,
                    allUsersInZones,
                    zoneDefenseCounsels,
                    caseNoteCategoryTypes,
                    displayCategories,
                ]) => {
                    setDisplaySections(displaySections ?? []);
                    setStaticItems(caseFileStaticItems ?? []);
                    setAssertionDescriptors(assertionDescriptors ?? []);
                    setCaseStatuses(caseStatusLookup ?? []);
                    setCaseManagers(caseManagersInZone ?? []);
                    setZoneUsers(allUsersInZones ?? []);
                    setZoneDefenseCounsels(zoneDefenseCounsels ?? []);
                    setCaseNoteCategoryTypes(caseNoteCategoryTypes ?? []);
                    setCaseDisplayCategories(displayCategories ?? []);

                    CaseDataCustomLogicHelper.Run(currentCase, assertionDescriptors ?? []);
                }
            )
            .catch((error: Error) => {
                hasErrors = true;
                setValidation(ValidateUtils.parseErrors([], error.message));
            })
            .finally(() => {
                setIsLoading(false);
            });

        if (hasErrors) return;

        setCurrentCase(currentCase);
        setOriginalCase(_.cloneDeep(currentCase));
        setOriginalNotes(currentCase.notes ? JSON.parse(JSON.stringify(currentCase.notes)) : []);

        setEnableCaseFileEdit(enableCaseFileEdit);
        setEnablePlanningExecution(enablePlanningExecution);
        setIsLoading(true);

        await fetchOrganizationSettings()
            .then((orgSettings) => setOrganizationSettings(orgSettings ?? []))
            .catch((error: Error) => setValidation(ValidateUtils.parseErrors([], error.message)))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const resetConfirmChanges = (originalCase: ICaseModel) => {
        setCurrentCase(_.cloneDeep(originalCase));
        setIsReadOnlyMode(true);
        setValidation({} as IValidation);
        setShowUnsavedChangesOnCancelDialog(false);
    };

    const getSingleValueEditControl = (caseAssertion: ICaseAssertionModel) => {
        const setStyleAsSystemAutoPopulated = CaseHelper.setStyleAsSystemAutoPopulated(
            caseAssertion,
            currentCase
        );
        const additionalStyleClass = setStyleAsSystemAutoPopulated ? ' font-green' : '';

        if (
            CaseHelper.isFieldReadOnly(caseAssertion?.assertionDescriptor?.guid ?? '', currentCase)
        ) {
            let value = '';
            if (
                caseAssertion.assertionDescriptor!.valueSourceType?.id ==
                AssertionDescriptorValueSourceTypeEnum.Selection.Value
            ) {
                if (
                    caseAssertion.assertionDescriptorValue &&
                    caseAssertion.assertionDescriptorValue.text
                )
                    value = caseAssertion.assertionDescriptorValue.text;
            } else if (caseAssertion.text) value = caseAssertion.text;

            return (
                <input
                    className={'form-control d-inline-block ms-2' + additionalStyleClass}
                    type="text"
                    name={caseAssertion.assertionDescriptor!.guid}
                    value={value}
                    readOnly={true}
                />
            );
        }

        if (
            caseAssertion.assertionDescriptor!.valueDisplayType?.id ===
            AssertionDescriptorDisplayValueTypeEnum.DateTime.Value
        )
            return (
                <input
                    className={
                        caseAssertion.text && Common.isValidDate(caseAssertion.text)
                            ? 'form-control d-inline-block ms-2'
                            : 'form-control d-inline-block ms-2 unselectClass' +
                              additionalStyleClass
                    }
                    type="date"
                    name={caseAssertion.assertionDescriptor!.guid}
                    value={caseAssertion.text}
                    onChange={handleChangeADTextValue}
                />
            );

        if (
            caseAssertion.assertionDescriptor!.valueDisplayType?.id ==
            AssertionDescriptorDisplayValueTypeEnum.Percentage.Value
        )
            return (
                <span>
                    <span>%</span>
                    <input
                        className={'form-control d-inline-block ms-2' + additionalStyleClass}
                        type="number"
                        name={caseAssertion.assertionDescriptor!.guid}
                        value={caseAssertion.text}
                        onChange={handleChangeADTextValue}
                    />
                </span>
            );

        if (
            caseAssertion.assertionDescriptor!.valueDisplayType?.id ==
            AssertionDescriptorDisplayValueTypeEnum.Currency.Value
        )
            return (
                <span style={{ marginLeft: '10px' }}>
                    <span>$</span>
                    <input
                        className={'form-control d-inline-block ms-2' + additionalStyleClass}
                        type="number"
                        name={caseAssertion.assertionDescriptor!.guid}
                        value={caseAssertion.text}
                        onChange={handleChangeADTextValue}
                    />
                </span>
            );

        if (
            caseAssertion.assertionDescriptor!.valueDisplayType?.id ==
            AssertionDescriptorDisplayValueTypeEnum.Number.Value
        )
            return (
                <input
                    className={'form-control d-inline-block ms-2' + additionalStyleClass}
                    type="number"
                    name={caseAssertion.assertionDescriptor!.guid}
                    value={caseAssertion.text}
                    onChange={handleChangeADTextValue}
                />
            );

        if (
            caseAssertion.assertionDescriptor!.valueDisplayType?.id ==
            AssertionDescriptorDisplayValueTypeEnum.ShortText.Value
        )
            return (
                <input
                    className={'form-control d-inline-block ms-2' + additionalStyleClass}
                    type="text"
                    maxLength={100}
                    name={caseAssertion.assertionDescriptor!.guid}
                    value={caseAssertion.text}
                    onChange={handleChangeADTextValue}
                />
            );

        if (
            caseAssertion.assertionDescriptor!.valueDisplayType?.id ==
            AssertionDescriptorDisplayValueTypeEnum.SSN.Value
        )
            return (
                <input
                    className={'form-control d-inline-block ms-2' + additionalStyleClass}
                    type="text"
                    placeholder="XXX-XX-XXXX"
                    maxLength={11}
                    name={caseAssertion.assertionDescriptor!.guid}
                    value={caseAssertion.text}
                    onChange={handleChangeADTextValue}
                />
            );

        if (
            caseAssertion.assertionDescriptor!.valueSourceType?.id ==
            AssertionDescriptorValueSourceTypeEnum.Boolean.Value
        )
            return (
                <div className={'d-inline-block ms-2'}>
                    <CheckmateSelect
                        options={CheckmateSelectHelper.getBooleanOptions()}
                        value={CheckmateSelectHelper.getBooleanValueFromString(caseAssertion.text)}
                        systemPopulated={setStyleAsSystemAutoPopulated}
                        onChange={(e: any) => {
                            handleAssertionDescriptorSearchableSingleSelectChange(
                                e,
                                caseAssertion.assertionDescriptor!.guid!,
                                true
                            );
                        }}
                    />
                </div>
            );

        if (
            caseAssertion.assertionDescriptor!.valueSourceType?.id ==
            AssertionDescriptorValueSourceTypeEnum.Selection.Value
        ) {
            return (
                <span className="d-inline-block ms-2">
                    <CheckmateSelect
                        options={CaseFileCheckmateSelectHelper.getOptionsForCheckmateSelect(
                            caseAssertion.assertionDescriptor!.assertionDescriptorValues!
                        )}
                        name={caseAssertion.assertionDescriptor!.guid!}
                        systemPopulated={setStyleAsSystemAutoPopulated}
                        value={CaseFileCheckmateSelectHelper.getCheckmateSelectValue(caseAssertion)}
                        onChange={(e: any) =>
                            handleAssertionDescriptorSearchableSingleSelectChange(
                                e,
                                caseAssertion.assertionDescriptor!.guid!
                            )
                        }
                    />
                </span>
            );
        }
    };

    // HANDLERS

    const handleSetPriorityDialogConfrim = async () => {
        if (!currentCase) return;

        setShowSetPriorityDialog(false);
        setIsLoading(true);
        const response = await _apiClient.updateCasePriority(
            currentCase.guid!,
            currentCase.priority ? 'false' : 'true'
        );

        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            return;
        }

        const updatedCase = { ...currentCase, priority: !currentCase.priority };

        setCurrentCase(updatedCase);
        setOriginalCase(updatedCase);
        setIsLoading(false);
    };

    const handleSaveNoteComplete = (noteItem: INoteModel) => {
        const caseCopy = { ...currentCase };
        const caseNotes = caseCopy.notes ?? [];
        const foundIdx = caseNotes?.findIndex((note) => note.guid === noteItem.guid);

        if (foundIdx > -1) {
            caseNotes[foundIdx] = noteItem;
        } else {
            caseNotes.push({ ...noteItem });
        }

        caseCopy.notes = caseNotes.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));

        const originalNotes = cloneDeep(caseCopy.notes);

        setCurrentNote(null);
        setCurrentCase(caseCopy);
        setOriginalCase((prev) => ({ ...prev, notes: originalNotes }));
        setOriginalNotes(originalNotes);
    };

    const handleCancelAddEditNote = () => {
        const validationCopy = { ...validation };
        validationCopy.categories = [];
        validationCopy.content = [];

        const caseCopy = { ...currentCase };
        caseCopy.notes = [...originalNotes];

        setCurrentCase(caseCopy);
        setForceNoteEditorInReadOnlyMode(false);
        setCurrentNote(null);
        setValidation(validationCopy);
    };

    const handleAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.CaseNote },
            status: { id: NoteStatusTypes.Open },
            caseGuid: currentCase.guid!,
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        setCurrentNote(currentNote);
        setForceNoteEditorInReadOnlyMode(false);
    };

    const handleSaveCase = async () => {
        const caseCopy: ICaseModel = cloneDeep(currentCase);

        caseCopy.defenseCounsels = caseCopy.defenseCounsels?.filter((dc) => !!dc.guid);

        // CHECK-2126 - notes, depositions & Case Experts are saved by independent API calls and not part of the save case.
        caseCopy.notes = [];
        caseCopy.depositions = [];
        caseCopy.caseExperts = [];

        setIsLoading(true);
        setValidation({});

        // SSN Validation - Quick and Easy option for now
        const ssnErrors =
            caseCopy.caseAssertions
                ?.filter(
                    (assertion) =>
                        assertion.assertionDescriptor?.name?.toUpperCase().includes('SSN') &&
                        assertion.text &&
                        !ValidateUtils.isValidSSN(assertion.text!)
                )
                .map(
                    (assertion) =>
                        'Invalid ' +
                        assertion.assertionDescriptor!.displayName! +
                        ". Enter a valid SSN in the format XXX-XX-XXXX (Use 0's if you only have last 4 of the SSN i.e. 000-000-1234) |"
                ) ?? [];

        const dateErrors =
            caseCopy.caseAssertions
                ?.filter(
                    (x) =>
                        x.assertionDescriptor?.valueDisplayType?.id ===
                            AssertionDescriptorValueDisplayTypes.DateTime.Value &&
                        x.text &&
                        !Common.isValidDate(x.text!)
                )
                .map(
                    (assertion) => 'Invalid ' + assertion.assertionDescriptor!.displayName! + '. |'
                ) ?? [];

        const errors: string[] = ssnErrors.concat(dateErrors);

        if (errors.length > 0) {
            const validation: IValidation = {};
            validation.model = errors;
            setIsLoading(false);
            setValidation(validation);
            return;
        }

        CaseHelper.updateUserOverrideFieldForCaseAssertions(caseCopy);

        let response: IApiResponse<ICaseModel>;

        if (caseCopy.guid) {
            response = await _apiClient.updateCase(caseCopy);
        } else {
            response = await _apiClient.createCase(caseCopy);
        }

        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            setIsLoading(false);
            return;
        }

        setOriginalCase(caseCopy);

        window.location.assign(LocalRoutes.CaseFile.replace(':guid', response.payload!.guid!));
        return;
    };

    const handleCancelClick = async () => {
        if (hasUnsavedChanges) {
            setShowUnsavedChangesOnCancelDialog(true);
            return;
        }

        if (currentCase.guid) {
            setIsReadOnlyMode(true);
        } else {
            navigate(LocalRoutes.Cases);
        }
    };

    const handleDeleteCaseDialogConfirm = async () => {
        if (!currentCase) return;

        setValidation({});
        setIsLoading(true);

        const response = await _apiClient.deleteCase(currentCase.guid!);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            setIsLoading(false);
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            return;
        }

        navigate(LocalRoutes.Cases);
    };

    const handleCaseExpertEditorSave = (updatedCaseExpert: ICaseExpertViewDetailModel) => {
        if (!currentCase) return;

        const caseCopy = cloneDeep(currentCase);
        const found = caseCopy.caseExperts?.find((ce) => ce.guid === updatedCaseExpert.guid);

        if (found) {
            found.disciplines = updatedCaseExpert.expert.disciplines ?? [];
            found.leadDefendant = updatedCaseExpert.leadDefendant ?? '';
            found.coDefendants = updatedCaseExpert.coDefendants ?? '';
            found.expertReportStatusTypeId = updatedCaseExpert.expertReportStatusTypeId ?? 0;
            found.expertRetainedByTypeId = updatedCaseExpert.expertRetainedByTypeId ?? 0;
            found.purposeOfRetention = updatedCaseExpert.purposeOfRetention;

            setCurrentCaseExpert({ ...found } as IViewCaseExpertModelWithDefaultEdit);
        }

        setCurrentCase(caseCopy);
        setOriginalCase(caseCopy);
    };

    const handleUpdateCaseNotes = (notes: INoteModel[]) => {
        const caseCopy = cloneDeep(currentCase);
        caseCopy.notes = [...notes];
        setCurrentCase(caseCopy);
        setNotesRefreshIdentifier(uniqueId);
    };

    // EFFFECTS

    useEffect(() => {
        if (!isLoadingUser && isLoggedIn) {
            if (!Authorization.isAuthorizedToRoute(LocalRoutes.CaseFile, user)) {
                navigate(LocalRoutes.AccessDenied);
            }

            loadInitialData(guid);
        }
    }, [user, isLoggedIn, isLoadingUser]);

    // RENDER

    if (isLoading) {
        return <Loader />;
    }

    if (currentNote !== null) {
        const availableEntityItems = currentCase.caseExperts?.map(
            (ce) =>
                ({
                    label: ce.name,
                    value: ce.guid,
                    guid: ce.guid,
                } as IMultiSelectOptions)
        );

        return (
            <NoteEditor
                authorizedToEdit={NoteHelper.isUserAuthorizedToEditNote(
                    currentNote,
                    user,
                    Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, user),
                    Authorization.userHasRight(UserRightsEnum.EditCaseNote, user),
                    Authorization.userHasRight(UserRightsEnum.AddCaseNote, user)
                )}
                caseLocalCounselGuid={CaseHelper.getCaseLocalCounselGuid(currentCase)}
                caseTitle={CaseHelper.getCaseTitle(currentCase)}
                currentNote={currentNote}
                defaultAlertTarget={CaseHelper.getDefaultAlertTargetForCase(user, currentCase)}
                defaultWatchTargets={CaseHelper.getDefaultWatchTargetsForCase(user, currentCase)}
                enableNoteAssociations={false}
                entityItems={availableEntityItems}
                entityType={{ id: EntityTypes.CaseExpert }}
                forceNoteEditorInReadOnlyMode={forceNoteEditorInReadOnlyMode}
                handleCancel={handleCancelAddEditNote}
                handleSaveComplete={handleSaveNoteComplete}
                noteAssociationModalTitle="Add Related Case Expert(s)"
                noteCategoryTypes={caseNoteCategoryTypes}
                useNoteAssociationsModal
                user={user}
            />
        );
    }

    if (currentCaseExpert) {
        return (
            <CaseExpertEditor
                caseExpertGuid={currentCaseExpert.guid}
                caseGuid={currentCase.guid}
                defaultIsEditMode={currentCaseExpert.defaultIsEditMode ?? false}
                onBackClick={() => setCurrentCaseExpert(null)}
                onUpdateCaseNotes={handleUpdateCaseNotes}
                onSave={handleCaseExpertEditorSave}
                user={user}
                noteRefreshIdentifier={notesRefreshIdentifier}
            />
        );
    }

    const headerSection = displaySections[0];

    return (
        <>
            <DocumentTitle title={currentCase.guid ? currentCase.caseName : 'Create Case'} />
            <UnsavedChangesBlocker when={hasUnsavedChanges && !isLoading} />

            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <CaseFileHeader
                    user={user}
                    enableCaseFileEdit={enableCaseFileEdit}
                    enablePlanningExecution={enablePlanningExecution}
                    isSystemAdmin={isSystemAdmin}
                    onClickAddNote={handleAddNote}
                    onClickDeleteCase={() => setShowDeleteCaseDialog(true)}
                    onClickSetCasePriority={() => setShowSetPriorityDialog(true)}
                    validation={validation}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveCase}
                />

                <div style={{ padding: '10px 24px' }}>
                    <CaseFileSection
                        numberOfColumns={3}
                        section={headerSection}
                        user={user}
                        validation={validation}
                        displayAsCondensed={true}
                    />
                </div>

                <hr style={{ margin: 0 }} />

                <div
                    style={{
                        height: '100%',
                        overflow: 'hidden',
                        overflowY: 'auto',
                    }}
                >
                    <div style={{ marginTop: '-1px' }}>
                        <CaseFileSections
                            sections={
                                displaySections.filter(
                                    (section) => section.id !== headerSection.id
                                ) ?? []
                            }
                            user={user}
                            validation={validation}
                        />
                    </div>
                </div>
            </div>

            <CheckmateDialog
                isShowingModal={showSetPriorityDialog}
                body={
                    currentCase?.priority
                        ? 'Removing Priority Status on a case will remove Priority Status on all tasks for the case. Are you sure you want to remove the Priority Status on this case?'
                        : 'Are you sure you want to mark this case as Priority?'
                }
                handleClose={() => {
                    setShowSetPriorityDialog(false);
                }}
                handleConfirm={handleSetPriorityDialogConfrim}
                confirmText="Yes"
                cancelText="No"
                confirmButtonClassName="btn btn-black float-end "
                dialogClassName="confirm-document-delete-dialog"
                closeButtonClassName="btn btn-default float-end"
            />

            <CheckmateDialog
                isShowingModal={showDeleteCaseDialog}
                body="Are you sure you want to delete this case? This operation is permanent and cannot be reverted back."
                handleClose={() => setShowDeleteCaseDialog(false)}
                handleConfirm={handleDeleteCaseDialogConfirm}
                confirmText="Yes"
                cancelText="No"
                confirmButtonClassName="btn btn-black float-end "
                dialogClassName="confirm-document-delete-dialog"
                closeButtonClassName="btn btn-default float-end"
            />

            <CheckmateDialog
                isShowingModal={showUnsavedChangesOnCancelDialog}
                title={'Unsaved Changes Detected'}
                body={'You have unsaved changes. Are you sure you want to proceed?'}
                handleClose={() => {
                    setShowUnsavedChangesOnCancelDialog(false);
                }}
                handleConfirm={() => {
                    setCurrentCase(_.cloneDeep(originalCase));
                    setShowUnsavedChangesOnCancelDialog(false);

                    if (currentCase.guid) {
                        setIsReadOnlyMode(true);
                        resetConfirmChanges(originalCase);
                    } else {
                        setTimeout(() => {
                            navigate(LocalRoutes.Cases);
                        }, 1);
                    }
                }}
                cancelText={'Cancel'}
                confirmText={'Continue'}
                closeButtonClassName="btn btn-default float-end"
                confirmButtonClassName="btn btn-black float-end"
                size="sm"
            />

            <AdditionalFieldsDialog
                assertionDescriptors={assertionDescriptors}
                currentCase={currentCase}
                getSingleValueEditControl={getSingleValueEditControl}
                onHide={() => setShowAdditionalFieldsDialog(false)}
                open={showAdditionalFieldsDialog}
                validation={validation}
                onCancelClick={() => setShowAdditionalFieldsDialog(false)}
                onOkClick={() => setShowAdditionalFieldsDialog(false)}
            />

            {currentNarrativeCaseAssertion && (
                <CaseNarrativeDialog
                    title={currentNarrativeCaseAssertion?.assertionDescriptor?.displayName ?? ''}
                    assertion={currentNarrativeCaseAssertion}
                    validation={validation}
                    onSubmit={(value: string) => {
                        handleNarrativeChange(
                            currentNarrativeCaseAssertion?.assertionDescriptor?.guid ?? '',
                            value
                        );
                        setCurrentNarrativeCaseAssertion(null);
                    }}
                    onCancel={() => setCurrentNarrativeCaseAssertion(null)}
                />
            )}
        </>
    );
}

// SHOULD BE SERVICE METHODS

const fetchCaseFileModel = async () => {
    const response = await _apiClient.getCaseFileModel();
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        return;
    }

    return response.payload;
};

const fetchCaseFileStaticItems = async () => {
    const response = await _apiClient.getCaseFileStaticItems();
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        return;
    }
    return response.payload;
};

const fetchCaseFileDisplayCategories = async () => {
    const response = await _apiClient.getCaseFileDisplayCategories();
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        return;
    }
    return response.payload;
};

const fetchAssertionDescriptors = async () => {
    const response = await _apiClient.getAssertionDescriptors(
        AssertionDescriptorUsageTypesEnum.Case
    );
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        return;
    }
    return response.payload;
};

const fetchCaseDetailsById = async (id: string) => {
    const response = await _apiClient.getCaseById(id);
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        return;
    }
    return response.payload;
};

const fetchLoggedInUserDefaultOrganization = async () => {
    const response = await _apiClient.getDefaultUserOrganizationFromServer();
    if (!response) return;
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        return;
    }
    return response.payload;
};

const fetchCaseAssertionsForNewCase = async () => {
    const response = await _apiClient.getAssertionDescriptors(
        AssertionDescriptorUsageTypesEnum.Case
    );
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        return;
    }
    const caseAssertions: ICaseAssertionModel[] = [];
    response
        .payload!.filter(
            (x) => x.valueSourceType!.id != AssertionDescriptorValueSourceTypeEnum.Implied.Value
        )
        .map((assertionDescriptor: IAssertionDescriptorModel) => {
            const caseAssertion: ICaseAssertionModel = {
                assertionDescriptor: assertionDescriptor,
            };
            caseAssertions.push(caseAssertion);
        });
    return caseAssertions;
};

const fetchCaseStatusLookup = async () => {
    const response = await _apiClient.getCaseStatusLookupData();
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    return response.payload;
};

const fetchCaseManagers = async () => {
    const response = await _apiClient.getCaseManagersInZone();
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    return response.payload;
};

const fetchAllUsersInZone = async () => {
    const response = await _apiClient.getUsersInZone();
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    return response.payload;
};

const fetchZoneDefenseCounsels = async () => {
    const response = await _apiClient.getOrganizationInZone(OrganizationTypesEnum.Firm.Value);
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    return response.payload;
};

const fetchCaseNoteCategoryTypes = async () => {
    const response = await _apiClient.getNoteCategoryTypes(LookupDataEnums.CaseNoteCategories);
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    return response.payload;
};

const fetchOrganizationSettings = async () => {
    const response = await _apiClient.getOrganizationSettings();
    if (response.httpResponse.status == 401) {
        window.location.reload();
        return;
    }
    if (response.errorMessage) {
        throw new Error(response.errorMessage);
    }

    return response.payload;
};
