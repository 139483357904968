import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetExpertKey = 'get-expert';

export const useGetExpert = (id?: string) =>
    useQuery({
        queryKey: [useGetExpertKey, id],
        queryFn: async ({ queryKey }) => {
            const [, expertGuid] = queryKey;
            const response = await _apiClient.getExpertById(expertGuid ?? '');

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve this expert.');
            }
            return response.payload;
        },
        enabled: !!id,
    });
