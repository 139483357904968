import classNames from 'classnames';

function SortData(props: {
    fieldName: string;
    sortData: (
        fieldName: string,
        order: string,
        subKey?: string,
        subGrandKey?: string,
        dataObjectIdentifier?: number
    ) => void;
    dataObjectIdentifier?: number;
    subKey?: string;
    subGrandKey?: string;
    stackControls?: boolean;
}) {
    const handleSort = (sortDirection?: string) => {
        props.sortData(
            props.fieldName,
            sortDirection || 'asc',
            props.subKey,
            props.subGrandKey,
            props.dataObjectIdentifier
        );
    };

    const spanClass = classNames({
        'stacked-sort-controls': props.stackControls,
    });

    return (
        <div className={spanClass} style={props.stackControls ? {} : undefined}>
            <button className="btn-no-bg sort-arrow">
                <i
                    className="fal fa-lg fa-angle-up"
                    onClick={() => {
                        handleSort('desc');
                    }}
                />
            </button>
            <button className="btn-no-bg sort-arrow">
                <i
                    className="fal fa-lg fa-angle-down"
                    onClick={() => {
                        handleSort();
                    }}
                />
            </button>
        </div>
    );
}

export default SortData;
