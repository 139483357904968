import { Accordion } from 'react-bootstrap';
import { IDisplaySectionModel } from '../../interfaces/IDisplaySectionModel';
import { useCaseFileContext } from '../../contexts/CaseFileContext';

const pixelsPerLevel = 8;

interface IAccordionWrapperProps {
    accordionIndex: number;
    children: JSX.Element;
    className?: string;
    isExpandable: boolean;
    isLastSection: boolean;
    isOpen: boolean;
    isTopLevel: boolean;
    onAccordionSelectChange?: (idx: string, section: IDisplaySectionModel) => void;
    section: IDisplaySectionModel;
    shouldWrap: boolean;
}

export function AccordionWrapper({
    accordionIndex,
    children,
    className,
    isExpandable,
    isOpen,
    isLastSection,
    isTopLevel,
    onAccordionSelectChange,
    section,
    shouldWrap,
}: IAccordionWrapperProps) {
    const { isReadOnlyMode, caseDisplayCategories } = useCaseFileContext();

    const showToggleIcon = isTopLevel && isExpandable;
    const pixelsToIndent = isTopLevel ? 30 : pixelsPerLevel;

    const headerName =
        section.displayName ??
        caseDisplayCategories.find((lu) => lu.id === section.id)?.displayName ??
        '';

    const handleHeaderClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!isTopLevel || !isExpandable) e.stopPropagation();
    };

    if (!shouldWrap) {
        return <div>{children}</div>;
    }

    return (
        <Accordion.Item
            key={accordionIndex}
            eventKey={accordionIndex.toString()}
            onClick={() => {
                if (typeof onAccordionSelectChange === 'function')
                    onAccordionSelectChange(accordionIndex.toString(), section);
            }}
            className={className}
        >
            <>
                {!section.hideHeader && (
                    <Accordion.Header as="h5" onClick={handleHeaderClick}>
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 5,
                                paddingLeft: isTopLevel && !isExpandable ? '18px' : '',
                            }}
                        >
                            {showToggleIcon && <ToggleIcon isOpen={isOpen} />}

                            <span className={'accordion-header-text'}>{headerName}</span>
                        </div>
                    </Accordion.Header>
                )}
                {((isTopLevel && isExpandable) || !isTopLevel) && (
                    <>
                        <Accordion.Body
                            onClick={(e) => e.stopPropagation()}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <div
                                style={{
                                    marginLeft: pixelsToIndent,
                                    marginTop: isReadOnlyMode ? '-5px' : 'auto',
                                    marginBottom: isReadOnlyMode ? '-5px' : 'auto',
                                }}
                            >
                                {children}
                            </div>
                        </Accordion.Body>
                    </>
                )}
                {isTopLevel && <hr style={{ margin: 0 }} />}

                {!isTopLevel && !isLastSection && section.level === 2 && (
                    <hr
                        style={{
                            margin: '10px auto 15px auto',
                            borderColor: '#dddddd',
                            opacity: 1,
                        }}
                    />
                )}
            </>
        </Accordion.Item>
    );
}

function ToggleIcon({ isOpen }: { isOpen: boolean }) {
    return (
        <>
            {isOpen ? (
                <i className="far color-black fa-minus casefile-category-icon-minus" />
            ) : (
                <i className="far color-black fa-plus casefile-category-icon-plus" />
            )}
        </>
    );
}
