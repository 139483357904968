import { IBoardParametersModel } from "../interfaces/Report/IBoard";
import { BoardCriteriaEnum, BoardFilterDateTypesEnum, CaseStatusTypesEnum, CaseSubStatusTypesEnum } from "./Constants";

export default class BoardHelper {

    static getBoardReportParameters(boardPreferences: IBoardParametersModel, parentReportName?: string, commentText?: string) {
        let value: any = {
            statusId: boardPreferences.statusId,
            subStatusIds: boardPreferences.subStatusIds || '',
            criteria: boardPreferences.criteria,
            dateFilterType: boardPreferences.date!.type,
            startDate: boardPreferences.date!.startDate,
            endDate: boardPreferences.date!.endDate,
            includeTimeline: this.isTimelineReportValid(boardPreferences),
            preferences: btoa(JSON.stringify(boardPreferences)),
            parentReportName: parentReportName,
            commentText: commentText
        }

        return value;
    }

    static isTimelineReportValid(boardPreferences: IBoardParametersModel)
    {
        if (boardPreferences.statusId == 0 || boardPreferences.date == null) return false;

        switch (boardPreferences.statusId)
        {
            case CaseStatusTypesEnum.Open.Value:
                switch (boardPreferences.criteria)
                {
                    case BoardCriteriaEnum.CaseVolume.Value:
                        {
                            switch (boardPreferences.date.type)
                            {
                                case BoardFilterDateTypesEnum.AsOfDate.Value:
                                case BoardFilterDateTypesEnum.ServiceDate.Value:
                                case BoardFilterDateTypesEnum.TrialDate.Value:
                                case BoardFilterDateTypesEnum.ProjectedSettlementDate.Value:
                                    return true;
                                default:
                                    break;
                            }
                        }
                        break;
                    case BoardCriteriaEnum.ProjectedSettlement.Value:
                        {
                            switch (boardPreferences.date.type)
                            {
                                case BoardFilterDateTypesEnum.ServiceDate.Value:
                                case BoardFilterDateTypesEnum.TrialDate.Value:
                                case BoardFilterDateTypesEnum.ProjectedSettlementDate.Value:
                                    return true;
                                default:
                                    break;
                            }
                        }
                        break;
                }
                break;
            case CaseStatusTypesEnum.Resolved.Value:
                switch (boardPreferences.criteria)
                {
                    case BoardCriteriaEnum.CaseVolume.Value:
                        {
                            switch (boardPreferences.date.type)
                            {
                                case BoardFilterDateTypesEnum.ServiceDate.Value:
                                case BoardFilterDateTypesEnum.TrialDate.Value:
                                case BoardFilterDateTypesEnum.ResolutionDate.Value:
                                    return true;
                                default:
                                    break;
                            }
                        }
                        break;
                    case BoardCriteriaEnum.ActualSettlement.Value:
                        if (boardPreferences.date.type == BoardFilterDateTypesEnum.ResolutionDate.Value && boardPreferences.subStatusIdList != null && boardPreferences.subStatusIdList.length == 1 && boardPreferences.subStatusIdList[0] == CaseSubStatusTypesEnum.Settled.Value)
                            return true;
                        break;
                }
                break;
        }

        return false;
    }

}