import { useLocation, useParams } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { WorkProductDetail } from '../case/WorkProductDetail';

function WorkProductDetailWrapper(props: { user: IUserModel }) {
    const { guid, documentguid, entityguid, entitytypeid } = useParams();
    const location = useLocation();
    let redirectUrl: string = '';
    let parentEntityJson: string = '';

    if (location.state) {
        // AR TODO
    }

    return (
        <WorkProductDetail
            guid={guid || ''}
            user={props.user}
            documentguid={documentguid || ''}
            entityguid={entityguid || ''}
            entitytypeid={entitytypeid || ''}
            parentEntityJson={parentEntityJson}
            redirectUrl={redirectUrl}
        />
    );
}

export default WorkProductDetailWrapper;
