interface IExpandableRowProps {
    children: React.ReactNode;
    colSpan?: number;
    columnPlaceholderCount?: number;
    contentMarginLeft?: number;
    isExpanded: boolean;
    expandElement?: React.ReactNode;
}

function ExpandableRow(props: IExpandableRowProps) {
    const placeholderCells = Array.from(
        { length: props.columnPlaceholderCount ?? 0 },
        (_, index) => index
    );

    return (
        <tr>
            <td colSpan={props.colSpan}>
                <div>
                    <div>{props.expandElement}</div>
                    <div
                        style={{
                            marginLeft: props.contentMarginLeft,
                            visibility: props.isExpanded ? 'visible' : 'collapse',
                            height: props.isExpanded ? 'auto' : 0,
                        }}
                    >
                        <div
                            style={{
                                paddingBottom: '1em',
                            }}
                        >
                            {props.children}
                        </div>
                    </div>
                </div>
            </td>
            {placeholderCells.map((_, index) => (
                <td key={index} />
            ))}
        </tr>
    );
}

export default ExpandableRow;
