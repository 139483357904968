import {
    ApplicationHelpType,
    EntityTypes,
    ExpertReportStatusTypesEnum,
    ExpertRetainedByTypesEnum,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    UserRightsEnum,
} from '../../../../utilities/Constants';
import {
    ICaseExpertViewDetailModel,
    IUpdateCaseExpertModel,
    IViewCaseExpertActivityItemModel,
} from '../../../../interfaces/ICaseExpert';
import { ICaseModel, ICaseTitleModel, IDocumentModel } from '../../../../interfaces/ICase';
import { useEffect, useState } from 'react';

import Authorization from '../../../../stores/Authorization';
import { CaseExpertActivities } from './CaseExpertActivities';
import CaseHelper from '../../../../utilities/CaseHelper';
import { CaseName } from '../../CaseName';
import CheckmateSelect from '../../../shared/CheckmateSelect';
import { Help } from '../../../shared/Help';
import { IMultiSelectOptions } from '../../../../interfaces/ILookup';
import { INoteModel } from '../../../../interfaces/INote';
import { IUserModel } from '../../../../interfaces/IUser';
import { Link } from 'react-router-dom';
import { Loader } from '../../../shared/Loader';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { NoteEditor } from '../../../notes/NoteEditor';
import NoteHelper from '../../../../utilities/NoteHelper';
import { NoteListWrapper } from '../NoteListWrapper';
import Sort from '../../../../stores/Sort';
import UIHelper from '../../../../utilities/UIHelper';
import { WorkProductWrapper } from '../../../work-product/WorkProductWrapper';
import { cloneDeep } from 'lodash';
import { useCaseExpertFetch } from './useCaseExpertFetch';
import { useCaseExpertUpdateMutation } from './useCaseExpertUpdateMutation';
import { useCaseFetch } from '../../useCaseFetch';
import { useCaseFileContext } from '../../../../contexts/CaseFileContext';
import { useExpertReportStatusTypesLookup } from '../../../../shared/react-query-hooks/useExpertReportStatusTypesLookup';
import { useExpertRetainedByTypesLookup } from '../../../../shared/react-query-hooks/useExpertRetainedByTypesLookup';
import { useNoteCategoryTypes } from '../../../../shared/react-query-hooks/useNoteCategoryTypes';

export interface ICaseExpertEditorProps {
    caseExpertGuid: string;
    caseGuid?: string;
    defaultIsEditMode: boolean;
    onBackClick: () => void;
    onSave: (updatedCaseExpert: ICaseExpertViewDetailModel) => void;
    onUpdateCaseNotes?: (notes: INoteModel[]) => void;
    noteRefreshIdentifier?: string;
    user: IUserModel;
}

export function CaseExpertEditor({
    caseExpertGuid,
    caseGuid,
    defaultIsEditMode,
    onBackClick,
    onSave,
    onUpdateCaseNotes,
    noteRefreshIdentifier,
    user,
}: ICaseExpertEditorProps) {
    const { currentNote, setCurrentNote } = useCaseFileContext();
    const { data: caseNoteCategoryTypes } = useNoteCategoryTypes();

    const {
        data: caseExpert,
        isLoading: caseExpertIsLoading,
        refetch: refetchCaseExpert,
    } = useCaseExpertFetch(caseExpertGuid);
    const {
        data: currentCase,
        isLoading: currentCaseIsLoading,
        refetch: refetchCase,
    } = useCaseFetch(caseGuid);

    const [isEditMode, setIsEditMode] = useState<boolean>(defaultIsEditMode);
    const [forceNoteEditorInReadOnlyMode, setForceNoteEditorInReadOnlyMode] =
        useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [localCaseExpert, setLocalCaseExpert] = useState<ICaseExpertViewDetailModel>(
        caseExpert ?? ({} as ICaseExpertViewDetailModel)
    );
    const userExpertRightsAreRestrictedByReportStatus = Authorization.userHasRight(
        UserRightsEnum.RestrictExpertReport,
        user
    );
    const cannotViewOrDownloadDocument =
        userExpertRightsAreRestrictedByReportStatus &&
        localCaseExpert.expertReportStatusTypeId != ExpertReportStatusTypesEnum.Disclosed;

    const retainedByTypes = useExpertRetainedByTypesLookup();

    const selectedRetainedByType = retainedByTypes.data?.find(
        (type) => type.id === localCaseExpert.expertRetainedByTypeId
    );

    const reportStatusTypes = useExpertReportStatusTypesLookup();

    const selectedReportStatus = reportStatusTypes.data?.find(
        (type) => type.id === localCaseExpert.expertReportStatusTypeId
    );

    const updateMutation = useCaseExpertUpdateMutation((errorMessage: string) =>
        setError(errorMessage)
    );

    const showDefendants = [
        ExpertRetainedByTypesEnum.JointCodefendants,
        ExpertRetainedByTypesEnum.JointCompany,
    ].includes(selectedRetainedByType?.id ?? -1);

    const disciplinesAsString = caseExpert?.expert?.disciplines
        ?.map((discipline) => discipline.displayName)
        ?.join(' | ');

    const caseExpertNotes =
        currentCase?.notes?.filter((note) =>
            note.associations?.some((assoc) => assoc.entityGuid === caseExpert?.guid)
        ) ?? [];

    const handleSaveClick = () => {
        if (!localCaseExpert) return;

        if (
            !currentCase?.uniqueCaseId ||
            !localCaseExpert.expertRetainedByTypeId ||
            !currentCase?.guid
        )
            return;

        const mappedFormData: IUpdateCaseExpertModel = {
            caseExpertActivities: localCaseExpert.caseExpertActivities ?? [],
            caseId: currentCase?.guid,
            expertId: localCaseExpert.expert?.guid,
            expertRetainedByTypeId: localCaseExpert.expertRetainedByTypeId,
            expertReportStatusTypeId:
                localCaseExpert.expertReportStatusTypeId !== undefined &&
                localCaseExpert.expertReportStatusTypeId <= 0
                    ? undefined
                    : localCaseExpert.expertReportStatusTypeId,
            guid: localCaseExpert?.guid,
            caseOpinion: localCaseExpert.caseOpinion,
            purposeOfRetention: localCaseExpert.purposeOfRetention,
            leadDefendant: showDefendants ? localCaseExpert.leadDefendant?.trim() : undefined,
            coDefendants: showDefendants ? localCaseExpert.coDefendants?.trim() : undefined,
        };

        updateMutation
            .mutateAsync(mappedFormData)
            .then((response) => {
                if (response.payload) {
                    onSave(response.payload!);
                    refetchCaseExpert();
                    refetchCase();
                }
                setIsEditMode(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleCancelClick = () => {
        if (caseExpert) {
            setLocalCaseExpert(cloneDeep(caseExpert));
        }
        setIsEditMode(false);
    };

    const handleEditClick = () => {
        setIsEditMode(true);
    };

    const handleSaveWorkProductComplete = (document: IDocumentModel) => {
        if (!caseExpert) {
            return;
        }
        const caseExpertCopy = cloneDeep(localCaseExpert);

        if (!caseExpertCopy?.documents) {
            caseExpertCopy.documents = [document];
        } else {
            const foundIndex = caseExpertCopy.documents.findIndex((d) => d.guid === document.guid);
            if (foundIndex > -1) {
                caseExpertCopy.documents.splice(foundIndex, 1, document);
            } else {
                caseExpertCopy.documents.push(document);
            }
        }

        onSave(caseExpertCopy);
        setLocalCaseExpert(caseExpertCopy);
    };

    const handleDeleteWorkProductComplete = (documentGuid: string) => {
        if (!localCaseExpert) {
            return;
        }
        const caseExpertCopy = cloneDeep(localCaseExpert);
        if (caseExpertCopy?.documents) {
            caseExpertCopy.documents = caseExpertCopy.documents.filter(
                (x) => x.guid !== documentGuid
            );
        }
        onSave(caseExpertCopy);
        setLocalCaseExpert(caseExpertCopy);
    };

    const handleLeadDefendantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalCaseExpert((prev) => ({ ...prev, leadDefendant: e.target.value }));
    };

    const handleCoDefendantChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocalCaseExpert((prev) => ({ ...prev, coDefendants: e.target.value }));
    };

    const handlePurposeOfRetentionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocalCaseExpert((prev) => ({ ...prev, purposeOfRetention: e.target.value }));
    };

    const handleCaseOpinionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocalCaseExpert((prev) => ({ ...prev, caseOpinion: e.target.value }));
    };

    const handleActivityValueChange = (activity: IViewCaseExpertActivityItemModel) => {
        setLocalCaseExpert((prev) => {
            const caseExpertCopy = { ...prev };
            const activitiesCopy = [...caseExpertCopy.caseExpertActivities];
            const found = activitiesCopy.find((cea) => cea.typeId === activity.typeId);

            if (found) {
                found.statusId = activity.statusId;
                found.dateCompleted = activity.dateCompleted;
            }

            return {
                ...prev,
                caseExpertActivities: activitiesCopy,
            };
        });
    };

    const handleSelectedRetainedByTypeChange = (selected: { label: string; value: string }) => {
        const valueAsNumber = parseInt(selected?.value);
        setLocalCaseExpert((prev) => ({
            ...prev,
            expertRetainedByTypeId: !isNaN(valueAsNumber) ? valueAsNumber : undefined,
        }));
    };

    const handleSelectedReportStatusTypeChange = (selected: { label: string; value: string }) => {
        const valueAsNumber = parseInt(selected?.value);
        setLocalCaseExpert((prev) => ({
            ...prev,
            expertReportStatusTypeId: !isNaN(valueAsNumber) ? valueAsNumber : undefined,
        }));
    };

    const handleAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.CaseNote },
            status: { id: NoteStatusTypes.Open },
            caseGuid: currentCase?.guid,
            purpose: { id: NotePurposeTypesEnum.Info },
            associations: [
                {
                    entityType: { id: EntityTypes.CaseExpert },
                    entityGuid: caseExpert?.guid,
                    displayName: UIHelper.formatExpertName(caseExpert?.expert),
                },
            ],
        };

        setCurrentNote(currentNote);
        setForceNoteEditorInReadOnlyMode(false);
    };

    const handleViewEditNote = (noteGuidToEdit: string, readOnly?: boolean) => {
        if (!currentCase || !currentCase?.notes) {
            return;
        }

        const currentNote = currentCase.notes.find((x) => x.guid == noteGuidToEdit);

        if (currentNote) {
            setCurrentNote(currentNote);
        }
        setForceNoteEditorInReadOnlyMode(readOnly ?? false);
    };

    const handleRefreshNotesListParent = (notes: INoteModel[]) => {
        if (typeof onUpdateCaseNotes === 'function') {
            onUpdateCaseNotes([...notes]);
        }
    };

    const handleSaveNoteComplete = (noteItem: INoteModel) => {
        const caseCopy = { ...currentCase };
        const caseNotes = caseCopy.notes ?? [];
        const foundIdx = caseNotes?.findIndex((note) => note.guid == noteItem.guid);

        if (foundIdx > -1) {
            caseNotes[foundIdx] = noteItem;
        } else {
            caseNotes.push({ ...noteItem });
        }

        caseCopy.notes = caseNotes.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));

        setCurrentNote(null);
    };

    const handleCancelAddEditNote = () => {
        setCurrentNote(null);
    };

    useEffect(() => {
        if (caseExpert) {
            setLocalCaseExpert(caseExpert);
        }
    }, [caseExpert]);

    useEffect(() => {
        refetchCase();
    }, [noteRefreshIdentifier]);

    if (caseExpertIsLoading || !caseExpert || currentCaseIsLoading || !currentCase) {
        return <Loader />;
    }

    if (currentNote !== null) {
        const availableEntityItems = currentCase.caseExperts?.map(
            (ce) =>
                ({
                    label: ce.name,
                    value: ce.guid,
                    guid: ce.guid,
                } as IMultiSelectOptions)
        );

        return (
            <NoteEditor
                forceNoteEditorInReadOnlyMode={forceNoteEditorInReadOnlyMode}
                authorizedToEdit={NoteHelper.isUserAuthorizedToEditNote(
                    currentNote,
                    user,
                    Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, user),
                    Authorization.userHasRight(UserRightsEnum.EditCaseNote, user),
                    Authorization.userHasRight(UserRightsEnum.AddCaseNote, user)
                )}
                caseLocalCounselGuid={CaseHelper.getCaseLocalCounselGuid(currentCase)}
                defaultAlertTarget={CaseHelper.getDefaultAlertTargetForCase(user, currentCase)}
                defaultWatchTargets={CaseHelper.getDefaultWatchTargetsForCase(user, currentCase)}
                caseTitle={CaseHelper.getCaseTitle(currentCase)}
                user={user}
                noteCategoryTypes={caseNoteCategoryTypes ?? []}
                currentNote={currentNote}
                handleSaveComplete={handleSaveNoteComplete}
                handleCancel={handleCancelAddEditNote}
                enableNoteAssociations={false}
                useNoteAssociationsModal
                noteAssociationModalTitle="Add Related Case Expert(s)"
                entityType={{ id: EntityTypes.CaseExpert }}
                entityItems={availableEntityItems}
            />
        );
    }

    return (
        <>
            {updateMutation.isLoading && <Loader />}
            <div className="row mb-3">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <h1>
                        <CaseName
                            caseTitle={getCaseTitle(currentCase, false)}
                            user={user}
                            openLinkInNewTab
                            additionalText="- Experts"
                        />
                    </h1>
                    <Buttons
                        allowEdit={Authorization.userHasRight(
                            UserRightsEnum.UpdateCaseExpert,
                            user
                        )}
                        isEditMode={isEditMode}
                        onSaveClick={handleSaveClick}
                        onCancelClick={handleCancelClick}
                        onEditClick={handleEditClick}
                        onBackClick={onBackClick}
                    />
                </div>
            </div>
            {error && (
                <div>
                    <span className="text-danger">{error}</span>
                </div>
            )}
            <div className="row my-2" style={{ alignItems: 'center' }}>
                <label className="col-sm-2 text-gray">Name</label>
                <div className="col-sm-10">
                    <Link
                        to={LocalRoutes.ExpertDetail.replace(':id', localCaseExpert.expert?.guid)}
                        target="_blank"
                    >
                        {UIHelper.formatExpertName(caseExpert?.expert)}
                    </Link>
                </div>
            </div>
            <div className="row my-2" style={{ alignItems: 'center' }}>
                <label className="col-sm-2 text-gray">Discipline(s)</label>
                <div className="col-sm-10">{disciplinesAsString}</div>
            </div>
            <div className="row my-2" style={{ alignItems: 'center' }}>
                <label className="col-sm-2 text-gray">Retained by</label>
                <div className="col-sm-10">
                    {isEditMode ? (
                        <div style={{ maxWidth: 250 }}>
                            <CheckmateSelect
                                options={
                                    retainedByTypes.data?.map(
                                        (lookup) =>
                                            ({
                                                id: lookup.id,
                                                label: lookup.displayName,
                                                value: lookup.id.toString(),
                                            } as IMultiSelectOptions)
                                    ) ?? []
                                }
                                name="retainedByType"
                                value={
                                    selectedRetainedByType
                                        ? [
                                              {
                                                  label: selectedRetainedByType.displayName,
                                                  value: selectedRetainedByType.id.toString(),
                                              } as IMultiSelectOptions,
                                          ]
                                        : []
                                }
                                onChange={handleSelectedRetainedByTypeChange}
                            />
                        </div>
                    ) : (
                        <>{selectedRetainedByType?.displayName}</>
                    )}
                </div>
            </div>

            {showDefendants && (
                <>
                    <div className="row my-2" style={{ alignItems: 'flex-start' }}>
                        <label className="col-sm-2 text-gray">Lead Defendant</label>
                        <div className="col-sm-10">
                            {isEditMode ? (
                                <div style={{ maxWidth: 250 }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={localCaseExpert.leadDefendant ?? ''}
                                        name="leadDefendant"
                                        onChange={handleLeadDefendantChange}
                                        maxLength={3000}
                                    />
                                </div>
                            ) : (
                                <>{caseExpert?.leadDefendant?.trim()}</>
                            )}
                        </div>
                    </div>
                    <div className="row my-2" style={{ alignItems: 'flex-start' }}>
                        <label className="col-sm-2 text-gray">Co-Defendants</label>
                        <div className="col-sm-10">
                            {isEditMode ? (
                                <textarea
                                    className="form-control"
                                    value={localCaseExpert.coDefendants ?? ''}
                                    name="coDefendants"
                                    rows={6}
                                    onChange={handleCoDefendantChange}
                                    maxLength={3000}
                                />
                            ) : (
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                    {caseExpert?.coDefendants}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}

            <div className="row my-2" style={{ alignItems: 'flex-start' }}>
                <label className="col-sm-2 text-gray">Purpose of Retention</label>
                <div className="col-sm-10">
                    {isEditMode ? (
                        <textarea
                            className="form-control"
                            value={localCaseExpert.purposeOfRetention ?? ''}
                            name="purposeOfRetention"
                            rows={6}
                            onChange={handlePurposeOfRetentionChange}
                            maxLength={1500}
                        />
                    ) : (
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {caseExpert?.purposeOfRetention}
                        </div>
                    )}
                </div>
            </div>
            <div className="row my-2" style={{ alignItems: 'flex-start' }}>
                <label className="col-sm-2 text-gray">Case opinions</label>
                <div className="col-sm-10">
                    {isEditMode ? (
                        <textarea
                            className="form-control"
                            value={localCaseExpert.caseOpinion ?? ''}
                            name="noteContent"
                            rows={6}
                            onChange={handleCaseOpinionChange}
                            maxLength={1500}
                        />
                    ) : (
                        <div style={{ whiteSpace: 'pre-wrap' }}>{caseExpert?.caseOpinion}</div>
                    )}
                </div>
            </div>
            <div className="row my-2" style={{ alignItems: 'flex-start' }}>
                <label className="col-sm-2 text-gray">
                    Activity{' '}
                    <Help
                        type={ApplicationHelpType.Info}
                        title="Activity"
                        helpText="These fields can be edited from the Case File or Easy Update"
                    />
                </label>
                <div className="col-sm-10">
                    <CaseExpertActivities
                        activities={[...(localCaseExpert.caseExpertActivities ?? [])]}
                        isEditMode={isEditMode}
                        onActivityValueChange={handleActivityValueChange}
                    />
                </div>
            </div>
            <div className="row my-2" style={{ alignItems: 'center' }}>
                <label className="col-sm-2 text-gray">
                    Report Status
                    <Help
                        type={ApplicationHelpType.Info}
                        title="Expert Report Status"
                        helpText="Expert Report status is updated by assigned NCC users; depending on your user access levels, you may not be able to download the report unless the status is 'disclosed'. "
                    />
                </label>
                <div className="col-sm-10">
                    {!userExpertRightsAreRestrictedByReportStatus && isEditMode ? (
                        <div style={{ maxWidth: 250 }}>
                            <CheckmateSelect
                                options={reportStatusTypes.data ?? []}
                                name="reportStatus"
                                value={reportStatusTypes.data?.find(
                                    (rst) => rst.id === localCaseExpert.expertReportStatusTypeId
                                )}
                                onChange={handleSelectedReportStatusTypeChange}
                            />
                        </div>
                    ) : (
                        <>{selectedReportStatus?.label}</>
                    )}
                </div>
            </div>
            <div className="row" id="AttachmentsDiv">
                <WorkProductWrapper
                    uploadOnly
                    user={user}
                    entityGuid={localCaseExpert?.guid}
                    entityTypeId={EntityTypes.CaseExpert}
                    title="Attachments"
                    documents={localCaseExpert?.documents}
                    parentEntityJson={JSON.stringify(localCaseExpert)}
                    parentEntityRedirectUrl={LocalRoutes.CaseExpertDetail.replace(
                        ':id',
                        localCaseExpert?.guid
                    )}
                    authorizedToEdit={Authorization.userHasRight(
                        UserRightsEnum.UpdateCaseExpert,
                        user
                    )}
                    cannotViewOrDownloadDocument={cannotViewOrDownloadDocument}
                    onSaveComplete={handleSaveWorkProductComplete}
                    onDeleteComplete={handleDeleteWorkProductComplete}
                />
            </div>
            <div className="my-2 mt-4">
                <div className="text-gray">Case Notes</div>
                <div style={{ marginLeft: '-12px' }}>
                    <NoteListWrapper
                        allowAddNew
                        allowDelete
                        allowEdit
                        allowView
                        entityType={EntityTypes.CaseExpert}
                        notes={caseExpertNotes}
                        onAddNote={handleAddNote}
                        onViewEditNote={handleViewEditNote}
                        onRefreshParent={handleRefreshNotesListParent}
                        user={user}
                    />
                </div>
            </div>
        </>
    );
}

interface IButtonsProps {
    allowEdit?: boolean;
    isEditMode: boolean;
    onEditClick: () => void;
    onCancelClick: () => void;
    onSaveClick: () => void;
    onBackClick: () => void;
}

function Buttons({
    allowEdit,
    isEditMode,
    onBackClick,
    onCancelClick,
    onEditClick,
    onSaveClick,
}: IButtonsProps) {
    return (
        <div>
            {isEditMode ? (
                <>
                    <button type="button" className="btn btn-orange me-2" onClick={onSaveClick}>
                        Save
                    </button>
                    <button type="button" className="btn btn-default" onClick={onCancelClick}>
                        Cancel
                    </button>
                </>
            ) : (
                <>
                    {allowEdit && (
                        <button className="btn btn-no-bg text-gray" onClick={onEditClick}>
                            <i className="fal fa-edit" />
                            &nbsp;Edit
                        </button>
                    )}
                    <button type="button" className="btn btn-blue" onClick={onBackClick}>
                        Back
                    </button>
                </>
            )}
        </div>
    );
}

const getCaseTitle = (currentCase: ICaseModel, excludeCaseGuid?: boolean) => {
    const caseTitle: ICaseTitleModel = {
        guid: excludeCaseGuid ? undefined : currentCase?.guid,
        caseName: currentCase.caseName,
        priority: currentCase.priority,
        SQProjection: currentCase.qProjection,
        SQScore: currentCase.qScore,
        threatScore: currentCase.threatScore,
    };
    return caseTitle;
};
