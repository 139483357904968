import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { IInvitationModel, IInvitationRegistrationModel } from '../../interfaces/IUser';
import { IDialogModel } from '../../interfaces/IDialog';
import { IValidation } from '../../interfaces/IError';
import ValidateUtils from '../../shared/validations';

import { DisplayMessages } from '../../utilities/DisplayMessages';
import { LocalRoutes } from '../../utilities/LocalRoutes';

import CheckmateApiClient from '../../services/apiClient';
import { CheckmateDialog } from '../../components/shared/dialog';
import { ApplicationHelpType } from '../../utilities/Constants';
import { Help } from '../../components/shared/Help';

let _apiClient = new CheckmateApiClient();

interface IRegisterAccountProps {
    guid: string;
}

interface IRegisterAccountState {
    userInvitation: IInvitationModel;
    invitationRegistration: IInvitationRegistrationModel;
    validation: IValidation;
    redirect: boolean;
    redirectTo: string;
    dialog: IDialogModel;
    validationDialog: IDialogModel;
    acceptTerms: boolean;
}

export class RegisterWithInvitation extends React.Component<
    IRegisterAccountProps,
    IRegisterAccountState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            userInvitation: { organizationRoles: [] },
            invitationRegistration: {},
            validation: {},
            redirect: false,
            redirectTo: '/',
            dialog: { isShowingModal: false },
            validationDialog: { isShowingModal: false },
            acceptTerms: false,
        };
    }

    componentDidMount() {
        this.loadUserInvitation(this.props.guid);
    }

    loadUserInvitation = async (guid: string) => {
        try {
            var response = await _apiClient.getUserInvitation(guid);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                });
                return;
            }
            if (response.payload) {
                this.setState({
                    userInvitation: response.payload,
                    invitationRegistration: {
                        username: response.payload.email,
                        invitationGuid: guid,
                    },
                });
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    validatePassword = () => {
        let password = this.state.invitationRegistration.password;
        let confirmPassword = this.state.invitationRegistration.confirmPassword;
        let validation = this.state.validation;

        if (!password || !confirmPassword) {
            validation.password = [DisplayMessages.PasswordRequired];
            this.setState({ validation: validation });
            return;
        }

        if (password !== confirmPassword) {
            validation.password = [DisplayMessages.PasswordMatch];
            this.setState({ validation: validation });
            return;
        }

        // if (!password!.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)) {
        //     validation.password = [DisplayMessages.PasswordCriteria];
        //     this.setState({ validation: validation });
        //     return;
        // }

        this.createUserAccount();
    };

    createUserAccount = async () => {
        if (!this.state.acceptTerms) {
            this.setState({
                validationDialog: {
                    isShowingModal: true,
                    title: '',
                    body: DisplayMessages.AcceptTermsOfServiceRequiredAccount,
                    cancelText: 'OK',
                },
            });
            return;
        }

        let userInformation = this.state.invitationRegistration;

        var response = await _apiClient.createUserAccount(userInformation);
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
        } else {
            this.setState({
                dialog: {
                    isShowingModal: true,
                    title: '',
                    body: 'User Account created successfully.',
                    cancelText: 'OK',
                },
            });
        }
    };

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue(name: string, value: any) {
        let invitationRegistration = this.state.invitationRegistration;
        let validation = this.state.validation;

        switch (name) {
            case 'password':
                invitationRegistration.password = value;
                validation.password = [];
                break;
            case 'confirmPassword':
                invitationRegistration.confirmPassword = value;
                validation.confirmPassword = [];
                break;
            default:
                break;
        }
        this.setState({
            invitationRegistration: invitationRegistration,
            validation: validation,
        });
    }

    handleConfirm = () => {
        this.setState({ redirect: true });
    };

    handleCancel = () => {
        this.setState({
            redirect: true,
            redirectTo: LocalRoutes.AcceptInvitation.replace(':guid', this.props.guid),
        });
    };

    handleCloseValidation = () => {
        this.setState({ validationDialog: { isShowingModal: false } });
    };

    changeAcceptTermsValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        this.setState({ acceptTerms: value });
    };

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirectTo} />;
        }

        return (
            <div className="modal-container">
                <h1>
                    Create Account
                    <span className="float-end">
                        <input
                            type="button"
                            className="btn btn-green horizontal-margin"
                            onClick={this.validatePassword}
                            value="Create"
                        />
                        <input
                            type="button"
                            className="btn btn-default"
                            onClick={this.handleCancel}
                            value="Cancel"
                        />
                    </span>
                </h1>
                <h5 className="vertical-padding-lg text-bold">{DisplayMessages.CreateAccount}</h5>
                <span className="text-danger ">{this.state.validation.model}</span>
                <div className="row mb-2">
                    <div className="col-sm-2">
                        <label className="text-gray">Username/Email</label>
                    </div>
                    <div className="col-sm-5">
                        <input
                            disabled
                            className="form-control"
                            type="text"
                            name="email"
                            value={this.state.userInvitation.email || ''}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-2">
                        <label className="text-gray">Password</label>
                        <Help
                            type={ApplicationHelpType.Info}
                            title="Password Requirements"
                            helpText="* Minimum of 8 characters\n* Must Contain at least 1 lowercase letter\n* Must Contain at least 1 capital letter\n* Must Contain at least 1 number\n"
                        />
                    </div>
                    <div className="col-sm-5">
                        <input
                            className="form-control"
                            style={{ display: 'inline-block' }}
                            type="password"
                            name="password"
                            value={this.state.invitationRegistration.password || ''}
                            onChange={this.changeValue}
                        />
                        <span className="text-danger ms-2">{this.state.validation.password}</span>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-2">
                        <label className="text-gray">Confirm Password*</label>
                    </div>
                    <div className="col-sm-5">
                        <input
                            className="form-control"
                            style={{ display: 'inline-block' }}
                            type="password"
                            name="confirmPassword"
                            value={this.state.invitationRegistration.confirmPassword || ''}
                            onChange={this.changeValue}
                        />
                        <span className="text-danger ms-2">
                            {this.state.validation.confirmPassword}
                        </span>
                    </div>
                </div>
                <div className="vertical-padding-lg">
                    <h5 className="text-gray">User Acknowledgment</h5>
                    <h5 className="text-gray">
                        <i className="fas fa-chevron-right"></i>&nbsp;
                        {DisplayMessages.AcceptTermsOfServiceRequest1}
                    </h5>
                    <h5 className="text-gray">
                        <i className="fas fa-chevron-right"></i>&nbsp;
                        {DisplayMessages.AcceptTermsOfServiceRequest2}
                    </h5>
                </div>
                <div className="row">
                    <div className="col-sm-5 horizontal-padding margin">
                        <input
                            name="acceptTerms"
                            className="form-check-input margin-left-sm"
                            type="checkbox"
                            onChange={this.changeAcceptTermsValue}
                            checked={this.state.acceptTerms}
                        />
                        <label className="form-check-label horizontal-margin" htmlFor="acceptTerms">
                            I Accept
                        </label>
                    </div>
                    <span className="text-danger">{this.state.validation.acceptTerms}</span>
                </div>
                <CheckmateDialog
                    isShowingModal={this.state.dialog.isShowingModal}
                    title={this.state.dialog.title}
                    body={this.state.dialog.body}
                    handleClose={this.handleConfirm}
                    cancelText={this.state.dialog.cancelText}
                    closeButtonClassName="btn btn-black float-end"
                    dialogClassName="create-account-message-dialog"
                />
                <CheckmateDialog
                    isShowingModal={this.state.validationDialog.isShowingModal}
                    title={this.state.validationDialog.title}
                    body={this.state.validationDialog.body}
                    handleClose={this.handleCloseValidation}
                    cancelText={this.state.validationDialog.cancelText}
                    closeButtonClassName="btn btn-black float-end"
                    dialogClassName="create-account-message-dialog"
                />
            </div>
        );
    }
}
