import * as React from 'react';

interface IBasicSearchProps {
    onSearch: (e: React.FormEvent<HTMLButtonElement>, searchString: string) => void;
    placeholderText?: string;
    justifyLeft: boolean;
}

interface IBasicSearchState {
    searchString: string;
}

export class BasicSearch extends React.Component<IBasicSearchProps, IBasicSearchState> {
    constructor(props: any) {
        super(props);

        this.state = {
            searchString: '',
        };
    }

    changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchString: e.target.value,
        });
    };

    onSearch = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.props.onSearch(e, this.state.searchString);
    };

    render() {
        return (
            <div>
                {this.props.justifyLeft ? (
                    <div>
                        <div className="no-padding case-search-div">
                            <input
                                className="form-control float-end full-width full-max-width"
                                type="text"
                                name="userSearch"
                                placeholder={
                                    this.props.placeholderText && this.props.placeholderText != ''
                                        ? this.props.placeholderText
                                        : 'Enter Search Text here'
                                }
                                value={this.state.searchString}
                                onChange={this.changeValue}
                            ></input>
                        </div>
                        <div className="padding-left-10" style={{ display: 'inline-block' }}>
                            <div style={{ display: 'inline-block' }} className="float-end">
                                <button
                                    type="submit"
                                    className="btn btn-black btn-icon float-end "
                                    onClick={this.onSearch}
                                >
                                    <i className="fal fa-lg fa-search color-white" />
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className=" row ">
                        <div style={{ display: 'inline-block' }} className="float-end">
                            <button
                                className="btn btn-black btn-icon"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    this.onSearch(e);
                                }}
                            >
                                <i className="fal fa-lg fa-search color-white" />
                            </button>
                        </div>
                        <div className="col-sm-10 float-end">
                            <input
                                className="form-control"
                                type="text"
                                name="orgSearch"
                                placeholder={
                                    this.props.placeholderText && this.props.placeholderText != ''
                                        ? this.props.placeholderText
                                        : 'Enter Search Text here'
                                }
                                value={this.state.searchString}
                                onChange={this.changeValue}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
