import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { ExecutionPlan } from '../strategy/ExecutionPlan';
import { IUserModel } from '../../interfaces/IUser';
import { useLocation } from 'react-router';

function ExecutionPlanWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();
    return (
        <>
            <DocumentTitle title="Execution Plan"></DocumentTitle>{' '}
            <ExecutionPlan user={props.user} refreshIdentifier={key} />
        </>
    );
}

export default ExecutionPlanWrapper;
