import * as React from 'react';

import { IOrganizationRoleModel, IUserModel } from '../../../interfaces/IUser';
import { useLocation, useNavigate } from 'react-router-dom';

import { CaseFileProvider } from '../../../contexts/CaseFileContext';
import CheckmateApiClient from '../../../services/apiClient';
import { CheckmateDialog } from '../../shared/dialog';
import { DatabaseAppSettingsEnum } from '../../../utilities/Constants';
import { DocumentTitle } from '../../shared/DocumentTitle';
import { HubConnection } from '@microsoft/signalr';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { NavigationMenu } from './menus/NavigationMenu';
import SideNavMenuWrapper from './menus/SideNavMenuWrapper';
import { SignalRMethods } from '../../../utilities/SignalR';
import TagManager from 'react-gtm-module';
import { UserMenu } from './menus/UserMenu';
import { useGetDbAppSettings } from '../../../shared/react-query-hooks/useGetDbAppSettings';
import { useGetUserUnreadQueryExports } from '../../../shared/react-query-hooks/useGetUserUnreadQueryExports';

const _apiClient = new CheckmateApiClient();

let localSwitchZone = false;

export interface ISecureLayoutProps {
    alertHubConnection: HubConnection | undefined;
    children: React.ReactNode;
    isLoggedIn: boolean;
    user: IUserModel;
}

export function SecureLayout(props: ISecureLayoutProps) {
    const appSettings = useGetDbAppSettings();

    const navigate = useNavigate();
    const { key: locationKey } = useLocation();

    const { refetch: refetchReports } = useGetUserUnreadQueryExports(
        props.user ?? { active: false }
    );

    const [showZoneChangeDetectedDialog, setShowZoneChangeDetectedDialog] =
        React.useState<boolean>(false);

    const refreshApp = (redirectPath?: string) => {
        window.location.assign(redirectPath ?? LocalRoutes.Root);
    };

    const switchZone = async (
        organizationGuid: string,
        organizationName: string,
        redirectPath?: string
    ) => {
        // Call API to Save the switch. Then redirect to home page.
        const response = await _apiClient.setDefaultUserOrganization(
            organizationGuid,
            organizationName,
            locationKey
        );

        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (!response.errorMessage) {
            window.location.reload();
        }

        refreshApp(redirectPath);
    };

    const handleZoneChange = (org: IOrganizationRoleModel, redirectPath?: string) => {
        localSwitchZone = true;
        switchZone(org.guid!, org.shortName!, redirectPath);
    };

    const handleAppLogoClick = () => {
        navigate(LocalRoutes.Root);
    };

    const reportSignalrState = () => {
        console.log(`SignalR - Alert Hub => State: ${props.alertHubConnection?.state}`);
    };

    const startConnection = async () => {
        try {
            if (props.alertHubConnection?.state === 'Disconnected') {
                await props.alertHubConnection?.start();
                reportSignalrState();
            }
        } catch (err) {
            console.log(err);
            reportSignalrState();

            window.setTimeout(() => startConnection(), 10000);
        }
    };

    const bindSignalrEvents = () => {
        const handleSendReportNotificationReceived = () => {
            // console.log(`******* ${SignalRMethods.SendReportNotification} *******`);
            refetchReports();
        };

        const handleZoneChangedReceived = () => {
            // console.log(`******* ${SignalRMethods.ZoneChanged} *******`);
            setShowZoneChangeDetectedDialog(!localSwitchZone);
        };

        if (props.alertHubConnection !== undefined) {
            console.log(
                `SignalR - Alert Hub => Listening for ${SignalRMethods.SendReportNotification}`
            );
            props.alertHubConnection?.on(
                SignalRMethods.SendReportNotification,
                handleSendReportNotificationReceived
            );

            console.log(`SignalR - Alert Hub => Listening for ${SignalRMethods.ZoneChanged}`);
            props.alertHubConnection?.on(SignalRMethods.ZoneChanged, handleZoneChangedReceived);

            props.alertHubConnection?.onclose(async () => {
                reportSignalrState();
                await startConnection();
            });
        }

        startConnection();
    };

    const unbindSignalrEvents = () => {
        console.log(
            `SignalR - Alert Hub => Stopped listening for ${SignalRMethods.SendReportNotification}`
        );
        props.alertHubConnection?.off(SignalRMethods.SendReportNotification);

        console.log(`SignalR - Alert Hub => Stopped listening for ${SignalRMethods.ZoneChanged}`);
        props.alertHubConnection?.off(SignalRMethods.ZoneChanged);

        console.log(`SignalR - Alert Hub => Stopped`);
        props.alertHubConnection?.stop();
    };

    React.useEffect(() => {
        if (!appSettings.isLoading && appSettings.data) {
            const gtmId = appSettings.data.find(
                (setting) => setting.name === DatabaseAppSettingsEnum.GoogleTagManagerId
            )?.value;

            if (gtmId) {
                TagManager.initialize({
                    gtmId,
                });
            } else {
                console.log('Failed to load Google Tag Manager identifier.');
            }
        }
    }, [appSettings.isLoading, appSettings.data]);

    React.useEffect(() => {
        bindSignalrEvents();
        return unbindSignalrEvents;
    }, [props.alertHubConnection]);

    return (
        <>
            <DocumentTitle />
            <div className="container-fluid" style={{ background: 'white' }}>
                <div className="row">
                    <div
                        className="col-sm-2 print-hide-display"
                        style={{ paddingLeft: '15px', paddingRight: '15px' }}
                    >
                        <div className="logo-container">
                            <img
                                className="logo-img rounded"
                                src="/images/CheckmateLogoFromBrian.svg"
                                onClick={handleAppLogoClick}
                                alt="Checkmate Logo"
                            />
                        </div>
                    </div>
                    <div className="col-sm-10 nav-menu-item-container">
                        <div
                            className="dropdown-container print-hide-display"
                            style={{
                                padding: '0',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <NavigationMenu onZoneChange={handleZoneChange} />
                            <UserMenu onZoneChange={handleZoneChange} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2" style={{ padding: '0px' }}>
                        <SideNavMenuWrapper
                            user={props.user}
                            isAuthenticated={props.isLoggedIn}
                            alertHubConnection={props.alertHubConnection}
                            setIsLocalSwitchZone={() => (localSwitchZone = true)}
                        />
                    </div>
                    <CaseFileProvider>
                        <div className="col-sm-10 main-display" style={{ paddingLeft: '15px' }}>
                            {props.children}
                        </div>
                    </CaseFileProvider>
                </div>
            </div>
            <CheckmateDialog
                isShowingModal={showZoneChangeDetectedDialog}
                title={'Current Active Zone Change Detected'}
                body={
                    'Your current active zone has been changed on a different tab and this page will automatically reload to reflect the same.'
                }
                handleClose={() => refreshApp()}
                cancelText={'OK'}
                dialogClassName="registration-options-dialog"
                closeButtonClassName="btn btn-black float-end"
                showBackdrop
            />
        </>
    );
}
