import { SortableHeader } from '../shared/SortableHeader';

interface INoteListTableHeadProps {
    hideCategoryInfoText: boolean;
    onSortDataClick: (
        key: string,
        order: string,
        subKey?: string | undefined,
        subGrandKey?: string | undefined
    ) => void;
    onViewNotesCategoryAdditionalInfo: () => void;
    showCaseColumn?: boolean;
    showEasyUpdateColumn?: boolean;
    showExpertsColumn?: boolean;
    showTypeColumn?: boolean;
}

export function NoteListTableHead(props: INoteListTableHeadProps) {
    return (
        <thead>
            <tr style={{ verticalAlign: 'middle' }}>
                {props.showCaseColumn && (
                    <SortableHeader
                        headerComponent={
                            <div>
                                <span>Case Name</span>
                                <SummaryHeading />
                            </div>
                        }
                        sortKey="caseName"
                        onSort={props.onSortDataClick}
                        noWrap
                    />
                )}
                {props.showTypeColumn && (
                    <SortableHeader
                        headerComponent={
                            <div>
                                <span>Type</span>
                                {!props.showCaseColumn && <SummaryHeading />}
                            </div>
                        }
                        sortKey="originStrategyTypeName"
                        onSort={props.onSortDataClick}
                    />
                )}
                {props.showTypeColumn && (
                    <SortableHeader
                        headerComponent={
                            <div>
                                <span>Name</span>
                            </div>
                        }
                        sortKey="originEntityName"
                        onSort={props.onSortDataClick}
                    />
                )}
                <th className="col-sm-2">
                    Categories
                    {!props.hideCategoryInfoText && (
                        <button
                            className="btn-no-bg ps-1"
                            onClick={props.onViewNotesCategoryAdditionalInfo}
                            title="Click to view details about Notes Category Additional Information"
                        >
                            <i className="fal fa-lg fa-info-circle color-gray" />
                        </button>
                    )}
                    {!props.showCaseColumn && !props.showTypeColumn && <SummaryHeading />}
                </th>
                <SortableHeader
                    headerComponent={
                        <button className="btn-no-bg" title="Private">
                            <i className="fal fa-lg fa-location-dot-slash color-gray" />
                        </button>
                    }
                    sortKey="private"
                    onSort={props.onSortDataClick}
                />
                <SortableHeader
                    headerComponent={
                        <button className="btn-no-bg" title="Allow local edit">
                            <i className="fal fa-lg fa-map-marker-edit color-gray" />
                        </button>
                    }
                    sortKey="allowLocalEdit"
                    onSort={props.onSortDataClick}
                />
                <SortableHeader
                    headerComponent={
                        <button className="btn-no-bg" title="Modified by">
                            <i className="fal fa-lg fa-map-marker-smile color-gray" />
                        </button>
                    }
                    sortKey="modifiedByLocal"
                    onSort={props.onSortDataClick}
                />
                <SortableHeader
                    headerComponent={
                        <button className="btn-no-bg" title="Context">
                            <i className="fal fa-lg fa-message-dots color-gray" />
                        </button>
                    }
                    sortKey="context"
                    onSort={props.onSortDataClick}
                />
                <SortableHeader
                    headerComponent={
                        <button className="btn-no-bg" title="Has attachment">
                            <i className="fal fa-lg fa-paperclip color-gray" />
                        </button>
                    }
                    sortKey="hasAttachment"
                    onSort={props.onSortDataClick}
                />
                {props.showExpertsColumn && (
                    <SortableHeader
                        headerComponent={
                            <button className="btn-no-bg" title="Experts">
                                <i className="fal fa-lg fa-head-side-brain color-gray" />
                            </button>
                        }
                        sortKey="experts"
                        onSort={props.onSortDataClick}
                    />
                )}
                <SortableHeader
                    headerText="Purpose"
                    sortKey="purpose"
                    subKey="displayName"
                    onSort={props.onSortDataClick}
                />
                <SortableHeader
                    headerText="Decision"
                    sortKey="approvalStatus"
                    subKey="displayName"
                    onSort={props.onSortDataClick}
                />
                <SortableHeader
                    headerText="Writer"
                    sortKey="author"
                    subKey="profile"
                    subGrandKey="firstName"
                    onSort={props.onSortDataClick}
                />
                <SortableHeader
                    headerText="Date"
                    sortKey="modifiedDate"
                    onSort={props.onSortDataClick}
                />
                <th />
                {props.showEasyUpdateColumn && <th />}
            </tr>
        </thead>
    );
}
function SummaryHeading() {
    return (
        <>
            <span className="text-gray ms-1">| Summary</span>
        </>
    );
}
