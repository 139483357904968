import * as React from 'react';

import { AddProjectModal } from './projects/AddProjectModal';
import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../../components/shared/dialog';
import Common from '../../stores/Common';
import { IProjectModel } from '../../interfaces/IStrategy';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import { UserRightsEnum } from '../../utilities/Constants';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IProjectListProps {
    user: IUserModel;
    refreshIdentifier?: string;
}

interface IProjectListState {
    pendingResponse: boolean;
    validation: IValidation;
    projects: IProjectModel[];
    showConfirmRemoveProjectListDialog?: boolean;
    showAddProjectModal?: boolean;
    selectedProjectGuidToDelete?: string;
}

export class ProjectList extends React.Component<IProjectListProps, IProjectListState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: true,
            validation: {},
            projects: [],
        };
    }

    componentDidMount() {
        this.loadProjects();
    }

    componentDidUpdate(prevProps: IProjectListProps) {
        if (
            this.props.refreshIdentifier &&
            this.props.refreshIdentifier != prevProps.refreshIdentifier
        ) {
            this.setState(
                {
                    pendingResponse: true,
                    validation: {},
                    projects: [],
                },
                this.loadProjects
            );
        }
    }

    loadProjects = async () => {
        this.setState({ pendingResponse: true });

        const response = await _apiClient.getProjects();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            this.setState({
                projects: response.payload,
                pendingResponse: false,
                showAddProjectModal: false,
            });
        }
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let projects = this.state.projects;
        if (projects) {
            if (key.includes('Date')) {
                projects = projects.sort(Sort.compareDate(key, subKey, order));
            } else {
                projects = projects.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }

        this.setState({ projects: projects });
    };

    showConfirmRemoveDailog = (guid: string) => {
        this.setState({
            showConfirmRemoveProjectListDialog: true,
            selectedProjectGuidToDelete: guid,
        });
    };

    removeProject = async () => {
        if (!this.state.selectedProjectGuidToDelete) return;

        const response = await _apiClient.removeProject(this.state.selectedProjectGuidToDelete);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        const projects = this.state.projects.filter(
            (x) => x.guid != this.state.selectedProjectGuidToDelete
        );
        this.setState({ projects: projects, showConfirmRemoveProjectListDialog: false });
    };

    handleProjectCreated = (newProject: IProjectModel) => {
        if (newProject) {
            this.loadProjects();
        }
    };

    render() {
        const showAddProjectButton = Authorization.userHasRight(
            UserRightsEnum.AddEditDeleteProject,
            this.props.user
        );

        if (this.state.pendingResponse) return <Loader />;
        return (
            <div>
                <h1>
                    Projects
                    {showAddProjectButton && (
                        <button
                            className="btn-no-bg float-end "
                            onClick={() => this.setState({ showAddProjectModal: true })}
                        >
                            <span className="btn-green btn float-end btn-icon">
                                <i className="fal fa-lg fa-plus color-white" />
                            </span>
                        </button>
                    )}
                </h1>
                {this.state.projects.length > 0 ? (
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <SortableHeader
                                    headerText="Name"
                                    onSort={this.sortData}
                                    sortKey="name"
                                />
                                <SortableHeader
                                    headerText="Risk or Opportunity"
                                    onSort={this.sortData}
                                    sortKey="type"
                                    subKey="displayName"
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Target Date"
                                    onSort={this.sortData}
                                    sortKey="targetDate"
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Status"
                                    onSort={this.sortData}
                                    sortKey="status"
                                    subKey="displayName"
                                />
                                {Authorization.userHasRight(
                                    UserRightsEnum.AddEditDeleteProject,
                                    this.props.user
                                ) ? (
                                    <th />
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.projects.map((item: IProjectModel) => {
                                return (
                                    <tr key={item.guid} style={{ verticalAlign: 'middle' }}>
                                        <td className="col-sm-3">
                                            <Link
                                                target="_blank"
                                                to={LocalRoutes.ProjectDetail.replace(
                                                    ':guid',
                                                    item.guid!
                                                )}
                                            >
                                                {item.name}
                                            </Link>
                                        </td>
                                        <td className="col-sm-3">
                                            {item.type ? item.type.displayName : ''}
                                        </td>
                                        <td className="col-sm-3">
                                            {item.isOngoing
                                                ? 'Ongoing'
                                                : item.targetDate
                                                ? Common.dateFormat(item.targetDate)
                                                : ''}
                                        </td>
                                        <td className="col-sm-2">
                                            {item.status ? item.status.displayName : ''}
                                        </td>
                                        {Authorization.userHasRight(
                                            UserRightsEnum.AddEditDeleteProject,
                                            this.props.user
                                        ) ? (
                                            <td className="col-sm-1">
                                                <button
                                                    className="btn btn-no-bg float-end"
                                                    onClick={() => {
                                                        this.showConfirmRemoveDailog(item.guid!);
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        ) : null}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <span>No projects have been created for this zone.</span>
                )}

                {this.state.showAddProjectModal && (
                    <AddProjectModal
                        onHide={() => this.setState({ showAddProjectModal: false })}
                        onCreated={this.handleProjectCreated}
                    />
                )}

                <CheckmateDialog
                    isShowingModal={this.state.showConfirmRemoveProjectListDialog || false}
                    body="Are you sure you want to delete this item? This operation is permanent and cannot be reverted back."
                    handleClose={() => this.setState({ showConfirmRemoveProjectListDialog: false })}
                    handleConfirm={this.removeProject}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
