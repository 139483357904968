import { IOrganizationBaseModel } from "../interfaces/IOrganization";
import { IUserModel } from "../interfaces/IUser";
import { RolesEnum } from "../utilities/Constants";

export default class Auth {

    static userDefaultOrganizationGuidKey = "DefaultOrganizationGuid";
    static userHomeOrganizationGuidKey = "HomeOrganizationGuid";
    static userDefaultOrganizationNameKey = "DefaultOrganizationName";

    static setUserDefaultOrganization(organizationGuid: string, organizationName: string) {
        window.localStorage.setItem(Auth.userDefaultOrganizationGuidKey, organizationGuid);
        window.localStorage.setItem(Auth.userDefaultOrganizationNameKey, organizationName);
    }

    static clearUserDefaultOrganization() {
        window.localStorage.removeItem(Auth.userDefaultOrganizationGuidKey);
        window.localStorage.removeItem(Auth.userDefaultOrganizationNameKey);
    }

    static getUserDefaultOrganization() {
        const organizationGuid = window.localStorage.getItem(Auth.userDefaultOrganizationGuidKey);
        const organizationName = window.localStorage.getItem(Auth.userDefaultOrganizationNameKey);

        // adding default org type to be portfolio
        if (organizationGuid && organizationName) {
            const defaultOrg: IOrganizationBaseModel = {
                guid: organizationGuid,
                name: organizationName,
                displayName: organizationName,
                organizationType: { id: 1, name: "Portfolio", displayName: "Portfolio", description: "Portfolio" }
            }

            return defaultOrg;
        }
    }

    static getUserHomeOrganization(user: IUserModel) {
        if (user && user.homeOrganization) {
            return user.homeOrganization;
        }
    }

    static isInRole(roleId: number, user?: IUserModel): boolean {
        const defaultOrg = Auth.getUserDefaultOrganization();
        return (defaultOrg != undefined && defaultOrg.guid != undefined && user != undefined && user.organizationRoles != undefined && user.organizationRoles.filter(x => x.guid === defaultOrg.guid && x.role && x.role.id === roleId).length > 0);
    }

    static isInApplicationRole(roleId: number, user?: IUserModel): boolean {
        if (user && user.applicationRoles) {
            const roles = user.applicationRoles;
            for (let i = 0; i < roles.length; i++) {
                if (roles[i].id && roles[i].id == roleId) {
                    return true;
                }
            }
        }

        return false;
    }

    static isSystemAdmin(user: IUserModel): boolean {
        if (user) {
            return this.isInApplicationRole(RolesEnum.SystemAdmin, user);
        }
        return false;
    }

    static isOrganizationAdmin(user: IUserModel): boolean {
        return this.isInRole(RolesEnum.OrganizationAdmin, user);
    }

    static isMasterAdmin(user: IUserModel): boolean {
        return this.isInRole(RolesEnum.MasterOrganizationAdmin, user);
    }

    static isReportUser(user: IUserModel): boolean {
        return this.isInRole(RolesEnum.ReportUser, user);
    }

    static isBoardUser(user: IUserModel): boolean {
        return this.isInRole(RolesEnum.Board, user);
    }

    static isUser(user: IUserModel): boolean {
        return this.isInRole(RolesEnum.User, user);
    }
}