import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { config } from '../config/config';

export interface SignalRContextProps {
    alertHub: HubConnection | undefined;
}

const SignalRContext = createContext<SignalRContextProps | undefined>(undefined);

interface SignalRProviderProps {
    children: ReactNode;
}

const SignalRProvider: React.FC<SignalRProviderProps> = ({ children }) => {
    const [alertHub, setAlertHub] = useState<HubConnection | undefined>();

    useEffect(() => {
        setAlertHub(new HubConnectionBuilder().withUrl(`${config.baseUrl}alertHub`).build());
    }, []);

    return (
        <SignalRContext.Provider
            value={{
                alertHub,
            }}
        >
            {children}
        </SignalRContext.Provider>
    );
};

const useSignalRContext = (): SignalRContextProps => {
    const context = useContext(SignalRContext);
    if (!context) {
        throw new Error('useSignalR must be used within a SignalRProvider');
    }
    return context;
};

export { SignalRProvider, useSignalRContext };
