import { useEffect, useState } from 'react';

import CheckmateApiClient from '../../services/apiClient';
import { IInvitationModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { Navigate } from 'react-router';
import ValidateUtils from '../../shared/validations';
import { useNavigate } from 'react-router-dom';

const _apiClient = new CheckmateApiClient();

interface IRegistrationOptionProps {
    guid: string;
}

export function RegistrationOption(props: IRegistrationOptionProps) {
    const [userInvitation, setUserInvitation] = useState<IInvitationModel>();
    const [validation, setValidation] = useState<IValidation>();
    const [showModal, setShowModal] = useState<boolean>();
    const [isLoaded, setIsLoaded] = useState<boolean>();

    const navigate = useNavigate();

    const loadUserInviation = async (guid: string) => {
        setIsLoaded(false);
        const response = await _apiClient.getUserInvitation(guid);
        setIsLoaded(true);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            return;
        }
        if (response.payload) {
            setUserInvitation(response.payload);
            setShowModal(response.payload.invitationAccepted);
        }
    };

    const handleOkClick = () => {
        setShowModal(false);
        navigate('/');
    };

    useEffect(() => {
        loadUserInviation(props.guid);
    }, []);

    if (!isLoaded) return <>Retriveing invitation...</>;

    if (validation)
        return (
            <div>
                <span className="text-danger">{validation.model}</span>
            </div>
        );

    if (!userInvitation?.invitationAccepted)
        return <Navigate to={LocalRoutes.RegisterWithInvitation.replace(':guid', props.guid)} />;

    return (
        <Modal
            centered
            show={showModal}
            dialogClassName="registration-options-dialog"
            onHide={() => setShowModal(false)}
            backdrop={false}
        >
            <Modal.Body>
                <div>
                    <p>
                        This invitation has already been accepted. Please login to access your
                        account.
                    </p>
                    <div className="dialog-btn-div">
                        <button className="btn btn-black float-end" onClick={handleOkClick}>
                            Ok
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
