import { useEffect, useState } from 'react';

export enum EntryTypeEnum {
    reload = 'reload',
    navigate = 'navigate',
}

export interface IEventTypeHandlerMap {
    [key: string]: () => void;
}

export default function usePerformanceNavigation() {
    const [navigationType, setNavigationType] = useState<EntryTypeEnum | undefined>();

    useEffect(() => {
        const existingEntries: any = performance.getEntriesByType('navigation');
        existingEntries.forEach((entry: { type: EntryTypeEnum }) => {
            setNavigationType(entry.type);
        });
    }, []);

    return navigationType;
}
