import Authorization from '../../stores/Authorization';
import CaseHelper from '../../utilities/CaseHelper';
import { CaseName } from './CaseName';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { UserRightsEnum } from '../../utilities/Constants';
import ValidateUtils from '../../shared/validations';
import { useCaseFileContext } from '../../contexts/CaseFileContext';

export interface ICaseFileHeaderProps {
    enableCaseFileEdit: boolean;
    enablePlanningExecution: boolean;
    isSystemAdmin: boolean;
    onCancelClick: () => void;
    onClickAddNote: () => void;
    onClickDeleteCase: () => void;
    onClickSetCasePriority: () => void;
    onSaveClick: () => void;
    user: IUserModel;
    validation: IValidation;
}

export function CaseFileHeader({
    enableCaseFileEdit,
    enablePlanningExecution,
    isSystemAdmin,
    onCancelClick,
    onClickAddNote,
    onClickDeleteCase,
    onClickSetCasePriority,
    onSaveClick,
    user,
    validation,
}: ICaseFileHeaderProps) {
    const { currentCase, isReadOnlyMode, setIsReadOnlyMode } = useCaseFileContext();

    const isLocalCounselAssigned =
        (currentCase.defenseCounsels?.filter((x) => x.priority && x.priority == 1).length ?? 0) > 0;

    const showEditSaveButtons = Authorization.userHasRight(UserRightsEnum.EditCaseFile, user);

    const showPriorityToggleButton =
        isReadOnlyMode &&
        enableCaseFileEdit &&
        !isSystemAdmin &&
        currentCase &&
        Authorization.userHasRight(UserRightsEnum.EditCaseFile, user);

    const showDeleteCaseButton =
        isReadOnlyMode &&
        enableCaseFileEdit &&
        !isSystemAdmin &&
        currentCase &&
        Authorization.userHasRight(UserRightsEnum.DeleteCase, user);

    const showAddCaseButton =
        currentCase.guid &&
        (Authorization.userHasRight(UserRightsEnum.EditCaseFile, user) ||
            Authorization.userHasRight(UserRightsEnum.AddCaseNote, user));

    const hasValidationItems = Object.keys(validation).length > 0;
    const catchAllValidationKeys = getCatchAllValidationItems(validation);

    return (
        <>
            <h1 style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <CaseName user={user} caseTitle={CaseHelper.getCaseTitle(currentCase, true)} />
                </div>
                <span className="float-end print-hide-display">
                    <span>
                        {/* Only checking for specific Planning & Execution Rights. Checking whether the logged in user has access to the case File is done prior to here */}
                        {enablePlanningExecution && isLocalCounselAssigned ? (
                            Authorization.userHasRight(
                                UserRightsEnum.FullPlanningAndExecution,
                                user
                            ) ||
                            (currentCase.casePlan != undefined &&
                                (Authorization.userHasRight(
                                    UserRightsEnum.ReadOnlyPlanningAndExecution,
                                    user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.FirmLevelPlanningAndExecution,
                                        user
                                    ))) ? (
                                <span className="btn btn-blue float-end ">
                                    {' '}
                                    <Link
                                        to={LocalRoutes.CasePlan.replace(
                                            ':guid',
                                            currentCase.guid!
                                        )}
                                        style={{ color: 'white' }}
                                    >
                                        Case plan
                                    </Link>
                                </span>
                            ) : null
                        ) : null}
                        {showEditSaveButtons && (
                            <>
                                {!isReadOnlyMode ? (
                                    <>
                                        <button
                                            className="btn btn-default float-end text-gray"
                                            onClick={onCancelClick}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-orange float-end text-gray"
                                            onClick={onSaveClick}
                                        >
                                            Save
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        className="btn btn-no-bg float-end text-gray"
                                        onClick={() => setIsReadOnlyMode(false)}
                                    >
                                        <i className="fal fa-edit" />
                                        &nbsp;Edit
                                    </button>
                                )}
                            </>
                        )}
                        {showAddCaseButton && (
                            <button
                                className="btn btn-no-bg float-end text-gray"
                                onClick={onClickAddNote}
                            >
                                <i className="fal fa-memo" />
                                &nbsp;Add Case Note
                            </button>
                        )}
                        {showPriorityToggleButton && (
                            <button
                                className="btn btn-no-bg float-end text-gray"
                                onClick={onClickSetCasePriority}
                            >
                                <i className="fal fa-exclamation-circle" />
                                &nbsp;
                                {currentCase.priority ? 'Remove Priority' : 'Mark as Priority'}
                            </button>
                        )}
                        {showDeleteCaseButton && (
                            <button
                                className="btn btn-no-bg float-end text-gray"
                                onClick={onClickDeleteCase}
                            >
                                <i className="fal fa-trash-alt" />
                                &nbsp;Delete Case
                            </button>
                        )}
                    </span>
                </span>
            </h1>
            <hr className="no-margin" />
            {hasValidationItems && (
                <div style={{ marginTop: '5px' }}>
                    <ul>
                        {validation.casestatus && (
                            <li>
                                <span className="text-danger">{validation.casestatus}</span>
                            </li>
                        )}
                        {validation.casenumber && (
                            <li>
                                <span className="text-danger">{validation.casenumber}</span>
                            </li>
                        )}
                        {validation.defenseCounsel && (
                            <li>
                                <span className="text-danger">{validation.defenseCounsel}</span>
                            </li>
                        )}
                        {validation.model &&
                            ValidateUtils.parseModelErrors(validation.model).map(
                                (value: string) => {
                                    return (
                                        <li key={value}>
                                            <span className="text-danger">{value}</span>
                                        </li>
                                    );
                                }
                            )}
                        {catchAllValidationKeys.map((key) => {
                            const validationItem = validation[key];
                            return validationItem.map((vi) => {
                                return (
                                    <li key={vi}>
                                        <span className="text-danger">{vi}</span>
                                    </li>
                                );
                            });
                        })}
                    </ul>
                </div>
            )}
        </>
    );
}

const getCatchAllValidationItems = (validation: IValidation) => {
    const excludedKeys = ['casestatus', 'casenumber', 'defenseCounsel', 'model'];
    return Object.keys(validation).filter((key) => !excludedKeys.includes(key));
};
