import {
    IAssertionDescriptorModel,
    IAssertionDescriptorValueModel,
} from '../interfaces/IAssertionDescriptor';
import { ICaseAssertionModel, ICaseModel, ICaseStatusLookupModel } from '../interfaces/ICase';

import { AssertionDescriptorValueSourceTypeEnum } from './Constants';
import { IMultiSelectOptions } from '../interfaces/ILookup';

export default class CaseFileCheckmateSelectHelper {
    static isSearchableSelect(name?: string): boolean {
        if (name) {
            switch (name.toUpperCase()) {
                case 'PLAINTIFFS FIRM - LOCAL':
                case 'PLAINTIFFS FIRM - SECONDARY':
                case 'JURISDICTION':
                case 'OCCUPATION':
                    return true;
                default:
                    return false;
            }
        }

        return false;
    }

    static getCaseStatusOptionsForCheckmateSelect(values: ICaseStatusLookupModel[]) {
        const options: IMultiSelectOptions[] = [];
        for (let i = 0; i < values.length; i++) {
            options.push({ label: values[i].name!, value: values[i].name!, id: values[i].id });
        }
        return options;
    }

    static getCaseStatusValue(values: ICaseStatusLookupModel[], id: number) {
        const match = values.find((x) => x.id === id);
        if (match) {
            const selectedValue: IMultiSelectOptions = {
                label: match.name!,
                value: match.name!,
                id: match.id,
            };
            return selectedValue;
        }
    }

    static getOptionsForCheckmateSelect(values: IAssertionDescriptorValueModel[]) {
        const options: IMultiSelectOptions[] = [];
        for (let i = 0; i < values.length; i++) {
            options.push({
                label: values[i].text!,
                value: values[i].guid!,
                id: i,
                guid: values[i].guid!,
            });
        }
        return options;
    }

    static getCheckmateSelectValue(caseAssertion: ICaseAssertionModel) {
        if (caseAssertion?.assertionDescriptorValue?.guid) {
            const match = caseAssertion?.assertionDescriptor!.assertionDescriptorValues!.find(
                (x) => x.guid == caseAssertion?.assertionDescriptorValue!.guid
            );
            if (match) {
                const selectedValue: IMultiSelectOptions = {
                    label: match.text!,
                    value: match.guid!,
                    id: 0,
                    guid: match.guid!,
                };
                return selectedValue;
            }
        }
    }

    static getCheckmateNSelectValue(caseAssertions: ICaseAssertionModel[]): IMultiSelectOptions[] {
        const values = caseAssertions
            .filter((ca) => ca !== undefined)
            .map((ca) => ca.assertionDescriptorValue)
            .filter((adv) => adv !== undefined)
            .map((adv) => ({
                label: adv!.text!,
                value: adv!.guid!,
                id: 0,
                guid: adv!.guid!,
            }));

        return values ?? [];
    }

    static updateCaseOnSelectedAssertionDescriptorValueChange(
        caseItem: ICaseModel,
        assertionDescriptorGuid: string,
        isBoolean: boolean,
        selectedItem?: IMultiSelectOptions,
        assertionDescriptors?: IAssertionDescriptorModel[]
    ) {
        // AR - TO DO - Do not trust the "isBoolean" value passed in, if you can determine from the assertionDescriptor, thats the best option!
        if (assertionDescriptorGuid) {
            // Parent Identifier = Assertion Descriptor, cannot do anything without it

            if (!caseItem.caseAssertions) caseItem.caseAssertions = [];
            const caseAssertionMatch = caseItem.caseAssertions.find(
                (x) =>
                    x.assertionDescriptor!.guid === assertionDescriptorGuid ||
                    x.assertionDescriptor?.name === assertionDescriptorGuid
            );

            if (caseAssertionMatch) {
                isBoolean =
                    caseAssertionMatch.assertionDescriptor?.valueSourceType?.id ===
                    AssertionDescriptorValueSourceTypeEnum.Boolean.Value;

                if (isBoolean) {
                    caseAssertionMatch.assertionDescriptorValue = undefined;
                    caseAssertionMatch.text = selectedItem ? selectedItem.value : undefined;
                    caseAssertionMatch.userOverride = true;
                } else {
                    if (selectedItem && selectedItem.guid) {
                        const advMatch =
                            caseAssertionMatch.assertionDescriptor!.assertionDescriptorValues!.find(
                                (x) => x.guid === selectedItem.guid
                            );

                        caseAssertionMatch.assertionDescriptorValue = advMatch
                            ? JSON.parse(JSON.stringify(advMatch))
                            : undefined;
                    } else {
                        caseAssertionMatch.assertionDescriptorValue = selectedItem
                            ? { guid: selectedItem.guid! }
                            : undefined;
                    }

                    caseAssertionMatch.text = undefined;
                    caseAssertionMatch.userOverride = true;
                }
            } else if (assertionDescriptors && selectedItem) {
                const assertionDescriptorMatch = assertionDescriptors.find(
                    (x) => x.guid == assertionDescriptorGuid
                );

                if (assertionDescriptorMatch) {
                    if (
                        assertionDescriptorMatch.valueSourceType?.id ===
                        AssertionDescriptorValueSourceTypeEnum.Boolean.Value
                    )
                        isBoolean = true;

                    if (isBoolean) {
                        caseItem.caseAssertions.push({
                            assertionDescriptor: JSON.parse(
                                JSON.stringify(assertionDescriptorMatch)
                            ),
                            text: selectedItem.value,
                            userOverride: true,
                        });
                    } else {
                        const advMatch = assertionDescriptorMatch.assertionDescriptorValues!.find(
                            (x) => x.guid === selectedItem.guid
                        );
                        caseItem.caseAssertions.push({
                            assertionDescriptor: JSON.parse(
                                JSON.stringify(assertionDescriptorMatch)
                            ),
                            assertionDescriptorValue: advMatch
                                ? JSON.parse(JSON.stringify(advMatch))
                                : undefined,
                            userOverride: true,
                        });
                    }
                }
            }
        }

        return caseItem;
    }
}
