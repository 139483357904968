import CheckmateApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new CheckmateApiClient();

export const useGetCaseKey = 'get-case';

export const useCaseFetch = (id?: string) =>
    useQuery({
        queryKey: [useGetCaseKey, id],
        queryFn: async ({ queryKey }) => {
            const [, caseGuid] = queryKey;
            const response = await _apiClient.getCaseById(caseGuid ?? '');

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve this case.');
            }
            return response.payload;
        },
        enabled: !!id,
    });
