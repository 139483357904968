import * as React from 'react';

import {
    ApplicationHelpType,
    AssertionDescriptorUsageTypesEnum,
    ConditionSelectorEnums,
    QueryTypes,
} from '../../utilities/Constants';
import { INoteUISearchModel, IQueryFilterCriteria } from '../../interfaces/IQuery';

import CaseHelper from '../../utilities/CaseHelper';
import { Help } from '../../components/shared/Help';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { ILookupModel } from '../../interfaces/ILookup';
import { INoteCategoryTypeModel } from '../../interfaces/INote';
import { IUserModel } from '../../interfaces/IUser';
import { NotesSectionCriteriaValueRows } from './NotesSectionCriteriaValues';
import { QueryFilterCriteriaSelectedRow } from './QueryFilterCriteriaSelectedRows';
import { useAssertionDescriptorsLookup } from '../../shared/react-query-hooks/useAssertionDescriptorsLookup';

export interface ISelectedFilterCriteriaProps {
    acknowledgmentStatusTypes: ILookupModel[];
    approvalStatusTypes: ILookupModel[];
    buildValuesColumn: (item: IQueryFilterCriteria) => JSX.Element | JSX.Element[];
    noteCategoryTypes: INoteCategoryTypeModel[];
    notePurposeTypes: ILookupModel[];
    noteSearchCriteria: INoteUISearchModel;
    onChangeAlt: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    onCheckmateSingleSelectChange: (
        selectedItem: any,
        name: string,
        item?: IQueryFilterCriteria
    ) => void;
    onConditionOptionChange: (filters: IQueryFilterCriteria[], filterIdx: number) => void;
    onDeleteField: (item: IQueryFilterCriteria) => void;
    onFilterValuesSelectedAlt: (optionsList: any, name: string) => void;
    suppressDateRangeCondition?: boolean;
    user: IUserModel;
    queryFilterCriteriaSelected: IQueryFilterCriteria[];
    queryType: number;
}

export function SelectedFilterCriteria(props: ISelectedFilterCriteriaProps) {
    const { data: assertionDescriptors } = useAssertionDescriptorsLookup(
        AssertionDescriptorUsageTypesEnum.Case
    );

    switch (props.queryType) {
        case QueryTypes.CaseDetails.Value:
        case QueryTypes.CaseExperts.Value:
        case QueryTypes.CaseNotes.Value:
        case QueryTypes.Deposition.Value:
            return (props.queryFilterCriteriaSelected.length ?? 0) > 0 ? (
                <div className="row my-2">
                    <div className="col-sm-2" />
                    <div className="col-sm-10">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th className="col-sm-3">Criteria</th>
                                    <th className="col-sm-2">Condition</th>
                                    <th className="col-sm-7">Values</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {props.queryFilterCriteriaSelected
                                    .map((item) => {
                                        if (
                                            item.condition === undefined ||
                                            item.condition === null
                                        ) {
                                            item.condition = ConditionSelectorEnums.EqualTo;
                                        }
                                        return item;
                                    })
                                    .map((item: IQueryFilterCriteria, i: number) => (
                                        <QueryFilterCriteriaSelectedRow
                                            assertionDescriptors={assertionDescriptors ?? []}
                                            helpComponent={getCriteriaHelpText(
                                                item,
                                                assertionDescriptors ?? []
                                            )}
                                            item={item}
                                            key={i}
                                            onConditionOptionChange={props.onConditionOptionChange}
                                            onDeleteClick={(itemToDelete) =>
                                                props.onDeleteField(itemToDelete)
                                            }
                                            selectedCriteria={props.queryFilterCriteriaSelected}
                                            suppressDateRangeCondition={
                                                props.suppressDateRangeCondition
                                            }
                                            valuesColumn={props.buildValuesColumn(item)}
                                        />
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : null;
        case QueryTypes.ZoneNotes.Value:
        case QueryTypes.StrategyNote.Value:
            return (
                <div className="row">
                    <div className="col-sm-2" />
                    <div className="col-sm-10 padding-left-5">
                        <table className="table margin-top-sm">
                            <thead>
                                <tr>
                                    <th className="col-sm-3">Criteria</th>
                                    <th className="col-sm-8">Values</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.noteSearchCriteria.validationError ? (
                                    <tr>
                                        <td></td>
                                        <td>
                                            <span className="text-danger">
                                                {props.noteSearchCriteria.validationError}
                                            </span>
                                        </td>
                                    </tr>
                                ) : null}
                                <NotesSectionCriteriaValueRows
                                    acknowledgmentStatusTypes={props.acknowledgmentStatusTypes}
                                    approvalStatusTypes={props.approvalStatusTypes}
                                    noteCategoryTypes={props.noteCategoryTypes}
                                    notePurposeTypes={props.notePurposeTypes}
                                    noteSearchCriteria={props.noteSearchCriteria}
                                    onChangeAlt={props.onChangeAlt}
                                    onCheckmateSingleSelectChange={
                                        props.onCheckmateSingleSelectChange
                                    }
                                    onFilterValuesSelectedAlt={props.onFilterValuesSelectedAlt}
                                    queryType={props.queryType}
                                    user={props.user}
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        default:
            return null;
    }
}

const getCriteriaHelpText = (
    item: IQueryFilterCriteria,
    assertionDescriptors: IAssertionDescriptorModel[]
) => {
    let helpText: string | null = null;
    if (item.isAssertionDescriptor) {
        helpText = CaseHelper.getAssertionDescriptorFieldHelpText(
            assertionDescriptors,
            item.value,
            item.assertionDescriptorGuid,
            true
        );
    } else if (item.staticItemId) {
        helpText = CaseHelper.getStaticItemFieldHelpText(item.staticItemId);
    }

    if (helpText) {
        return <Help type={ApplicationHelpType.Info} title={item.label} helpText={helpText} />;
    }

    return null;
};
