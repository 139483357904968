import { useLocation, useParams } from 'react-router-dom';

import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { MyDocumentsTypesEnum } from '../../utilities/Constants';
import { Trends } from '../report/Trends';
import { useGetStaticDataDate } from '../../shared/react-query-hooks/useGetStaticDataDate';

function TrendsWrapper(props: { user: IUserModel }) {
    const { key } = useParams();
    const location = useLocation();

    const reportDate = useGetStaticDataDate(MyDocumentsTypesEnum.Trends.Value);

    return (
        <>
            <DocumentTitle title="Trends" />
            <Trends
                key={key || ''}
                user={props.user}
                refreshIdentifier={location.key}
                reportDate={reportDate.data}
            />
        </>
    );
}

export default TrendsWrapper;
