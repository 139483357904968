import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetEntityConversations = (entityTypeId: number, entityGuid: string) => {
    return useQuery({
        queryKey: ['get-entity-conversations', { entityTypeId, entityGuid }],
        queryFn: async () => {
            const response = await _apiClient.getEntityConversations(entityTypeId, entityGuid);

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve entity conversations.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        enabled: !!entityGuid,
    });
};
