import { Button, ButtonProps, Stack } from 'react-bootstrap';

import classNames from 'classnames';

export interface IIconButtonprops extends ButtonProps {
    buttonCssClass?: string;
    disabled?: boolean;
    icon?: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement> | undefined) => void;
    size?: 'sm' | 'lg' | undefined;
    text?: string;
    variant?: 'primary' | 'secondary';
}

export function IconButton(props: IIconButtonprops) {
    return (
        <Button
            onClick={props.onClick}
            className={classNames(props.buttonCssClass)}
            disabled={props.disabled}
            variant={props.variant ?? 'default'}
            size={props.size}
        >
            <Stack direction="horizontal" gap={1} style={{ alignItems: 'center' }}>
                {props.icon && <i className={props.icon} style={{ color: 'inherit' }} />}
                {props.text && <span>{props.text}</span>}
            </Stack>
        </Button>
    );
}
