import * as React from 'react';

import {
    ApplicationHelpType,
    AssertionDescriptorCategories,
    DataScopesEnum,
    EntityTypes,
    LookupDataEnums,
    NoteCategories,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    MyDocumentsTypesEnum,
    UserRightsEnum,
} from '../../utilities/Constants';
import { INoteCategoryTypeModel, INoteModel, INotesUICustomization } from '../../interfaces/INote';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../../components/shared/dialog';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import EntityAssertionControl from '../../components/shared/EntityAssertionControl';
import { ExportFileButton } from '../../components/shared/ExportFileButton';
import { Help } from '../../components/shared/Help';
import { IEntityAssertionModel } from '../../interfaces/IAssertionDescriptor';
import { IOrganizationModel } from '../../interfaces/IOrganization';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IStrategicPlanProps {
    user: IUserModel;
    refreshIdentifier?: string;
}

interface IStrategicPlanState {
    pendingResponse: boolean;
    readOnly: boolean;
    validation: IValidation;
    assertions: IEntityAssertionModel[];
    originalAssertions: IEntityAssertionModel[];
    currentNarrativeCaseAssertionOnEditMode?: IEntityAssertionModel;
    significantChanges: INoteModel[];
    originalSignificantChanges: INoteModel[];
    showSignificantChanges?: boolean;
    selectedSignificantChange?: INoteModel;
    openNoteEditor?: boolean;
    noteCategoryTypes: INoteCategoryTypeModel[];
    defaultOrganization: IOrganizationModel;
    showConfirmRemoveSignificantChangeDialog?: boolean;
    selectedSignificantChangeToDelete?: INoteModel;
    loggedInUserDataScopeId: number;
}

export class StrategicPlan extends React.Component<IStrategicPlanProps, IStrategicPlanState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: true,
            readOnly: true,
            validation: {},
            assertions: [],
            originalAssertions: [],
            noteCategoryTypes: [],
            defaultOrganization: {},
            significantChanges: [],
            originalSignificantChanges: [],
            loggedInUserDataScopeId: 0,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.StrategicPlan, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);
        this.loadInitialData();
    }

    componentDidUpdate(prevProps: IStrategicPlanProps) {
        if (
            this.props.refreshIdentifier &&
            prevProps.refreshIdentifier != this.props.refreshIdentifier
        )
            this.setState(
                {
                    pendingResponse: true,
                    readOnly: true,
                    validation: {},
                    assertions: [],
                    originalAssertions: [],
                    noteCategoryTypes: [],
                    defaultOrganization: {},
                    significantChanges: [],
                    originalSignificantChanges: [],
                    loggedInUserDataScopeId: 0,
                    openNoteEditor: false,
                    selectedSignificantChange: undefined,
                    showSignificantChanges: false,
                },
                this.loadInitialData
            );
    }

    loadInitialData = async () => {
        const strategicPlanDataResponse = await this.getStrategicPlanAssertions();

        let noteCategoryTypesResponse = await _apiClient.getNoteCategoryTypes(
            LookupDataEnums.SignificantChangesCategories
        );
        if (noteCategoryTypesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (noteCategoryTypesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    noteCategoryTypesResponse.errors,
                    noteCategoryTypesResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const sigChangesResponse = await _apiClient.getNotes(
            NoteTypes.StrategySignificantChange,
            NoteCategories.StrategySignificantChanges.toString()
        );
        if (sigChangesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (sigChangesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    sigChangesResponse.errors,
                    sigChangesResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const userDefaultOrgResponse = await _apiClient.getDefaultUserOrganizationFromServer();
        if (!userDefaultOrgResponse) return;
        if (userDefaultOrgResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (userDefaultOrgResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    userDefaultOrgResponse.errors,
                    userDefaultOrgResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );

        this.setState({
            pendingResponse: false,
            readOnly: true,
            assertions: strategicPlanDataResponse!.payload!,
            originalAssertions: JSON.parse(JSON.stringify(strategicPlanDataResponse!.payload)),
            noteCategoryTypes: noteCategoryTypesResponse.payload!,
            significantChanges: sigChangesResponse.payload!,
            originalSignificantChanges: JSON.parse(JSON.stringify(sigChangesResponse.payload!)),
            defaultOrganization: userDefaultOrgResponse.payload!,
            loggedInUserDataScopeId: loggedInUserDataScopeId,
        });
    };

    getStrategicPlanAssertions = async () => {
        const strategicPlanDataResponse = await _apiClient.getStrategicPlanEntityAssertions();
        if (strategicPlanDataResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (strategicPlanDataResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    strategicPlanDataResponse.errors,
                    strategicPlanDataResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        return strategicPlanDataResponse;
    };

    getHelpText = (originalText?: string) => {
        let text = '';
        if (originalText) {
            const temp = originalText.split('-');
            for (let i = 0; i < temp.length; i++) {
                if (temp[i]) text += '- ' + temp[i] + '\n';
            }
        }

        return text;
    };

    getLastUpdated = () => {
        if (this.state.assertions.filter((x) => x.modifiedDate).length > 0)
            return (
                'Last Updated ' +
                Common.dateTimeFormatToLocal(
                    this.state.assertions
                        .filter((x) => x.modifiedDate)
                        .sort(Sort.compareDate('modifiedDate', 'desc'))[0].modifiedDate
                )
            );
        else return '';
    };

    save = async () => {
        this.setState({ pendingResponse: true, validation: {} });
        const response = await _apiClient.saveEntityAssertions(this.state.assertions);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        const strategicPlanDataResponse = await this.getStrategicPlanAssertions();
        this.setState({
            pendingResponse: false,
            readOnly: true,
            assertions: strategicPlanDataResponse!.payload!,
            originalAssertions: JSON.parse(JSON.stringify(strategicPlanDataResponse!.payload)),
        });
    };

    finalizeChange = (modifiedAssertions: IEntityAssertionModel[]) => {
        if (modifiedAssertions.length == 1) {
            const assertions = this.state.assertions;
            const match = assertions.filter(
                (x) =>
                    x.assertionDescriptor &&
                    modifiedAssertions[0].assertionDescriptor &&
                    x.assertionDescriptor.guid == modifiedAssertions[0].assertionDescriptor.guid
            );
            if (match.length > 0) {
                match[0].textValue = modifiedAssertions[0].textValue;
                match[0].booleanValue = modifiedAssertions[0].booleanValue;
                match[0].numericValue = modifiedAssertions[0].numericValue;
                match[0].dateTimeValue = modifiedAssertions[0].dateTimeValue;
                match[0].assertionDescriptorValue = modifiedAssertions[0].assertionDescriptorValue;
                this.setState({
                    assertions: assertions,
                    currentNarrativeCaseAssertionOnEditMode: undefined,
                });
            }
        }
    };

    handleNarrativeFieldEdit = (item: IEntityAssertionModel) => {
        const match = this.state.assertions.find(
            (x) =>
                x.guid == item.guid &&
                x.assertionDescriptor &&
                item.assertionDescriptor &&
                x.assertionDescriptor.guid == item.assertionDescriptor.guid
        );
        if (match)
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: JSON.parse(JSON.stringify(match)),
            });
    };

    handleClearNarrativeFieldEdit = () => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = '';
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    handleNarrativeFieldTextChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = event.target.value;
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    onClickViewAddEditNotesSection = () => {
        this.setState({ showSignificantChanges: !this.state.showSignificantChanges });
    };

    handleAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.StrategySignificantChange },
            status: { id: NoteStatusTypes.Open },
            organizationGuid: this.state.defaultOrganization.guid,
            categories: [{ id: NoteCategories.StrategySignificantChanges }],
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        this.setState({
            openNoteEditor: true,
            selectedSignificantChange: currentNote,
            showSignificantChanges: false,
        });
    };

    handleEditNote = (e: React.MouseEvent<HTMLButtonElement>, noteGuidToEdit: string) => {
        if (!this.state.significantChanges) return;

        const currentNote = this.state.significantChanges.find((x) => x.guid == noteGuidToEdit);
        if (currentNote) {
            this.setState({
                selectedSignificantChange: currentNote,
                openNoteEditor: true,
                showSignificantChanges: false,
            });
        }
    };

    refreshParent = (notes: INoteModel[]) => {
        this.setState({ significantChanges: JSON.parse(JSON.stringify(notes)) });
    };

    handleCancelNoteEditor = () => {
        this.setState({
            significantChanges: this.state.originalSignificantChanges
                ? JSON.parse(JSON.stringify(this.state.originalSignificantChanges))
                : undefined,
            selectedSignificantChange: undefined,
            openNoteEditor: false,
            showSignificantChanges: true,
        });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        let significantChanges = this.state.significantChanges || [];
        let matchFound = false;
        for (let i = 0; i < significantChanges.length; i++) {
            if (significantChanges[i].guid == noteItem.guid) {
                matchFound = true;
                significantChanges[i] = JSON.parse(JSON.stringify(noteItem));
                break;
            }
        }

        if (!matchFound) significantChanges.push(JSON.parse(JSON.stringify(noteItem)));
        significantChanges = significantChanges.sort(
            Sort.compareDate('modifiedDate', undefined, 'desc')
        );
        this.setState({
            pendingResponse: false,
            openNoteEditor: false,
            significantChanges: significantChanges,
            originalSignificantChanges: JSON.parse(JSON.stringify(significantChanges)),
            selectedSignificantChange: undefined,
            showSignificantChanges: true,
        });
    };

    getNotesUICustomization = () => {
        const config: INotesUICustomization = {
            hideCategories: true,
            contentHeaderText: 'Impact',
            displayAdditionalInfoAsColumns: true,
            hideFilterByCategory: true,
            hideAuditFields: true,
            hideExport: true,
        };

        return config;
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        if (this.state.significantChanges) {
            let significantChanges: INoteModel[] = [];
            if (key == 'date') {
                // HACK. Only for Signiticant Changes List, Date = Associated Entity Assertion and NOT Modified Date
                const significantChangesTemp: INoteModel[] = JSON.parse(
                    JSON.stringify(this.state.significantChanges)
                );
                {
                    significantChangesTemp.map((item: INoteModel) => {
                        item.modifiedDate = Common.dateFormat(
                            NoteHelper.getAssertionValue(item, 'DATE')
                        );
                    });
                }

                const sortedData = significantChangesTemp.sort(
                    Sort.compareDate('modifiedDate', subKey, order)
                );
                sortedData.map((item: INoteModel) => {
                    significantChanges.push(
                        this.state.significantChanges.find((x) => x.guid === item.guid)!
                    );
                });
            } else {
                significantChanges = this.state.significantChanges.sort(
                    Sort.compareValues(key, subKey, order, subGrandKey)
                );
            }

            this.setState({ significantChanges: significantChanges });
        }
    };

    showConfirmRemoveDailog = (note: INoteModel) => {
        this.setState({
            showConfirmRemoveSignificantChangeDialog: true,
            selectedSignificantChangeToDelete: note,
        });
    };

    cancelRemoveSignificantChange = () => {
        this.setState({
            showConfirmRemoveSignificantChangeDialog: false,
            selectedSignificantChangeToDelete: undefined,
        });
    };

    deleteNote = async () => {
        if (!this.state.selectedSignificantChangeToDelete) return;

        let key;
        if (this.state.selectedSignificantChangeToDelete.type!.id == NoteTypes.CaseNote) {
            key = this.state.selectedSignificantChangeToDelete.caseGuid;
        }

        const response = await _apiClient.deleteNote(
            this.state.selectedSignificantChangeToDelete!.guid!,
            key
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        const updatedSignificantChanges = this.state.significantChanges.filter(
            (x) => x.guid != this.state.selectedSignificantChangeToDelete!.guid!
        );
        this.setState({
            significantChanges: updatedSignificantChanges,
            originalSignificantChanges: JSON.parse(JSON.stringify(updatedSignificantChanges)),
            showConfirmRemoveSignificantChangeDialog: false,
        });
    };

    handleSignificantChangesExportError = () => {
        const validation = this.state.validation;
        validation.significantChanges = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    handleExportError = () => {
        const validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        if (
            this.state.openNoteEditor &&
            this.state.selectedSignificantChange &&
            this.state.openNoteEditor
        )
            return (
                <NoteEditor
                    authorizedToEdit={true}
                    user={this.props.user}
                    noteCategoryTypes={this.state.noteCategoryTypes}
                    currentNote={this.state.selectedSignificantChange}
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.handleCancelNoteEditor}
                    notesUICustomization={this.getNotesUICustomization()}
                    enableNoteAssociations={true}
                    noteAssociationTitle="Associated Topic(s)"
                    entityType={{ id: EntityTypes.AssertionDescriptor }}
                    entityItems={NoteHelper.getAssociatedAssertionDescriptorListForSignificantChanges(
                        this.state.assertions
                    )}
                />
            );

        return (
            <div>
                <h1 className="mb-3">
                    Strategic Plan
                    <span className="font-size-xs ms-2">{this.getLastUpdated()}</span>
                    {this.state.readOnly ? (
                        <span>
                            <button
                                className="btn btn-no-bg float-end text-gray "
                                onClick={() => this.setState({ showSignificantChanges: true })}
                            >
                                <i className="fal fa-directions" />
                                &nbsp;Significant Changes
                            </button>
                            <ExportFileButton
                                url={ApiRoutes.ExportReport}
                                reportType={{ id: MyDocumentsTypesEnum.StrategicPlanExport.Value }}
                                onError={this.handleExportError}
                                icon="fa-file-pdf"
                            />
                            {Authorization.userHasRight(
                                UserRightsEnum.EditStrategicPlan,
                                this.props.user
                            ) ? (
                                <button
                                    className="btn btn-no-bg float-end text-gray "
                                    onClick={() => this.setState({ readOnly: false })}
                                >
                                    <i className="fal fa-pen" />
                                    &nbsp;Edit
                                </button>
                            ) : null}
                        </span>
                    ) : (
                        <span>
                            <button
                                className="btn btn-default float-end"
                                onClick={() =>
                                    this.setState({
                                        assertions: this.state.originalAssertions,
                                        readOnly: true,
                                    })
                                }
                            >
                                Cancel
                            </button>
                            <input
                                type="button"
                                className="btn btn-orange float-end "
                                onClick={this.save}
                                value="Save"
                            />
                        </span>
                    )}
                </h1>
                <span className="text-danger">{this.state.validation.model}</span>
                <h5>Situation</h5>
                <hr className="strategic-plan-line-margin" />
                {this.state.assertions
                    .filter(
                        (x) =>
                            x.assertionDescriptor &&
                            x.assertionDescriptor.category &&
                            x.assertionDescriptor.category.id ==
                                AssertionDescriptorCategories.Strategy_Situation.Value
                    )
                    .map((item: IEntityAssertionModel) => {
                        return (
                            <div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <label className="text-gray">
                                            {item.assertionDescriptor.displayName}
                                        </label>
                                        {item.assertionDescriptor.helpText ? (
                                            <Help
                                                type={ApplicationHelpType.Info}
                                                title={item.assertionDescriptor.displayName!}
                                                helpText={item.assertionDescriptor.helpText}
                                            />
                                        ) : null}
                                        {this.state.significantChanges &&
                                        NoteHelper.hasSignificantChange(
                                            item.assertionDescriptor.guid!,
                                            this.state.significantChanges
                                        ) ? (
                                            <button
                                                className="btn btn-no-bg"
                                                onClick={() =>
                                                    this.setState({ showSignificantChanges: true })
                                                }
                                            >
                                                <i className="fal fa-lg fa-directions color-gray" />
                                            </button>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-9 horizontal-padding">
                                        <EntityAssertionControl
                                            assertionDescriptor={item.assertionDescriptor}
                                            entityAssertions={[item]}
                                            editMode={!this.state.readOnly}
                                            refreshChange={this.finalizeChange}
                                            handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                            narrativeFieldAsPopup={true}
                                        />
                                    </div>
                                </div>
                                {this.state.readOnly ? (
                                    /*&& index != (this.state.assertions.filter(x => x.assertionDescriptor && x.assertionDescriptor.category && x.assertionDescriptor.category.id == AssertionDescriptorCategories.Strategy_Situation.Value).length - 1)*/
                                    <hr className="thin-top-border strategic-plan-line-margin" />
                                ) : null}
                            </div>
                        );
                    })}
                <h5>Goals</h5>
                <hr className="strategic-plan-line-margin" />
                {this.state.assertions
                    .filter(
                        (x) =>
                            x.assertionDescriptor &&
                            x.assertionDescriptor.category &&
                            x.assertionDescriptor.category.id ==
                                AssertionDescriptorCategories.Strategy_Goals.Value
                    )
                    .map((item: IEntityAssertionModel) => {
                        return (
                            <div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <label className="text-gray">
                                            {item.assertionDescriptor.displayName}
                                        </label>
                                        {item.assertionDescriptor.helpText ? (
                                            <Help
                                                type={ApplicationHelpType.Info}
                                                title={item.assertionDescriptor.displayName!}
                                                helpText={item.assertionDescriptor.helpText}
                                            />
                                        ) : null}
                                        {this.state.significantChanges &&
                                        NoteHelper.hasSignificantChange(
                                            item.assertionDescriptor.guid!,
                                            this.state.significantChanges
                                        ) ? (
                                            <button
                                                className="btn btn-no-bg"
                                                onClick={() =>
                                                    this.setState({ showSignificantChanges: true })
                                                }
                                            >
                                                <i className="fal fa-lg fa-directions color-gray" />
                                            </button>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-9 horizontal-padding">
                                        <EntityAssertionControl
                                            assertionDescriptor={item.assertionDescriptor}
                                            entityAssertions={[item]}
                                            editMode={!this.state.readOnly}
                                            refreshChange={this.finalizeChange}
                                            handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                            narrativeFieldAsPopup={true}
                                        />
                                    </div>
                                </div>
                                {this.state.readOnly ? (
                                    /*&& index != (this.state.assertions.filter(x => x.assertionDescriptor && x.assertionDescriptor.category && x.assertionDescriptor.category.id == AssertionDescriptorCategories.Strategy_Goals.Value).length - 1)*/
                                    <hr className="thin-top-border strategic-plan-line-margin" />
                                ) : null}
                            </div>
                        );
                    })}
                <h5>Principles</h5>
                <hr className="strategic-plan-line-margin" />
                {this.state.assertions
                    .filter(
                        (x) =>
                            x.assertionDescriptor &&
                            x.assertionDescriptor.category &&
                            x.assertionDescriptor.category.id ==
                                AssertionDescriptorCategories.Strategy_Principles.Value
                    )
                    .map((item: IEntityAssertionModel) => {
                        return (
                            <div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <label className="text-gray">
                                            {item.assertionDescriptor.displayName}
                                        </label>
                                        {item.assertionDescriptor.helpText ? (
                                            <Help
                                                type={ApplicationHelpType.Info}
                                                title={item.assertionDescriptor.displayName!}
                                                helpText={item.assertionDescriptor.helpText}
                                            />
                                        ) : null}
                                        {this.state.significantChanges &&
                                        NoteHelper.hasSignificantChange(
                                            item.assertionDescriptor.guid!,
                                            this.state.significantChanges
                                        ) ? (
                                            <button
                                                className="btn btn-no-bg"
                                                onClick={() =>
                                                    this.setState({ showSignificantChanges: true })
                                                }
                                            >
                                                <i className="fal fa-lg fa-directions color-gray" />
                                            </button>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-9 horizontal-padding">
                                        <EntityAssertionControl
                                            assertionDescriptor={item.assertionDescriptor}
                                            entityAssertions={[item]}
                                            editMode={!this.state.readOnly}
                                            refreshChange={this.finalizeChange}
                                            handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                            narrativeFieldAsPopup={true}
                                        />
                                    </div>
                                </div>
                                {this.state.readOnly ? (
                                    /*&& index != (this.state.assertions.filter(x => x.assertionDescriptor && x.assertionDescriptor.category && x.assertionDescriptor.category.id == AssertionDescriptorCategories.Strategy_Principles.Value).length - 1) */
                                    <hr className="thin-top-border strategic-plan-line-margin" />
                                ) : null}
                            </div>
                        );
                    })}

                {this.state.currentNarrativeCaseAssertionOnEditMode ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() =>
                            this.setState({ currentNarrativeCaseAssertionOnEditMode: undefined })
                        }
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {
                                    this.state.currentNarrativeCaseAssertionOnEditMode
                                        .assertionDescriptor!.displayName
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div>
                                <textarea
                                    className="form-control"
                                    value={
                                        this.state.currentNarrativeCaseAssertionOnEditMode.textValue
                                    }
                                    name={
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            .assertionDescriptor!.guid
                                    }
                                    rows={10}
                                    onChange={this.handleNarrativeFieldTextChange}
                                />
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() =>
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            ? this.finalizeChange([
                                                  this.state
                                                      .currentNarrativeCaseAssertionOnEditMode,
                                              ])
                                            : {}
                                    }
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end "
                                    onClick={() =>
                                        this.setState({
                                            currentNarrativeCaseAssertionOnEditMode: undefined,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleClearNarrativeFieldEdit}
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                {this.state.showSignificantChanges ? (
                    <Modal
                        centered
                        size="xl"
                        show={true}
                        onHide={() =>
                            this.setState({
                                showSignificantChanges: false,
                                selectedSignificantChange: undefined,
                                openNoteEditor: false,
                            })
                        }
                        backdrop={false}
                        dialogClassName="add-task-dialog"
                    >
                        <Modal.Header>
                            <Modal.Title className="w-100">
                                <div className="row">
                                    <div className="col-sm-6">Significant Changes</div>
                                    <div className="col-sm-6">
                                        {Authorization.userHasRight(
                                            UserRightsEnum.EditStrategicPlan,
                                            this.props.user
                                        ) ? (
                                            <button
                                                className="btn-no-bg float-end  "
                                                onClick={this.handleAddNote}
                                            >
                                                {' '}
                                                <span className="btn-green btn float-end btn-icon">
                                                    <i className="fal fa-lg fa-plus color-white" />
                                                </span>
                                            </button>
                                        ) : null}
                                        <ExportFileButton
                                            url={ApiRoutes.ExportReport}
                                            reportType={{
                                                id: MyDocumentsTypesEnum.StrategySignificantChanges
                                                    .Value,
                                            }}
                                            reportParameters={{
                                                reportType: {
                                                    id: MyDocumentsTypesEnum.StrategySignificantChanges
                                                        .Value,
                                                },
                                                options: {
                                                    typeId: NoteTypes.StrategySignificantChange,
                                                    key: NoteCategories.StrategySignificantChanges.toString(),
                                                },
                                            }}
                                            onError={this.handleSignificantChangesExportError}
                                        />
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span className="text-danger">
                                {this.state.validation.significantChanges}
                            </span>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="col-sm-2 ">Change</th>
                                        <SortableHeader
                                            headerText="Date"
                                            sortKey="date"
                                            onSort={this.sortData}
                                            thClassName="col-sm-1"
                                        />
                                        <th>Impact</th>
                                        <th className="col-sm-2 ">Associated Topic(s)</th>
                                        {Authorization.userHasRight(
                                            UserRightsEnum.EditStrategicPlan,
                                            this.props.user
                                        ) ? (
                                            <th className="col-sm-1"></th>
                                        ) : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.significantChanges.map((item: INoteModel) => {
                                        return (
                                            <tr key={item.guid}>
                                                <td className="col-sm-2">
                                                    {NoteHelper.getAssertionValue(item, 'CHANGE')}
                                                </td>
                                                <td className="col-sm-1 ">
                                                    {Common.dateFormat(
                                                        NoteHelper.getAssertionValue(item, 'DATE')
                                                    )}
                                                </td>
                                                <td>
                                                    {item.content!.split('\n').map((item, key) => {
                                                        return (
                                                            <span key={key}>
                                                                {item}
                                                                <br />
                                                            </span>
                                                        );
                                                    })}
                                                </td>
                                                <td className="col-sm-3 ">
                                                    {NoteHelper.getAssociatedTopicsForSignificantChanges(
                                                        item
                                                    )
                                                        .split('\n')
                                                        .map((item, key) => {
                                                            return (
                                                                <span key={key}>
                                                                    {item}
                                                                    <br />
                                                                </span>
                                                            );
                                                        })}
                                                </td>
                                                {Authorization.userHasRight(
                                                    UserRightsEnum.EditStrategicPlan,
                                                    this.props.user
                                                ) ? (
                                                    <td className="col-sm-2 ">
                                                        <button
                                                            className="btn btn-no-bg float-end"
                                                            onClick={() => {
                                                                this.showConfirmRemoveDailog(item);
                                                            }}
                                                        >
                                                            <i className="fal fa-lg fa-trash-alt"></i>
                                                        </button>

                                                        {Authorization.userHasRight(
                                                            UserRightsEnum.EditCaseNote,
                                                            this.props.user
                                                        ) &&
                                                        (this.state.loggedInUserDataScopeId !==
                                                            DataScopesEnum.LocalBasic.Value ||
                                                            item.allowLocalEdit) ? (
                                                            <button
                                                                className="btn btn-no-bg float-end"
                                                                onClick={(
                                                                    e: React.MouseEvent<HTMLButtonElement>
                                                                ) => {
                                                                    this.handleEditNote(
                                                                        e,
                                                                        item.guid!
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fal fa-lg fa-pen" />
                                                            </button>
                                                        ) : null}
                                                    </td>
                                                ) : null}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            showSignificantChanges: false,
                                            selectedSignificantChange: undefined,
                                            openNoteEditor: false,
                                        })
                                    }
                                >
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                <CheckmateDialog
                    isShowingModal={this.state.showConfirmRemoveSignificantChangeDialog || false}
                    body="Are you sure you want to delete the selected item?"
                    handleClose={this.cancelRemoveSignificantChange}
                    handleConfirm={this.deleteNote}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end "
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
