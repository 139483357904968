import ApiClient from '../../services/apiClient';
import { LookupDataEnums } from '../../utilities/Constants';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useExpertRetainedByTypesLookup = () => {
    return useQuery({
        queryKey: ['get-expert-retained-by-types-lookup'],
        queryFn: async () => {
            const response = await _apiClient.getLookupData(LookupDataEnums.ExpertRetainedByTypes);

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve expert retained by types.');
            }
            return response.payload;
        },
        staleTime: Infinity,
    });
};
