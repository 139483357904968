import UIHelper from "../utilities/UIHelper";

    interface IPaginationProps {
        pageNumber: number;
        pageSize: number;
        count: number;
        handleNextPage: () => void;
        handlePreviousPage: () => void;
    }

    const Pagination: React.FC<IPaginationProps> = ({ pageNumber, pageSize, count, handleNextPage, handlePreviousPage }) => {
        return (
            <div>
                {pageNumber + 1 < Math.ceil(count / pageSize) && (
                    <button className="btn btn-orange float-end text-gray horizontal-margin" onClick={handleNextPage}>
                        Next
                    </button>
                )}
                {pageNumber > 0 && (
                    <button className="btn btn-orange float-end text-gray horizontal-margin" onClick={handlePreviousPage}>
                        Previous
                    </button>
                )}
                {Math.ceil(count / pageSize) > 0 && (
                    <span className="float-end text-gray" style={{ paddingTop: '10px', paddingRight: '10px' }}>
                        Page {pageNumber + 1} of {Math.ceil(count / pageSize)}
                        {UIHelper.buildPagingDetailsString(count, pageSize, pageNumber)}
                    </span>
                )}
            </div>
        );
    };


export default Pagination;
