import './caseFileSection.scss';

import { IDisplayFieldModel, IDisplaySectionModel } from '../../interfaces/IDisplaySectionModel';
import { useEffect, useMemo, useState } from 'react';

import Authorization from '../../stores/Authorization';
import { DynamicSectionRenderer } from './DynamicSectionRenderer';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { StaticSectionRenderer } from './StaticSectionRenderer';
import { useCaseFileContext } from '../../contexts/CaseFileContext';

function addPlaceholderFields(fields: IDisplayFieldModel[]): IDisplayFieldModel[] {
    const newFields: IDisplayFieldModel[] = [];
    let lastOrder = 0;
    fields.forEach((field) => {
        const sortOrderDiff = field.sortOrder - lastOrder;

        for (let i = 1; i < sortOrderDiff; i++) {
            newFields.push({ sortOrder: lastOrder + i } as IDisplayFieldModel);
        }

        newFields.push(field);
        lastOrder = field.sortOrder;
    });
    return newFields;
}

function splitFieldsIntoRows(
    fields: IDisplayFieldModel[],
    numColumns: number
): IDisplayFieldModel[][] {
    const fieldsWithPlaceholders = addPlaceholderFields(fields);

    const rows: IDisplayFieldModel[][] = [];

    let newRow: IDisplayFieldModel[] = [];
    fieldsWithPlaceholders.forEach((field) => {
        newRow.push(field);

        if (newRow.length === numColumns) {
            rows.push(newRow);
            newRow = [];
        }
    });

    if (newRow.length > 0) {
        rows.push(newRow);
    }

    return rows;
}

interface ICaseFileSectionProps {
    displayAsCondensed: boolean;
    numberOfColumns: 2 | 3;
    section: IDisplaySectionModel;
    user: IUserModel;
    validation: IValidation;
}

export function CaseFileSection({
    displayAsCondensed,
    numberOfColumns,
    section,
    user,
    validation,
}: ICaseFileSectionProps) {
    const [loggedInUserDataScopeId, setLoggedInUserDataScopeId] = useState<number>(0);

    const { currentCase, isReadOnlyMode, zoneDefenseCounsels, zoneUsers } = useCaseFileContext();

    const rows = useMemo(
        () => splitFieldsIntoRows(section.fields ?? [], numberOfColumns),
        [section.fields]
    );

    useEffect(() => {
        if (currentCase) {
            const dataScopeId = Authorization.getUserDefaultOrganizationDataScope(user);
            setLoggedInUserDataScopeId(dataScopeId);
        }
    }, []);

    return (
        <>
            {section.isStatic ? (
                <StaticSectionRenderer
                    dataScopeId={loggedInUserDataScopeId}
                    isReadOnlyMode={isReadOnlyMode}
                    section={section}
                    user={user}
                    validation={validation}
                    zoneDefenseCounsels={zoneDefenseCounsels}
                    zoneUsers={zoneUsers}
                />
            ) : (
                <DynamicSectionRenderer
                    rows={rows}
                    section={section}
                    user={user}
                    validation={validation}
                    numberOfColumns={numberOfColumns}
                    displayAsCondensed={displayAsCondensed}
                />
            )}
        </>
    );
}
