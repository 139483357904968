import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { StrategicPlan } from '../strategy/StrategicPlan';
import { useLocation } from 'react-router';

function StrategicPlanWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();
    return (
        <>
            <DocumentTitle title="Strategic Plan"></DocumentTitle>
            <StrategicPlan user={props.user} refreshIdentifier={key} />
        </>
    );
}

export default StrategicPlanWrapper;
