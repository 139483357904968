import * as React from 'react';

import {
    IDepositionUISearchModel,
    IQueryFilterCriteria,
    IQueryFilterCriteriaSelectionValue,
} from '../../interfaces/IQuery';

import CheckmateSelect from '../../components/shared/CheckmateSelect';
import Common from '../../stores/Common';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import Sort from '../../stores/Sort';
import { DataScopesEnum } from '../../utilities/Constants';

export interface IDepositionSectionCriteriaValuesProps {
    defenseCounselsOptions: IQueryFilterCriteriaSelectionValue[];
    depositionCategoryOptions: IMultiSelectOptions[];
    depositionSearchCriteria?: IDepositionUISearchModel;
    depoStatusTypeOptions: IMultiSelectOptions[];
    disableInputs: boolean;
    item: IQueryFilterCriteria;
    onChangeAlt: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    onCheckedChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        item?: IQueryFilterCriteria
    ) => void;
    onFilterValuesSelectedAlt: (optionsList: any, name: string) => void;
    onSearchableSingleSelectChange: (selectedItem?: any) => void;
    zoneUsers: IUserModel[];
}

export function DepositionSectionCriteriaValues(props: IDepositionSectionCriteriaValuesProps) {
    return (
        <table className="table table-sm">
            <tbody>
                {props.item.validationError ? (
                    <tr>
                        <td colSpan={2}>
                            <span className="text-danger">{props.item.validationError}</span>
                        </td>
                    </tr>
                ) : null}
                <tr>
                    <td style={{ borderTop: 'none' }}>Type</td>
                    <td style={{ borderTop: 'none' }}>
                        <CheckmateSelect
                            isMulti={true}
                            options={props.depositionCategoryOptions}
                            value={props.depositionSearchCriteria?.categories ?? []}
                            isDisabled={
                                props.depositionSearchCriteria
                                    ? props.depositionSearchCriteria.readOnly
                                    : false
                            }
                            onChange={(e: any) => {
                                props.onFilterValuesSelectedAlt(e, 'depositionCategories');
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>
                        <CheckmateSelect
                            isMulti={true}
                            options={props.depoStatusTypeOptions}
                            value={props.depositionSearchCriteria?.statuses ?? []}
                            isDisabled={
                                props.depositionSearchCriteria
                                    ? props.depositionSearchCriteria.readOnly
                                    : false
                            }
                            onChange={(e: any) => {
                                props.onFilterValuesSelectedAlt(e, 'depositionStatuses');
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Date Range</td>
                    <td>
                        <input
                            className={
                                'form-control d-inline-block' +
                                (props.depositionSearchCriteria &&
                                props.depositionSearchCriteria.startDate &&
                                Common.isValidDate(props.depositionSearchCriteria.startDate)
                                    ? ''
                                    : ' unselectClass')
                            }
                            min="1753-01-01"
                            disabled={
                                props.depositionSearchCriteria
                                    ? props.depositionSearchCriteria.readOnly
                                    : false
                            }
                            value={
                                props.depositionSearchCriteria &&
                                props.depositionSearchCriteria.startDate
                                    ? Common.dateFormat(props.depositionSearchCriteria.startDate)
                                    : ''
                            }
                            type="date"
                            name="depositionStartDate"
                            onChange={props.onChangeAlt}
                        />
                        <span>&nbsp;to&nbsp;</span>
                        <input
                            className={
                                'form-control d-inline-block' +
                                (props.depositionSearchCriteria &&
                                props.depositionSearchCriteria.endDate &&
                                Common.isValidDate(props.depositionSearchCriteria.endDate)
                                    ? ''
                                    : ' unselectClass')
                            }
                            min="1753-01-01"
                            disabled={
                                props.depositionSearchCriteria
                                    ? props.depositionSearchCriteria.readOnly
                                    : false
                            }
                            value={
                                props.depositionSearchCriteria &&
                                props.depositionSearchCriteria.endDate
                                    ? Common.dateFormat(props.depositionSearchCriteria.endDate)
                                    : ''
                            }
                            type="date"
                            name="depositionEndDate"
                            onChange={props.onChangeAlt}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Defense Counsel</td>
                    <td>
                        <CheckmateSelect
                            options={props.defenseCounselsOptions}
                            name="DepositionDefenseCounsel"
                            value={
                                props.depositionSearchCriteria &&
                                props.depositionSearchCriteria.defenseCounsel
                                    ? props.depositionSearchCriteria.defenseCounsel
                                    : undefined
                            }
                            onChange={props.onSearchableSingleSelectChange}
                            isDisabled={props.depositionSearchCriteria?.readOnly}
                        />
                    </td>
                </tr>
                {props.depositionSearchCriteria &&
                props.depositionSearchCriteria.defenseCounsel &&
                props.depositionSearchCriteria.defenseCounsel.guid ? (
                    <tr>
                        <td>Defense Attorney</td>
                        <td>
                            <CheckmateSelect
                                isMulti={true}
                                options={getUsersInDefenseFirmOptions(
                                    props.zoneUsers,
                                    props.depositionSearchCriteria?.defenseCounsel?.guid ?? ''
                                )}
                                value={props.depositionSearchCriteria.defenseCounselUsers || []}
                                isDisabled={props.depositionSearchCriteria.readOnly}
                                onChange={(e: any) => {
                                    props.onFilterValuesSelectedAlt(e, 'defenseCounselUsers');
                                }}
                            />
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </table>
    );
}

const getUsersInDefenseFirmOptions = (users: IUserModel[], guid: string) => {
    const usersInFirm = users
        .filter((x) => x.homeOrganization && x.homeOrganization.guid === guid)
        .map((user: IUserModel) => ({
            label:
                user.profile!.firstName +
                ' ' +
                user.profile!.lastName +
                (user.activeZoneDataScope &&
                user.activeZoneDataScope.id === DataScopesEnum.LocalBasic.Value
                    ? ' (Local)'
                    : ''),
            value: user.guid!,
            id: 0,
            guid: user.guid,
        }));

    usersInFirm.sort(Sort.compareValues('label'));
    return usersInFirm;
};
