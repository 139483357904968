import ApiClient from '../../services/apiClient';
import { LookupDataEnums } from '../../utilities/Constants';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useExpertDisciplineTypesLookup = () => {
    return useQuery({
        queryKey: ['get-expert-discipline-types-lookup'],
        queryFn: async () => {
            const response = await _apiClient.getLookupData(LookupDataEnums.ExpertDisciplineTypes);

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve expert disciplines.');
            }
            return response.payload;
        },
    });
};
