import * as React from 'react';

import { IQueryFilterCriteria } from '../../interfaces/IQuery';

export interface IRangeControlProps {
    disableFrom: boolean;
    disableTo: boolean;
    fromName: string;
    fromValue: string | number;
    isCurrency: boolean;
    item: IQueryFilterCriteria;
    toName: string;
    toValue: string | number;
    onFromChange: (e: React.ChangeEvent<HTMLInputElement>, item: IQueryFilterCriteria) => void;
    onToChange: (e: React.ChangeEvent<HTMLInputElement>, item: IQueryFilterCriteria) => void;
}

export function NumericRangeControl({
    disableFrom,
    disableTo,
    fromName,
    fromValue,
    toName,
    toValue,
    isCurrency,
    item,
    onFromChange,
    onToChange,
}: IRangeControlProps) {
    return (
        <div style={{ display: 'block' }}>
            {isCurrency && <span>$&nbsp;</span>}
            <input
                className="form-control d-inline-block"
                type="number"
                name={fromName}
                disabled={disableFrom}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onFromChange(e, item);
                }}
                value={fromValue}
            />
            <span style={{ display: 'inline-block', margin: '0 8px' }}>to</span>
            <input
                className="form-control d-inline-block"
                type="number"
                name={toName}
                disabled={disableTo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onToChange(e, item);
                }}
                value={toValue}
            />
            <span className="text-danger ms-2">{item.validationError}</span>
        </div>
    );
}
