import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { ProjectList } from '../strategy/ProjectList';
import { useLocation } from 'react-router';

function ProjectListWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();
    return (
        <>
            <DocumentTitle title="Projects"></DocumentTitle>
            <ProjectList user={props.user} refreshIdentifier={key} />
        </>
    );
}

export default ProjectListWrapper;
