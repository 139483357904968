import Authorization from '../../../../stores/Authorization';
import { IOrganizationRoleModel } from '../../../../interfaces/IUser';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { MenuItemNavLinkWithIcon } from '../../MenuItemNavLinkWithIcon';
import { UserUnreadQueryExportStatus } from '../../../report/UserUnreadQueryExportStatus';
import { useAuthContext } from '../../../../contexts/AuthContext';

interface IDataMenuItemProps {
    onZoneChange: (org: IOrganizationRoleModel, redirectPath?: string) => void;
}

export function DataMenuItem(props: IDataMenuItemProps) {
    const { user, isLoggedIn: isAuthenticated } = useAuthContext();

    const show = Authorization.isAuthorizedToAtLeastOneRoute(
        [LocalRoutes.ReportDetail, LocalRoutes.Query, LocalRoutes.ZoneNotes],
        user
    );

    return (
        <>
            {show && (
                <div className="innerContainer">
                    <button className="btn-black bold" data-bs-toggle="dropdown">
                        DATA
                    </button>
                    <div className="dropdown-menu adjust-left">
                        {isAuthenticated && (
                            <div>
                                {/*
                                {Authorization.isAuthorizedToRoute(LocalRoutes.Query, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-database"
                                        label="QUERY LEGACY"
                                        to={LocalRoutes.QueryLegacy}
                                    />
                                )} 
                                */}
                                {Authorization.isAuthorizedToRoute(LocalRoutes.Query, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-database"
                                        label="QUERY"
                                        to={LocalRoutes.Query2}
                                    />
                                )}
                                {Authorization.isAuthorizedToRoute(LocalRoutes.ZoneNotes, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-memo"
                                        label="ZONE NOTES"
                                        to={LocalRoutes.ZoneNotes}
                                    />
                                )}
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.ReportDetail,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-file-chart-column"
                                        label="REPORT TOOL"
                                        to={LocalRoutes.ReportTypeList}
                                    />
                                )}
                                <MenuItemNavLinkWithIcon
                                    iconClass="fal fa-file-lines"
                                    label="MY DOCUMENTS"
                                    to={LocalRoutes.MyDocuments}
                                />
                            </div>
                        )}
                    </div>
                    <UserUnreadQueryExportStatus user={user} onZoneChange={props.onZoneChange} />
                </div>
            )}
        </>
    );
}
