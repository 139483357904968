import { DocumentTypeEnum, StrategyTypes } from '../../utilities/Constants';
import { useEffect, useState } from 'react';

import { ChildDocument } from './ChildDocument';
import Common from '../../stores/Common';
import ExpandableRow from '../../components/shared/ExpandableRow';
import { ILookupModel } from '../../interfaces/ILookup';
import { IViewDocumentModel } from '../../interfaces/IDocument';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Stack } from 'react-bootstrap';
import { useGetChildDocuments } from '../../shared/react-query-hooks/useGetMyChildDocuments';

interface IDocumentRowProps {
    canDelete: boolean;
    document: IViewDocumentModel;
    documentTypes: ILookupModel[];
    onDelete: (document: IViewDocumentModel, onSuccess?: () => void) => Promise<void>;
    onDownload: (event: React.FormEvent<any>, document: IViewDocumentModel) => void;
    onViewClick: (document: IViewDocumentModel) => void;
}

const DocumentRow = ({
    canDelete,
    document,
    documentTypes,
    onDelete,
    onDownload,
    onViewClick,
}: IDocumentRowProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [childDocuments, setChildDocuments] = useState<IViewDocumentModel[]>([]);

    const {
        data: reportChildDocuments,
        isLoading,
        isSuccess,
        refetch: refetchChildDocuments,
    } = useGetChildDocuments(document.guid!, expanded);

    useEffect(() => {
        if (isSuccess) {
            setChildDocuments(reportChildDocuments ?? []);
        }
    }, [isSuccess, reportChildDocuments]);

    const handleToggle = async () => {
        setExpanded(!expanded);
    };

    const handleViewClick = async () => {
        onViewClick(document);
    };

    const getDocumentTypeEnumValue = (type: string): DocumentTypeEnum | undefined => {
        const map: { [key: string]: DocumentTypeEnum } = {
            WorkProduct: DocumentTypeEnum.WorkProduct,
            OfficialCaseDocument: DocumentTypeEnum.OfficialCaseDocument,
            Report: DocumentTypeEnum.Report,
            DataExport: DocumentTypeEnum.DataExport,
            ScreenPDF: DocumentTypeEnum.ScreenPDF,
        };
        return map[type];
    };

    //Todo:@@archana - As discussed - strategicplan and executionplan is saved (when we choose save - pdf) into database documents table only, but not in the entitydocuments table..
    const getNavigationUrl = (document: IViewDocumentModel): string => {
        let navigationUrl = '';

        const documentTypeEnumValue = getDocumentTypeEnumValue(document.type);

        switch (documentTypeEnumValue) {
            case DocumentTypeEnum.Report:
                if (document.subType === 'Weekly Recap' && document.isCollab) {
                    navigationUrl = LocalRoutes.WeeklyRecap.replace(':guid', document.entityGuid!);
                } else if (document.subType === 'Monthly Recap') {
                    navigationUrl = LocalRoutes.MonthlyRecap.replace(':guid', document.entityGuid!);
                }
                // else if (document.subType === 'Strategy Report') {// this is not required to view (only download)
                //     //reports/12
                //     navigationUrl = LocalRoutes.Strategy.replace(':guid', document.entityGuid!);
                // }
                break;
            case DocumentTypeEnum.ScreenPDF:
                //JurisdictionStrategyDetailExport, PlaintiffsFirmStrategyDetailExport, ProjectDetailExport, LocalCounselStrategyDetailExport, ExpertStrategyDetailExport
                switch (document.strategySubTypeId!) {
                    case StrategyTypes.Jurisdiction.Value:
                        navigationUrl = LocalRoutes.JurisdictionStrategyDetail.replace(
                            ':guid',
                            document.entityGuid!
                        );
                        break;

                    case StrategyTypes.PlaintiffsFirm.Value:
                        navigationUrl = LocalRoutes.PlaintiffFirmStrategyDetail.replace(
                            ':guid',
                            document.entityGuid!
                        );
                        break;

                    case StrategyTypes.LocalCounsel.Value:
                        navigationUrl = LocalRoutes.LocalCounselStrategyDetail.replace(
                            ':guid',
                            document.entityGuid!
                        );
                        break;

                    case StrategyTypes.Experts.Value:
                        navigationUrl = LocalRoutes.ExpertDetail.replace(
                            ':guid',
                            document.entityGuid!
                        );
                        break;

                    case StrategyTypes.Projects.Value:
                        navigationUrl = LocalRoutes.ProjectDetail.replace(
                            ':guid',
                            document.entityGuid!
                        );
                        break;

                    case StrategyTypes.StrategicPlan.Value: //todo:aj this is not required.
                        navigationUrl = LocalRoutes.StrategyExecutionPlan;
                        break;

                    default:
                        break;
                }

                break;
            default:
                break;
        }

        return navigationUrl;
    };

    const handleDeleteDocument = (document: IViewDocumentModel, isChild: boolean) => {
        if (isChild) {
            onDelete(document, refetchChildDocuments);
        } else {
            onDelete(document);
        }
    };

    return (
        <>
            <tr
                key={document.guid}
                style={{
                    verticalAlign: 'middle',
                    fontWeight: document.isRead ? 'normal' : 'bold',
                }}
            >
                {document.isCollab && document.hasChildren ? (
                    <td className="col-sm-3">
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 8,
                                paddingRight: '5px',
                            }}
                            onClick={handleToggle}
                        >
                            <ToggleIcon isOpen={expanded} />
                            <Link to={getNavigationUrl(document)} target="_blank">
                                {document.filename}
                            </Link>
                        </div>
                    </td>
                ) : (
                    <td className="col-sm-3" style={{ paddingLeft: 20, paddingRight: 15 }}>
                        {document.isCollab ? (
                            <Link to={getNavigationUrl(document)} target="_blank">
                                {document.filename}
                            </Link>
                        ) : (
                            <Link to="#" onClick={(e: any) => onDownload(e, document)}>
                                {document.filename}
                            </Link>
                        )}
                    </td>
                )}
                <td className="col-sm-2">{document.type}</td>
                <td className="col-sm-2">{document.subType}</td>
                <td>{document.isCollab && <i className="fal fa-users" />}</td>
                <td className="col-sm-2">{Common.dateTimeFormatToLocal(document.createdDate)}</td>
                <td className="col-sm-2">{document.status}</td>
                <td className="text-center" style={{ paddingRight: 30 }}>
                    {document.isRead && <>Yes</>}
                </td>

                <td>
                    {documentTypes.find((lookup) => lookup.id === document.typeId!)?.displayName}
                </td>

                <td>
                    <div
                        className="text-nowrap"
                        style={{
                            display: 'flex',
                            gap: 5,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        {document.isCollab ? (
                            <div style={{ width: 20, textAlign: 'center' }}>
                                <Link
                                    to={getNavigationUrl(document)}
                                    target="_blank"
                                    onClick={handleViewClick}
                                >
                                    <i className="fal fa-lg fa-eye" style={{ width: 20 }} />
                                </Link>
                            </div>
                        ) : (
                            <div style={{ width: 20, textAlign: 'center', cursor: 'pointer' }}>
                                <i
                                    className="fal fa-lg fa-file-download"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                        onDownload(e, document)
                                    }
                                />
                            </div>
                        )}

                        {canDelete ? (
                            <button
                                className="btn btn-no-bg float-end px-2"
                                onClick={() => handleDeleteDocument(document!, false)}
                            >
                                <i className="fal fa-lg fa-trash-alt"></i>
                            </button>
                        ) : (
                            <div style={{ minHeight: '30px' }}></div>
                        )}
                    </div>
                </td>
            </tr>
            {expanded && document.isCollab && document.hasChildren && (
                <ExpandableRow isExpanded={expanded} colSpan={8}>
                    <div style={{ padding: '2em', background: '#fbfbfb' }}>
                        <div style={{ display: 'inline-block' }}>
                            <Stack
                                direction="horizontal"
                                gap={5}
                                style={{
                                    fontWeight: '600',
                                    opacity: '0.8',
                                    paddingLeft: '1em',
                                    marginBottom: '0.5em',
                                }}
                            >
                                <div style={{ width: 300 }}>Filename</div>
                                <div style={{ width: 115 }}>Date</div>
                                <div style={{ width: 50, textAlign: 'center' }}>Read</div>
                                <div style={{ width: 70 }}>Type</div>
                            </Stack>
                            {isLoading ? (
                                <>Loading child documents</>
                            ) : (
                                <>
                                    {childDocuments.map((childDocument) => (
                                        <ChildDocument
                                            canDelete={canDelete}
                                            childDocument={childDocument}
                                            documentGuid={document.guid!}
                                            onDelete={handleDeleteDocument}
                                            onDownload={onDownload}
                                            key={childDocument.guid}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </ExpandableRow>
            )}
        </>
    );
};

export default DocumentRow;

const ToggleIcon = ({ isOpen }: { isOpen: boolean }) => {
    return (
        <>
            {isOpen ? (
                <i className="far color-black fa-minus" />
            ) : (
                <i className="far color-black fa-plus" />
            )}
        </>
    );
};
