export interface ISectionCommentProps {
    text: string | undefined;
    rowCount?: number;
}

export function SectionComment(props: ISectionCommentProps) {
    return (
        <>
            {!props.rowCount && (
                <div className="mb-3 margin-left-20">No data found for this time period</div>
            )}
            {props.text && (
                <div
                    className="font-size-xs mb-3 margin-left-20"
                    style={{ whiteSpace: 'pre-wrap' }}
                >
                    {props.text}
                </div>
            )}
        </>
    );
}
