import { Navigate, Outlet } from 'react-router-dom';

import { Loader } from '../../shared/Loader';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { TutorialsLayout } from './TutorialsLayout';
import { useAuthContext } from '../../../contexts/AuthContext';

const TutorialsRoute = () => {
    const { isInitialized, isLoadingUser, isLoggedIn } = useAuthContext();

    if (!isInitialized && isLoadingUser) {
        return <Loader />;
    }

    return isLoggedIn && isInitialized ? (
        <TutorialsLayout>
            <Outlet />
            {isLoadingUser && <Loader />}
        </TutorialsLayout>
    ) : (
        <Navigate to={LocalRoutes.Login} />
    );
};

export default TutorialsRoute;
