import Authorization from '../../../stores/Authorization';
import { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { IAssertionDescriptorModel } from '../../../interfaces/IAssertionDescriptor';
import { IStaticItemLookupModel } from '../../../interfaces/ILookup';
import { ITriggerRuleSettingsModel } from '../../../interfaces/ITriggerRule';
import { IUserModel } from '../../../interfaces/IUser';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { TriggerCriteriaCell } from './trigger-criteria/TriggerCriteriaCell';
import { TriggerFieldsCell } from './trigger-fields/TriggerFieldsCell';
import { useCaseStatusTypesLookup } from '../../../shared/react-query-hooks/useCaseStatusTypesLookup';
import { useGetTriggerRules } from './useGetTriggerRules';
import { useLoading } from '../../../contexts/LoadingContext';
import { useUpdateTriggerMutation } from '../useUpdateTriggerMutation';

export interface ITriggerRulesTableRowProps {
    showDisableToggle?: boolean;
    assertionDescriptors: IAssertionDescriptorModel[];
    onDeleteClick: (triggerGuid: string) => void;
    onEditClick: (triggerGuid: string) => void;
    setting: ITriggerRuleSettingsModel;
    staticItems: IStaticItemLookupModel[];
    user: IUserModel;
}

export function TriggerRulesTableRow(props: ITriggerRulesTableRowProps) {
    const { setting, user } = props;
    const { data: caseStatuses } = useCaseStatusTypesLookup();
    const loading = useLoading();
    const triggersApi = useGetTriggerRules();

    const updateMutation = useUpdateTriggerMutation((errorMessage: string) => {
        alert(errorMessage);
    });

    const showEditButton = Authorization.isAuthorizedToRoute(LocalRoutes.TriggerDetails, user);

    const handleDisableToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
        loading.showLoading();
        updateMutation
            .mutateAsync({ ...setting, isDisabled: !e.target.checked })
            .then(() => {
                triggersApi.refetch();
            })
            .finally(() => loading.hideLoading());
    };

    return (
        <tr>
            <td style={{ opacity: setting.isDisabled ? 0.3 : 1 }}>{setting.ruleName}</td>
            <td>{setting.ruleDescription}</td>
            <TriggerCriteriaCell
                assertionDescriptors={props.assertionDescriptors ?? []}
                caseStatuses={caseStatuses ?? []}
                staticItems={props.staticItems}
                triggerRules={setting.filters}
            />
            <TriggerFieldsCell
                assertionDescriptors={props.assertionDescriptors ?? []}
                caseStatuses={caseStatuses ?? []}
                staticItems={props.staticItems}
                triggerRules={setting.triggerRules}
            />
            <td>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                    {showEditButton && (
                        <button
                            className="btn btn-no-bg m-0 pt-0"
                            onClick={() => {
                                if (setting.guid) {
                                    props.onEditClick(setting.guid);
                                }
                            }}
                        >
                            <i className="fal fa-lg fa-pen" />
                        </button>
                    )}
                    <button
                        className="btn btn-no-bg m-0 pt-0"
                        onClick={() => {
                            if (setting.guid) {
                                props.onDeleteClick(setting.guid);
                            }
                        }}
                    >
                        <i className="fal fa-lg fa-trash-alt"></i>
                    </button>
                </div>
            </td>
            {props.showDisableToggle && (
                <td style={{ textAlign: 'right' }}>
                    <Form.Check
                        type="switch"
                        onChange={handleDisableToggleChange}
                        checked={!setting.isDisabled}
                    />
                </td>
            )}
        </tr>
    );
}
