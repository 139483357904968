import { useLocation, useParams } from 'react-router-dom';

import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { EntityStrategy } from '../strategy/EntityStrategy';
import { IUserModel } from '../../interfaces/IUser';
import { StrategyTypes } from '../../utilities/Constants';

export const getEntityStrategyDisplay = (strategyEntityType: number) => {
    switch (strategyEntityType) {
        case StrategyTypes.PlaintiffsFirm.Value:
            return ' Plaintiffs Firm ';
        case StrategyTypes.Jurisdiction.Value:
            return ' Jurisdiction ';
        case StrategyTypes.LocalCounsel.Value:
            return ' Local Counsel ';
        default:
            return '';
    }
};

function EntityStrategyWrapper(props: { user: IUserModel }) {
    const { type } = useParams();
    const { key } = useLocation();
    return (
        <>
            <DocumentTitle
                title={`${getEntityStrategyDisplay(parseInt(type ?? '0'))}`}
            ></DocumentTitle>
            <EntityStrategy type={parseInt(type || '')} user={props.user} refreshIdentifier={key} />
        </>
    );
}

export default EntityStrategyWrapper;
