import { useEffect } from 'react';

const confirmationMessage = 'You have unsaved changes. Continue?'; // deprecated in most browsers

export default function useUnsavedChangesWarning(isUnsafeTabClose: boolean) {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isUnsafeTabClose) {
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isUnsafeTabClose]);
}
