import {
    AlertListTypeEnum,
    AssertionDescriptorUsageTypesEnum,
    LookupDataEnums,
} from '../../utilities/Constants';
import { useLocation, useParams } from 'react-router-dom';

import { AlertList } from '../notes/AlertList';
import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { Loader } from '../../components/shared/Loader';
import { useAssertionDescriptorsLookup } from '../../shared/react-query-hooks/useAssertionDescriptorsLookup';
import { useGetFavorites } from '../../shared/react-query-hooks/useGetFavorites';
import { useGetLookupData } from '../../shared/react-query-hooks/useGetLookupData';
import { useNoteCategoryTypes } from '../../shared/react-query-hooks/useNoteCategoryTypes';
import { useZoneUsers } from '../../shared/react-query-hooks/useZoneUsers';

function AlertListWrapper(props: { user: IUserModel }) {
    const { type } = useParams();
    const { key } = useLocation();

    const typeAsNum = parseInt(type ?? '0');

    const { data: users, isLoading: usersIsLoading } = useZoneUsers();
    const { data: alertStatusTypes, isLoading: alertStatusTypesIsLoading } = useGetLookupData(
        LookupDataEnums.AlertStatusTypes
    );
    const { data: alertOriginTypes, isLoading: alertOriginTypesIsLoading } = useGetLookupData(
        LookupDataEnums.AlertOriginTypes
    );
    const { data: notePurposeTypes, isLoading: notePurposeTypesIsLoading } = useGetLookupData(
        LookupDataEnums.NotePurposeTypes
    );
    const { data: noteCategoryTypes, isLoading: noteCategoryTypesIsLoading } =
        useNoteCategoryTypes();
    const { data: noteTypes, isLoading: noteTypesIsLoading } = useGetLookupData(
        LookupDataEnums.NoteTypes
    );
    const { data: assertionDescriptors, isLoading: assertionDescriptorsIsLoading } =
        useAssertionDescriptorsLookup(AssertionDescriptorUsageTypesEnum.Case);

    const { data: defaultFavorites, isLoading: defaultFavoritesIsLoading } = useGetFavorites(
        typeAsNum,
        true
    );

    const showLoading =
        usersIsLoading ||
        alertStatusTypesIsLoading ||
        alertOriginTypesIsLoading ||
        notePurposeTypesIsLoading ||
        noteCategoryTypesIsLoading ||
        noteTypesIsLoading ||
        assertionDescriptorsIsLoading ||
        defaultFavoritesIsLoading;

    if (showLoading) {
        return <Loader />;
    }

    const defaultFavorite = defaultFavorites?.[0] ?? null;

    return (
        <>
            <DocumentTitle
                title={
                    typeAsNum === AlertListTypeEnum.MyAlertList
                        ? 'My Alert List'
                        : 'Zone Alert List'
                }
            />
            <AlertList
                alertListType={typeAsNum}
                alertOriginTypes={alertOriginTypes ?? []}
                alertStatusTypes={alertStatusTypes ?? []}
                assertionDescriptors={assertionDescriptors ?? []}
                defaultFavorite={defaultFavorite}
                noteCategoryTypes={noteCategoryTypes ?? []}
                notePurposeTypes={notePurposeTypes ?? []}
                noteTypes={noteTypes ?? []}
                users={users ?? []}
                user={props.user}
                refreshIdentifier={key}
            />
        </>
    );
}

export default AlertListWrapper;
