import {
    CaseManagerControl,
    CaseNumberControl,
    CaseStatusControl,
    LocalCounselControl,
    PackageSettlementControl,
    UniqueCaseIDControl,
} from './StaticItemControls';

import { CaseFileStaticItems } from '../../utilities/Constants';
import { FieldControlWrapper } from './FieldControlWrapper';
import { IDisplayFieldModel } from '../../interfaces/IDisplaySectionModel';
import { ReactElement } from 'react';
import { useCaseFileContext } from '../../contexts/CaseFileContext';

interface IStaticFieldRendererProps {
    displayAsCondensed: boolean;
    field: IDisplayFieldModel;
}

export function StaticFieldRenderer({ displayAsCondensed, field }: IStaticFieldRendererProps) {
    const { currentCase, staticItems } = useCaseFileContext();

    let component: ReactElement | null = null;

    const found = staticItems.find((item) => item.guid === field.staticItemGuid);

    switch (field.staticItemGuid) {
        case CaseFileStaticItems.CaseStatus.Guid:
            component = <CaseStatusControl />;
            break;
        case CaseFileStaticItems.CaseNumber.Guid:
            component = <CaseNumberControl />;
            break;
        case CaseFileStaticItems.LocalCounsel.Guid:
            component = <LocalCounselControl field={field} />;
            break;
        case CaseFileStaticItems.CaseManager.Guid:
            component = <CaseManagerControl isPrimary />;
            break;
        case CaseFileStaticItems.AlternateCaseManager.Guid:
            component = <CaseManagerControl isPrimary={false} />;
            break;
        case CaseFileStaticItems.UniqueCaseID.Guid:
            component = <UniqueCaseIDControl />;
            break;
        case CaseFileStaticItems.PackageSettlement.Guid:
            component = currentCase.packageSettlement ? <PackageSettlementControl /> : null;
            break;
        default:
            component = <div>Static Field Not Found!</div>;
    }

    if (!component) return null;

    return (
        <FieldControlWrapper
            component={component}
            displayAsCondensed={displayAsCondensed}
            labelText={found?.displayName || found?.name || ''}
            identifier={field.staticItemGuid}
            help={
                found?.helpText
                    ? { text: found?.helpText, title: found.displayName || '' }
                    : undefined
            }
        />
    );
}
