import {
    AssertionDescriptorDisplayCategoryEnum,
    DataScopesEnum,
    EntityTypes,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    UserRightsEnum,
} from '../../utilities/Constants';

import Authorization from '../../stores/Authorization';
import { CaseDeadlines } from './CaseDeadlines';
import { CaseDefenseCounsels } from './static-sections/CaseDefenseCounsels';
import { CaseDepositionListWrapper } from './static-sections/CaseDepositionListWrapper';
import { CaseExpertsList } from './static-sections/case-experts/CaseExpertsList';
import { IAssociatedOrganizationModel } from '../../interfaces/IOrganization';
import { ICaseExpertModel } from '../../interfaces/ICaseExpert';
import { IDepositionModel } from '../../interfaces/ICase';
import { IDisplaySectionModel } from '../../interfaces/IDisplaySectionModel';
import { INoteModel } from '../../interfaces/INote';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { NoteListWrapper } from './static-sections/NoteListWrapper';
import { OfficialCaseDocuments } from './OfficialCaseDocuments';
import { WorkProductWrapper } from '../work-product/WorkProductWrapper';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useCaseFileContext } from '../../contexts/CaseFileContext';

interface IStaticSectionRendererProps {
    dataScopeId: number;
    isReadOnlyMode: boolean;
    section: IDisplaySectionModel;
    user: IUserModel;
    validation: IValidation;
    zoneDefenseCounsels: IAssociatedOrganizationModel[];
    zoneUsers: IUserModel[];
}

export function StaticSectionRenderer({
    dataScopeId,
    isReadOnlyMode,
    section,
    user,
    validation,
    zoneDefenseCounsels,
    zoneUsers,
}: IStaticSectionRendererProps) {
    const {
        currentCase,
        currentNote,
        setCurrentCase,
        setCurrentCaseExpert,
        setCurrentNote,
        setOriginalCase,
        setForceNoteEditorInReadOnlyMode,
    } = useCaseFileContext();

    const handleAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.CaseNote },
            status: { id: NoteStatusTypes.Open },
            caseGuid: currentCase.guid!,
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        setCurrentNote(currentNote);
        setForceNoteEditorInReadOnlyMode(false);
    };

    const handleViewEditNote = (noteGuidToEdit: string, readOnly?: boolean) => {
        if (!currentCase.notes) return;

        const currentNote = currentCase.notes.find((x) => x.guid == noteGuidToEdit);

        if (currentNote) {
            setCurrentNote(currentNote);
            setForceNoteEditorInReadOnlyMode(readOnly ?? false);
        }
    };

    const handleNoteListChanges = (notes: INoteModel[]) => {
        const caseCopy = cloneDeep(currentCase);
        caseCopy.notes = notes;

        setOriginalCase(caseCopy);
        setCurrentCase(caseCopy);
    };

    const handleDepositionsChange = (depositions: IDepositionModel[]) => {
        setOriginalCase((prev) => ({ ...prev, depositions }));
        setCurrentCase((prev) => ({ ...prev, depositions }));
    };

    const handleCaseExpertsChange = useCallback((caseExperts: ICaseExpertModel[]) => {
        const caseCopy = cloneDeep(currentCase);
        caseCopy.caseExperts = caseExperts;

        setOriginalCase(caseCopy);
        setCurrentCase(caseCopy);
    }, []);

    switch (section.id) {
        case AssertionDescriptorDisplayCategoryEnum.CaseNotes.Value:
            return (
                <div style={{ marginLeft: -35 }}>
                    <NoteListWrapper
                        entityType={EntityTypes.Case}
                        user={user}
                        notes={currentCase.notes ?? []}
                        allowView={Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, user)}
                        allowEdit={
                            !isReadOnlyMode &&
                            Authorization.userHasRight(UserRightsEnum.EditCaseNote, user) &&
                            (dataScopeId !== DataScopesEnum.LocalBasic.Value ||
                                currentNote?.allowLocalEdit === true)
                        }
                        allowDelete={
                            !isReadOnlyMode &&
                            Authorization.userHasRight(UserRightsEnum.DeleteCaseNote, user)
                        }
                        allowAddNew={
                            !isReadOnlyMode &&
                            Authorization.userHasRight(UserRightsEnum.AddCaseNote, user)
                        }
                        onAddNote={handleAddNote}
                        onViewEditNote={handleViewEditNote}
                        onRefreshParent={handleNoteListChanges}
                    />
                </div>
            );

        case AssertionDescriptorDisplayCategoryEnum.OfficialCaseDocuments.Value:
            return <OfficialCaseDocuments user={user} case={currentCase} />;

        case AssertionDescriptorDisplayCategoryEnum.WorkProduct.Value:
            return (
                <WorkProductWrapper
                    user={user}
                    entityGuid={currentCase.guid!}
                    entityTypeId={EntityTypes.Case}
                    documents={currentCase.documents}
                    parentEntityJson={JSON.stringify(currentCase)}
                    parentEntityRedirectUrl={LocalRoutes.CaseFile.replace(
                        ':guid',
                        currentCase.guid!
                    )}
                    authorizedToEdit={Authorization.userHasRight(UserRightsEnum.EditCaseFile, user)}
                />
            );

        case AssertionDescriptorDisplayCategoryEnum.Deadlines.Value:
            return (
                <CaseDeadlines
                    user={user}
                    caseDeadlines={currentCase.caseDeadlines || []}
                    caseGuid={currentCase.guid!}
                />
            );

        case AssertionDescriptorDisplayCategoryEnum.DefenseCounsel.Value:
            return (
                <CaseDefenseCounsels
                    user={user}
                    allDefenseCounsels={zoneDefenseCounsels}
                    caseLocalCounsels={currentCase.defenseCounsels}
                    readOnlyMode={isReadOnlyMode || !currentCase.guid}
                    validation={validation}
                    setCurrentCase={setCurrentCase}
                />
            );

        case AssertionDescriptorDisplayCategoryEnum.Deposition.Value: {
            return (
                <>
                    {Authorization.userHasRight(UserRightsEnum.ViewDeposition, user) && (
                        <CaseDepositionListWrapper
                            user={user}
                            caseGuid={currentCase.guid}
                            currentCase={currentCase}
                            usersInZone={zoneUsers}
                            allDefenseCounselsForZone={zoneDefenseCounsels}
                            depositions={currentCase.depositions ?? []}
                            onDepositionsChange={handleDepositionsChange}
                            readonly={isReadOnlyMode}
                        />
                    )}
                </>
            );
        }

        case AssertionDescriptorDisplayCategoryEnum.CaseExperts.Value:
            return (
                <CaseExpertsList
                    user={user}
                    caseExperts={currentCase.caseExperts ?? []}
                    caseGuid={currentCase.guid}
                    refreshParent={handleCaseExpertsChange}
                    onSetCurrentCaseExpert={(caseExpert) => setCurrentCaseExpert(caseExpert)}
                    readonly={isReadOnlyMode}
                />
            );
        default:
            return null;
    }
}
