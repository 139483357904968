import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export function getQueryKey(entityTypeId: number, entityGuid: string | undefined) {
    return ['get-is-entity-lease-available', { entityTypeId, entityGuid }];
}

export const useGetIsEntityLeaseAvailable = (
    entityTypeId: number,
    entityGuid: string | undefined,
    enabled = true
) => {
    return useQuery({
        queryKey: getQueryKey(entityTypeId, entityGuid),
        queryFn: async () => {
            if (!entityGuid) return;
            const response = await _apiClient.getIsEntityLeaseAvailable(entityTypeId, entityGuid);

            if (response.httpResponse.status === 204) {
                return null;
            } else if (response.httpResponse.status === 500) {
                throw new Error('Failed to check availability of entity lease.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }
            return response.payload;
        },
        enabled,
    });
};
