import * as React from 'react';

import Common from '../../stores/Common';
import { IReportCaseDetailModel } from '../../interfaces/Report/IReportCaseDetailModel';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../shared/SortableHeader';

interface IReportCorpRepRequestListProps {
    data: IReportCaseDetailModel[];
}

interface IReportCorpRepRequestListState {
    data: IReportCaseDetailModel[];
}

export class ReportCorpRepRequestList extends React.Component<
    IReportCorpRepRequestListProps,
    IReportCorpRepRequestListState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }
    }

    componentDidUpdate(prevProps: IReportCorpRepRequestListProps) {
        if (prevProps.data != this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }
    }

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let data = this.state.data;
        if (key.includes('date')) {
            data = data.sort(Sort.compareDate(key, subKey, order));
        } else {
            data = data.sort(Sort.compareValues(key, subKey, order, subGrandKey));
        }

        this.setState({ data: data });
    };

    render() {
        return (
            <table className="table table-sm">
                <thead>
                    <tr style={{ verticalAlign: 'middle' }}>
                        <SortableHeader
                            headerText="Case Name"
                            sortKey="caseName"
                            onSort={this.sortData}
                            thClassName="col-sm-2"
                            noWrap
                        />
                        <SortableHeader
                            headerText="SQ Score"
                            sortKey="qScore"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                            noWrap
                        />
                        <SortableHeader
                            headerText="Jurisdiction"
                            sortKey="jurisdiction"
                            onSort={this.sortData}
                            thClassName="col-sm-2"
                        />
                        <SortableHeader
                            headerText="Plaintiffs Firm"
                            sortKey="plaintiffsFirm"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                            noWrap
                        />
                        <SortableHeader
                            headerText="Disease"
                            sortKey="diagnosis"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                        <SortableHeader
                            headerText="Projected Resolution Date"
                            sortKey="projectedResolutionDate"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                        <SortableHeader
                            headerText="Projected Settlement"
                            sortKey="projectedSettlement"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                        <SortableHeader
                            headerText="Date Received"
                            sortKey="corporateRepDemandReceivedDate"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                            noWrap
                        />
                        <SortableHeader
                            headerComponent={
                                <div>
                                    <div style={{ whiteSpace: 'nowrap' }}>Defense Date</div>
                                    <div>Proposed</div>
                                </div>
                            }
                            sortKey="defenseDateProposed"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                        <SortableHeader
                            headerText="Deadline"
                            sortKey="deadlineForCorpRepDepoDate"
                            onSort={this.sortData}
                            thClassName="col-sm-1"
                        />
                    </tr>
                </thead>
                <tbody>
                    {this.state.data && this.state.data.length > 0
                        ? this.state.data.map((item: IReportCaseDetailModel, i: number) => {
                              return (
                                  <tr key={i}>
                                      <td className="col-sm-2">
                                          <Link
                                              target="_blank"
                                              to={LocalRoutes.CaseFile.replace(
                                                  ':guid',
                                                  item.caseGuid
                                              )}
                                          >
                                              {item.caseName}
                                          </Link>
                                      </td>
                                      <td className="col-sm-1">{item.qScore}</td>
                                      <td className="col-sm-2">{item.jurisdiction}</td>
                                      <td className="col-sm-1">{item.plaintiffsFirm}</td>
                                      <td className="col-sm-1">{item.diagnosis}</td>
                                      <td className="col-sm-1">
                                          {Common.dateFormat(item.projectedResolutionDate)}
                                      </td>
                                      <td className="col-sm-1">
                                          {item.projectedSettlement != undefined
                                              ? Common.formatCurrency(
                                                    item.projectedSettlement.toString()
                                                )
                                              : ''}
                                      </td>
                                      <td className="col-sm-1">
                                          {Common.dateFormat(item.corporateRepDemandReceivedDate)}
                                      </td>
                                      <td className="col-sm-1">
                                          {Common.dateFormat(item.defenseDateProposed)}
                                      </td>
                                      <td className="col-sm-1">
                                          {Common.dateFormat(item.deadlineForCorpRepDepoDate)}
                                      </td>
                                  </tr>
                              );
                          })
                        : null}
                </tbody>
            </table>
        );
    }
}
