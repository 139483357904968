import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Navigate } from 'react-router';
import { CheckmateDialog } from '../../components/shared/dialog';
import { IRecoverAccount } from '../../interfaces/IAccount';
import { IDialogModel } from '../../interfaces/IDialog';
import { IValidation } from '../../interfaces/IError';
import CheckmateApiClient from '../../services/apiClient';
import ValidateUtils from '../../shared/validations';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { LocalRoutes } from '../../utilities/LocalRoutes';

let _apiClient = new CheckmateApiClient();

interface IRecoverAccountState {
    redirect: boolean;
    redirectTo: string;
    recoverAccount: IRecoverAccount;
    validation: IValidation;
    dialog: IDialogModel;
}

export default class RecoverAccount extends React.Component<any, IRecoverAccountState> {
    constructor(props: any) {
        super(props);

        this.state = {
            redirect: false,
            redirectTo: LocalRoutes.Login,
            recoverAccount: {},
            validation: {},
            dialog: { isShowingModal: false },
        };
    }

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const value = event.target.value;

        this.setState({
            recoverAccount: { email: value },
            validation: {},
            dialog: { isShowingModal: false },
        });
    };

    handleClose = () => {
        this.setState({ redirect: true });
    };

    handleSuccessMessageClose = () => {
        this.setState({
            redirect: true,
            redirectTo: '/',
        });
    };

    handleSendRecoveryEmail = async () => {
        var response = await _apiClient.recoverAccount(this.state.recoverAccount);
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
        } else {
            this.setState({
                dialog: {
                    isShowingModal: true,
                    title: '',
                    body: DisplayMessages.RecoverAccountEmailSent,
                    cancelText: 'OK',
                },
            });
        }
    };

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirectTo} />;
        }

        if (this.state.dialog.isShowingModal) {
            return (
                <CheckmateDialog
                    isShowingModal={this.state.dialog.isShowingModal}
                    title={this.state.dialog.title}
                    body={this.state.dialog.body}
                    handleClose={this.handleSuccessMessageClose}
                    cancelText={this.state.dialog.cancelText}
                    dialogClassName="recover-account-success-dialog"
                    closeButtonClassName="btn btn-black float-end"
                />
            );
        }

        return (
            <Modal
                centered
                show={true}
                dialogClassName="recover-account-dialog centered-modal"
                onHide={this.handleClose}
                backdrop={false}
            >
                <Modal.Header>
                    <Modal.Title>Recover Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{DisplayMessages.RecoverAccountSubtitle}</p>
                    <div>
                        <span className="text-danger">{this.state.validation.model}</span>
                        <div className="form-group">
                            <label className="control-label" htmlFor="email">
                                Email*
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                name="email"
                                value={this.state.recoverAccount.email || ''}
                                onChange={this.changeValue}
                            />
                            <span className="text-danger">{this.state.validation.email}</span>
                        </div>
                    </div>
                    <div className="recover-account-btn-div">
                        <button
                            className="btn btn-black full-width mt-3"
                            onClick={this.handleSendRecoveryEmail}
                        >
                            Send Account Recovery Email
                        </button>
                        <button
                            className="btn btn-default float-end mt-2 me-0"
                            onClick={this.handleClose}
                        >
                            Cancel
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
