import { Accordion } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { AccordionWrapper } from './AccordionWrapper';
import { CaseDisplayCategoryEnum } from '../../utilities/Constants';
import { CaseFileSection } from './CaseFileSection';
import { IDisplaySectionModel } from '../../interfaces/IDisplaySectionModel';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { useCaseFileContext } from '../../contexts/CaseFileContext';
import { useState } from 'react';

const getSectionIsTopLevel = (section: IDisplaySectionModel) => {
    return section.level === 1;
};

export interface ICaseFileSectionsProps {
    sections: IDisplaySectionModel[];
    user: IUserModel;
    validation: IValidation;
}

export function CaseFileSections({ sections, user, validation }: ICaseFileSectionsProps) {
    const { currentCase, isReadOnlyMode } = useCaseFileContext();

    const isNewCase = !currentCase.guid;
    const [activeKey, setActiveKey] = useState<AccordionEventKey>(['-1']);

    const handleAccordionSelectChange = (key: string, section: IDisplaySectionModel) => {
        if (!getSectionIsTopLevel(section)) return;

        // convoluted logic here because AccordionEventKey can be a single value or an Array
        if (!key) {
            setActiveKey(['-1']);
        } else if (Array.isArray(activeKey)) {
            if (activeKey.some((ak) => ak === key)) {
                setActiveKey((prevKeys) => {
                    if (Array.isArray(prevKeys)) return prevKeys?.filter((pk) => pk !== key);
                    return prevKeys;
                });
            } else {
                setActiveKey([...activeKey, key]);
            }
        } else {
            setActiveKey(['-1', key]);
        }
    };

    const getSectionIsOpen = (idx: string) => {
        if (Array.isArray(activeKey)) {
            return activeKey?.some((key) => key === idx);
        } else {
            return activeKey === idx;
        }
    };

    return (
        <Accordion activeKey={activeKey} flush alwaysOpen>
            {sections.map((section, idx) => {
                const isFirstSection = idx === 0;
                const isTopLevel = getSectionIsTopLevel(section);

                const nonExpandableSectionsOnCreate = [
                    CaseDisplayCategoryEnum.Deposition.Value,
                    CaseDisplayCategoryEnum.CaseNotes.Value,
                    CaseDisplayCategoryEnum.CaseExpert.Value,
                ].includes(section.id);

                const isExpandable =
                    isFirstSection ||
                    (!isNewCase && isTopLevel) ||
                    (isNewCase && isTopLevel && !nonExpandableSectionsOnCreate);

                const isOpen = isExpandable && getSectionIsOpen(idx.toString());
                const isLastSection = idx === section.sections?.length - 1;

                return (
                    <AccordionWrapper
                        key={idx}
                        accordionIndex={idx}
                        className={getSectionIsTopLevel(section) ? 'top-level' : ''}
                        isOpen={isOpen}
                        onAccordionSelectChange={handleAccordionSelectChange}
                        shouldWrap={true}
                        section={section}
                        isExpandable={isExpandable}
                        isTopLevel={isTopLevel}
                        isLastSection={isLastSection}
                    >
                        <CaseFileSection
                            key={idx}
                            displayAsCondensed={true}
                            numberOfColumns={2}
                            section={section}
                            user={user}
                            validation={validation}
                        />
                    </AccordionWrapper>
                );
            })}
        </Accordion>
    );
}
