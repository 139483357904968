import * as React from 'react';

import { Button, Modal } from 'react-bootstrap';

import { ApplicationHelpType } from '../../utilities/Constants';

interface IHelpProps {
    type: ApplicationHelpType;
    title: string;
    helpText: string;
    additionalStyleClassNames?: string;
}

interface IHelpState {
    showDialog: boolean;
}

export class Help extends React.Component<IHelpProps, IHelpState> {
    constructor(props: IHelpProps) {
        super(props);

        this.state = { showDialog: false };
    }

    getClassName() {
        let className = '';
        switch (this.props.type) {
            case ApplicationHelpType.Info:
                className = 'fa-info-circle';
                break;
            case ApplicationHelpType.Check:
                className = 'fa-check-circle';
                break;
            default:
                className = '';
        }

        if (className) className = 'fal fa-lg ' + className + ' color-gray ';

        return className;
    }

    render() {
        return (
            <span>
                <button
                    className={'mx-2 btn-no-bg ' + (this.props.additionalStyleClassNames || '')}
                    onClick={(event: React.FormEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        this.setState({ showDialog: true });
                    }}
                >
                    <i className={this.getClassName()} />
                </button>
                <Modal
                    centered
                    show={this.state.showDialog}
                    onHide={() => this.setState({ showDialog: false })}
                    backdrop={false}
                >
                    <Modal.Header>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-2">
                        {this.props.helpText
                            .replace(/\\n/gi, '\n')
                            .split('\n')
                            .map((item, key) => {
                                return (
                                    <span key={key}>
                                        {item}
                                        <br />
                                    </span>
                                );
                            })}
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button
                            variant="dark"
                            onClick={(event: React.FormEvent<HTMLButtonElement>) => {
                                event.preventDefault();
                                this.setState({ showDialog: false });
                            }}
                        >
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </span>
        );
    }
}
