import {
    AssertionDescriptorValueSourceTypeEnum,
    AssertionDescriptors,
    CalculatedAgeAssertionDescriptorValues,
    CaseAssertionLevelEnum,
    CaseFileStaticItems,
    CaseStatusTypesEnum,
    CaseSubStatusTypesEnum,
    CustomLogicAssertionDescriptors,
    DataScopesEnum,
    HelpKeys,
    PlaintiffsFirmDisplayMode,
    QueryFilterDatabaseFieldsHelpText,
    QueryTypes,
    ReportCaseDetailsLastColumn,
    UserRightsEnum,
} from './Constants';
import {
    IAssertionDescriptorModel,
    IAssertionDescriptorValueModel,
} from '../interfaces/IAssertionDescriptor';
import { ICaseAssertionModel, ICaseModel, ICaseTitleModel } from '../interfaces/ICase';
import { ILookupModel, IMultiSelectOptions, IStaticItemLookupModel } from '../interfaces/ILookup';

import Authorization from '../stores/Authorization';
import Common from '../stores/Common';
import { IOrganizationSettingModel } from '../interfaces/IOrganization';
import { IReportCaseDetailModel } from '../interfaces/Report/IReportCaseDetailModel';
import { IUserModel } from '../interfaces/IUser';

export default class CaseHelper {
    static isResolvedCase(currentCase: ICaseModel): boolean {
        return currentCase.caseStatus?.status?.id === CaseStatusTypesEnum.Resolved.Value;
    }

    static isTenderedAcceptedCase(currentCase: ICaseModel): boolean {
        return (
            currentCase.caseStatus?.status?.id === CaseStatusTypesEnum.Tendered.Value &&
            currentCase.caseStatus?.subStatus?.id === CaseSubStatusTypesEnum.Accepted.Value
        );
    }

    static isAltMonitoredCase(currentCase: ICaseModel): boolean {
        return (
            currentCase.caseStatus?.status?.id === CaseStatusTypesEnum.Alt.Value &&
            currentCase.caseStatus.subStatus?.id === CaseSubStatusTypesEnum.Monitored.Value
        );
    }

    static isAltCase(currentCase: ICaseModel): boolean {
        return currentCase.caseStatus?.status?.id === CaseStatusTypesEnum.Alt.Value;
    }

    static getAdditionalCaseAssertionFields = (
        caseItem: ICaseModel,
        assertionDescriptors: IAssertionDescriptorModel[]
    ): ICaseAssertionModel[] => {
        const additionalCaseAssertions: ICaseAssertionModel[] = [];
        if (!caseItem.caseAssertions) caseItem.caseAssertions = [];

        let adGuidsForAdditionalFields: string[] = [];
        if (CaseHelper.isResolvedCase(caseItem)) {
            adGuidsForAdditionalFields = [
                AssertionDescriptors.ResolutionAgreementDate.Guid,
                AssertionDescriptors.DismissalDate.Guid,
                AssertionDescriptors.SettlementAmount.Guid,
            ];
        } else if (CaseHelper.isTenderedAcceptedCase(caseItem)) {
            adGuidsForAdditionalFields = [AssertionDescriptors.TenderedAcceptedDate.Guid];
        } else if (CaseHelper.isAltCase(caseItem)) {
            adGuidsForAdditionalFields = [
                AssertionDescriptors.ResolutionAgreementDate.Guid,
                AssertionDescriptors.DismissalDate.Guid,
            ];
        }

        for (let i = 0; i < adGuidsForAdditionalFields.length; i++) {
            const caseAssertionMatch = caseItem.caseAssertions.find(
                (x) => x.assertionDescriptor?.guid?.toUpperCase() === adGuidsForAdditionalFields[i]
            );
            if (caseAssertionMatch) {
                additionalCaseAssertions.push(caseAssertionMatch);
            } else {
                const adMatch = assertionDescriptors.find(
                    (ad) => ad.guid?.toUpperCase() === adGuidsForAdditionalFields[i]
                );
                if (adMatch) {
                    additionalCaseAssertions.push({
                        assertionDescriptor: { ...adMatch },
                    });
                    caseItem.caseAssertions.push({
                        assertionDescriptor: { ...adMatch },
                    });
                }
            }
        }

        return additionalCaseAssertions;
    };

    static getDocumentedStatusCaseAssertion = (caseItem: ICaseModel) => {
        if (caseItem.caseAssertions) {
            const match = caseItem.caseAssertions.find(
                (x) =>
                    x.assertionDescriptor != undefined &&
                    x.assertionDescriptor!.name!.toUpperCase() ==
                        AssertionDescriptors.StatusDocumented.Name.toUpperCase()
            );
            return match;
        }
    };

    static getDocumentedStatusAssertionDescriptor = (
        assertionDescriptors: IAssertionDescriptorModel[]
    ) => {
        const match = assertionDescriptors.find(
            (x) => x.name!.toUpperCase() == AssertionDescriptors.StatusDocumented.Name.toUpperCase()
        );
        return match;
    };

    // AR - TO DO - Revisit logic with Lori when working on Threat Score stuff
    static getCaseAssertionClass = (
        caseAssertion: ICaseAssertionModel,
        user: IUserModel
    ): string => {
        if (!Authorization.userHasRight(UserRightsEnum.ViewThreatScore, user)) return 'square-none';
        if (caseAssertion.assertionDescriptorValue || caseAssertion.text) return 'square-none';
        return 'square-none';
    };

    static getCaseNotesHelpText = (organizationSettings: IOrganizationSettingModel[]) => {
        let helpText: ILookupModel[] = [];
        const match = organizationSettings.find((x) => x.settingName == HelpKeys.CaseNotes.Name);
        if (match) helpText = JSON.parse(match.settingValue);
        return helpText;
    };

    static getAssertionTextByName(caseItem: ICaseModel, assertionDescriptorName: string) {
        if (caseItem.caseAssertions) {
            const match = caseItem.caseAssertions.find(
                (x) =>
                    x.assertionDescriptor != undefined &&
                    x.assertionDescriptor!.name!.toUpperCase() ==
                        assertionDescriptorName.toUpperCase()
            );
            if (match) {
                return match.assertionDescriptorValue
                    ? match.assertionDescriptorValue.text
                    : match.text;
            }
        }
        return '';
    }

    static getAssertionText(caseItem: ICaseModel, assertionDescriptorGuid: string) {
        if (caseItem.caseAssertions) {
            const adMatches = caseItem.caseAssertions.filter(
                (x) =>
                    x.assertionDescriptor != undefined &&
                    x.assertionDescriptor!.guid!.toUpperCase() ===
                        assertionDescriptorGuid.toUpperCase()
            );
            if (adMatches.length > 0) {
                const text: string[] = [];
                const valueSourceTypeId =
                    adMatches[0].assertionDescriptor &&
                    adMatches[0].assertionDescriptor.valueSourceType
                        ? adMatches[0].assertionDescriptor.valueSourceType.id
                        : 0;
                for (let index = 0; index < adMatches.length; index++) {
                    const element = adMatches[index];
                    if (
                        valueSourceTypeId === AssertionDescriptorValueSourceTypeEnum.Selection.Value
                    )
                        text.push(
                            element.assertionDescriptorValue
                                ? element.assertionDescriptorValue.text || ''
                                : ''
                        );
                    else text.push(element.text || '');
                }

                return text.length > 0 ? (text.length === 1 ? text[0] : text.join(' | ')) : '';
            }
        }
        return '';
    }

    static getMultiSelectOptions = (ad: IAssertionDescriptorModel) => {
        const options: IMultiSelectOptions[] = [];
        ad.assertionDescriptorValues!.map((item: IAssertionDescriptorValueModel) => {
            return options.push({ label: item.text!, value: item.guid!, id: 0, guid: item.guid });
        });
        return options;
    };

    static getMultiSelectSelectedValues = (
        ad: IAssertionDescriptorModel,
        currentCase?: ICaseModel
    ) => {
        const selectedValues: IMultiSelectOptions[] = [];
        if (currentCase && currentCase.caseAssertions) {
            const match = currentCase.caseAssertions.filter(
                (x) =>
                    x.assertionDescriptorValue &&
                    x.assertionDescriptor!.guid == ad.guid &&
                    x.assertionDescriptorValue
            );
            for (let i = 0; i < match.length; i++) {
                const item: ICaseAssertionModel = match[i];
                if (item.assertionDescriptorValue)
                    selectedValues.push({
                        label: item.assertionDescriptorValue!.text!,
                        value: item.assertionDescriptorValue!.guid!,
                        id: 0,
                        guid: item.assertionDescriptorValue!.guid,
                    });
            }
        }
        return selectedValues;
    };

    static getMultiSelectSelectedValuesTitleText(selectedValues: IMultiSelectOptions[]) {
        let selectedValuesTitleText = '';
        if (selectedValues.length > 1) {
            selectedValues.map((item: IMultiSelectOptions) => {
                return (selectedValuesTitleText += item.label + '\n');
            });
        }

        return selectedValuesTitleText;
    }

    static getCalculatedAgeAssertionDescriptorValueGuid(age: number): string {
        if (age > 0) {
            const match = CalculatedAgeAssertionDescriptorValues.find(
                (x) => age >= x.From && age <= x.To
            );
            if (match) return match.Guid;
        }
        return '';
    }

    static RunCaseFileFieldsCustomLogicOBSOLETE(
        currentCase: ICaseModel,
        assertionDescriptorsLookupData?: IAssertionDescriptorModel[]
    ) {
        if (!currentCase.caseAssertions) currentCase.caseAssertions = [];

        //#region CASE ASSERTION LOOKUPS
        const livingDeceasedCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.LivingDeceased.Guid.toUpperCase()
        );
        const dobCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.DOB.Guid.toUpperCase()
        );
        const dateOfDeathCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.DateOfDeath.Guid.toUpperCase()
        );
        const calculatedAgeCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.CalculatedAgeRange.Guid.toUpperCase()
        );
        const exactAgeCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.CalculatedExactAge.Guid.toUpperCase()
        );
        const estimatedAgeCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.EstimatedAge.Guid.toUpperCase()
        );
        const smokingHistoryPacksPerDayCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.SmokingHistoryPacksPerDay.Guid.toUpperCase()
        );
        const smokingHistoryPackYearCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.SmokingHistoryPackYear.Guid.toUpperCase()
        );
        const smokingStartDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.SmokingStartDate.Guid.toUpperCase()
        );
        const smokingEndDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.SmokingEndDate.Guid.toUpperCase()
        );
        const isSmokerCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.IsSmoker.Guid.toUpperCase()
        );
        const msjStatusCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.MSJStatus.Guid.toUpperCase()
        );
        const msjFiledCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.MSJFiled.Guid.toUpperCase()
        );
        const msjGrantedDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.MSJGrantedDate.Guid.toUpperCase()
        );
        const pjStatusCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.PJStatus.Guid.toUpperCase()
        );
        const pjFiledCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.PJFiled.Guid.toUpperCase()
        );
        const pjGrantedDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.PJGrantedDate.Guid.toUpperCase()
        );
        const resolutionAgreementDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.ResolutionAgreementDate.Guid.toUpperCase()
        );
        const dismissalDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.DismissalDate.Guid.toUpperCase()
        );

        const demandForCorporateRepDepoCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.DemandForCorporateRepDepo.Guid.toUpperCase()
        );
        const corporateRepDepoReceivedDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.CorporateRepDemandReceivedDate.Guid.toUpperCase()
        );
        //#endregion

        //#region AGE AND LIVING/DECEASED

        let endDateForCalcAgeLiving: string = Common.currentDate();

        // If the case is a Resolved-Settled case, only User is Living, Only Calculate Age with End Date = Resolution Date. Not current Date.
        if (
            currentCase.caseStatus &&
            currentCase.caseStatus.status?.id === CaseStatusTypesEnum.Resolved.Value &&
            currentCase.caseStatus.subStatus?.id === CaseSubStatusTypesEnum.Settled.Value
        ) {
            if (
                resolutionAgreementDateCaseAssertionMatch &&
                resolutionAgreementDateCaseAssertionMatch.text &&
                Common.isValidDate(resolutionAgreementDateCaseAssertionMatch.text)
            )
                endDateForCalcAgeLiving = resolutionAgreementDateCaseAssertionMatch.text;
            else if (
                dismissalDateCaseAssertionMatch &&
                dismissalDateCaseAssertionMatch.text &&
                Common.isValidDate(dismissalDateCaseAssertionMatch.text)
            )
                endDateForCalcAgeLiving = dismissalDateCaseAssertionMatch.text;
        }

        if (calculatedAgeCaseAssertionMatch)
            calculatedAgeCaseAssertionMatch.assertionDescriptorValue = undefined;
        if (livingDeceasedCaseAssertionMatch && !livingDeceasedCaseAssertionMatch.userOverride)
            livingDeceasedCaseAssertionMatch.assertionDescriptorValue = undefined;

        // Auto Populate Plaintiff to Deceased if Date of Death exists
        if (
            dateOfDeathCaseAssertionMatch &&
            dateOfDeathCaseAssertionMatch.text &&
            Common.isValidDate(dateOfDeathCaseAssertionMatch.text)
        ) {
            if (livingDeceasedCaseAssertionMatch)
                // This is for case File Updates, since there is always a case Assertion Item for each AD even if user there is no value.
                livingDeceasedCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                    JSON.stringify(
                        livingDeceasedCaseAssertionMatch.assertionDescriptor!.assertionDescriptorValues!.filter(
                            (x) =>
                                x.guid!.toUpperCase() ===
                                AssertionDescriptors.LivingDeceased.DeceasedADVGuid.toUpperCase()
                        )[0]
                    )
                );
            else if (assertionDescriptorsLookupData) {
                // This is for Easy Update since we only pull valid case Assertions for the case. Hence if user never entered a value, that case Assertion will be NULL.
                const livingDeceasedAD = assertionDescriptorsLookupData.find(
                    (x) =>
                        x.guid != undefined &&
                        x.guid.toUpperCase() === AssertionDescriptors.LivingDeceased.Guid
                );
                if (livingDeceasedAD) {
                    currentCase.caseAssertions.push({
                        assertionDescriptor: JSON.parse(JSON.stringify(livingDeceasedAD)),
                        assertionDescriptorValue: JSON.parse(
                            JSON.stringify(
                                livingDeceasedAD.assertionDescriptorValues!.filter(
                                    (x) =>
                                        x.guid!.toUpperCase() ===
                                        AssertionDescriptors.LivingDeceased.DeceasedADVGuid.toUpperCase()
                                )[0]
                            )
                        ),
                    });
                }
            }
        }

        let calculatedAge = 0;
        let ageADVGuid = '';

        // Both Valid DOB & Date of Death entered by User. Hence Calculate Age
        const validDob = Common.isValidDate(dobCaseAssertionMatch?.text ?? '');
        const validDod = Common.isValidDate(dateOfDeathCaseAssertionMatch?.text ?? '');

        if (validDob && validDod) {
            calculatedAge = Common.dateDiffInYears(
                dateOfDeathCaseAssertionMatch?.text ?? '',
                dobCaseAssertionMatch?.text ?? ''
            );
            ageADVGuid = this.getCalculatedAgeAssertionDescriptorValueGuid(calculatedAge);
            if (ageADVGuid) {
                if (calculatedAgeCaseAssertionMatch) {
                    // CASE FILE UPDATE
                    calculatedAgeCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                        JSON.stringify(
                            calculatedAgeCaseAssertionMatch.assertionDescriptor!.assertionDescriptorValues!.find(
                                (x) => x.guid!.toUpperCase() === ageADVGuid.toUpperCase()
                            )
                        )
                    );
                } else if (assertionDescriptorsLookupData) {
                    // EASY UPDATE
                    const calcAgeAD = assertionDescriptorsLookupData.find(
                        (x) =>
                            x.guid?.toUpperCase() === AssertionDescriptors.CalculatedAgeRange.Guid
                    );
                    if (calcAgeAD) {
                        currentCase.caseAssertions.push({
                            assertionDescriptor: JSON.parse(JSON.stringify(calcAgeAD)),
                            assertionDescriptorValue: JSON.parse(
                                JSON.stringify(
                                    calcAgeAD.assertionDescriptorValues!.filter(
                                        (x) => x.guid!.toUpperCase() === ageADVGuid.toUpperCase()
                                    )[0]
                                )
                            ),
                        });
                    }
                }
            }
        }

        // valid DOB entered by User. No Date of Death
        else if (validDob && !validDod) {
            // Auto Populate Plaintiff to Living if User hasnt manually set to Deceased.
            if (livingDeceasedCaseAssertionMatch) {
                // CASE FILE UPDATE
                if (!livingDeceasedCaseAssertionMatch.userOverride)
                    livingDeceasedCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                        JSON.stringify(
                            livingDeceasedCaseAssertionMatch.assertionDescriptor!.assertionDescriptorValues!.filter(
                                (x) =>
                                    x.guid!.toUpperCase() ===
                                    AssertionDescriptors.LivingDeceased.LivingADVGuid.toUpperCase()
                            )[0]
                        )
                    );
                else if (
                    livingDeceasedCaseAssertionMatch.assertionDescriptorValue &&
                    livingDeceasedCaseAssertionMatch.assertionDescriptorValue.guid &&
                    livingDeceasedCaseAssertionMatch.assertionDescriptorValue.guid.toUpperCase() ===
                        AssertionDescriptors.LivingDeceased.DeceasedADVGuid.toUpperCase()
                ) {
                    endDateForCalcAgeLiving = ''; // Cannot Calculate Age because User set to Deceased but didnt add a Date of Death
                }
            } else if (assertionDescriptorsLookupData) {
                // EASY UPDATE
                const livingDeceasedAD = assertionDescriptorsLookupData.find(
                    (x) =>
                        x.guid != undefined &&
                        x.guid.toUpperCase() === AssertionDescriptors.LivingDeceased.Guid
                );
                if (livingDeceasedAD) {
                    currentCase.caseAssertions.push({
                        assertionDescriptor: JSON.parse(JSON.stringify(livingDeceasedAD)),
                        assertionDescriptorValue: JSON.parse(
                            JSON.stringify(
                                livingDeceasedAD.assertionDescriptorValues!.filter(
                                    (x) =>
                                        x.guid!.toUpperCase() ===
                                        AssertionDescriptors.LivingDeceased.LivingADVGuid.toUpperCase()
                                )[0]
                            )
                        ),
                    });
                }
            }

            // Calculate Age as If user has set Plaintiff to Living or User has not set Living or Deceased at all (System can auto calculate Plaintiff as Living if not touched by User)
            //if (!livingDeceasedCaseAssertionMatch
            //    || (!livingDeceasedCaseAssertionMatch.userOverride && !livingDeceasedCaseAssertionMatch.assertionDescriptorValue)
            //    || (livingDeceasedCaseAssertionMatch.assertionDescriptorValue && livingDeceasedCaseAssertionMatch.assertionDescriptorValue.guid && livingDeceasedCaseAssertionMatch.assertionDescriptorValue.guid.toUpperCase() === AssertionDescriptors.LivingDeceased.LivingADVGuid.toUpperCase())) {
            if (endDateForCalcAgeLiving)
                calculatedAge = Common.dateDiffInYears(
                    endDateForCalcAgeLiving,
                    dobCaseAssertionMatch?.text ?? ''
                );

            if (calculatedAge) {
                ageADVGuid = this.getCalculatedAgeAssertionDescriptorValueGuid(calculatedAge);
            }

            if (ageADVGuid) {
                if (calculatedAgeCaseAssertionMatch) {
                    // CASE FILE UPDATE
                    calculatedAgeCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                        JSON.stringify(
                            calculatedAgeCaseAssertionMatch.assertionDescriptor!.assertionDescriptorValues!.filter(
                                (x) => x.guid!.toUpperCase() === ageADVGuid.toUpperCase()
                            )[0]
                        )
                    );
                } else if (assertionDescriptorsLookupData) {
                    // EASY UPDATE
                    const calcAgeAD = assertionDescriptorsLookupData.find(
                        (x) =>
                            x.guid?.toUpperCase() === AssertionDescriptors.CalculatedAgeRange.Guid
                    );
                    if (calcAgeAD) {
                        currentCase.caseAssertions.push({
                            assertionDescriptor: JSON.parse(JSON.stringify(calcAgeAD)),
                            assertionDescriptorValue: JSON.parse(
                                JSON.stringify(
                                    calcAgeAD.assertionDescriptorValues!.filter(
                                        (x) => x.guid!.toUpperCase() === ageADVGuid.toUpperCase()
                                    )[0]
                                )
                            ),
                        });
                    }
                }
            }
            //}
        }

        if (validDob && validDod) {
            if (exactAgeCaseAssertionMatch) {
                // CASE FILE UPDATE
                exactAgeCaseAssertionMatch.text = calculatedAge.toString();
            } else if (assertionDescriptorsLookupData) {
                // EASY UPDATE
                const exactAgeAD = assertionDescriptorsLookupData.find(
                    (x) =>
                        x.guid != undefined &&
                        x.guid.toUpperCase() === AssertionDescriptors.CalculatedExactAge.Guid
                );
                if (exactAgeAD) {
                    currentCase.caseAssertions.push({
                        assertionDescriptor: JSON.parse(JSON.stringify(exactAgeAD)),
                        text: calculatedAge.toString(),
                    });
                }
            }

            if (estimatedAgeCaseAssertionMatch) {
                estimatedAgeCaseAssertionMatch.text = undefined;
                estimatedAgeCaseAssertionMatch.assertionDescriptorValue = undefined;
            }
        } else if (exactAgeCaseAssertionMatch) {
            exactAgeCaseAssertionMatch.text = undefined;
        }
        //#endregion

        //#region SMOKING STUFF
        const isSmoker =
            (smokingStartDateCaseAssertionMatch &&
                smokingStartDateCaseAssertionMatch.text &&
                Common.isValidDate(smokingStartDateCaseAssertionMatch.text)) ||
            (smokingEndDateCaseAssertionMatch &&
                smokingEndDateCaseAssertionMatch.text &&
                Common.isValidDate(smokingEndDateCaseAssertionMatch.text)) ||
            (smokingHistoryPacksPerDayCaseAssertionMatch &&
                smokingHistoryPacksPerDayCaseAssertionMatch.assertionDescriptorValue) ||
            (smokingHistoryPackYearCaseAssertionMatch &&
                smokingHistoryPackYearCaseAssertionMatch.assertionDescriptorValue);

        if (isSmoker) {
            // todo: rethink the assertionDescriptor/assertionDescriptorValues
            if (
                isSmokerCaseAssertionMatch &&
                (isSmokerCaseAssertionMatch.assertionDescriptor?.assertionDescriptorValues
                    ?.length ?? 0) > 0
            ) {
                const isSmokerYesADV =
                    isSmokerCaseAssertionMatch.assertionDescriptor!.assertionDescriptorValues!.find(
                        (x) =>
                            x.guid != undefined &&
                            x.guid.toUpperCase() ===
                                AssertionDescriptors.IsSmoker.YesADVGuid.toUpperCase()
                    );

                isSmokerCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                    JSON.stringify(isSmokerYesADV)
                );

                isSmokerCaseAssertionMatch.userOverride = false;
            } else if (assertionDescriptorsLookupData) {
                const ad = assertionDescriptorsLookupData.find(
                    (x) =>
                        x.guid != undefined &&
                        x.guid.toUpperCase() === AssertionDescriptors.IsSmoker.Guid
                );
                if (ad) {
                    const isSmokerYesADV = ad.assertionDescriptorValues!.find(
                        (x) =>
                            x.guid != undefined &&
                            x.guid.toUpperCase() ===
                                AssertionDescriptors.IsSmoker.YesADVGuid.toUpperCase()
                    );
                    currentCase.caseAssertions.push({
                        assertionDescriptor: JSON.parse(JSON.stringify(ad)),
                        assertionDescriptorValue: JSON.parse(JSON.stringify(isSmokerYesADV)),
                    });
                }
            }
        } else if (isSmokerCaseAssertionMatch && !isSmokerCaseAssertionMatch.userOverride) {
            isSmokerCaseAssertionMatch.assertionDescriptorValue = undefined;
        }

        if (
            smokingHistoryPackYearCaseAssertionMatch &&
            !smokingHistoryPackYearCaseAssertionMatch.userOverride
        ) {
            smokingHistoryPackYearCaseAssertionMatch.text = '';
            if (
                smokingHistoryPacksPerDayCaseAssertionMatch &&
                smokingHistoryPacksPerDayCaseAssertionMatch.assertionDescriptorValue &&
                smokingHistoryPacksPerDayCaseAssertionMatch.assertionDescriptorValue.numericValue
            ) {
                const packsPerDay =
                    smokingHistoryPacksPerDayCaseAssertionMatch.assertionDescriptorValue
                        .numericValue;
                let smokingDays = 0;
                if (
                    smokingStartDateCaseAssertionMatch &&
                    smokingStartDateCaseAssertionMatch.text &&
                    Common.isValidDate(smokingStartDateCaseAssertionMatch.text)
                ) {
                    const smokingEndDate =
                        smokingEndDateCaseAssertionMatch &&
                        smokingEndDateCaseAssertionMatch.text &&
                        Common.isValidDate(smokingEndDateCaseAssertionMatch.text)
                            ? smokingEndDateCaseAssertionMatch.text
                            : Common.currentDate();
                    smokingDays = Common.dateDiffInDays(
                        smokingEndDate,
                        smokingStartDateCaseAssertionMatch.text
                    );
                }

                if (smokingDays > 0) {
                    const packYears = (packsPerDay * (smokingDays / 365)).toFixed(1);
                    smokingHistoryPackYearCaseAssertionMatch.text = packYears.toString();
                }
            }
        }
        //#endregion

        //#region MSJ/PJ STUFF
        const defaultMSJStatusToNoRuling: boolean =
            (msjFiledCaseAssertionMatch &&
                msjFiledCaseAssertionMatch.assertionDescriptorValue &&
                msjFiledCaseAssertionMatch.assertionDescriptorValue.guid &&
                msjFiledCaseAssertionMatch.assertionDescriptorValue.guid.toUpperCase() ===
                    AssertionDescriptors.MSJFiled.AvailableAndFiledGuid) ||
            (msjGrantedDateCaseAssertionMatch &&
                msjGrantedDateCaseAssertionMatch.text &&
                Common.isValidDate(msjGrantedDateCaseAssertionMatch.text) &&
                Common.dateDiff(msjGrantedDateCaseAssertionMatch.text, Common.currentDate()) < 0)
                ? true
                : false;

        if (!msjStatusCaseAssertionMatch) {
            if (defaultMSJStatusToNoRuling && assertionDescriptorsLookupData) {
                const msjStatusADMatch = assertionDescriptorsLookupData.find(
                    (x) => x.guid!.toUpperCase() == AssertionDescriptors.MSJStatus.Guid
                );
                if (msjStatusADMatch && msjStatusADMatch.assertionDescriptorValues) {
                    const noRulingMSJADV = msjStatusADMatch.assertionDescriptorValues.find(
                        (x) => x.guid!.toUpperCase() === AssertionDescriptors.MSJStatus.NoRulingGuid
                    );
                    if (noRulingMSJADV)
                        currentCase.caseAssertions.push({
                            assertionDescriptor: JSON.parse(JSON.stringify(msjStatusADMatch)),
                            assertionDescriptorValue: JSON.parse(JSON.stringify(noRulingMSJADV)),
                        });
                }
            }
        } else if (!msjStatusCaseAssertionMatch.userOverride) {
            if (defaultMSJStatusToNoRuling) {
                if (
                    msjStatusCaseAssertionMatch.assertionDescriptor &&
                    msjStatusCaseAssertionMatch.assertionDescriptor.assertionDescriptorValues
                ) {
                    const noRulingMSJADV =
                        msjStatusCaseAssertionMatch.assertionDescriptor.assertionDescriptorValues.find(
                            (x) =>
                                x.guid!.toUpperCase() ===
                                AssertionDescriptors.MSJStatus.NoRulingGuid
                        );
                    if (noRulingMSJADV)
                        msjStatusCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                            JSON.stringify(noRulingMSJADV)
                        );
                }
            } else msjStatusCaseAssertionMatch.assertionDescriptorValue = undefined;
        }

        const defaultPJStatusToNoRuling: boolean =
            (pjFiledCaseAssertionMatch &&
                pjFiledCaseAssertionMatch.assertionDescriptorValue &&
                pjFiledCaseAssertionMatch.assertionDescriptorValue.guid &&
                pjFiledCaseAssertionMatch.assertionDescriptorValue.guid.toUpperCase() ===
                    AssertionDescriptors.PJFiled.AvailableAndFiledGuid) ||
            (pjGrantedDateCaseAssertionMatch &&
                pjGrantedDateCaseAssertionMatch.text &&
                Common.isValidDate(pjGrantedDateCaseAssertionMatch.text) &&
                Common.dateDiff(pjGrantedDateCaseAssertionMatch.text, Common.currentDate()) < 0)
                ? true
                : false;

        if (!pjStatusCaseAssertionMatch) {
            if (defaultPJStatusToNoRuling && assertionDescriptorsLookupData) {
                const pjStatusADMatch = assertionDescriptorsLookupData.find(
                    (x) => x.guid!.toUpperCase() == AssertionDescriptors.PJStatus.Guid
                );
                if (pjStatusADMatch && pjStatusADMatch.assertionDescriptorValues) {
                    const noRulingPJADV = pjStatusADMatch.assertionDescriptorValues.find(
                        (x) => x.guid!.toUpperCase() === AssertionDescriptors.PJStatus.NoRulingGuid
                    );
                    if (noRulingPJADV)
                        currentCase.caseAssertions.push({
                            assertionDescriptor: JSON.parse(JSON.stringify(pjStatusADMatch)),
                            assertionDescriptorValue: JSON.parse(JSON.stringify(noRulingPJADV)),
                        });
                }
            }
        } else if (!pjStatusCaseAssertionMatch.userOverride) {
            if (defaultPJStatusToNoRuling) {
                if (
                    pjStatusCaseAssertionMatch.assertionDescriptor &&
                    pjStatusCaseAssertionMatch.assertionDescriptor.assertionDescriptorValues
                ) {
                    const noRulingPJADV =
                        pjStatusCaseAssertionMatch.assertionDescriptor.assertionDescriptorValues.find(
                            (x) =>
                                x.guid!.toUpperCase() === AssertionDescriptors.PJStatus.NoRulingGuid
                        );
                    if (noRulingPJADV)
                        pjStatusCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                            JSON.stringify(noRulingPJADV)
                        );
                }
            } else pjStatusCaseAssertionMatch.assertionDescriptorValue = undefined;
        }

        //#endregion

        //#region DEMAND
        if (
            corporateRepDepoReceivedDateCaseAssertionMatch &&
            corporateRepDepoReceivedDateCaseAssertionMatch.text &&
            Common.isValidDate(corporateRepDepoReceivedDateCaseAssertionMatch.text)
        ) {
            // User added Corporate Rep Depo Received Date. Hence ALWAYS Default Demand for Corporate Rep Depo to TRUE
            if (!demandForCorporateRepDepoCaseAssertionMatch) {
                if (assertionDescriptorsLookupData) {
                    const demandForCorporateRepDepoADMatch = assertionDescriptorsLookupData.find(
                        (x) =>
                            x.guid!.toUpperCase() ==
                            AssertionDescriptors.DemandForCorporateRepDepo.Guid
                    );
                    if (demandForCorporateRepDepoADMatch) {
                        currentCase.caseAssertions.push({
                            assertionDescriptor: JSON.parse(
                                JSON.stringify(demandForCorporateRepDepoADMatch)
                            ),
                            text: 'true',
                        });
                    }
                }
            } else {
                demandForCorporateRepDepoCaseAssertionMatch.text = 'true';
                demandForCorporateRepDepoCaseAssertionMatch.userOverride = false;
            }
        } else {
            if (
                demandForCorporateRepDepoCaseAssertionMatch &&
                !demandForCorporateRepDepoCaseAssertionMatch.userOverride
            )
                demandForCorporateRepDepoCaseAssertionMatch.text = undefined;
        }
        //#endregion
    }

    static setStyleAsSystemAutoPopulated(
        caseAssertion: ICaseAssertionModel,
        currentCase: ICaseModel
    ): boolean {
        if (
            !caseAssertion ||
            !caseAssertion.assertionDescriptor ||
            !caseAssertion.assertionDescriptor?.guid
        )
            return false;

        if (this.isFieldReadOnly(caseAssertion.assertionDescriptor?.guid || '', currentCase))
            return true;

        if (
            CustomLogicAssertionDescriptors.find(
                (x) =>
                    x.Guid.toUpperCase() ===
                        caseAssertion.assertionDescriptor?.guid?.toUpperCase() && x.AutoCalculated
            ) &&
            !caseAssertion.userOverride &&
            (caseAssertion.assertionDescriptorValue || caseAssertion.text)
        ) {
            return true;
        }

        return false;
    }

    static isFieldReadOnly(assertionDescriptorGuid: string, currentCase: ICaseModel): boolean {
        if (
            CustomLogicAssertionDescriptors.filter(
                (x) => x.Guid.toUpperCase() == assertionDescriptorGuid.toUpperCase() && x.ReadOnly
            ).length > 0
        )
            return true;

        switch (assertionDescriptorGuid.toUpperCase()) {
            case AssertionDescriptors.LivingDeceased.Guid.toUpperCase():
                if (currentCase.caseAssertions) {
                    const dateOfDeathAssertion = currentCase.caseAssertions.find(
                        (x) =>
                            x.assertionDescriptor != undefined &&
                            x.assertionDescriptor.guid != undefined &&
                            x.assertionDescriptor.guid.toUpperCase() ==
                                AssertionDescriptors.DateOfDeath.Guid.toUpperCase()
                    );
                    if (
                        dateOfDeathAssertion &&
                        dateOfDeathAssertion.text &&
                        Common.isValidDate(dateOfDeathAssertion.text)
                    )
                        return true;
                }
                break;
            case AssertionDescriptors.EstimatedAge.Guid.toUpperCase():
                if (currentCase.caseAssertions) {
                    const dobAssertion = currentCase.caseAssertions.find(
                        (x) =>
                            x.assertionDescriptor != undefined &&
                            x.assertionDescriptor.guid != undefined &&
                            x.assertionDescriptor.guid.toUpperCase() ==
                                AssertionDescriptors.DOB.Guid.toUpperCase()
                    );
                    const dateOfDeathAssertion = currentCase.caseAssertions.find(
                        (x) =>
                            x.assertionDescriptor!.guid!.toUpperCase() ==
                            AssertionDescriptors.DateOfDeath.Guid.toUpperCase()
                    );
                    if (
                        dobAssertion &&
                        dateOfDeathAssertion &&
                        dobAssertion.text &&
                        dateOfDeathAssertion.text &&
                        Common.isValidDate(dobAssertion.text) &&
                        Common.isValidDate(dateOfDeathAssertion.text)
                    )
                        return true;

                    if (
                        dobAssertion &&
                        dobAssertion.text &&
                        Common.isValidDate(dobAssertion.text)
                    ) {
                        const livingDeceasedCaseAssertion = currentCase.caseAssertions.find(
                            (x) =>
                                x.assertionDescriptor != undefined &&
                                x.assertionDescriptor.guid != undefined &&
                                x.assertionDescriptor.guid.toUpperCase() ==
                                    AssertionDescriptors.LivingDeceased.Guid.toUpperCase()
                        );
                        if (
                            livingDeceasedCaseAssertion &&
                            livingDeceasedCaseAssertion.assertionDescriptorValue &&
                            livingDeceasedCaseAssertion.assertionDescriptorValue.guid &&
                            (!livingDeceasedCaseAssertion.userOverride ||
                                livingDeceasedCaseAssertion.assertionDescriptorValue.guid.toUpperCase() ===
                                    AssertionDescriptors.LivingDeceased.LivingADVGuid)
                        ) {
                            // If living/deceased value exists and its inferred by the system and not overriden by user, Estimate Age should be disabled.
                            // If living/deceased value exists and set to Living and a DOB exists, then system will infer Calc Age and hence Estimate Age should be disabled.
                            return true;
                        }
                    }
                }
                break;
            default:
        }

        return false;
    }

    static updateUserOverrideFieldForCaseAssertions(currentCase: ICaseModel) {
        if (currentCase.caseAssertions) {
            for (let i = 0; i < currentCase.caseAssertions.length; i++) {
                const currentItem = currentCase.caseAssertions[i];
                // Only store User Override column for Auto Calculated fields
                if (
                    currentItem.userOverride &&
                    currentItem.assertionDescriptor &&
                    currentItem.assertionDescriptor.guid &&
                    CustomLogicAssertionDescriptors.filter(
                        (x) =>
                            x.Guid === currentItem.assertionDescriptor!.guid!.toUpperCase() &&
                            x.AutoCalculated
                    ).length == 0
                ) {
                    currentItem.userOverride = undefined;
                }
            }
        }
    }

    static handleGroupFieldsForEasyUpdate(
        currentCase: ICaseModel,
        easyUpdateFieldsSelected: IMultiSelectOptions[],
        adGroups: string[],
        assertionDescriptors: IAssertionDescriptorModel[]
    ) {
        if (
            easyUpdateFieldsSelected.some(
                (x) => x.guid && adGroups.some((y) => y === x.guid!.toUpperCase())
            )
        ) {
            adGroups.forEach((adGroupGuid) => {
                const isNotSelected = !easyUpdateFieldsSelected.some(
                    (x) => x.guid !== undefined && x.guid.toUpperCase() === adGroupGuid
                );

                if (isNotSelected) {
                    const adMatch = assertionDescriptors.find(
                        (x) => x.guid && x.guid.toUpperCase() === adGroupGuid
                    );

                    if (adMatch) {
                        easyUpdateFieldsSelected.push({
                            id: 0,
                            label: adMatch.displayName!,
                            value: adMatch.name!,
                            guid: adMatch.guid!,
                        });

                        if (!currentCase.caseAssertions) currentCase.caseAssertions = [];

                        if (
                            currentCase.caseAssertions.some(
                                (x) => x.assertionDescriptor?.guid === adMatch.guid
                            )
                        ) {
                            const adCopy = JSON.parse(JSON.stringify(adMatch));
                            currentCase.caseAssertions.push({
                                assertionDescriptor: adCopy,
                            });
                        }
                    }
                }
            });
        }
    }

    static getStaticItemFieldHelpText(staticItemId: number) {
        if (staticItemId === CaseFileStaticItems.CaseManager.Id)
            return 'Cases where the selected value is A1 or A2, a primary point of contact for a case\n\n“Blank” returns cases where A1 AND A2 fields are blank\n\n“Not Blank” returns cases where A1 AND/OR A2 fields have a value selected';

        for (let i = 0; i < QueryFilterDatabaseFieldsHelpText.length; i++) {
            if (staticItemId === QueryFilterDatabaseFieldsHelpText[i].id)
                return QueryFilterDatabaseFieldsHelpText[i].helpText;
        }
        return null;
    }

    static getAssertionDescriptorFieldHelpText(
        assertionDescriptors: IAssertionDescriptorModel[],
        name: string,
        assertionDescriptorGuid?: string,
        queryTool?: boolean
    ) {
        if (assertionDescriptorGuid) {
            if (queryTool) {
                if (
                    assertionDescriptorGuid.toUpperCase() ===
                    AssertionDescriptors.PlainfirmFirmLocal.Guid.toUpperCase()
                )
                    return 'Cases where the selected firm is a Plaintiffs Firm or National Plaintiffs Firm\n\n“Blank” returns cases where Plaintiffs Firm AND National Plaintiffs Firm fields are blank\n\n“Not Blank” returns cases where Plaintiffs Firm AND/OR National Plaintiffs Firm fields have a value selected';
                else if (
                    assertionDescriptorGuid.toUpperCase() ===
                    AssertionDescriptors.Occupation.Guid.toUpperCase()
                )
                    return 'Cases where the selected Occupation is Occupation or Other Occupation\n\n“Blank” returns cases where Occupation AND Other Occupation fields are blank\n\n“Not Blank” returns cases where Occupation AND/OR Other Occupation fields have a value selected';
            }

            const match = assertionDescriptors.find(
                (x) =>
                    x.guid != undefined &&
                    x.guid.toUpperCase() == assertionDescriptorGuid.toUpperCase()
            );
            if (match && match.helpText) return match.helpText;
        }

        return null;
    }

    static getCaseTitle(currentCase: ICaseModel, excludeCaseGuid?: boolean) {
        const caseTitle: ICaseTitleModel = {
            guid: excludeCaseGuid ? undefined : currentCase.guid,
            caseName: currentCase.caseName,
            priority: currentCase.priority,
            SQProjection: currentCase.qProjection,
            SQScore: currentCase.qScore,
            threatScore: currentCase.threatScore,
        };
        return caseTitle;
    }

    static getCaseLocalCounselGuid = (currentCase: ICaseModel) => {
        if (currentCase.defenseCounsels) {
            const localCounsel = currentCase.defenseCounsels.find((x) => x.priority === 1);
            if (localCounsel) return localCounsel.guid;
        }
    };

    static getDefaultAlertTargetForCase = (user: IUserModel, currentCase: ICaseModel) => {
        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(user);
        if (
            loggedInUserDataScopeId === DataScopesEnum.LocalBasic.Value &&
            currentCase.caseManagerUser
        ) {
            return currentCase.caseManagerUser;
        }
    };

    static getDefaultWatchTargetsForCase = (user: IUserModel, currentCase: ICaseModel) => {
        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(user);
        if (
            loggedInUserDataScopeId === DataScopesEnum.LocalBasic.Value &&
            currentCase.alternateCaseManagerUser
        ) {
            return [currentCase.alternateCaseManagerUser];
        }
    };

    static updateReportData = (
        reportCase: IReportCaseDetailModel,
        caseModel: ICaseModel,
        plaintiffsFirmDisplayMode?: number,
        caseDetailsLastColumn?: number
    ) => {
        reportCase.caseNumber = caseModel.caseNumber || '';
        reportCase.uniqueCaseId = caseModel.uniqueCaseId;
        reportCase.jurisdiction = CaseHelper.getAssertionText(
            caseModel,
            AssertionDescriptors.Jurisdiction.Guid
        );
        reportCase.plaintiffsFirm = CaseHelper.getAssertionText(
            caseModel,
            AssertionDescriptors.PlainfirmFirmLocal.Guid
        );
        reportCase.secondaryPlaintiffsFirm = CaseHelper.getAssertionText(
            caseModel,
            AssertionDescriptors.PlainfirmFirmSecondary.Guid
        );
        reportCase.diagnosis = CaseHelper.getAssertionText(
            caseModel,
            AssertionDescriptors.Diagnosis.Guid
        );
        reportCase.occupation = CaseHelper.getAssertionText(
            caseModel,
            AssertionDescriptors.Occupation.Guid
        );
        reportCase.alternateCaseIDNumber = CaseHelper.getAssertionText(
            caseModel,
            AssertionDescriptors.AlternateCaseIDNumber.Guid
        );
        reportCase.alternateCaseIDNumber3 = CaseHelper.getAssertionText(
            caseModel,
            AssertionDescriptors.AlternateCaseIDNumber3.Guid
        );
        reportCase.alternateCaseIDNumber4 = CaseHelper.getAssertionText(
            caseModel,
            AssertionDescriptors.AlternateCaseIDNumber4.Guid
        );

        if (!plaintiffsFirmDisplayMode)
            plaintiffsFirmDisplayMode = PlaintiffsFirmDisplayMode.Hierarchy;

        switch (plaintiffsFirmDisplayMode) {
            case PlaintiffsFirmDisplayMode.Local:
                reportCase.plaintiffsFirmDisplayName = reportCase.plaintiffsFirm;
                break;
            case PlaintiffsFirmDisplayMode.National:
                reportCase.plaintiffsFirmDisplayName = reportCase.secondaryPlaintiffsFirm;
                break;
            case PlaintiffsFirmDisplayMode.Hierarchy:
                reportCase.plaintiffsFirmDisplayName =
                    reportCase.secondaryPlaintiffsFirm || reportCase.plaintiffsFirm;
                break;
            case PlaintiffsFirmDisplayMode.Both:
                reportCase.plaintiffsFirmDisplayName =
                    reportCase.secondaryPlaintiffsFirm + ' | ' + reportCase.plaintiffsFirm;
                break;
            default:
                break;
        }

        if (!caseDetailsLastColumn)
            caseDetailsLastColumn = ReportCaseDetailsLastColumn.ProductPremises;

        switch (caseDetailsLastColumn) {
            case ReportCaseDetailsLastColumn.ProductPremises:
                reportCase.additionalCaseIdentifier = reportCase.productPremises;
                break;
            case ReportCaseDetailsLastColumn.Occupation: {
                const occupations: string[] = [];
                if (reportCase.occupation) occupations.push(reportCase.occupation);
                const otherOcc = this.getAssertionText(
                    caseModel,
                    AssertionDescriptors.OtherOccupation.Guid
                );
                if (otherOcc) occupations.push(otherOcc);
                reportCase.additionalCaseIdentifier = occupations.join(' | ');
                break;
            }
            case ReportCaseDetailsLastColumn.UniqueCaseID:
                reportCase.additionalCaseIdentifier = reportCase.uniqueCaseId;
                break;
            case ReportCaseDetailsLastColumn.AlternateCaseIDNumber:
                reportCase.additionalCaseIdentifier = reportCase.alternateCaseIDNumber;
                break;
            case ReportCaseDetailsLastColumn.AlternateCaseIDNumber3:
                reportCase.additionalCaseIdentifier = reportCase.alternateCaseIDNumber3;
                break;
            case ReportCaseDetailsLastColumn.AlternateCaseIDNumber4:
                reportCase.additionalCaseIdentifier = reportCase.alternateCaseIDNumber4;
                break;
            default:
                break;
        }
    };

    // User "alternateDisplayName" everywhere BUT the Case File.
    static getCaseFileStaticItemDisplayName(lookupData: IStaticItemLookupModel[], item: number) {
        const match = lookupData.find((x) => x.id === item);
        if (match) return match.alternateDisplayName || match.displayName || match.name || '';

        return '';
    }

    
    static getAuthorizedQueryTypes(user: IUserModel) {
        const queryTypes: ILookupModel[] = [];
    
        if (Authorization.userHasRight(UserRightsEnum.ViewCaseFile, user)) {
            queryTypes.push({
                id: QueryTypes.CaseDetails.Value,
                name: QueryTypes.CaseDetails.Name,
                displayName: QueryTypes.CaseDetails.DisplayName,
            });
        }
    
        if (Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, user)) {
            queryTypes.push({
                id: QueryTypes.CaseNotes.Value,
                name: QueryTypes.CaseNotes.Name,
                displayName: QueryTypes.CaseNotes.DisplayName,
            });
        }
    
        if (Authorization.userHasRight(UserRightsEnum.ViewCaseExpert, user)) {
            queryTypes.push({
                id: QueryTypes.CaseExperts.Value,
                name: QueryTypes.CaseExperts.Name,
                displayName: QueryTypes.CaseExperts.DisplayName,
            });
        }
    
        if (Authorization.userHasRight(UserRightsEnum.ViewDeposition, user)) {
            queryTypes.push({
                id: QueryTypes.Deposition.Value,
                name: QueryTypes.Deposition.Name,
                displayName: QueryTypes.Deposition.DisplayName,
            });
        }
    
        if (Authorization.userHasRight(UserRightsEnum.ViewZoneNotes, user)) {
            queryTypes.push({
                id: QueryTypes.ZoneNotes.Value,
                name: QueryTypes.ZoneNotes.Name,
                displayName: QueryTypes.ZoneNotes.DisplayName,
            });
        }
    
        if (Authorization.userHasViewStrategyNotesRight(user)) {
            queryTypes.push({
                id: QueryTypes.StrategyNote.Value,
                name: QueryTypes.StrategyNote.Name,
                displayName: QueryTypes.StrategyNote.DisplayName,
            });
        }
    
        return queryTypes;
    }
}
