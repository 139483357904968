import ApiClient from '../../../services/apiClient';
import { useLoading } from '../../../contexts/LoadingContext';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetTriggerRule = (triggerGuid?: string, enableLoader?: boolean) => {
    const loading = useLoading();

    return useQuery({
        queryKey: ['get-trigger-rule', { triggerGuid }],
        queryFn: async () => {
            if (!triggerGuid) return;

            if (enableLoader) {
                loading.showLoading();
            }

            const response = await _apiClient.getTrigger(triggerGuid);
            return response.payload;
        },
        onSettled: enableLoader ? () => loading.hideLoading() : undefined,
        enabled: !!triggerGuid,
        cacheTime: 0,
        staleTime: 0,
    });
};
