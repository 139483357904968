import ApiClient from '../../services/apiClient';
import { EntityTypes } from '../../utilities/Constants';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useNoteCategoryTypes = (entityType?: EntityTypes) => {
    return useQuery({
        queryKey: ['get-note-category-types', entityType ?? 'all'],
        queryFn: async () => {
            const response = await _apiClient.getNoteCategoryTypesByEntityType(entityType);

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve note category types.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        cacheTime: 60 * 60 * 100,
        staleTime: 60 * 60 * 100,
    });
};
