import * as React from 'react';

import {
    AssertionDescriptorUsageTypesEnum,
    CaseFileStaticItems,
    QueryToolRedirectTypeEnum,
} from '../../utilities/Constants';

import { IQueryFilterCriteria } from '../../interfaces/IQuery';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { Query2 } from './Query2';
import { useAssertionDescriptorsLookup } from '../../shared/react-query-hooks/useAssertionDescriptorsLookup';
import { useCaseManagersInZone } from '../../shared/react-query-hooks/useCaseManagersInZone';
import { useCaseStatusTypesLookup } from '../../shared/react-query-hooks/useCaseStatusTypesLookup';
import { useDefenseCounselsLookup } from '../../shared/react-query-hooks/useDefenseCounselsLookup';
import { useGetAcknowledgmentStatusTypes } from '../../shared/react-query-hooks/useGetAcknowledgmentStatusTypes';
import { useGetApprovalStatusTypes } from '../../shared/react-query-hooks/useGetApprovalStatusTypes';
import { useGetDepositionCategories } from '../../shared/react-query-hooks/useGetDepositionCategories';
import { useGetDepositionStatusTypes } from '../../shared/react-query-hooks/useGetDepositionStatusTypes';
import { useGetNotePurposeTypes } from '../../shared/react-query-hooks/useGetNotePurposeTypes';
import { useGetPackageDeals } from '../../shared/react-query-hooks/useGetPackageDeals';
import { useGetStaticItems } from '../../shared/react-query-hooks/useGetStaticItems';
import { useLocation } from 'react-router-dom';
import { useNoteCategoryTypes } from '../../shared/react-query-hooks/useNoteCategoryTypes';
import { useUsStatesLookup } from '../../shared/react-query-hooks/useUsStatesLookup';
import { useZoneUsers } from '../../shared/react-query-hooks/useZoneUsers';

export interface IQuery2WrapperProps {
    user: IUserModel;
    displayColumnsSelected?: IQueryFilterCriteria[];
    queryFilterCriteriaSelected?: IQueryFilterCriteria[];
    redirectType?: QueryToolRedirectTypeEnum;
    title?: string;
    redirectUrl?: string;
    redirectComponentCurrentState?: string;
    refreshIdentifier?: string;
}

export function Query2Wrapper(props: IQuery2WrapperProps) {
    const { key, state: locationState } = useLocation();
    const acknowledgmentStatusTypes = useGetAcknowledgmentStatusTypes();
    const approvalStatusTypes = useGetApprovalStatusTypes();
    const assertionDescriptorsLookup = useAssertionDescriptorsLookup(
        AssertionDescriptorUsageTypesEnum.Case
    );
    const caseManagersLookup = useCaseManagersInZone();
    const caseStatusTypesLookup = useCaseStatusTypesLookup();
    const defenseCounselsLookup = useDefenseCounselsLookup();
    const depositionCategories = useGetDepositionCategories();
    const depositionStatusTypes = useGetDepositionStatusTypes();
    const noteCategoryTypes = useNoteCategoryTypes();
    const notePurposes = useGetNotePurposeTypes();
    const staticItems = useGetStaticItems();
    const usStatesLookup = useUsStatesLookup();
    const zoneUsersLookup = useZoneUsers();
    const packageDeals = useGetPackageDeals(
        !!(staticItems.data ?? []).find((si) => si.id === CaseFileStaticItems.PackageSettlement.Id)
    );

    const isLoadingLookups =
        acknowledgmentStatusTypes.isLoading ||
        approvalStatusTypes.isLoading ||
        assertionDescriptorsLookup.isLoading ||
        caseManagersLookup.isLoading ||
        caseStatusTypesLookup.isLoading ||
        defenseCounselsLookup.isLoading ||
        depositionCategories.isLoading ||
        depositionStatusTypes.isLoading ||
        noteCategoryTypes.isLoading ||
        notePurposes.isLoading ||
        staticItems.isLoading ||
        usStatesLookup.isLoading ||
        zoneUsersLookup.isLoading ||
        packageDeals.isLoading;

    const hasLookupError =
        approvalStatusTypes.isError ||
        acknowledgmentStatusTypes.isError ||
        assertionDescriptorsLookup.isError ||
        caseManagersLookup.isError ||
        caseStatusTypesLookup.isError ||
        defenseCounselsLookup.isError ||
        depositionCategories.isError ||
        depositionStatusTypes.isError ||
        noteCategoryTypes.isError ||
        notePurposes.isError ||
        staticItems.isError ||
        usStatesLookup.isError ||
        zoneUsersLookup.isError ||
        packageDeals.isError;

    let validation: IValidation = {};

    if (hasLookupError) {
        validation = {
            ...validation,
            lookups: [
                'An unhandled error occurred while retrieving Query Criteria data. Please refresh your page and try again. If the issue persists, contact your administrator',
            ],
        };
    }

    if (isLoadingLookups) {
        return <Loader />;
    }

    return (
        <Query2
            acknowledgmentStatusTypes={acknowledgmentStatusTypes?.data ?? []}
            approvalStatusTypes={approvalStatusTypes?.data ?? []}
            assertionDescriptors={assertionDescriptorsLookup?.data ?? []}
            caseManagers={caseManagersLookup?.data ?? []}
            caseStatuses={caseStatusTypesLookup?.data ?? []}
            defenseCounsels={defenseCounselsLookup?.data ?? []}
            depositionCategories={depositionCategories?.data ?? []}
            depositionStatusTypes={depositionStatusTypes?.data ?? []}
            noteCategoryTypes={noteCategoryTypes?.data ?? []}
            notePurposeTypes={notePurposes?.data ?? []}
            packageDeals={packageDeals?.data ?? []}
            redirectModel={locationState?.redirectModel}
            refreshIdentifier={key}
            staticItems={staticItems.data ?? []}
            user={props.user}
            usStates={usStatesLookup.data ?? []}
            validation={validation}
            zoneUsers={zoneUsersLookup.data ?? []}
        />
    );
}

export interface IQueryRedirectModel {
    queryFilterCriteriaSelected: IQueryFilterCriteria[];
    displayColumnsSelected: IQueryFilterCriteria[];
    title: string;
    redirectUrl: string;
    redirectType: QueryToolRedirectTypeEnum | undefined;
    redirectComponentCurrentState: any;
    showSelectAllCheckbox?: boolean;
}
