import { CaseFileStaticItems, UserRightsEnum } from '../../utilities/Constants';

import Authorization from '../../stores/Authorization';
import CaseFileCheckmateSelectHelper from '../../utilities/CaseFileCheckmateSelectHelper';
import CaseHelper from '../../utilities/CaseHelper';
import CheckmateSelect from '../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { FieldControlWrapper } from './FieldControlWrapper';
import { ICaseStatusModel } from '../../interfaces/ICase';
import { IDisplayFieldModel } from '../../interfaces/IDisplaySectionModel';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { useCaseFileContext } from '../../contexts/CaseFileContext';

interface IStaticControlProps {
    field: IDisplayFieldModel;
}

function CaseNumberControl() {
    const { currentCase, isReadOnlyMode, handleChangeCaseValue } = useCaseFileContext();
    return (
        <>
            {isReadOnlyMode ? (
                <>{currentCase.caseNumber}</>
            ) : (
                <input
                    className="form-control d-inline-block ms-2"
                    type="text"
                    name="caseNumber"
                    value={currentCase.caseNumber ?? ''}
                    onChange={handleChangeCaseValue}
                    style={{ width: 'auto' }}
                />
            )}
        </>
    );
}

function CaseStatusControl() {
    const {
        currentCase,
        caseStatuses,
        handleStaticItemSearchableSingleSelectChange,
        isReadOnlyMode,
        setShowAdditionalFieldsDialog,
    } = useCaseFileContext();

    const getCaseStatusLookupItem = (caseStatus?: ICaseStatusModel) => {
        if (caseStatus && caseStatus.status) {
            let filteredList = caseStatuses.filter(
                (x) => x.status && x.status.id == caseStatus.status.id
            );
            if (caseStatus.subStatus) {
                filteredList = filteredList.filter(
                    (x) => x.subStatus && x.subStatus.id == caseStatus.subStatus.id
                );
            }
            if (filteredList.length > 0) return filteredList[0];
        }
        return undefined;
    };

    const caseStatusLookupItem = getCaseStatusLookupItem(currentCase?.caseStatus);

    const showAdditionalFieldsDialogButton =
        CaseHelper.isResolvedCase(currentCase) ||
        CaseHelper.isTenderedAcceptedCase(currentCase) ||
        CaseHelper.isAltCase(currentCase);

    return (
        <>
            {isReadOnlyMode ? (
                <>{currentCase.caseStatus?.displayName ?? ''}</>
            ) : (
                <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}
                >
                    <div style={{ minWidth: 250, maxWidth: 300, width: '100%' }}>
                        <CheckmateSelect
                            name="caseStatus"
                            options={CaseFileCheckmateSelectHelper.getCaseStatusOptionsForCheckmateSelect(
                                caseStatuses
                            )}
                            onChange={(e: any) =>
                                handleStaticItemSearchableSingleSelectChange(
                                    e,
                                    CaseFileStaticItems.CaseStatus.Id
                                )
                            }
                            value={CaseFileCheckmateSelectHelper.getCaseStatusValue(
                                caseStatuses,
                                caseStatusLookupItem ? caseStatusLookupItem.id : 0
                            )}
                        />
                    </div>
                    {showAdditionalFieldsDialogButton && (
                        <button
                            className="ps-2 btn-no-bg"
                            onClick={() => setShowAdditionalFieldsDialog(true)}
                        >
                            <i className="fal fa-lg fa-calendar-exclamation color-black" />
                        </button>
                    )}
                </div>
            )}
        </>
    );
}

function LocalCounselControl(props: IStaticControlProps) {
    const {
        currentCase,
        handleStaticItemSearchableSingleSelectChange,
        isReadOnlyMode,
        zoneDefenseCounsels,
    } = useCaseFileContext();

    const getDefenseCounselName = () => {
        return currentCase.defenseCounsels?.find((dc) => dc.priority === 1)?.name ?? '';
    };

    const getLocalCounselGuid = () => {
        return currentCase.defenseCounsels?.find((dc) => dc.priority === 1)?.guid ?? '';
    };

    return (
        <>
            {isReadOnlyMode ? (
                <>{getDefenseCounselName()}</>
            ) : (
                <div style={{ minWidth: 250, maxWidth: 300, width: '100%' }}>
                    <CheckmateSelect
                        name={props.field.name}
                        options={CheckmateSelectHelper.getOrgOptions(zoneDefenseCounsels)}
                        onChange={(optionsList: any) =>
                            handleStaticItemSearchableSingleSelectChange(
                                optionsList,
                                CaseFileStaticItems.LocalCounsel.Id
                            )
                        }
                        value={CheckmateSelectHelper.getSelectedOrgValue(
                            zoneDefenseCounsels,
                            getLocalCounselGuid() || ''
                        )}
                    />
                </div>
            )}
        </>
    );
}

interface ICaseManagerControlProps {
    isPrimary: boolean;
}
function CaseManagerControl(props: ICaseManagerControlProps) {
    const {
        caseManagers,
        zoneUsers,
        currentCase,
        handleStaticItemSearchableSingleSelectChange,
        isReadOnlyMode,
    } = useCaseFileContext();

    const selectedValue: string = props.isPrimary
        ? currentCase.caseManagerUser?.guid || ''
        : currentCase.alternateCaseManagerUser?.guid || '';

    const getCaseManagerName = (alternate?: boolean) => {
        if (currentCase) {
            if (alternate) {
                if (currentCase.alternateCaseManagerUser?.profile) {
                    return `${currentCase.alternateCaseManagerUser.profile!.firstName} ${
                        currentCase.alternateCaseManagerUser.profile!.lastName
                    }`;
                }
            } else {
                if (currentCase.caseManagerUser?.profile) {
                    return `${currentCase.caseManagerUser.profile!.firstName} ${
                        currentCase.caseManagerUser.profile!.lastName
                    }`;
                }
            }
            return '';
        }
    };

    return (
        <>
            {isReadOnlyMode ? (
                <>{getCaseManagerName(!props.isPrimary)}</>
            ) : (
                <div style={{ minWidth: 250, maxWidth: 300, width: '100%' }}>
                    <CheckmateSelect
                        name={props.isPrimary ? 'caseManager' : 'alternateCaseManager'}
                        options={CheckmateSelectHelper.getUserOptions(caseManagers)}
                        onChange={(e: any) =>
                            handleStaticItemSearchableSingleSelectChange(
                                e,
                                props.isPrimary
                                    ? CaseFileStaticItems.CaseManager.Id
                                    : CaseFileStaticItems.AlternateCaseManager.Id
                            )
                        }
                        value={CheckmateSelectHelper.getSelectedUserValue(zoneUsers, selectedValue)}
                    />
                </div>
            )}
        </>
    );
}

function UniqueCaseIDControl() {
    const { currentCase, handleChangeCaseValue, isReadOnlyMode } = useCaseFileContext();

    return (
        <>
            {isReadOnlyMode ? (
                <>{currentCase.uniqueCaseId}</>
            ) : (
                <input
                    className="form-control"
                    type="text"
                    maxLength={100}
                    name="uniqueCaseId"
                    value={currentCase.uniqueCaseId ?? ''}
                    onChange={handleChangeCaseValue}
                />
            )}
        </>
    );
}

function PackageSettlementControl() {
    const { currentCase, user } = useCaseFileContext();

    if (!currentCase.packageSettlement) return null;

    const { packageSettlement } = currentCase;
    const { guid, name } = packageSettlement;

    return (
        <>
            {guid ? (
                Authorization.userHasRight(UserRightsEnum.ViewPackageSettlement, user) ? (
                    <Link
                        target="_blank"
                        to={LocalRoutes.PackageSettlementDetails.replace(':guid', guid)}
                    >
                        {name}
                    </Link>
                ) : (
                    <span>{name}</span>
                )
            ) : (
                <span>__________</span>
            )}
        </>
    );
}

export {
    CaseNumberControl,
    CaseStatusControl,
    LocalCounselControl,
    CaseManagerControl,
    UniqueCaseIDControl,
    PackageSettlementControl,
    FieldControlWrapper,
};
