import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useDocumentCategoryTypes = (entityTypeId: number) => {
    return useQuery({
        queryKey: ['get-document-category-types', { entityTypeId }],
        queryFn: async () => {
            const response = await _apiClient.getDocumentCategoriesForEntityType(entityTypeId);

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve document category types.');
            }
            return response.payload;
        },
    });
};