import { useParams } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { AssertionDescriptorDetail } from '../assertiondescriptor/AssertionDescriptorDetail';

function AssertionDescriptorDetailWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return <AssertionDescriptorDetail guid={guid || ''} user={props.user} />;
}

export default AssertionDescriptorDetailWrapper;
