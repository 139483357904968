import { ValueControls, ValueControlsType } from '../value-controls/ValueControls';

import { IQueryFilterCriteria } from '../../../../interfaces/IQuery';

export interface ITriggerFilterCriteriaValueControlsProps {
    item: IQueryFilterCriteria;
    onDateRangeChange: (item: IQueryFilterCriteria, field: string, value: string) => void;
    onRelativeDateChange: (item: IQueryFilterCriteria, value: number) => void;
    onNumericRangeChange: (
        item: IQueryFilterCriteria,
        field: string,
        value: number | undefined
    ) => void;
    onSelectorChange: (item: IQueryFilterCriteria, options: any) => void;
    onTextChange: (item: IQueryFilterCriteria, value: string) => void;
    onYesNoChange: (item: IQueryFilterCriteria, option: any) => void;
}

export function TriggerFilterCriteriaValueControls(
    props: ITriggerFilterCriteriaValueControlsProps
) {
    return <ValueControls {...props} type={ValueControlsType.Criteria} />;
}
