import ApiClient from '../../services/apiClient';
import { LookupDataEnums } from '../../utilities/Constants';
import { useQuery } from 'react-query';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import  {ILookupModel, IMultiSelectOptions} from '../../interfaces/ILookup';

const _apiClient = new ApiClient();

export const useExpertReportStatusTypesLookup = () => {
    return useQuery({
        queryKey: ['get-expert-report-status-types-lookup'],
        queryFn: async () => {
            const response = await _apiClient.getLookupData(LookupDataEnums.ExpertReportStatusTypes);

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve expert report status types.');
            }

            const options:IMultiSelectOptions[] =  CheckmateSelectHelper.getLookupOptions(
                response.payload as ILookupModel[], true
            );
            return options;
        },
    });
};