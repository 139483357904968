import ApiClient from '../../services/apiClient';
import { ITriggerRuleSettingsModel } from '../../interfaces/ITriggerRule';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useCreateTriggerMutation = (onError: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (newTrigger: ITriggerRuleSettingsModel) => {
            const response = await _apiClient.createTrigger(newTrigger);
            if (!response?.httpResponse.ok) {
                if (response.httpResponse.status === 400
                ) {
                    throw new Error(`Failed to create Trigger : ${response.errorMessage}`);
                }
                else if (response.httpResponse.status === 403
                ) {
                    throw new Error(`Forbidden to create trigger`);
                }
                throw new Error('Failed to create Trigger');
            }
            return response.payload;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
    });
