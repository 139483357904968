import { INoteCategoryTypeModel, INoteModel } from '../../../interfaces/INote';
import { useEffect, useState } from 'react';

import ApiClient from '../../../services/apiClient';
import { EntityTypes } from '../../../utilities/Constants';
import { IUserModel } from '../../../interfaces/IUser';
import { NoteList } from '../../notes/NoteList';

const _apiClient = new ApiClient();

export interface INoteListWrapperProps {
    allowAddNew: boolean;
    allowDelete: boolean;
    allowEdit: boolean;
    allowView: boolean;
    entityType: EntityTypes;
    notes: INoteModel[];
    title?: string;
    titleClassName?: string;
    user: IUserModel;
    onAddNote: () => void;
    onViewEditNote: (noteGuid: string, readOnly?: boolean) => void;
    onRefreshParent?: (notes: INoteModel[]) => void;
}

export function NoteListWrapper({
    allowAddNew,
    allowDelete,
    allowEdit,
    allowView,
    entityType,
    notes,
    title,
    titleClassName,
    user,
    onAddNote,
    onViewEditNote: onViewEditNote,
    onRefreshParent,
}: INoteListWrapperProps) {
    const [, setIsLoadingCategories] = useState<boolean>(true);
    const [categoryTypes, setCategoryTypes] = useState<INoteCategoryTypeModel[]>([]);

    const loadCaseNoteCategoryTypes = async () => {
        const response = await _apiClient.getNoteCategoryTypesByEntityType(entityType);
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            throw new Error(response.errorMessage);
        }
        return response.payload;
    };

    const init = async () => {
        setIsLoadingCategories(true);

        await loadCaseNoteCategoryTypes()
            .then((types) => setCategoryTypes(types ?? []))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setIsLoadingCategories(false));
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <NoteList
            title={title}
            titleClassName={titleClassName}
            user={user}
            notes={notes}
            noteCategoryTypes={categoryTypes}
            handleViewEditNote={onViewEditNote}
            handleAddNote={onAddNote}
            allowView={allowView}
            allowEdit={allowEdit}
            allowDelete={allowDelete}
            allowAddNew={allowAddNew}
            showExpertsColumn={true}
            refreshParent={(notes) => {
                if (onRefreshParent) {
                    onRefreshParent(notes);
                }
            }}
        />
    );
}
