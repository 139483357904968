import * as React from 'react';

import CheckmateApiClient from '../../services/apiClient';
import { CheckmateDialog } from '../shared/dialog';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ICaseDeadlineModel } from '../../interfaces/ICase';
import { IDialogModel } from '../../interfaces/IDialog';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../shared/Loader';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../shared/SortableHeader';
import ValidateUtils from '../../shared/validations';

const _apiClient = new CheckmateApiClient();

interface ICaseDeadlinesProps {
    user: IUserModel;
    caseDeadlines: ICaseDeadlineModel[];
    caseGuid: string;
}

interface ICaseDeadlinesState {
    validation: IValidation;
    caseDeadlines: ICaseDeadlineModel[];
    dialog: IDialogModel;
    readOnlyMode: boolean;
    pendingResponse: boolean;
}

export class CaseDeadlines extends React.Component<ICaseDeadlinesProps, ICaseDeadlinesState> {
    constructor(props: any) {
        super(props);

        this.state = {
            validation: {},
            caseDeadlines: [],
            dialog: { isShowingModal: false },
            readOnlyMode: true,
            pendingResponse: false,
        };
    }

    componentDidMount() {
        this.setState({
            caseDeadlines: this.props.caseDeadlines,
        });
    }

    componentDidUpdate(prevProps: ICaseDeadlinesProps) {
        // AR TO DO - Commented out during Tech Upgrade. Handle as needed
        /*         if (prevProps.caseDeadlines) {
                    this.setState({
                        caseDeadlines: prevProps.caseDeadlines
                    });
                } */
    }

    saveDeadlines = async () => {
        const response = await _apiClient.updateCaseDeadlines(
            this.props.caseGuid,
            this.state.caseDeadlines!
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        if (response.payload) {
            this.setState({
                dialog: { isShowingModal: true, body: DisplayMessages.CaseDeadlinesUpdate },
            });
        }
    };

    handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>, deadlineTypeId: number) => {
        const coreDeadlines = this.state.caseDeadlines;
        if (coreDeadlines) {
            const match = coreDeadlines.filter((x) => x.deadlineType!.id == deadlineTypeId);
            if (match.length > 0) {
                match[0].date = event.target.value;
            }
        }

        this.setState({ caseDeadlines: coreDeadlines });
    };

    handleCloseDialog = () => {
        window.location.reload();
    };

    editCaseDeadlines = () => {
        this.setState({ readOnlyMode: false });
    };

    resetDeadlines = () => {
        window.location.reload();
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let caseDeadlines = this.state.caseDeadlines;

        if (caseDeadlines) {
            if (key == 'date') {
                caseDeadlines = caseDeadlines.sort(Sort.compareDate(key, subKey, order));
            } else {
                caseDeadlines = caseDeadlines.sort(
                    Sort.compareValues(key, subKey, order, subGrandKey)
                );
            }
        }

        this.setState({ caseDeadlines: caseDeadlines });
    };

    render() {
        return (
            <div className="container-fluid ">
                {this.state.pendingResponse ? <Loader /> : null}
                {/*Authorization.userHasRight(UserRightsEnum.EditCaseFile, this.props.user) ?
                    (this.state.readOnlyMode ?
                            <button className="btn btn-no-bg float-end text-gray margin-bottom" onClick={this.editCaseDeadlines}><i className="fal fa-pen" />&nbsp;Edit</button> :
                            <span>
                                <button className="btn btn-orange float-end" onClick={this.saveDeadlines} >Save deadlines</button>
                                <button className="btn btn-default float-end" onClick={this.resetDeadlines} >Cancel</button>
                            </span>)
                    : null
                */}
                <div>
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>
                <div>
                    <table className="table vertical-margin-lg">
                        <thead>
                            <tr style={{ verticalAlign: 'middle' }}>
                                <SortableHeader
                                    headerText="Deadline Category"
                                    sortKey="deadlineCategory"
                                    subKey="displayName"
                                    onSort={this.sortData}
                                />
                                <th /* width="30" */ />
                                <SortableHeader
                                    headerText="Deadline Type"
                                    sortKey="deadlineType"
                                    subKey="displayName"
                                    onSort={this.sortData}
                                />
                                <SortableHeader
                                    headerText="Due Date"
                                    sortKey="date"
                                    onSort={this.sortData}
                                    noWrap
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.caseDeadlines.map(
                                (deadline: ICaseDeadlineModel, index: number) => {
                                    return (
                                        <tr key={`deadline${index}`}>
                                            <td>
                                                {
                                                    deadline!.deadlineType!.deadlineCategory!
                                                        .displayName
                                                }
                                            </td>
                                            <td>
                                                {deadline.required ? (
                                                    <i className="fal fa-lg fa-calendar-exclamation" />
                                                ) : null}
                                            </td>
                                            <td>{deadline.deadlineType!.displayName}</td>
                                            <td className="center">
                                                {this.state.readOnlyMode ? (
                                                    Common.dateFormat(deadline.date)
                                                ) : (
                                                    <input
                                                        type="date"
                                                        className={
                                                            deadline.date &&
                                                            Common.isValidDate(deadline.date)
                                                                ? 'form-control'
                                                                : 'unselectClass form-control'
                                                        }
                                                        name="endDate"
                                                        value={Common.dateFormat(deadline.date)}
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLInputElement>
                                                        ) => {
                                                            this.handleChangeDate(
                                                                e,
                                                                deadline.deadlineType.id!
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
                <CheckmateDialog
                    isShowingModal={this.state.dialog.isShowingModal}
                    title={this.state.dialog.title}
                    body={this.state.dialog.body}
                    handleClose={this.handleCloseDialog}
                    cancelText="Ok"
                    dialogClassName="user-profile-dialog"
                    closeButtonClassName="btn btn-black float-end"
                />
            </div>
        );
    }
}
