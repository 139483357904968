import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';
import { LookupDataEnums } from '../../utilities/Constants';

const _apiClient = new ApiClient();

export const useGetAcknowledgmentStatusTypes = () => {
    return useQuery({
        queryKey: ['get-acknowledgement-status-types'],
        queryFn: async () => {
            const response = await _apiClient.getLookupData(
                LookupDataEnums.AcknowledgmentStatusTypes
            );

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve acknowledgement status types.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        staleTime: Infinity,
    });
};
