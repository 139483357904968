import * as React from 'react';

import {
    BoardCriteriaEnum,
    BoardViewDataTypesEnum,
    CaseStatusTypesEnum,
} from '../../utilities/Constants';
import { IBoardComment, IBoardParametersModel } from '../../interfaces/Report/IBoard';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import BoardHelper from '../../utilities/BoardHelper';
import { BoardPreferencesHeader } from '../../components/board/BoardPreferencesHeader';
import { DocumentTitle } from '../../components/shared/DocumentTitle';
import Html5ReportViewer from '../../components/report/Html5ReportViewer';
import { ICaseStatusLookupModel } from '../../interfaces/ICase';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import ParamUtils from '../../shared/ParamUtilities';
import ValidateUtils from '../../shared/validations';

let _apiClient = new ApiClient();

interface IBoardViewDetailState {
    pendingResponse: boolean;
    validation: IValidation;
    boardPreferences: IBoardParametersModel;
    type: string;
    typeValue?: string;
    addEditComment: boolean;
    commentText: string;
    caseStatuses: ICaseStatusLookupModel[];
}

export class BoardViewDetail extends React.Component<any, IBoardViewDetailState> {
    constructor(props: any) {
        super(props);

        this.state = {
            validation: {},
            type: '',
            pendingResponse: false,
            boardPreferences: {
                statusId: CaseStatusTypesEnum.Open.Value,
                criteria: BoardCriteriaEnum.CaseVolume.Value,
            },
            addEditComment: false,
            commentText: '',
            caseStatuses: [],
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.BoardViewDetail, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        let type: string = ParamUtils.getParameterByName('type');
        let preferences: string = ParamUtils.getParameterByName('preferences');

        let boardPreferences: IBoardParametersModel = {
            statusId: CaseStatusTypesEnum.Open.Value,
            criteria: BoardCriteriaEnum.CaseVolume.Value,
        };

        if (preferences) {
            var jsonString = atob(preferences);
            boardPreferences = JSON.parse(jsonString);
        }

        this.setState({ type: type, boardPreferences: boardPreferences });

        this.loadCaseStatuses();
    }

    getReportName() {
        return this.state.type.concat('ViewDetail.trdp');
    }

    loadCaseStatuses = async () => {
        var response = await _apiClient.getCaseStatusLookupData();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ caseStatuses: response.payload!, pendingResponse: false });
    };

    handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ commentText: event.target.value });
    };

    handleAddComment = () => {
        this.setState({ addEditComment: true });
    };

    handleCancelComment = () => {
        this.setState({ addEditComment: false });
    };

    saveComment = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        let boardViewDataType = 0;
        switch (this.state.type) {
            case BoardViewDataTypesEnum.State.Name:
                boardViewDataType = BoardViewDataTypesEnum.State.Value;
                break;
            case BoardViewDataTypesEnum.Jurisdiction.Name:
                boardViewDataType = BoardViewDataTypesEnum.Jurisdiction.Value;
                break;
            case BoardViewDataTypesEnum.PlaintiffsFirm.Name:
                boardViewDataType = BoardViewDataTypesEnum.PlaintiffsFirm.Value;
                break;
            case BoardViewDataTypesEnum.Diagnosis.Name:
                boardViewDataType = BoardViewDataTypesEnum.Diagnosis.Value;
                break;
            case BoardViewDataTypesEnum.Timeline.Name:
                boardViewDataType = BoardViewDataTypesEnum.Timeline.Value;
                break;
            default:
        }

        let boardComment: IBoardComment = {
            text: this.state.commentText,
            boardViewDataType: boardViewDataType,
        };

        var response = await _apiClient.saveBoardComments([boardComment]);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ addEditComment: false, pendingResponse: false });
    };

    render() {
        if (this.state.pendingResponse) {
            return <Loader />;
        } else {
            return (
                <div>
                    <DocumentTitle title={`Board View - ${this.state.type}`} />
                    <h1 style={{ paddingBottom: '10px', paddingLeft: '10px' }}>
                        View Details
                        <BoardPreferencesHeader
                            boardPreferences={this.state.boardPreferences}
                            includeCriteria={true}
                            caseStatuses={this.state.caseStatuses}
                        />
                        <span className="btn btn-blue float-end">
                            {' '}
                            <Link
                                to={
                                    LocalRoutes.Board +
                                    '?preferences=' +
                                    btoa(JSON.stringify(this.state.boardPreferences))
                                }
                                style={{ color: 'white' }}
                            >
                                Back to Board
                            </Link>
                        </span>
                        {this.state.addEditComment ? (
                            <span>
                                <input
                                    type="button"
                                    className="btn btn-default float-end"
                                    onClick={this.handleCancelComment}
                                    value="Cancel"
                                />
                                <button
                                    className="btn btn-orange float-end text-gray horizontal-margin"
                                    onClick={this.saveComment}
                                >
                                    Save
                                </button>
                            </span>
                        ) : (
                            <button
                                className="btn btn-orange float-end text-gray"
                                onClick={this.handleAddComment}
                            >
                                Comments
                            </button>
                        )}
                    </h1>
                    <div>
                        <span className="text-danger">{this.state.validation.model}</span>
                    </div>
                    {this.state.addEditComment ? (
                        <div className="form-group" style={{ paddingLeft: '10px' }}>
                            <label className="control-label" htmlFor="zoneShortName">
                                Comments
                            </label>
                            <textarea
                                className="form-control"
                                value={this.state.commentText}
                                onChange={this.handleCommentChange}
                            />
                        </div>
                    ) : null}
                    {this.state.type ? (
                        <Html5ReportViewer
                            addSpaceForCommentOnTop={this.state.addEditComment}
                            reportName={this.getReportName()}
                            reportParameters={BoardHelper.getBoardReportParameters(
                                this.state.boardPreferences,
                                'Self',
                                this.state.commentText
                            )}
                            refreshReport={false}
                        />
                    ) : null}
                </div>
            );
        }
    }
}
