import ApiClient from '../../services/apiClient';
import { IExpertUpdateModel } from '../../interfaces/IExpert';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useExpertUpdateMutation = (onError: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (expert: IExpertUpdateModel) => {
            const response = await _apiClient.updateExpert(expert);

            if (!response?.httpResponse.ok) {
                throw new Error('Failed to update Expert');
            }

            return response;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
    });
