import ApiClient from '../../services/apiClient';
import { useLoading } from '../../contexts/LoadingContext';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useExpertsList = (enableLoader?: boolean) => {
    const loading = useLoading();

    return useQuery({
        queryKey: ['get-experts-list'],
        queryFn: async () => {
            if (enableLoader) {
                loading.showLoading();
            }

            const response = await _apiClient.getExperts();
            const sorted = response.payload?.sort((a, b) =>
                a.lastName.toLowerCase() < b.lastName.toLowerCase() ? -1 : 1
            );

            return sorted;
        },
        onSettled: enableLoader ? () => loading.hideLoading() : undefined,
        staleTime: 1 * 60 * 1000,
    });
};
