import ApiClient from '../../services/apiClient';
import { IDocumentSearchModel } from '../../interfaces/IDocument';
import { useLoading } from '../../contexts/LoadingContext';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetMyDocumentsKey = ['get-mydocuments'];

export const useGetMyDocuments = (
    searchModel: IDocumentSearchModel,
    pageNumber: number,
    pageSize: number,
    enableLoader?: boolean
) => {
    const loading = useLoading();

    return useQuery({
        queryKey: useGetMyDocumentsKey,
        queryFn: async () => {
            if (enableLoader) {
                loading.showLoading();
            }

            const response = await _apiClient.getMyDocuments(searchModel, pageNumber, pageSize);
            const sorted = response.payload?.sort((a, b) =>
                b.createdDate < a.createdDate ? -1 : 1
            );

            return sorted;
        },
        onSettled: enableLoader ? () => loading.hideLoading() : undefined,
        staleTime: 1 * 60 * 1000,
    });
};

export const useGetMyDocumentsCount = (searchModel: IDocumentSearchModel) => {
    return useQuery({
        queryKey: ['get-mydocuments-count'],
        queryFn: async () => {
            const response = await _apiClient.getMyDocumentsCount(searchModel);

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve my documents count.');
            }

            return response.payload;
        },
    });
};
