import { DataScopesEnum, EmptyGuid, EntityTypes } from '../../utilities/Constants';
import { useEffect, useState } from 'react';

import { IEntityModel } from '../../interfaces/IAssertionDescriptor';
import { INoteModel } from '../../interfaces/INote';
import { NoteListTableHead } from './NoteListTableHead';
import { NoteListTableRow } from './NoteListTableRow';
import Sort from '../../stores/Sort';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';

interface INoteListTableProps {
    hideCategoryInfoText?: boolean;
    getEasyUpdateIconColorStyle?: (caseGuid: string, noteGuid?: string) => string;
    notes: INoteModel[];
    onEasyUpdateClick?: (caseGuid: string, noteGuid: string) => void;
    onRowAddClick?: (caseGuid: string) => void;
    onShowConfirmRemoveDialog: (note: INoteModel) => void;
    onSortComplete?: (notes: INoteModel[]) => void;
    onViewEditNote: (note: INoteModel, readOnly?: boolean) => void;
    onViewNotesCategoryAdditionalInfo: () => void;
    showCaseColumn?: boolean;
    showEasyUpdateColumn?: boolean;
    showDeleteButton?: boolean;
    showEditButton?: boolean;
    showExpertsColumn?: boolean;
    showTypeColumn?: boolean;
}

interface ISortableNoteModel extends INoteModel {
    allowLocalEditSortOrder: number;
}

export function NoteListTable({
    hideCategoryInfoText,
    getEasyUpdateIconColorStyle,
    notes,
    onRowAddClick,
    onEasyUpdateClick,
    onShowConfirmRemoveDialog,
    onSortComplete,
    onViewEditNote,
    onViewNotesCategoryAdditionalInfo,
    showCaseColumn,
    showEasyUpdateColumn,
    showDeleteButton,
    showEditButton,
    showExpertsColumn,
    showTypeColumn,
}: INoteListTableProps) {
    const [expandAllSummaries, setExpandAllSummaries] = useState<boolean>(false);
    const [sortedRows, setSortedRows] = useState<INoteModel[]>(notes);
    const [forceExpandedItemGuids, setForceExpandedItemGuids] = useState<string[]>([]);

    const sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let notes = cloneDeep(sortedRows);

        const caseExpertFilter = (assoc: IEntityModel) =>
            assoc.entityType.id === EntityTypes.CaseExpert;

        const hasExperts = (note: INoteModel) =>
            (note.associations?.filter(caseExpertFilter)?.length ?? 0) > 0;

        const noteToAllowLocalEditSortableMapper = (note: INoteModel): ISortableNoteModel => {
            let sortOrder = 0;
            if (note.actionTarget?.activeZoneDataScope?.id === DataScopesEnum.LocalBasic.Value) {
                sortOrder = 2;
            } else if (note.allowLocalEdit) {
                sortOrder = 1;
            }

            return {
                ...note,
                allowLocalEditSortOrder: sortOrder,
            };
        };

        if (notes) {
            if (key === 'modifiedDate') {
                notes.sort(Sort.compareDate(key, subKey, order));
            }
            if (key === 'allowLocalEdit') {
                const allowLocalEditSortableNotes = notes.map(noteToAllowLocalEditSortableMapper);
                const sortedNotes = allowLocalEditSortableNotes
                    .sort(Sort.compareValues('allowLocalEditSortOrder', undefined, order))
                    .map((sortedNote) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { allowLocalEditSortOrder, ...note } = sortedNote;
                        return note as INoteModel;
                    });

                notes = sortedNotes;
            }
            if (key === 'experts') {
                notes.sort((a, b) => {
                    const aHasExperts = hasExperts(a);
                    const bHasExperts = hasExperts(b);

                    if (order === 'asc') {
                        return aHasExperts === bHasExperts ? 0 : aHasExperts ? -1 : 1;
                    } else {
                        return aHasExperts === bHasExperts ? 0 : aHasExperts ? 1 : -1;
                    }
                });
            } else {
                notes.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }

        setSortedRows(notes);

        if (onSortComplete) {
            onSortComplete(notes);
        }
    };

    const getEasyUpdateIconClass = (item: INoteModel): string => {
        if (typeof getEasyUpdateIconColorStyle === 'function' && item.caseGuid) {
            return getEasyUpdateIconColorStyle(item.caseGuid);
        }

        return '';
    };

    const handleSortDataClick = (
        key: string,
        order: string,
        subKey?: string | undefined,
        subGrandKey?: string | undefined
    ) => {
        sortData(key, order, subKey, subGrandKey);
    };

    const handleEasyUpdateClick = (item: INoteModel) => {
        if (typeof onEasyUpdateClick === 'function' && item.caseGuid && item.guid) {
            onEasyUpdateClick(item.caseGuid!, item.guid);
        }
    };

    const handleExpandAllSummaries = (expandAll: boolean) => {
        if (expandAll) {
            setForceExpandedItemGuids(sortedRows.map((row) => row.guid ?? ''));
        } else {
            setForceExpandedItemGuids([]);
        }
        setExpandAllSummaries(expandAll);
    };

    useEffect(() => {
        setSortedRows(notes);
    }, [JSON.stringify(notes)]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <ExpandAllButton
                expandAll={expandAllSummaries}
                onExpandAll={handleExpandAllSummaries}
            />
            <table className="table table-sm">
                <NoteListTableHead
                    hideCategoryInfoText={hideCategoryInfoText ?? false}
                    onViewNotesCategoryAdditionalInfo={onViewNotesCategoryAdditionalInfo}
                    onSortDataClick={handleSortDataClick}
                    showEasyUpdateColumn={showEasyUpdateColumn}
                    showCaseColumn={showCaseColumn}
                    showExpertsColumn={showExpertsColumn}
                    showTypeColumn={showTypeColumn}
                />
                <tbody>
                    {sortedRows.map((item: INoteModel) => (
                        <NoteListTableRow
                            easyUpdateIconClass={getEasyUpdateIconClass(item)}
                            key={`${item.guid}-${item.caseGuid}`}
                            item={item}
                            isEmptyEntity={item.guid === EmptyGuid}
                            showDeleteButton={showDeleteButton}
                            showEditButton={showEditButton}
                            onAddClick={onRowAddClick}
                            onEasyUpdateClick={() => handleEasyUpdateClick(item)}
                            onShowConfirmRemoveDialog={() => onShowConfirmRemoveDialog(item)}
                            onViewEditNote={(note, readOnly) => onViewEditNote(note, readOnly)}
                            showCaseColumn={showCaseColumn}
                            showEasyUpdateColumn={showEasyUpdateColumn}
                            showExpertsColumn={showExpertsColumn}
                            showTypeColumn={showTypeColumn}
                            summaryRowIsExpanded={forceExpandedItemGuids.some(
                                (guid) => guid === item.guid
                            )}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

interface IExpandAllButtonProps {
    expandAll: boolean;
    onExpandAll: (expanded: boolean) => void;
}

function ExpandAllButton(props: IExpandAllButtonProps) {
    return (
        <button
            className="btn btn-no-bg text-gray"
            onClick={() => props.onExpandAll(!props.expandAll)}
            title={props.expandAll ? 'Collapse all Summary fields' : 'Expand all Summary fields'}
        >
            <i
                className={classNames('fa-regular', {
                    'fa-minus': props.expandAll,
                    'fa-plus': !props.expandAll,
                })}
            />
        </button>
    );
}
