export function CommentsButton(props: {
    onClick: (section: number) => void;
    section: number;
    show: boolean;
}) {
    if (!props.show) {
        return null;
    }

    return (
        <button className="btn btn-no-bg text-gray" onClick={() => props.onClick(props.section)}>
            <i className="fal fa-comment-lines" />
            &nbsp;Comments
        </button>
    );
}
