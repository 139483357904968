import { ILookupModel, IMultiSelectOptions } from '../../../../interfaces/ILookup';
import { useEffect, useState } from 'react';

import CheckmateSelect from '../../../shared/CheckmateSelect';
import { ICreateCaseExpertModel } from '../../../../interfaces/ICaseExpert';
import { IExpertListModel } from '../../../../interfaces/IExpert';
import { IValidation } from '../../../../interfaces/IError';
import { Modal } from 'react-bootstrap';
import UIHelper from '../../../../utilities/UIHelper';
import { useExpertRetainedByTypesLookup } from '../../../../shared/react-query-hooks/useExpertRetainedByTypesLookup';
import { useExpertTypesLookup } from '../../../../shared/react-query-hooks/useExpertTypesLookup';
import { useExpertsList } from '../../../../pages/experts/useExpertsList';

export interface IAddCaseExpertDialogProps {
    caseId: string;
    open: boolean;
    title: string;
    validation?: IValidation;
    onSubmitClick: (caseExpert: ICreateCaseExpertModel) => void;
    onCancelClick: () => void;
}

export function AddCaseExpertDialog(props: IAddCaseExpertDialogProps) {
    const [selectedExpert, setSelectedExpert] = useState<IExpertListModel | undefined>();
    const [selectedRetainedByType, setSelectedRetainedByType] = useState<
        ILookupModel | undefined
    >();
    const [validation, setValidation] = useState<IValidation>({});

    const experts = useExpertsList();
    const retainedByTypes = useExpertRetainedByTypesLookup();
    const expertTypesLookup = useExpertTypesLookup();

    const validateExpert = (expert: IExpertListModel | undefined) => {
        return !expert ? ['Required'] : undefined;
    };
    const validateRetainedByType = (retainedByType: ILookupModel | undefined) => {
        return !retainedByType ? ['Required'] : undefined;
    };

    const validateForm = () => {
        const violations: { expert?: string[]; retainedByType?: string[] } = {};

        const expertValidation = validateExpert(selectedExpert);
        if (expertValidation) {
            violations.expert = expertValidation;
        }

        const retainedByTypeValidation = validateRetainedByType(selectedRetainedByType);
        if (retainedByTypeValidation) {
            violations.retainedByType = retainedByTypeValidation;
        }

        return violations;
    };

    const handleSubmitClick = () => {
        const validationResults = validateForm();

        if (Object.values(validationResults).filter((v) => !!v).length > 0) {
            setValidation(validationResults);
            return;
        }

        props.onSubmitClick({
            caseId: props.caseId,
            expertId: selectedExpert?.guid ?? '',
            expertRetainedByTypeId: selectedRetainedByType?.id ?? 0,
            caseExpertActivities: [],
        });
    };

    const handleCancelClick = () => {
        props.onCancelClick();
    };

    const handleSelectedExpertChange = (selected: { label: string; value: string }) => {
        const expert = (experts.data ?? []).find((expert) => expert.guid === selected?.value);
        setSelectedExpert(expert);

        const expertValidation = validateExpert(expert);
        setValidation((prev) => ({ ...prev, expert: expertValidation ?? [] }));
    };

    const handleSelectedRetainedByTypeChange = (selected: { label: string; value: string }) => {
        const retainedBy = (retainedByTypes.data ?? []).find(
            (type) => type.id.toString() === selected?.value
        );
        setSelectedRetainedByType(retainedBy);

        const retainedByValidation = validateRetainedByType(retainedBy);
        setValidation((prev) => ({ ...prev, retainedByType: retainedByValidation ?? [] }));
    };

    const expertTypeName =
        expertTypesLookup.data?.find((et) => et.id === selectedExpert?.expertTypeId)?.displayName ??
        '';

    useEffect(() => {
        if (experts.isError || retainedByTypes.isError) {
            setValidation((prev) => ({ ...prev, model: ['Failed to load experts'] }));
        }
    }, [experts.isError, retainedByTypes.isError]);

    return (
        <Modal centered show={props.open} backdrop={false}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    {props.validation?.model && (
                        <div>
                            <span className="text-danger">{props.validation?.model}</span>
                        </div>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div className="form-group">
                            <label className="control-label">Expert*</label>
                            <CheckmateSelect
                                isLoading={experts.isLoading}
                                options={
                                    experts.data?.map(
                                        (expert) =>
                                            ({
                                                label: UIHelper.formatExpertName(expert),
                                                value: expert.guid,
                                            } as IMultiSelectOptions)
                                    ) ?? []
                                }
                                name="expert"
                                value={
                                    selectedExpert
                                        ? [
                                              {
                                                  label: UIHelper.formatExpertName(selectedExpert),
                                                  value: selectedExpert.guid,
                                              } as IMultiSelectOptions,
                                          ]
                                        : []
                                }
                                onChange={handleSelectedExpertChange}
                            />
                            {validation.expert && (
                                <div className="text-danger">{validation.expert}</div>
                            )}
                        </div>
                        {selectedExpert! && selectedExpert?.expertTypeId > 0 && (
                            <div
                                className="form-group"
                                style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                            >
                                <label className="control-label">Expert Type</label>
                                <span>{expertTypeName}</span>
                            </div>
                        )}
                        <div className="form-group">
                            <label className="control-label">Retained by*</label>
                            <CheckmateSelect
                                isLoading={retainedByTypes.isLoading}
                                options={
                                    retainedByTypes.data?.map(
                                        (lookup) =>
                                            ({
                                                id: lookup.id,
                                                label: lookup.displayName,
                                                value: lookup.id.toString(),
                                            } as IMultiSelectOptions)
                                    ) ?? []
                                }
                                name="retainedByType"
                                value={
                                    selectedRetainedByType
                                        ? [
                                              {
                                                  label: selectedRetainedByType.displayName,
                                                  value: selectedRetainedByType.id.toString(),
                                              } as IMultiSelectOptions,
                                          ]
                                        : []
                                }
                                onChange={handleSelectedRetainedByTypeChange}
                            />
                            {validation.retainedByType && (
                                <div className="text-danger">{validation.retainedByType}</div>
                            )}
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-orange float-end"
                    onClick={handleSubmitClick}
                    disabled={experts.isError || retainedByTypes.isError}
                >
                    Save
                </button>
                <button className="btn btn-default float-end " onClick={handleCancelClick}>
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    );
}
