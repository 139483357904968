import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ValidateUtils from '../../shared/validations';
import ParamUtils from '../../shared/ParamUtilities';
import CheckmateApiClient from '../../services/apiClient';
import { IValidation } from '../../interfaces/IError';
import { Help } from '../../components/shared/Help';
import { ApplicationHelpType } from '../../utilities/Constants';
let _apiClient = new CheckmateApiClient();

interface IResetPasswordState {
    validation: IValidation;
    redirection: IRedirect;
    resetPassword: IPassword;
    resetPasswordMsg: string;
}

interface IPassword {
    password: string;
    confirmPassword: string;
}

interface IRedirect {
    redirect?: boolean;
    redirectedPage: string;
    resetSuccess?: boolean;
}

export class ResetPassword extends React.Component<any, IResetPasswordState> {
    constructor(props: any) {
        super(props);

        this.state = {
            validation: {
                errors: [],
                passwordErrors: [],
                confirmPasswordErrors: [],
            },
            redirection: {
                redirect: false,
                redirectedPage: '/',
                resetSuccess: false,
            },
            resetPassword: {
                password: '',
                confirmPassword: '',
            },
            resetPasswordMsg: '',
        };
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setModel(event.target.id, event.target.value);
    };

    private setModel(id: string, value: any) {
        let { resetPassword, validation } = this.state;
        switch (id) {
            case 'password':
                if (resetPassword.confirmPassword === '')
                    validation.passwordErrors = ValidateUtils.validatePasswordRequirements(value);
                else
                    validation.confirmPasswordErrors = ValidateUtils.matchPassword(
                        value,
                        resetPassword.confirmPassword
                    );
                resetPassword.password = value;
                break;
            case 'confirmpassword':
                validation.confirmPasswordErrors = ValidateUtils.matchPassword(
                    resetPassword.password,
                    value
                );
                resetPassword.confirmPassword = value;
                break;
            default:
                break;
        }
        this.setState({
            validation: validation,
            resetPassword: resetPassword,
        });
    }

    handleResetPassword = async () => {
        try {
            let { resetPassword, validation } = this.state;
            if (
                validation.passwordErrors.length > 0 ||
                validation.confirmPasswordErrors.length > 0
            ) {
                return;
            }

            let userid: string = ParamUtils.getParameterByName('userid');
            let code: string = ParamUtils.getParameterByName('code');

            var response = await _apiClient.resetPassword(userid, code, resetPassword.password);
            if (response.payload) {
                this.setState({
                    resetPasswordMsg: response.payload.message,
                    redirection: {
                        redirectedPage: '/',
                        resetSuccess: true,
                    },
                });
            } else {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors),
                });
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    handleCancelResetPassword = () => {
        this.setState({
            redirection: {
                redirect: true,
                redirectedPage: '/', // TO DO: - Where should we redirect on cancel password reset?
            },
        });
    };

    handleOkResponse = () => {
        this.setState({
            redirection: {
                redirect: true,
                redirectedPage: '/', // TO DO: - Where should we redirect on successful password reset?
            },
        });
    };

    render() {
        if (this.state.redirection.redirect) {
            return <Navigate to={this.state.redirection.redirectedPage} />;
        }

        return (
            <Modal
                centered
                show={true}
                dialogClassName="registration-options-dialog"
                onHide={this.handleCancelResetPassword}
                backdrop={false}
            >
                <Modal.Header>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.redirection.resetSuccess ? (
                        <div>
                            <p>{this.state.resetPasswordMsg}</p>
                            <div className=" dialog-btn-div">
                                <button
                                    type="button"
                                    className="btn btn-black float-end"
                                    data-dismiss="modal"
                                    onClick={this.handleOkResponse}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <div className="form-group">
                                    <ul className="validation-list">
                                        {this.state.validation.passwordErrors
                                            ? this.state.validation.passwordErrors.map(
                                                  (error: string, i: number) => {
                                                      return <li key={i}>{error}</li>;
                                                  }
                                              )
                                            : null}
                                    </ul>
                                    <label>Password:</label>
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Password Requirements"
                                        helpText="* Minimum of 8 characters\n* Must Contain at least 1 lowercase letter\n* Must Contain at least 1 capital letter\n* Must Contain at least 1 number\n"
                                    />
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        value={this.state.resetPassword.password}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <ul className="validation-list">
                                        {this.state.validation.confirmPasswordErrors
                                            ? this.state.validation.confirmPasswordErrors.map(
                                                  (error: string, i: number) => {
                                                      return <li key={i}>{error}</li>;
                                                  }
                                              )
                                            : null}
                                    </ul>
                                    <label>Confirm Password:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmpassword"
                                        value={this.state.resetPassword.confirmPassword}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <ul className="validation-list">
                                    {this.state.validation.errors
                                        ? this.state.validation.errors.map(
                                              (error: string, i: number) => {
                                                  return <li key={i}>{error}</li>;
                                              }
                                          )
                                        : null}
                                </ul>
                            </div>
                            <div className="dialog-btn-div">
                                <button
                                    type="button"
                                    className="btn btn-default float-end"
                                    data-dismiss="modal"
                                    onClick={this.handleCancelResetPassword}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-black float-end"
                                    data-dismiss="modal"
                                    onClick={this.handleResetPassword}
                                >
                                    Reset Password
                                </button>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}
