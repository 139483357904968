import ApiClient from '../../services/apiClient';
import { LookupDataEnums } from '../../utilities/Constants';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useUsStatesLookup = () => {
    return useQuery({
        queryKey: ['us-states-lookup'],
        queryFn: async () => {
            const response = await _apiClient.getLookupData(LookupDataEnums.USStates);

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve us states.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        cacheTime: Infinity,
        staleTime: Infinity,
    });
};
