import * as React from 'react';

import {
    AssertionDescriptorValueDisplayTypes,
    CaseFileStaticItems,
    QueryToolModeEnum,
    QueryTypes,
} from '../../utilities/Constants';
import {
    IQueryColumnDefinitionModel,
    IQueryFilterCriteria,
    IQueryResultModel,
} from '../../interfaces/IQuery';

import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { SortableHeader } from '../../components/shared/SortableHeader';
import Table from 'react-bootstrap/Table';

export interface ICaseDetailsResultsProps {
    caseResults: IQueryResultModel;
    enableEasyUpdate: boolean;
    getEasyUpdateIconColorStyle?: (caseGuid: string, noteGuid?: string) => string;
    mode: number;
    onCheckedChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        item?: IQueryFilterCriteria
    ) => void;
    onEasyUpdate: (caseGuid: string, noteGuid?: string) => void;
    onSortData: (
        key: string,
        order: string,
        subKey?: string,
        subGrandKey?: string,
        dataItem?: number
    ) => void;
    selectAllCasesForRedirect?: boolean;
    selectedGuidsForRedirect: string[];
    showSelectAllCheckbox?: boolean;
}

const InternalDatabaseFields_PackageSettlementGuid = -4;

export function CaseDetailsResults(props: ICaseDetailsResultsProps) {
    return (
        <>
            {props.caseResults.columns && props.caseResults.values ? (
                <Table responsive>
                    <thead style={{ verticalAlign: 'middle' }}>
                        <tr>
                            {props.mode === QueryToolModeEnum.Redirect &&
                                props.showSelectAllCheckbox && (
                                    <th>
                                        <input
                                            type="checkbox"
                                            name="chkSelectAllCases"
                                            className="form-check-input"
                                            checked={props.selectAllCasesForRedirect ?? false}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                props.onCheckedChange(e);
                                            }}
                                        />
                                    </th>
                                )}
                            {props.caseResults.columns
                                .filter(
                                    (x) =>
                                        (x.index ?? 0) > 0 &&
                                        x.staticItemId !==
                                            InternalDatabaseFields_PackageSettlementGuid
                                )
                                .map((item: IQueryColumnDefinitionModel) => {
                                    return (
                                        <SortableHeader
                                            headerComponent={
                                                <>
                                                    {item.displayName?.toUpperCase() ===
                                                    'LOCKED' ? (
                                                        <i className="fal fa-lock color-gray" />
                                                    ) : (
                                                        item.displayName
                                                    )}
                                                </>
                                            }
                                            noWrap={(item.displayName?.length ?? 0) < 20}
                                            sortKey={item.index!.toString()}
                                            subKey={item.dataType}
                                            dataObjectIdentifier={QueryTypes.CaseDetails.Value}
                                            onSort={props.onSortData}
                                        />
                                    );
                                })}
                            {props.enableEasyUpdate ? (
                                <th className="text-center">
                                    <i className="fal fa-edit" />
                                </th>
                            ) : null}
                        </tr>
                    </thead>
                    <tbody>
                        <CaseDetailRows {...props} />
                    </tbody>
                </Table>
            ) : null}
        </>
    );
}

const QueryStaticItemFields = Object.assign({}, CaseFileStaticItems);
function CaseDetailRows(props: ICaseDetailsResultsProps) {
    const rows = [];

    const getEasyUpdateIconClass = (caseGuid: string): string => {
        if (typeof props.getEasyUpdateIconColorStyle === 'function' && caseGuid) {
            return props.getEasyUpdateIconColorStyle(caseGuid);
        }

        return '';
    };

    if (props.caseResults.values) {
        const columnDataTypes: string[] = [];
        let packageDealIndex = -1;
        let packageDealGuidIndex = -1;
        let otherDefenseCounselIndex = -1;

        if (props.caseResults.columns) {
            for (let i = 0; i < props.caseResults.columns.length; i++) {
                const col = props.caseResults.columns[i];
                if (col && col.dataType) {
                    columnDataTypes.push(col.dataType!);
                } else {
                    columnDataTypes.push('');
                }

                if (col.staticItemId === QueryStaticItemFields.OtherDefenseCounsels.Id) {
                    otherDefenseCounselIndex = i;
                }
                if (col.staticItemId === QueryStaticItemFields.PackageSettlement.Id) {
                    packageDealIndex = i;
                } else if (col.staticItemId === InternalDatabaseFields_PackageSettlementGuid) {
                    packageDealGuidIndex = i;
                }
            }
        }

        for (let rowIndex = 0; rowIndex < props.caseResults.values.length; rowIndex++) {
            // Index 0 is ALWAYS Case Guid;
            // Index 1 is Case Name.

            const row = props.caseResults.values[rowIndex];
            const caseGuidColIndex = 0;
            const caseNameColIndex = 1; // Index 1 is ALWAYS Case Name. Add a link to Case File like everywhere else
            const caseGuid: string = row[caseGuidColIndex];
            const columns = [];

            if (props.mode === QueryToolModeEnum.Redirect && props.showSelectAllCheckbox) {
                let hasPackageDeal = false;

                if (row[packageDealIndex]) hasPackageDeal = true;

                if (!hasPackageDeal)
                    columns.push(
                        <td>
                            <input
                                type="checkbox"
                                name={caseGuid}
                                className="form-check-input"
                                checked={
                                    props.selectAllCasesForRedirect ||
                                    props.selectedGuidsForRedirect.findIndex(
                                        (x) => x === caseGuid
                                    ) >= 0
                                }
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    props.onCheckedChange(e);
                                }}
                            />{' '}
                        </td>
                    );
                else columns.push(<td />);
            }

            for (let colIndex = 1; colIndex < row.length; colIndex++) {
                if (colIndex === caseNameColIndex)
                    columns.push(
                        <td className="col-sm-2">
                            <Link
                                target="_blank"
                                to={LocalRoutes.CaseFile.replace(':guid', caseGuid)}
                            >
                                {row[colIndex]}
                            </Link>
                        </td>
                    );
                else if (colIndex === packageDealIndex) {
                    const packageDealGuid = row[packageDealGuidIndex];
                    if (packageDealGuid)
                        columns.push(
                            <td>
                                <Link
                                    target="_blank"
                                    to={LocalRoutes.PackageSettlementDetails.replace(
                                        ':guid',
                                        packageDealGuid
                                    )}
                                >
                                    {row[colIndex]}
                                </Link>
                            </td>
                        );
                    else columns.push(<td>{row[colIndex]}</td>);
                } else if (colIndex === packageDealGuidIndex) {
                    // Dont include in display
                } else if (colIndex === otherDefenseCounselIndex) {
                    const counsels = row[colIndex].split('|');
                    const parts = [];
                    for (let i = 0; i < counsels.length; i++) {
                        if (i == 0) {
                            parts.push(<span> {counsels[i]} </span>);
                        } else {
                            parts.push(
                                <span>
                                    <br />
                                    {counsels[i]}
                                </span>
                            );
                        }
                    }
                    columns.push(<td>{parts}</td>);
                } else {
                    columns.push(
                        <td>{getFormattedValue(row[colIndex], columnDataTypes[colIndex])}</td>
                    );
                }
            }
            if (props.enableEasyUpdate) {
                columns.push(
                    <td width={85} className="center">
                        {' '}
                        <button
                            className="btn-no-bg"
                            onClick={() => {
                                props.onEasyUpdate(caseGuid);
                            }}
                        >
                            {' '}
                            <i className={`fal fa-edit ${getEasyUpdateIconClass(caseGuid)} `} />
                        </button>
                    </td>
                );
            }
            rows.push(<tr key={rowIndex}>{columns}</tr>);
        }
    } else {
        rows.push(<tr />);
    }

    return <>{rows}</>;
}

const getFormattedValue = (value: string, formatType?: string) => {
    if (value === null) return null;
    if (!formatType) return value;

    switch (formatType.toLowerCase()) {
        case AssertionDescriptorValueDisplayTypes.Currency.Name.toLowerCase():
            return '$' + value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        case AssertionDescriptorValueDisplayTypes.Percentage.Name.toLowerCase():
            return value + '%';
        default:
            return value;
    }
};
