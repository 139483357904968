import { AlertTypesEnum, IQueryExportCountModel } from '../../utilities/Constants';
import { IOrganizationRoleModel, IUserModel } from '../../interfaces/IUser';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Stack } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import Auth from '../../stores/authentication';
import { IAlertUserUpdateModel } from '../../interfaces/INote';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { SignalRMethods } from '../../utilities/SignalR';
import { cloneDeep } from 'lodash';
import { useAuthContext } from '../../contexts/AuthContext';
import { useGetUserUnreadQueryExports } from '../../shared/react-query-hooks/useGetUserUnreadQueryExports';
import { useSignalRContext } from '../../contexts/SignalRContext';

export function UserUnreadQueryExportStatus(props: {
    user: IUserModel;
    onZoneChange: (org: IOrganizationRoleModel, redirectPath?: string) => void;
}) {
    const { user } = useAuthContext();
    const { alertHub } = useSignalRContext();

    const [zonesWithUnreadQueryExports, setZonesWithUnreadQueryExports] = useState<
        IQueryExportCountModel[]
    >([]);
    const [showZoneSelectionModal, setShowZoneSelectionModal] = useState<boolean>(false);
    const [showSwitchingZonesModal, setShowSwitchingZonesModal] = useState<boolean>(false);

    const navigate = useNavigate();
    const { data: userUnreadQueryExports, status: unreadQueryRequestStatus } =
        useGetUserUnreadQueryExports(props.user);

    const bindSignalrEvents = () => {
        const handleAvailableReports = (payload: IAlertUserUpdateModel) => {
            const { alertUpdates } = payload;

            setZonesWithUnreadQueryExports((prev) => {
                const prevCopy = cloneDeep(prev);

                alertUpdates.forEach((update) => {
                    const orgId = update.organization.guid ?? '';

                    update.changeByType
                        .filter((changeByType) => changeByType.type === AlertTypesEnum.Report)
                        .forEach((changeByType) => {
                            const zoneForThisOrgId = prevCopy.find((zone) => zone.orgId === orgId);
                            if (zoneForThisOrgId) {
                                zoneForThisOrgId.count += changeByType.change;
                            } else {
                                prevCopy.push({ orgId, count: changeByType.change });
                            }
                        });
                });

                return [...prevCopy];
            });
        };

        if (alertHub !== undefined) {
            console.log(
                `SignalR - Alert Hub => Listening for ${SignalRMethods.SendQueryExportNotification}`
            );
            alertHub?.on(SignalRMethods.SendQueryExportNotification, handleAvailableReports);
        }
    };

    const unbindSignalrEvents = () => {
        console.log(
            `SignalR - Alert Hub /Stopped listening for ${SignalRMethods.SendQueryExportNotification}`
        );
        alertHub?.off(SignalRMethods.SendQueryExportNotification);
    };

    useEffect(() => {
        bindSignalrEvents();
        return unbindSignalrEvents;
    }, [alertHub]);

    const handleIconClick = (e: React.MouseEvent<HTMLElement>) => {
        const defaultOrg = Auth.getUserDefaultOrganization();

        const moreThanOneZoneHasUnreadExports =
            (zonesWithUnreadQueryExports.filter((zone) => zone.count > 0)?.length ?? 0) > 1;

        const zoneGuidsWithAlerts = zonesWithUnreadQueryExports.map((zone) => zone.orgId);

        const zoneWithChangesIsNotCurrentZone =
            (zoneGuidsWithAlerts?.length ?? 0) === 1 &&
            defaultOrg?.guid !== zoneGuidsWithAlerts?.[0];

        if (zoneWithChangesIsNotCurrentZone) {
            e.preventDefault();
            setShowSwitchingZonesModal(true);
        } else if (moreThanOneZoneHasUnreadExports) {
            e.preventDefault();
            setShowZoneSelectionModal(true);
        }
    };

    const handleSwitchingZonesSubmit = () => {
        const firstAndOnlyMatchingOrg = zonesWithUnreadQueryExports?.[0]?.orgId;
        const chosenOrg = user.organizationRoles?.find(
            (org) => org.guid === firstAndOnlyMatchingOrg
        );

        if (chosenOrg) {
            props.onZoneChange(chosenOrg, LocalRoutes.MyDocuments);
        } else {
            // todo: do something because user is trying to access docs in a zone that it doesn't have access to
            //       ...shouldn't even occur
        }
    };

    const handleZoneSelection = (zoneGuid: string) => {
        const defaultZone = user.organizationRoles?.find((org) => org.default);
        const isCurrentZone = zoneGuid === defaultZone?.guid;

        if (isCurrentZone) {
            setShowZoneSelectionModal(false);
            navigate(LocalRoutes.MyDocuments);
            return;
        }

        const chosenOrg = user.organizationRoles?.find((org) => org.guid === zoneGuid);

        if (chosenOrg) {
            props.onZoneChange(chosenOrg, LocalRoutes.MyDocuments);
            setShowZoneSelectionModal(false);
        } else {
            // todo: do something because user is trying to access docs in a zone that it doesn't have access to
            //       ...shouldn't even occur
        }
    };

    useEffect(() => {
        if (unreadQueryRequestStatus === 'success') {
            setZonesWithUnreadQueryExports(userUnreadQueryExports ?? []);
        }
    }, [unreadQueryRequestStatus]);

    if (!zonesWithUnreadQueryExports.some((zone) => zone.count > 0)) {
        return null;
    }

    return (
        <>
            <Link
                to={LocalRoutes.MyDocuments}
                className="align-self-center"
                style={{ marginLeft: 5 }}
                onClick={handleIconClick}
            >
                <i className="fal fa-file-exclamation color-yellow" />
            </Link>
            <SwitchingZonesModal
                onCancel={() => setShowSwitchingZonesModal(false)}
                onClose={() => setShowSwitchingZonesModal(false)}
                onSubmit={handleSwitchingZonesSubmit}
                open={showSwitchingZonesModal}
            />
            <ZoneSelectionModal
                onCancel={() => setShowZoneSelectionModal(false)}
                onClose={() => setShowZoneSelectionModal(false)}
                onSubmit={handleZoneSelection}
                open={showZoneSelectionModal}
                zoneGuids={zonesWithUnreadQueryExports.map((zone) => zone.orgId)}
            />
        </>
    );
}

export interface ISwitchingZonesModalProps {
    onCancel: () => void;
    onClose: () => void;
    onSubmit: () => void;
    open: boolean;
}

function SwitchingZonesModal(props: ISwitchingZonesModalProps) {
    return (
        <Modal centered show={props.open} onHide={props.onClose} backdrop>
            <Modal.Header>
                <Modal.Title>Switch Zones</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack gap={3}>
                    <div>
                        The documents you are accessing belong to a different zone. <br />
                        Would you like to switch zones?
                    </div>
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-orange" onClick={props.onSubmit}>
                    Switch
                </button>
                <button className="btn btn-default" onClick={props.onCancel}>
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export interface IZoneSelectionModalProps {
    onCancel: () => void;
    onClose: () => void;
    onSubmit: (zoneGuid: string) => void;
    zoneGuids: string[];
    open: boolean;
}

function ZoneSelectionModal(props: IZoneSelectionModalProps) {
    const { user } = useAuthContext();
    const [selectedZoneGuid, setSelectedZoneGuid] = useState<string>();

    const handleZoneSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedZoneGuid(e.target.value);
    };

    const handleSubmit = () => {
        if (selectedZoneGuid) {
            props.onSubmit(selectedZoneGuid);
        }
    };

    return (
        <Modal centered show={props.open} onHide={props.onClose} backdrop>
            <Modal.Header>
                <Modal.Title>Select a Zone</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack gap={3}>
                    <div>
                        Multiple zones have documents ready to view.
                        <br />
                        Which zone would you like to view documents for?
                    </div>
                    <ul className="mx-5">
                        {user.organizationRoles
                            ?.filter((org) => props.zoneGuids.includes(org.guid ?? ''))
                            .map((org) => {
                                return (
                                    <li key={org.guid}>
                                        <Stack
                                            direction="horizontal"
                                            gap={1}
                                            style={{ alignItems: 'center' }}
                                        >
                                            <input
                                                name="zones"
                                                type="radio"
                                                id={org.guid}
                                                value={org.guid}
                                                onChange={handleZoneSelection}
                                                checked={selectedZoneGuid === org.guid}
                                                style={{ appearance: 'auto' }}
                                            />
                                            <label htmlFor={org.guid} className="form-check-label">
                                                {org.shortName}
                                            </label>
                                        </Stack>
                                    </li>
                                );
                            })}
                    </ul>
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-orange"
                    onClick={handleSubmit}
                    disabled={!selectedZoneGuid}
                >
                    Go
                </button>
                <button className="btn btn-default" onClick={props.onCancel}>
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    );
}
