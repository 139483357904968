import { useSearchParams } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { BoardCaseDetail } from '../board/BoardCaseDetail';

function BoardCaseDetailWrapper(props: { user: IUserModel }) {
    const [searchParams] = useSearchParams();

    const type: string = searchParams.get('preferences') || '';
    const preferences: string = searchParams.get('preferences') || '';

    return <BoardCaseDetail user={props.user} type={type} preferences={preferences} />;
}

export default BoardCaseDetailWrapper;
