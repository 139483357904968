import CheckmateApiClient from '../../../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new CheckmateApiClient();

export const useGetCaseExpertKey = 'get-case-expert';

export const useCaseExpertFetch = (id?: string) =>
    useQuery({
        queryKey: [useGetCaseExpertKey, id],
        queryFn: async ({ queryKey }) => {
            const [, caseExpertGuid] = queryKey;
            const response = await _apiClient.getCaseExpertById(caseExpertGuid ?? '');

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve this case expert.');
            }
            return response.payload;
        },
        enabled: !!id,
    });
