import * as React from 'react';

import { AlertListTypeEnum, StrategyTypes } from '../../../../utilities/Constants';

import Authorization from '../../../../stores/Authorization';
import { DataMenuItem } from './DataMenuItem';
import { IOrganizationRoleModel } from '../../../../interfaces/IUser';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { MenuItemNavLinkWithIcon } from '../../MenuItemNavLinkWithIcon';
import { useAuthContext } from '../../../../contexts/AuthContext';

interface INavigationMenuProps {
    onZoneChange: (org: IOrganizationRoleModel, redirectPath?: string) => void;
}

export function NavigationMenu(props: INavigationMenuProps) {
    const { user, isLoggedIn } = useAuthContext();

    const showCaseMenuItem =
        Authorization.isAuthorizedToRoute(LocalRoutes.Cases, user) ||
        Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, user) ||
        Authorization.isAuthorizedToRoute(LocalRoutes.PackageSettlements, user);

    if (!user.profile) {
        return <div className="float-end" style={{ margin: '38px' }} />;
    }

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                gap: '4vw',
                paddingLeft: '4vw',
            }}
        >
            {showCaseMenuItem && (
                <div className="innerContainer">
                    <button
                        className="btn-black bold"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        CASES
                    </button>
                    <div className="dropdown-menu adjust-left">
                        {isLoggedIn && (
                            <div>
                                {Authorization.isAuthorizedToRoute(LocalRoutes.Cases, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-list"
                                        label="CASE LIST"
                                        to={LocalRoutes.Cases}
                                    />
                                )}
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.CaseEasyUpdate,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-edit"
                                        label="EASY UPDATE"
                                        to={LocalRoutes.CaseEasyUpdate.replace(':guid', '')}
                                    />
                                )}
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.PackageSettlements,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-box-usd"
                                        label="PACKAGE DEALS"
                                        to={LocalRoutes.PackageSettlements}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <DataMenuItem onZoneChange={props.onZoneChange} />
            {Authorization.showStrategyMenuItem(user) && (
                <div className="innerContainer">
                    <button className="btn-black bold" data-bs-toggle="dropdown">
                        STRATEGY
                    </button>
                    <div className="dropdown-menu adjust-left">
                        {isLoggedIn ? (
                            <div>
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.StrategicPlan,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-flag"
                                        label="STRATEGIC PLAN"
                                        to={LocalRoutes.StrategicPlan}
                                    />
                                )}
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.StrategyExecutionPlan,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-users"
                                        label="EXECUTION PLAN"
                                        to={LocalRoutes.StrategyExecutionPlan}
                                    />
                                )}
                                {Authorization.isAuthorizedToStrategyType(
                                    StrategyTypes.PlaintiffsFirm.Name,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-building"
                                        label="PLAINTIFFS FIRMS"
                                        to={LocalRoutes.Strategy.replace(
                                            ':type',
                                            StrategyTypes.PlaintiffsFirm.Value.toString()
                                        )}
                                    />
                                )}
                                {Authorization.isAuthorizedToStrategyType(
                                    StrategyTypes.Jurisdiction.Name,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-gavel"
                                        label="JURISDICTIONS"
                                        to={LocalRoutes.Strategy.replace(
                                            ':type',
                                            StrategyTypes.Jurisdiction.Value.toString()
                                        )}
                                    />
                                )}
                                {Authorization.isAuthorizedToStrategyType(
                                    StrategyTypes.Projects.Name,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-chart-gantt"
                                        label="PROJECTS"
                                        to={LocalRoutes.Projects}
                                    />
                                )}
                                {Authorization.isAuthorizedToStrategyType(
                                    StrategyTypes.LocalCounsel.Name,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-map-marker-smile"
                                        label="LOCAL COUNSEL"
                                        to={LocalRoutes.Strategy.replace(
                                            ':type',
                                            StrategyTypes.LocalCounsel.Value.toString()
                                        )}
                                    />
                                )}
                                {Authorization.isAuthorizedToStrategyType(
                                    StrategyTypes.Experts.Name,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-head-side-brain"
                                        label="EXPERTS"
                                        to={LocalRoutes.Experts}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            )}

            {isLoggedIn && Authorization.showReviewMenuItem(user) && (
                <div className="innerContainer">
                    <button className="btn-black bold" data-bs-toggle="dropdown">
                        REVIEW
                    </button>
                    <div className="dropdown-menu adjust-left">
                        {Authorization.isAuthorizedToRoute(LocalRoutes.Alerts, user) && (
                            <MenuItemNavLinkWithIcon
                                iconClass="fal fa-bell-exclamation"
                                label="MY ALERT LIST"
                                to={LocalRoutes.Alerts.replace(
                                    ':type',
                                    AlertListTypeEnum.MyAlertList.toString()
                                )}
                            />
                        )}
                        {Authorization.isAuthorizedToRoute(LocalRoutes.Alerts, user) && (
                            <MenuItemNavLinkWithIcon
                                iconClass="fal fa-bells"
                                label="ZONE ALERT LIST"
                                to={LocalRoutes.Alerts.replace(
                                    ':type',
                                    AlertListTypeEnum.ZoneAlertList.toString()
                                )}
                            />
                        )}
                        {Authorization.isAuthorizedToRoute(LocalRoutes.Trends, user) && (
                            <MenuItemNavLinkWithIcon
                                iconClass="fal fa-chart-line-down"
                                label="TRENDS"
                                to={LocalRoutes.Trends}
                            />
                        )}
                        {Authorization.isAuthorizedToRoute(LocalRoutes.Snapshot, user) && (
                            <MenuItemNavLinkWithIcon
                                iconClass="fal fa-camera-retro"
                                label="SNAPSHOT"
                                to={LocalRoutes.Snapshot}
                            />
                        )}
                        {Authorization.isAuthorizedToRoute(LocalRoutes.Board, user) && (
                            <MenuItemNavLinkWithIcon
                                iconClass="fal fa-game-board"
                                label="BOARD"
                                to={LocalRoutes.Board}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
