import * as React from 'react';

import { IOrganizationDependencyModel, IOrganizationModel } from '../../interfaces/IOrganization';
import { LookupDataEnums, OrganizationTypesEnum } from '../../utilities/Constants';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { IAddressModel } from '../../interfaces/IAddress';
import { ILookupModel } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Navigate } from 'react-router-dom';
import { OrganizationAddUpdateModal } from '../../components/organization/OrganizationAddUpdateModal';
import ValidateUtils from '../../shared/validations';

let _apiClient = new ApiClient();

interface IOrganizationExperienceDetailProps {
    portfolioGuid?: string;
    user: IUserModel;
}

interface IOrganizationExperienceDetailState {
    portfolioOrganization: IOrganizationModel; // This is the actual Org you will be creating... But then all other Org Types will be dependencies.
    dependentOrganizationList: IOrganizationDependencyModel[];
    createNew: boolean;
    validation: IValidation;
    redirect: boolean;
    displayAddressDetail: boolean;
    addresses: IAddressModel[];
    selectedAddress: IAddressModel;
    editAddressIndex: number;
    organizationDependencyTypes: ILookupModel[];
    displayDependencyDialog: boolean;
    addingUpdatingNewDependentOrganization: boolean;
    newDependentOrganizationName: string;
    currentDependentOrganizationTypeId: number;
    currentDependentOrganizationTypeName: string;
    currentDependentOrganization: IOrganizationDependencyModel;
    addDependentOrganization: boolean;
    pendingResponse: boolean;
    addPrimaryFirm: boolean;
    enableCaseFileEdit: boolean;
    enablePlanningExecution: boolean;
}

export class OrganizationExperienceDetail extends React.Component<
    IOrganizationExperienceDetailProps,
    IOrganizationExperienceDetailState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            portfolioOrganization: {},
            dependentOrganizationList: [],
            createNew: false,
            validation: {},
            redirect: false,
            displayAddressDetail: false,
            addresses: [],
            selectedAddress: {},
            editAddressIndex: -1,
            organizationDependencyTypes: [],
            displayDependencyDialog: false,
            addingUpdatingNewDependentOrganization: false,
            newDependentOrganizationName: '',
            currentDependentOrganizationTypeId: 0,
            currentDependentOrganizationTypeName: '',
            currentDependentOrganization: {},
            addDependentOrganization: false,
            pendingResponse: false,
            addPrimaryFirm: true,
            enableCaseFileEdit: false,
            enablePlanningExecution: false,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.ZoneDetail, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadOrganizationDependencyTypes();
        let portfolioGuid = this.props.portfolioGuid;
        if (portfolioGuid) {
            this.loadPortfolioOrganizationDetailsById(portfolioGuid);
        } else {
            let portfolioOrganization = this.state.portfolioOrganization;
            let portfolioOrgType: ILookupModel = {
                id: OrganizationTypesEnum.Portfolio.Value,
            };

            portfolioOrganization.organizationType = portfolioOrgType;

            this.setState({ createNew: true, portfolioOrganization: portfolioOrganization });
        }
    }

    loadOrganizationDependencyTypes = async () => {
        var response = await _apiClient.getLookupData(LookupDataEnums.OrganizationDependencyTypes);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        this.setState({ organizationDependencyTypes: response.payload! });
    };

    loadPortfolioOrganizationDetailsById = async (id: string) => {
        var response = await _apiClient.getOrganizationById(id);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        let portfolioOrganization = response.payload!;
        this.setState({
            portfolioOrganization: portfolioOrganization,
            addresses: portfolioOrganization.addresses || [],
            dependentOrganizationList: portfolioOrganization.dependentOrganizations || [],
        });
    };

    changeValue = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    handleOptionsCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const checked = event.target.checked;

        let portfolioOrganization = this.state.portfolioOrganization;

        switch (name) {
            case 'enableCaseFileEdit':
                portfolioOrganization.enableCaseFileEdit = checked;
                break;
            case 'enablePlanningExecution':
                portfolioOrganization.enablePlanningExecution = checked;
                break;
            default:
                break;
        }

        this.setState({
            portfolioOrganization: portfolioOrganization,
        });
    };

    setChangedValue(name: string, value: any) {
        let portfolioOrganization = this.state.portfolioOrganization;
        let newDependentOrganizationName = this.state.newDependentOrganizationName;

        let validation = this.state.validation;

        switch (name) {
            case 'portfolioOrganizationName':
                portfolioOrganization.name = value;
                validation.name = [''];
                break;
            case 'zoneShortName':
                portfolioOrganization.shortName = value;
                validation.shortName = [''];
                break;
            case 'newDependentOrganizationName':
                newDependentOrganizationName = value;
                validation.newdependentorganizationname = [''];
                break;
            case 'effectiveDate':
                portfolioOrganization.effectiveDate = value;
                break;
            case 'comments':
                portfolioOrganization.comments = value;
                break;
            default:
                break;
        }

        this.setState({
            portfolioOrganization: portfolioOrganization,
            newDependentOrganizationName: newDependentOrganizationName,
            validation: validation,
        });
    }

    setDependencyTypeChangeValue = (selectedItem: any, org: IOrganizationDependencyModel) => {
        const value = selectedItem ? selectedItem.id : 0;
        let dependentOrganizationList = this.state.dependentOrganizationList;
        for (var i = 0; i < dependentOrganizationList.length; i++) {
            if (
                dependentOrganizationList[i].name == org.name &&
                dependentOrganizationList[i].organizationType!.id == org.organizationType!.id
            ) {
                dependentOrganizationList[i].dependencyType = {
                    id: value,
                };

                this.setState({ dependentOrganizationList: dependentOrganizationList });
            }
        }
    };

    saveDetail = async () => {
        let organization: IOrganizationModel = this.state.portfolioOrganization;
        organization.addresses = this.state.addresses;
        organization.dependentOrganizations = this.state.dependentOrganizationList;
        let validation = this.state.validation;
        validation.subscriptiontype = [''];
        let validationError = false;
        let numberOfMasterSubscriptionType = 0;

        //custom validation for subscription type
        organization.dependentOrganizations.map(
            (organization: IOrganizationDependencyModel, index: number) => {
                if (organization.dependencyType) {
                    if (organization.dependencyType.id == 0) {
                        validation.subscriptiontype = [DisplayMessages.SubscriptionTypeError];
                        validationError = true;
                        return;
                    }
                    if (organization.dependencyType.id == OrganizationTypesEnum.Company.Value) {
                        numberOfMasterSubscriptionType++;
                    }
                    if (numberOfMasterSubscriptionType >= 2) {
                        validation.subscriptiontype = [DisplayMessages.MasterSubscriptionError];
                        validationError = true;
                        return;
                    }
                }
            }
        );
        //custom validation for primary firm
        let countSecondaryFirms = organization.dependentOrganizations.filter(
            (x) => x.organizationType!.id == OrganizationTypesEnum.Firm.Value && x.priority != 1
        ).length;
        let countPrimaryFirms = organization.dependentOrganizations.filter(
            (x) => x.organizationType!.id == OrganizationTypesEnum.Firm.Value && x.priority == 1
        ).length;
        if (countSecondaryFirms > 0 && countPrimaryFirms == 0) {
            validation.primaryfirm = [DisplayMessages.PrimaryFirmError];
            validationError = true;
        }
        if (validationError) {
            this.setState({ validation: validation });
            return;
        }
        this.setState({ pendingResponse: true });

        if (organization.guid) {
            var response = await _apiClient.updateOrganization(organization);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                    pendingResponse: false,
                });
            } else {
                this.setState({ redirect: true });
            }
        } else {
            var response = await _apiClient.createOrganization(organization);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                    pendingResponse: false,
                });
            } else {
                this.setState({ redirect: true });
            }
        }
    };

    cancelDetail = () => {
        this.setState({ redirect: true });
    };

    showDependencyDialog = (
        event: React.FormEvent<HTMLButtonElement>,
        typeId: number,
        name: string,
        primaryFirm?: boolean
    ) => {
        event.preventDefault();

        let validation = this.state.validation;
        if (primaryFirm) {
            validation.primaryfirm = [''];
        }
        this.setState({
            displayDependencyDialog: true,
            addingUpdatingNewDependentOrganization: false,
            addDependentOrganization: true,
            currentDependentOrganizationTypeId: typeId,
            currentDependentOrganizationTypeName: name,
            newDependentOrganizationName: '',
            addPrimaryFirm: primaryFirm != undefined ? primaryFirm : this.state.addPrimaryFirm,
            validation: validation,
        });
    };

    updateDependentOrganization = (
        event: React.FormEvent<HTMLButtonElement>,
        typeId: number,
        name: string,
        organization: IOrganizationDependencyModel
    ) => {
        event.preventDefault();

        this.setState({
            displayDependencyDialog: true,
            addingUpdatingNewDependentOrganization: true,
            addDependentOrganization: false,
            currentDependentOrganizationTypeId: typeId,
            currentDependentOrganizationTypeName: name,
            newDependentOrganizationName: organization.name!,
            currentDependentOrganization: organization,
        });
    };

    handleDependencyDialogClose = () => {
        this.setState({
            displayDependencyDialog: false,
            currentDependentOrganizationTypeId: 0,
        });
    };

    addingNewDependentOrganization = () => {
        this.setState({
            addingUpdatingNewDependentOrganization: true,
        });
    };

    doneAddingNewDependentOrganization = () => {
        let validation = this.state.validation;

        if (!this.state.newDependentOrganizationName) {
            validation.newdependentorganizationname = [DisplayMessages.DependencyOrgNameError];
            this.setState({ validation: validation });
            return;
        }

        let currentdependentOrganizationList = this.state.dependentOrganizationList;

        if (currentdependentOrganizationList) {
            for (var i = 0; i < currentdependentOrganizationList.length; i++) {
                if (
                    currentdependentOrganizationList[i]!.name ==
                        this.state.newDependentOrganizationName &&
                    currentdependentOrganizationList[i].organizationType!.id ==
                        this.state.currentDependentOrganizationTypeId
                ) {
                    validation.newdependentorganizationname = [
                        DisplayMessages.DependencyOrgDuplicateNameError,
                    ];
                    this.setState({ validation: validation });
                    return;
                }
            }
            let orgType: ILookupModel = {
                id: this.state.currentDependentOrganizationTypeId,
            };

            let newDepOrg: IOrganizationDependencyModel = {
                name: this.state.newDependentOrganizationName,
                organizationType: orgType,
                dependencyType: { id: 0 },
                priority: this.state.addPrimaryFirm ? 1 : 0,
            };

            currentdependentOrganizationList.push(newDepOrg);
        }

        this.setState({
            addingUpdatingNewDependentOrganization: false,
            newDependentOrganizationName: '',
            displayDependencyDialog: false,
            currentDependentOrganizationTypeId: 0,
            currentDependentOrganizationTypeName: '',
            dependentOrganizationList: currentdependentOrganizationList,
        });
    };

    addOrganizationDependency = (
        event: React.FormEvent<HTMLButtonElement>,
        organization: IOrganizationModel
    ) => {
        event.preventDefault();

        let currentdependentOrganizationList = this.state.dependentOrganizationList;

        // Custom Logic for Company. Can only have 1 company. Hence remove old company
        if (
            organization &&
            organization.organizationType &&
            organization.organizationType.id == OrganizationTypesEnum.Company.Value
        ) {
            for (var i = 0; i < currentdependentOrganizationList.length; i++) {
                if (
                    currentdependentOrganizationList[i].organizationType!.id ==
                    OrganizationTypesEnum.Company.Value
                ) {
                    currentdependentOrganizationList.slice(i, 1);
                }
            }
        }

        let orgDep: IOrganizationDependencyModel = {
            guid: organization.guid,
            name: organization.name,
            organizationType: organization.organizationType,
            dependencyType: { id: 0 },
            priority: this.state.addPrimaryFirm ? 1 : 0,
        };

        // Add org to dependentOrganizationList
        currentdependentOrganizationList.push(orgDep);

        this.setState({
            displayDependencyDialog: false,
            currentDependentOrganizationTypeId: 0,
            dependentOrganizationList: currentdependentOrganizationList,
        });
    };

    updateNewlyAddedOrganizationDependency = () => {
        let organization: IOrganizationDependencyModel = this.state.currentDependentOrganization;
        let validation = this.state.validation;

        if (!this.state.newDependentOrganizationName) {
            validation.newdependentorganizationname = [DisplayMessages.DependencyOrgNameError];
            this.setState({ validation: validation });
            return;
        }

        let currentdependentOrganizationList = this.state.dependentOrganizationList;

        if (currentdependentOrganizationList) {
            for (var i = 0; i < currentdependentOrganizationList.length; i++) {
                if (
                    currentdependentOrganizationList[i]!.name ==
                        this.state.newDependentOrganizationName &&
                    currentdependentOrganizationList[i].organizationType!.id ==
                        this.state.currentDependentOrganizationTypeId
                ) {
                    validation.newdependentorganizationname = [
                        DisplayMessages.DependencyOrgDuplicateNameError,
                    ];
                    this.setState({ validation: validation });
                    return;
                }
            }
            let orgType: ILookupModel = {
                id: this.state.currentDependentOrganizationTypeId,
            };
            // update the exisitng dependent organization
            if (currentdependentOrganizationList) {
                for (var i = 0; i < currentdependentOrganizationList.length; i++) {
                    if (
                        currentdependentOrganizationList[i]!.name == organization.name &&
                        currentdependentOrganizationList[i].organizationType!.id ==
                            organization.organizationType!.id
                    ) {
                        currentdependentOrganizationList[i].name =
                            this.state.newDependentOrganizationName;
                        currentdependentOrganizationList[i].priority = this.state.addPrimaryFirm
                            ? 1
                            : 0;
                    }
                }
            }
        }

        this.setState({
            addingUpdatingNewDependentOrganization: false,
            newDependentOrganizationName: '',
            displayDependencyDialog: false,
            currentDependentOrganizationTypeId: 0,
            currentDependentOrganizationTypeName: '',
            dependentOrganizationList: currentdependentOrganizationList,
        });
    };

    getCompanyName(): string {
        let company = this.getCompany();
        if (company && company.name) {
            return company.name;
        }

        return '';
    }

    getCompany(): IOrganizationDependencyModel {
        if (this.state.dependentOrganizationList) {
            let currentdependentOrganizationList = this.state.dependentOrganizationList;
            for (var i = 0; i < currentdependentOrganizationList.length; i++) {
                if (
                    currentdependentOrganizationList[i].organizationType!.id ==
                    OrganizationTypesEnum.Company.Value
                ) {
                    return currentdependentOrganizationList[i];
                }
            }
        }

        return {};
    }

    getCompanyDependencyType(): number {
        let company = this.getCompany();
        if (company && company.dependencyType && company.dependencyType.id) {
            return company.dependencyType.id;
        }

        return 0;
    }

    removeOrganization = (
        event: React.FormEvent<HTMLButtonElement>,
        organization: IOrganizationDependencyModel,
        primaryFirm?: boolean
    ) => {
        event.preventDefault();
        let currentdependentOrganizationList = this.state.dependentOrganizationList;

        for (var i = 0; i < currentdependentOrganizationList.length; i++) {
            if (
                currentdependentOrganizationList[i].name == organization.name &&
                currentdependentOrganizationList[i].organizationType!.id ==
                    organization.organizationType!.id
            ) {
                currentdependentOrganizationList.splice(i, 1);
            }
        }

        this.setState({
            displayDependencyDialog: false,
            currentDependentOrganizationTypeId: 0,
            dependentOrganizationList: currentdependentOrganizationList,
        });
    };

    getInsurers() {
        return this.state.dependentOrganizationList
            ? this.state.dependentOrganizationList.filter(
                  (org) => org.organizationType!.id == OrganizationTypesEnum.Insurer.Value
              )
            : [];
    }

    getFirms(primaryFirm: boolean) {
        if (primaryFirm) {
            return this.state.dependentOrganizationList
                ? this.state.dependentOrganizationList.filter(
                      (org) =>
                          org.organizationType!.id == OrganizationTypesEnum.Firm.Value &&
                          org.priority == 1
                  )
                : [];
        } else {
            return this.state.dependentOrganizationList
                ? this.state.dependentOrganizationList.filter(
                      (org) =>
                          org.organizationType!.id == OrganizationTypesEnum.Firm.Value &&
                          org.priority != 1
                  )
                : [];
        }
    }

    getPropertyType = (organization: IOrganizationDependencyModel) => {
        // eslint-disable-next-line no-prototype-builtins
        if (organization.hasOwnProperty('guid')) {
            return true;
        }
        return false;
    };

    disableAddPrimaryFirm = () => {
        return this.getFirms(true).length == 1 ? true : false;
    };

    render() {
        if (this.state.redirect) return <Navigate to={LocalRoutes.Zones} />;

        if (this.state.pendingResponse) return <Loader />;

        return (
            <>
                <div className="row mb-3">
                    <h1 className="col-sm-6">
                        {this.state.createNew
                            ? 'New Portfolio'
                            : this.state.portfolioOrganization.name}
                    </h1>
                    <div className="col-sm-6">
                        <input
                            type="button"
                            className="btn btn-default float-end"
                            onClick={this.cancelDetail}
                            value="Cancel"
                        />
                        <input
                            type="button"
                            className="btn btn-orange float-end"
                            onClick={this.saveDetail}
                            value="Save"
                        />
                    </div>
                </div>

                <span className="text-danger">{this.state.validation.model}</span>
                <span className="text-danger">{this.state.validation.subscriptiontype}</span>

                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="clients">
                        Company*
                    </label>
                    <div className="col-sm-6 ps-0">
                        <input
                            type="text"
                            readOnly={true}
                            className="form-control"
                            id="staticEmail"
                            value={this.getCompanyName()}
                        />
                        <span className="text-danger">{this.state.validation.company}</span>
                    </div>
                    <div className="col-sm-2">
                        <CheckmateSelect
                            name="organizationDependencyTypeForCompany"
                            value={CheckmateSelectHelper.getSelectedValueById(
                                this.state.organizationDependencyTypes,
                                this.getCompanyDependencyType()
                            )}
                            options={CheckmateSelectHelper.getLookupOptions(
                                this.state.organizationDependencyTypes
                            )}
                            onChange={(e: any) => {
                                this.setDependencyTypeChangeValue(e, this.getCompany());
                            }}
                        />
                    </div>
                    <div className="col-sm-2">
                        {this.getCompanyName() == '' ? (
                            <button
                                className="btn btn-default float-end btn-icon"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    this.showDependencyDialog(
                                        e,
                                        OrganizationTypesEnum.Company.Value,
                                        OrganizationTypesEnum.Company.DisplayName
                                    );
                                }}
                            >
                                <i className="fal fa-lg fa-plus" />
                            </button>
                        ) : this.getPropertyType(this.getCompany()) ? (
                            <button
                                className="btn btn-no-bg float-end btn-icon"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    this.removeOrganization(e, this.getCompany());
                                }}
                            >
                                <i className="fal fa-lg fa-trash-alt"></i>
                            </button>
                        ) : (
                            <span>
                                <button
                                    className="btn btn-no-bg float-end btn-icon"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        this.removeOrganization(e, this.getCompany());
                                    }}
                                >
                                    <i className="fal fa-lg fa-trash-alt"></i>
                                </button>
                                <button
                                    className="btn btn-no-bg float-end btn-icon"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        this.updateDependentOrganization(
                                            e,
                                            OrganizationTypesEnum.Company.Value,
                                            OrganizationTypesEnum.Company.DisplayName,
                                            this.getCompany()
                                        );
                                    }}
                                >
                                    <i className="fal fa-lg fa-pen" />
                                </button>
                            </span>
                        )}
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="portfolioOrganization">
                        Portfolio Name*
                    </label>
                    <input
                        className="form-control col-sm-2"
                        type="text"
                        name="portfolioOrganizationName"
                        value={this.state.portfolioOrganization.name || ''}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">{this.state.validation.name}</span>
                </div>
                <div className="row mb-3">
                    <label className="control-label col-sm-2" htmlFor="zoneShortName">
                        Short Name
                    </label>
                    <input
                        className="form-control col-sm-2"
                        type="text"
                        name="zoneShortName"
                        value={this.state.portfolioOrganization.shortName || ''}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">{this.state.validation.shortName}</span>
                </div>
                <div className="mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name="enableCaseFileEdit"
                        onChange={this.handleOptionsCheckedChange}
                        checked={this.state.portfolioOrganization.enableCaseFileEdit}
                    />
                    <label className="ps-2" htmlFor="enableCaseFileEdit">
                        Enable Case File Edit
                    </label>
                    <span className="ps-4">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="enablePlanningExecution"
                            onChange={this.handleOptionsCheckedChange}
                            checked={this.state.portfolioOrganization.enablePlanningExecution}
                        />
                        <label className="ps-2" htmlFor="enablePlanningExecution">
                            Enable Planning & Execution
                        </label>
                    </span>
                </div>
                <div className="row mb-2">
                    <label className="control-label  col-sm-2" htmlFor="effectiveDate">
                        Effective Date
                    </label>
                    <input
                        type="date"
                        className={
                            'form-control  col-sm-2' +
                            (this.state.portfolioOrganization.effectiveDate &&
                            Common.isValidDate(this.state.portfolioOrganization.effectiveDate)
                                ? ''
                                : ' unselectClass')
                        }
                        name="effectiveDate"
                        value={
                            this.state.portfolioOrganization.effectiveDate
                                ? Common.dateFormat(this.state.portfolioOrganization.effectiveDate)
                                : ''
                        }
                        onChange={this.changeValue}
                    />
                </div>
                <div className="row mb-2">
                    <label className="control-label col" htmlFor="comments">
                        Comments
                    </label>
                </div>
                <div className="row mb-2">
                    <textarea
                        className="form-control col"
                        style={{ marginLeft: '13px' }}
                        name="comments"
                        value={this.state.portfolioOrganization.comments || ''}
                        onChange={this.changeValue}
                    />
                </div>
                <span className="text-danger">{this.state.validation.primaryfirm}</span>
                <div className="mb-2">
                    <label className="control-label" htmlFor="primaryFirm">
                        Primary Firm
                    </label>
                    <button
                        className="btn btn-default btn-icon ms-2"
                        disabled={this.disableAddPrimaryFirm()}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.showDependencyDialog(
                                e,
                                OrganizationTypesEnum.Firm.Value,
                                OrganizationTypesEnum.Firm.DisplayName,
                                true
                            );
                        }}
                    >
                        <i className="fal fa-lg fa-plus" />
                    </button>
                </div>
                <div>
                    {this.getFirms(true) && this.getFirms(true).length > 0 ? (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Subscription Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {this.getFirms(true).map(
                                (organization: IOrganizationDependencyModel, index: number) => {
                                    return (
                                        <tr key={organization.guid}>
                                            <td className="col-sm-4">{organization.name}</td>
                                            <td className="col-sm-4">
                                                <CheckmateSelect
                                                    name="organizationDependencyTypeForFirm"
                                                    value={CheckmateSelectHelper.getSelectedValueById(
                                                        this.state.organizationDependencyTypes,
                                                        organization.dependencyType
                                                            ? organization.dependencyType.id
                                                            : 0
                                                    )}
                                                    options={CheckmateSelectHelper.getLookupOptions(
                                                        this.state.organizationDependencyTypes
                                                    )}
                                                    onChange={(e: any) => {
                                                        this.setDependencyTypeChangeValue(
                                                            e,
                                                            organization
                                                        );
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-no-bg float-end"
                                                    onClick={(
                                                        e: React.MouseEvent<HTMLButtonElement>
                                                    ) => {
                                                        this.removeOrganization(
                                                            e,
                                                            organization,
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-trash-alt"></i>
                                                </button>
                                                {this.getPropertyType(organization) ? null : (
                                                    <button
                                                        className="btn btn-no-bg float-end"
                                                        onClick={(
                                                            e: React.MouseEvent<HTMLButtonElement>
                                                        ) => {
                                                            this.updateDependentOrganization(
                                                                e,
                                                                OrganizationTypesEnum.Firm.Value,
                                                                OrganizationTypesEnum.Firm
                                                                    .DisplayName,
                                                                organization
                                                            );
                                                        }}
                                                    >
                                                        <i className="fal fa-lg fa-pen" />
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </table>
                    ) : null}
                </div>
                <div className="mb-2">
                    <label className="control-label" htmlFor="clients">
                        Secondary Firm(s)
                    </label>
                    <button
                        className="btn btn-default btn-icon ms-2"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.showDependencyDialog(
                                e,
                                OrganizationTypesEnum.Firm.Value,
                                OrganizationTypesEnum.Firm.DisplayName,
                                false
                            );
                        }}
                    >
                        <i className="fal fa-lg fa-plus" />
                    </button>
                </div>
                <div>
                    {this.getFirms(false) && this.getFirms(false).length > 0 ? (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Subscription Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {this.getFirms(false).map(
                                (organization: IOrganizationDependencyModel, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td className="col-sm-4">{organization.name}</td>
                                            <td className="col-sm-4">
                                                <CheckmateSelect
                                                    name="organizationDependencyTypeForFirm"
                                                    value={CheckmateSelectHelper.getSelectedValueById(
                                                        this.state.organizationDependencyTypes,
                                                        organization.dependencyType
                                                            ? organization.dependencyType.id
                                                            : 0
                                                    )}
                                                    options={CheckmateSelectHelper.getLookupOptions(
                                                        this.state.organizationDependencyTypes
                                                    )}
                                                    onChange={(e: any) => {
                                                        this.setDependencyTypeChangeValue(
                                                            e,
                                                            organization
                                                        );
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-no-bg float-end"
                                                    onClick={(
                                                        e: React.MouseEvent<HTMLButtonElement>
                                                    ) => {
                                                        this.removeOrganization(e, organization);
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-trash-alt"></i>
                                                </button>
                                                {this.getPropertyType(organization) ? null : (
                                                    <button
                                                        className="btn btn-no-bg float-end"
                                                        onClick={(
                                                            e: React.MouseEvent<HTMLButtonElement>
                                                        ) => {
                                                            this.updateDependentOrganization(
                                                                e,
                                                                OrganizationTypesEnum.Firm.Value,
                                                                OrganizationTypesEnum.Firm
                                                                    .DisplayName,
                                                                organization
                                                            );
                                                        }}
                                                    >
                                                        <i className="fal fa-lg fa-pen" />
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </table>
                    ) : null}
                </div>
                <div>
                    <label className="control-label" htmlFor="clients">
                        Insurer(s)
                    </label>
                    <button
                        className="btn btn-default btn-icon ms-2"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.showDependencyDialog(
                                e,
                                OrganizationTypesEnum.Insurer.Value,
                                OrganizationTypesEnum.Insurer.DisplayName
                            );
                        }}
                    >
                        <i className="fal fa-lg fa-plus" />
                    </button>
                </div>
                <div>
                    {this.getInsurers() && this.getInsurers().length > 0 ? (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Subscription Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {this.getInsurers().map(
                                (organization: IOrganizationDependencyModel, index: number) => {
                                    return (
                                        <tr key={organization.guid}>
                                            <td className="col-sm-4">{organization.name}</td>
                                            <td className="col-sm-4">
                                                <CheckmateSelect
                                                    name="organizationDependencyTypeForInsurer"
                                                    value={CheckmateSelectHelper.getSelectedValueById(
                                                        this.state.organizationDependencyTypes,
                                                        organization.dependencyType
                                                            ? organization.dependencyType.id
                                                            : 0
                                                    )}
                                                    options={CheckmateSelectHelper.getLookupOptions(
                                                        this.state.organizationDependencyTypes
                                                    )}
                                                    onChange={(e: any) => {
                                                        this.setDependencyTypeChangeValue(
                                                            e,
                                                            organization
                                                        );
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-no-bg float-end"
                                                    onClick={(
                                                        e: React.MouseEvent<HTMLButtonElement>
                                                    ) => {
                                                        this.removeOrganization(e, organization);
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-trash-alt"></i>
                                                </button>
                                                {this.getPropertyType(organization) ? null : (
                                                    <button
                                                        className="btn btn-no-bg float-end"
                                                        onClick={(
                                                            e: React.MouseEvent<HTMLButtonElement>
                                                        ) => {
                                                            this.updateDependentOrganization(
                                                                e,
                                                                OrganizationTypesEnum.Insurer.Value,
                                                                OrganizationTypesEnum.Insurer
                                                                    .DisplayName,
                                                                organization
                                                            );
                                                        }}
                                                    >
                                                        <i className="fal fa-lg fa-pen" />
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </table>
                    ) : null}
                </div>

                <OrganizationAddUpdateModal
                    displayDependencyDialog={this.state.displayDependencyDialog}
                    handleDependencyDialogClose={this.handleDependencyDialogClose}
                    currentDependentOrganizationTypeName={
                        this.state.currentDependentOrganizationTypeName
                    }
                    addDependentOrganization={this.state.addDependentOrganization}
                    addingUpdatingNewDependentOrganization={
                        this.state.addingUpdatingNewDependentOrganization
                    }
                    newDependentOrganizationName={this.state.newDependentOrganizationName}
                    validation={this.state.validation}
                    changeValue={this.changeValue}
                    addOrganizationDependency={this.addOrganizationDependency}
                    currentDependentOrganizationTypeId={
                        this.state.currentDependentOrganizationTypeId
                    }
                    addingNewDependentOrganization={this.addingNewDependentOrganization}
                    doneAddingNewDependentOrganization={this.doneAddingNewDependentOrganization}
                    updateNewlyAddedOrganizationDependency={
                        this.updateNewlyAddedOrganizationDependency
                    }
                />
            </>
        );
    }
}
