import * as React from 'react';

import {
    ApplicationHelpType,
    DataScopesEnum,
    EntityTypes,
    ExportOptions,
    LookupDataEnums,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    MyDocumentsTypesEnum,
    StrategyTypes,
    TaskActivityTypesEnum,
    TaskTypesEnum,
    UserRightsEnum,
} from '../../utilities/Constants';
import {
    ICaseStatisticsByDiagnosisModel,
    IJurisdictionStrategyDetailModel,
    IStrategyPrincipleModel,
    ITableRaw,
    IUpdateStrategyModel,
} from '../../interfaces/IStrategy';
import { INoteCategoryTypeModel, INoteModel } from '../../interfaces/INote';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { DocumentTitle } from '../../components/shared/DocumentTitle';
import EntityAssertionControl from '../../components/shared/EntityAssertionControl';
import { ExportFileButton } from '../../components/shared/ExportFileButton';
import { Help } from '../../components/shared/Help';
import { ICheckmateTaskModel } from '../../interfaces/ICase';
import { IEntityAssertionModel } from '../../interfaces/IAssertionDescriptor';
import { ILookupModel } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import { NoteList } from '../../components/notes/NoteList';
import Sort from '../../stores/Sort';
import { TaskList } from '../../components/tasks/TaskList';
import ValidateUtils from '../../shared/validations';
import { WorkProductWrapper } from '../../components/work-product/WorkProductWrapper';

const _apiClient = new ApiClient();

interface IJurisdictionStrategyDetailProps {
    user: IUserModel;
    guid?: string;
}

interface IJurisdictionStrategyDetailState {
    pendingResponse: boolean;
    readOnly: boolean;
    validation: IValidation;
    strategyDetails?: IJurisdictionStrategyDetailModel;
    originalStrategyDetails?: IJurisdictionStrategyDetailModel;
    qScores: ILookupModel[];
    currentNarrativeCaseAssertionOnEditMode?: IEntityAssertionModel;
    ownerList: IUserModel[];
    noteCategoryTypes: INoteCategoryTypeModel[];
    currentNote: INoteModel;
    openNoteEditor: boolean;
    loggedInUserDataScopeId: number;
    forceNoteEditorInReadOnlyMode?: boolean;
}

export class JurisdictionStrategyDetail extends React.Component<
    IJurisdictionStrategyDetailProps,
    IJurisdictionStrategyDetailState
> {
    constructor(props: IJurisdictionStrategyDetailProps) {
        super(props);

        this.state = {
            pendingResponse: true,
            readOnly: true,
            validation: {},
            qScores: [],
            ownerList: [],
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            noteCategoryTypes: [],
            openNoteEditor: false,
            loggedInUserDataScopeId: 0,
        };
    }

    componentDidMount() {
        if (
            !Authorization.isAuthorizedToStrategyType(
                StrategyTypes.Jurisdiction.Name,
                this.props.user
            )
        )
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadInitialLookupData();
        const id = this.props.guid;
        if (id) this.loadJurisdictionStrategyDetails(id);
    }

    loadJurisdictionStrategyDetails = async (id: string) => {
        const response = await _apiClient.getJurisdictionStrategyDetails(id);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            this.setState({
                strategyDetails: response.payload,
                originalStrategyDetails: JSON.parse(JSON.stringify(response.payload)),
                pendingResponse: false,
                readOnly: true,
                validation: {},
            });
        }
    };

    loadInitialLookupData = async () => {
        this.setState({ pendingResponse: true });
        const qScoreResponse = await _apiClient.getLookupData(LookupDataEnums.QScore);
        if (qScoreResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (qScoreResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    qScoreResponse.errors,
                    qScoreResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const usersResponse = await _apiClient.getCaseManagersInZone();
        if (usersResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (usersResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    usersResponse.errors,
                    usersResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const noteCategoryTypesResponse = await _apiClient.getNoteCategoryTypes(
            LookupDataEnums.StrategyNoteCategories
        );
        if (noteCategoryTypesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (noteCategoryTypesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    noteCategoryTypesResponse.errors,
                    noteCategoryTypesResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );

        this.setState({
            qScores: qScoreResponse.payload!,
            ownerList: usersResponse.payload!,
            noteCategoryTypes: noteCategoryTypesResponse.payload!,
            pendingResponse: false,
            loggedInUserDataScopeId: loggedInUserDataScopeId,
        });
    };

    finalizeChange = (modifiedAssertions: IEntityAssertionModel[]) => {
        if (this.state.strategyDetails && modifiedAssertions.length == 1) {
            const strategyDetails = this.state.strategyDetails;

            if (
                strategyDetails.strategy.guid === modifiedAssertions[0].guid &&
                strategyDetails.strategy.assertionDescriptor &&
                modifiedAssertions[0].assertionDescriptor &&
                strategyDetails.strategy.assertionDescriptor.guid ===
                    modifiedAssertions[0].assertionDescriptor.guid &&
                strategyDetails.strategy.entityType &&
                modifiedAssertions[0].entityType &&
                strategyDetails.strategy.entityType.id === modifiedAssertions[0].entityType.id &&
                strategyDetails.strategy.entityId === modifiedAssertions[0].entityId
            ) {
                strategyDetails.strategy.textValue = modifiedAssertions[0].textValue;
                strategyDetails.strategy.booleanValue = modifiedAssertions[0].booleanValue;
                strategyDetails.strategy.numericValue = modifiedAssertions[0].numericValue;
                strategyDetails.strategy.dateTimeValue = modifiedAssertions[0].dateTimeValue;
                strategyDetails.strategy.assertionDescriptorValue =
                    modifiedAssertions[0].assertionDescriptorValue;
            } else {
                for (let i = 0; i < strategyDetails.principles.length; i++) {
                    const principleItem = strategyDetails.principles[i];
                    if (
                        principleItem.topic.guid === modifiedAssertions[0].guid &&
                        principleItem.topic.assertionDescriptor &&
                        modifiedAssertions[0].assertionDescriptor &&
                        principleItem.topic.assertionDescriptor.guid ===
                            modifiedAssertions[0].assertionDescriptor.guid &&
                        principleItem.topic.entityType &&
                        modifiedAssertions[0].entityType &&
                        principleItem.topic.entityType.id === modifiedAssertions[0].entityType.id &&
                        principleItem.topic.entityId === modifiedAssertions[0].entityId &&
                        principleItem.topic.correlationKeyId ===
                            modifiedAssertions[0].correlationKeyId
                    ) {
                        principleItem.topic.textValue = modifiedAssertions[0].textValue;
                        principleItem.topic.booleanValue = modifiedAssertions[0].booleanValue;
                        principleItem.topic.numericValue = modifiedAssertions[0].numericValue;
                        principleItem.topic.dateTimeValue = modifiedAssertions[0].dateTimeValue;
                        principleItem.topic.assertionDescriptorValue =
                            modifiedAssertions[0].assertionDescriptorValue;
                        break;
                    } else if (
                        principleItem.guidance.guid === modifiedAssertions[0].guid &&
                        principleItem.guidance.assertionDescriptor &&
                        modifiedAssertions[0].assertionDescriptor &&
                        principleItem.guidance.assertionDescriptor.guid ===
                            modifiedAssertions[0].assertionDescriptor.guid &&
                        principleItem.guidance.entityType &&
                        modifiedAssertions[0].entityType &&
                        principleItem.guidance.entityType.id ===
                            modifiedAssertions[0].entityType.id &&
                        principleItem.guidance.entityId === modifiedAssertions[0].entityId &&
                        principleItem.guidance.correlationKeyId ===
                            modifiedAssertions[0].correlationKeyId
                    ) {
                        principleItem.guidance.textValue = modifiedAssertions[0].textValue;
                        principleItem.guidance.booleanValue = modifiedAssertions[0].booleanValue;
                        principleItem.guidance.numericValue = modifiedAssertions[0].numericValue;
                        principleItem.guidance.dateTimeValue = modifiedAssertions[0].dateTimeValue;
                        principleItem.guidance.assertionDescriptorValue =
                            modifiedAssertions[0].assertionDescriptorValue;
                        break;
                    }
                }
            }

            this.setState({
                strategyDetails: strategyDetails,
                currentNarrativeCaseAssertionOnEditMode: undefined,
            });
        }
    };

    handleNarrativeFieldEdit = (item: IEntityAssertionModel) => {
        if (this.state.strategyDetails) {
            const strategyDetails = this.state.strategyDetails;

            if (
                strategyDetails.strategy.guid === item.guid &&
                strategyDetails.strategy.assertionDescriptor &&
                item.assertionDescriptor &&
                strategyDetails.strategy.assertionDescriptor.guid ===
                    item.assertionDescriptor.guid &&
                strategyDetails.strategy.entityType &&
                item.entityType &&
                strategyDetails.strategy.entityType.id === item.entityType.id &&
                strategyDetails.strategy.entityId === item.entityId
            ) {
                this.setState({
                    currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                        JSON.stringify(strategyDetails.strategy)
                    ),
                });
            } else {
                for (let i = 0; i < strategyDetails.principles.length; i++) {
                    const principleItem = strategyDetails.principles[i];
                    if (
                        principleItem.topic.guid === item.guid &&
                        principleItem.topic.assertionDescriptor &&
                        item.assertionDescriptor &&
                        principleItem.topic.assertionDescriptor.guid ===
                            item.assertionDescriptor.guid &&
                        principleItem.topic.entityType &&
                        item.entityType &&
                        principleItem.topic.entityType.id === item.entityType.id &&
                        principleItem.topic.entityId === item.entityId &&
                        principleItem.topic.correlationKeyId === item.correlationKeyId
                    ) {
                        this.setState({
                            currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                                JSON.stringify(principleItem.topic)
                            ),
                        });
                        break;
                    } else if (
                        principleItem.guidance.guid === item.guid &&
                        principleItem.guidance.assertionDescriptor &&
                        item.assertionDescriptor &&
                        principleItem.guidance.assertionDescriptor.guid ===
                            item.assertionDescriptor.guid &&
                        principleItem.guidance.entityType &&
                        item.entityType &&
                        principleItem.guidance.entityType.id === item.entityType.id &&
                        principleItem.guidance.entityId === item.entityId &&
                        principleItem.guidance.correlationKeyId === item.correlationKeyId
                    ) {
                        this.setState({
                            currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                                JSON.stringify(principleItem.guidance)
                            ),
                        });
                        break;
                    }
                }
            }
        }
    };

    handleClearNarrativeFieldEdit = () => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = '';
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    handleNarrativeFieldTextChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = event.target.value;
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    save = async () => {
        const validation: IValidation = {};
        if (!this.state.strategyDetails) {
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true });

        const updateStrategyModel: IUpdateStrategyModel = {
            assertions: [],
            tasks: JSON.parse(JSON.stringify(this.state.strategyDetails.tasks)),
            strategyEntityType: StrategyTypes.Jurisdiction.Value,
        };
        updateStrategyModel.assertions.push(this.state.strategyDetails.strategy);
        for (let i = 0; i < this.state.strategyDetails.principles.length; i++) {
            updateStrategyModel.assertions.push(this.state.strategyDetails.principles[i].topic);
            updateStrategyModel.assertions.push(this.state.strategyDetails.principles[i].guidance);
        }

        const response = await _apiClient.updateStrategyDetails(
            this.state.strategyDetails.guid,
            updateStrategyModel
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.loadJurisdictionStrategyDetails(this.state.strategyDetails.guid);
    };

    addNewPrincipleItem = () => {
        if (this.state.strategyDetails) {
            const strategyDetails = this.state.strategyDetails;
            const existingIds = strategyDetails.principles.map((item: IStrategyPrincipleModel) => {
                return item.correlationKeyId;
            });
            const maxCorrelationId = Math.max(...existingIds);
            strategyDetails.principles.push({
                correlationKeyId: maxCorrelationId + 1,
                topic: {
                    correlationKeyId: maxCorrelationId + 1,
                    assertionDescriptor: strategyDetails.principles[0].topic.assertionDescriptor,
                    entityType: strategyDetails.principles[0].topic.entityType,
                    entityGuid: strategyDetails.principles[0].topic.entityGuid,
                },
                guidance: {
                    correlationKeyId: maxCorrelationId + 1,
                    assertionDescriptor: strategyDetails.principles[0].guidance.assertionDescriptor,
                    entityType: strategyDetails.principles[0].guidance.entityType,
                    entityGuid: strategyDetails.principles[0].guidance.entityGuid,
                },
            });

            this.setState({ strategyDetails: strategyDetails });
        }
    };

    refreshTasks = (tasks: ICheckmateTaskModel[]) => {
        if (this.state.strategyDetails) {
            const strategyDetails = this.state.strategyDetails;
            strategyDetails.tasks = JSON.parse(JSON.stringify(tasks));
            this.setState({ strategyDetails: strategyDetails });
        }
    };

    buildCaseCountRows = () => {
        if (!this.state.strategyDetails) return [<tr />];

        const tableRaw: ITableRaw[] = [];
        let rowIndex = 0;

        // Build 1st column of all rows
        if (this.state.strategyDetails.caseStatisticsByDiagnosis.length > 0) {
            for (
                let i = 0;
                i <
                this.state.strategyDetails.caseStatisticsByDiagnosis[0].openActiveCaseCountByEntity
                    .length;
                i++
            ) {
                const pfItem =
                    this.state.strategyDetails.caseStatisticsByDiagnosis[0]
                        .openActiveCaseCountByEntity[i];
                tableRaw.push({
                    rowIndex: i,
                    columnIndex: 0,
                    data: pfItem.hasStrategy ? (
                        <td className="col-sm-4">
                            <Link
                                target="_blank"
                                to={LocalRoutes.PlaintiffFirmStrategyDetail.replace(
                                    ':guid',
                                    pfItem.entityGuid!
                                )}
                            >
                                {pfItem.entityName || 'No Plaintiff Firm'}
                            </Link>
                        </td>
                    ) : (
                        <td className="col-sm-4">{pfItem.entityName || 'No Plaintiff Firm'}</td>
                    ),
                });
            }
        }

        for (
            let diagnosisItemIndex = 0;
            diagnosisItemIndex < this.state.strategyDetails.caseStatisticsByDiagnosis.length;
            diagnosisItemIndex++
        ) {
            const diagnosisItem =
                this.state.strategyDetails.caseStatisticsByDiagnosis[diagnosisItemIndex];
            for (let i = 0; i < diagnosisItem.openActiveCaseCountByEntity.length; i++) {
                const pfItem = diagnosisItem.openActiveCaseCountByEntity[i];
                tableRaw.push({
                    rowIndex: i,
                    columnIndex: diagnosisItemIndex + 1,
                    data: <td className="text-center">{pfItem.count}</td>,
                });
                rowIndex++;
            }
        }

        const totalRows = rowIndex;
        const totalColumns = this.state.strategyDetails.caseStatisticsByDiagnosis.length + 1;

        const rows = [];
        for (let i = 0; i < totalRows; i++) {
            const columns = [];
            for (let j = 0; j < totalColumns; j++) {
                columns.push(
                    tableRaw.filter((x) => x.rowIndex == i && x.columnIndex == j).length > 0
                        ? tableRaw.filter((x) => x.rowIndex == i && x.columnIndex == j)[0].data
                        : null
                );
            }
            rows.push(<tr>{columns}</tr>);
        }

        return rows;
    };

    handleExportError = () => {
        const validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    handleCancelNoteEditor = () => {
        this.setState({
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            openNoteEditor: false,
        });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        let matchFound = false;
        const strategyDetails = this.state.strategyDetails;
        for (let i = 0; i < strategyDetails!.notes.length; i++) {
            if (strategyDetails!.notes[i].guid == noteItem.guid) {
                matchFound = true;
                strategyDetails!.notes[i] = JSON.parse(JSON.stringify(noteItem));
                break;
            }
        }

        if (!matchFound) strategyDetails!.notes.push(JSON.parse(JSON.stringify(noteItem)));
        strategyDetails!.notes = strategyDetails!.notes.sort(
            Sort.compareDate('modifiedDate', undefined, 'desc')
        );
        this.setState({ strategyDetails: strategyDetails, openNoteEditor: false });
    };

    handleViewEditNote = (noteGuidToEdit: string, readOnly?: boolean) => {
        const validation: IValidation = {};

        const currentNote = this.state.strategyDetails!.notes.find((x) => x.guid == noteGuidToEdit);
        if (!currentNote) {
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ pendingResponse: false, validation: validation });
            return;
        }

        this.setState({
            currentNote: JSON.parse(JSON.stringify(currentNote)),
            openNoteEditor: true,
            forceNoteEditorInReadOnlyMode: readOnly,
        });
    };

    refreshNotes = (notes: INoteModel[]) => {
        const strategyDetails = this.state.strategyDetails;
        if (strategyDetails) {
            strategyDetails.notes = notes;
            this.setState({ strategyDetails: strategyDetails });
        }
    };

    handleAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.StrategyNote },
            status: { id: NoteStatusTypes.Open },
            parent: {
                entityType: { id: EntityTypes.AssertionDescriptorValueStrategy },
                entityGuid: this.state.strategyDetails!.guid,
            },
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        this.setState({
            openNoteEditor: true,
            currentNote: currentNote,
            forceNoteEditorInReadOnlyMode: false,
        });
    };

    render() {
        if (this.state.pendingResponse || !this.state.strategyDetails) return <Loader />;

        if (this.state.openNoteEditor)
            return (
                <>
                    <DocumentTitle title={this.state.strategyDetails!.name} />
                    <NoteEditor
                        forceNoteEditorInReadOnlyMode={this.state.forceNoteEditorInReadOnlyMode}
                        authorizedToEdit={NoteHelper.isUserAuthorizedToEditNote(
                            this.state.currentNote,
                            this.props.user,
                            Authorization.userHasRight(
                                UserRightsEnum.ViewJurisdictionStrategyNote,
                                this.props.user
                            ),
                            Authorization.userHasRight(
                                UserRightsEnum.UpdateJurisdictionStrategyNote,
                                this.props.user
                            ),
                            Authorization.userHasRight(
                                UserRightsEnum.AddJurisdictionStrategyNote,
                                this.props.user
                            )
                        )}
                        strategyTypeId={StrategyTypes.Jurisdiction.Value}
                        user={this.props.user}
                        originEntityName={this.state.strategyDetails.name}
                        originEntityKey={this.state.strategyDetails.guid}
                        originEntityType={StrategyTypes.Jurisdiction.Value}
                        noteCategoryTypes={this.state.noteCategoryTypes}
                        currentNote={this.state.currentNote}
                        handleSaveComplete={this.onSaveNoteComplete}
                        handleCancel={this.handleCancelNoteEditor}
                    />
                </>
            );

        return (
            <div>
                <DocumentTitle title={this.state.strategyDetails!.name} />
                <h1>
                    {this.state.strategyDetails!.name}
                    <span>
                        {this.state.readOnly ? (
                            <span>
                                <ExportFileButton
                                    url={'/' + ApiRoutes.ExportReport}
                                    reportType={{
                                        id: MyDocumentsTypesEnum.JurisdictionStrategyDetailExport.Value,
                                        availableReportOptions: {
                                            customOptions: [
                                                ExportOptions.IncludeTactics,
                                                ExportOptions.IncludeAttachments,
                                                ExportOptions.IncludeStrategyNotes,
                                            ],
                                        },
                                    }}
                                    reportParameters={{
                                        reportType: {
                                            id: MyDocumentsTypesEnum.JurisdictionStrategyDetailExport
                                                .Value,
                                        },
                                        options: { key: this.state.strategyDetails.guid },
                                    }}
                                    onError={this.handleExportError}
                                    icon="fa-file-pdf"
                                />
                                {Authorization.userHasRight(
                                    UserRightsEnum.AddEditDeleteJurisdictionStrategy,
                                    this.props.user
                                ) && (
                                    <button
                                        className="btn btn-no-bg float-end text-gray"
                                        onClick={() => this.setState({ readOnly: false })}
                                    >
                                        <i className="fal fa-pen" />
                                        &nbsp;Edit
                                    </button>
                                )}
                                <a
                                    className="btn btn-no-bg float-end text-gray"
                                    href={window.location.pathname + '#Notes'}
                                >
                                    <i className="fal fa-memo" />
                                    &nbsp;Strategy Notes
                                </a>
                            </span>
                        ) : (
                            <span>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            strategyDetails: this.state.originalStrategyDetails,
                                            readOnly: true,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <input
                                    type="button"
                                    className="btn btn-orange float-end "
                                    onClick={this.save}
                                    value="Save"
                                />
                            </span>
                        )}
                    </span>
                </h1>
                <span className="text-danger">{this.state.validation.model}</span>
                {!!this.state.strategyDetails.caseStatisticsByDiagnosis && (
                    <table className="table thick-outer-border">
                        <tbody>
                            <tr>
                                <td className="col-sm-4 border-0 bold">Disease</td>
                                {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                    (item: ICaseStatisticsByDiagnosisModel) => {
                                        return (
                                            <td className="border-0 bold text-center">
                                                {item.diagnosis}
                                            </td>
                                        );
                                    }
                                )}
                            </tr>
                            <tr>
                                <td className="col-sm-4">Open-Active Cases</td>
                                {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                    (item: ICaseStatisticsByDiagnosisModel) => {
                                        return (
                                            <td className="text-center">
                                                {item.openActiveCaseCount}
                                            </td>
                                        );
                                    }
                                )}
                            </tr>
                            <tr>
                                <td className="col-sm-4">Open-Inactive Cases</td>
                                {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                    (item: ICaseStatisticsByDiagnosisModel) => {
                                        return (
                                            <td className="text-center">
                                                {item.openInactiveCaseCount}
                                            </td>
                                        );
                                    }
                                )}
                            </tr>
                            <tr>
                                <td className="col-sm-4">Alt-Monitored Cases</td>
                                {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                    (item: ICaseStatisticsByDiagnosisModel) => {
                                        return (
                                            <td className="text-center">
                                                {item.altMonitoredCaseCount}
                                            </td>
                                        );
                                    }
                                )}
                            </tr>
                            <tr>
                                <td className="col-sm-4">
                                    {'Cases with Trial Date < 12 months'}
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Cases with Trial Date < 12 months"
                                        helpText="Open - Active, Open - Inactive and Alt - Monitored Cases with Cases with Trial Date < 12 months"
                                    />
                                </td>
                                {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                    (item: ICaseStatisticsByDiagnosisModel) => {
                                        return (
                                            <td className="text-center">
                                                {item.trialDateOver12MonthsCaseCount}
                                            </td>
                                        );
                                    }
                                )}
                            </tr>
                            <tr>
                                <td className="col-sm-4">
                                    Aged Inventory
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Aged Inventory"
                                        helpText="Open - Active Cases with Date Served > 3 years"
                                    />
                                </td>
                                {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                    (item: ICaseStatisticsByDiagnosisModel) => {
                                        return (
                                            <td className="text-center">
                                                {item.agedInventoryCount}
                                            </td>
                                        );
                                    }
                                )}
                            </tr>
                            <tr>
                                <td className="col-sm-4">3-year Average $/Case settled</td>
                                {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                    (item: ICaseStatisticsByDiagnosisModel) => {
                                        return (
                                            <td className="text-center">
                                                {Common.formatCurrency(
                                                    item.threeYearAverageSettlementAmount.toString()
                                                )}
                                            </td>
                                        );
                                    }
                                )}
                            </tr>
                            <tr>
                                <td className="col-sm-4">Historic Average $/Case settled</td>
                                {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                    (item: ICaseStatisticsByDiagnosisModel) => {
                                        return (
                                            <td className="text-center">
                                                {Common.formatCurrency(
                                                    item.historicAverageSettlementAmount.toString()
                                                )}
                                            </td>
                                        );
                                    }
                                )}
                            </tr>
                            <tr>
                                <td className="col-sm-4 bold">
                                    Open Active Case Count By Plaintiffs Firm
                                </td>
                                {this.state.strategyDetails.caseStatisticsByDiagnosis.map(() => {
                                    return <td className=""></td>;
                                })}
                            </tr>
                            {this.buildCaseCountRows()}
                        </tbody>
                    </table>
                )}

                <div className="row mb-2">
                    <div className="col-sm-2 margin-bottom">
                        <label className="text-gray">Strategy</label>
                    </div>
                    <div className="col-sm-10">
                        <EntityAssertionControl
                            assertionDescriptor={
                                this.state.strategyDetails.strategy.assertionDescriptor
                            }
                            entityAssertions={[this.state.strategyDetails.strategy]}
                            editMode={!this.state.readOnly}
                            refreshChange={this.finalizeChange}
                            handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                            narrativeFieldAsPopup={true}
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-2">
                        <label className="text-gray">Principles</label>&nbsp;
                        {this.state.readOnly ? null : (
                            <button
                                className="btn btn-default  btn-icon"
                                onClick={this.addNewPrincipleItem}
                            >
                                <i className="fal fa-lg fa-plus" />
                            </button>
                        )}
                    </div>
                    <div className="col-sm-10 ">
                        <table className="table">
                            <thead>
                                <th className="col-sm-3">Topic</th>
                                <th className="col-sm-9">Guidance/Position</th>
                            </thead>
                            <tbody>
                                {this.state.strategyDetails.principles.map(
                                    (item: IStrategyPrincipleModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td className="col-sm-3 align-top">
                                                    <EntityAssertionControl
                                                        assertionDescriptor={
                                                            item.topic.assertionDescriptor
                                                        }
                                                        entityAssertions={[item.topic]}
                                                        editMode={!this.state.readOnly}
                                                        refreshChange={this.finalizeChange}
                                                        handleNarrativeFieldEdit={
                                                            this.handleNarrativeFieldEdit
                                                        }
                                                        narrativeFieldAsPopup={true}
                                                    />
                                                </td>
                                                <td className="col-sm-9 align-top">
                                                    <EntityAssertionControl
                                                        assertionDescriptor={
                                                            item.guidance.assertionDescriptor
                                                        }
                                                        entityAssertions={[item.guidance]}
                                                        editMode={!this.state.readOnly}
                                                        refreshChange={this.finalizeChange}
                                                        handleNarrativeFieldEdit={
                                                            this.handleNarrativeFieldEdit
                                                        }
                                                        narrativeFieldAsPopup={true}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <TaskList
                    user={this.props.user}
                    taskType={TaskTypesEnum.StrategyTactics}
                    parentEntityGuid=""
                    parentEntityType={{ id: EntityTypes.AssertionDescriptorValueStrategy }}
                    defaultTaskActivityTypeId={TaskActivityTypesEnum.AnalysisStrategy}
                    ownerList={this.state.ownerList}
                    readOnly={this.state.readOnly}
                    refreshParent={this.refreshTasks}
                    title="Tactics"
                    tasks={this.state.strategyDetails.tasks}
                />

                {this.state.readOnly && (
                    <div className="row" id="AttachmentsDiv">
                        <WorkProductWrapper
                            uploadOnly={true}
                            user={this.props.user}
                            entityGuid={this.state.strategyDetails.guid!}
                            entityTypeId={EntityTypes.AssertionDescriptorValueStrategy}
                            strategyTypeId={StrategyTypes.Jurisdiction.Value}
                            title="Attachments"
                            documents={this.state.strategyDetails.documents}
                            parentEntityJson={JSON.stringify(this.state.strategyDetails)}
                            parentEntityRedirectUrl={LocalRoutes.JurisdictionStrategyDetail.replace(
                                ':guid',
                                this.state.strategyDetails.guid!
                            )}
                            authorizedToEdit={Authorization.userHasRight(
                                UserRightsEnum.AddEditDeleteJurisdictionStrategy,
                                this.props.user
                            )}
                            onSaveComplete={() => {
                                if (this.props.guid) {
                                    this.loadJurisdictionStrategyDetails(this.props.guid);
                                }
                            }}
                        />
                    </div>
                )}

                {this.state.readOnly && (
                    <div className="row" id="Notes">
                        {/*No explicit View Strategy Note Right. User has access to Strategy Notes if they have access to the corresponding Strategy Page*/}
                        <NoteList
                            title="Strategy Notes"
                            titleClassName="text-gray font-size-sm"
                            user={this.props.user}
                            notes={this.state.strategyDetails.notes}
                            noteCategoryTypes={this.state.noteCategoryTypes}
                            handleViewEditNote={this.handleViewEditNote}
                            refreshParent={this.refreshNotes}
                            handleAddNote={this.handleAddNote}
                            allowView={Authorization.userHasRight(
                                UserRightsEnum.ViewJurisdictionStrategyNote,
                                this.props.user
                            )}
                            allowEdit={
                                Authorization.userHasRight(
                                    UserRightsEnum.UpdateJurisdictionStrategyNote,
                                    this.props.user
                                ) &&
                                (this.state.loggedInUserDataScopeId !==
                                    DataScopesEnum.LocalBasic.Value ||
                                    this.state.currentNote.allowLocalEdit == true)
                            }
                            allowDelete={Authorization.userHasRight(
                                UserRightsEnum.DeleteJurisdictionStrategyNote,
                                this.props.user
                            )}
                            allowAddNew={Authorization.userHasRight(
                                UserRightsEnum.AddJurisdictionStrategyNote,
                                this.props.user
                            )}
                        />
                    </div>
                )}

                {!!this.state.currentNarrativeCaseAssertionOnEditMode && (
                    <Modal
                        centered
                        show={true}
                        onHide={() =>
                            this.setState({ currentNarrativeCaseAssertionOnEditMode: undefined })
                        }
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {
                                    this.state.currentNarrativeCaseAssertionOnEditMode
                                        .assertionDescriptor!.displayName
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <textarea
                                className="form-control mb-2"
                                value={this.state.currentNarrativeCaseAssertionOnEditMode.textValue}
                                name={
                                    this.state.currentNarrativeCaseAssertionOnEditMode
                                        .assertionDescriptor!.guid
                                }
                                rows={10}
                                onChange={this.handleNarrativeFieldTextChange}
                            />
                            <div className="dialog-btn-div">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() =>
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            ? this.finalizeChange([
                                                  this.state
                                                      .currentNarrativeCaseAssertionOnEditMode,
                                              ])
                                            : {}
                                    }
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end "
                                    onClick={() =>
                                        this.setState({
                                            currentNarrativeCaseAssertionOnEditMode: undefined,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleClearNarrativeFieldEdit}
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        );
    }
}
