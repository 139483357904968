import * as React from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { ApplicationHelpType } from '../../utilities/Constants';
import CheckmateApiClient from '../../services/apiClient';
import { CheckmateDialog } from '../../components/shared/dialog';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { Help } from '../../components/shared/Help';
import { IApiResponse } from '../../tpi.apiclient';
import { IDialogModel } from '../../interfaces/IDialog';
import { ILoginResponse } from '../../interfaces/ILoginResponse';
import { ILoginUser } from '../../interfaces/IAccount';
import { IValidation } from '../../interfaces/IError';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import ValidateUtils from '../../shared/validations';
import classNames from 'classnames';
import { useAuthContext } from '../../contexts/AuthContext';
import useLocalStorage from '../../hooks/useLocalStorage';

const _apiClient = new CheckmateApiClient();

export function AccountLogin() {
    const { guid } = useParams();
    const { login } = useAuthContext();
    const navigate = useNavigate();
    const [, , , removeUserId] = useLocalStorage('userId', '');

    const [animate, setAnimate] = React.useState<boolean>(false);
    const [animate2, setAnimate2] = React.useState<boolean>(false);
    const [loginUser, setLoginUser] = React.useState<ILoginUser>({});
    const [validation, setValidation] = React.useState<IValidation>({});
    const [displayMessageDialog, setDisplayMessageDialog] = React.useState<IDialogModel>({
        isShowingModal: false,
    });
    const [displayRequiredDialog, setDisplayRequiredDialog] = React.useState<IDialogModel>({
        isShowingModal: false,
    });
    const [userId, setUserId] = React.useState<string>('');
    const [displayOneTimeCodeDialog, setDisplayOneTimeCodeDialog] = React.useState<boolean>(false);
    const [oneTimeCode, setOneTimeCode] = React.useState<string>('');

    const changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        setChangedValue(name, value);
    };

    const setChangedValue = (name: string, value: any) => {
        const localLoginUser: ILoginUser = { ...loginUser };
        switch (name) {
            case 'email': {
                localLoginUser.userName = value;
                validation.username = [];
                break;
            }
            case 'password':
                localLoginUser.password = value;
                validation.password = [];
                validation.model = [];
                break;
            case 'onetimecode': {
                let data = value as string;
                if (data) {
                    data = data.replace(/[^\d.-]/g, ''); // this must be done because if we change the type= 'number ' then the size of the field will be too small
                    if (data.length > 6) {
                        setOneTimeCode(data.substring(0, 6));
                    } else {
                        setOneTimeCode(data);
                    }
                }
                validation.onetimecode = [];
                break;
            }
            default:
                break;
        }

        setLoginUser(localLoginUser);
        setValidation(validation);
    };

    const handleClose = () => {
        let redirectTo = LocalRoutes.Root;

        if (guid) {
            redirectTo = LocalRoutes.AcceptInvitation.replace(':guid', guid);
        }

        navigate(redirectTo);
    };

    const handleLogin = async () => {
        if (loginUser.userName && loginUser.userName.length > 256) {
            setDisplayMessageDialog({
                isShowingModal: true,
                title: ' ',
                body: 'User Name cannot exceed 256 characters ',
                dialogClassName: 'account-update-dialog ',
                cancelText: 'OK',
                closeButtonClassName: 'btn btn-default float-end',
            });
            return;
        }

        if (loginUser.password && loginUser.password.length > 256) {
            setDisplayMessageDialog({
                isShowingModal: true,
                title: ' ',
                body: 'User Password cannot exceed 256 characters ',
                dialogClassName: 'account-update-dialog',
                cancelText: 'OK',
                closeButtonClassName: 'btn btn-default float-end',
            });

            return;
        }

        if (guid !== undefined) {
            const invitationResponse = await _apiClient.loginAcceptInvitation(loginUser, guid);
            if (invitationResponse.errorMessage) {
                setValidation(
                    ValidateUtils.parseErrors(
                        invitationResponse.errors,
                        invitationResponse.errorMessage
                    )
                );
            } else {
                setDisplayMessageDialog({
                    isShowingModal: true,
                    title: ' ',
                    body: 'User Account updated successfully. ',
                    dialogClassName: 'account-update-dialog ',
                    cancelText: 'OK ',
                    closeButtonClassName: 'btn btn-default float-end ',
                });
            }
            return;
        }

        try {
            const loginResponse = displayOneTimeCodeDialog
                ? await _apiClient.loginVerifyTwoFactorCode(loginUser, oneTimeCode)
                : await login(loginUser);

            if (loginResponse.payload?.invalidTwoFactorVerification) {
                setDisplayMessageDialog({
                    isShowingModal: true,
                    title: ' ',
                    body: DisplayMessages.TwoFactorTokenInvalid,
                    cancelText: 'OK ',
                    dialogClassName: 'login-username-info-dialog ',
                    closeButtonClassName: 'btn btn-default float-end ',
                });

                setDisplayOneTimeCodeDialog(false);
                setUserId(loginResponse.payload.userId);
                return;
            }

            if (loginResponse.payload?.requiresTwoFactorVerification) {
                setDisplayOneTimeCodeDialog(true);
                setUserId(loginResponse.payload.userId);
                return;
            }

            if (loginResponse.payload?.requiresAcceptingTermsOfService) {
                setDisplayRequiredDialog({
                    isShowingModal: true,
                    title: 'User Acknowledgment',
                    body:
                        DisplayMessages.AcceptTermsOfServiceRequest1 +
                        '\n' +
                        DisplayMessages.AcceptTermsOfServiceRequest2,
                    dialogClassName: 'account-accept-terms-dialog ',
                    confirmText: 'I Accept ',
                    cancelText: 'Cancel ',
                    closeButtonClassName: 'btn btn-default float-end ',
                    confirmButtonClassName: 'btn btn-orange float-end ',
                });

                setUserId(loginResponse.payload.userId);
                return;
            }
            handleResponseAfterLogin(loginResponse, LocalRoutes.Root);
        } catch (e) {
            setValidation({ model: [(e as Error).message] });
        }
    };

    const handleAcceptTermsOfService = async () => {
        if (!userId) return;
        const response = await _apiClient.acceptTermsOfService(userId, loginUser);
        handleResponseAfterLogin(response, LocalRoutes.Root);
    };

    const handleResponseAfterLogin = (
        response: IApiResponse<ILoginResponse>,
        redirectTo: string
    ) => {
        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
        } else {
            setAnimate(true);
            setTimeout(() => {
                setAnimate2(true);
            }, 250);

            setTimeout(() => {
                navigate(redirectTo, { state: { isAuthenticated: true } });
            }, 1000);
        }
    };

    const handleConfirm = () => {
        const displayMessageDialogCopy = { ...displayMessageDialog };
        displayMessageDialogCopy.isShowingModal = false;
        setDisplayMessageDialog(displayMessageDialogCopy);
    };

    const handleRejectRequired = () => {
        const displayRequiredDialogCopy = { ...displayRequiredDialog };
        const validationCopy = { ...validation };

        displayRequiredDialogCopy.isShowingModal = false;
        validation.termsOfService = ['rejected'];

        setDisplayMessageDialog({
            isShowingModal: true,
            title: ' ',
            body: DisplayMessages.AcceptTermsOfServiceRequiredLogin,
            cancelText: 'OK',
            dialogClassName: 'login-username-info-dialog',
            closeButtonClassName: 'btn btn-default float-end ',
        });
        setDisplayRequiredDialog(displayRequiredDialogCopy);
        setValidation(validationCopy);
    };

    const handleVerifyTwoFactor = async () => {
        if (oneTimeCode.length === 0) {
            const validationCopy = { ...validation };
            validationCopy.onetimecode = ['Code is Required'];
            setValidation(validationCopy);
        } else {
            await handleLogin();
        }
    };

    const handleCloseOnetimecode = () => {
        setDisplayOneTimeCodeDialog(false);
        setOneTimeCode('');
        setValidation({});
    };

    React.useEffect(() => {
        removeUserId();
    }, []);

    return (
        <div className="login-container">
            <img className="app-logo" src="" />
            <svg className="login-background">
                <g
                    className={classNames('login-translation', {
                        'set-transform-rotate': animate2,
                    })}
                >
                    <g
                        className={classNames('login-rotation', {
                            'set-transform-rotate': animate2,
                        })}
                    >
                        <path
                            d="M 0,0 L 1,0 1,1 0,1 Z M 0,0 L -1,0 -1,-1 0,-1 Z"
                            className="login-check"
                        />
                    </g>
                </g>
            </svg>
            <div
                className={classNames('login-panel col-sm-2 px-3', {
                    'set-opacity': animate,
                })}
            >
                <div className="logo-container">
                    <img className="logo-img rounded" src="/images/ckmt_login.svg" />
                </div>
                <div className="form-group">
                    {(validation.model?.length ?? 0) > 0 && (
                        <div className="text-danger mt-2">{validation.model}</div>
                    )}
                    <label className="col-form-label">
                        Username*
                        <Help
                            type={ApplicationHelpType.Info}
                            title="Username"
                            helpText={DisplayMessages.LoginUsernameInfo}
                        />
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        name="email"
                        value={loginUser.userName || ''}
                        onChange={changeValue}
                    />
                    <span className="text-danger">{validation.username}</span>
                </div>
                <div className="form-group">
                    <label className="col-form-label">Password*</label>
                    <input
                        className="form-control"
                        type="password"
                        name="password"
                        value={loginUser.password || ''}
                        onChange={changeValue}
                    />
                    <span className="text-danger">{validation.password}</span>
                    <Link className="float-end" to={LocalRoutes.ForgotPassword}>
                        Forgot password?
                    </Link>
                </div>
                <div className="form-group">
                    <button
                        className="btn btn-black full-width vertical-margin-lg"
                        onClick={handleLogin}
                    >
                        Login
                    </button>
                </div>
                <CheckmateDialog
                    isShowingModal={displayMessageDialog.isShowingModal}
                    title={displayMessageDialog.title}
                    body={displayMessageDialog.body}
                    handleClose={handleConfirm}
                    dialogClassName={displayMessageDialog.dialogClassName}
                    cancelText={displayMessageDialog.cancelText}
                    closeButtonClassName={displayMessageDialog.closeButtonClassName}
                />

                <CheckmateDialog
                    isShowingModal={displayRequiredDialog.isShowingModal}
                    title={displayRequiredDialog.title}
                    body={displayRequiredDialog.body}
                    handleClose={handleRejectRequired}
                    handleConfirm={handleAcceptTermsOfService}
                    dialogClassName={displayRequiredDialog.dialogClassName}
                    confirmText={displayRequiredDialog.confirmText}
                    cancelText={displayRequiredDialog.cancelText}
                    closeButtonClassName={displayRequiredDialog.closeButtonClassName}
                    confirmButtonClassName={displayRequiredDialog.confirmButtonClassName}
                />

                <Modal
                    show={displayOneTimeCodeDialog}
                    dialogClassName="onetimecode-dialog centered-modal"
                    contentClassName="onetimecode-dialog-content"
                    onHide={handleClose}
                    backdrop={false}
                >
                    <Modal.Header>
                        <Modal.Title>Two Factor Authentication</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{DisplayMessages.TwoFactorSubtitle}</p>
                        <>
                            <span className="text-danger">{validation.onetimecode}</span>
                            <label className="control-label" htmlFor="onetimecode">
                                Two Factor Code*
                            </label>
                            <input
                                className="form-control mb-3"
                                type="text"
                                pattern="[0-9]"
                                autoComplete="one-time-code"
                                name="onetimecode"
                                value={oneTimeCode || ''}
                                onChange={changeValue}
                            />
                        </>
                        <div>
                            <button
                                className="btn btn-black full-width vertical-margin"
                                onClick={handleVerifyTwoFactor}
                            >
                                Verify Code
                            </button>
                            <button
                                className="btn btn-default float-end vertical-margin-lg"
                                onClick={handleCloseOnetimecode}
                            >
                                Cancel
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <div className={classNames('login-sidebar', { 'set-opacity': animate })}></div>
        </div>
    );
}
