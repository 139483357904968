import ApiClient from '../../../../services/apiClient';
import { IUpdateCaseExpertModel } from '../../../../interfaces/ICaseExpert';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useCaseExpertUpdateMutation = (onError: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (caseExpert: IUpdateCaseExpertModel) => {
            const response = await _apiClient.updateCaseExpert(caseExpert);

            if (!response?.httpResponse.ok) {
                throw new Error('Failed to update Case Expert');
            }

            return response;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
    });
