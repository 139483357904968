import * as React from 'react';

import { Helmet } from 'react-helmet';

export interface IDocumentTitleProps {
    title?: string;
}

export function DocumentTitle({ title }: IDocumentTitleProps) {
    return (
        <Helmet>
            <title>{title ?? 'Checkmate'}</title>
        </Helmet>
    );
}
