import { ConvertRegionTo, convertRegion } from '../../utilities/RegionLookup';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CheckmateApiClient from '../../services/apiClient';
import Stack from 'react-bootstrap/Stack';

interface IWeatherDisplayProps {
    fontColor?: string;
    fontSize?: string;
    setWeather: (weather: IWeather | null) => void;
    weather: IWeather | null;
}

export interface IWeather {
    temp: number;
    status: string;
    location: string;
}

export function WeatherDisplay({ fontColor, setWeather, weather }: IWeatherDisplayProps) {
    const [coords, setCoords] = useState<GeolocationCoordinates | null>(null);

    const getUserCoordinates = useCallback(() => {
        const geolocationAPI = navigator.geolocation;

        if (!geolocationAPI) {
            console.error('Geolocation API is not available in your browser!');
            setWeather(null);
        } else {
            geolocationAPI.getCurrentPosition(
                (position) => {
                    const { coords } = position;
                    setCoords(coords);
                },
                () => {
                    console.error('Something went wrong getting your position!');
                    setWeather(null);
                }
            );
        }
    }, [setWeather]);

    useEffect(() => {
        getUserCoordinates();
    }, [getUserCoordinates]);

    useEffect(() => {
        async function fetchWeather(coords: GeolocationCoordinates) {
            const client = new CheckmateApiClient();

            const response = await client.getWeather({
                lat: coords.latitude,
                lon: coords.longitude,
            });

            if (!response.payload?.data) return;

            const { geoLocationData, weatherData } = response.payload.data;

            const { main, weather: currentWeather } = weatherData;
            const { name, state } = geoLocationData[0];

            const { temp } = main;
            const { main: status } = currentWeather[0];

            const stateAbbreviation = convertRegion(state, ConvertRegionTo.Abbreviated);

            const weatherObj = {
                temp: Math.round(temp),
                status,
                location: `${name}, ${stateAbbreviation}`,
            };

            setWeather(weatherObj);
        }

        if (coords !== null) {
            fetchWeather(coords);
        }
    }, [coords]);

    const dateTimeDisplayStyles = useMemo(
        () => ({
            color: fontColor || '#000000',
            opacity: 0.9,
            textAlign: 'left' as const,
            paddingRight: '4px',
        }),
        [fontColor]
    );

    if (!weather) return null;

    return (
        <Stack direction="vertical" gap={0} style={dateTimeDisplayStyles}>
            <div style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '-0.2em' }}>
                {weather?.temp}&deg;
            </div>
            <div style={{ fontSize: '0.8em' }}>{weather?.status}</div>
        </Stack>
    );
}
