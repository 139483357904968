import { NavLink } from 'react-router-dom';

interface ISideNavMenuItemProps {
    url: string;
    icon: string;
    label: string;
}

export function SideNavMenuItem({ url, icon, label }: ISideNavMenuItemProps) {
    return (
        <div>
            <NavLink
                to={url}
                style={({ isActive }) => {
                    return {
                        borderBottom: isActive ? '1px solid #ffffff' : 'none',
                        color: '#ffffff',
                        display: 'inline-block',
                        textDecoration: 'none',
                    };
                }}
            >
                <div
                    className="mt-2"
                    style={{
                        alignItems: 'baseline',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                    }}
                >
                    <i className={`fa ${icon}`} />
                    <span className="font-size-xs">{label}</span>
                </div>
            </NavLink>
        </div>
    );
}
