import ApiClient from '../../../services/apiClient';
import { HttpResponseError } from '../../../interfaces/IHttpResponseError';
import { useLoading } from '../../../contexts/LoadingContext';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const getTriggerRulesReactQueryKey = ['get-trigger-rules'];

export const useGetTriggerRules = (
    enableLoader?: boolean,
    onError?: (error: HttpResponseError) => void
) => {
    const loading = useLoading();

    return useQuery({
        queryKey: getTriggerRulesReactQueryKey,
        queryFn: async () => {
            if (enableLoader) {
                loading.showLoading();
            }
            const response = await _apiClient.searchTriggers();

            if (!response.httpResponse.ok) {
                const error: HttpResponseError = new Error('Failed to load Trigger Rules');
                error.status = response.httpResponse.status;
                throw error;
            }

            return response.payload;
        },
        onSettled: enableLoader ? () => loading.hideLoading() : undefined,
        onError: (error: HttpResponseError) => {
            if (typeof onError === 'function') {
                onError(error);
            }
        },
        cacheTime: 0,
        staleTime: 0,
    });
};
