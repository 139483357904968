import ApiClient from '../../services/apiClient';
import Sort from '../../stores/Sort';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetPackageDeals = (enabled = false) => {
    return useQuery({
        queryKey: ['get-package-deals'],
        queryFn: async () => {
            const response = await _apiClient.getPackageSettlements();

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve package deals.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            const data = response.payload;
            data?.sort(Sort.compareValues('name', undefined, 'asc'));

            return data;
        },
        enabled: enabled
    });
};
