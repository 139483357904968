import * as React from 'react';

import {
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
    CaseFileStaticItems,
    LookupDataEnums,
    OrganizationTypesEnum,
    MyDocumentsTypesEnum,
} from '../../utilities/Constants';
import { ILookupModel, IStaticItemLookupModel } from '../../interfaces/ILookup';
import { IQueryFilterCriteria, IQuerySettingsModel } from '../../interfaces/IQuery';
import { Link } from 'react-router-dom';
import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Auth from '../../stores/authentication';
import CaseHelper from '../../utilities/CaseHelper';
import { CheckmateDialog } from '../../components/shared/dialog';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IAssociatedOrganizationModel } from '../../interfaces/IOrganization';
import { ICaseStatusLookupModel } from '../../interfaces/ICase';
import { INoteCategoryTypeModel } from '../../interfaces/INote';
import { IUserModel } from '../../interfaces/IUser';
import { IUserReportParametersModel } from '../../interfaces/Report/IReport';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { Modal } from 'react-bootstrap';
import ParamUtils from '../../shared/ParamUtilities';
import Sort from '../../stores/Sort';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IUserReportListState {
    reports: IUserReportParametersModel[];
    pendingResponse: boolean;
    validation: IValidation;
    assertionDescriptors: IAssertionDescriptorModel[];
    showQueryInfoDialog?: boolean;
    selectedQueryItemGuid: string;
    caseStatuses: ICaseStatusLookupModel[];
    caseManagers: IUserModel[];
    allDefenseCounselsForZone: IAssociatedOrganizationModel[];
    USStates: ILookupModel[];
    noteCategoryTypes: INoteCategoryTypeModel[];
    showConfirmDeleteDialog?: boolean;
    reportGuidToDelete?: string;
    staticItems: IStaticItemLookupModel[];
}

interface IUserReportListProps {
    user: IUserModel;
    reportRefreshHook?(): void;
}

export class UserReportList extends React.Component<IUserReportListProps, IUserReportListState> {
    constructor(props: any) {
        super(props);

        this.state = {
            reports: [],
            pendingResponse: true,
            validation: {},
            assertionDescriptors: [],
            selectedQueryItemGuid: '',
            caseManagers: [],
            allDefenseCounselsForZone: [],
            caseStatuses: [],
            USStates: [],
            noteCategoryTypes: [],
            staticItems: [],
        };
    }

    componentDidMount() {
        this.loadUserReports();
        this.loadAssertionDescriptors();
        this.loadCaseManagers();
        this.loadAllDefenseCounselsForZone();
        this.loadCaseStatuses();
        this.loadUSStates();
        this.loadNoteCategoryTypes();
        this.loadStaticItems();
    }

    componentDidUpdate() {
        const reload: string = ParamUtils.getParameterByName('reload');
        if (reload.toUpperCase() == 'TRUE') {
            this.loadUserReports();
        }
    }

    loadUserReports = async () => {
        const response = await _apiClient.getUserReports();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ reports: response.payload!, pendingResponse: false });
    };

    loadAssertionDescriptors = async () => {
        const response = await _apiClient.getAssertionDescriptors(
            AssertionDescriptorUsageTypesEnum.Case
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ assertionDescriptors: response.payload!, pendingResponse: false });
    };

    loadCaseManagers = async () => {
        const response = await _apiClient.getCaseManagersInZone();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ caseManagers: response.payload!, pendingResponse: false });
    };

    loadAllDefenseCounselsForZone = async () => {
        const response = await _apiClient.getOrganizationInZone(OrganizationTypesEnum.Firm.Value);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ allDefenseCounselsForZone: response.payload!, pendingResponse: false });
    };

    loadCaseStatuses = async () => {
        const response = await _apiClient.getCaseStatusLookupData();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ caseStatuses: response.payload!, pendingResponse: false });
    };

    loadUSStates = async () => {
        const response = await _apiClient.getLookupData(LookupDataEnums.USStates);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ USStates: response.payload!, pendingResponse: false });
    };

    loadNoteCategoryTypes = async () => {
        const response = await _apiClient.getNoteCategoryTypes(LookupDataEnums.CaseNoteCategories);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ noteCategoryTypes: response.payload!, pendingResponse: false });
    };

    loadStaticItems = async () => {
        const response = await _apiClient.getCaseFileStaticItems();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            return;
        }
        return response.payload;
    };

    formatDate = (date?: string) => {
        if (date) {
            return date.substring(0, 10);
        }
        return '';
    };

    sortData = (key: string, order: string, subKey?: string) => {
        this.setState({ pendingResponse: true });

        if (this.state.reports) {
            const reports = this.state.reports.sort(Sort.compareValues(key, subKey, order));
            this.setState({ reports: reports, pendingResponse: false });
        }
    };

    sortDate = (key: string, order: string, subKey?: string) => {
        this.setState({ pendingResponse: true });

        if (this.state.reports) {
            const reports = this.state.reports.sort(Sort.compareDate(key, subKey, order));
            this.setState({ reports: reports, pendingResponse: false });
        }
    };

    downloadReport = (event: React.FormEvent<HTMLButtonElement>, guid: string) => {
        this.setState({ pendingResponse: true });
        const reports = this.state.reports;
        const match = reports.filter((x) => x.guid == guid)[0];
        const originalReadStatus = match.read;

        const url = ApiRoutes.DownloadReport.replace('{id}', guid);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                // The DB will update the Read status to True, however the page is NOT refreshed, hence update Read status in State.
                match.read = true;
                this.setState({ reports: reports, pendingResponse: false });

                let fileName = 'doc.txt';
                const blob = xhr.response;
                //NOTE: in order to pull the filename out of the response header, this must be POST call. GET calls don't appear to have 'Content-Disposition' in their headers
                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                match.read = originalReadStatus;
                const validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ reports: reports, pendingResponse: false, validation: validation });
            } else if (typeof this.props.reportRefreshHook === 'function')
                this.props.reportRefreshHook();
        };
        xhr.send();
    };

    deleteReport = async () => {
        const response = await _apiClient.deleteReport(this.state.reportGuidToDelete!);

        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        if (typeof this.props.reportRefreshHook === 'function') this.props.reportRefreshHook();

        const reports = this.state.reports.filter((x) => x.guid !== this.state.reportGuidToDelete!);
        this.setState({ reports: reports, showConfirmDeleteDialog: false });
    };

    buildQueryInfoValuesList = (values: string[]): any => {
        if (values.length == 0) return '';
        const ul = [];
        for (let i = 0; i < values.length; i++) {
            ul.push(<li>values[i]</li>);
        }
        ul.push(<ul>{ul}</ul>);
        return ul;
    };

    buildQueryInfo = (guid: string): any => {
        const rows = [];
        const match = this.state.reports.find((x) => x.guid === guid);
        if (match && match.parametersJson) {
            const settings: IQuerySettingsModel = JSON.parse(match.parametersJson);
            if (settings && settings.filters) {
                const statusIds: number[] = [];
                const subStatusIds: number[] = [];

                const statusMatch = settings.filters.find((x) => x.value == 'Status');
                const subStatusMatch = settings.filters.find((x) => x.value == 'SubStatus');

                if (statusMatch && statusMatch.selectedValues) {
                    for (let i = 0; i < statusMatch.selectedValues.length; i++) {
                        statusIds.push(statusMatch.selectedValues[i].id!);
                    }
                }

                if (subStatusMatch && subStatusMatch.selectedValues) {
                    for (let i = 0; i < subStatusMatch.selectedValues.length; i++) {
                        subStatusIds.push(subStatusMatch.selectedValues[i].id!);
                    }
                }

                const values: string[] = [];
                for (let i = 0; i < this.state.caseStatuses.length; i++) {
                    if (
                        statusIds.find((x) => x == this.state.caseStatuses![i].status!.id!) &&
                        (!this.state.caseStatuses![i].subStatus ||
                            subStatusIds.find(
                                (x) => x == this.state.caseStatuses![i].subStatus.id!
                            ))
                    ) {
                        values.push(this.state.caseStatuses![i].name);
                    }
                }

                if (values.length > 0) {
                    rows.push(
                        <div className="row">
                            <div className="col-sm-6">
                                {' '}
                                {CaseHelper.getCaseFileStaticItemDisplayName(
                                    this.state.staticItems,
                                    CaseFileStaticItems.CaseStatus.Id
                                )}{' '}
                            </div>
                            {values.length > 0 ? (
                                <div className="col-sm-6">
                                    {' '}
                                    <ul>
                                        {values.map((s: string) => {
                                            return <li>{s}</li>;
                                        })}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    );
                }

                for (let i = 0; i < settings.filters.length; i++) {
                    rows.push(<div>{this.buildQueryInfoRow(settings.filters[i])}</div>);
                }
            }
        }
        return rows;
    };

    buildQueryInfoRow = (filterItem: IQueryFilterCriteria): any => {
        let values: string[] = [];
        const content = [];
        if (filterItem.isAssertionDescriptor) {
            const assertionDescriptorMatch = this.state.assertionDescriptors.find(
                (x) => x.guid == filterItem.assertionDescriptorGuid
            );
            if (assertionDescriptorMatch) {
                switch (assertionDescriptorMatch.valueSourceType!.id) {
                    case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                        if (
                            assertionDescriptorMatch.assertionDescriptorValues &&
                            assertionDescriptorMatch.assertionDescriptorValues.length > 0
                        ) {
                            values = [];
                            if (filterItem.selectedValues) {
                                for (let i = 0; i < filterItem.selectedValues.length; i++) {
                                    const match =
                                        assertionDescriptorMatch.assertionDescriptorValues.find(
                                            (x) => x.guid == filterItem.selectedValues![i].guid!
                                        );
                                    if (match) values.push(match.text!);
                                }
                            }
                            content.push(
                                <div className="row">
                                    <div className="col-sm-6">
                                        {' '}
                                        {assertionDescriptorMatch.displayName ||
                                            assertionDescriptorMatch.name}{' '}
                                    </div>
                                    {values.length > 0 ? (
                                        <div className="col-sm-6">
                                            {' '}
                                            <ul>
                                                {values.map((s: string) => {
                                                    return <li>{s}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        }
                        break;
                    case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
                        const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                            ? assertionDescriptorMatch.valueDisplayType.id
                            : 0;
                        switch (valueDisplayTypeId) {
                            case AssertionDescriptorValueDisplayTypes.Currency.Value:
                                content.push(
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {' '}
                                            {assertionDescriptorMatch.displayName ||
                                                assertionDescriptorMatch.name}{' '}
                                        </div>
                                        <div className="col-sm-6">
                                            {' '}
                                            <ul>
                                                <li>
                                                    ${filterItem.numericValueFrom} to $
                                                    {filterItem.numericValueTo}{' '}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                                break;
                            case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                                content.push(
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {' '}
                                            {assertionDescriptorMatch.displayName ||
                                                assertionDescriptorMatch.name}{' '}
                                        </div>
                                        <div className="col-sm-6">
                                            {' '}
                                            <ul>
                                                <li>
                                                    {Common.dateFormat(filterItem.startDate)} to{' '}
                                                    {Common.dateFormat(filterItem.endDate)}{' '}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                                break;
                            case AssertionDescriptorValueDisplayTypes.Number.Value:
                                content.push(
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {' '}
                                            {assertionDescriptorMatch.displayName ||
                                                assertionDescriptorMatch.name}{' '}
                                        </div>
                                        <div className="col-sm-6">
                                            {' '}
                                            <ul>
                                                <li>
                                                    {filterItem.numericValueFrom} to{' '}
                                                    {filterItem.numericValueTo}{' '}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                                break;
                            default:
                                content.push(
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {' '}
                                            {assertionDescriptorMatch.displayName ||
                                                assertionDescriptorMatch.name}{' '}
                                        </div>
                                        <div className="col-sm-6">
                                            {' '}
                                            <ul>
                                                <li>{filterItem.text} </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                                break;
                        }
                        break;
                    }
                    case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                        content.push(
                            <div className="row">
                                <div className="col-sm-6">
                                    {' '}
                                    {assertionDescriptorMatch.displayName ||
                                        assertionDescriptorMatch.name}{' '}
                                </div>
                                <div className="col-sm-6">
                                    {' '}
                                    <ul>
                                        <li> {filterItem.booleanValue ? 'Yes' : 'No'} </li>
                                    </ul>
                                </div>
                            </div>
                        );
                        break;
                    default:
                        break;
                }
            }
        } else {
            switch (filterItem.staticItemId) {
                case CaseFileStaticItems.CaseNumber.Id:
                case CaseFileStaticItems.UniqueCaseID.Id:
                    content.push(
                        <div className="row">
                            <div className="col-sm-6">
                                {' '}
                                {CaseHelper.getCaseFileStaticItemDisplayName(
                                    this.state.staticItems,
                                    CaseFileStaticItems.CaseNumber.Id
                                )}{' '}
                            </div>
                            <div className="col-sm-6">
                                {' '}
                                <ul>
                                    <li>{filterItem.text} </li>
                                </ul>
                            </div>
                        </div>
                    );
                    break;
                case CaseFileStaticItems.CaseManager.Id:
                case CaseFileStaticItems.AlternateCaseManager.Id:
                    values = [];
                    if (filterItem.selectedValues) {
                        for (let i = 0; i < filterItem.selectedValues.length; i++) {
                            const match = this.state.caseManagers.find(
                                (x) => x.guid == filterItem.selectedValues![i].guid!
                            );
                            if (match && match.profile)
                                values.push(match.profile.firstName + ' ' + match.profile.lastName);
                        }
                    }
                    content.push(
                        <div className="row">
                            <div className="col-sm-6">
                                {' '}
                                {filterItem.staticItemId === CaseFileStaticItems.CaseManager.Id
                                    ? CaseHelper.getCaseFileStaticItemDisplayName(
                                          this.state.staticItems,
                                          CaseFileStaticItems.CaseManager.Id
                                      )
                                    : CaseHelper.getCaseFileStaticItemDisplayName(
                                          this.state.staticItems,
                                          CaseFileStaticItems.AlternateCaseManager.Id
                                      )}{' '}
                            </div>
                            {values.length > 0 ? (
                                <div className="col-sm-6">
                                    {' '}
                                    <ul>
                                        {values.map((s: string) => {
                                            return <li>{s}</li>;
                                        })}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    );
                    break;
                case CaseFileStaticItems.LocalCounsel.Id:
                    values = [];
                    if (filterItem.selectedValues) {
                        for (let i = 0; i < filterItem.selectedValues.length; i++) {
                            const match = this.state.allDefenseCounselsForZone.find(
                                (x) => x.guid == filterItem.selectedValues![i].guid!
                            );
                            if (match) values.push(match.name!);
                        }
                    }
                    content.push(
                        <div className="row">
                            <div className="col-sm-6">
                                {' '}
                                {CaseHelper.getCaseFileStaticItemDisplayName(
                                    this.state.staticItems,
                                    CaseFileStaticItems.LocalCounsel.Id
                                )}{' '}
                            </div>
                            {values.length > 0 ? (
                                <div className="col-sm-6">
                                    {' '}
                                    <ul>
                                        {values.map((s: string) => {
                                            return <li>{s}</li>;
                                        })}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    );
                    break;
                case CaseFileStaticItems.CasePriority.Id:
                    content.push(
                        <div className="row">
                            <div className="col-sm-6">
                                {' '}
                                {CaseHelper.getCaseFileStaticItemDisplayName(
                                    this.state.staticItems,
                                    CaseFileStaticItems.CasePriority.Id
                                )}{' '}
                            </div>
                            <div className="col-sm-6">
                                {' '}
                                <ul>
                                    <li>{filterItem.booleanValue ? 'Yes' : 'No'} </li>
                                </ul>{' '}
                            </div>
                        </div>
                    );
                    break;
                case CaseFileStaticItems.CaseStatusAsOfDate.Id:
                case CaseFileStaticItems.ResolutionDate.Id:
                    values = [];
                    if (filterItem.selectedValues) {
                        if (filterItem.selectedValues.length === 1) {
                            const statusId = filterItem.selectedValues[0].id!;
                            values.push(
                                this.state.caseStatuses.filter(
                                    (x) => x.status && x.status.id == statusId
                                )[0].status.displayName!
                            );
                        } else if (filterItem.selectedValues.length > 1) {
                            const subStatusValues = filterItem.selectedValues.slice(1);
                            for (let i = 0; i < subStatusValues.length; i++) {
                                values.push(
                                    this.state.caseStatuses.filter(
                                        (x) =>
                                            x.subStatus && x.subStatus.id == subStatusValues[i].id!
                                    )[0].name
                                );
                            }
                        }
                    }
                    if (filterItem.startDate || filterItem.endDate)
                        values.push(
                            Common.dateFormat(filterItem.startDate) +
                                ' to ' +
                                Common.dateFormat(filterItem.endDate)
                        );
                    content.push(
                        <div className="row">
                            <div className="col-sm-6">
                                {' '}
                                {CaseHelper.getCaseFileStaticItemDisplayName(
                                    this.state.staticItems,
                                    CaseFileStaticItems.CaseStatusAsOfDate.Id
                                )}{' '}
                            </div>
                            {values.length > 0 ? (
                                <div className="col-sm-6">
                                    {' '}
                                    <ul>
                                        {values.map((s: string) => {
                                            return <li>{s}</li>;
                                        })}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    );
                    break;
                case CaseFileStaticItems.State.Id:
                    values = [];
                    if (filterItem.selectedValues) {
                        for (let i = 0; i < filterItem.selectedValues.length; i++) {
                            const match = this.state.USStates.find(
                                (x) => x.guid == filterItem.selectedValues![i].guid!
                            );
                            if (match) values.push(match.displayName || match.name!);
                        }
                    }
                    content.push(
                        <div className="row">
                            <div className="col-sm-6">
                                {' '}
                                {CaseHelper.getCaseFileStaticItemDisplayName(
                                    this.state.staticItems,
                                    CaseFileStaticItems.State.Id
                                )}{' '}
                            </div>
                            {values.length > 0 ? (
                                <div className="col-sm-6">
                                    {' '}
                                    <ul>
                                        {values.map((s: string) => {
                                            return <li>{s}</li>;
                                        })}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    );
                    break;
                case CaseFileStaticItems.NotesSection.Id:
                    values = [];
                    if (filterItem.selectedValues) {
                        for (let i = 0; i < filterItem.selectedValues.length; i++) {
                            const match = this.state.noteCategoryTypes.find(
                                (x) => x.id == filterItem.selectedValues![i].id!
                            );
                            if (match) values.push(match.displayName || match.name!);
                        }
                    }
                    if (filterItem.startDate || filterItem.endDate)
                        values.push(
                            Common.dateFormat(filterItem.startDate) +
                                ' to ' +
                                Common.dateFormat(filterItem.endDate)
                        );
                    content.push(
                        <div className="row">
                            <div className="col-sm-6">
                                {' '}
                                {CaseHelper.getCaseFileStaticItemDisplayName(
                                    this.state.staticItems,
                                    CaseFileStaticItems.NotesSection.Id
                                )}{' '}
                            </div>
                            {values.length > 0 ? (
                                <div className="col-sm-6">
                                    {' '}
                                    <ul>
                                        {values.map((s: string) => {
                                            return <li>{s}</li>;
                                        })}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    );
                    break;
            }
        }

        return content;
    };

    getNavigationUrl = (item: IUserReportParametersModel): string => {
        const navigationUrl: string = item.type?.clientRouteUrl ?? '';
        const guid: string = item.guid ?? '';
    
        // If guid exists, append it to the end of the URL
        const url = navigationUrl + (guid ? '/' + guid : '');
    
        return url;
    };

    render() {
        const isSystemAdmin = this.props.user ? Auth.isSystemAdmin(this.props.user) : false;
        if (this.state.pendingResponse) {
            return <Loader />;
        } else {
            return (
                <div>
                    <h1>Saved User Reports</h1>
                    <hr />
                    <div className="text-danger">{this.state.validation.model}</div>
                    <table className="table">
                        <thead>
                            <tr>
                                {isSystemAdmin ? (
                                    <th>
                                        Zone
                                        <button
                                            className="btn-no-bg sort-arrow"
                                            onClick={() => {
                                                this.sortData('organizationName', 'desc');
                                            }}
                                        >
                                            <i className="fal fa-lg fa-angle-up" />
                                        </button>
                                        <button
                                            className="btn-no-bg sort-arrow"
                                            onClick={() => {
                                                this.sortData('organizationName', 'asc');
                                            }}
                                        >
                                            <i className="fal fa-lg fa-angle-down" />
                                        </button>
                                    </th>
                                ) : null}
                                <th>
                                    Name
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortData('name', 'desc');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-up" />
                                    </button>
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortData('name', 'asc');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-down" />
                                    </button>
                                </th>
                                <th />
                                <th>
                                    Type
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortData('type', 'desc', 'displayName');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-up" />
                                    </button>
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortData('type', 'asc', 'displayName');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-down" />
                                    </button>
                                </th>
                                <th>
                                    Status
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortData('status', 'desc', 'displayName');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-up" />
                                    </button>
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortData('status', 'asc', 'displayName');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-down" />
                                    </button>
                                </th>
                                <th className="center">
                                    Created Date
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortDate('createdDate', 'desc');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-up" />
                                    </button>
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortDate('createdDate', 'asc');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-down" />
                                    </button>
                                </th>
                                <th>
                                    Unread
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortData('read', 'desc');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-up" />
                                    </button>
                                    <button
                                        className="btn-no-bg sort-arrow"
                                        onClick={() => {
                                            this.sortData('read', 'asc');
                                        }}
                                    >
                                        <i className="fal fa-lg fa-angle-down" />
                                    </button>
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.reports.map(
                                (reportItem: IUserReportParametersModel, i: number) => {

                                    const nameNavigateUrl = this.getNavigationUrl(reportItem);

                                    return (
                                        <tr key={i}>
                                            {isSystemAdmin ? (
                                                <td>{reportItem.organization!.shortName}</td>
                                            ) : null}
                                            <td>
                                                {reportItem.status!.id == 4 ? (
                                                    <button
                                                        className="btn btn-no-bg text-hyperlink"
                                                        title="Click to download report."
                                                        style={{ paddingLeft: '0px' }}
                                                        onClick={(e) => {
                                                            this.downloadReport(
                                                                e,
                                                                reportItem.guid!
                                                            );
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight: reportItem.read
                                                                    ? 'normal'
                                                                    : 'bold',
                                                            }}
                                                        >
                                                            {reportItem.name}
                                                        </span>
                                                    </button>
                                                ) : (
                                                        <span
                                                            style={{
                                                                fontWeight: reportItem.read
                                                                    ? 'normal'
                                                                    : 'bold',
                                                            }}
                                                        >
                                                            {nameNavigateUrl ? (
                                                                <Link to={nameNavigateUrl} target="_blank">
                                                                    {reportItem.name}
                                                                </Link>
                                                            ) : (
                                                                reportItem.name
                                                            )}
                                                        </span>
                                                )}
                                            </td>
                                            <td>
                                                {reportItem.type!.id ===
                                                MyDocumentsTypesEnum.QueryResults.Value ? (
                                                    <button
                                                        className="btn-no-bg"
                                                        style={{ marginTop: '2px' }}
                                                        title="Click to view details about the query parameters"
                                                        onClick={() => {
                                                            this.setState({
                                                                showQueryInfoDialog: true,
                                                                selectedQueryItemGuid:
                                                                    reportItem.guid!,
                                                            });
                                                        }}
                                                    >
                                                        <i className="fal fa-lg fa-check-circle color-gray" />
                                                    </button>
                                                ) : null}
                                            </td>
                                            <td>{reportItem.type!.displayName}</td>
                                            <td>{reportItem.status!.displayName}</td>
                                            <td className="center">
                                                {this.formatDate(reportItem.createdDate)}
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    disabled
                                                    checked={!reportItem.read}
                                                ></input>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-no-bg float-end"
                                                    onClick={() => {
                                                        this.setState({
                                                            reportGuidToDelete: reportItem.guid!,
                                                            showConfirmDeleteDialog: true,
                                                        });
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                    {this.state.showQueryInfoDialog ? (
                        <Modal
                            centered
                            show={this.state.showQueryInfoDialog}
                            onHide={() => this.setState({ showQueryInfoDialog: false })}
                            backdrop={false}
                        >
                            <Modal.Header>
                                <Modal.Title>
                                    {' '}
                                    {this.state.selectedQueryItemGuid
                                        ? this.state.reports.filter(
                                              (x) => x.guid == this.state.selectedQueryItemGuid
                                          )[0].name
                                        : ''}{' '}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="container-fluid">
                                    {this.buildQueryInfo(this.state.selectedQueryItemGuid)}
                                </div>
                                <div className="dialog-btn-div margin-top-sm">
                                    <button
                                        className="btn btn-default float-end"
                                        onClick={() =>
                                            this.setState({ showQueryInfoDialog: false })
                                        }
                                    >
                                        Close
                                    </button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    ) : null}

                    <CheckmateDialog
                        isShowingModal={this.state.showConfirmDeleteDialog || false}
                        body="Are you sure you want to delete this item? This operation is permanent and cannot be reverted back."
                        handleClose={() => this.setState({ showConfirmDeleteDialog: false })}
                        handleConfirm={this.deleteReport}
                        confirmText="Yes"
                        cancelText="No"
                        confirmButtonClassName="btn btn-black float-end horizontal-margin"
                        dialogClassName="confirm-document-delete-dialog"
                        closeButtonClassName="btn btn-default float-end"
                    />
                </div>
            );
        }
    }
}
