import * as React from 'react';

import {
    CaseFilterType,
    LookupDataEnums,
    OrganizationTypesEnum,
    StrategyTypes,
    UserRightsEnum,
} from '../../utilities/Constants';
import { ICreateStrategyModel, IStrategySummaryModel } from '../../interfaces/IStrategy';
import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../../components/shared/dialog';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { IAssociatedOrganizationModel } from '../../interfaces/IOrganization';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import ValidateUtils from '../../shared/validations';
import { getEntityStrategyDisplay } from '../wrapper/EntityStrategyWrapper';

const _apiClient = new ApiClient();

interface IEntityStrategyProps {
    user: IUserModel;
    type: number;
    refreshIdentifier: string;
}

interface IEntityStrategyState {
    pendingResponse: boolean;
    readOnly: boolean;
    validation: IValidation;
    strategyEntityType: number;
    entityStrategies: IStrategySummaryModel[];
    entityLookupList: IMultiSelectOptions[];
    selectedEntityItem?: IMultiSelectOptions;
    showConfirmRemoveEntityStrategyDialog?: boolean;
    addNew?: boolean;
    selectedEntityStrategyGuidToDelete?: string;
    defenseCounsels: IAssociatedOrganizationModel[];
    localCounselGuid?: string;
}

export class EntityStrategy extends React.Component<IEntityStrategyProps, IEntityStrategyState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: false,
            readOnly: true,
            validation: {},
            strategyEntityType: 0,
            entityStrategies: [],
            entityLookupList: [],
            defenseCounsels: [],
        };

        this.onSearchableSingleSelectChange = this.onSearchableSingleSelectChange.bind(this);
    }

    componentDidMount() {
        switch (this.props.type) {
            case StrategyTypes.PlaintiffsFirm.Value:
                if (
                    !Authorization.isAuthorizedToStrategyType(
                        StrategyTypes.PlaintiffsFirm.Name,
                        this.props.user
                    )
                )
                    window.location.assign(LocalRoutes.AccessDenied);
                break;
            case StrategyTypes.Jurisdiction.Value:
                if (
                    !Authorization.isAuthorizedToStrategyType(
                        StrategyTypes.Jurisdiction.Name,
                        this.props.user
                    )
                )
                    window.location.assign(LocalRoutes.AccessDenied);
                break;
            case StrategyTypes.LocalCounsel.Value:
                if (
                    !Authorization.isAuthorizedToStrategyType(
                        StrategyTypes.LocalCounsel.Name,
                        this.props.user
                    )
                )
                    window.location.assign(LocalRoutes.AccessDenied);
                break;
            default:
                window.location.assign(LocalRoutes.AccessDenied);
        }

        this.loadEntityStrategies(this.props.type);
        this.entityLookupList();
    }

    componentDidUpdate(prevProps: IEntityStrategyProps) {
        if (this.props.refreshIdentifier != prevProps.refreshIdentifier) {
            this.loadEntityStrategies(this.props.type);
        }
    }

    loadEntityStrategies = async (type: number) => {
        this.setState({ pendingResponse: true });

        const response = await _apiClient.getEntityStrategies(type);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            this.setState(
                {
                    strategyEntityType: type,
                    entityStrategies: response.payload,
                    pendingResponse: false,
                    readOnly: true,
                    addNew: false,
                    selectedEntityItem: undefined,
                    localCounselGuid: undefined,
                },
                this.entityLookupList
            );
        }
    };

    entityLookupList = async () => {
        switch (this.state.strategyEntityType) {
            case StrategyTypes.PlaintiffsFirm.Value:
                this.loadPlaintiffsfirms();
                break;
            case StrategyTypes.Jurisdiction.Value:
                this.loadJurisdictions();
                break;
            case StrategyTypes.LocalCounsel.Value:
                this.fetchAllDefenseCounselsForZone();
                break;
            default:
        }
    };

    loadPlaintiffsfirms = async () => {
        this.setState({ pendingResponse: true });
        const response = await _apiClient.getLookupData(LookupDataEnums.PlaintiffsFirms);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        if (response.payload) {
            const plaintiffsfirms = response.payload;
            const plaintiffsfirmsOptions: IMultiSelectOptions[] = [];
            plaintiffsfirms.map((plaintiffsfirm: ILookupModel) => {
                const item: IMultiSelectOptions = {
                    label: plaintiffsfirm.displayName!,
                    value: plaintiffsfirm.name!,
                    id: CaseFilterType.PlaintiffsFirm.Value,
                    guid: plaintiffsfirm.guid,
                };
                plaintiffsfirmsOptions.push(item);
            });
            this.setState({ entityLookupList: plaintiffsfirmsOptions, pendingResponse: false });
        }
    };

    loadJurisdictions = async () => {
        this.setState({ pendingResponse: true });
        const response = await _apiClient.getLookupData(LookupDataEnums.Jurisdictions);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        if (response.payload) {
            const jurisdictions = response.payload;
            const jurisdictionOptions: IMultiSelectOptions[] = [];
            jurisdictions.map((jur: ILookupModel) => {
                const item: IMultiSelectOptions = {
                    label: jur.displayName!,
                    value: jur.name!,
                    id: CaseFilterType.PlaintiffsFirm.Value,
                    guid: jur.guid,
                };
                jurisdictionOptions.push(item);
            });
            this.setState({ entityLookupList: jurisdictionOptions, pendingResponse: false });
        }
    };

    fetchAllDefenseCounselsForZone = async () => {
        this.setState({ pendingResponse: true });
        const response = await _apiClient.getOrganizationInZone(OrganizationTypesEnum.Firm.Value);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            const localCounsel = response.payload;
            const localCounselOptions: IMultiSelectOptions[] = [];
            localCounsel.map((lc: IAssociatedOrganizationModel) => {
                const item: IMultiSelectOptions = {
                    label: lc.name!,
                    value: lc.name!,
                    id: 0,
                    guid: lc.guid,
                };
                localCounselOptions.push(item);
            });
            this.setState({ entityLookupList: localCounselOptions, pendingResponse: false });
        }
    };

    addEntityStrategy = async () => {
        if (!this.state.selectedEntityItem) {
            const validation: IValidation = {};
            validation.model = ['Enter ' + this.getTitle()];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true, validation: {} });
        const data: ICreateStrategyModel = {
            guid: this.state.selectedEntityItem!.guid!,
            strategyType: this.state.strategyEntityType,
        };

        const response = await _apiClient.addStrategy(data);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.loadEntityStrategies(this.state.strategyEntityType);
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let entityStrategies = this.state.entityStrategies;
        if (entityStrategies) {
            if (key.includes('Date')) {
                entityStrategies = entityStrategies.sort(Sort.compareDate(key, subKey, order));
            } else {
                entityStrategies = entityStrategies.sort(
                    Sort.compareValues(key, subKey, order, subGrandKey)
                );
            }
        }

        this.setState({ entityStrategies: entityStrategies });
    };

    showConfirmRemoveDailog = (guid: string) => {
        this.setState({
            showConfirmRemoveEntityStrategyDialog: true,
            selectedEntityStrategyGuidToDelete: guid,
        });
    };

    clickCancel = () => {
        this.setState({
            showConfirmRemoveEntityStrategyDialog: false,
            selectedEntityStrategyGuidToDelete: undefined,
        });
    };

    removeEntityStrategy = async () => {
        if (!this.state.selectedEntityStrategyGuidToDelete) return;

        const response = await _apiClient.removeStrategy(
            this.state.selectedEntityStrategyGuidToDelete,
            this.state.strategyEntityType
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        const plaintiffsFirmStrategies = this.state.entityStrategies.filter(
            (x) => x.guid != this.state.selectedEntityStrategyGuidToDelete
        );
        this.setState({
            entityStrategies: plaintiffsFirmStrategies,
            showConfirmRemoveEntityStrategyDialog: false,
        });
    };

    onSearchableSingleSelectChange(selectedItem: any) {
        this.setState({ selectedEntityItem: selectedItem, validation: {} });
    }

    authorizedToEdit = () => {
        switch (this.state.strategyEntityType) {
            case StrategyTypes.PlaintiffsFirm.Value:
                return Authorization.userHasRight(
                    UserRightsEnum.AddEditDeletePlaintiffsFirmStrategy,
                    this.props.user
                );
            case StrategyTypes.Jurisdiction.Value:
                return Authorization.userHasRight(
                    UserRightsEnum.AddEditDeleteJurisdictionStrategy,
                    this.props.user
                );
            case StrategyTypes.LocalCounsel.Value:
                return Authorization.userHasRight(
                    UserRightsEnum.AddEditDeleteLocalCounselStrategy,
                    this.props.user
                );
            default:
        }

        return false;
    };

    handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (event.target.name === 'localCounsel') {
            this.setState({ localCounselGuid: event.target.value });
        }
    };

    getTitle = () => {
        switch (this.state.strategyEntityType) {
            case StrategyTypes.PlaintiffsFirm.Value:
                return ' Plaintiffs Firm ';
            case StrategyTypes.Jurisdiction.Value:
                return ' Jurisdiction ';
            case StrategyTypes.LocalCounsel.Value:
                return ' Local Counsel ';
            default:
                return '';
        }
    };

    getDetailURL = () => {
        switch (this.state.strategyEntityType) {
            case StrategyTypes.PlaintiffsFirm.Value:
                return LocalRoutes.PlaintiffFirmStrategyDetail;
            case StrategyTypes.Jurisdiction.Value:
                return LocalRoutes.JurisdictionStrategyDetail;
            case StrategyTypes.LocalCounsel.Value:
                return LocalRoutes.LocalCounselStrategyDetail;
            default:
                return '';
        }
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        const showPackageDealsInProgress =
            this.state.strategyEntityType === StrategyTypes.PlaintiffsFirm.Value;

        return (
            <>
                <div className="row mb-3">
                    <h1 className="col-sm-4">
                        {getEntityStrategyDisplay(this.state.strategyEntityType)}
                    </h1>
                    {this.authorizedToEdit() ? (
                        <button
                            className="btn-no-bg float-end col-sm-8"
                            onClick={() => this.setState({ addNew: true })}
                        >
                            {' '}
                            <span className="btn-green btn float-end btn-icon">
                                <i className="fal fa-lg fa-plus color-white" />
                            </span>
                        </button>
                    ) : null}
                </div>
                {this.state.entityStrategies.length > 0 ? (
                    <table className="table table-sm">
                        <thead>
                            <tr style={{ verticalAlign: 'middle' }}>
                                <SortableHeader
                                    headerText="Name"
                                    sortKey="name"
                                    onSort={this.sortData}
                                />
                                <SortableHeader
                                    headerText="All Open - Active Cases"
                                    sortKey="openActiveCaseCount"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="All Open - Active SQ5 Cases"
                                    sortKey="openActiveQ5Count"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Cases with Trial Date < 12 Months"
                                    sortKey="trialDateOver12MonthsCaseCount"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                {showPackageDealsInProgress && (
                                    <SortableHeader
                                        headerText="Package Deal(s) in Progress"
                                        sortKey="packageDealsInProcess"
                                        onSort={this.sortData}
                                        noWrap
                                    />
                                )}
                                {this.authorizedToEdit() ? <th /> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.entityStrategies.map((item: IStrategySummaryModel) => {
                                return (
                                    <tr key={item.guid} style={{ verticalAlign: 'middle' }}>
                                        <td className="col-sm-3">
                                            <Link
                                                target="_blank"
                                                to={this.getDetailURL().replace(
                                                    ':guid',
                                                    item.guid!
                                                )}
                                            >
                                                {item.name}
                                            </Link>
                                        </td>
                                        <td className="col-sm-2">{item.openActiveCaseCount}</td>
                                        <td className="col-sm-2">{item.openActiveQ5Count}</td>
                                        <td className="col-sm-2">
                                            {item.trialDateOver12MonthsCaseCount}
                                        </td>
                                        {this.state.strategyEntityType ===
                                        StrategyTypes.PlaintiffsFirm.Value ? (
                                            <td className="col-sm-2">
                                                {item.packageDealsInProcess ? 'Yes' : 'No'}
                                            </td>
                                        ) : null}
                                        <td className="col-sm-1">
                                            {this.authorizedToEdit() ? (
                                                <button
                                                    className="btn btn-no-bg float-end"
                                                    onClick={() => {
                                                        this.showConfirmRemoveDailog(item.guid);
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-trash-alt"></i>
                                                </button>
                                            ) : null}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <span>No records have been created for this zone.</span>
                )}

                {this.state.addNew ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() =>
                            this.setState({ addNew: false, selectedEntityItem: undefined })
                        }
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                Add {getEntityStrategyDisplay(this.state.strategyEntityType)}{' '}
                                Strategy
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="name">
                                    {getEntityStrategyDisplay(this.state.strategyEntityType)}*
                                </label>
                                <span className="text-danger">
                                    &nbsp;{this.state.validation.name}
                                </span>
                                <CheckmateSelect
                                    options={this.state.entityLookupList}
                                    name={getEntityStrategyDisplay(this.state.strategyEntityType)}
                                    value={
                                        this.state.selectedEntityItem
                                            ? [this.state.selectedEntityItem]
                                            : []
                                    }
                                    onChange={this.onSearchableSingleSelectChange}
                                />
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            addNew: false,
                                            selectedEntityItem: undefined,
                                            localCounselGuid: undefined,
                                            validation: {},
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.addEntityStrategy}
                                >
                                    Save
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                <CheckmateDialog
                    isShowingModal={this.state.showConfirmRemoveEntityStrategyDialog || false}
                    body="Are you sure you want to delete this item? This operation is permanent and cannot be reverted back."
                    handleClose={() =>
                        this.setState({ showConfirmRemoveEntityStrategyDialog: false })
                    }
                    handleConfirm={this.removeEntityStrategy}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </>
        );
    }
}
