import CheckmateSelect from '../CheckmateSelect';
import { IQueryFilterCriteriaSelectionValue } from '../../../interfaces/IQuery';
import { useCaseStatusTypesLookup } from '../../../shared/react-query-hooks/useCaseStatusTypesLookup';
import { useMemo } from 'react';

export interface ICaseStatusSelectorProps {
    disabled?: boolean;
    multi?: boolean;
    onChange: (options: any) => void;
    value: IQueryFilterCriteriaSelectionValue[] | undefined;
    validationError?: string | undefined;
}

export function CaseStatusSelector(props: ICaseStatusSelectorProps) {
    const { data, isLoading } = useCaseStatusTypesLookup();

    const availableOptions = useMemo(
        () =>
            data?.map((caseStatus) => ({
                label: caseStatus.name,
                value: caseStatus.name,
                id: caseStatus.id!,
            })),
        [props.value, data]
    );

    const selectedOptions = useMemo(
        () => availableOptions?.filter((opt) => props.value?.map((v) => v.id).includes(opt.id)),
        [props.value, availableOptions]
    );

    return (
        <>
            <CheckmateSelect
                isMulti={props.multi}
                options={availableOptions ?? []}
                isDisabled={props.disabled}
                value={selectedOptions}
                onChange={props.onChange}
                isLoading={isLoading}
            />
            <span className="text-danger">{props.validationError}</span>
        </>
    );
}
