import {
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
} from '../../../../utilities/Constants';
import {
    BooleanSummary,
    CurrencySummary,
    DateRangeSummary,
    NumericSummary,
    SelectedValuesSummary,
    TextSummary,
} from './triggerFieldSummaries';

import { IAssertionDescriptorModel } from '../../../../interfaces/IAssertionDescriptor';
import { IItemSummaryProps } from './TriggerFieldsCell';
import { IQueryFilterCriteria } from '../../../../interfaces/IQuery';

export function AssertionDescriptorSummary(props: IItemSummaryProps) {
    const getSummaryComponent = (
        item: IQueryFilterCriteria,
        assertionDescriptorMatch?: IAssertionDescriptorModel
    ) => {
        if (!assertionDescriptorMatch) {
            return;
        }

        switch (assertionDescriptorMatch?.valueSourceType?.id) {
            case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                return <SelectedValuesSummary item={item} />;
            case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                return <BooleanSummary item={item} />;
            case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
                const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                    ? assertionDescriptorMatch.valueDisplayType.id
                    : 0;

                switch (valueDisplayTypeId) {
                    case AssertionDescriptorValueDisplayTypes.Currency.Value:
                        return <CurrencySummary item={item} />;
                    case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                    case AssertionDescriptorValueDisplayTypes.Number.Value:
                    case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                        return <NumericSummary item={item} />;
                    case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                        return <DateRangeSummary item={item} />;
                    default:
                        return <TextSummary item={item} />;
                }
            }
            default:
                break;
        }
    };

    return (
        <>
            <div>
                {props.trigger.label}:{' '}
                {getSummaryComponent(
                    props.trigger,
                    props.assertionDescriptors.find(
                        (ad) => ad.guid === props.trigger.assertionDescriptorGuid
                    )
                )}
            </div>
            {/* <pre>{JSON.stringify(props.trigger, undefined, 2)}</pre> */}
        </>
    );
}
