import { Stack } from 'react-bootstrap';
import TimeAndWeatherWidget from '../components/shared/TimeAndWeatherWidget';

export function Home() {
    return (
        <div className="home-page">
            <Stack>
                <div className="ms-auto">
                    <TimeAndWeatherWidget
                        fontColor="rgba(255,255,255,0.9)"
                        fontSize="1em"
                        margin="1.5em"
                    />
                </div>
            </Stack>
        </div>
    );
}
