import * as React from 'react';

import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { DateRangePicker } from './DateRangePicker';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { IPackageSettlementBaseModel } from '../../interfaces/ICase';
import { IQueryFilterCriteria } from '../../interfaces/IQuery';

export interface IPackageDealsCriteriaValuesProps {
    assertionDescriptors: IAssertionDescriptorModel[];
    disableInputs: boolean;
    item: IQueryFilterCriteria;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
        item: IQueryFilterCriteria
    ) => void;
    onFilterValuesSelected: (optionsList: any, item: IQueryFilterCriteria) => void;
    packageDeals: IPackageSettlementBaseModel[];
}

export function PackageDealsCriteriaValues(props: IPackageDealsCriteriaValuesProps) {
    const disablePackageDealSelector =
        !!props.item.text || !!props.item.startDate || !!props.item.endDate;

    const disableQueryFields = (props.item.selectedValues?.length ?? 0) > 0;

    return (
        <table className="table table-sm">
            <tbody>
                {props.item.validationError ? (
                    <tr>
                        <td colSpan={2}>
                            <span className="text-danger">{props.item.validationError}</span>
                        </td>
                    </tr>
                ) : null}
                <tr>
                    <td style={{ borderTop: 'none' }}>Name</td>
                    <td style={{ borderTop: 'none' }}>
                        <CheckmateSelect
                            isMulti
                            options={getPackageDealOptions(props.packageDeals)}
                            isDisabled={props.disableInputs || disablePackageDealSelector}
                            value={props.item.selectedValues}
                            onChange={(e: any) => {
                                props.onFilterValuesSelected(e, props.item);
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <input
                            className="form-control"
                            style={{ display: 'inline-block', width: '100%' }}
                            type="text"
                            placeholder="Enter at least 3 characters"
                            disabled={props.disableInputs || disableQueryFields}
                            name="packageSettlementName"
                            value={props.item.text}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                props.onChange(e, props.item);
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Date Range</td>
                    <td>
                        <DateRangePicker
                            endDate={props.item.endDate}
                            endInputFieldName="packageSettlementEndDate"
                            onEndDateChange={(e) => props.onChange(e, props.item)}
                            onStartDateChange={(e) => props.onChange(e, props.item)}
                            startDate={props.item.startDate}
                            startInputFieldName="packageSettlementStartDate"
                            disableEndDate={props.disableInputs || disableQueryFields}
                            disableStartDate={props.disableInputs || disableQueryFields}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

const getPackageDealOptions = (packageDeals: IPackageSettlementBaseModel[]) => {
    return packageDeals.map(
        (deal) =>
            ({
                label: deal.name,
                value: deal.guid!,
                guid: deal.guid!,
            } as IMultiSelectOptions)
    );
};
