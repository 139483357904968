import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import UIHelper from '../../utilities/UIHelper';
import { useEffect } from 'react';
import { useExpertReportStatusTypesLookup } from '../../shared/react-query-hooks/useExpertReportStatusTypesLookup';
import { useExpertRetainedByTypesLookup } from '../../shared/react-query-hooks/useExpertRetainedByTypesLookup';
import { useExpertsList } from '../experts/useExpertsList';

interface IExpertsCriteriaSelectorProps {
    error: string | undefined;
    onExpertChange: (optionsList: any) => void;
    onRetainedByChange: (optionsList: any) => void;
    onReportStatusChange: (optionsList: any) => void;
    selectedExpertGuids: string[];
    selectedRetainedByTypeIds: number[];
    selectedReportStatusTypeIds: number[];
    readonly?: boolean;
}

export function ExpertsCriteriaSelector(props: IExpertsCriteriaSelectorProps) {
    const expertsList = useExpertsList();
    const retainedByTypes = useExpertRetainedByTypesLookup();
    const reportStatusTypes = useExpertReportStatusTypesLookup();

    const availableExpertsOptions: IMultiSelectOptions[] =
        expertsList.data?.map(
            (expert) =>
                ({
                    label: UIHelper.formatExpertName(expert),
                    value: expert.guid,
                } as IMultiSelectOptions)
        ) ?? [];

    const availableRetainedByTypesOptions = (retainedByTypes.data ?? []).map(
        (lu) =>
            ({
                id: lu.id,
                label: lu.alternateDisplayName ?? lu.displayName ?? lu.name,
                value: lu.id.toString(),
            } as IMultiSelectOptions)
    );

    const availableReportStatusTypesOptions = reportStatusTypes.data ?? [];

    const selectedExpertsOptions = availableExpertsOptions.filter((opt) =>
        props.selectedExpertGuids.includes(opt.value ?? '')
    );

    const selectedRetainedByTypesOptions = availableRetainedByTypesOptions.filter((opt) =>
        props.selectedRetainedByTypeIds.includes(opt.id)
    );

    const selectedReportStatusTypesOptions = availableReportStatusTypesOptions.filter((opt) =>
        props.selectedReportStatusTypeIds.includes(opt.id)
    );

    useEffect(() => {
        if (props.readonly) {
            props.onExpertChange([]);
            props.onRetainedByChange([]);
            props.onReportStatusChange([]);
        }
    }, [props.readonly]);

    return (
        <table className="table table-sm">
            <tbody>
                {props.error ? (
                    <tr>
                        <td colSpan={2}>
                            <span className="text-danger">{props.error}</span>
                        </td>
                    </tr>
                ) : null}
                <tr>
                    <td style={{ borderTop: 'none', whiteSpace: 'nowrap' }}>Expert</td>
                    <td style={{ borderTop: 'none', width: '100%' }}>
                        <CheckmateSelect
                            isMulti={true}
                            options={availableExpertsOptions}
                            value={selectedExpertsOptions}
                            onChange={props.onExpertChange}
                            isDisabled={props.readonly}
                        />
                    </td>
                </tr>
                <tr style={{ whiteSpace: 'nowrap' }}>
                    <td style={{ paddingRight: '15px' }}>Retained By</td>
                    <td style={{ width: '100%' }}>
                        <CheckmateSelect
                            isMulti={true}
                            options={availableRetainedByTypesOptions}
                            value={selectedRetainedByTypesOptions}
                            onChange={props.onRetainedByChange}
                            isDisabled={props.readonly}
                        />
                    </td>
                </tr>
                <tr style={{ whiteSpace: 'nowrap' }}>
                    <td>Report Status</td>
                    <td style={{ width: '100%' }}>
                        <CheckmateSelect
                            isMulti={true}
                            options={availableReportStatusTypesOptions}
                            value={selectedReportStatusTypesOptions}
                            onChange={props.onReportStatusChange}
                            isDisabled={props.readonly}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
