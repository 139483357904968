import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { ZoneNotes } from '../notes/ZoneNotes';
import { useLocation } from 'react-router';

function ZoneNotesWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();
    return (
        <>
            <DocumentTitle title="Zone Notes"></DocumentTitle>
            <ZoneNotes user={props.user} refreshIdentifier={key} />
        </>
    );
}

export default ZoneNotesWrapper;
