import { useParams } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { OrganizationExperienceDetail } from '../organization/OrganizationExperienceDetail';

function OrganizationExperienceDetailWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return <OrganizationExperienceDetail portfolioGuid={guid || ''} user={props.user} />;
}

export default OrganizationExperienceDetailWrapper;
