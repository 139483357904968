import { AssertionDescriptorSummary } from './AssertionDescriptorSummary';
import { ITriggerRuleInfoCellProps as ICriteriaInfoCellProps } from '../TriggerRulesTable';
import { IQueryFilterCriteria } from '../../../../interfaces/IQuery';
import { StaticItemSummary } from './StaticItemSummary';

export function TriggerCriteriaCell(props: ICriteriaInfoCellProps) {
    return (
        <td>
            {props.triggerRules.map((trigger, idx) => {
                if (trigger.isAssertionDescriptor) {
                    return <AssertionDescriptorSummary key={idx} {...props} trigger={trigger} />;
                } else {
                    return <StaticItemSummary key={idx} {...props} trigger={trigger} />;
                }
            })}
        </td>
    );
}

export interface IItemSummaryProps extends ICriteriaInfoCellProps {
    trigger: IQueryFilterCriteria;
}
