import { ApplicationHelpType, EmptyGuid, UserRightsEnum } from '../../../../utilities/Constants';
import { ILookupModel, IMultiSelectOptions } from '../../../../interfaces/ILookup';
import { useEffect, useState } from 'react';

import Authorization from '../../../../stores/Authorization';
import { Help } from '../../../shared/Help';
import { ICaseExpertModel } from '../../../../interfaces/ICaseExpert';
import { IUserModel } from '../../../../interfaces/IUser';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { SortableHeader } from '../../../shared/SortableHeader';
import classNames from 'classnames';
import { useExpertReportStatusTypesLookup } from '../../../../shared/react-query-hooks/useExpertReportStatusTypesLookup';
import { useExpertRetainedByTypesLookup } from '../../../../shared/react-query-hooks/useExpertRetainedByTypesLookup';
import { useSort } from '../../../../hooks/useSort';

interface ICaseExpertTableProps {
    canDelete: boolean;
    canEdit: boolean;
    canView: boolean;
    canAddWhenEmpty?: boolean;
    caseExperts: ICaseExpertModel[];
    getEasyUpdateIconColorStyle?: (caseGuid: string, noteGuid?: string) => string;
    hideNoRecordsMessage?: boolean;
    noRecordsMessage?: string;
    onCaseExpertRemoveClick?: (caseExpertGuid: string) => void;
    onCaseExpertViewEditClick?: (caseExpertGuid: string, defaultIsEditMode: boolean) => void;
    onEasyUpdateClick?: (caseExpertGuid: string) => void;
    onRowAddClick: (caseGuid: string) => void;
    showCaseName?: boolean;
    showEasyUpdateIcon?: boolean;
    user: IUserModel;
}

interface ICaseExpertModelSortable extends ICaseExpertModel {
    expertRetainedByTypeSort: string;
    expertReportStatusTypeSort: string;
}

export function CaseExpertTable(props: ICaseExpertTableProps) {
    const retainedByTypes = useExpertRetainedByTypesLookup();
    const expertReportStatusTypes = useExpertReportStatusTypesLookup();
    const authorizedToViewExpert = Authorization.userHasRight(
        UserRightsEnum.ViewExpert,
        props.user
    );

    const [sortableExperts, setSortableExperts] = useState<ICaseExpertModelSortable[]>([]);
    const { handleSort, sortedData } = useSort<ICaseExpertModelSortable>(sortableExperts);

    useEffect(() => {
        const sortMapper = (ce: ICaseExpertModel) =>
            ({
                ...ce,
                expertRetainedByTypeSort:
                    (retainedByTypes.data ?? []).find((rbt) => rbt.id === ce.expertRetainedByTypeId)
                        ?.displayName ?? '',
                expertReportStatusTypeSort:
                    (expertReportStatusTypes.data ?? []).find(
                        (rst) => rst.id === ce.expertReportStatusTypeId
                    )?.label ?? '',
            } as ICaseExpertModelSortable);

        setSortableExperts(props.caseExperts.map(sortMapper));
    }, [retainedByTypes.data, expertReportStatusTypes.data, props.caseExperts]);

    return (
        <>
            {sortedData.length > 0 || props.hideNoRecordsMessage ? (
                <table className="table table-sm">
                    <thead>
                        <CaseExpertsListHeaderRow
                            onSort={handleSort}
                            showCaseName={props.showCaseName ?? false}
                            showEasyUpdateIcon={
                                !!props.showEasyUpdateIcon &&
                                typeof props.onEasyUpdateClick === 'function'
                            }
                        />
                    </thead>
                    <tbody>
                        {sortedData.map((item: ICaseExpertModel) => {
                            return (
                                <CaseExpertsListRow
                                    key={`${item.guid}${item.caseGuid}`}
                                    caseExpert={item}
                                    canDelete={
                                        props.canDelete &&
                                        Authorization.userHasRight(
                                            UserRightsEnum.DeleteCaseExpert,
                                            props.user
                                        )
                                    }
                                    canEdit={
                                        props.canEdit &&
                                        Authorization.userHasRight(
                                            UserRightsEnum.UpdateCaseExpert,
                                            props.user
                                        )
                                    }
                                    canView={
                                        props.canView &&
                                        Authorization.userHasRight(
                                            UserRightsEnum.ViewCaseExpert,
                                            props.user
                                        )
                                    }
                                    canViewExpert={authorizedToViewExpert}
                                    canAddWhenEmpty={
                                        props.canAddWhenEmpty &&
                                        Authorization.userHasRight(
                                            UserRightsEnum.AddCaseExpert,
                                            props.user
                                        )
                                    }
                                    getEasyUpdateIconColorStyle={props.getEasyUpdateIconColorStyle}
                                    isEmptyEntity={item.guid === EmptyGuid}
                                    onAddClick={props.onRowAddClick}
                                    onDelete={props.onCaseExpertRemoveClick}
                                    onEasyUpdateClick={props.onEasyUpdateClick}
                                    onViewEdit={props.onCaseExpertViewEditClick}
                                    retainedByTypes={retainedByTypes.data ?? []}
                                    showCaseName={!!props.showCaseName}
                                    showEasyUpdateIcon={
                                        !!props.showEasyUpdateIcon &&
                                        typeof props.onEasyUpdateClick === 'function'
                                    }
                                    expertReportStatusTypes={expertReportStatusTypes.data ?? []}
                                />
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <span>{props.noRecordsMessage ?? 'No case experts meet this criteria.'}</span>
            )}
        </>
    );
}

interface ICaseExpertsListHeaderRowProps {
    onSort: (key: string, order: string, subKey?: string, subGrandKey?: string) => void;
    showCaseName: boolean;
    showEasyUpdateIcon: boolean;
}

function CaseExpertsListHeaderRow({
    onSort,
    showCaseName = false,
    showEasyUpdateIcon = false,
}: ICaseExpertsListHeaderRowProps) {
    return (
        <tr style={{ verticalAlign: 'middle' }}>
            {showCaseName && (
                <SortableHeader headerText="Case Name" sortKey="caseName" onSort={onSort} />
            )}
            <SortableHeader headerText="Name" sortKey="name" onSort={onSort} />
            <th style={{ paddingRight: '20px' }}>Discipline(s)</th>
            <SortableHeader
                headerText="Retained by"
                sortKey="expertRetainedByTypeSort"
                onSort={onSort}
                thStyle={{ paddingRight: '20px' }}
                noWrap
            />
            <SortableHeader
                headerComponent={
                    <span style={{ whiteSpace: 'nowrap' }}>
                        Report Status
                        <Help
                            type={ApplicationHelpType.Info}
                            title="Expert Report Status"
                            helpText="Expert Report status is updated by assigned NCC users; depending on your user access levels, you may not be able to download the report unless the status is 'disclosed'. "
                        />
                    </span>
                }
                sortKey="expertReportStatusTypeSort"
                onSort={onSort}
            />
            <th />
            {showEasyUpdateIcon && <th style={{ padding: '0 12px' }} />}
        </tr>
    );
}

interface ICaseExpertsListRowProps {
    canDelete: boolean;
    canEdit: boolean;
    canView: boolean;
    canViewExpert: boolean;
    canAddWhenEmpty?: boolean;
    caseExpert: ICaseExpertModel;
    expertReportStatusTypes: IMultiSelectOptions[];
    getEasyUpdateIconColorStyle?: (caseGuid: string, noteGuid?: string) => string;
    isEmptyEntity: boolean;
    onAddClick: (caseGuid: string) => void;
    onDelete?: (expertGuid: string) => void;
    onEasyUpdateClick?: (caseGuid: string) => void;
    onViewEdit?: (expertGuid: string, defaultIsEditMode: boolean) => void;
    retainedByTypes: ILookupModel[];
    showCaseName: boolean;
    showEasyUpdateIcon: boolean;
}

function CaseExpertsListRow({
    canDelete,
    canEdit,
    canView,
    canViewExpert,
    canAddWhenEmpty,
    caseExpert,
    expertReportStatusTypes,
    getEasyUpdateIconColorStyle,
    isEmptyEntity,
    onAddClick,
    onDelete,
    onEasyUpdateClick,
    onViewEdit,
    retainedByTypes,
    showCaseName = false,
    showEasyUpdateIcon = false,
}: ICaseExpertsListRowProps) {
    const retainedBy = retainedByTypes.find(
        (retainedByType) => retainedByType.id === caseExpert.expertRetainedByTypeId
    )?.displayName;

    const expertReportStatusType = expertReportStatusTypes.find(
        (reportStatusType) => reportStatusType.id === caseExpert.expertReportStatusTypeId
    )?.label;

    const easyUpdateIconStyle = classNames(
        `fal fa-edit`,
        getEasyUpdateIconColorStyle?.(caseExpert.caseGuid ?? '')
    );

    const handleRowAddClick = () => {
        if (caseExpert?.caseGuid && caseExpert?.caseGuid !== EmptyGuid) {
            onAddClick(caseExpert.caseGuid);
        }
    };

    return (
        <tr key={caseExpert.guid}>
            {showCaseName && (
                <td>
                    {caseExpert.caseGuid && (
                        <Link
                            to={LocalRoutes.CaseFile.replace(':guid', caseExpert.caseGuid)}
                            target="_blank"
                        >
                            {caseExpert.caseName}
                        </Link>
                    )}
                </td>
            )}
            <td className="col-sm-2">
                {canViewExpert ? (
                    <Link
                        to={LocalRoutes.ExpertDetail.replace(':id', caseExpert.expertGuid)}
                        target="_blank"
                    >
                        {caseExpert.name}
                    </Link>
                ) : (
                    <>{caseExpert.name}</>
                )}
            </td>
            <td style={{ paddingRight: '20px' }}>
                {caseExpert.disciplines?.map((disc) => disc.displayName).join(' | ')}
            </td>
            <td style={{ whiteSpace: 'nowrap', paddingRight: '20px' }}>{retainedBy}</td>
            <td style={{ paddingRight: '15px', whiteSpace: 'nowrap' }}>{expertReportStatusType}</td>
            <td className="table-actions">
                <div
                    className="text-nowrap"
                    style={{ display: 'flex', gap: 0, justifyContent: 'flex-end' }}
                >
                    {!isEmptyEntity && (
                        <>
                            {canView && typeof onViewEdit === 'function' && (
                                <button
                                    className="btn btn-no-bg"
                                    style={{ margin: 0 }}
                                    onClick={() => {
                                        onViewEdit(caseExpert.guid, false);
                                    }}
                                >
                                    <i className="fal fa-lg fa-eye" />
                                </button>
                            )}

                            {canEdit && typeof onViewEdit === 'function' && (
                                <button
                                    className="btn btn-no-bg"
                                    style={{ margin: 0 }}
                                    onClick={() => {
                                        onViewEdit(caseExpert.guid, true);
                                    }}
                                >
                                    <i className="fal fa-lg fa-pen" />
                                </button>
                            )}

                            {canDelete && typeof onDelete === 'function' && (
                                <button
                                    className="btn btn-no-bg"
                                    style={{ margin: 0 }}
                                    onClick={() => onDelete(caseExpert.guid)}
                                >
                                    <i className="fal fa-lg fa-trash-alt"></i>
                                </button>
                            )}
                        </>
                    )}

                    {isEmptyEntity && canAddWhenEmpty && (
                        <button
                            className="btn btn-no-bg"
                            style={{ margin: 0 }}
                            onClick={handleRowAddClick}
                        >
                            <i className="fal fa-lg fa-plus"></i>
                        </button>
                    )}
                </div>
            </td>
            {showEasyUpdateIcon && typeof onEasyUpdateClick === 'function' && (
                <td className="table-actions">
                    <button
                        className="btn btn-no-bg"
                        style={{ margin: 0 }}
                        onClick={() => {
                            if (caseExpert.caseGuid) {
                                onEasyUpdateClick(caseExpert.caseGuid);
                            }
                        }}
                    >
                        <i className={easyUpdateIconStyle} />
                    </button>
                </td>
            )}
        </tr>
    );
}
