import { IRequestProcessor } from '../interfaces/IRequestProcessor';
import { IApiRequest } from '../interfaces/IApiRequest';
import { IApiRequestOptions } from '../interfaces/IApiRequestOptions';
import { IApiResponse } from '../interfaces/IApiResponse';

class BaseRequestProcessor implements IRequestProcessor {
    _owner?: IRequestProcessor;

    /**
     * Constructs a new request processor.
     * @param {RequestProcessor | null} owner The request processor that owns this instance.
     */
    constructor(owner: IRequestProcessor|undefined) {
        this._owner = owner;
    }

    /**
     * Prepares the request for transmission.
     * @param {IApiRequest<T>} request The request object.
     * @returns {Promise<ApiRequest>} The prepared request object.
     */
    async prepareRequest<T>(request: IApiRequest<T>) {
        if (this._owner) {
            request = await this._owner.prepareRequest(request);
        }
        return request;
    }

    /**
     * Processes a response after completion.
     * @param {ApiRequest} request The request object.
     * @param {ApiResponse} response The response object.
     * @returns {Promise<ApiResponse>} The processed response object.
     */
    async processResponse<TRequest, TResponse>(request: IApiRequest<TRequest>, response: IApiResponse<TResponse>): Promise<IApiResponse<TResponse>> {
        if (this._owner) {
            response = await this._owner.processResponse(request, response);
        }
        return response;
    }
}

export default BaseRequestProcessor;