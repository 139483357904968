import { CommentsButton } from './CommentsButton';
import { TimelineReportSections } from '../../../../../utilities/Constants';

interface ISectionHeaderProps {
    checked: boolean | undefined;
    helpComponent?: JSX.Element | null;
    isPreviewMode: boolean;
    onCommentsButtonClick: (section: number) => void;
    onSectionCheckedChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => void;
    readonly: boolean;
    section: number;
    title: string;
}

export function SectionHeader(props: ISectionHeaderProps) {
    return (
        <h4 style={{ marginTop: 25 }}>
            {!props.isPreviewMode && (
                <input
                    className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                    type="checkbox"
                    checked={props.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        props.onSectionCheckedChange(e, props.section);
                    }}
                    disabled={props.readonly}
                />
            )}
            {props.title}
            {props.helpComponent && !props.isPreviewMode && <>{props.helpComponent}</>}
            <CommentsButton
                onClick={props.onCommentsButtonClick}
                section={props.section}
                show={!props.readonly}
            />
        </h4>
    );
}
