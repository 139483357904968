import {
    AlertTypes,
    AssertionDescriptorCategories,
    AssertionDescriptorValueDisplayTypes,
    DataScopesEnum,
    EntityTypes,
    NotePurposeTypesEnum,
    NoteTypes,
    StrategySignificantChangesAssertionDescriptors,
    StrategyTypes,
    UserRightsEnum,
} from './Constants';
import { IEntityAssertionModel, IEntityModel } from '../interfaces/IAssertionDescriptor';
import { INoteCategoryModel, INoteCategoryTypeModel, INoteModel } from '../interfaces/INote';

import Authorization from '../stores/Authorization';
import Common from '../stores/Common';
import { ICaseTitleModel } from '../interfaces/ICase';
import { IMultiSelectOptions } from '../interfaces/ILookup';
import { IUserModel } from '../interfaces/IUser';
import Sort from '../stores/Sort';

export default class NoteHelper {
    static updateCurrentNoteOnCategoryChange(
        currentNote: INoteModel,
        optionsList: any,
        noteCategoryTypes: INoteCategoryTypeModel[]
    ) {
        const existingNoteCategories: INoteCategoryModel[] = currentNote.categories
            ? JSON.parse(JSON.stringify(currentNote.categories))
            : [];

        currentNote.categories = [];
        if (optionsList) {
            for (let i = 0; i < optionsList.length; i++) {
                const match = existingNoteCategories.filter((x) => x.id == optionsList[i].id);
                currentNote.categories.push({
                    id: optionsList[i].id,
                    name: optionsList[i].value,
                    displayName: optionsList[i].displayName,
                    assertions: match.length > 0 ? match[0].assertions : undefined,
                    subCategory: match.length > 0 ? match[0].subCategory : undefined,
                });
                const categoryLookupMatch = noteCategoryTypes.find(
                    (x) => x.id === optionsList[i].id
                );
                if (
                    categoryLookupMatch &&
                    (!categoryLookupMatch.subCategories ||
                        categoryLookupMatch.subCategories.length === 0)
                ) {
                    if (!currentNote.content && categoryLookupMatch.contentTemplateText)
                        currentNote.content = categoryLookupMatch.contentTemplateText;
                    if (!currentNote.context && categoryLookupMatch.contextTemplateText)
                        currentNote.context = categoryLookupMatch.contextTemplateText;
                }
            }
        }

        return currentNote;
    }

    static getDisplayValueForEntityAssertion = (assertion: IEntityAssertionModel) => {
        if (assertion.assertionDescriptorValue) return assertion.assertionDescriptorValue.text;
        if (assertion.dateTimeValue) return Common.dateFormat(assertion.dateTimeValue);
        if (assertion.booleanValue != undefined) return assertion.booleanValue ? 'Yes' : 'No';
        if (assertion.numericValue) {
            if (assertion.assertionDescriptor && assertion.assertionDescriptor.valueDisplayType) {
                switch (assertion.assertionDescriptor.valueDisplayType.id) {
                    case AssertionDescriptorValueDisplayTypes.Currency.Value:
                        return Common.formatCurrency(Math.round(assertion.numericValue).toString());
                    case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                        return Math.round(assertion.numericValue).toString() + '%';
                    case AssertionDescriptorValueDisplayTypes.Number.Value:
                        return Math.round(assertion.numericValue).toString();
                    case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                        return assertion.numericValue.toFixed(2).toString();
                }
            }

            return assertion.numericValue.toString();
        }

        return assertion.textValue;
    };

    static getNoteCategoryTypeOptionsForMultiselect = (
        noteCategoryTypes: INoteCategoryTypeModel[]
    ): IMultiSelectOptions[] => {
        let noteCategoryTypeOptions: IMultiSelectOptions[] = [];
        noteCategoryTypes.map((cat: INoteCategoryTypeModel) => {
            const item: IMultiSelectOptions = {
                label: cat.displayName!,
                value: cat.name!,
                id: cat.id,
            }; // Use TypeId to hold the actual Status.Id unlike the other filters
            noteCategoryTypeOptions.push(item);
        });

        noteCategoryTypeOptions = noteCategoryTypeOptions.sort(Sort.compareValues('label'));
        return noteCategoryTypeOptions;
    };

    static getAdditionalInfoContent = (item: INoteModel): any => {
        const content: string[] = [];
        if (item.categories) {
            item.categories.map((itemCategory: INoteCategoryModel) => {
                if (itemCategory.assertions) {
                    itemCategory.assertions.map((assertion: IEntityAssertionModel) => {
                        const value = NoteHelper.getDisplayValueForEntityAssertion(assertion);
                        if (value) content.push(value);
                    });
                }
            });
        }

        return content;
    };

    static getNotesAdditionalEntityAssertionNames = (item?: INoteModel): string[] => {
        const columnNames: string[] = [];

        if (item && item.categories) {
            {
                item.categories.map((itemCategory: INoteCategoryModel) => {
                    if (itemCategory.assertions) {
                        itemCategory.assertions.map((assertion: IEntityAssertionModel) => {
                            columnNames.push(assertion.assertionDescriptor!.displayName!);
                        });
                    }
                });
            }
        }

        return columnNames;
    };

    static getNotesAdditionalEntityAssertionValues = (item?: INoteModel): string[] => {
        const values: string[] = [];

        if (item && item.categories) {
            {
                item.categories.map((itemCategory: INoteCategoryModel) => {
                    if (itemCategory.assertions) {
                        itemCategory.assertions.map((assertion: IEntityAssertionModel) => {
                            const value = NoteHelper.getDisplayValueForEntityAssertion(assertion);
                            if (value) values.push(value);
                        });
                    }
                });
            }
        }

        return values;
    };

    static getAssertionValue = (item: INoteModel, adName: string) => {
        if (
            item.categories &&
            item.categories!.length > 0 &&
            item.categories[0]!.assertions &&
            item.categories[0]!.assertions!.length > 0
        ) {
            let match: IEntityAssertionModel | undefined;
            if (adName.toUpperCase() === 'CHANGE')
                match = item.categories[0]!.assertions!.find(
                    (x) =>
                        x.assertionDescriptor &&
                        x.assertionDescriptor.guid!.toUpperCase() ==
                            StrategySignificantChangesAssertionDescriptors.Strategy_SignificantChanges_Change
                );
            else if (adName.toUpperCase() === 'DATE')
                match = item.categories[0]!.assertions!.find(
                    (x) =>
                        x.assertionDescriptor &&
                        x.assertionDescriptor.guid!.toUpperCase() ==
                            StrategySignificantChangesAssertionDescriptors.Strategy_SignificantChanges_Date
                );
            if (match) return NoteHelper.getDisplayValueForEntityAssertion(match);
        }

        return '';
    };

    static getAssociatedAssertionDescriptorListForSignificantChanges = (
        assertions: IEntityAssertionModel[]
    ) => {
        const list: IMultiSelectOptions[] = [];

        for (let i = 0; i < assertions.length; i++) {
            if (
                (assertions[i].assertionDescriptor!.category!.id ===
                    AssertionDescriptorCategories.Strategy_Principles.Value ||
                    assertions[i].assertionDescriptor!.category!.id ===
                        AssertionDescriptorCategories.Strategy_Goals.Value ||
                    assertions[i].assertionDescriptor!.category!.id ===
                        AssertionDescriptorCategories.Strategy_Situation.Value) &&
                list.findIndex((x) => x.guid === assertions[i].assertionDescriptor!.guid) === -1
            )
                list.push({
                    label:
                        assertions[i].assertionDescriptor!.category!.displayName! +
                        ' - ' +
                        assertions[i].assertionDescriptor!.displayName!,
                    id: 0,
                    guid: assertions[i].assertionDescriptor!.guid,
                    value: assertions[i].assertionDescriptor!.guid!,
                });
        }

        return list;
    };

    static getSelectedValuesTextFromMultiSelect(selectedItems: IMultiSelectOptions[]) {
        if (selectedItems.length == 0) return '';
        let title = 'SELECTED VALUES:';
        for (let i = 0; i < selectedItems.length; i++) {
            title = title + '\n' + selectedItems[i].label;
        }
        return title;
    }

    static hasSignificantChange = (adGuid: string, significantChanges: INoteModel[]) => {
        for (let i = 0; i < significantChanges.length; i++) {
            if (
                significantChanges[i].associations &&
                significantChanges[i].associations!.filter(
                    (x) =>
                        x.entityType &&
                        x.entityType.id === EntityTypes.AssertionDescriptor &&
                        x.entityGuid === adGuid
                ).length > 0
            ) {
                return true;
            }
        }
        return false;
    };

    static getAssociatedTopicsForSignificantChanges = (significantChange: INoteModel) => {
        if (significantChange.associations) {
            const output: string[] = [];
            significantChange.associations
                .filter((x) => x.displayName)
                .map((item: IEntityModel) => {
                    return output.push(item.displayName!);
                });
            return output.join('\n').toString();
        }

        return '';
    };

    static allowEditByDataScope = (note: INoteModel, allowEditRight: boolean, user: IUserModel) => {
        if (!allowEditRight) return false;
        const dataScopeId = Authorization.getUserDefaultOrganizationDataScope(user);
        switch (dataScopeId) {
            case DataScopesEnum.LocalBasic.Value:
                return note.allowLocalEdit || false;
            default:
                return true;
        }
    };

    static isUserAuthorizedToEditNote = (
        currentNote: INoteModel,
        user: IUserModel,
        userHasViewRight: boolean,
        userHasEditRight: boolean,
        userHasAddNewRight: boolean
    ) => {
        if (!currentNote.guid && userHasAddNewRight) return true; // A new note is being added
        if (userHasViewRight && !userHasEditRight) return false;
        if (userHasEditRight) {
            const dataScopeId = Authorization.getUserDefaultOrganizationDataScope(user);
            switch (dataScopeId) {
                case DataScopesEnum.LocalBasic.Value:
                    if (currentNote.allowLocalEdit) return true;

                    // If the current action target is Local, then all users in that Local Counsel that have access to the case can edit the note.
                    if (
                        currentNote.type &&
                        currentNote.type.id === NoteTypes.CaseNote &&
                        currentNote.localCounselOrganizationGuid &&
                        currentNote.alert &&
                        currentNote.alert.users &&
                        currentNote.alert.users.filter(
                            (x) =>
                                x.type &&
                                x.type.id === AlertTypes.Act.Value &&
                                x.user &&
                                x.user.guid
                        ).length > 0
                    ) {
                        const actionTarget = currentNote.alert.users.filter(
                            (x) =>
                                x.type &&
                                x.type.id === AlertTypes.Act.Value &&
                                x.user &&
                                x.user.guid
                        )[0];
                        if (
                            actionTarget.dataScope &&
                            actionTarget.dataScope.id === DataScopesEnum.LocalBasic.Value
                        ) {
                            if (actionTarget.user!.guid!.toUpperCase() === user.guid!.toUpperCase())
                                return false; // If logged in user is the action target
                            if (
                                user.homeOrganization!.guid ===
                                currentNote.localCounselOrganizationGuid
                            )
                                return true; // If logged in User's home org is the same as case's Local Counsel Org
                        }
                    }

                    return false;
                default:
                    return true;
            }
        }
        return false;
    };

    static allowNoteEdit = (
        currentNote: INoteModel,
        loggedInUser: IUserModel,
        strategyTypeId?: number,
        parentEntityLocked?: boolean
    ) => {
        const noteTypeId = currentNote.type?.id;
        const loggedInUserDataScopeId =
            Authorization.getUserDefaultOrganizationDataScope(loggedInUser);
        let userHasEditRight = false;

        switch (noteTypeId) {
            case NoteTypes.CaseNote:
                userHasEditRight =
                    !parentEntityLocked &&
                    Authorization.userHasRight(UserRightsEnum.EditCaseNote, loggedInUser);

                if (
                    userHasEditRight &&
                    loggedInUserDataScopeId === DataScopesEnum.LocalBasic.Value
                ) {
                    if (currentNote.allowLocalEdit) break;

                    userHasEditRight = false;

                    // If the current action target is Local, then all users in that Local Counsel that have access to the case can edit the note.
                    if (
                        currentNote.localCounselOrganizationGuid &&
                        currentNote.alert?.users?.some(
                            (x) =>
                                x.type &&
                                x.type.id === AlertTypes.Act.Value &&
                                x.user &&
                                x.user.guid
                        )
                    ) {
                        const actionTargetFilter = currentNote.alert.users.filter(
                            (x) =>
                                x.type &&
                                x.type.id === AlertTypes.Act.Value &&
                                x.user &&
                                x.user.guid
                        );
                        if (actionTargetFilter.length > 0) {
                            const actionTarget = actionTargetFilter[0];
                            if (
                                // Logged In LOCAL USER is the Action Target, Hence Can edit
                                (actionTarget?.user?.guid ?? '').toUpperCase() ===
                                (loggedInUser.guid ?? '').toUpperCase()
                            )
                                userHasEditRight = true;
                            else if (
                                // Action Target Home Org = Local Counsel
                                (actionTarget?.user?.homeOrganization?.guid ?? '').toUpperCase() ===
                                    (loggedInUser.homeOrganization?.guid ?? '').toUpperCase() &&
                                loggedInUser.homeOrganization?.guid ===
                                    currentNote.localCounselOrganizationGuid
                            )
                                userHasEditRight = true;
                        }
                    }
                }
                break;
            case NoteTypes.ZoneNote:
                userHasEditRight =
                    Authorization.userHasRight(UserRightsEnum.EditZoneNote, loggedInUser) &&
                    (loggedInUserDataScopeId !== DataScopesEnum.LocalBasic.Value ||
                        currentNote.allowLocalEdit === true);

                break;
            case NoteTypes.PackageSettlementNote:
                userHasEditRight =
                    !parentEntityLocked &&
                    Authorization.userHasRight(
                        UserRightsEnum.AddEditDeletePackageSettlement,
                        loggedInUser
                    );

                break;
            case NoteTypes.StrategyNote:
                switch (strategyTypeId) {
                    case StrategyTypes.StrategicPlan.Value:
                        userHasEditRight = Authorization.userHasRight(
                            UserRightsEnum.EditStrategicPlan,
                            loggedInUser
                        );

                        break;
                    case StrategyTypes.ExecutionPlan.Value:
                        userHasEditRight = Authorization.userHasRight(
                            UserRightsEnum.EditExecutionPlan,
                            loggedInUser
                        );

                        break;
                    case StrategyTypes.PlaintiffsFirm.Value:
                        userHasEditRight = Authorization.userHasRight(
                            UserRightsEnum.AddEditDeletePlaintiffsFirmStrategy,
                            loggedInUser
                        );

                        break;
                    case StrategyTypes.Jurisdiction.Value:
                        userHasEditRight = Authorization.userHasRight(
                            UserRightsEnum.AddEditDeleteJurisdictionStrategy,
                            loggedInUser
                        );

                        break;
                    case StrategyTypes.Projects.Value:
                        userHasEditRight = Authorization.userHasRight(
                            UserRightsEnum.AddEditDeleteProject,
                            loggedInUser
                        );

                        break;
                    case StrategyTypes.LocalCounsel.Value:
                        userHasEditRight = Authorization.userHasRight(
                            UserRightsEnum.AddEditDeleteLocalCounselStrategy,
                            loggedInUser
                        );

                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        return userHasEditRight;
    };

    static getNoteDecisionText = (item: INoteModel) => {
        let text = '';

        if (!item.purpose) return text;

        switch (item.purpose.id) {
            case NotePurposeTypesEnum.Acknowledgment:
                text = item.acknowledgmentStatus
                    ? item.acknowledgmentStatus.displayName || item.acknowledgmentStatus.name!
                    : '';
                if (item.acknowledgmentStatusModifiedBy) {
                    if (text) text += ' | ';
                    text +=
                        item.acknowledgmentStatusModifiedBy.profile!.firstName +
                        ' ' +
                        item.acknowledgmentStatusModifiedBy.profile!.lastName;
                }

                if (item.acknowledgmentStatusModifiedDate) {
                    if (text) text += ' | ';
                    text += Common.dateTimeFormatToLocal(
                        item.acknowledgmentStatusModifiedDate.toString()
                    );
                }
                break;
            case NotePurposeTypesEnum.Approval:
                text = item.approvalStatus
                    ? item.approvalStatus.displayName || item.approvalStatus.name!
                    : '';
                if (item.approvalStatusModifiedBy) {
                    if (text) text += ' | ';
                    text +=
                        item.approvalStatusModifiedBy.profile!.firstName +
                        ' ' +
                        item.approvalStatusModifiedBy.profile!.lastName;
                }

                if (item.approvalStatusModifiedDate) {
                    if (text) text += ' | ';
                    text += Common.dateTimeFormatToLocal(
                        item.approvalStatusModifiedDate.toString()
                    );
                }
                break;
            default:
        }

        return text;
    };

    static getModifiedByName = (user?: IUserModel) => {
        if (user && user.profile && user.profile.firstName && user.profile.lastName) {
            return user.profile.firstName.concat(' ', user.profile.lastName);
        }
        return '';
    };

    static getCaseTitle = (currentNote: INoteModel) => {
        if (currentNote.type && currentNote.type.id === NoteTypes.CaseNote) {
            const caseTitle: ICaseTitleModel = {
                caseName: currentNote.caseName,
                guid: currentNote.caseGuid,
                SQScore: currentNote.qScore,
                SQProjection: currentNote.qProjection,
            };
            return caseTitle;
        }
    };
}
