import { SortableHeader } from '../../components/shared/SortableHeader';

interface IDocumentsHeaderProps {
    onSort: (key: string, order: string, subKey?: string, subGrandKey?: string) => void;
}

export function DocumentsHeader({ onSort }: IDocumentsHeaderProps) {
    return (
        <tr>
            <SortableHeader headerText="Name" sortKey="filename" onSort={onSort} />
            <SortableHeader headerText="Type" sortKey="type" onSort={onSort} />
            <SortableHeader headerText="Sub Type" sortKey="subType" onSort={onSort} noWrap />
            <SortableHeader
                sortKey="isCollab"
                headerComponent={<i className="fal fa-users" title="Is Collab" />}
                onSort={onSort}
            />
            <SortableHeader headerText="Date" sortKey="createdDate" onSort={onSort} />
            <SortableHeader headerText="Status" sortKey="status" onSort={onSort} />
            <SortableHeader headerText="Read" sortKey="isRead" onSort={onSort} />
            <th />
            <th />
        </tr>
    );
}
