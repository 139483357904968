import { useParams } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { MeetingAgendaReport } from '../report/MeetingAgendaReport';

function MeetingAgendaReportWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return <MeetingAgendaReport guid={guid || ''} user={props.user} />;
}

export default MeetingAgendaReportWrapper;
