import { EmptyGuid, UserRightsEnum } from '../../../../utilities/Constants';
import {
    ICaseExpertModel,
    ICreateCaseExpertModel,
    IViewCaseExpertModelWithDefaultEdit,
} from '../../../../interfaces/ICaseExpert';

import { AddCaseExpertDialog } from './AddCaseExpertDialog';
import Authorization from '../../../../stores/Authorization';
import { CaseExpertTable } from './CaseExpertTable';
import { CheckmateDialog } from '../../../shared/dialog';
import { IUserModel } from '../../../../interfaces/IUser';
import { Loader } from '../../../shared/Loader';
import { cloneDeep } from 'lodash';
import { useCaseExpertCreateMutation } from './useCaseExpertCreateMutation';
import { useCaseExpertDeleteMutation } from './useCaseExpertDeleteMutation';
import { useState } from 'react';

export function CaseExpertsList({
    caseExperts,
    caseGuid,
    displayTableWhenEmpty,
    enableEasyUpdateColumn,
    getEasyUpdateIconColorStyle,
    hideDelete,
    onEasyUpdateClick,
    onSetCurrentCaseExpert,
    showCaseName,
    user,
    readonly,
    refreshParent,
}: {
    caseExperts: ICaseExpertModel[];
    caseGuid?: string;
    displayTableWhenEmpty?: boolean;
    enableEasyUpdateColumn?: boolean;
    getEasyUpdateIconColorStyle?: (caseGuid: string, noteGuid?: string) => string;
    hideDelete?: boolean;
    onEasyUpdateClick?: (caseGuid: string) => void;
    onSetCurrentCaseExpert?: (caseExpert: ICaseExpertModel | null) => void;
    showCaseName?: boolean;
    user: IUserModel;
    readonly?: boolean;
    refreshParent?: (caseExperts: ICaseExpertModel[]) => void;
}) {
    const authorizedToCreate = Authorization.userHasRight(UserRightsEnum.AddCaseExpert, user);
    const authorizedToDelete = Authorization.userHasRight(UserRightsEnum.DeleteCaseExpert, user);
    const authorizedToEdit = Authorization.userHasRight(UserRightsEnum.UpdateCaseExpert, user);
    const authorizedToView = Authorization.userHasRight(UserRightsEnum.ViewCaseExpert, user);

    const [caseExpertToRemove, setCaseExpertToRemove] = useState<ICaseExpertModel | null>(null);
    const [error, setError] = useState<string>();
    const [showAddCaseExpertDialog, setShowAddCaseExpertDialog] = useState<string | undefined>();

    const createCaseExpertMutation = useCaseExpertCreateMutation((errorMessage: string) =>
        setError(errorMessage)
    );

    const deleteMutation = useCaseExpertDeleteMutation((errorMessage: string) =>
        setError(errorMessage)
    );

    const executeDelete = (caseExpertGuid: string) => {
        setCaseExpertToRemove(null);
        deleteMutation
            .mutateAsync(caseExpertGuid)
            .then(() => {
                const filtered = caseExperts?.filter((ce) => ce.guid !== caseExpertGuid);
                if (typeof refreshParent === 'function') refreshParent(filtered ?? []);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleAddClick = (caseGuid: string) => {
        setShowAddCaseExpertDialog(caseGuid);
    };

    const handleCaseExpertRemoveClick = (caseExpertGuid: string) => {
        setCaseExpertToRemove(caseExperts?.find((ce) => ce.guid === caseExpertGuid) ?? null);
    };

    const handleCaseExpertViewEditClick = (caseExpertGuid: string, defaultIsEditMode: boolean) => {
        const found = caseExperts?.find((ce) => ce.guid === caseExpertGuid);

        const foundCopy = { ...found } as IViewCaseExpertModelWithDefaultEdit;

        foundCopy.defaultIsEditMode = defaultIsEditMode;

        if (typeof onSetCurrentCaseExpert === 'function') {
            onSetCurrentCaseExpert(foundCopy ?? null);
        }
    };

    const handleAddCaseExpertDialogCancel = () => {
        setShowAddCaseExpertDialog(undefined);
    };

    const handleAddCaseExpertDialogSubmit = async (caseExpert: ICreateCaseExpertModel) => {
        setShowAddCaseExpertDialog(undefined);

        setError('');

        await createCaseExpertMutation
            .mutateAsync(caseExpert)
            .then(({ payload: createdCaseExpert }) => {
                if (createdCaseExpert) {
                    const caseExpertsCopy = cloneDeep(caseExperts);

                    const found = caseExpertsCopy.find(
                        (existing) =>
                            existing.expertGuid === EmptyGuid &&
                            createdCaseExpert.caseGuid === existing.caseGuid
                    );

                    if (found) {
                        found.name = `${createdCaseExpert.expert.lastName}, ${createdCaseExpert.expert.firstName}`;
                        found.expertGuid = createdCaseExpert.expertGuid;
                        found.guid = createdCaseExpert.guid;
                        found.disciplines = createdCaseExpert.expert.disciplines ?? [];
                        found.leadDefendant = createdCaseExpert.leadDefendant ?? '';
                        found.coDefendants = createdCaseExpert.coDefendants ?? '';
                        found.expertReportStatusTypeId =
                            createdCaseExpert.expertReportStatusTypeId ?? 0;
                        found.expertRetainedByTypeId =
                            createdCaseExpert.expertRetainedByTypeId ?? 0;
                        found.purposeOfRetention = createdCaseExpert.purposeOfRetention;
                    } else {
                        const newCaseExpert: ICaseExpertModel = {
                            caseGuid: '',
                            caseName: '',
                            name: `${createdCaseExpert.expert.lastName}, ${createdCaseExpert.expert.firstName}`,
                            expertGuid: createdCaseExpert.expertGuid,
                            guid: createdCaseExpert.guid,
                            disciplines: createdCaseExpert.expert.disciplines ?? [],
                            leadDefendant: createdCaseExpert.leadDefendant ?? '',
                            coDefendants: createdCaseExpert.coDefendants ?? '',
                            expertReportStatusTypeId:
                                createdCaseExpert.expertReportStatusTypeId ?? 0,
                            expertRetainedByTypeId: createdCaseExpert.expertRetainedByTypeId ?? 0,
                            purposeOfRetention: createdCaseExpert.purposeOfRetention,
                        };
                        caseExpertsCopy.push(newCaseExpert);
                    }

                    if (typeof refreshParent === 'function') {
                        refreshParent(caseExpertsCopy);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const showLoader = createCaseExpertMutation.isLoading || deleteMutation.isLoading;

    return (
        <div className="container-fluid">
            {showLoader && <Loader />}
            {error && (
                <div>
                    <span className="text-danger">{error}</span>
                </div>
            )}
            {caseGuid && authorizedToCreate && !readonly && (
                <SectionHeader onAddClick={() => handleAddClick(caseGuid)} />
            )}
            <CaseExpertTable
                canDelete={authorizedToDelete && !hideDelete && !readonly}
                canEdit={authorizedToEdit && !readonly}
                canView={authorizedToView}
                canAddWhenEmpty={authorizedToCreate}
                caseExperts={caseExperts ?? []}
                getEasyUpdateIconColorStyle={getEasyUpdateIconColorStyle}
                hideNoRecordsMessage={displayTableWhenEmpty}
                noRecordsMessage="No case experts have been added to this case."
                onCaseExpertRemoveClick={handleCaseExpertRemoveClick}
                onCaseExpertViewEditClick={handleCaseExpertViewEditClick}
                onEasyUpdateClick={onEasyUpdateClick}
                onRowAddClick={handleAddClick}
                showCaseName={showCaseName}
                showEasyUpdateIcon={enableEasyUpdateColumn}
                user={user}
            />
            {caseExpertToRemove && (
                <CheckmateDialog
                    body={`Are you sure you wish to remove expert ${caseExpertToRemove?.name} from this case?`}
                    cancelText="No"
                    closeButtonClassName="btn btn-default float-end"
                    confirmButtonClassName="btn btn-black float-end "
                    confirmText="Yes"
                    dialogClassName="confirm-document-delete-dialog"
                    handleClose={() => setCaseExpertToRemove(null)}
                    handleConfirm={() => executeDelete(caseExpertToRemove?.guid ?? '')}
                    isShowingModal
                />
            )}

            {showAddCaseExpertDialog && (
                <AddCaseExpertDialog
                    caseId={showAddCaseExpertDialog}
                    title="Add Case Expert"
                    open={true}
                    onCancelClick={handleAddCaseExpertDialogCancel}
                    onSubmitClick={handleAddCaseExpertDialogSubmit}
                />
            )}
        </div>
    );
}

function SectionHeader({ onAddClick }: { onAddClick: () => void }) {
    return (
        <div className="row mb-1">
            <button className="btn-no-bg float-end col-sm-12" onClick={onAddClick}>
                <span className="btn-green btn float-end btn-icon">
                    <i className="fal fa-lg fa-plus color-white" />
                </span>
            </button>
        </div>
    );
}
