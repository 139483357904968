import * as React from 'react';

import { BoardCriteriaEnum, BoardFilterDateTypesEnum } from '../../utilities/Constants';

import Common from '../../stores/Common';
import { IBoardParametersModel } from '../../interfaces/Report/IBoard';
import { ICaseStatusLookupModel } from '../../interfaces/ICase';

interface IBoardPreferencesHeaderProps {
    boardPreferences: IBoardParametersModel;
    includeCriteria?: boolean;
    type?: string;
    typeValue?: string;
    caseStatuses?: ICaseStatusLookupModel[];
}

export class BoardPreferencesHeader extends React.Component<IBoardPreferencesHeaderProps, any> {
    constructor(props: any) {
        super(props);
    }

    getCaseStatusText = () => {
        let text = '';
        if (this.props.boardPreferences.statusId > 0 && this.props.caseStatuses) {
            let statusMatch = this.props.caseStatuses.filter(
                (x) => x.status && x.status.id == this.props.boardPreferences.statusId
            );
            if (statusMatch.length > 0) {
                text = statusMatch[0].status.displayName || statusMatch[0].status.name!;
                if (this.props.boardPreferences.subStatusIdList) {
                    let subStatusText: string[] = [];
                    for (var i = 0; i < this.props.boardPreferences.subStatusIdList.length; i++) {
                        let subStatusMatch = statusMatch.find(
                            (x) =>
                                x.subStatus &&
                                x.subStatus.id === this.props.boardPreferences.subStatusIdList![i]
                        );
                        if (subStatusMatch)
                            subStatusText.push(
                                subStatusMatch.subStatus!.displayName ||
                                    subStatusMatch.subStatus!.name!
                            );
                    }

                    if (subStatusText.length > 0 && subStatusText.length < statusMatch.length)
                        text += ' - ' + subStatusText.join('|');
                }
            }
        }
        return text;
    };

    getNameFromValue(value: number, type: string) {
        switch (type) {
            case 'Criteria':
                switch (value) {
                    case BoardCriteriaEnum.CaseVolume.Value:
                        return BoardCriteriaEnum.CaseVolume.Name;
                    case BoardCriteriaEnum.ProjectedSettlement.Value:
                        return BoardCriteriaEnum.ProjectedSettlement.Name;
                    case BoardCriteriaEnum.ActualSettlement.Value:
                        return BoardCriteriaEnum.ActualSettlement.Name;
                    default:
                        return '';
                }
            case 'DateRangeType':
                switch (value) {
                    case BoardFilterDateTypesEnum.AsOfDate.Value:
                        return BoardFilterDateTypesEnum.AsOfDate.DisplayName;
                    case BoardFilterDateTypesEnum.ServiceDate.Value:
                        return BoardFilterDateTypesEnum.ServiceDate.DisplayName;
                    case BoardFilterDateTypesEnum.ResolutionDate.Value:
                        return BoardFilterDateTypesEnum.ResolutionDate.DisplayName;
                    case BoardFilterDateTypesEnum.TrialDate.Value:
                        return BoardFilterDateTypesEnum.TrialDate.DisplayName;
                    case BoardFilterDateTypesEnum.ProjectedSettlementDate.Value:
                        return BoardFilterDateTypesEnum.ProjectedSettlementDate.DisplayName;
                    default:
                        return '';
                }
            default:
                return '';
        }
    }

    getDateRangeText = () => {
        let text = '';
        if (this.props.boardPreferences.date && this.props.boardPreferences.date.type) {
            text = this.getNameFromValue(this.props.boardPreferences.date.type!, 'DateRangeType');
            switch (this.props.boardPreferences.date.type) {
                case BoardFilterDateTypesEnum.AsOfDate.Value:
                    if (this.props.boardPreferences.date.startDate)
                        text +=
                            ' | ' + Common.dateFormat(this.props.boardPreferences.date.startDate);
                    if (this.props.boardPreferences.date.endDate)
                        text += ' - ' + Common.dateFormat(this.props.boardPreferences.date.endDate);
                    break;
                default:
                    if (
                        this.props.boardPreferences.date.startDate &&
                        this.props.boardPreferences.date.endDate
                    )
                        text +=
                            ' | ' +
                            Common.dateFormat(this.props.boardPreferences.date.startDate) +
                            ' - ' +
                            Common.dateFormat(this.props.boardPreferences.date.endDate);
                    else if (
                        this.props.boardPreferences.date.startDate &&
                        !this.props.boardPreferences.date.endDate
                    )
                        text +=
                            ' | On or After ' +
                            Common.dateFormat(this.props.boardPreferences.date.startDate);
                    else if (
                        !this.props.boardPreferences.date.startDate &&
                        this.props.boardPreferences.date.endDate
                    )
                        text +=
                            ' | On or Before ' +
                            Common.dateFormat(this.props.boardPreferences.date.endDate);
            }
        }

        return text;
    };

    render() {
        return (
            <span>
                <span className="font-size-xs" style={{ marginLeft: '20px' }}>
                    {this.getCaseStatusText()}
                </span>
                &nbsp;
                {this.props.includeCriteria ? (
                    <span>
                        <span className="font-size-sm bold">|</span>&nbsp;
                        <span className="font-size-xs">
                            {this.getNameFromValue(
                                this.props.boardPreferences.criteria,
                                'Criteria'
                            )}
                        </span>
                        &nbsp;
                    </span>
                ) : null}
                {this.props.type && this.props.typeValue ? (
                    <span>
                        <span className="font-size-sm bold">|</span>&nbsp;
                        <span className="font-size-xs">{this.props.typeValue}</span>&nbsp;
                    </span>
                ) : null}
                {this.props.boardPreferences.date &&
                this.props.boardPreferences.date.type &&
                this.props.boardPreferences.date.type > 0 ? (
                    <span>
                        <span className="font-size-sm bold">|</span>&nbsp;
                        <span className="font-size-xs">{this.getDateRangeText()}</span>&nbsp;
                    </span>
                ) : null}
            </span>
        );
    }
}
