import { Modal } from 'react-bootstrap';

export interface IQueryExportInBackgroundModalProps {
    maxExportRecordCount: number;
    open: boolean;
    onHide: () => void;
    queryType: number;
}

export function QueryExportInBackgroundModal(props: IQueryExportInBackgroundModalProps) {
    return (
        <Modal centered show={props.open} onHide={props.onHide} backdrop={false}>
            <Modal.Header>
                <Modal.Title> Query Results </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <span>
                        Your query returned more than {props.maxExportRecordCount} records. The
                        export request will be processed in the background and a{' '}
                        <i className="fal fa-file-exclamation color-yellow" /> icon will appear next
                        to the DATA link in the header when the report is ready. This may take a few
                        minutes.
                    </span>
                </div>
                <div className="dialog-btn-div margin-top-sm">
                    <button className="btn btn-default float-end" onClick={props.onHide}>
                        Close
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
