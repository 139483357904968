import * as React from 'react';

import CheckmateSelectGrouped, {
    IGroupableOption,
} from '../../components/shared/CheckmateSelectGrouped';
import { QueryToolModeEnum, QueryTypes } from '../../utilities/Constants';

import CaseHelper from '../../utilities/CaseHelper';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { IMultiSelectOptions as IMultiSelectOption } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';

export interface IQueryTypeSelectorProps {
    helpComponent?: JSX.Element;
    mode: number;
    onChange: (selectedItem: any) => void;
    readonly?: boolean;
    selectorLabel?: string;
    selectedQueryType: number;
    user: IUserModel;
    validation: IValidation;
}

function getGroupLabel(option: IMultiSelectOption) {
    return option.id === QueryTypes.ZoneNotes.Value || option.id === QueryTypes.StrategyNote.Value
        ? 'Other'
        : 'Case';
}

export function QueryTypeSelector(props: IQueryTypeSelectorProps) {
    const queryTypes = CaseHelper.getAuthorizedQueryTypes(props.user);
    const lookupOptions = CheckmateSelectHelper.getLookupOptions(queryTypes);
    const groupableOptions: IGroupableOption[] = lookupOptions.map((option) => ({
        ...option,
        groupLabel: getGroupLabel(option),
    }));

    return (
        <div className="row mb-2">
            <div className="col-sm-2">
                <label className="text-gray text-lg">{props.selectorLabel ?? 'Query Type'}</label>
                &nbsp;
                {props.helpComponent}
            </div>
            <div className="col-sm-2">
                <CheckmateSelectGrouped
                    notClearable={true}
                    options={groupableOptions}
                    name="queryTypes"
                    isDisabled={props.readonly || props.mode === QueryToolModeEnum.Redirect}
                    onChange={props.onChange}
                    value={CheckmateSelectHelper.getSelectedValueById(
                        queryTypes,
                        props.selectedQueryType
                    )}
                />
            </div>
            <span className="text-danger">{props.validation.queryType}</span>
        </div>
    );
}
