import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';
import { INoteCategoryTypeModel, INoteSubCategoryTypeModel } from '../../interfaces/INote';
import { INoteUISearchModel, IQueryFilterCriteria } from '../../interfaces/IQuery';
import {
    NotePurposeTypesEnum,
    NoteTypes,
    QueryTypes,
    StrategyTypes,
    UserRightsEnum,
} from '../../utilities/Constants';

import Authorization from '../../stores/Authorization';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { IUserModel } from '../../interfaces/IUser';
import Sort from '../../stores/Sort';
import classNames from 'classnames';

export interface INotesSectionCriteriaValuesProps extends INotesSectionCriteriaValueRowsProps {
    item: IQueryFilterCriteria;
}

export function NotesSectionCriteriaValues(props: INotesSectionCriteriaValuesProps) {
    return (
        <table className="table table-sm">
            <tbody>
                {props.item.validationError ? (
                    <tr>
                        <td colSpan={2}>
                            <span className="text-danger">{props.item.validationError}</span>
                        </td>
                    </tr>
                ) : null}
                <NotesSectionCriteriaValueRows {...props} />
            </tbody>
        </table>
    );
}

interface INotesSectionCriteriaValueRowsProps {
    acknowledgmentStatusTypes: ILookupModel[];
    approvalStatusTypes: ILookupModel[];
    hideRowDividers?: boolean;
    noteCategoryTypes: INoteCategoryTypeModel[];
    notePurposeTypes: ILookupModel[];
    noteSearchCriteria: INoteUISearchModel;
    onChangeAlt: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    onCheckmateSingleSelectChange: (
        selectedItem: any,
        name: string,
        item?: IQueryFilterCriteria
    ) => void;
    onFilterValuesSelectedAlt: (optionsList: any, name: string) => void;
    queryType: number;
    user: IUserModel;
}

export function NotesSectionCriteriaValueRows(props: INotesSectionCriteriaValueRowsProps) {
    const noteSubCategoryTypes = getNoteSubCategoryTypeOptions(
        props.noteSearchCriteria,
        props.noteCategoryTypes
    );

    const showTypeRow = props.queryType === QueryTypes.StrategyNote.Value;
    const ShowRelated =
        props.queryType !== QueryTypes.StrategyNote.Value &&
        props.queryType !== QueryTypes.ZoneNotes.Value;
    const showSubCategoriesRow = noteSubCategoryTypes.length > 0;
    const showDecisionRow = !!props.noteSearchCriteria.purpose?.find(
        (item) => item.id === NotePurposeTypesEnum.Approval
    );
    const showDecisionStatusRow = !!props.noteSearchCriteria.purpose?.find(
        (item) => item.id === NotePurposeTypesEnum.Acknowledgment
    );
    const showPrivateRow = Authorization.userHasRight(UserRightsEnum.NoteSecurity, props.user);
    const showLocalEditRow = Authorization.userHasRight(UserRightsEnum.NoteSecurity, props.user);

    const rowClassName = classNames({ 'no-border-bottom': props.hideRowDividers });

    return (
        <>
            {showTypeRow && (
                <tr className={rowClassName}>
                    <td className="col-sm-4">Type</td>
                    <td>
                        <CheckmateSelect
                            isMulti={true}
                            options={getStrategySubTypes(props.user)}
                            value={props.noteSearchCriteria.noteSubTypes || []}
                            isDisabled={props.noteSearchCriteria.readOnly}
                            onChange={(e: any) => {
                                props.onFilterValuesSelectedAlt(e, 'noteSubTypes');
                            }}
                        />
                    </td>
                </tr>
            )}
            <tr className={rowClassName}>
                <td className="col-sm-4">Categories</td>
                <td>
                    <CheckmateSelect
                        isMulti={true}
                        options={getNoteCategoryTypeOptions(
                            props.queryType,
                            props.noteCategoryTypes
                        )}
                        value={props.noteSearchCriteria.noteCategories || []}
                        isDisabled={props.noteSearchCriteria.readOnly}
                        onChange={(e: any) => {
                            props.onFilterValuesSelectedAlt(e, 'noteCategories');
                        }}
                    />
                </td>
            </tr>
            {showSubCategoriesRow && (
                <tr className={rowClassName}>
                    <td className="col-sm-4">Sub Categories</td>
                    <td>
                        <CheckmateSelect
                            isMulti={true}
                            options={noteSubCategoryTypes}
                            value={props.noteSearchCriteria.noteSubCategories || []}
                            isDisabled={props.noteSearchCriteria.readOnly}
                            onChange={(e: any) => {
                                props.onFilterValuesSelectedAlt(e, 'noteSubCategories');
                            }}
                        />
                    </td>
                </tr>
            )}
            <tr className={rowClassName}>
                <td className="col-sm-4">Date Range</td>
                <td>
                    <input
                        className={
                            'form-control me-2 d-inline-block' +
                            (props.noteSearchCriteria.startDate &&
                            Common.isValidDate(props.noteSearchCriteria.startDate)
                                ? ''
                                : ' unselectClass')
                        }
                        min="1753-01-01"
                        disabled={props.noteSearchCriteria.readOnly}
                        value={
                            props.noteSearchCriteria.startDate
                                ? Common.dateFormat(props.noteSearchCriteria.startDate)
                                : ''
                        }
                        type="date"
                        name="noteStartDate"
                        onChange={props.onChangeAlt}
                    />
                    to
                    <input
                        className={
                            'form-control ms-2 d-inline-block' +
                            (props.noteSearchCriteria.endDate &&
                            Common.isValidDate(props.noteSearchCriteria.endDate)
                                ? ''
                                : ' unselectClass')
                        }
                        min="1753-01-01"
                        disabled={props.noteSearchCriteria.readOnly}
                        value={
                            props.noteSearchCriteria.endDate
                                ? Common.dateFormat(props.noteSearchCriteria.endDate)
                                : ''
                        }
                        type="date"
                        name="noteEndDate"
                        onChange={props.onChangeAlt}
                    />
                </td>
            </tr>
            <tr className={rowClassName}>
                <td className="col-sm-4">Purpose</td>
                <td>
                    <div className="col-sm-5">
                        <CheckmateSelect
                            options={CheckmateSelectHelper.getLookupOptions(props.notePurposeTypes)}
                            name="purpose"
                            onChange={(e: any) => {
                                props.onCheckmateSingleSelectChange(e, 'purpose');
                            }}
                            isDisabled={props.noteSearchCriteria.readOnly}
                            value={CheckmateSelectHelper.getSelectedValueById(
                                props.notePurposeTypes,
                                props.noteSearchCriteria.purpose &&
                                    props.noteSearchCriteria.purpose.length > 0
                                    ? props.noteSearchCriteria.purpose[0].id
                                    : 0
                            )}
                        />
                    </div>
                </td>
            </tr>
            {showDecisionRow && (
                <tr className={rowClassName}>
                    <td className="col-sm-4">Decision</td>
                    <td>
                        <div className="col-sm-5">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getLookupOptions(
                                    props.approvalStatusTypes,
                                    true
                                )}
                                name="approvalStatus"
                                onChange={(e: any) => {
                                    props.onCheckmateSingleSelectChange(e, 'approvalStatus');
                                }}
                                isDisabled={props.noteSearchCriteria.readOnly}
                                value={CheckmateSelectHelper.getSelectedValueById(
                                    props.approvalStatusTypes,
                                    props.noteSearchCriteria.approvalStatus &&
                                        props.noteSearchCriteria.approvalStatus.length > 0
                                        ? props.noteSearchCriteria.approvalStatus[0].id
                                        : 0
                                )}
                            />
                        </div>
                    </td>
                </tr>
            )}
            {showDecisionStatusRow && (
                <tr className={rowClassName}>
                    <td className="col-sm-4">Decision Status</td>
                    <td>
                        <div className="col-sm-5">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getLookupOptions(
                                    props.acknowledgmentStatusTypes,
                                    true
                                )}
                                name="acknowledgementStatus"
                                onChange={(e: any) => {
                                    props.onCheckmateSingleSelectChange(e, 'acknowledgementStatus');
                                }}
                                isDisabled={props.noteSearchCriteria.readOnly}
                                value={CheckmateSelectHelper.getSelectedValueById(
                                    props.acknowledgmentStatusTypes,
                                    props.noteSearchCriteria.acknowledgementStatus &&
                                        props.noteSearchCriteria.acknowledgementStatus.length > 0
                                        ? props.noteSearchCriteria.acknowledgementStatus[0].id
                                        : 0
                                )}
                            />
                        </div>
                    </td>
                </tr>
            )}
            {showPrivateRow && (
                <tr className={rowClassName}>
                    <td className="col-sm-4">Private</td>
                    <td>
                        <div className="col-sm-4">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getBooleanOptions()}
                                value={CheckmateSelectHelper.getBooleanValue(
                                    props.noteSearchCriteria.private
                                )}
                                isDisabled={props.noteSearchCriteria.readOnly}
                                name="private"
                                onChange={(e: any) => {
                                    props.onCheckmateSingleSelectChange(e, 'private');
                                }}
                            />
                        </div>
                    </td>
                </tr>
            )}
            {showLocalEditRow && (
                <tr className={rowClassName}>
                    <td className="col-sm-4">Allow Local Edit</td>
                    <td>
                        <div className="col-sm-4">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getBooleanOptions()}
                                value={CheckmateSelectHelper.getBooleanValue(
                                    props.noteSearchCriteria.allowLocalEdit
                                )}
                                name="allowLocalEdit"
                                onChange={(e: any) => {
                                    props.onCheckmateSingleSelectChange(e, 'allowLocalEdit');
                                }}
                                isDisabled={props.noteSearchCriteria.readOnly}
                            />
                        </div>
                    </td>
                </tr>
            )}
            <tr className={rowClassName}>
                <td className="col-sm-4">Created/Edited by Local</td>
                <td>
                    <div className="col-sm-4">
                        <CheckmateSelect
                            options={CheckmateSelectHelper.getBooleanOptions()}
                            value={CheckmateSelectHelper.getBooleanValue(
                                props.noteSearchCriteria.modifiedByLocal
                            )}
                            name="modifiedByLocal"
                            onChange={(e: any) => {
                                props.onCheckmateSingleSelectChange(e, 'modifiedByLocal');
                            }}
                            isDisabled={props.noteSearchCriteria.readOnly}
                        />
                    </div>
                </td>
            </tr>
            <tr className={rowClassName}>
                <td className="col-sm-4">Has Attachment</td>
                <td>
                    <div className="col-sm-4">
                        <CheckmateSelect
                            options={CheckmateSelectHelper.getBooleanOptions()}
                            value={CheckmateSelectHelper.getBooleanValue(
                                props.noteSearchCriteria.hasAttachment
                            )}
                            name="hasAttachment"
                            onChange={(e: any) => {
                                props.onCheckmateSingleSelectChange(e, 'hasAttachment');
                            }}
                            isDisabled={props.noteSearchCriteria.readOnly}
                        />
                    </div>
                </td>
            </tr>
            {ShowRelated && (
                <tr className={rowClassName}>
                    <td className="col-sm-4">Related to</td>
                    <td>
                        <div className="col-sm-5">
                            <CheckmateSelect
                                options={
                                    [
                                        {
                                            label: 'Case Experts',
                                            value: 'CASE_EXPERTS',
                                        },
                                    ] as IMultiSelectOptions[]
                                }
                                value={
                                    props.noteSearchCriteria.isAssociatedCaseExpertCaseNote
                                        ? ({
                                              label: 'Case Experts',
                                              value: 'CASE_EXPERTS',
                                          } as IMultiSelectOptions)
                                        : undefined
                                }
                                name="relatedTo"
                                onChange={(e: any) => {
                                    props.onCheckmateSingleSelectChange(e, 'relatedTo');
                                }}
                                isDisabled={props.noteSearchCriteria.readOnly}
                            />
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}

const getNoteSubCategoryTypeOptions = (
    noteSearchCriteria: INoteUISearchModel,
    noteCategoryTypes: INoteCategoryTypeModel[]
) => {
    let noteSubCategoryTypeOptions: IMultiSelectOptions[] = [];

    noteSearchCriteria.noteCategories?.map((cat: IMultiSelectOptions) => {
        const match = noteCategoryTypes.find((x) => x.id === cat.id);

        if (match?.subCategories?.length ?? 0 > 0) {
            noteSubCategoryTypeOptions = match!.subCategories!.map(
                (item: INoteSubCategoryTypeModel) => ({
                    label: item.displayName!,
                    value: item.name!,
                    id: item.id,
                    parentId: match!.id,
                })
            );
        }
    });

    return noteSubCategoryTypeOptions.sort(Sort.compareValues('label'));
};

const getNoteTypeByQueryType = (queryType: number) => {
    switch (queryType) {
        case QueryTypes.CaseDetails.Value:
        case QueryTypes.Deposition.Value:
        case QueryTypes.CaseNotes.Value:
        case QueryTypes.CaseExperts.Value:
            return NoteTypes.CaseNote;
        case QueryTypes.ZoneNotes.Value:
            return NoteTypes.ZoneNote;
        case QueryTypes.StrategyNote.Value:
            return NoteTypes.StrategyNote;
        default:
            return 0;
    }
};

const getNoteCategoryTypeOptions = (
    queryType: number,
    noteCategoryTypes: INoteCategoryTypeModel[]
) => {
    const noteTypeId = getNoteTypeByQueryType(queryType);
    let noteCategoryTypeOptions: IMultiSelectOptions[] = [];
    noteCategoryTypes
        .filter((x) => x.noteType.id === noteTypeId)
        .map((cat: ILookupModel) => {
            noteCategoryTypeOptions.push({
                label: cat.displayName!,
                value: cat.name!,
                id: cat.id,
            });
        });
    noteCategoryTypeOptions = noteCategoryTypeOptions.sort(Sort.compareValues('label'));
    return noteCategoryTypeOptions;
};

const getStrategySubTypes = (user: IUserModel) => {
    const strategySubTypes: IMultiSelectOptions[] = [];

    if (Authorization.isAuthorizedToStrategyType(StrategyTypes.Jurisdiction.Name, user))
        strategySubTypes.push({
            label: StrategyTypes.Jurisdiction.DisplayName,
            value: StrategyTypes.Jurisdiction.Name,
            id: StrategyTypes.Jurisdiction.Value,
        });
    if (Authorization.isAuthorizedToStrategyType(StrategyTypes.PlaintiffsFirm.Name, user))
        strategySubTypes.push({
            label: StrategyTypes.PlaintiffsFirm.DisplayName,
            value: StrategyTypes.PlaintiffsFirm.Name,
            id: StrategyTypes.PlaintiffsFirm.Value,
        });
    if (Authorization.isAuthorizedToStrategyType(StrategyTypes.LocalCounsel.Name, user))
        strategySubTypes.push({
            label: StrategyTypes.LocalCounsel.DisplayName,
            value: StrategyTypes.LocalCounsel.Name,
            id: StrategyTypes.LocalCounsel.Value,
        });
    if (Authorization.isAuthorizedToStrategyType(StrategyTypes.Projects.Name, user))
        strategySubTypes.push({
            label: StrategyTypes.Projects.DisplayName,
            value: StrategyTypes.Projects.Name,
            id: StrategyTypes.Projects.Value,
        });
    if (Authorization.isAuthorizedToStrategyType(StrategyTypes.Experts.Name, user))
        strategySubTypes.push({
            label: StrategyTypes.Experts.DisplayName,
            value: StrategyTypes.Experts.Name,
            id: StrategyTypes.Experts.Value,
        });

    return strategySubTypes;
};
