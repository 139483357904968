import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetCollaborationReportStatus = (reportGuid?: string) => {
    return useQuery({
        queryKey: ['get-collab-report-status', reportGuid],
        queryFn: async () => {
            if (!reportGuid) return;

            const response = await _apiClient.getCollaborationReportStatus(reportGuid);

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error(`Failed to retrieve report status for report ${reportGuid}`);
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        enabled: !!reportGuid,
    });
};
