import { Outlet } from 'react-router-dom';
import { PublicLayout } from './PublicLayout';

const PublicRoute = () => {
    return (
        <PublicLayout>
            <Outlet />
        </PublicLayout>
    );
};

export default PublicRoute;
