import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { Snapshot } from '../Snapshot';
import { useLocation } from 'react-router';

function SnapshotWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();
    return (
        <>
            <DocumentTitle title="Snapshot" />
            <Snapshot user={props.user} refreshIdentifier={key} />
        </>
    );
}

export default SnapshotWrapper;
