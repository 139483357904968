import Authorization from '../../../../../stores/Authorization';
import Common from '../../../../../stores/Common';
import { IReportDepositionModel } from '../../../../../interfaces/Report/ITimelineReport';
import { IUserModel } from '../../../../../interfaces/IUser';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../../../../utilities/LocalRoutes';

export interface IDepositionsTableProps {
    depositions: IReportDepositionModel[];
    easyUpdateAttemptedGuids: string[];
    isReadOnly: boolean;
    onEasyUpdateClick: (caseGuid: string) => void;
    user: IUserModel;
}

export function DepositionsTable(props: IDepositionsTableProps) {
    return (
        <>
            {props.depositions.length > 0 && (
                <table className="table margin-left-20" style={{ width: '98.5%' }}>
                    <thead>
                        <tr>
                            <th>Case Name</th>
                            <th>Jurisdiction</th>
                            <th>Witness</th>
                            <th>Status</th>
                            <th>Date</th>
                            {Authorization.isAuthorizedToRoute(
                                LocalRoutes.CaseEasyUpdate,
                                props.user
                            ) && <th></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {props.depositions.map((item: IReportDepositionModel, idx) => {
                            const easyUpdateAttempted =
                                props.easyUpdateAttemptedGuids.filter((x) => x === item.caseGuid)
                                    .length > 0;

                            return (
                                <tr key={idx}>
                                    <td className="col-sm-2">
                                        <Link
                                            target="_blank"
                                            to={LocalRoutes.CaseFile.replace(
                                                ':guid',
                                                item.caseGuid
                                            )}
                                        >
                                            {item.caseName}
                                        </Link>
                                    </td>
                                    <td className="col-sm-3">{item.jurisdiction}</td>
                                    <td className="col-sm-2">{item.witness}</td>
                                    <td className="col-sm-2">{item.status.displayName}</td>
                                    <td className="col-sm-1">{Common.dateFormat(item.date)}</td>
                                    {Authorization.isAuthorizedToRoute(
                                        LocalRoutes.CaseEasyUpdate,
                                        props.user
                                    ) && (
                                        <td className="center" style={{ width: '3%' }}>
                                            {!props.isReadOnly && (
                                                <button
                                                    className="btn-no-bg"
                                                    onClick={() => {
                                                        props.onEasyUpdateClick(item.caseGuid);
                                                    }}
                                                >
                                                    <i
                                                        className={`fal fa-edit ${
                                                            easyUpdateAttempted
                                                                ? 'color-gray'
                                                                : 'color-black'
                                                        } `}
                                                    />
                                                </button>
                                            )}
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </>
    );
}
