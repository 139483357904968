import * as React from 'react';

import { IOrganizationRoleModel, IUserModel } from '../../interfaces/IUser';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import { BasicSearch } from '../../components/shared/BasicSearch';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IUserListProps {
    user: IUserModel;
}
interface IUserListState {
    pendingResponse: boolean;
    users: IUserModel[];
    validation: IValidation;
    disabledOnly?: boolean;
}

export class UserList extends React.Component<IUserListProps, IUserListState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: false,
            users: [],
            validation: {},
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.Users, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadUsers(true);
        this.setState({ validation: {} });
    }

    errorMessage = (msg: string) => {
        const validation = this.state.validation;
        validation.errorMessage = [msg];
        this.setState({ validation: validation });
        console.log(msg);
    };

    loadUsers = async (active: boolean, searchString?: string) => {
        try {
            this.setState({ pendingResponse: true });
            const res = await _apiClient.getUsers(!this.state.disabledOnly, searchString);
            if (res.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (res.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                });
                return;
            }
            if (res.payload) {
                this.setState({ users: res.payload, pendingResponse: false });
            }
            return true;
        } catch (error) {
            this.errorMessage('Error: ' + error);
            this.setState({ pendingResponse: false });
            return false;
        }
    };

    runSearch = (event: React.FormEvent<HTMLButtonElement>, searchString: string) => {
        event.preventDefault();
        this.loadUsers(!this.state.disabledOnly, searchString);
    };

    sortData = (key: string, order: string, subKey?: string) => {
        if (this.state.users) {
            const users = this.state.users.sort(Sort.compareValues(key, subKey, order));
            this.setState({ users: users });
        }
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <>
                <h1>Users</h1>
                <div className="row">
                    <div className="col-sm-5">
                        <BasicSearch
                            placeholderText="Search by User Name"
                            onSearch={this.runSearch}
                            justifyLeft={true}
                        />
                    </div>
                    <div className="col-sm-7">
                        <input
                            name="chkDisableUser"
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.disabledOnly}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ disabledOnly: e.target.checked });
                            }}
                        />
                        <span className="font-size-sm px-2">Deactivated User Only</span>
                    </div>
                </div>

                <table className="table table-striped">
                    <thead>
                        <tr style={{ verticalAlign: 'middle' }}>
                            <SortableHeader
                                headerText="Name"
                                sortKey="profile"
                                subKey="lastName"
                                onSort={this.sortData}
                            />
                            <SortableHeader
                                headerText="Email"
                                sortKey="profile"
                                subKey="email"
                                onSort={this.sortData}
                            />
                            <SortableHeader
                                headerText="Organization"
                                sortKey="homeOrganization"
                                subKey="name"
                                onSort={this.sortData}
                            />
                            <SortableHeader
                                headerText="Status"
                                sortKey="active"
                                onSort={this.sortData}
                            />
                            <th>Zone Access</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users.map((user: IUserModel) => {
                            return (
                                <tr key={user.guid}>
                                    <td className="col-lg-3">
                                        <Link
                                            to={LocalRoutes.UserDetail.replace(':guid', user.guid!)}
                                        >
                                            {user.profile!.lastName}, {user.profile!.firstName}{' '}
                                        </Link>
                                    </td>
                                    <td className="col-lg-2">{user.profile!.email}</td>
                                    <td className="col-lg-2">
                                        {user.homeOrganization ? user.homeOrganization.name : ''}
                                    </td>
                                    <td className="col-lg-1">
                                        {user.active ? 'Active' : 'Disabled'}
                                    </td>
                                    <td>
                                        <ul style={{ listStyle: 'none' }}>
                                            {user.organizationRoles
                                                ? user.organizationRoles.map(
                                                      (
                                                          organization: IOrganizationRoleModel,
                                                          i: number
                                                      ) => {
                                                          return (
                                                              <li>
                                                                  {organization.displayName} |{' '}
                                                                  {organization.role!.displayName} |{' '}
                                                                  {
                                                                      organization.dataScope!
                                                                          .displayName
                                                                  }
                                                              </li>
                                                          );
                                                      }
                                                  )
                                                : null}
                                        </ul>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <span className="text-danger">{this.state.validation.model}</span>
            </>
        );
    }
}
