import { CaseEasyUpdate, ICaseEasyUpdateProps } from '../case/CaseEasyUpdate';

import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

function CaseEasyUpdateWrapper(props: ICaseEasyUpdateProps) {
    const { key } = useLocation();
    return (
        <>
            <DocumentTitle title="Easy Update"></DocumentTitle>
            <CaseEasyUpdate user={props.user} refreshIdentifier={key} navigate={useNavigate()} />
        </>
    );
}

export default CaseEasyUpdateWrapper;
