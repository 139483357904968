import * as React from 'react';

import { ILookupModel, IMultiSelectOptions } from '../../../interfaces/ILookup';
import { useEffect, useState } from 'react';

import ApiClient from '../../../services/apiClient';
import CheckmateSelect from '../../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../../utilities/CheckmateSelectHelper';
import Common from '../../../stores/Common';
import { IProjectModel } from '../../../interfaces/IStrategy';
import { IValidation } from '../../../interfaces/IError';
import { LookupDataEnums } from '../../../utilities/Constants';
import { Modal } from 'react-bootstrap';
import ValidateUtils from '../../../shared/validations';
import { useLoading } from '../../../contexts/LoadingContext';

export interface IAddProjectFormData {
    name?: string;
    type?: ILookupModel;
    status?: ILookupModel;
    targetDate?: string;
    isOngoing?: boolean;
}

export interface IAddProjectModalProps {
    onHide: () => void;
    onCreated: (project: IProjectModel) => void;
}

const _apiClient = new ApiClient();

export function AddProjectModal(props: IAddProjectModalProps) {
    const loading = useLoading();

    const [formData, setFormData] = useState<IAddProjectFormData>({
        isOngoing: false,
        name: '',
        status: undefined,
        targetDate: '',
        type: undefined,
    });

    const [projectStatusTypes, setProjectStatusTypes] = useState<ILookupModel[]>([]);
    const [projectTypes, setProjectTypes] = useState<ILookupModel[]>([]);

    const [validation, setValidation] = useState<IValidation>({});

    const loadLookupData = async () => {
        loading.showLoading();
        const projectTypesResponse = await _apiClient.getLookupData(LookupDataEnums.ProjectTypes);
        if (projectTypesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (projectTypesResponse.errorMessage) {
            setValidation(
                ValidateUtils.parseErrors(
                    projectTypesResponse.errors,
                    projectTypesResponse.errorMessage
                )
            );
            loading.hideLoading();
            return;
        }
        loading.hideLoading();

        loading.showLoading();
        const projectStatusTypesResponse = await _apiClient.getLookupData(
            LookupDataEnums.PackageSettlementStatusTypes
        );

        if (projectStatusTypesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (projectStatusTypesResponse.errorMessage) {
            setValidation(
                ValidateUtils.parseErrors(
                    projectStatusTypesResponse.errors,
                    projectStatusTypesResponse.errorMessage
                )
            );
            loading.hideLoading();
            return;
        }
        loading.hideLoading();

        setProjectStatusTypes(projectStatusTypesResponse.payload!);
        setProjectTypes(projectTypesResponse.payload!);
    };

    const saveProject = async () => {
        loading.showLoading();
        const response = await _apiClient.addProject({
            ...formData,
        } as IProjectModel);

        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (response.errorMessage) {
            loading.hideLoading();
            throw new Error(response.errorMessage);
        }
        loading.hideLoading();

        return formData as IProjectModel;
    };

    const validateForm = () => {
        let hasErrors = false;
        const localValidation: IValidation = {};

        if (!formData.name) {
            localValidation.name = ['Required'];
            hasErrors = true;
        }

        if ((formData.type?.id ?? 0) === 0) {
            localValidation.type = ['Required'];
            hasErrors = true;
        }

        if ((formData.status?.id ?? 0) === 0) {
            localValidation.status = ['Required'];
            hasErrors = true;
        }

        if (hasErrors) {
            setValidation(localValidation);
            return false;
        }

        return true;
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({ ...prev, name: e.target.value }));
    };

    const handleTypeChange = (type: IMultiSelectOptions) => {
        setFormData((prev) => ({ ...prev, type }));
    };

    const handleTargetDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({ ...prev, targetDate: e.target.value }));
    };

    const handleOnGoingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({ ...prev, isOngoing: e.target.checked }));
    };

    const handleStatusChange = (status: IMultiSelectOptions) => {
        setFormData((prev) => ({ ...prev, status }));
    };

    const handleCancelClick = () => {
        props.onHide();
    };

    const handleSaveClick = () => {
        if (!validateForm()) {
            return;
        }

        saveProject()
            .then((newProject: IProjectModel | undefined) => {
                if (newProject) {
                    props.onCreated(newProject);
                } else {
                    setValidation((prev) => ({ ...prev, model: ['Failed to create project'] }));
                }
            })
            .catch((err) => {
                console.log(err);
                setValidation((prev) => ({ ...prev, model: ['Failed to create project'] }));
            });
    };

    useEffect(() => {
        loadLookupData();
    }, []);

    return (
        <Modal centered show={true} onHide={props.onHide} backdrop={false}>
            <Modal.Header>
                <Modal.Title>Add New Project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span className="text-danger">{validation.model}</span>
                </div>
                <div className="row my-2">
                    <label className="col-sm-3 text-gray">Title*</label>
                    <div className="col-sm-9">
                        <input
                            className="form-control"
                            type="text"
                            name="name"
                            value={formData.name}
                            maxLength={100}
                            onChange={handleNameChange}
                        />
                        <span className="text-danger">{validation.name}</span>
                    </div>
                </div>
                <div className="row my-2">
                    <label className="text-gray col-sm-3">Risk or Opportunity*</label>
                    <div className="col-sm-9">
                        <CheckmateSelect
                            name="type"
                            onChange={handleTypeChange}
                            options={CheckmateSelectHelper.getLookupOptions(projectTypes)}
                            value={CheckmateSelectHelper.getSelectedValueById(
                                projectTypes,
                                formData.type?.id ?? 0
                            )}
                        />
                        <span className="text-danger">{validation.type}</span>
                    </div>
                </div>
                <div className="row my-2">
                    <label className="text-gray col-sm-3">Target Date</label>
                    <div className="col-sm-9 d-inline-block">
                        <input
                            type="date"
                            name="targetDate"
                            className={
                                'form-control d-inline-block ' +
                                (formData.targetDate && Common.isValidDate(formData.targetDate)
                                    ? ''
                                    : 'unselectClass')
                            }
                            value={formData.targetDate}
                            onChange={handleTargetDateChange}
                        />
                        <input
                            type="checkbox"
                            name="isOngoing"
                            className="form-check-input ms-2 me-1 d-inline-block"
                            style={{ marginTop: '10px' }}
                            checked={formData.isOngoing}
                            onChange={handleOnGoingChange}
                        />
                        Ongoing
                    </div>
                </div>
                <div className="row my-2">
                    <label className="text-gray col-sm-3">Status*</label>
                    <div className="col-sm-9">
                        <CheckmateSelect
                            name="status"
                            onChange={handleStatusChange}
                            options={CheckmateSelectHelper.getLookupOptions(projectStatusTypes)}
                            value={CheckmateSelectHelper.getSelectedValueById(
                                projectStatusTypes,
                                formData.status?.id ?? 0
                            )}
                        />
                        <span className="text-danger">{validation.status}</span>
                    </div>
                </div>
                <div className="dialog-btn-div margin-top-sm">
                    <button className="btn btn-default float-end" onClick={handleCancelClick}>
                        Cancel
                    </button>
                    <button className="btn btn-orange float-end" onClick={handleSaveClick}>
                        Save
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
