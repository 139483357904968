import * as React from 'react';

import { EntityTypes } from '../../utilities/Constants';
import { IEntityAssertionModel } from '../../interfaces/IAssertionDescriptor';
import { IValidation } from '../../interfaces/IError';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';

export interface INarrativeModalProps {
    entityAssertion: IEntityAssertionModel;
    onCancelClick: () => void;
    onSubmitClick: (entityAssertion: IEntityAssertionModel) => void;
    validation?: IValidation;
}

export function NarrativeModal(props: INarrativeModalProps) {
    const [value, setValue] = useState<string>(props.entityAssertion.textValue ?? '');

    const handleNarrativeFieldTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };

    const handleSubmitClick = () => {
        const newEntityAssertion = { ...props.entityAssertion, textValue: value };
        props.entityAssertion ? props.onSubmitClick(newEntityAssertion) : {};
    };

    const handleClearClick = () => {
        setValue('');
    };

    const maxLength =
        props.entityAssertion.entityType?.id === EntityTypes.OperatingMechanism ? 300 : -1;

    const rowCount =
        props.entityAssertion.entityType?.id === EntityTypes.OperatingMechanism ? 4 : 15;

    return (
        <Modal centered show={true} backdrop={false}>
            <Modal.Header>
                <Modal.Title>{props.entityAssertion.assertionDescriptor!.displayName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span className="text-danger">{props.validation?.model}</span>
                </div>
                <div>
                    <textarea
                        className="form-control"
                        value={value}
                        name={props.entityAssertion.assertionDescriptor!.guid}
                        rows={rowCount}
                        maxLength={maxLength}
                        onChange={handleNarrativeFieldTextChange}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-default" onClick={handleClearClick}>
                    Clear
                </button>
                <button className="btn btn-default" onClick={props.onCancelClick}>
                    Cancel
                </button>
                <button className="btn btn-orange" onClick={handleSubmitClick}>
                    OK
                </button>
            </Modal.Footer>
        </Modal>
    );
}
