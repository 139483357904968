import * as React from 'react';

import {
    AlertOriginTypesEnum,
    AlertTypes,
    ApplicationHelpType,
    DataScopesEnum,
    EmptyGuid,
    EntityTypes,
    LookupDataEnums,
    NoteAcknowledgementStatusTypesEnum,
    NoteApprovalStatusTypesEnum,
    NoteCategories,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    StrategyTypes,
    UserRightsEnum,
} from '../../utilities/Constants';
import {
    IAlertUser,
    INoteCategoryModel,
    INoteCategoryTypeModel,
    INoteConversationModel,
    INoteModel,
    INoteSubCategoryTypeModel,
    INotesUICustomization,
} from '../../interfaces/INote';
import {
    IAssertionDescriptorModel,
    IEntityAssertionModel,
} from '../../interfaces/IAssertionDescriptor';
import { ICaseTitleModel, IDocumentModel } from '../../interfaces/ICase';
import { IKeyValuePairModel, ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';
import { Modal, Stack } from 'react-bootstrap';

import ApiClient from '../../services/apiClient';
import Auth from '../../stores/authentication';
import Authorization from '../../stores/Authorization';
import CaseHelper from '../../utilities/CaseHelper';
import { CaseName } from '../case/CaseName';
import CheckmateSelect from '../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import EntityAssertionControl from '../shared/EntityAssertionControl';
import { Help } from '../shared/Help';
import { IAuditLogModel } from '../../interfaces/IAudit';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { IconButton } from '../../shared/buttons/IconButton';
import { Link } from 'react-router-dom';
import { Loader } from '../shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import NoteHelper from '../../utilities/NoteHelper';
import { RelatedEntitiesModal } from './RelatedEntitiesModal';
import Sort from '../../stores/Sort';
import ValidateUtils from '../../shared/validations';
import { WorkProductWrapper } from '../work-product/WorkProductWrapper';
import { cloneDeep } from 'lodash';

const _apiClient = new ApiClient();

interface INoteEditorProps {
    caseLocalCounselGuid?: string;
    caseTitle?: ICaseTitleModel;
    currentNote: INoteModel;
    defaultAlertTarget?: IUserModel;
    defaultWatchTargets?: IUserModel[];
    enableNoteAssociations?: boolean;
    entityItems?: IMultiSelectOptions[];
    entityType?: ILookupModel;
    handleCancel: (alertGuid: string) => void;
    handleSaveComplete: (note: INoteModel) => void;
    helpText?: ILookupModel[];
    noteAssociationModalTitle?: string;
    noteAssociationTitle?: string;
    noteCategoryTypes: INoteCategoryTypeModel[];
    notesUICustomization?: INotesUICustomization;
    originEntityKey?: string;
    originEntityName?: string;
    originEntityType?: number;
    authorizedToEdit?: boolean;
    forceNoteEditorInReadOnlyMode?: boolean;
    strategyTypeId?: number;
    useNoteAssociationsModal?: boolean;
    user: IUserModel;
}

interface INoteEditorState {
    acknowledgmentStatusTypes: ILookupModel[];
    additionalWatchGroupUsers: IMultiSelectOptions[];
    addUserToWatchGroup?: boolean;
    allAlertTargets: IMultiSelectOptions[];
    allUsers: IUserModel[];
    approvalStatusTypes: ILookupModel[];
    authorizedToEdit: boolean;
    currentNote: INoteModel;
    forceNoteEditorInReadOnlyMode?: boolean;
    hasUnsavedChanges?: boolean;
    keepDocumentUploadDialogOpen?: boolean;
    newChatMessage: string;
    notePurposeTypes: ILookupModel[];
    noteStateChanged?: boolean;
    noteStatusTypes?: ILookupModel[];
    originalNote: INoteModel;
    pendingChatMessagePost?: boolean;
    pendingResponse: boolean;
    returnToPriorTarget: boolean;
    selectedAlertTarget: IMultiSelectOptions;
    selectedEntityItems?: IMultiSelectOptions[];
    selectedNoteCategories: IMultiSelectOptions[];
    showEditButton: boolean;
    showHistoryModal: boolean;
    showNoteAssociationsModal: boolean;
    showWatchGroup?: boolean;
    validation: IValidation;
}

export class NoteEditor extends React.Component<INoteEditorProps, INoteEditorState> {
    constructor(props: INoteEditorProps) {
        super(props);
        this.state = {
            acknowledgmentStatusTypes: [],
            additionalWatchGroupUsers: [],
            allAlertTargets: [],
            allUsers: [],
            approvalStatusTypes: [],
            authorizedToEdit: false,
            currentNote: {
                purpose: {
                    id: NotePurposeTypesEnum.Info,
                },
                status: {
                    id: NoteStatusTypes.Open,
                },
            },
            forceNoteEditorInReadOnlyMode: props.forceNoteEditorInReadOnlyMode,
            newChatMessage: '',
            notePurposeTypes: [],
            noteStateChanged: false,
            originalNote: {
                purpose: {
                    id: NotePurposeTypesEnum.Info,
                },
                status: {
                    id: NoteStatusTypes.Open,
                },
            },
            pendingResponse: false,
            returnToPriorTarget: false,
            selectedAlertTarget: {
                id: 0,
                label: '',
                value: '',
            },
            selectedNoteCategories: [],
            showEditButton:
                this.props.forceNoteEditorInReadOnlyMode || !this.props.authorizedToEdit,
            showHistoryModal: false,
            showNoteAssociationsModal: false,
            validation: {},
        };

        this.onSearchableSingleSelectChange = this.onSearchableSingleSelectChange.bind(this);
    }

    componentDidMount() {
        if (!this.props.currentNote.guid || this.props.currentNote.guid === EmptyGuid) {
            const noteCopy = cloneDeep(this.props.currentNote);
            const selectedEntityItems = this.getSelectedEntityItems(noteCopy);

            this.setState(
                {
                    forceNoteEditorInReadOnlyMode: this.props.forceNoteEditorInReadOnlyMode,
                    authorizedToEdit: this.props.authorizedToEdit ?? false,
                    currentNote: this.props.currentNote,
                    originalNote: noteCopy,
                    selectedEntityItems,
                },
                this.loadNoteStatusTypes
            );
        } else {
            this.loadNoteData(this.props.currentNote.guid!);
        }
    }

    loadNoteData = async (id: string) => {
        this.setState({ pendingResponse: true });
        const response = await _apiClient.getNoteDetails(id);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        const currentNote = response.payload!;
        const selectedNoteCategories: IMultiSelectOptions[] = [];
        let selectedEntityItems: IMultiSelectOptions[] = [];
        const hasValidGuid = currentNote.guid && currentNote.guid !== EmptyGuid;
        const hasValidAlert = currentNote.alert?.guid && currentNote.alert.guid !== EmptyGuid;
        const hasUnreadAlert = currentNote.alert?.users?.some(
            (x) => x.user?.guid === this.props.user.guid && !x.read
        );
        const hasAlertUsers = currentNote.alert && currentNote.alert.users;

        if (currentNote.categories != undefined) {
            for (let i = 0; i < currentNote.categories.length; i++) {
                selectedNoteCategories.push({
                    label: currentNote.categories[i].name!,
                    value: currentNote.categories[i].name!,
                    id: currentNote.categories[i].id,
                });
            }
        }

        if (currentNote.associations !== undefined) {
            selectedEntityItems = this.getSelectedEntityItems(currentNote);
        }

        if (hasValidGuid && hasValidAlert && hasUnreadAlert) {
            this.markNoteAsRead(currentNote.alert!.guid!).then(() => {
                currentNote.alertReadForLoggedInUser = true;
            });
        }

        let selectedAlertTarget: IMultiSelectOptions = { id: 0, label: '', value: '' };

        if (hasValidGuid && hasAlertUsers) {
            const actionTargets = currentNote.alert!.users!.filter(
                (x) => x.type && x.type.id === AlertTypes.Act.Value
            );
            if (actionTargets.length === 0) {
                if (this.props.defaultAlertTarget) {
                    selectedAlertTarget = {
                        id: 0,
                        label: `${this.props.defaultAlertTarget.profile!.firstName} ${
                            this.props.defaultAlertTarget.profile!.lastName
                        }`,
                        value: this.props.defaultAlertTarget.guid!,
                        guid: this.props.defaultAlertTarget.guid!,
                    };
                }
            } else {
                const user = actionTargets[0].user;
                if (user)
                    selectedAlertTarget = {
                        id: 0,
                        label: this.getUserFullNameDisplay(user),
                        value: user.guid!,
                        guid: user.guid!,
                    };
            }

            if (this.props.defaultWatchTargets && this.props.defaultWatchTargets.length > 0) {
                for (let i = 0; i < this.props.defaultWatchTargets.length; i++) {
                    const item = this.props.defaultWatchTargets[i];
                    if (
                        !currentNote.alert!.users!.some(
                            (x) =>
                                x.type?.id === AlertTypes.Watch.Value && x.user?.guid === item.guid
                        )
                    ) {
                        const watchTarget: IAlertUser = {
                            user: cloneDeep(item),
                            type: { id: AlertTypes.Watch.Value },
                            read: false,
                        };
                        currentNote.alert!.users!.push(watchTarget);
                    }
                }
            }
        }

        this.setState(
            {
                forceNoteEditorInReadOnlyMode: this.props.forceNoteEditorInReadOnlyMode,
                authorizedToEdit: this.props.authorizedToEdit ?? false,
                currentNote: currentNote,
                originalNote: cloneDeep(currentNote),
                selectedNoteCategories: selectedNoteCategories,
                selectedEntityItems: selectedEntityItems,
                selectedAlertTarget: selectedAlertTarget,
            },
            this.loadNoteStatusTypes
        );
    };

    componentDidUpdate(prevProps: INoteEditorProps) {
        if (this.props.currentNote != prevProps.currentNote) {
            const currentNote = this.props.currentNote;
            const selectedNoteCategories: IMultiSelectOptions[] = [];
            let selectedEntityItems: IMultiSelectOptions[] = [];
            if (
                this.props.currentNote.categories != prevProps.currentNote.categories &&
                this.props.currentNote.categories != undefined
            ) {
                for (let i = 0; i < this.props.currentNote.categories.length; i++) {
                    selectedNoteCategories.push({
                        label: this.props.currentNote.categories[i].name!,
                        value: this.props.currentNote.categories[i].name!,
                        id: this.props.currentNote.categories[i].id,
                    });
                }
            }

            if (this.props.currentNote.associations !== prevProps.currentNote.associations) {
                selectedEntityItems = this.getSelectedEntityItems(this.props.currentNote);
            }

            let selectedAlertTarget: IMultiSelectOptions = { id: 0, label: '', value: '' };

            if (currentNote.guid !== EmptyGuid && currentNote.alert?.users) {
                const actionTargets = currentNote.alert?.users.filter(
                    (x) => x.type && x.type.id === AlertTypes.Act.Value
                );
                if (actionTargets.length === 0) {
                    if (this.props.defaultAlertTarget) {
                        selectedAlertTarget = {
                            id: 0,
                            label:
                                this.props.defaultAlertTarget.profile!.firstName +
                                ' ' +
                                this.props.defaultAlertTarget.profile!.lastName,
                            value: this.props.defaultAlertTarget.guid!,
                            guid: this.props.defaultAlertTarget.guid!,
                        };
                    }
                } else {
                    const user = actionTargets[0].user;
                    if (user)
                        selectedAlertTarget = {
                            id: 0,
                            label: this.getUserFullNameDisplay(user),
                            value: user.guid!,
                            guid: user.guid!,
                        };
                }

                if (
                    this.props.defaultWatchTargets != prevProps.defaultWatchTargets &&
                    this.props.defaultWatchTargets &&
                    this.props.defaultWatchTargets.length > 0
                ) {
                    for (let i = 0; i < this.props.defaultWatchTargets.length; i++) {
                        const item = this.props.defaultWatchTargets[i];
                        if (
                            currentNote.alert.users.filter(
                                (x) =>
                                    x.type &&
                                    x.type.id === AlertTypes.Watch.Value &&
                                    x.user &&
                                    x.user.guid === item.guid
                            ).length === 0
                        ) {
                            const watchTarget: IAlertUser = {
                                user: JSON.parse(JSON.stringify(item)),
                                type: { id: AlertTypes.Watch.Value },
                                read: false,
                            };
                            currentNote.alert.users.push(watchTarget);
                        }
                    }
                }
            }

            this.setState({
                currentNote: currentNote,
                originalNote: JSON.parse(JSON.stringify(this.props.currentNote)),
                selectedNoteCategories: selectedNoteCategories,
                selectedEntityItems: selectedEntityItems,
                selectedAlertTarget: selectedAlertTarget,
            });
        }
    }

    getNoteDetails = async (id: string) => {
        const response = await _apiClient.getNoteDetails(id);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        return response.payload!;
    };

    markNoteAsRead = async (id: string) => {
        //We do not need to mark  Watch alert Note as read explicitly.
        //service side when user responds it automatically set as read.
        const response = await _apiClient.markAlertAsRead(id);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
    };

    loadNoteStatusTypes = async () => {
        const response = await _apiClient.getLookupData(LookupDataEnums.NoteStatusTypes);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ noteStatusTypes: response.payload! }, this.loadNotePurposeTypes);
    };

    loadNotePurposeTypes = async () => {
        const response = await _apiClient.getLookupData(LookupDataEnums.NotePurposeTypes);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ notePurposeTypes: response.payload! }, this.loadApprovalStatusTypes);
    };

    loadApprovalStatusTypes = async () => {
        const response = await _apiClient.getLookupData(LookupDataEnums.ApprovalStatusTypes);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState(
            { approvalStatusTypes: response.payload! },
            this.loadAcknowledgmentStatusTypes
        );
    };

    loadAcknowledgmentStatusTypes = async () => {
        const response = await _apiClient.getLookupData(LookupDataEnums.AcknowledgmentStatusTypes);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ acknowledgmentStatusTypes: response.payload! }, this.loadAlertTargets);
    };

    loadAlertTargets = async () => {
        const response = await _apiClient.getNoteTargets(
            this.props.currentNote.type!.id,
            this.props.caseLocalCounselGuid,
            this.getStrategyTypeId()
        );

        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        const allUsers = response.payload!;

        const allAlertTargets: IMultiSelectOptions[] = allUsers.map((user) => ({
            id: 0,
            label: this.getUserFullNameDisplay(user),
            value: user.guid!,
            guid: user.guid!,
        }));

        allAlertTargets.sort(Sort.compareValues('label'));

        this.setState({
            allAlertTargets,
            allUsers,
            pendingResponse: false,
        });
    };

    getSelectedEntityItems = (currentNote: INoteModel) => {
        const selectedNoteAssociations: IMultiSelectOptions[] = [];
        if (
            (this.props.enableNoteAssociations || this.props.useNoteAssociationsModal) &&
            this.props.entityItems &&
            currentNote.associations &&
            currentNote.associations.length > 0
        ) {
            for (let i = 0; i < currentNote.associations.length; i++) {
                const match = this.props.entityItems.find(
                    (x) =>
                        x.value.toLowerCase() ===
                        currentNote.associations![i].entityGuid?.toLowerCase()
                );
                if (match) selectedNoteAssociations.push(JSON.parse(JSON.stringify(match)));
            }
        }

        return selectedNoteAssociations;
    };

    handleChangeValue = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        const currentNote = this.state.currentNote;
        if (!currentNote) return;

        const validation = this.state.validation;
        const selectedAlertTarget = this.state.selectedAlertTarget;

        switch (name) {
            case 'noteContent':
                currentNote.content = value;
                validation.content = [''];
                break;
            case 'noteContext':
                currentNote.context = value;
                validation.context = [''];
                break;
            case 'status':
                currentNote.status = { id: parseInt(value) };
                break;
            case 'approvalStatus':
                currentNote.approvalStatus = value ? { id: parseInt(value) } : undefined;
                break;
            case 'acknowledgmentStatus':
                currentNote.acknowledgmentStatus = value ? { id: parseInt(value) } : undefined;
                break;
            case 'newChatMessage':
                this.setState({
                    newChatMessage: value,
                    hasUnsavedChanges: true,
                    pendingChatMessagePost: value ? true : false,
                });
                return;
            default:
                break;
        }
        this.setState({
            currentNote: currentNote,
            selectedAlertTarget: selectedAlertTarget,
            validation: validation,
            hasUnsavedChanges: true,
        });
    };

    handleNoteCategoriesSelected = (optionsList: any) => {
        const currentNote = NoteHelper.updateCurrentNoteOnCategoryChange(
            this.state.currentNote!,
            optionsList,
            this.props.noteCategoryTypes
        );
        this.setState({
            currentNote: currentNote,
            selectedNoteCategories: optionsList,
            hasUnsavedChanges: true,
        });
    };

    handleNoteAssociationSelected = (optionsList: any) => {
        if (!this.state.currentNote || !this.props.entityType) return;
        const currentNote = this.state.currentNote;
        currentNote.associations = [];
        if (optionsList) {
            for (let i = 0; i < optionsList.length; i++) {
                currentNote.associations.push({
                    entityType: this.props.entityType,
                    entityGuid: optionsList[i].guid,
                    displayName: optionsList[i].label,
                });
            }
        }
        this.setState({
            currentNote: currentNote,
            selectedEntityItems: optionsList,
            hasUnsavedChanges: true,
        });
    };

    getOtherCategoryId() {
        return this.state.currentNote.type!.id == NoteTypes.CaseNote
            ? NoteCategories.CaseNoteOther
            : NoteCategories.ZoneNoteOther;
    }

    handleSave = async () => {
        await this.saveNote();
    };

    getAlertOriginTypeBasedOnNoteType = (): ILookupModel => {
        const currentNoteType =
            this.state.currentNote !== undefined
                ? this.state.currentNote.type
                : this.props.currentNote.type;

        const alertOriginType: ILookupModel = { id: AlertOriginTypesEnum.CaseNote };

        switch (currentNoteType?.id) {
            case NoteTypes.CaseNote:
                return alertOriginType;
            case NoteTypes.ZoneNote: {
                alertOriginType.id = AlertOriginTypesEnum.ZoneNote;
                return alertOriginType;
            }
            case NoteTypes.StrategyNote: {
                alertOriginType.id = AlertOriginTypesEnum.StrategyNote;
                return alertOriginType;
            }
            case NoteTypes.PackageSettlementNote: {
                alertOriginType.id = AlertOriginTypesEnum.PackageSettlementNote;
                return alertOriginType;
            }
            default:
                return alertOriginType;
        }
    };

    validateNote(currentNote: INoteModel, noteCategoryTypes: INoteCategoryTypeModel[]) {
        const validation: IValidation = {};

        const purposeId: any = currentNote.purpose.id;
        if (!currentNote.purpose.id || purposeId == '') {
            validation.notePurpose = ['Required'];
        }

        if (!currentNote.content || currentNote.content!.trim().length == 0) {
            validation.content = ['Required'];
        }

        if (!currentNote.purpose.id) {
            validation.notePurpose = ['Required'];
        } else {
            if (
                (currentNote.purpose.id === NotePurposeTypesEnum.Approval ||
                    currentNote.purpose.id === NotePurposeTypesEnum.Acknowledgment) &&
                (!this.state.selectedAlertTarget.guid ||
                    this.state.selectedAlertTarget.guid === EmptyGuid)
            )
                validation.actionTarget = ['Required'];
        }

        let subCategoryMissing = false;

        if (!currentNote.categories || currentNote.categories!.length === 0) {
            validation.categories = ['At least One Note Category is required'];
        } else if (currentNote.categories) {
            const categoryValidationErrors: string[] = [];

            currentNote.categories.forEach((noteCategoryItem: INoteCategoryModel) => {
                const noteCategoryTypeLookupItem = noteCategoryTypes.find(
                    (x) => x.id == noteCategoryItem.id
                );
                let additionalInfoMissing = false;
                if (noteCategoryTypeLookupItem) {
                    if (
                        noteCategoryTypeLookupItem.assertionDescriptors &&
                        noteCategoryTypeLookupItem.assertionDescriptors.length > 0
                    ) {
                        if (
                            !noteCategoryItem.assertions ||
                            noteCategoryItem.assertions.length == 0
                        ) {
                            additionalInfoMissing = true;
                        } else {
                            noteCategoryTypeLookupItem.assertionDescriptors.map(
                                (assertionDescriptor: IAssertionDescriptorModel) => {
                                    const assertionMatch = noteCategoryItem.assertions!.filter(
                                        (x) =>
                                            x.assertionDescriptor &&
                                            x.assertionDescriptor.guid == assertionDescriptor.guid
                                    );
                                    if (
                                        assertionMatch.length == 0 ||
                                        (!assertionMatch[0].assertionDescriptorValue &&
                                            !assertionMatch[0].dateTimeValue &&
                                            !assertionMatch[0].textValue &&
                                            !assertionMatch[0].booleanValue &&
                                            !assertionMatch[0].numericValue)
                                    ) {
                                        additionalInfoMissing = true;
                                    }
                                }
                            );
                        }
                    }

                    if (
                        (noteCategoryTypeLookupItem.subCategories?.length ?? 0) > 0 &&
                        (!noteCategoryItem.subCategory || noteCategoryItem.subCategory.id === 0)
                    ) {
                        subCategoryMissing = true;
                    }

                    if (additionalInfoMissing) {
                        categoryValidationErrors.push(
                            `${noteCategoryItem.id}|Additional Fields required for Note`
                        );
                    }
                }
            });

            if (categoryValidationErrors.length > 0) {
                validation.additionalFields = categoryValidationErrors;
            }

            if (subCategoryMissing) {
                validation.subCategory = ['Sub Category is Required'];
            }
        }

        if (this.state.pendingChatMessagePost) {
            validation.model = ['You have a chat message that has not been posted.'];
        }

        if (this.isActionTargetLocal() && currentNote.private) {
            validation.model = ['Cannot set a Local Action Target for a Private Note.'];
        }

        return validation;
    }

    saveNote = async (keepDocumentUploadDialogOpen?: boolean) => {
        if (!this.state.currentNote) return;
        const validation = this.validateNote(this.state.currentNote!, this.props.noteCategoryTypes);
        if (JSON.stringify(validation) != JSON.stringify({})) {
            this.setState({ validation: validation, pendingResponse: false });
            return;
        }

        const currentNoteCopy = cloneDeep(this.state.currentNote);
        //Todo:aj verify if it is existing alert.

        if (this.state.selectedAlertTarget) {
            const originType = this.getAlertOriginTypeBasedOnNoteType();

            if (!currentNoteCopy.alert) currentNoteCopy.alert = {};
            if (!currentNoteCopy.alert.users) currentNoteCopy.alert.users = [];

            currentNoteCopy.alert.originType = originType;

            // remove all existing Action Targets
            for (let i = 0; i < currentNoteCopy.alert.users.length; i++) {
                if (
                    currentNoteCopy.alert.users[i].type &&
                    currentNoteCopy.alert.users[i].type!.id === AlertTypes.Act.Value
                )
                    currentNoteCopy.alert.users.splice(i, 1);
            }

            const match = this.state.allUsers.find(
                (x) => x.guid === this.state.selectedAlertTarget.guid
            );
            if (match) {
                const alertUserToAdd: IAlertUser = {
                    user: JSON.parse(JSON.stringify(match)),
                    read: false,
                    type: { id: AlertTypes.Act.Value },
                };
                currentNoteCopy.alert.users.push(alertUserToAdd);
            }
        }

        this.setState({ pendingResponse: true });
        const response = await _apiClient.saveNote(currentNoteCopy!);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (!response.httpResponse.ok) {
            this.setState({
                currentNote: cloneDeep(this.state.originalNote),
                hasUnsavedChanges: false,
                validation: {},
                returnToPriorTarget: false,
            });
            return;
        }

        const savedNote = response.payload!;
        const selectedNoteCategories: IMultiSelectOptions[] = [];
        let selectedEntityItems: IMultiSelectOptions[] = [];

        if (savedNote.categories !== undefined) {
            for (let i = 0; i < savedNote.categories.length; i++) {
                selectedNoteCategories.push({
                    label: savedNote.categories[i].name!,
                    value: savedNote.categories[i].name!,
                    id: savedNote.categories[i].id,
                });
            }
        }

        if (this.props.currentNote.associations !== undefined) {
            selectedEntityItems = this.getSelectedEntityItems(savedNote);
        }

        this.setState({
            currentNote: savedNote,
            originalNote: cloneDeep(savedNote),
            selectedNoteCategories,
            selectedEntityItems,
            showEditButton: true,
            pendingResponse: false,
            hasUnsavedChanges: false,
            noteStateChanged: true,
            validation: {},
            keepDocumentUploadDialogOpen,
            forceNoteEditorInReadOnlyMode: true,
        });
    };

    buildAdditionalFieldsByNoteCategory(noteCategory: INoteCategoryModel) {
        const match = this.props.noteCategoryTypes.find((x) => x.id == noteCategory.id);
        if (!match || !match.assertionDescriptors || match.assertionDescriptors.length == 0) return;

        return (
            <div>
                {this.props.notesUICustomization &&
                this.props.notesUICustomization.hideCategories ? null : (
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <label className="text-gray">
                                Additional Fields for Category - {match.displayName!} *
                            </label>
                        </div>
                    </div>
                )}
                {match.assertionDescriptors.map(
                    (assertionDescriptor: IAssertionDescriptorModel) => {
                        let noteAssertion: IEntityAssertionModel = {
                            entityType: {
                                id: EntityTypes.Note,
                            },
                            assertionDescriptor: {},
                        };

                        if (
                            noteCategory.assertions &&
                            noteCategory.assertions.filter(
                                (x) =>
                                    x.assertionDescriptor &&
                                    x.assertionDescriptor.guid == assertionDescriptor.guid
                            ).length > 0
                        ) {
                            const noteAssertionMatch = noteCategory.assertions.filter(
                                (x) =>
                                    x.assertionDescriptor &&
                                    x.assertionDescriptor.guid == assertionDescriptor.guid
                            )[0];
                            noteAssertion = noteAssertionMatch;
                        }

                        noteAssertion.assertionDescriptor = JSON.parse(
                            JSON.stringify(assertionDescriptor)
                        );
                        return (
                            <div className="row mb-2">
                                <div className="col-sm-2">
                                    <label className="text-gray">
                                        {assertionDescriptor.displayName!}*
                                    </label>
                                </div>
                                <div className="col-sm-6">
                                    <EntityAssertionControl
                                        assertionDescriptor={assertionDescriptor}
                                        entityAssertions={[noteAssertion]}
                                        refreshChange={this.handleNoteCategoryAdditionalFieldChange}
                                        additionalIdentifierKey={noteCategory.id}
                                        handleNarrativeFieldEdit={() => {
                                            console.log('Function not implemented');
                                        }}
                                        editMode={!this.state.forceNoteEditorInReadOnlyMode}
                                    />
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
        );
    }

    handleNoteCategoryAdditionalFieldChange = (items: IEntityAssertionModel[], key?: any) => {
        const item = items[0];
        const noteCategoryId = parseInt(key!);
        const currentNote = cloneDeep(this.state.currentNote);

        if (!currentNote.categories) return;

        const noteCategoryMatch = currentNote.categories.find((x) => x.id == noteCategoryId);
        if (noteCategoryMatch) {
            if (!noteCategoryMatch.assertions || noteCategoryMatch.assertions.length == 0)
                noteCategoryMatch.assertions = [item];
            else {
                let assertionMatch = noteCategoryMatch.assertions.find(
                    (x) => x.assertionDescriptor.guid == item.assertionDescriptor.guid
                );
                if (assertionMatch) {
                    assertionMatch = JSON.parse(JSON.stringify(item));
                } else {
                    noteCategoryMatch.assertions.push(item);
                }
            }

            this.setState({ currentNote, hasUnsavedChanges: true });
        }
    };

    selectLabel = (e: any, label: string) => {
        if (!e.value || e.value.length === 0) {
            return <span className="unselectClass css-1v99tuv">--{label}--</span>;
        }

        if (e.value.length === 1) {
            return <span className="css-1v99tuv">{e.value[0].label}</span>;
        } else {
            return <span className="css-1v99tuv">{e.value.length} selected</span>;
        }
    };

    enableStatusTracking = () => {
        if (this.state.currentNote.categories) {
            for (let i = 0; i < this.state.currentNote.categories.length; i++) {
                const item = this.state.currentNote.categories[i];
                const match = this.props.noteCategoryTypes.find((x) => x.id === item.id);
                if (match && match.enableStatusTracking) return true;
            }
        }
        return false;
    };

    handleCheckChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentNote = this.state.currentNote;
        let returnToPriorTarget = this.state.returnToPriorTarget;
        let selectedAlertTarget = this.state.selectedAlertTarget;
        switch (event.target.name) {
            case 'chkPrivate':
                currentNote.private = event.target.checked;
                break;
            case 'chkAllowLocalEdit':
                currentNote.allowLocalEdit = event.target.checked;
                break;
            case 'chkModifiedBeforeApproval':
                currentNote.modifiedBeforeApproval = event.target.checked;
                break;
            case 'chkReturnToPriorTarget':
                returnToPriorTarget = event.target.checked;
                selectedAlertTarget = { id: 0, label: '', value: '' };
                if (returnToPriorTarget) {
                    if (currentNote.alert && currentNote.alert.actionTargetHistory) {
                        const maxSortOrder = Math.max(
                            ...currentNote.alert.actionTargetHistory.map((x) => x.sortOrder || 0)
                        );

                        const priorAlertUser = currentNote.alert.actionTargetHistory.find(
                            (x) => x.sortOrder === maxSortOrder
                        );

                        if (priorAlertUser)
                            selectedAlertTarget = {
                                id: 0,
                                label: this.getUserFullNameDisplay(priorAlertUser.user!),
                                value: priorAlertUser.user!.guid!,
                                guid: priorAlertUser.user!.guid!,
                            };
                    }
                } else {
                    if (
                        this.state.currentNote.alert &&
                        this.state.currentNote.alert.users &&
                        this.state.currentNote.alert.users.filter(
                            (x) => x.type!.id === AlertTypes.Act.Value && !x.endDate
                        ).length > 0
                    ) {
                        const currentTarget =
                            this.state.currentNote.alert.users &&
                            this.state.currentNote.alert.users.filter(
                                (x) => x.type!.id === AlertTypes.Act.Value && !x.endDate
                            )[0];
                        selectedAlertTarget = {
                            id: 0,
                            label: this.getUserFullNameDisplay(currentTarget.user!),
                            value: currentTarget.user!.guid!,
                            guid: currentTarget.user!.guid!,
                        };
                    }
                }
                break;
            default:
        }

        this.setState({
            currentNote: currentNote,
            hasUnsavedChanges: true,
            selectedAlertTarget: selectedAlertTarget,
            returnToPriorTarget: returnToPriorTarget,
        });
    };

    getContentLength = (): number => {
        if (this.state.currentNote.content) return this.state.currentNote.content.length;
        return 0;
    };

    getContextLength = (): number => {
        if (this.state.currentNote.context) return this.state.currentNote.context.length;
        return 0;
    };

    getNoteTypeDisplayName = () => {
        switch (this.props.currentNote.type?.id) {
            case NoteTypes.StrategyNote:
                return 'Strategy Note';
            case NoteTypes.PackageSettlementNote:
                return 'Deal Note';
            case NoteTypes.ZoneNote:
                return 'Zone Note';
            default:
                return 'Note';
        }
    };

    getNoteOriginEntityURL = (): string => {
        if (
            this.props.currentNote.type?.id === NoteTypes.StrategyNote &&
            this.props.originEntityKey
        ) {
            switch (this.getStrategyTypeId()) {
                case StrategyTypes.PlaintiffsFirm.Value:
                    return LocalRoutes.PlaintiffFirmStrategyDetail.replace(
                        ':guid',
                        this.props.originEntityKey
                    );
                case StrategyTypes.Jurisdiction.Value:
                    return LocalRoutes.JurisdictionStrategyDetail.replace(
                        ':guid',
                        this.props.originEntityKey
                    );
                case StrategyTypes.LocalCounsel.Value:
                    return LocalRoutes.LocalCounselStrategyDetail.replace(
                        ':guid',
                        this.props.originEntityKey
                    );
                case StrategyTypes.Projects.Value:
                    return LocalRoutes.ProjectDetail.replace(':guid', this.props.originEntityKey);
                default:
                    break;
            }
        }
        return '';
    };

    handleEditClick = (): void => {
        this.setState({
            forceNoteEditorInReadOnlyMode: false,
            showEditButton: false,
        });
    };

    getHeader = () => {
        if (!this.state.currentNote.type) return null;

        const showLocalEditAndPrivateButtons =
            (this.state.currentNote.type?.id === NoteTypes.CaseNote ||
                this.state.currentNote.type?.id === NoteTypes.ZoneNote) &&
            Authorization.userHasRight(UserRightsEnum.NoteSecurity, this.props.user);

        const showAddRelatedButton =
            this.props.useNoteAssociationsModal && (this.props.entityItems?.length ?? 0) > 0;

        return (
            <>
                <Stack
                    direction="horizontal"
                    style={{
                        justifyContent: 'space-between',
                        marginBottom: 30,
                    }}
                >
                    <h1 style={{ marginBottom: 0 }}>
                        {this.props.currentNote?.type?.id === NoteTypes.CaseNote &&
                        this.props.caseTitle ? (
                            <CaseName
                                user={this.props.user}
                                caseTitle={this.props.caseTitle}
                                openLinkInNewTab={true}
                            />
                        ) : (
                            <>
                                {this.getNoteOriginEntityURL() ? (
                                    <Link target="_blank" to={this.getNoteOriginEntityURL()}>
                                        {this.props.originEntityName}
                                    </Link>
                                ) : (
                                    this.props.originEntityName
                                )}
                                {this.props.originEntityName ? ' - ' : ''}
                                {this.getNoteTypeDisplayName()}
                            </>
                        )}
                    </h1>
                    <Stack direction="horizontal" gap={1}>
                        {this.state.authorizedToEdit && this.state.showEditButton && (
                            <IconButton
                                buttonCssClass="btn btn-no-bg text-gray"
                                icon="fal fa-pen"
                                onClick={this.handleEditClick}
                                text="Edit"
                            />
                        )}

                        {showAddRelatedButton && (
                            <AddRelatedButton
                                hasItems={(this.state.selectedEntityItems ?? []).length > 0}
                                onClick={() => {
                                    this.setState({ showNoteAssociationsModal: true });
                                }}
                            />
                        )}

                        {showLocalEditAndPrivateButtons && (
                            <>
                                <div
                                    className="text-gray"
                                    style={{ paddingLeft: 12, paddingRight: 16 }}
                                >
                                    <label>
                                        <input
                                            name="chkAllowLocalEdit"
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={this.handleCheckChanged}
                                            checked={
                                                this.state.currentNote.allowLocalEdit ||
                                                this.isAllowLocalEditSystemSet()
                                            }
                                            disabled={
                                                this.state.forceNoteEditorInReadOnlyMode ||
                                                this.state.currentNote.private
                                            }
                                        />
                                        <span
                                            className={
                                                'float-end ms-2 ' +
                                                (this.isAllowLocalEditSystemSet()
                                                    ? 'font-green'
                                                    : 'text-gray')
                                            }
                                        >
                                            <i className="fal fa-map-marker-edit px-1" />
                                            Allow Local Edit
                                            <Help
                                                type={ApplicationHelpType.Info}
                                                title="Allow Local Edit"
                                                helpText="By default, local counsel can create new case notes and view case notes but not edit case notes; “private” notes cannot be viewed by local counsel; “allow local edit” allows local counsel to edit an existing case note."
                                            />
                                        </span>
                                    </label>
                                </div>
                                <div
                                    className="text-gray"
                                    style={{ paddingLeft: 12, paddingRight: 16 }}
                                >
                                    <label>
                                        <input
                                            name="chkPrivate"
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={this.handleCheckChanged}
                                            checked={this.state.currentNote.private || false}
                                            disabled={
                                                this.state.forceNoteEditorInReadOnlyMode ||
                                                (!this.state.currentNote.private &&
                                                    (this.state.currentNote.allowLocalEdit ||
                                                        this.isAllowLocalEditSystemSet() ||
                                                        this.isActionTargetLocal()))
                                            }
                                        />
                                        <i className="fal fa-location-dot-slash ps-2 pe-1" />
                                        Private
                                    </label>
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Private"
                                        helpText="By default, local counsel can create new case notes and view case notes but not edit case notes; “private” notes cannot be viewed by local counsel; “allow local edit” allows local counsel to edit an existing case note."
                                    />
                                </div>
                            </>
                        )}

                        {this.state.currentNote.alert && (
                            <IconButton
                                buttonCssClass="btn btn-no-bg text-gray"
                                icon="fal fa-history"
                                onClick={() => this.setState({ showHistoryModal: true })}
                                text="History"
                            />
                        )}

                        {this.state.currentNote.alert?.users?.some(
                            (x) => x.type && x.type.id === AlertTypes.Watch.Value
                        ) && (
                            <IconButton
                                buttonCssClass="btn btn-no-bg text-gray"
                                icon="fal fa-glasses-alt"
                                onClick={() => this.setState({ showWatchGroup: true })}
                                text="Watch List"
                            />
                        )}
                        {!this.state.forceNoteEditorInReadOnlyMode && (
                            <>
                                {this.state.hasUnsavedChanges && (
                                    <button className="btn btn-orange" onClick={this.handleSave}>
                                        Save
                                    </button>
                                )}
                                <button className="btn btn-default" onClick={this.cancelEdit}>
                                    Cancel
                                </button>
                            </>
                        )}
                        <button className="btn btn-blue" onClick={this.handleBack}>
                            Back
                        </button>
                    </Stack>
                </Stack>

                {/* todo jec: move to own modal. keep state internally. Allow cancel to rollback changes */}
                {this.state.showNoteAssociationsModal && (
                    <RelatedEntitiesModal
                        availableOptions={this.props.entityItems ?? []}
                        title={this.props.noteAssociationModalTitle ?? ''}
                        validation={this.state.validation}
                        onCancel={() => this.setState({ showNoteAssociationsModal: false })}
                        onHide={() => {
                            this.setState({ showNoteAssociationsModal: false });
                        }}
                        onSubmit={(selectedItems: IMultiSelectOptions[]) => {
                            this.handleNoteAssociationSelected(selectedItems);
                            this.setState({ showNoteAssociationsModal: false });
                        }}
                        selectedEntityItems={this.state.selectedEntityItems ?? []}
                    />
                )}
            </>
        );
    };

    cancelEdit = () => {
        this.setState({
            additionalWatchGroupUsers: [],
            currentNote: cloneDeep(this.state.originalNote),
            showEditButton: true,
            forceNoteEditorInReadOnlyMode: true,
            hasUnsavedChanges: false,
            validation: {},
        });
    };

    handleBack = () => {
        this.state.noteStateChanged
            ? this.props.handleSaveComplete(this.state.currentNote)
            : this.props.handleCancel(this.state.currentNote.alert?.guid ?? '');
    };

    getNoteSubCategoryOptions = (values: INoteSubCategoryTypeModel[]) => {
        const options: IMultiSelectOptions[] = [];
        for (let i = 0; i < values.length; i++) {
            options.push({
                label: values[i].displayName!,
                value: values[i].name!,
                id: values[i].id,
                guid: values[i].guid!,
            });
        }
        return options;
    };

    getSelectedNoteSubCategoryValue = (values: INoteSubCategoryTypeModel[], id: number) => {
        const match = values.find((x) => x.id === id);
        if (match)
            return {
                label: match.displayName!,
                value: match.name!,
                id: match.id,
                guid: match.guid!,
            };
    };

    buildSubCategoryUI = () => {
        if (this.state.selectedNoteCategories.length == 0) return null;

        for (let i = 0; i < this.state.selectedNoteCategories.length; i++) {
            const categoryId = this.state.selectedNoteCategories[i].id;
            const noteCategoryTypeLookup = this.props.noteCategoryTypes.find(
                (x) => x.id === categoryId
            );
            if (
                noteCategoryTypeLookup &&
                noteCategoryTypeLookup.subCategories &&
                noteCategoryTypeLookup.subCategories.length > 0
            ) {
                const subCategoryId = this.getSelectedSubCategoryId(categoryId);

                return (
                    <div className="row mb-2">
                        <div className="col-sm-2">
                            <label className="text-gray">
                                Sub Category for {noteCategoryTypeLookup.displayName}*
                            </label>
                        </div>
                        <div className="col-sm-5 d-inline-block">
                            <CheckmateSelect
                                options={this.getNoteSubCategoryOptions(
                                    noteCategoryTypeLookup.subCategories
                                )}
                                name="subCategory"
                                value={this.getSelectedNoteSubCategoryValue(
                                    noteCategoryTypeLookup.subCategories,
                                    subCategoryId
                                )}
                                onChange={(e: any) => {
                                    this.onSearchableSingleSelectChange(e, 'subCategory');
                                }}
                            />
                            <span className="text-danger padding-10">
                                {this.state.validation.subCategory}
                            </span>
                        </div>
                    </div>
                );
            }
        }
    };

    getSelectedSubCategoryId = (categoryId: number): number => {
        if (this.state.currentNote.categories) {
            const match = this.state.currentNote.categories.find((x) => x.id === categoryId);
            if (match && match.subCategory) return match.subCategory.id;
        }
        return 0;
    };

    handleSaveWorkProductComplete = (document: IDocumentModel) => {
        const currentNoteCopy = cloneDeep(this.state.currentNote);
        if (!currentNoteCopy.documents) currentNoteCopy.documents = [];

        const match = currentNoteCopy.documents.find((x) => x.guid === document.guid);
        if (match) {
            Object.assign(match, document);
        } else {
            currentNoteCopy.documents.push(document);
        }

        currentNoteCopy.hasAttachment = true;

        this.setState({
            currentNote: currentNoteCopy,
            keepDocumentUploadDialogOpen: false,
            noteStateChanged: true,
        });
    };

    handleDeleteWorkProductComplete = (documentGuid: string) => {
        const currentNoteCopy = cloneDeep(this.state.currentNote);

        if (currentNoteCopy.documents) {
            const matchIndex = currentNoteCopy.documents.findIndex((x) => x.guid === documentGuid);
            if (matchIndex >= 0) {
                currentNoteCopy.documents.splice(matchIndex, 1);
                currentNoteCopy.hasAttachment = currentNoteCopy.documents.length > 0;

                this.setState({ currentNote: currentNoteCopy, noteStateChanged: true });
            }
        }
    };

    onSearchableSingleSelectChange(selectedItem: any, identifier: string) {
        const currentNote = cloneDeep(this.state.currentNote);
        let selectedAlertTarget = this.state.selectedAlertTarget;
        switch (identifier) {
            case 'actionTarget':
                if (selectedItem) selectedAlertTarget = selectedItem;
                else selectedAlertTarget = { id: 0, label: '', value: '' };
                break;
            case 'subCategory':
                if (currentNote.categories) {
                    const value: number = selectedItem ? selectedItem.id || 0 : 0;
                    const category = this.getCategoryBySubCategoryId(value);
                    if (!category) break;
                    const matchInCurrentNote = currentNote.categories.find(
                        (x) => x.id === category.id
                    );
                    if (matchInCurrentNote) {
                        matchInCurrentNote.subCategory = value ? { id: value } : undefined;
                    }
                    if (value) {
                        const categoryLookupMatch = this.props.noteCategoryTypes.find(
                            (x) => x.id === category.id
                        );
                        if (categoryLookupMatch && categoryLookupMatch.subCategories) {
                            const subCategoryLookupMatch = categoryLookupMatch.subCategories.find(
                                (x) => x.id === value
                            );
                            if (subCategoryLookupMatch) {
                                if (
                                    !currentNote.content &&
                                    subCategoryLookupMatch.contentTemplateText
                                )
                                    currentNote.content =
                                        subCategoryLookupMatch.contentTemplateText;
                                if (
                                    !currentNote.context &&
                                    subCategoryLookupMatch.contextTemplateText
                                )
                                    currentNote.context =
                                        subCategoryLookupMatch.contextTemplateText;
                            }
                        }
                    }
                }
                break;
            case 'purpose': {
                const purposeId: number = selectedItem ? selectedItem.id || 0 : 0;
                const newPurpose = this.state.notePurposeTypes.find((f) => f.id == purposeId);
                currentNote.purpose = newPurpose!;
                if (
                    currentNote.purpose.id === NotePurposeTypesEnum.Approval ||
                    currentNote.purpose.id === NotePurposeTypesEnum.Acknowledgment
                ) {
                    if (!currentNote.alert) currentNote.alert = {};
                    if (!selectedAlertTarget.label && this.props.defaultAlertTarget) {
                        selectedAlertTarget = {
                            id: 0,
                            label:
                                this.props.defaultAlertTarget.profile!.firstName +
                                ' ' +
                                this.props.defaultAlertTarget.profile!.lastName,
                            value: this.props.defaultAlertTarget.guid!,
                            guid: this.props.defaultAlertTarget.guid!,
                        };
                    }

                    if (
                        this.props.defaultWatchTargets &&
                        this.props.defaultWatchTargets.length > 0
                    ) {
                        for (let i = 0; i < this.props.defaultWatchTargets.length; i++) {
                            const item = this.props.defaultWatchTargets[i];
                            if (!currentNote.alert.users) currentNote.alert.users = [];
                            if (
                                currentNote.alert.users.filter(
                                    (x) =>
                                        x.type &&
                                        x.type.id === AlertTypes.Watch.Value &&
                                        x.user &&
                                        x.user.guid === item.guid
                                ).length === 0
                            ) {
                                const watchTarget: IAlertUser = {
                                    user: JSON.parse(JSON.stringify(item)),
                                    type: { id: AlertTypes.Watch.Value },
                                    read: false,
                                };
                                currentNote.alert.users.push(watchTarget);
                            }
                        }
                    }
                } else {
                    currentNote.alert = undefined;
                    selectedAlertTarget = { id: 0, label: '', value: '' };
                }
                break;
            }
            case 'approvalStatus': {
                const approvalStatusId: number = selectedItem ? selectedItem.id || 0 : 0;
                currentNote.approvalStatus = { id: approvalStatusId };
                break;
            }
            case 'acknowledgmentStatus': {
                const acknowledgmentStatusId: number = selectedItem ? selectedItem.id || 0 : 0;
                currentNote.acknowledgmentStatus = { id: acknowledgmentStatusId };
                break;
            }
            default:
                break;
        }

        this.setState({
            currentNote,
            selectedAlertTarget,
            hasUnsavedChanges: true,
        });
    }

    getCategoryBySubCategoryId = (subCategoryId: number) => {
        for (let index = 0; index < this.props.noteCategoryTypes.length; index++) {
            const categoryElement = this.props.noteCategoryTypes[index];
            if (categoryElement.subCategories) {
                const match = categoryElement.subCategories.find((x) => x.id === subCategoryId);
                if (match) return categoryElement;
            }
        }
    };

    onAdditionalWatchGroupUsersSelected = (optionList: any) => {
        this.setState({
            additionalWatchGroupUsers: optionList,
        });
    };

    getAllAdditionalWatchGroupUserList = () => {
        if (this.state.currentNote.alert) {
            const userGuidsToExclude: string[] = [];
            if (this.state.currentNote.alert.users) {
                this.state.currentNote.alert.users.map((item: IAlertUser) => {
                    userGuidsToExclude.push(item.user!.guid!);
                });
            }

            const output: IMultiSelectOptions[] = [];
            for (let i = 0; i < this.state.allAlertTargets.length; i++) {
                const item = this.state.allAlertTargets[i];
                if (userGuidsToExclude.filter((x) => x === item.guid).length === 0)
                    output.push(JSON.parse(JSON.stringify(item)));
            }

            return output;
        }
    };

    handleWatchGroupPopupClose = () => {
        const currentNote = this.state.currentNote;
        let hasUnsavedChanges = this.state.hasUnsavedChanges;
        if (this.state.currentNote.alert) {
            if (!currentNote.alert!.users) currentNote.alert!.users = [];

            currentNote.alert!.users = currentNote.alert!.users!.filter((x) => !x.new);
            for (let i = 0; i < this.state.additionalWatchGroupUsers.length; i++) {
                const match = this.state.allUsers.find(
                    (x) => x.guid === this.state.additionalWatchGroupUsers[i].guid
                );
                if (
                    match &&
                    currentNote.alert!.users!.filter(
                        (x) =>
                            x.type &&
                            x.type.id == AlertTypes.Watch.Value &&
                            x.user &&
                            x.user.guid === match.guid
                    ).length === 0
                ) {
                    currentNote.alert!.users!.push({
                        type: { id: AlertTypes.Watch.Value },
                        user: JSON.parse(JSON.stringify(match)),
                        read: false,
                        new: true,
                    });
                    hasUnsavedChanges = true;
                }
            }
        }

        this.setState({
            currentNote: currentNote,
            hasUnsavedChanges: hasUnsavedChanges,
            showWatchGroup: false,
            addUserToWatchGroup: false,
        });
    };

    getNotePurposesByNoteType = () => {
        if (
            this.state.currentNote.type?.id === NoteTypes.Personal ||
            this.state.currentNote.type?.id === NoteTypes.StrategySignificantChange
        )
            return this.state.notePurposeTypes.filter((x) => x.id === NotePurposeTypesEnum.Info);

        return this.state.notePurposeTypes;
    };

    addConversation = () => {
        if (!this.state.newChatMessage) return;

        if (this.state.currentNote.alert) {
            const currentNote = this.state.currentNote;
            if (!currentNote.conversations) currentNote.conversations = [];
            const conv: INoteConversationModel = {
                guid: EmptyGuid,
                text: this.state.newChatMessage,
                author: JSON.parse(JSON.stringify(this.props.user)),
                dateUTC: Common.currentUTCTime(),
            };

            currentNote.conversations.push(conv);
            this.setState({
                currentNote: currentNote,
                newChatMessage: '',
                pendingChatMessagePost: false,
            });
        }
    };

    getPriorTargetName = () => {
        if (this.state.currentNote.alert && this.state.currentNote.alert.actionTargetHistory) {
            const maxSortOrder = Math.max(
                ...this.state.currentNote.alert.actionTargetHistory.map((x) => x.sortOrder || 0)
            );

            const match = this.state.currentNote.alert.actionTargetHistory.find(
                (x) => x.sortOrder === maxSortOrder
            );
            if (match?.user) {
                return this.getUserFullNameDisplay(match?.user);
            }
        }

        return '';
    };

    getUserFullNameDisplay = (user: IUserModel) => {
        const fullName = `${user.profile!.firstName} ${user.profile!.lastName}`;
        const isLocalSuffix =
            user.activeZoneDataScope && this.getIsLocalBasicUser(user) ? ' (Local)' : '';
        return fullName + isLocalSuffix;
    };

    getOuterChatBoxHeight(): string {
        const mainSectionDiv: any = document.getElementById('NoteMainSection');
        if (mainSectionDiv && mainSectionDiv.clientHeight) {
            return (parseInt(mainSectionDiv.clientHeight) - 38).toString() + 'px';
        }

        return '672.25px';
    }

    getChatHistoryBoxHeight(): string {
        const mainSectionDiv: any = document.getElementById('NoteMainSection');
        if (mainSectionDiv && mainSectionDiv.clientHeight) {
            return (parseInt(mainSectionDiv.clientHeight) - 135).toString() + 'px';
        }

        return '577.25px';
    }

    isAllowLocalEditSystemSet = () => {
        const match = this.state.allUsers.find(
            (x) => x.guid === this.state.selectedAlertTarget.guid
        );

        if (match) {
            const currentZoneDataScope = match.organizationRoles?.find(
                (x) => x.guid === Auth.getUserDefaultOrganization()?.guid
            )?.dataScope;

            return (
                currentZoneDataScope?.id === DataScopesEnum.LocalBasic.Value &&
                !this.state.currentNote.allowLocalEdit
            );
        }

        return false;
    };

    getActionTargetHistoryData = () => {
        let output: IKeyValuePairModel[] = [];
        let actionTargetHistory: IAlertUser[] = [];
        const sortOrder = 1;

        if (this.state.currentNote.alert && this.state.currentNote.alert.actionTargetHistory) {
            actionTargetHistory = this.state.currentNote.alert.actionTargetHistory;
            const maxSortOrder = Math.max(...actionTargetHistory.map((x) => x.sortOrder || 0));

            if (
                this.state.currentNote.alert.actionTargetHistory.filter((x) => x.sortOrder).length >
                0
            ) {
                // Only get items with sort order > 0.
                // Sort Order = 0 contains the user who created the alert in case it needs to be sent back the first time.
                for (let i = 0; i < actionTargetHistory.filter((x) => x.sortOrder).length; i++) {
                    const toAdd: IKeyValuePairModel = {
                        key: this.getUserFullNameDisplay(actionTargetHistory[i].user!),
                        sortOrder: sortOrder,
                        value:
                            i < actionTargetHistory.length - 1
                                ? actionTargetHistory[i + 1].endDate!
                                : this.state.currentNote.createdDate || '',
                    };
                    output.push(toAdd);
                }
            }

            if (
                this.state.currentNote.alert.users &&
                this.state.currentNote.alert.users.filter(
                    (x) => x.type!.id === AlertTypes.Act.Value && !x.endDate
                ).length > 0
            ) {
                const currentTarget =
                    this.state.currentNote.alert.users &&
                    this.state.currentNote.alert.users.filter(
                        (x) => x.type!.id === AlertTypes.Act.Value && !x.endDate
                    )[0];

                const toAdd: IKeyValuePairModel = {
                    key: this.getUserFullNameDisplay(currentTarget.user!),
                    sortOrder: maxSortOrder + 1,
                    value:
                        maxSortOrder &&
                        actionTargetHistory.find((x) => x.sortOrder === maxSortOrder)
                            ? actionTargetHistory.find((x) => x.sortOrder === maxSortOrder)!
                                  .endDate || ''
                            : this.state.currentNote.createdDate || '',
                };
                output.push(toAdd);
            }
        }

        output = output.sort(Sort.compareDate('sortOrder', '', 'desc'));
        return output;
    };

    allowReturnToPriorUser = () => {
        if (
            this.state.currentNote.purpose.id === NotePurposeTypesEnum.Acknowledgment &&
            this.state.currentNote.acknowledgmentStatus &&
            (this.state.currentNote.acknowledgmentStatus.id ===
                NoteAcknowledgementStatusTypesEnum.Acknowledge ||
                this.state.currentNote.acknowledgmentStatus.id ===
                    NoteAcknowledgementStatusTypesEnum.Decline)
        )
            return false;

        if (
            this.state.currentNote.purpose.id === NotePurposeTypesEnum.Approval &&
            this.state.currentNote.approvalStatus &&
            (this.state.currentNote.approvalStatus.id === NoteApprovalStatusTypesEnum.Approved ||
                this.state.currentNote.approvalStatus.id ===
                    NoteApprovalStatusTypesEnum.NotApproved)
        )
            return false;

        if (this.state.currentNote.alert && this.state.currentNote.alert.actionTargetHistory) {
            const maxSortOrder = Math.max(
                ...this.state.currentNote.alert.actionTargetHistory.map((x) => x.sortOrder || 0)
            );
            if (
                this.state.currentNote.alert.actionTargetHistory.filter(
                    (x) => x.sortOrder === maxSortOrder
                ).length > 0 &&
                this.state.currentNote.alert.actionTargetHistory.filter(
                    (x) => x.sortOrder === maxSortOrder
                )[0].user!.guid !== this.props.user.guid
            )
                return true;
        }

        return false;
    };

    isActionTargetLocal = () => {
        if (this.state.selectedAlertTarget.guid) {
            const userMatch = this.state.allUsers.find(
                (x) => x.guid === this.state.selectedAlertTarget.guid
            );
            if (userMatch && this.getIsLocalBasicUser(userMatch)) return true;
        }

        return false;
    };

    getStrategyTypeId = () => {
        return (
            this.props.strategyTypeId ??
            this.props.originEntityType ??
            this.state.currentNote?.originStrategyType
        );
    };

    getSelectedActionTargetDataScope = () => {
        return this.state.allUsers.find((x) => x.guid === this.state.selectedAlertTarget?.guid)
            ?.activeZoneDataScope;
    };

    getIsLocalBasicUser = (user: IUserModel) => {
        return user.activeZoneDataScope?.id === DataScopesEnum.LocalBasic.Value;
    };

    public render() {
        if (this.state.pendingResponse) return <Loader />;

        const { guid, purpose } = this.state.currentNote;

        const isGuidValid = guid && guid !== EmptyGuid;
        const isInfoPurpose = purpose.id === NotePurposeTypesEnum.Info;
        const isLocalBasicUser = this.getIsLocalBasicUser(this.props.user);
        const displayPurposeAsLabel = isGuidValid && isInfoPurpose && isLocalBasicUser;

        return (
            <div className="mb-4">
                {this.state.forceNoteEditorInReadOnlyMode || !this.state.authorizedToEdit ? (
                    <>
                        {this.getHeader()}
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="row mb-2">
                                    <div className="col-sm-2">
                                        <label className="text-gray">Purpose *</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <span>{this.state.currentNote.purpose.displayName}</span>
                                    </div>
                                </div>

                                {this.state.currentNote.purpose.id ===
                                NotePurposeTypesEnum.Approval ? (
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <label className="text-gray">Decision</label>
                                        </div>
                                        <div
                                            className="col-sm-10"
                                            style={{ display: 'inline-block' }}
                                        >
                                            <span>
                                                {this.state.currentNote.approvalStatus
                                                    ? this.state.currentNote.approvalStatus
                                                          .displayName
                                                    : ''}
                                                {this.state.currentNote.modifiedBeforeApproval
                                                    ? ' (Approved with Modification)'
                                                    : ''}
                                            </span>
                                            {this.state.currentNote.approvalStatusModifiedBy &&
                                            this.state.currentNote.approvalStatusModifiedDate ? (
                                                <div>
                                                    Decision last modified by{' '}
                                                    {this.state.currentNote.approvalStatusModifiedBy
                                                        .profile!.firstName +
                                                        ' ' +
                                                        this.state.currentNote
                                                            .approvalStatusModifiedBy.profile!
                                                            .lastName}{' '}
                                                    on{' '}
                                                    {Common.dateTimeFormatToLocal(
                                                        this.state.currentNote.approvalStatusModifiedDate.toString()
                                                    )}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}

                                {this.state.currentNote.purpose.id ===
                                NotePurposeTypesEnum.Acknowledgment ? (
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <label className="text-gray">Decision</label>
                                        </div>
                                        <div
                                            className="col-sm-10"
                                            style={{ display: 'inline-block' }}
                                        >
                                            {this.state.currentNote
                                                .acknowledgmentStatusModifiedBy &&
                                            this.state.currentNote
                                                .acknowledgmentStatusModifiedDate ? (
                                                <span>
                                                    Decision last modified by{' '}
                                                    {this.state.currentNote
                                                        .acknowledgmentStatusModifiedBy.profile!
                                                        .firstName +
                                                        ' ' +
                                                        this.state.currentNote
                                                            .acknowledgmentStatusModifiedBy.profile!
                                                            .lastName}{' '}
                                                    on{' '}
                                                    {Common.dateTimeFormatToLocal(
                                                        this.state.currentNote.acknowledgmentStatusModifiedDate.toString()
                                                    )}
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}

                                <div className="row mb-2">
                                    <div className="col-sm-2">
                                        <label className="text-gray">Category *</label>
                                    </div>
                                    <div className="col-sm-10">
                                        {this.state.currentNote.categories
                                            ? this.state.currentNote.categories.map(
                                                  (item: INoteCategoryModel, i: number) => {
                                                      return (
                                                          <div key={i}>
                                                              <span>
                                                                  {item.displayName}
                                                                  {item.subCategory
                                                                      ? ' - ' +
                                                                        item.subCategory!
                                                                            .displayName
                                                                      : ''}
                                                              </span>
                                                          </div>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </div>
                                </div>
                                {this.state.currentNote.categories
                                    ? this.state.currentNote.categories.map(
                                          (item: INoteCategoryModel, i: number) => {
                                              return (
                                                  <div key={i}>
                                                      {this.buildAdditionalFieldsByNoteCategory(
                                                          item
                                                      )}
                                                  </div>
                                              );
                                          }
                                      )
                                    : null}
                                {this.props.enableNoteAssociations &&
                                this.state.selectedEntityItems ? (
                                    <div className="row" style={{ marginBottom: '50px' }}>
                                        <label className="text-gray">
                                            {this.props.noteAssociationTitle || 'Association(s)'}
                                        </label>
                                        <ul>
                                            {this.state.selectedEntityItems.map(
                                                (item: IMultiSelectOptions) => {
                                                    return <li key={item.id}>{item.label}</li>;
                                                }
                                            )}
                                        </ul>
                                    </div>
                                ) : null}
                                {this.state.currentNote.alert ? (
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <label className="text-gray">Action Target*</label>
                                        </div>
                                        <div className="col-sm-5 d-inline-block">
                                            {this.state.currentNote.alert.users ? (
                                                <ul>
                                                    {this.state.currentNote.alert
                                                        .users!.filter(
                                                            (x) =>
                                                                x.type &&
                                                                x.type.id === AlertTypes.Act.Value
                                                        )
                                                        .map((alertUser: IAlertUser, i: number) => {
                                                            return (
                                                                <li key={i}>
                                                                    {this.getUserFullNameDisplay(
                                                                        alertUser.user!
                                                                    )}
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                                <div>
                                    <div className="row mb-2">
                                        <label className="text-gray">Summary</label>
                                        <div>
                                            {this.state.currentNote!.content
                                                ? this.state.currentNote!.content!.replace(
                                                      /\\n/gi,
                                                      '\n'
                                                  )
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label className="text-gray">Context</label>
                                        <div>{this.state.currentNote!.context}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                {this.state.currentNote.purpose.id !== NotePurposeTypesEnum.Info ? (
                                    <div>
                                        <label className="text-gray">Chat</label>
                                        <div
                                            className="border-gray"
                                            style={{ height: this.getOuterChatBoxHeight() }}
                                        >
                                            <div
                                                className="thin-bottom-border"
                                                style={{
                                                    height: this.getChatHistoryBoxHeight(),
                                                    overflowY: 'auto',
                                                    padding: '10px',
                                                }}
                                            >
                                                {this.state.currentNote.conversations
                                                    ? this.state.currentNote.conversations.map(
                                                          (
                                                              item: INoteConversationModel,
                                                              i: number
                                                          ) => {
                                                              return (
                                                                  <div
                                                                      key={i}
                                                                      className="padding-bottom-med"
                                                                  >
                                                                      <label className="bold">
                                                                          {item.author!.profile!
                                                                              .firstName +
                                                                              ' ' +
                                                                              item.author!.profile!
                                                                                  .lastName +
                                                                              (item.guid ===
                                                                              EmptyGuid
                                                                                  ? ''
                                                                                  : ' ' +
                                                                                    Common.dateTimeFormat(
                                                                                        item.dateUTC
                                                                                    ))}
                                                                      </label>
                                                                      <div>{item.text}</div>
                                                                  </div>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <div className="row" style={{ marginTop: 20 }}>
                                <WorkProductWrapper
                                    authorizedToEdit={
                                        !this.props.authorizedToEdit &&
                                        !this.state.forceNoteEditorInReadOnlyMode
                                    }
                                    documents={this.state.currentNote.documents}
                                    entityGuid={this.state.currentNote.guid!}
                                    entityTypeId={EntityTypes.Note}
                                    keepDocumentUploadDialogOpen={
                                        this.state.keepDocumentUploadDialogOpen
                                    }
                                    noteTypeId={
                                        this.props.currentNote.type
                                            ? this.props.currentNote.type.id
                                            : undefined
                                    }
                                    onCreateEntityBeforeUpload={this.saveNote}
                                    onSaveComplete={this.handleSaveWorkProductComplete}
                                    parentEntityJson={JSON.stringify(this.state.currentNote)}
                                    strategyTypeId={this.getStrategyTypeId()}
                                    title="Attachments"
                                    uploadOnly={true}
                                    user={this.props.user}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        {this.getHeader()}
                        {this.state.validation.model && (
                            <div style={{ padding: '10px 0' }}>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                        )}

                        <div className="row">
                            <div id="NoteMainSection" className="col-sm-9">
                                <div className="row mb-2">
                                    <div className="col-sm-2">
                                        <label className="text-gray">Purpose *</label>
                                    </div>
                                    {displayPurposeAsLabel ? (
                                        <div className="col-sm-3">
                                            <span>
                                                {this.state.currentNote.purpose.displayName}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="col-sm-3">
                                            <CheckmateSelect
                                                options={CheckmateSelectHelper.getLookupOptions(
                                                    this.getNotePurposesByNoteType()
                                                )}
                                                value={CheckmateSelectHelper.getSelectedValueById(
                                                    this.getNotePurposesByNoteType(),
                                                    this.state.currentNote.purpose.id
                                                )}
                                                name="purpose"
                                                onChange={(e: any) => {
                                                    this.onSearchableSingleSelectChange(
                                                        e,
                                                        'purpose'
                                                    );
                                                }}
                                            />
                                            <span className="text-danger">
                                                {this.state.validation.notePurpose}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                {this.state.currentNote.purpose.id ===
                                NotePurposeTypesEnum.Approval ? (
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <label className="text-gray">Decision</label>
                                        </div>
                                        <div className="col-sm-10 d-inline-block">
                                            <div className="d-inline-block col-sm-2">
                                                <CheckmateSelect
                                                    options={CheckmateSelectHelper.getLookupOptions(
                                                        this.state.approvalStatusTypes
                                                    )}
                                                    value={CheckmateSelectHelper.getSelectedValueById(
                                                        this.state.approvalStatusTypes,
                                                        this.state.currentNote.approvalStatus
                                                            ? this.state.currentNote.approvalStatus
                                                                  .id
                                                            : 0
                                                    )}
                                                    name="approvalStatus"
                                                    onChange={(e: any) => {
                                                        this.onSearchableSingleSelectChange(
                                                            e,
                                                            'approvalStatus'
                                                        );
                                                    }}
                                                    isDisabled={
                                                        !Authorization.userHasRight(
                                                            UserRightsEnum.EditNoteApprovalStatus,
                                                            this.props.user
                                                        )
                                                    }
                                                />
                                            </div>
                                            <span className="btn btn-no-bg text-gray ps-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={
                                                        this.state.currentNote
                                                            .modifiedBeforeApproval || false
                                                    }
                                                    disabled={
                                                        !Authorization.userHasRight(
                                                            UserRightsEnum.EditNoteApprovalStatus,
                                                            this.props.user
                                                        )
                                                    }
                                                    name="chkModifiedBeforeApproval"
                                                    onChange={this.handleCheckChanged}
                                                />
                                                <span className="text-gray ms-2">
                                                    Approved with Modification
                                                </span>
                                                <Help
                                                    type={ApplicationHelpType.Info}
                                                    title="Approved with Modification"
                                                    helpText="The user providing their approval should check this box if their approval is contingent upon modifications to the original request. "
                                                />
                                            </span>
                                            {this.state.currentNote.approvalStatusModifiedBy &&
                                            this.state.currentNote.approvalStatusModifiedDate ? (
                                                <div>
                                                    Decision last modified by{' '}
                                                    {this.state.currentNote.approvalStatusModifiedBy
                                                        .profile!.firstName +
                                                        ' ' +
                                                        this.state.currentNote
                                                            .approvalStatusModifiedBy.profile!
                                                            .lastName}{' '}
                                                    on{' '}
                                                    {Common.dateTimeFormatToLocal(
                                                        this.state.currentNote.approvalStatusModifiedDate.toString()
                                                    )}
                                                </div>
                                            ) : null}
                                            <span className="text-danger">
                                                {this.state.validation.approvalStatus}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}

                                {this.state.currentNote.purpose.id ===
                                NotePurposeTypesEnum.Acknowledgment ? (
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <label className="text-gray">Decision</label>
                                        </div>
                                        <div className="col-sm-10 d-inline-block">
                                            <div className="col-sm-3 d-inline-block">
                                                <CheckmateSelect
                                                    options={CheckmateSelectHelper.getLookupOptions(
                                                        this.state.acknowledgmentStatusTypes
                                                    )}
                                                    value={CheckmateSelectHelper.getSelectedValueById(
                                                        this.state.acknowledgmentStatusTypes,
                                                        this.state.currentNote.acknowledgmentStatus
                                                            ? this.state.currentNote
                                                                  .acknowledgmentStatus.id
                                                            : 0
                                                    )}
                                                    name="acknowledgmentStatus"
                                                    onChange={(e: any) => {
                                                        this.onSearchableSingleSelectChange(
                                                            e,
                                                            'acknowledgmentStatus'
                                                        );
                                                    }}
                                                    isDisabled={
                                                        Authorization.isLocalScope(
                                                            this.props.user
                                                        ) &&
                                                        this.state.selectedAlertTarget.guid !==
                                                            this.props.user.guid
                                                    }
                                                />
                                            </div>
                                            {this.state.currentNote
                                                .acknowledgmentStatusModifiedBy &&
                                            this.state.currentNote
                                                .acknowledgmentStatusModifiedDate ? (
                                                <div>
                                                    Decision last modified by{' '}
                                                    {this.state.currentNote
                                                        .acknowledgmentStatusModifiedBy.profile!
                                                        .firstName +
                                                        ' ' +
                                                        this.state.currentNote
                                                            .acknowledgmentStatusModifiedBy.profile!
                                                            .lastName}{' '}
                                                    on{' '}
                                                    {Common.dateTimeFormatToLocal(
                                                        this.state.currentNote.acknowledgmentStatusModifiedDate.toString()
                                                    )}
                                                </div>
                                            ) : null}
                                            <span className="text-danger">
                                                {this.state.validation.acknowledgmentStatus}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}

                                <div className="row mb-2">
                                    <div className="col-sm-2">
                                        <label className="text-gray">Category *</label>
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="row">
                                            <div className="col-sm-11">
                                                <CheckmateSelect
                                                    isMulti={true}
                                                    options={NoteHelper.getNoteCategoryTypeOptionsForMultiselect(
                                                        this.props.noteCategoryTypes
                                                    )}
                                                    value={this.state.selectedNoteCategories}
                                                    onChange={this.handleNoteCategoriesSelected}
                                                />
                                            </div>
                                            <div className="col-sm-1 pt-2">
                                                {this.state.selectedNoteCategories.length > 1 ? (
                                                    <Help
                                                        type={ApplicationHelpType.Check}
                                                        title={'Selected Categories'}
                                                        helpText={CaseHelper.getMultiSelectSelectedValuesTitleText(
                                                            this.state.selectedNoteCategories
                                                        )}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                        <span className="text-danger col-sm-3">
                                            {this.state.validation.categories}
                                        </span>
                                    </div>
                                </div>

                                {this.buildSubCategoryUI()}

                                {this.state.currentNote && this.state.currentNote.categories ? (
                                    <div>
                                        {this.state.currentNote.categories.map(
                                            (item: INoteCategoryModel, i: number) => {
                                                const currentError =
                                                    this.state.validation.additionalFields
                                                        ?.map((field) => {
                                                            const parts = field.split('|');

                                                            return {
                                                                id: parts[0],
                                                                message: parts[1],
                                                            };
                                                        })
                                                        ?.find(
                                                            (error) =>
                                                                error.id === item.id.toString()
                                                        );

                                                return (
                                                    <div key={i}>
                                                        {currentError && (
                                                            <span className="text-danger">
                                                                {currentError.message}
                                                            </span>
                                                        )}
                                                        {this.buildAdditionalFieldsByNoteCategory(
                                                            item
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                ) : null}
                                {this.props.enableNoteAssociations ? (
                                    <div className="form-group">
                                        <label className="text-gray">
                                            {this.props.noteAssociationTitle || 'Association(s)'}
                                        </label>
                                        <span className="text-danger">
                                            &nbsp;{this.state.validation.associations}
                                        </span>
                                        <div
                                            className="mb-2 "
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div style={{ flexGrow: 1 }}>
                                                <CheckmateSelect
                                                    isMulti={true}
                                                    options={this.props.entityItems || []}
                                                    value={this.state.selectedEntityItems}
                                                    onChange={this.handleNoteAssociationSelected}
                                                />
                                            </div>
                                            <div>
                                                {this.state.selectedEntityItems &&
                                                this.state.selectedEntityItems.length > 1 ? (
                                                    <Help
                                                        type={ApplicationHelpType.Check}
                                                        title="Selected Values"
                                                        helpText={NoteHelper.getSelectedValuesTextFromMultiSelect(
                                                            this.state.selectedEntityItems
                                                        )}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <div>
                                    {this.state.currentNote.alert ? (
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <label className="text-gray">Action Target *</label>
                                            </div>
                                            <div className="col-sm-4 d-inline-block">
                                                {Authorization.isLocalScope(this.props.user) &&
                                                this.state.currentNote.type?.id ===
                                                    NoteTypes.CaseNote ? (
                                                    <>
                                                        {this.state.selectedAlertTarget
                                                            ? this.state.selectedAlertTarget.label
                                                            : null}
                                                        <span className="text-danger">
                                                            {this.state.validation.actionTarget}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <CheckmateSelect
                                                            options={this.state.allAlertTargets}
                                                            name="actionTarget"
                                                            value={[this.state.selectedAlertTarget]}
                                                            onChange={(e: any) => {
                                                                this.onSearchableSingleSelectChange(
                                                                    e,
                                                                    'actionTarget'
                                                                );
                                                            }}
                                                            isDisabled={
                                                                this.state.returnToPriorTarget
                                                            }
                                                        />
                                                        <span className="text-danger">
                                                            {this.state.validation.actionTarget}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-sm-4">
                                                {this.allowReturnToPriorUser() ? (
                                                    <span className="btn btn-no-bg text-gray ps-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={
                                                                this.state.returnToPriorTarget ||
                                                                false
                                                            }
                                                            name="chkReturnToPriorTarget"
                                                            onChange={this.handleCheckChanged}
                                                        />
                                                        <span className="text-gray ms-2">
                                                            Return to {this.getPriorTargetName()}
                                                        </span>
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="mb-2">
                                        <label className="text-gray">
                                            Summary*&nbsp;
                                            <Help
                                                type={ApplicationHelpType.Info}
                                                title="Summary"
                                                helpText="The Summary should reflect the entire scope of the Case Note as a stand-alone statement.  The Summary will be displayed in lists throughout Checkmate, including the Case File, Note Queries and the Alert List and will also be used in routine reporting.  Additional Context can be provided and is viewable when a user opens a Note. Additional information and details can be provided in the Context box"
                                            />
                                        </label>
                                        <span className="text-gray">
                                            &nbsp;&nbsp;(
                                            {1500 -
                                                this.getContentLength() +
                                                ' characters remaining'}
                                            )
                                        </span>
                                        <span className="text-danger">
                                            &nbsp;{this.state.validation.content}
                                        </span>
                                        <textarea
                                            className="form-control"
                                            value={
                                                this.state.currentNote!.content
                                                    ? this.state.currentNote!.content!.replace(
                                                          /\\n/gi,
                                                          '\n'
                                                      )
                                                    : ''
                                            }
                                            name="noteContent"
                                            rows={8}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => {
                                                this.handleChangeValue(e);
                                            }}
                                            maxLength={1500}
                                        />
                                    </div>
                                    <div className="fmb-2">
                                        <label className="text-gray">Context</label>
                                        <span className="text-gray">
                                            &nbsp;&nbsp;(
                                            {30000 -
                                                this.getContextLength() +
                                                ' characters remaining'}
                                            )
                                        </span>
                                        <span className="text-danger">
                                            &nbsp;{this.state.validation.context}
                                        </span>
                                        <textarea
                                            className="form-control"
                                            value={this.state.currentNote!.context ?? ''}
                                            name="noteContext"
                                            rows={11}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => {
                                                this.handleChangeValue(e);
                                            }}
                                            maxLength={30000}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                id="NoteChatSection"
                                className="col-sm-3"
                                style={{ position: 'relative', left: 0, top: 0 }}
                            >
                                {this.state.currentNote.purpose.id !==
                                    NotePurposeTypesEnum.Info && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: '0 12px',
                                        }}
                                    >
                                        <label className="text-gray">Chat</label>
                                        <div
                                            className="border-gray border-bottom-0"
                                            style={{
                                                overflowY: 'auto',
                                                padding: '10px',
                                                flexGrow: 1,
                                            }}
                                        >
                                            {this.state.currentNote.conversations
                                                ? this.state.currentNote.conversations.map(
                                                      (item: INoteConversationModel, i: number) => {
                                                          return (
                                                              <div
                                                                  className="padding-bottom-med"
                                                                  key={i}
                                                              >
                                                                  <label className="bold">
                                                                      {item.author!.profile!
                                                                          .firstName +
                                                                          ' ' +
                                                                          item.author!.profile!
                                                                              .lastName +
                                                                          (item.guid === EmptyGuid
                                                                              ? ''
                                                                              : ' ' +
                                                                                Common.dateTimeFormat(
                                                                                    item.dateUTC
                                                                                ))}
                                                                  </label>
                                                                  <div>{item.text}</div>
                                                              </div>
                                                          );
                                                      }
                                                  )
                                                : null}
                                        </div>
                                        <div className="border-gray" style={{ display: 'flex' }}>
                                            <textarea
                                                className="form-control fill-light-gray only-right-border"
                                                value={this.state.newChatMessage}
                                                name="newChatMessage"
                                                rows={4}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                                ) => {
                                                    this.handleChangeValue(e);
                                                }}
                                                maxLength={30000}
                                            />
                                            <button
                                                className="btn btn-no-bg"
                                                onClick={this.addConversation}
                                            >
                                                <i className="fal fa-lg fa-share-all font-orange" />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: 20 }}>
                            <WorkProductWrapper
                                authorizedToEdit={true}
                                documents={this.state.currentNote.documents}
                                entityGuid={this.state.currentNote.guid!}
                                entityTypeId={EntityTypes.Note}
                                keepDocumentUploadDialogOpen={
                                    this.state.keepDocumentUploadDialogOpen
                                }
                                onCreateEntityBeforeUpload={this.saveNote}
                                onDeleteComplete={this.handleDeleteWorkProductComplete}
                                onSaveComplete={this.handleSaveWorkProductComplete}
                                noteTypeId={
                                    this.props.currentNote.type
                                        ? this.props.currentNote.type.id
                                        : undefined
                                }
                                parentEntityJson={JSON.stringify(this.state.currentNote)}
                                strategyTypeId={this.getStrategyTypeId()}
                                title="Attachments"
                                uploadOnly={true}
                                user={this.props.user}
                            />
                        </div>
                    </div>
                )}

                {this.state.showWatchGroup ? (
                    <Modal
                        centered
                        show={this.state.showWatchGroup}
                        onHide={() => {
                            this.setState({ showWatchGroup: false });
                        }}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Watch List</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label className="text-gray mb-2">Current Watch List</label>
                            {this.state.currentNote
                                .alert!.users!.filter((x) => x.type!.id === AlertTypes.Watch.Value)
                                .map((item: IAlertUser, i: number) => {
                                    return (
                                        <div className="form-group" key={i}>
                                            <span>{this.getUserFullNameDisplay(item.user!)}</span>
                                        </div>
                                    );
                                })}
                            {!this.state.forceNoteEditorInReadOnlyMode ? (
                                <div className="row mb-2 mt-4">
                                    <div className="col-sm-5">
                                        <label className="text-gray">Add Users to Watch List</label>
                                    </div>
                                    <div className="col-sm-10" style={{ display: 'inline-block' }}>
                                        <CheckmateSelect
                                            isMulti={true}
                                            options={
                                                this.getAllAdditionalWatchGroupUserList() || []
                                            }
                                            value={this.state.additionalWatchGroupUsers}
                                            onChange={this.onAdditionalWatchGroupUsersSelected}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-black float-end"
                                    onClick={this.handleWatchGroupPopupClose}
                                >
                                    OK
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                {this.state.showHistoryModal ? (
                    <Modal
                        centered
                        show={this.state.showHistoryModal}
                        onHide={() => {
                            this.setState({ showHistoryModal: false });
                        }}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>History</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.currentNote.decisionHistory?.length ? (
                                <>
                                    <h4 className="pb-3">Decision History</h4>
                                    <table className="table">
                                        <thead>
                                            <th>Previous</th>
                                            <th>Current</th>
                                            <th>User</th>
                                            <th>Date</th>
                                        </thead>
                                        <tbody>
                                            {this.state.currentNote.decisionHistory.map(
                                                (item: IAuditLogModel, i: number) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>
                                                                {item.previousValue ?? '<Blank>'}{' '}
                                                            </td>
                                                            <td>{item.value ?? '<Blank>'} </td>
                                                            <td>{item.user}</td>
                                                            <td>
                                                                {Common.dateTimeFormatToLocal(
                                                                    item.date
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            ) : null}
                            <h4 className="py-3">Action Target History</h4>
                            <table className="table">
                                <thead>
                                    <th>User</th>
                                    <th>Date Targeted</th>
                                </thead>
                                <tbody>
                                    {this.getActionTargetHistoryData().map(
                                        (item: IKeyValuePairModel, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{item.key} </td>
                                                    <td>
                                                        {Common.dateTimeFormatToLocal(item.value)}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-black float-end"
                                    onClick={() => this.setState({ showHistoryModal: false })}
                                >
                                    OK
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function AddRelatedButton({ hasItems, onClick }: { hasItems?: boolean; onClick: () => void }) {
    return (
        <span
            className={`btn btn-no-bg ps-3 ${hasItems ? 'color-orange' : 'text-gray'}`}
            onClick={onClick}
        >
            <i className="fa-regular fa-head-side-brain" />
            <span className="ms-2">Add Related Case Expert(s)</span>
        </span>
    );
}
