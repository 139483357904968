import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetStaticDataDate = (documentTypeId: number) => {
    return useQuery({
        queryKey: ['get-static-data-date', { documentTypeId }],
        queryFn: async () => {
            const response = await _apiClient.getStaticDataDate(documentTypeId);

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve report date.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        staleTime: 0,
        cacheTime: 0,
    });
};
