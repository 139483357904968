import ApiClient from '../../services/apiClient';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useDeleteTriggerMutation = (onError: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (triggerGuid: string) => {
            const response = await _apiClient.deleteTrigger(triggerGuid);

            if (!response?.httpResponse.ok) {
                if (response.httpResponse.status === 400) {
                    throw new Error(`Failed to delete Trigger : ${response.errorMessage}`);
                } else if (response.httpResponse.status === 403) {
                    throw new Error(`Forbidden to delete trigger`);
                }

                throw new Error('Failed to delete Trigger');
            }

            return true;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
    });
