import BaseRequestProcessor from './baseRequestProcessor';
import { IApiRequest } from '../interfaces/IApiRequest';
import { IApiRequestOptions } from '../interfaces/IApiRequestOptions';
import { IApiResponse } from '../interfaces/IApiResponse';
import { IRequestProcessor } from '../interfaces/IRequestProcessor';

class JsonRequestProcessor extends BaseRequestProcessor {
    /**
     * Constructs a new request processor.
     * @param {RequestProcessor | null} owner The request processor that owns this instance.
     */
    constructor(owner: IRequestProcessor) {
        super(owner);
    }

    /**
     * Prepares a JSON request for transmission.
     * @param {ApiRequest} request The request object.
     * @returns {Promise<Request>} The prepared request object.
     */
    async prepareRequest<T>(request: IApiRequest<T>): Promise<IApiRequest<T>> {
        request = await super.prepareRequest(request);

        request.httpRequest.headers.set("Content-Type", "application/json");

        request.httpRequest.headers.set("Accept", "application/json");

        return request;
    }

    /**
     * Processes a response after completion.
     * @param {ApiRequest} request The request object.
     * @param {ApiResponse} response The response object.
     * @returns {Promise<ApiResponse>} The processed response object.
     */
    async processResponse<TRequest, TResponse>(request: IApiRequest<TRequest>, response: IApiResponse<TResponse>): Promise<IApiResponse<TResponse>> {
        try {
            var json = await response.httpResponse.json();

            if (response.httpResponse.status >= 400) {
                response.errorMessage = json.message;
                if (json.errors)
                    response.errors.push(...json.errors);
                response.payload = undefined;
            }
            else {
                response.payload = json;
            }
        }
        catch (e) {
            response.errorMessage = "Unexpected Error.";
            response.payload = undefined;
            response.errors.push(e);
        }

        response = await super.processResponse(request, response);

        return response;
    }
}

export default JsonRequestProcessor;