import * as React from 'react';

import { EmptyGuid, EntityTypes, LookupDataEnums, UserRightsEnum } from '../../utilities/Constants';
import {
    ICaseModel,
    ICaseSearchModel,
    ICheckmateTaskModel,
    IPackageSettlementBaseModel,
} from '../../interfaces/ICase';
import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../shared/dialog';
import CheckmateSelect from '../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { IEntityModel } from '../../interfaces/IAssertionDescriptor';
import { ITaskCategoryModel } from '../../interfaces/ITask';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../shared/SortableHeader';
import ValidateUtils from '../../shared/validations';
import { cloneDeep } from 'lodash';

const _apiClient = new ApiClient();

enum DataObjectIdentifiers {
    Tasks = 1,
    CaseSearchResults = 2,
}

interface ITaskListProps {
    defaultTaskActivityTypeId: number;
    ownerList: IUserModel[];
    packageDeals?: IPackageSettlementBaseModel[];
    parentEntityGuid: string;
    parentEntityType: ILookupModel;
    readOnly: boolean;
    refreshParent: (tasks: ICheckmateTaskModel[]) => void;
    tasks?: ICheckmateTaskModel[];
    taskType: number;
    title?: string;
    user: IUserModel;
    validTaskAssociationEntityTypes?: number[];
}

interface ITaskListState {
    addNewTask: boolean;
    caseSearchResults?: ICaseModel[];
    caseSearchString: string;
    pendingResponse: boolean;
    selectedPackageSettlements?: IMultiSelectOptions[];
    selectedTaskToAddEdit?: ICheckmateTaskModel;
    selectedTaskToAddEditIndex: number;
    showAddCasesOptionsDialog: boolean;
    showConfirmDeleteDialog?: boolean;
    taskCategories: ITaskCategoryModel[];
    taskIndexToDelete?: number;
    tasks: ICheckmateTaskModel[];
    taskStatuses: ILookupModel[];
    validation: IValidation;
}

export class TaskList extends React.Component<ITaskListProps, ITaskListState> {
    constructor(props: ITaskListProps) {
        super(props);

        this.state = {
            addNewTask: false,
            caseSearchString: '',
            pendingResponse: false,
            selectedTaskToAddEditIndex: 0,
            showAddCasesOptionsDialog: false,
            taskCategories: [],
            tasks: [],
            taskStatuses: [],
            validation: {},
        };
    }

    componentDidMount() {
        this.loadInitialData();

        if (this.props.tasks) {
            this.prepAndSetStateTaskData(this.props.tasks);
        }
    }

    componentDidUpdate(prevProps: ITaskListProps) {
        if (prevProps.tasks != this.props.tasks) {
            this.prepAndSetStateTaskData(this.props.tasks || []);
        }
    }

    prepAndSetStateTaskData = (tasks: ICheckmateTaskModel[]) => {
        for (let i = 0; i < tasks.length; i++) {
            if (tasks[i].associations) {
                const caseTaskAssociations = tasks[i].associations!.filter(
                    (x) => x.entityType && x.entityType.id === EntityTypes.Case
                );
                for (let j = 0; j < caseTaskAssociations.length; j++) {
                    tasks![i].associations![j].entityId = j + 1;
                }
            }
        }

        this.setState({
            tasks: tasks,
        });
    };

    loadInitialData = async () => {
        this.setState({ pendingResponse: true });

        const taskStatuses = await this.fetchTaskStatuses();
        if (!taskStatuses) return;

        const taskCategories = await this.fetchTaskCategories();
        if (!taskCategories) return;

        const filteredTaskCategories = taskCategories.filter(
            (x) => x.taskType && x.taskType.id === this.props.taskType
        );

        this.setState({
            pendingResponse: false,
            taskStatuses: taskStatuses,
            taskCategories: filteredTaskCategories,
        });
    };

    fetchTaskStatuses = async () => {
        const response = await _apiClient.getLookupData(LookupDataEnums.TaskStatusTypes);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchTaskCategories = async () => {
        const response = await _apiClient.getTaskCategories();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    sortData = (
        key: string,
        order: string,
        subKey?: string,
        subGrandKey?: string,
        dataObjectIdentifier?: number
    ) => {
        switch (dataObjectIdentifier) {
            case DataObjectIdentifiers.Tasks:
                this.sortTasks(key, order, subKey, subGrandKey);
                break;
            case DataObjectIdentifiers.CaseSearchResults:
                this.sortCaseSearchResults(key, order, subKey, subGrandKey);
                break;
        }
    };

    sortCaseSearchResults = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        this.setState({ pendingResponse: true });

        if (this.state.caseSearchResults) {
            const caseSearchResults = this.state.caseSearchResults.sort(
                Sort.compareValues(key, subKey, order, subGrandKey)
            );
            this.setState({
                caseSearchResults: caseSearchResults,
                pendingResponse: false,
            });
        }
    };

    sortTasks = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let tasks = this.state.tasks;
        if (tasks) {
            if (key.includes('Date')) {
                tasks = tasks.sort(Sort.compareDate(key, subKey, order));
            } else {
                tasks = tasks.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }

        this.props.refreshParent(tasks);
        this.setState({ tasks: tasks });
    };

    deleteTask = () => {
        if (this.state.taskIndexToDelete == undefined) return;
        const tasks = this.state.tasks;
        tasks.splice(this.state.taskIndexToDelete!, 1);
        this.setState({
            tasks: tasks,
            showConfirmDeleteDialog: false,
            taskIndexToDelete: undefined,
        });
        this.props.refreshParent(tasks);
    };

    finalizeChange = () => {
        const validation: IValidation = {};
        if (!this.state.selectedTaskToAddEdit) {
            validation.addEditTask = [DisplayMessages.UnexpectedError];
            this.setState({ validation: validation });
            return;
        }

        let validationFailed = false;
        if (!this.state.selectedTaskToAddEdit.name) {
            validation.name = [(this.props.title || 'Name') + ' is Required'];
            validationFailed = true;
        }

        if (
            !this.state.selectedTaskToAddEdit.status ||
            this.state.selectedTaskToAddEdit.status.id === 0
        ) {
            validation.status = ['Status is required'];
            validationFailed = true;
        }

        //if (!this.state.selectedTaskToAddEdit.taskCategory || this.state.selectedTaskToAddEdit.taskCategory.id === 0) {
        //    validation.taskCategory = ["Category is required"];
        //    validationFailed = true;
        //}

        if (validationFailed) {
            this.setState({ validation: validation });
            return;
        }

        const tasks = [...this.state.tasks]; // Create a new array to avoid mutating state directly

        if (this.state.selectedTaskToAddEdit.guid === EmptyGuid) {
            if (this.state.addNewTask) {
                // Add a new task to the tasks array
                tasks.push(JSON.parse(JSON.stringify(this.state.selectedTaskToAddEdit)));
            } else {
                // Update an existing task in the tasks array
                const match = tasks[this.state.selectedTaskToAddEditIndex];
                if (match) {
                    tasks[this.state.selectedTaskToAddEditIndex] = JSON.parse(
                        JSON.stringify(this.state.selectedTaskToAddEdit)
                    );
                }
            }
        } else {
            // Update an existing task in the tasks array
            const matchIndex = tasks.findIndex(
                (x) => x.guid === this.state.selectedTaskToAddEdit!.guid
            );
            if (matchIndex > -1) {
                tasks[matchIndex] = { ...this.state.selectedTaskToAddEdit };
            }
        }

        this.setState({
            selectedTaskToAddEdit: undefined,
            selectedPackageSettlements: [],
            tasks,
        });

        this.props.refreshParent(tasks);
    };

    handleCheckmateSelectChange = (selectedItem: any, name: string) => {
        const selectedTaskToAddEdit = cloneDeep(this.state.selectedTaskToAddEdit);
        const validation = cloneDeep(this.state.validation);

        if (!selectedTaskToAddEdit) {
            validation.addEditTask = [DisplayMessages.UnexpectedError];
            this.setState({ validation: validation });
            return;
        }
        const value = selectedItem?.id ?? 0;
        const valueGuid = selectedItem?.guid ?? '';

        switch (name) {
            case 'status':
                if (value) {
                    validation.status = [''];
                    selectedTaskToAddEdit.status = this.state.taskStatuses.find(
                        (x) => x.id === value
                    );
                } else {
                    validation.status = ['Status is required'];
                    selectedTaskToAddEdit.status = undefined;
                }
                break;
            case 'taskCategory':
                if (value) {
                    validation.taskCategory = [''];
                    selectedTaskToAddEdit.taskCategory = JSON.parse(
                        JSON.stringify(this.state.taskCategories.find((x) => x.id === value))
                    );
                } else {
                    //validation.taskCategory = ["Category is required"];
                    selectedTaskToAddEdit.taskCategory = undefined;
                }
                break;
            case 'owner': {
                const ownerMatch = this.props.ownerList.find((x) => x.guid === valueGuid);
                selectedTaskToAddEdit.owner = ownerMatch
                    ? JSON.parse(JSON.stringify(ownerMatch))
                    : undefined;
                break;
            }
            default:
                break;
        }

        this.setState({ selectedTaskToAddEdit });
    };

    handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue(name: string, value: any) {
        const selectedTaskToAddEdit = cloneDeep(this.state.selectedTaskToAddEdit);
        const validation = cloneDeep(this.state.validation);

        if (!selectedTaskToAddEdit) {
            validation.addEditTask = [DisplayMessages.UnexpectedError];
            this.setState({ validation: validation });
            return;
        }

        switch (name) {
            case 'name':
                selectedTaskToAddEdit.name = value;
                validation.name = [value ? '' : (this.props.title || 'Name') + ' is Required'];
                break;
            case 'deadlineDate':
                selectedTaskToAddEdit.deadlineDate = value;
                break;
            case 'comments':
                selectedTaskToAddEdit.comments = value;
                validation.comments = [''];
                break;
            default:
                break;
        }
        this.setState({
            selectedTaskToAddEdit,
        });
    }

    getUserFullName = (user?: IUserModel) => {
        if (user && user.profile && user.profile.firstName && user.profile.lastName) {
            return user.profile.firstName.concat(' ', user.profile.lastName);
        }
        return '';
    };

    handleAddNewTask = () => {
        const selectedTaskToAddEdit: ICheckmateTaskModel = {
            id: 0,
            guid: EmptyGuid,
            taskType: { id: this.props.taskType },
            parentEntityGuid: this.props.parentEntityGuid || EmptyGuid,
            parentEntityType: this.props.parentEntityType,
            activityType: { id: this.props.defaultTaskActivityTypeId },
            levelOfEffort: { id: 1 },
        };

        const validation = { ...this.state.validation, addEdditTask: [], name: [], status: [] };

        this.setState({
            selectedTaskToAddEdit,
            addNewTask: true,
            validation,
        });
    };

    getOptionsForPackageDeals() {
        const options: IMultiSelectOptions[] = [];
        if (this.props.packageDeals) {
            for (let i = 0; i < this.props.packageDeals.length; i++) {
                const item = this.props.packageDeals[i];
                options.push({
                    label: item.displayName || item.name!,
                    value: item.guid!,
                    id: i,
                    guid: item.guid!,
                });
            }
        }
        return options;
    }

    handlePackageSettlementsSelected = (optionsList: any) => {
        if (this.state.selectedTaskToAddEdit) {
            const selectedTaskToAddEdit = { ...this.state.selectedTaskToAddEdit };

            if (selectedTaskToAddEdit.associations) {
                for (let i = 0; i < selectedTaskToAddEdit.associations.length; i++) {
                    if (
                        selectedTaskToAddEdit.associations[i].entityType &&
                        selectedTaskToAddEdit.associations[i].entityType.id ===
                            EntityTypes.PackageSettlement
                    )
                        selectedTaskToAddEdit.associations.splice(i, 1);
                }
            }

            if (optionsList.guid) {
                if (!selectedTaskToAddEdit.associations) selectedTaskToAddEdit.associations = [];
                selectedTaskToAddEdit.associations.push({
                    entityType: { id: EntityTypes.PackageSettlement },
                    entityGuid: optionsList.guid,
                    name: optionsList.label,
                    displayName: optionsList.label,
                });
            }

            this.setState({
                selectedPackageSettlements: optionsList,
                selectedTaskToAddEdit: selectedTaskToAddEdit,
            });
        }
    };

    selectLabel = (e: any, label: string) => {
        if (!e.value || e.value.length === 0) {
            return <span className="unselectClass css-1v99tuv">--{label}--</span>;
        }

        if (e.value.length === 1) {
            return <span className="css-1v99tuv">{e.value[0].label}</span>;
        } else {
            return <span className="css-1v99tuv">{e.value.length} selected</span>;
        }
    };

    getTaskAssociationHeaderText = () => {
        let text = '';
        if (this.props.validTaskAssociationEntityTypes) {
            if (
                this.props.validTaskAssociationEntityTypes.filter(
                    (x) => x == EntityTypes.PackageSettlement
                ).length > 0
            ) {
                text = 'Deal ';
            }

            if (
                this.props.validTaskAssociationEntityTypes.filter((x) => x == EntityTypes.Case)
                    .length > 0
            ) {
                if (!text) text = 'Case(s)';
                else text += '| Case(s)';
            }
        }

        return text;
    };

    runCaseSearch = async () => {
        const validation: IValidation = {};

        if (!this.state.caseSearchString || this.state.caseSearchString.trim() == '') {
            validation.caseNameOrNumberSearch = ['Enter a Case Number or Case Name. '];
            this.setState({ validation: validation });
            return;
        } else {
            validation.caseNameOrNumberSearch = [''];
        }

        this.setState({ pendingResponse: true });

        const caseSearchParameters: ICaseSearchModel = {
            searchText: this.state.caseSearchString,
        };

        const res = await _apiClient.getCases(caseSearchParameters);
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return false;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
            });
            return false;
        }

        this.setState({
            caseSearchResults: res.payload!,
            validation: validation,
            pendingResponse: false,
        });
    };

    addCaseAssociation = (caseItem: ICaseModel) => {
        if (this.state.selectedTaskToAddEdit) {
            const validation: IValidation = {};
            const selectedTaskToAddEdit = this.state.selectedTaskToAddEdit;
            if (!selectedTaskToAddEdit.associations) selectedTaskToAddEdit.associations = [];

            let showAddCasesOptionsDialog = false;
            if (
                selectedTaskToAddEdit.associations.filter(
                    (x) =>
                        x.entityType &&
                        x.entityType.id === EntityTypes.Case &&
                        x.entityGuid === caseItem.guid
                ).length == 0
            ) {
                const existingEntityIds = selectedTaskToAddEdit.associations
                    .filter((x) => x.entityId && x.entityType.id === EntityTypes.Case)
                    .map((ta: IEntityModel) => {
                        return ta.entityId!;
                    });
                const nextEntityId =
                    existingEntityIds.length === 0 ? 1 : Math.max(...existingEntityIds) + 1;

                selectedTaskToAddEdit.associations.push({
                    entityType: { id: EntityTypes.Case },
                    entityGuid: caseItem.guid,
                    name: caseItem.caseName,
                    displayName: caseItem.caseName,
                    entityId: nextEntityId,
                });
            } else {
                validation.addEditTask = ['Case Already Exists'];
                showAddCasesOptionsDialog = true;
            }

            this.setState({
                selectedTaskToAddEdit: selectedTaskToAddEdit,
                validation: validation,
                showAddCasesOptionsDialog: showAddCasesOptionsDialog,
            });
        }
    };

    deleteCaseAssociation = (tempEntityId: number) => {
        if (this.state.selectedTaskToAddEdit) {
            const selectedTaskToAddEdit = this.state.selectedTaskToAddEdit;
            if (selectedTaskToAddEdit.associations) {
                selectedTaskToAddEdit.associations.splice(
                    selectedTaskToAddEdit.associations.findIndex(
                        (x) => x.entityId === tempEntityId
                    ),
                    1
                );
                this.setState({ selectedTaskToAddEdit: selectedTaskToAddEdit });
            }
        }
    };

    editTask = (selectedTask: ICheckmateTaskModel, index: number) => {
        const selectedPackageSettlements: IMultiSelectOptions[] = [];

        if (selectedTask.associations) {
            const packageSettlementAssociations = selectedTask.associations.filter(
                (x) => x.entityType && x.entityType.id === EntityTypes.PackageSettlement
            );
            for (let i = 0; i < packageSettlementAssociations.length; i++) {
                selectedPackageSettlements.push({
                    label:
                        packageSettlementAssociations[i].displayName ||
                        packageSettlementAssociations[i].name!,
                    value: packageSettlementAssociations[i].entityGuid!,
                    id: i,
                    guid: packageSettlementAssociations[i].entityGuid!,
                });
            }
        }

        this.setState({
            selectedTaskToAddEdit: selectedTask,
            addNewTask: false,
            selectedTaskToAddEditIndex: index,
            selectedPackageSettlements: selectedPackageSettlements,
        });
    };

    public render() {
        if (this.state.pendingResponse) {
            return <Loader />;
        }

        const showTaskAssociationTypeColumn =
            (this.props.validTaskAssociationEntityTypes?.length ?? 0) > 0;

        return (
            <div className="mb-3">
                <div className="row">
                    <div className="col-sm-3">
                        <label className="text-gray">{this.props.title || 'Tasks'}</label>
                        &nbsp;
                        {this.props.readOnly ? null : (
                            <button
                                className="btn btn-default btn-icon"
                                onClick={this.handleAddNewTask}
                            >
                                <i className="fal fa-lg fa-plus" />
                            </button>
                        )}
                    </div>
                </div>
                {(this.state.tasks?.length ?? 0) > 0 ? (
                    <table className="table">
                        <thead>
                            <tr style={{ verticalAlign: 'middle' }}>
                                <SortableHeader
                                    headerText={this.props.title || 'Name'}
                                    sortKey="name"
                                    onSort={this.sortData}
                                    dataObjectIdentifier={DataObjectIdentifiers.Tasks}
                                />
                                <SortableHeader
                                    headerText="Status"
                                    sortKey="status"
                                    subKey="name"
                                    onSort={this.sortData}
                                    dataObjectIdentifier={DataObjectIdentifiers.Tasks}
                                />
                                <SortableHeader
                                    headerText="Category"
                                    sortKey="status"
                                    subKey="name"
                                    onSort={this.sortData}
                                    dataObjectIdentifier={DataObjectIdentifiers.Tasks}
                                />
                                <SortableHeader
                                    headerText="Due Date"
                                    sortKey="deadlineDate"
                                    onSort={this.sortData}
                                    dataObjectIdentifier={DataObjectIdentifiers.Tasks}
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Owner"
                                    sortKey="owner"
                                    subKey="profile"
                                    subGrandKey="firstName"
                                    onSort={this.sortData}
                                    dataObjectIdentifier={DataObjectIdentifiers.Tasks}
                                />
                                {(this.props.validTaskAssociationEntityTypes?.length ?? 0 > 0) && (
                                    <SortableHeader
                                        headerText={this.getTaskAssociationHeaderText()}
                                        sortKey="estimatedHours"
                                        onSort={this.sortData}
                                        dataObjectIdentifier={DataObjectIdentifiers.Tasks}
                                        noWrap
                                    />
                                )}
                                <th>Comments</th>
                                {Authorization.userHasRight(
                                    UserRightsEnum.AddEditDeletePlaintiffsFirmStrategy,
                                    this.props.user
                                ) &&
                                    !this.props.readOnly && <th />}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tasks.map(
                                (checkmateTask: ICheckmateTaskModel, i: number) => {
                                    const packageSettlementAssociations =
                                        checkmateTask.associations?.filter(
                                            (x) => x.entityType.id === EntityTypes.PackageSettlement
                                        );
                                    const caseAssociations = checkmateTask.associations?.filter(
                                        (x) => x.entityType.id === EntityTypes.Case
                                    );

                                    const showAssociationsPipe =
                                        (packageSettlementAssociations?.length ?? 0) > 0 &&
                                        (caseAssociations?.length ?? 0) > 0;

                                    return (
                                        <tr key={i}>
                                            <td>{checkmateTask.name}</td>
                                            <td>
                                                {checkmateTask.status
                                                    ? checkmateTask.status.displayName ||
                                                      checkmateTask.status.name
                                                    : ''}
                                            </td>
                                            <td>
                                                {checkmateTask.taskCategory
                                                    ? checkmateTask.taskCategory.displayName
                                                    : ''}
                                            </td>
                                            <td>{Common.dateFormat(checkmateTask.deadlineDate)}</td>
                                            <td>{this.getUserFullName(checkmateTask.owner)}</td>
                                            {showTaskAssociationTypeColumn && (
                                                <td>
                                                    {checkmateTask.associations && (
                                                        <ul>
                                                            {packageSettlementAssociations?.map(
                                                                (item: IEntityModel) => {
                                                                    return (
                                                                        <div>
                                                                            <Link
                                                                                to={LocalRoutes.PackageSettlementDetails.replace(
                                                                                    ':guid',
                                                                                    item.entityGuid!
                                                                                )}
                                                                                target="_blank"
                                                                            >
                                                                                {item.name}
                                                                            </Link>
                                                                            {showAssociationsPipe && (
                                                                                <> | </>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                            {caseAssociations?.map(
                                                                (
                                                                    item: IEntityModel,
                                                                    index: number,
                                                                    arr: IEntityModel[]
                                                                ) => {
                                                                    const showPipe =
                                                                        index !== arr.length - 1;

                                                                    return (
                                                                        <>
                                                                            <div
                                                                                key={
                                                                                    item.entityGuid
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    to={LocalRoutes.CaseFile.replace(
                                                                                        ':guid',
                                                                                        item.entityGuid!
                                                                                    )}
                                                                                    target="_blank"
                                                                                >
                                                                                    {item.name}
                                                                                </Link>
                                                                                {showPipe && (
                                                                                    <> | </>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    )}
                                                </td>
                                            )}
                                            <td
                                                className="col-sm-4"
                                                style={{ whiteSpace: 'pre-wrap' }}
                                            >
                                                {checkmateTask.comments}
                                            </td>
                                            {Authorization.userHasRight(
                                                UserRightsEnum.AddEditDeletePlaintiffsFirmStrategy,
                                                this.props.user
                                            ) && !this.props.readOnly ? (
                                                <td className="col-sm-1">
                                                    <button
                                                        className="btn btn-no-bg float-end px-2"
                                                        onClick={() =>
                                                            this.setState({
                                                                showConfirmDeleteDialog: true,
                                                                taskIndexToDelete: i,
                                                            })
                                                        }
                                                    >
                                                        <i className="fal fa-lg fa-trash-alt"></i>
                                                    </button>
                                                    <button
                                                        className="btn btn-no-bg float-end px-2"
                                                        onClick={() => {
                                                            this.editTask(checkmateTask, i);
                                                        }}
                                                    >
                                                        <i className="fal fa-lg fa-pen" />
                                                    </button>
                                                </td>
                                            ) : null}
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                ) : (
                    <span>No {this.props.title} found!</span>
                )}
                {this.state.selectedTaskToAddEdit && (
                    <Modal
                        centered
                        show={true}
                        onHide={() => this.setState({ selectedTaskToAddEdit: undefined })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Add/Edit {this.props.title || 'Task'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">
                                    {this.state.validation.addEditTask}
                                </span>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    <label htmlFor="name">{this.props.title || 'Task'}*</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={this.state.selectedTaskToAddEdit.name}
                                        onChange={this.handleChange}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.name}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    <label htmlFor="status">Status*</label>
                                </div>
                                <div className="col-sm-8">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getLookupOptions(
                                            this.state.taskStatuses
                                        )}
                                        name="status"
                                        onChange={(e: any) => {
                                            this.handleCheckmateSelectChange(e, 'status');
                                        }}
                                        value={CheckmateSelectHelper.getSelectedValueById(
                                            this.state.taskStatuses,
                                            this.state.selectedTaskToAddEdit.status?.id ?? 0
                                        )}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.status}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    {' '}
                                    <label htmlFor="taskCategory">Category</label>
                                </div>
                                <div className="col-sm-8">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getLookupOptions(
                                            this.state.taskCategories
                                        )}
                                        name="taskCategory"
                                        onChange={(e: any) => {
                                            this.handleCheckmateSelectChange(e, 'taskCategory');
                                        }}
                                        value={CheckmateSelectHelper.getSelectedValueById(
                                            this.state.taskCategories,
                                            this.state.selectedTaskToAddEdit.taskCategory
                                                ? this.state.selectedTaskToAddEdit.taskCategory.id
                                                : 0
                                        )}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.taskCategory}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    {' '}
                                    <label htmlFor="deadlineDate">Deadline Date</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        type="date"
                                        className={
                                            'form-control' +
                                            (this.state.selectedTaskToAddEdit.deadlineDate &&
                                            Common.isValidDate(
                                                this.state.selectedTaskToAddEdit.deadlineDate
                                            )
                                                ? ''
                                                : ' unselectClass')
                                        }
                                        name="deadlineDate"
                                        value={Common.dateFormat(
                                            this.state.selectedTaskToAddEdit.deadlineDate
                                        )}
                                        onChange={this.handleChange}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.deadlineDate}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    {' '}
                                    <label htmlFor="owner">Owner</label>
                                </div>
                                <div className="col-sm-8">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getUserOptions(
                                            this.props.ownerList
                                        )}
                                        name="owner"
                                        onChange={(e: any) => {
                                            this.handleCheckmateSelectChange(e, 'owner');
                                        }}
                                        value={CheckmateSelectHelper.getSelectedUserValue(
                                            this.props.ownerList,
                                            this.state.selectedTaskToAddEdit.owner
                                                ? this.state.selectedTaskToAddEdit.owner.guid || ''
                                                : ''
                                        )}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.owner}
                                    </span>
                                </div>
                            </div>
                            {this.props.validTaskAssociationEntityTypes &&
                            this.props.validTaskAssociationEntityTypes.filter(
                                (x) => x == EntityTypes.PackageSettlement
                            ).length > 0 &&
                            this.props.packageDeals ? (
                                <div className="row py-1">
                                    <div className="col-sm-4">
                                        {' '}
                                        <label htmlFor="packageSettlements">Package Deal(s)</label>
                                    </div>
                                    <div className="col-sm-8">
                                        <CheckmateSelect
                                            isMulti={false}
                                            options={this.getOptionsForPackageDeals()}
                                            value={this.state.selectedPackageSettlements}
                                            onChange={this.handlePackageSettlementsSelected}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {this.props.validTaskAssociationEntityTypes &&
                            this.props.validTaskAssociationEntityTypes.filter(
                                (x) => x == EntityTypes.Case
                            ).length > 0 ? (
                                <div className="row py-1">
                                    <div className="col-sm-4">
                                        {' '}
                                        <label htmlFor="cases">Case(s)</label>
                                    </div>
                                    <div className="col-sm-8">
                                        <button
                                            className="btn btn-default btn-icon"
                                            onClick={() =>
                                                this.setState({ showAddCasesOptionsDialog: true })
                                            }
                                        >
                                            <i className="fal fa-lg fa-plus" />
                                        </button>
                                    </div>
                                    {this.state.selectedTaskToAddEdit.associations
                                        ? this.state.selectedTaskToAddEdit.associations
                                              .filter(
                                                  (x) =>
                                                      x.entityType &&
                                                      x.entityType.id == EntityTypes.Case
                                              )
                                              .map((ta: IEntityModel) => {
                                                  return (
                                                      <div className="row py-1">
                                                          <div className="col-sm-10">{ta.name}</div>
                                                          <div className="col-sm-2">
                                                              <button
                                                                  className="btn btn-gray btn-icon float-end"
                                                                  onClick={() => {
                                                                      this.deleteCaseAssociation(
                                                                          ta.entityId!
                                                                      );
                                                                  }}
                                                              >
                                                                  <i className="fal fa-times color-white" />
                                                              </button>
                                                          </div>
                                                      </div>
                                                  );
                                              })
                                        : null}
                                </div>
                            ) : null}
                            <div className="form-group">
                                <label className="control-label" htmlFor="comments">
                                    Comments
                                </label>
                                <textarea
                                    className="form-control"
                                    name="comments"
                                    value={this.state.selectedTaskToAddEdit.comments}
                                    onChange={this.handleChange}
                                />
                                <span className="text-danger">
                                    {this.state.validation.comments}
                                </span>
                            </div>
                            <div className="mt-2">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.finalizeChange}
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({ selectedTaskToAddEdit: undefined })
                                    }
                                >
                                    Cancel
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {this.state.showAddCasesOptionsDialog ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() => this.setState({ showAddCasesOptionsDialog: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Add Case</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="no-padding case-search-div">
                                        <input
                                            className="form-control float-end"
                                            type="text"
                                            name="caseNameOrNumberSearch"
                                            placeholder="Search by Name or Number"
                                            value={this.state.caseSearchString}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                                this.setState({
                                                    caseSearchString: event.target.value,
                                                })
                                            }
                                        ></input>
                                    </div>
                                    <div
                                        className="float-end no-padding"
                                        style={{ display: 'inline-block' }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-gray btn-icon float-end"
                                            onClick={() => {
                                                this.setState({
                                                    caseSearchString: '',
                                                    caseSearchResults: undefined,
                                                });
                                            }}
                                        >
                                            <i className="fal fa-times color-white" />
                                        </button>
                                        <div
                                            style={{ display: 'inline-block' }}
                                            className="float-end"
                                        >
                                            <button
                                                type="submit"
                                                className="btn btn-black btn-icon float-end "
                                                onClick={this.runCaseSearch}
                                            >
                                                <i className="fal fa-search color-white" />
                                            </button>
                                        </div>
                                    </div>
                                    <span>
                                        <span className="text-danger">
                                            {this.state.validation.caseNameOrNumberSearch}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div style={{ maxHeight: '500px', overflowX: 'auto' }}>
                                {this.state.caseSearchResults ? (
                                    this.state.caseSearchResults.length > 0 ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <SortableHeader
                                                        headerText="Case Number"
                                                        sortKey="caseNumber"
                                                        onSort={this.sortData}
                                                    />
                                                    <SortableHeader
                                                        headerText="Case Name"
                                                        sortKey="caseName"
                                                        onSort={this.sortData}
                                                    />
                                                    <SortableHeader
                                                        headerText="Status"
                                                        sortKey="caseStatus"
                                                        subKey="displayName"
                                                        onSort={this.sortData}
                                                    />
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.caseSearchResults.map(
                                                    (caseItem: ICaseModel, i: number) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{caseItem.caseNumber}</td>
                                                                <td>{caseItem.caseName}</td>
                                                                <td>
                                                                    {
                                                                        caseItem.caseStatus!
                                                                            .displayName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-no-bg float-end text-gray"
                                                                        onClick={() => {
                                                                            this.addCaseAssociation(
                                                                                caseItem
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fal fa-check-square" />
                                                                        &nbsp;Select
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <span>No results found!</span>
                                    )
                                ) : null}
                            </div>
                            <div className="dialog-btn-div">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            showAddCasesOptionsDialog: false,
                                            caseSearchResults: undefined,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
                <CheckmateDialog
                    isShowingModal={this.state.showConfirmDeleteDialog || false}
                    body="Are you sure you want to delete this item? This operation is permanent and cannot be reverted back."
                    handleClose={() =>
                        this.setState({
                            showConfirmDeleteDialog: false,
                            taskIndexToDelete: undefined,
                        })
                    }
                    handleConfirm={this.deleteTask}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
