import { ApiRoutes } from '../../../../utilities/ApiRoutes';
import { ExportFileButton } from '../../../../components/shared/ExportFileButton';
import { ITimelineReportInputModel } from '../../../../interfaces/Report/ITimelineReport';
import { MyDocumentsTypesEnum } from '../../../../utilities/Constants';
import { Stack } from 'react-bootstrap';

interface IWeeklyRecapCreateActionsProps {
    hideSaveAsButton: boolean;
    inputModel: ITimelineReportInputModel;
    onCommentsClick: () => void;
    onExportError?: () => void;
    onSaveAsClick: () => void;
    onSettingsClick: () => void;
}

export function WeeklyRecapCreateActions(props: IWeeklyRecapCreateActionsProps) {
    const { reportSectionDetails, startDate, endDate, entityGuid, saveReport, ...options } =
        props.inputModel;

    return (
        <Stack direction="horizontal" style={{ flexWrap: 'wrap' }}>
            <button className="btn btn-no-bg float-end text-gray" onClick={props.onSettingsClick}>
                <i className="fal fa-user-cog" />
                &nbsp;Settings
            </button>
            <button className="btn btn-no-bg float-end text-gray" onClick={props.onCommentsClick}>
                <i className="fal fa-comment-lines" />
                &nbsp;Comments
            </button>
            <ExportFileButton
                url={'/' + ApiRoutes.TimelineRecapExport}
                reportType={{
                    id: MyDocumentsTypesEnum.WeeklyRecap.Value,
                }}
                reportParameters={{
                    reportType: {
                        id: MyDocumentsTypesEnum.WeeklyRecap.Value,
                    },
                    options,
                    reportSectionDetails,
                    date: {
                        startDate,
                        endDate,
                    },
                    entityGuid,
                    saveReport,
                }}
                onError={props.onExportError}
                icon="fa-file-pdf"
            />
            {!props.hideSaveAsButton && (
                <button className="btn btn-no-bg float-end text-gray" onClick={props.onSaveAsClick}>
                    <i className="fal fa-pen" />
                    &nbsp;Save As
                </button>
            )}
        </Stack>
    );
}
