import ApiClient from '../../services/apiClient';
import { IUserModel } from '../../interfaces/IUser';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const queryKey = ['get-user-unread-query-exports'];

export const useGetUserUnreadQueryExports = (user: IUserModel) => {
    return useQuery({
        queryKey,
        queryFn: async () => {
            const response = await _apiClient.getUserUnreadQueryExports();

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve unread query exports.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        enabled: user.active,
    });
};
