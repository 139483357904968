import * as React from 'react';
import { Modal } from 'react-bootstrap';
import ValidateUtils from '../../shared/validations';
import { IOrganizationRoleModel } from '../../interfaces/IUser';
import { IOrganizationBaseModel, IOrganizationModel } from '../../interfaces/IOrganization';
import { IDataScopeLookupModel, ILookupModel } from '../../interfaces/ILookup';
import { IValidation } from '../../interfaces/IError';

import ApiClient from '../../services/apiClient';
import { DataScopesEnum, EmptyGuid, OrganizationTypesEnum } from '../../utilities/Constants';
import CheckmateSelect from '../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
let _apiClient = new ApiClient();

interface IOrganizationRoleProps {
    open: boolean;
    handleCancel: () => void;
    handleSave: (organizationRole: IOrganizationRoleModel) => void;
    zones?: IOrganizationModel[];
    homeOrganization: IOrganizationBaseModel;
    currentOrganizationRole?: IOrganizationRoleModel;
}

interface IOrganizationRoleState {
    currentOrganizationRole?: IOrganizationRoleModel;
    lookupData: IDataScopeLookupModel[];
    validation: IValidation;
}

export class OrganizationRole extends React.Component<
    IOrganizationRoleProps,
    IOrganizationRoleState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            currentOrganizationRole: {},
            lookupData: [],
            validation: {},
        };
    }

    componentDidMount() {
        this.loadLookupData();
    }

    componentDidUpdate(prevProps: IOrganizationRoleProps) {
        if (prevProps.currentOrganizationRole != this.props.currentOrganizationRole) {
            this.setState({
                currentOrganizationRole: this.props.currentOrganizationRole,
            });
        }
    }

    loadLookupData = async () => {
        var response = await _apiClient.getDataScopes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        if (response.payload) {
            this.setState({ lookupData: response.payload });
        }
    };

    handleZoneSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value) {
            if (this.props.zones) {
                const organization = this.props.zones.find((x) => x.guid === e.target.value);
                if (organization) {
                    let newOrganizationRole: IOrganizationRoleModel = {
                        guid: organization.guid,
                        name: organization.name,
                        displayName: organization.displayName,
                        organizationType: organization.organizationType,
                        dataScope: this.getDefaultDataScopeForZone(organization),
                    };

                    let validation = this.state.validation;
                    validation.organization = [''];

                    this.setState({
                        currentOrganizationRole: newOrganizationRole,
                        validation: validation,
                    });
                }
            }
        } else this.setState({ currentOrganizationRole: undefined });
    };

    getDefaultDataScopeForZone = (organization: IOrganizationModel) => {
        if (organization.dependentOrganizations) {
            const match = organization.dependentOrganizations.find(
                (x) => x.guid === this.props.homeOrganization.guid
            );
            let dataScopeId = 0;
            if (match && match.organizationType) {
                switch (match.organizationType.id) {
                    case OrganizationTypesEnum.Firm.Value:
                        dataScopeId =
                            match.priority === 1
                                ? DataScopesEnum.National.Value
                                : DataScopesEnum.LocalBasic.Value;
                        break;
                    case OrganizationTypesEnum.Company.Value:
                        dataScopeId = DataScopesEnum.Company.Value;
                        break;
                    case OrganizationTypesEnum.Insurer.Value:
                        dataScopeId = DataScopesEnum.Insurer.Value;
                        break;
                    default:
                }
            }

            return this.state.lookupData.find((x) => x.id === dataScopeId);
        }
    };

    handleDone = () => {
        // Validation
        let valid = true;
        let validation: IValidation = this.state.validation;
        if (
            !this.state.currentOrganizationRole ||
            !this.state.currentOrganizationRole.guid ||
            this.state.currentOrganizationRole.guid == ''
        ) {
            valid = false;
            validation.organization = ['Zone is required. '];
        } else {
            if (!this.state.currentOrganizationRole.dataScope) {
                valid = false;
                validation.dataScope = ['Data Scope is required'];
            } else if (!this.state.currentOrganizationRole.role) {
                valid = false;
                validation.role = ['Role is required'];
            }
        }

        this.setState({ validation: validation });

        if (valid) {
            this.props.handleSave(this.state.currentOrganizationRole!);
        }
    };

    getRolesBySelectedDataScope = () => {
        if (
            this.state.currentOrganizationRole &&
            this.state.currentOrganizationRole.dataScope &&
            this.state.currentOrganizationRole.dataScope.id > 0
        ) {
            var temp = this.state.lookupData.filter(
                (x) => x.id === this.state.currentOrganizationRole!.dataScope!.id
            );
            if (temp.length > 0) return temp[0].validRoles;
        }

        return [];
    };

    handleCancel = () => {
        this.props.handleCancel();
    };

    handleCheckmateSelectChange = (selectedItem: any, name: string) => {
        let organizationRole = this.state.currentOrganizationRole;
        let validation = this.state.validation;
        switch (name) {
            case 'zone':
                if (selectedItem && selectedItem.guid && selectedItem.guid != EmptyGuid) {
                    if (this.props.zones) {
                        const organization = this.props.zones.find(
                            (x) => x.guid === selectedItem.guid
                        );
                        if (organization) {
                            organizationRole = {
                                guid: organization.guid,
                                name: organization.name,
                                displayName: organization.displayName,
                                organizationType: organization.organizationType,
                                dataScope: this.getDefaultDataScopeForZone(organization),
                            };

                            let validation = this.state.validation;
                            validation.organization = [''];
                        }
                    }
                } else this.setState({ currentOrganizationRole: undefined });
                break;
            case 'dataScope':
                if (organizationRole) {
                    const value = selectedItem ? selectedItem.id : 0;
                    organizationRole.dataScope = value
                        ? JSON.parse(
                              JSON.stringify(this.state.lookupData.find((x) => x.id === value))
                          )
                        : undefined;
                    if (value) validation['dataScope'] = [''];
                }
                break;
            case 'role':
                if (organizationRole) {
                    const value = selectedItem ? selectedItem.id : 0;
                    if (value) {
                        validation['role'] = [''];
                        if (organizationRole.dataScope) {
                            const dataScopeId = organizationRole.dataScope.id;
                            organizationRole.role = JSON.parse(
                                JSON.stringify(
                                    this.state.lookupData
                                        .find((x) => x.id === dataScopeId)!
                                        .validRoles.find((x) => x.id === parseInt(value))
                                )
                            );
                            break;
                        }
                    }
                    organizationRole.role = undefined;
                }
                break;
            default:
                break;
        }

        this.setState({
            currentOrganizationRole: organizationRole,
            validation: validation,
        });
    };

    render() {
        return (
            <div>
                <Modal
                    centered
                    size="lg"
                    show={this.props.open}
                    onHide={this.props.handleCancel}
                    backdrop={false}
                >
                    <Modal.Title>
                        <span className="ms-3">
                            {this.state.currentOrganizationRole &&
                            this.state.currentOrganizationRole.displayName
                                ? this.state.currentOrganizationRole.displayName
                                : 'Zone Access'}
                        </span>
                    </Modal.Title>
                    <Modal.Body>
                        {this.props.zones ? (
                            <div className="row mb-2">
                                <label className="control-label col-sm-2" htmlFor="zone">
                                    Zone{' '}
                                </label>
                                <div className="col-sm-6 ">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getOrgOptions(
                                            this.props.zones
                                        )}
                                        value={CheckmateSelectHelper.getSelectedOrgValue(
                                            this.props.zones,
                                            this.state.currentOrganizationRole
                                                ? this.state.currentOrganizationRole.guid || ''
                                                : ''
                                        )}
                                        name="zone"
                                        onChange={(e: any) => {
                                            this.handleCheckmateSelectChange(e, 'zone');
                                        }}
                                    />
                                </div>
                                <div className="col-sm-4 text-danger">
                                    {this.state.validation.organization}
                                </div>
                            </div>
                        ) : null}
                        {this.state.currentOrganizationRole ? (
                            <div className="row mb-2">
                                <label className="control-label col-sm-2" htmlFor="dataScope">
                                    Data Scope{' '}
                                </label>
                                <div className="col-sm-6">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getLookupOptions(
                                            this.state.lookupData
                                        )}
                                        value={CheckmateSelectHelper.getSelectedValueById(
                                            this.state.lookupData,
                                            this.state.currentOrganizationRole.dataScope
                                                ? this.state.currentOrganizationRole.dataScope.id ||
                                                      0
                                                : 0
                                        )}
                                        name="dataScope"
                                        onChange={(e: any) => {
                                            this.handleCheckmateSelectChange(e, 'dataScope');
                                        }}
                                    />
                                </div>
                                <div className="col-sm-4 text-danger">
                                    {this.state.validation.dataScope}
                                </div>
                            </div>
                        ) : null}
                        {this.state.currentOrganizationRole &&
                        this.state.currentOrganizationRole.dataScope &&
                        this.state.currentOrganizationRole.dataScope.id > 0 ? (
                            <div className="row mb-2">
                                <label className="control-label col-sm-2" htmlFor="role">
                                    Role{' '}
                                </label>
                                <div className="col-sm-6">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getLookupOptions(
                                            this.getRolesBySelectedDataScope()
                                        )}
                                        value={CheckmateSelectHelper.getSelectedValueById(
                                            this.getRolesBySelectedDataScope(),
                                            this.state.currentOrganizationRole &&
                                                this.state.currentOrganizationRole.role
                                                ? this.state.currentOrganizationRole.role!.id || 0
                                                : 0
                                        )}
                                        name="role"
                                        onChange={(e: any) => {
                                            this.handleCheckmateSelectChange(e, 'role');
                                        }}
                                    />
                                </div>
                                <div className="col-sm-4 text-danger">
                                    {this.state.validation.role}
                                </div>
                            </div>
                        ) : null}
                        <div>
                            <input
                                type="button"
                                onClick={this.handleCancel}
                                className="btn btn-default float-end"
                                value="Cancel"
                            />
                            <input
                                type="button"
                                className="btn btn-orange float-end"
                                onClick={this.handleDone}
                                value="Done"
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
