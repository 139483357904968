import ApiClient from '../../services/apiClient';
import { IViewDocumentModel } from '../../interfaces/IDocument';
import { useLoading } from '../../contexts/LoadingContext';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const useMyDocumentDeleteMutation = (onError: (errorMessage: string) => void) => {
    const loading = useLoading();

    return useMutation({
        mutationFn: async (document: IViewDocumentModel) => {
            loading.showLoading();

            const response = await _apiClient.deleteMyDocument(document.guid!);

            if (!response?.httpResponse.ok) {
                throw new Error('Failed to delete Document');
            }

            return response;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
        onSettled: () => loading.hideLoading(),
    });
};
