import { useEffect, useMemo, useState } from 'react';

import Stack from 'react-bootstrap/Stack';

interface IDateTimeDisplayProps {
    fontColor?: string;
    fontSize?: string;
}

const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
};

const timeOptions: Intl.DateTimeFormatOptions = {
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
};

export function DateTimeDisplay({ fontColor, fontSize }: IDateTimeDisplayProps) {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setDate(new Date()), 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const dateTimeDisplayStyles = useMemo(
        () => ({
            color: fontColor || '#000000',
            fontSize: fontSize || '1.1em',
            textAlign: 'right' as const,
        }),
        [fontColor, fontSize]
    );

    return (
        <Stack direction="horizontal" gap={1} style={dateTimeDisplayStyles}>
            <div>{date.toLocaleDateString('en-US', dateOptions)}</div>
            <div>{date.toLocaleTimeString('en-US', timeOptions).toLowerCase()}</div>
        </Stack>
    );
}
