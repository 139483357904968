import { IIconButtonprops, IconButton } from './IconButton';

export interface ILoadingButtonProps extends IIconButtonprops {
    icon?: string;
    loading?: boolean;
    loadingText?: string;
}

export function LoadingButton(props: ILoadingButtonProps) {
    return (
        <IconButton
            {...props}
            text={props.loading ? props.loadingText : props.text}
            icon={props.loading ? 'fal fa-spinner fa-solid fa-spin' : props.icon}
        />
    );
}
