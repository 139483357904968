import {
    CaseExpertActivityStatusType,
    CaseExpertActivityType,
} from '../../../../utilities/Constants';
import Common, { EMPTY_VALUE } from '../../../../stores/Common';
import { ILookupModel, IMultiSelectOptions } from '../../../../interfaces/ILookup';

import CheckmateSelect from '../../../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../../../utilities/CheckmateSelectHelper';
import { IViewCaseExpertActivityItemModel } from '../../../../interfaces/ICaseExpert';

const activityStatusTypeLookups = Object.values(CaseExpertActivityStatusType).map(
    (type) => ({ id: type.id, displayName: type.label } as ILookupModel)
);

const activityStatusTypeOptions = activityStatusTypeLookups.map(
    (type) => ({ id: type.id, label: type.displayName } as IMultiSelectOptions)
);

export interface ICaseExpertActivitiesProps {
    activities: IViewCaseExpertActivityItemModel[];
    isEditMode: boolean;
    onActivityValueChange: (activity: IViewCaseExpertActivityItemModel) => void;
}

export function CaseExpertActivities(props: ICaseExpertActivitiesProps) {
    return (
        <>
            {(props.activities.length ?? 0) > 0 ? (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Key Dates</th>
                            <th>Expert Deadline Status</th>
                            <th>Expert Deadline Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.activities.map((activity) => {
                            const selectedStatusTypeValue =
                                CheckmateSelectHelper.getSelectedValueById(
                                    activityStatusTypeLookups,
                                    activity.statusId
                                );

                            const formatted = activity.deadline
                                ? Common.dateFormat(activity.deadline)
                                : EMPTY_VALUE;

                            return (
                                <tr key={activity.typeId}>
                                    <td>
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <strong>{getTypeByTypeId(activity.typeId)}</strong>{' '}
                                            {formatted}
                                        </div>
                                    </td>
                                    {props.isEditMode ? (
                                        <>
                                            <td>
                                                <div style={{ maxWidth: '150px' }}>
                                                    <CheckmateSelect
                                                        onChange={(selection) => {
                                                            props.onActivityValueChange({
                                                                ...activity,
                                                                statusId: selection?.id,
                                                            });
                                                        }}
                                                        name={`activity_status_${activity.guid}`}
                                                        options={activityStatusTypeOptions}
                                                        value={selectedStatusTypeValue}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <input
                                                    type="date"
                                                    name={`activity_date_completed_${activity.guid}`}
                                                    className={
                                                        'form-control' +
                                                        (activity?.dateCompleted &&
                                                        Common.isValidDate(activity?.dateCompleted)
                                                            ? ''
                                                            : ' unselectClass')
                                                    }
                                                    value={
                                                        Common.dateFormat(
                                                            activity?.dateCompleted
                                                        ) ?? ''
                                                    }
                                                    onChange={(e) => {
                                                        props.onActivityValueChange({
                                                            ...activity,
                                                            dateCompleted: e.target.value,
                                                        });
                                                    }}
                                                    style={{ width: '100%' }}
                                                />
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td>{selectedStatusTypeValue?.label}</td>
                                            <td>{Common.dateFormat(activity.dateCompleted)}</td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <span>No Activity found!</span>
            )}
        </>
    );
}

const getTypeByTypeId = (typeId: number) => {
    switch (typeId) {
        case CaseExpertActivityType.disclosure.id:
            return 'Disclosure';
        case CaseExpertActivityType.report.id:
            return 'Report';
        case CaseExpertActivityType.tender.id:
            return 'Tender';
        case CaseExpertActivityType.deposition.id:
            return 'Deposition';
    }
};
