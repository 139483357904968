import { IApiRequest } from './interfaces/IApiRequest';
import { IApiRequestOptions } from './interfaces/IApiRequestOptions';
import { IApiResponse } from './interfaces/IApiResponse';
import { IRequestProcessor } from './interfaces/IRequestProcessor';
import _BaseRequestProcessor from './requestProcessors/baseRequestProcessor';
import _JsonRequestProcessor from './requestProcessors/jsonRequestProcessor';

export var BaseRequestProcessor = _BaseRequestProcessor;
export var JsonRequestProcessor = _JsonRequestProcessor;

export  type { IApiRequestOptions } from './interfaces/IApiRequestOptions';
export  type { IApiResponse } from './interfaces/IApiResponse';
export  type { IApiRequest } from './interfaces/IApiRequest';
export  type { IRequestProcessor } from './interfaces/IRequestProcessor';

export class ApiClient implements IRequestProcessor {

    _baseUri: string;
    _jsonProcessor: _JsonRequestProcessor;

    /**
     * Constructs a new API client.
     * @param {string} baseUri
     */
     constructor(baseUri: string) {
        if (baseUri && (baseUri.length === 0 || baseUri[baseUri.length-1] !== "/")) {
            baseUri += "/";
        }
        if (baseUri.length > 8) {
            baseUri = baseUri.substring(0,8) + baseUri.substring(8).replace("//", "/");
        }

        this._baseUri = baseUri;
        this._jsonProcessor = new _JsonRequestProcessor(this);
    }

    /**
     * Create an absolute URI from a relative URI.
     * @param {string} uri The relative URI that will be made absolute.
     * @param {ApiRequestOptions} options A collection of options for the request.
     * @returns {string} The absolute URI.
     */
     makeUri(url: string, options: IApiRequestOptions<any>): string {
        // TODO: Use a library to make this more robust.
        // TODO: The npm package `urijs` works, but the typescript definitions are broken.

        if (url.startsWith("http")) {
            url = url.substring(0, 8) + url.substring(8).replace("//", "/");
        } else {
            url = url.replace("//", "/");
        }

        if (!url.toLowerCase().startsWith("http://") && !url.toLowerCase().startsWith("https://")) {
            url = this._baseUri + url;
        }

        if (options.queryParams) {
            var queryParams: {[key: string]: string} = options.queryParams;
            url = url + "?" + Object.getOwnPropertyNames(queryParams).map(x => `${encodeURIComponent(x)}=${encodeURIComponent(queryParams[x])}`).join("&");
        }

        return url;
    }
    
    /**
     * Perform a GET request.
     * @param {string} uri The URI for the request.
     * @param {ApiRequestOptions} options A collection of options for the request.
     * @returns {object} The parsed JSON result.
     */
    get<T>(uri: string, options: IApiRequestOptions<any> = {}): Promise<IApiResponse<T>> {
        return this.performRequest("GET", uri, options);
    }

    /**
     * Perform a PUT request.
     * @param {string} uri The URI for the request.
     * @param {ApiRequestOptions} options A collection of options for the request.
     * @returns {object} The parsed JSON result.
     */
    put<T>(uri: string, options: IApiRequestOptions<any> = {}): Promise<IApiResponse<T>> {
        return this.performRequest("PUT", uri, options);
    }

    /**
     * Perform a POST request.
     * @param {string} uri The URI for the request.
     * @param {ApiRequestOptions} options A collection of options for the request.
     * @returns {object} The parsed JSON result.
     */
    post<T>(uri: string, options: IApiRequestOptions<any> = {}): Promise<IApiResponse<T>> {
        return this.performRequest("POST", uri, options);
    }

    /**
     * Perform a PATCH request.
     * @param {string} uri The URI for the request.
     * @param {ApiRequestOptions} options A collection of options for the request.
     * @returns {object} The parsed JSON result.
     */
    patch<T>(uri: string, options: IApiRequestOptions<any> = {}): Promise<IApiResponse<T>> {
        return this.performRequest("PATCH", uri, options);
    }

    /**
     * Perform a DELETE request.
     * @param {string} uri The URI for the request.
     * @param {ApiRequestOptions} options A collection of options for the request.
     * @returns {object} The parsed JSON result.
     */
    delete<T>(uri: string, options: IApiRequestOptions<any> = {}): Promise<IApiResponse<T>> {
        return this.performRequest("DELETE", uri, options);
    }

    /**
     * Perform a OPTIONS request.
     * @param {string} uri The URI for the request.
     * @param {ApiRequestOptions} options A collection of options for the request.
     * @returns {object} The parsed JSON result.
     */
    options<T>(uri: string, options: IApiRequestOptions<any> = {}): Promise<IApiResponse<T>> {
        return this.performRequest("OPTIONS", uri, options);
    }

    /**
     * Creates a request.
     * @param {string} method The method for the request.
     * @param {string} uri The URI for the request.
     * @param {ApiRequestOptions} options A collection of options for the request.
     * @returns {Promise<ApiRequest>} The prepared request object.
     */
    createRequest<T>(method: string, uri: string, options: IApiRequestOptions<T> = {}): Promise<IApiRequest<T>> {
        var body = (options.body !== null) ? JSON.stringify(options.body) : null;
        uri = this.makeUri(uri, options);

        let request = new Request(uri.toString(), {
            method: method,
            body: body,
        });
        return Promise.resolve({
            httpRequest: request,
            options: options,
        });
    }

    /**
     * Prepares the request for transmission.
     * @param {ApiRequest} request The request object.
     * @returns {Promise<ApiRequest>} The prepared request object.
     */
    prepareRequest<T>(request: IApiRequest<T>): Promise<IApiRequest<T>> {
        return Promise.resolve(request);
    }

    /**
     * Processes a response after completion.
     * @param {ApiRequest} request The request object.
     * @param {ApiResponse} response The response object.
     * @returns {Promise<ApiResponse>} The processed response object.
     */
    processResponse<T>(request: IApiRequest<any>, response: IApiResponse<T>): Promise<IApiResponse<T>> {
        return Promise.resolve(response);
    }

    /**
     * Performs a request and processes the response.
     * @param {string} method The method used to send the request.
     * @param {string} uri The URI used to send the request.
     * @param {ApiRequestOptions} options A collection of options for the request.
     * @returns {Promise<ApiResponse>} The processed response object.
     */
    async performRequest<T>(method: string, uri: string, options: IApiRequestOptions<any> = {}): Promise<IApiResponse<T>> {
        let processor = options.processor || this._jsonProcessor;

        let request = await this.createRequest(method, uri, options);

        request = await processor.prepareRequest(request);

        let httpResponse = await fetch(request.httpRequest, {
            credentials: 'include',
        });

        let response: IApiResponse<T> = {
            httpResponse: httpResponse,
            payload: undefined,
            errors: [],
        };

        response = await processor.processResponse(request, response);

        return response;
    }
}