import * as React from 'react';

import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { useNavigate } from 'react-router-dom';

export interface IPublicLayoutProps {
    children: React.ReactNode;
}

export function PublicLayout(props: IPublicLayoutProps) {
    const navigate = useNavigate();

    const handleAppLogoClick = () => {
        navigate(LocalRoutes.Root);
    };

    return (
        <div className="container-fluid" style={{ background: 'white' }}>
            <div className="row">
                <div
                    className="col-sm-2 print-hide-display"
                    style={{ paddingLeft: '15px', paddingRight: '15px' }}
                >
                    <div className="logo-container">
                        <img
                            className="logo-img rounded"
                            src="/images/CheckmateLogoFromBrian.svg"
                            onClick={handleAppLogoClick}
                            alt="Checkmate Logo"
                        />
                    </div>
                </div>
                <div className="col-sm-10 nav-menu-item-container">
                    <div
                        className="dropdown-container print-hide-display"
                        style={{
                            padding: '0',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="float-end" style={{ margin: '38px' }}></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-2" style={{ padding: '0px', background: 'black' }}></div>
                <div className="col-sm-10 main-display" style={{ paddingLeft: '15px' }}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}
