import {
    IQueryFilterCriteria,
    IQueryFilterCriteriaSelectionValue,
} from '../../../../interfaces/IQuery';

import Common from '../../../../stores/Common';
import { ConditionSelectorEnums } from '../../../../utilities/Constants';
import { ICaseStatusLookupModel } from '../../../../interfaces/ICase';

export function SelectedValuesSummary({ item }: { item: IQueryFilterCriteria }) {
    let fieldSummary = '';
    if (item?.condition !== ConditionSelectorEnums.AllChanges) {
        const fieldSummaries = item?.selectedValues?.map((selectedValue) => selectedValue.label);

        if (fieldSummaries?.length === 0) {
            fieldSummary += 'Not Set';
        } else {
            fieldSummary += fieldSummaries?.join(' | ');
        }
    } else {
        fieldSummary += 'All Changes';
    }

    return <>{fieldSummary}</>;
}

export function DateRangeSummary({ item }: { item: IQueryFilterCriteria }) {
    let fieldSummary = '';

    if (item.condition === ConditionSelectorEnums.Range) {
        if ((item.dateRangeInterval ?? 0) < 0) {
            fieldSummary = `Past ${Math.abs(item.dateRangeInterval ?? 0)} days`;
        } else {
            fieldSummary = `Next ${item.dateRangeInterval} days`;
        }
        return <>{fieldSummary}</>;
    }

    if (item?.condition === ConditionSelectorEnums.AllChanges) {
        fieldSummary += 'All Changes';
    } else {
        if (!item?.startDate && !item?.endDate) {
            fieldSummary += 'Not Set';
        } else if (item?.startDate && !item?.endDate)
            fieldSummary += `After ${Common.dateFormat(item.startDate)}`;
        else if (!item?.startDate && item?.endDate)
            fieldSummary += `Before ${Common.dateFormat(item.endDate)}`;
        else if (item?.startDate && item?.endDate)
            fieldSummary += `Between ${Common.dateFormat(item.startDate)} and ${Common.dateFormat(
                item.endDate
            )}`;
    }

    return <>{fieldSummary}</>;
}

export function BooleanSummary({ item }: { item: IQueryFilterCriteria }) {
    const fieldSummary =
        item?.condition !== ConditionSelectorEnums.AllChanges
            ? item.booleanValue
                ? 'Yes'
                : 'No'
            : 'All Changes';
    return <>{fieldSummary}</>;
}

export function TextSummary({ item }: { item: IQueryFilterCriteria }) {
    const text = item.text ?? 'Not Set';
    const fieldSummary =
        item?.condition !== ConditionSelectorEnums.AllChanges ? text : 'All Changes';
    return <>{fieldSummary}</>;
}

export function CaseStatusSummary({
    item,
    findStatus,
}: {
    item: IQueryFilterCriteria;
    findStatus: (value: IQueryFilterCriteriaSelectionValue) => ICaseStatusLookupModel | undefined;
}) {
    let fieldSummary = '';
    if (item?.condition !== ConditionSelectorEnums.AllChanges) {
        const fieldSummaries = item?.selectedValues?.map((selectedValue) => {
            const status = findStatus(selectedValue);
            return status?.name;
        });
        if ((fieldSummaries?.length ?? 0) === 0) {
            fieldSummary += 'Not Set';
        } else {
            fieldSummary += fieldSummaries?.filter((summary) => summary).join(' | ');
        }
    } else {
        fieldSummary += 'All Changes';
    }

    return <>{fieldSummary}</>;
}

export function CurrencySummary({ item }: { item: IQueryFilterCriteria }) {
    let fieldSummary = '';
    if (item?.condition !== ConditionSelectorEnums.AllChanges) {
        if (!item?.numericValueFrom && !item?.numericValueTo) {
            fieldSummary += 'Not Set';
        } else if (!!item.numericValueFrom && !!item.numericValueTo) {
            fieldSummary += `Between ${Common.formatCurrency(
                item.numericValueFrom?.toString()
            )} and ${Common.formatCurrency(item.numericValueTo?.toString())}`;
        } else if (item.numericValueFrom) {
            fieldSummary += `Greater than ${Common.formatCurrency(
                item.numericValueFrom?.toString()
            )}`;
        } else {
            fieldSummary += `Less than ${Common.formatCurrency(item.numericValueTo?.toString())}`;
        }
    } else {
        fieldSummary += 'All Changes';
    }

    return <>{fieldSummary}</>;
}

export function NumericSummary({ item }: { item: IQueryFilterCriteria }) {
    let fieldSummary = '';
    if (item?.condition !== ConditionSelectorEnums.AllChanges) {
        if (!item?.numericValueFrom && !item?.numericValueTo) {
            fieldSummary += 'Not Set';
        } else if (item.numericValueFrom && item.numericValueTo) {
            fieldSummary += `Between ${item.numericValueFrom} and ${item.numericValueTo}`;
        } else if (item.numericValueFrom) {
            fieldSummary += 'Greater than ' + item.numericValueFrom;
        } else {
            fieldSummary += `Less than ${item.numericValueTo}`;
        }
    } else {
        fieldSummary += 'All Changes';
    }
    return <>{fieldSummary}</>;
}
