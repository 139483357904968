import ApiClient from '../../services/apiClient';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { LookupDataEnums } from '../../utilities/Constants';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useDocumentTypesLookup = () => {
    return useQuery({
        queryKey: ['get-document-types-lookup'],
        queryFn: async () => {
            const response = await _apiClient.getLookupData(LookupDataEnums.DocumentTypes);

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve expert document types.');
            }

            const sorted =
                response.payload?.sort((a, b) =>
                    (a.displayName ?? '') < (b.displayName ?? '') ? -1 : 1
                ) ?? [];

            return CheckmateSelectHelper.getLookupOptions(sorted, false);
        },
    });
};
