import {
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
    ConditionSelectorEnums,
} from '../../../../utilities/Constants';

import CheckmateSelect from '../../../shared/CheckmateSelect';
import { DateRangePicker } from '../../../../pages/query2/DateRangePicker';
import { IMultiSelectOptions } from '../../../../interfaces/ILookup';
import { IQueryFilterCriteria } from '../../../../interfaces/IQuery';
import { IValueControlsProps } from './ValueControls';
import { NumericRangeControl } from '../../../../shared/controls/NumericRangeControl';
import { YesNoSelector } from '../../../shared/criteria-value-renderers/YesNoSelector';
import { useAssertionDescriptorsLookup } from '../../../../shared/react-query-hooks/useAssertionDescriptorsLookup';

interface IAssertionDescriptorValueControlsProps extends IValueControlsProps {
    disabled: boolean;
}

export function AssertionDescriptorValueControls(props: IAssertionDescriptorValueControlsProps) {
    const { data: assertionDescriptors } = useAssertionDescriptorsLookup(
        AssertionDescriptorUsageTypesEnum.Case
    );
    const { disabled, item } = props;

    const found = assertionDescriptors?.find(
        (ad) =>
            item.assertionDescriptorGuid !== undefined &&
            ad.guid !== undefined &&
            ad.guid.toUpperCase() === item.assertionDescriptorGuid.toUpperCase()
    );

    if (!found) {
        return <div>Unable to locate Assertion Descriptor</div>;
    }

    const content = [];
    switch (found.valueSourceType!.id) {
        case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
            if ((found?.assertionDescriptorValues?.length ?? 0) > 0) {
                const assertionDescriptorValuesAll =
                    found?.assertionDescriptorValues?.map((ad) => ({
                        label: ad.text!,
                        value: ad.guid!,
                        guid: ad.guid!,
                    })) ?? [];

                return (
                    <>
                        <CheckmateSelect
                            isMulti={true}
                            options={assertionDescriptorValuesAll}
                            value={item.selectedValues}
                            onChange={(options) => {
                                props.onSelectorChange(item, options);
                            }}
                            isDisabled={disabled}
                        />
                        <span className="text-danger">{item.validationError}</span>
                    </>
                );
            }
            break;
        case AssertionDescriptorValueSourceTypeEnum.Boolean.Value: {
            return (
                <div style={{ width: '135px' }}>
                    <YesNoSelector
                        disabled={disabled}
                        multi={false}
                        onChange={(optionalBool) => {
                            props.onYesNoChange(item, optionalBool);
                        }}
                        value={item.booleanValue}
                        validationError={item.validationError}
                    />
                </div>
            );
        }
        case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
            const valueDisplayTypeId = found.valueDisplayType ? found.valueDisplayType.id : 0;

            switch (valueDisplayTypeId) {
                case AssertionDescriptorValueDisplayTypes.Currency.Value:
                case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                case AssertionDescriptorValueDisplayTypes.Number.Value:
                case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                    return (
                        <NumericRangeControl
                            disableFrom={disabled}
                            disableTo={disabled}
                            isCurrency={
                                valueDisplayTypeId ==
                                AssertionDescriptorValueDisplayTypes.Currency.Value
                            }
                            item={item}
                            onFromChange={(e) => {
                                const value = e.target.value ? parseInt(e.target.value) : undefined;
                                props.onNumericRangeChange(item, 'from', value);
                            }}
                            onToChange={(e) => {
                                const value = e.target.value ? parseInt(e.target.value) : undefined;
                                props.onNumericRangeChange(item, 'to', value);
                            }}
                            fromValue={item.numericValueFrom ?? ''}
                            toValue={item.numericValueTo ?? ''}
                            fromName="numericValueFrom"
                            toName="numericValueTo"
                        />
                    );
                case AssertionDescriptorValueDisplayTypes.DateTime.Value: {
                    if (item.condition === ConditionSelectorEnums.Range) {
                        const handleDateRangeIntervalChange = (
                            selectedItem: IMultiSelectOptions
                        ) => {
                            props.onRelativeDateChange(item, selectedItem?.id);
                        };

                        return (
                            <RelativeDateSelector
                                item={item}
                                value={item.dateRangeInterval}
                                onChange={handleDateRangeIntervalChange}
                            />
                        );
                    }

                    return (
                        <DateRangePicker
                            disableEndDate={disabled}
                            disableStartDate={disabled}
                            endDate={item.endDate}
                            startDate={item.startDate}
                            onEndDateChange={(e) => {
                                props.onDateRangeChange(item, 'endDate', e.target.value);
                            }}
                            onStartDateChange={(e) => {
                                props.onDateRangeChange(item, 'startDate', e.target.value);
                            }}
                            validationError={item.validationError}
                            endInputFieldName="endDate"
                            startInputFieldName="startDate"
                        />
                    );
                }
                default:
                    return (
                        <span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter at least 3 characters"
                                disabled={disabled}
                                value={item.text ?? ''}
                                onChange={(e) => {
                                    props.onTextChange(item, e.target.value);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </span>
                    );
            }
            break;
        }
        default:
            content.push(<span className="text-danger">Unexpected Error!</span>);
            break;
    }

    return <div>AD Type Not Implemented</div>;
}

const relativeNumerals = [-30, -60, -90, -180, -360, 7, 30, 60, 90, 180, 360];
const relativeDateOptions = relativeNumerals.map((num) => {
    if (num < 0) {
        return {
            id: num,
            value: num.toString(),
            label: `Past ${Math.abs(num)} days`,
        } as IMultiSelectOptions;
    } else {
        return {
            id: num,
            value: num.toString(),
            label: `Next ${num} days`,
        } as IMultiSelectOptions;
    }
});

function RelativeDateSelector(props: {
    item: IQueryFilterCriteria;
    value: number | undefined;
    onChange: (selectedItem: IMultiSelectOptions) => void;
}) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
            <span style={{ maxWidth: 200, width: '100%', display: 'inline-block' }}>
                <CheckmateSelect
                    name="relative-date"
                    options={relativeDateOptions}
                    onChange={props.onChange}
                    value={relativeDateOptions.find(
                        (opt) => opt.value === props.item.dateRangeInterval?.toString() ?? ''
                    )}
                />
            </span>
            <span className="text-danger">{props.item.validationError}</span>
        </div>
    );
}
