import { useParams } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { PackageSettlementDetailExport } from '../report/PackageSettlementDetailExport';

function PackageSettlementDetailExportWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return <PackageSettlementDetailExport guid={guid || ''} user={props.user} />;
}

export default PackageSettlementDetailExportWrapper;
