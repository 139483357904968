import React, { ReactNode, createContext, useContext, useState } from 'react';

export interface LoadingContextProps {
    isLoading: boolean;
    showLoading: () => void;
    hideLoading: () => void;
    reactQueryLoadingHandlers: {
        onRequest: () => void;
        onSettled: () => void;
    };
}

const LoadingContext = createContext<LoadingContextProps | undefined>(undefined);

interface LoadingProviderProps {
    children: ReactNode;
}

const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
    const [loadingCount, setLoadingCount] = useState(0);

    const showLoading = () => {
        setLoadingCount((count) => count + 1);
    };

    const hideLoading = () => {
        setLoadingCount((count) => Math.max(0, count - 1));
    };

    const isLoading = loadingCount > 0;

    return (
        <LoadingContext.Provider
            value={{
                isLoading,
                showLoading,
                hideLoading,
                reactQueryLoadingHandlers: {
                    onRequest: () => showLoading(),
                    onSettled: () => hideLoading(),
                },
            }}
        >
            {children}
        </LoadingContext.Provider>
    );
};

const useLoading = (): LoadingContextProps => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    return context;
};

export { LoadingProvider, useLoading };
