import { AssertionDescriptorFieldRenderer } from './AssertionDescriptorFieldRenderer';
import { IDisplayFieldModel } from '../../interfaces/IDisplaySectionModel';
import { StaticFieldRenderer } from './StaticFieldRenderer';

interface IFieldRendererProps {
    displayAsCondensed: boolean;
    field: IDisplayFieldModel;
    tabIndex?: number;
}

export function FieldRenderer(props: IFieldRendererProps) {
    const isEmptyField = !props.field.assertionDescriptorGuid && !props.field.staticItemGuid;
    return (
        <>
            {props.field.assertionDescriptorGuid && <AssertionDescriptorFieldRenderer {...props} />}
            {props.field.staticItemGuid && <StaticFieldRenderer {...props} />}
            {isEmptyField && <>&nbsp;</>}
        </>
    );
}
