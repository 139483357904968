import * as React from 'react';

import { Stack } from 'react-bootstrap';

export interface IPageWithAffixedHeaderProps {
    children: React.ReactNode;
    headerComponent: React.ReactNode;
}

export function PageWithAffixedHeader(props: IPageWithAffixedHeaderProps) {
    return (
        <Stack
            direction="vertical"
            style={{
                height: 'calc(100%)',
                marginRight: '-12px',
                overflow: 'hidden',
            }}
        >
            <div className="row pb-3 pr-3">{props.headerComponent}</div>
            <div style={{ overflow: 'auto', paddingRight: '12px' }}>
                {props.children}
            </div>
        </Stack>
    );
}
