import React from 'react';
import classNames from 'classnames';

interface IWholeNumberInputProps {
    allowNegativeValues?: boolean;
    className?: string;
    disabled?: boolean;
    placeholder?: string;
    name?: string;
    style?: object;
    value: string | number | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export function WholeNumberInput(props: IWholeNumberInputProps) {
    const allStyles = { ...{ width: '25%', maxWidth: '170px' }, ...props.style };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const regex = props.allowNegativeValues ? /^-?\d+$/ : /^[\d]*$/;

        const isNonDecimalNumeric = regex.test(inputValue) || inputValue === '';
        if (isNonDecimalNumeric) {
            props.onChange(e);
        }
    };

    return (
        <input
            className={classNames('form-control d-inline-block ms-2 ', props.className)}
            disabled={props.disabled}
            name={props.name}
            onChange={handleChange}
            placeholder={props.placeholder}
            style={allStyles}
            value={props.value}
        />
    );
}
