import * as React from 'react';

import { IValidation } from '../../interfaces/IError';
import ValidateUtils from '../../shared/validations';

import { IOrganizationModel } from '../../interfaces/IOrganization';

import ApiClient from '../../services/apiClient';
import { Modal } from 'react-bootstrap';
import { OrganizationList } from './OrganizationList';
import { BasicSearch } from '../shared/BasicSearch';

let _apiClient = new ApiClient();

interface IOrganizationAddUpdateModalProps {
    displayDependencyDialog: boolean;
    handleDependencyDialogClose: () => void;
    currentDependentOrganizationTypeName: string;
    addDependentOrganization: boolean;
    addingUpdatingNewDependentOrganization: boolean;
    newDependentOrganizationName?: string;
    validation: IValidation;
    changeValue: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    addOrganizationDependency: (
        event: React.FormEvent<HTMLButtonElement>,
        organization: IOrganizationModel
    ) => void;
    currentDependentOrganizationTypeId: number;
    addingNewDependentOrganization: () => void;
    updateNewlyAddedOrganizationDependency: () => void;
    doneAddingNewDependentOrganization: () => void;
}

interface IOrganizationAddUpdateModalState {
    organizationSearchClicked: boolean;
    organizationSearchText: string;
    organizationList: IOrganizationModel[];
    validation: IValidation;
}

export class OrganizationAddUpdateModal extends React.Component<
    IOrganizationAddUpdateModalProps,
    IOrganizationAddUpdateModalState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            organizationSearchClicked: false,
            organizationSearchText: '',
            organizationList: [],
            validation: {},
        };
    }

    componentDidMount() {
        this.loadOrganizations();
    }

    loadOrganizations = async () => {
        try {
            var res = await _apiClient.getOrganizations();
            if (res.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (res.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                });
                return;
            }
            if (res.payload) {
                this.setState({ organizationList: res.payload });
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        this.props.changeValue(event);
    };

    searchOrganization = (event: React.FormEvent<HTMLButtonElement>, searchString: string) => {
        event.preventDefault();

        this.setState({
            organizationSearchClicked: true,
            organizationSearchText: searchString.toLowerCase(),
        });
    };

    getFilteredOrganizations(): IOrganizationModel[] {
        return this.state.organizationList
            ? this.state.organizationList.filter(
                  (org) =>
                      org.organizationType!.id == this.props.currentDependentOrganizationTypeId &&
                      org.name &&
                      org.name
                          .toLowerCase()
                          .indexOf(this.state.organizationSearchText.toLowerCase()) > -1
              )
            : [];
    }

    addOrganizationDependency = (
        event: React.FormEvent<HTMLButtonElement>,
        organization: IOrganizationModel
    ) => {
        this.props.addOrganizationDependency(event, organization);
        this.setState({
            organizationSearchClicked: false,
            organizationSearchText: '',
        });
    };

    handleDependencyDialogClose = () => {
        this.setState({ organizationSearchClicked: false });
        this.props.handleDependencyDialogClose();
    };

    addingUpdatingNewDependentOrganization = () => {
        this.setState({
            organizationSearchClicked: false,
            organizationSearchText: '',
        });
        this.props.addingNewDependentOrganization();
        /*if (this.props.addDependentOrganization) {
            this.props.addingNewDependentOrganization();
        }
        else {
            this.props.updateNewlyAddedOrganizationDependency();
        }*/
    };

    doneAddingNewDependentOrganization = () => {
        this.setState({
            organizationSearchClicked: false,
            organizationSearchText: '',
        });
        if (this.props.addDependentOrganization) {
            this.props.doneAddingNewDependentOrganization();
        } else {
            this.props.updateNewlyAddedOrganizationDependency();
        }
    };

    render() {
        return (
            <Modal
                centered
                show={this.props.displayDependencyDialog}
                size="lg"
                dialogClassName="add-company-dialog"
                onHide={this.handleDependencyDialogClose}
                backdrop={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        {this.props.addDependentOrganization ? 'Add' : 'Update'}{' '}
                        {this.props.currentDependentOrganizationTypeName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.addingUpdatingNewDependentOrganization ? (
                        <div className="row">
                            <label className="col-sm-2 control-label">Name*</label>
                            <div className="col-sm-10">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="newDependentOrganizationName"
                                    value={this.props.newDependentOrganizationName || ''}
                                    onChange={this.changeValue}
                                />
                                <span className="text-danger">
                                    {this.props.validation.newdependentorganizationname}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <BasicSearch
                            placeholderText="Search by organization Name"
                            onSearch={this.searchOrganization}
                            justifyLeft={true}
                        />
                    )}

                    <div>
                        <OrganizationList
                            organizationsList={
                                this.state.organizationSearchClicked
                                    ? this.getFilteredOrganizations()
                                    : []
                            }
                            onOrganizationSelection={this.addOrganizationDependency}
                        />
                    </div>

                    <div className="dialog-btn-div vertical-margin-lg">
                        <button
                            className="btn btn-default float-end"
                            onClick={this.props.handleDependencyDialogClose}
                        >
                            Cancel
                        </button>
                        {this.props.addingUpdatingNewDependentOrganization ? (
                            <button
                                className="btn btn-orange float-end"
                                onClick={this.doneAddingNewDependentOrganization}
                            >
                                {this.props.addDependentOrganization ? 'Add' : 'Update'}
                            </button>
                        ) : (
                            <button
                                className="btn btn-orange float-end"
                                onClick={this.addingUpdatingNewDependentOrganization}
                            >
                                Add New
                            </button>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
