import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetDefaultUserOrg = () => {
    return useQuery({
        queryKey: ['get-default-user-org'],
        queryFn: async () => {
            const response = await _apiClient.getDefaultUserOrganizationFromServer();

            if (!response) return;

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve default user org.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
    });
};
