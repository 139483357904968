import * as React from 'react';

import { ApplicationHelpType } from '../../utilities/Constants';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { Help } from '../../components/shared/Help';
import { IQueryFilterCriteria } from '../../interfaces/IQuery';
import { IValidation } from '../../interfaces/IError';

export interface IFilterCriteriaProps {
    isRequired?: boolean;
    onQueryCriteriaSelected: (optionsList: any) => void;
    queryFilterCriteriaAll: IQueryFilterCriteria[];
    queryFilterCriteriaSelected: IQueryFilterCriteria[];
    validation: IValidation;
}

export function FilterCriteria(props: IFilterCriteriaProps) {
    return <FilterCriteriaSelector {...props} />;
}

function FilterCriteriaSelector(props: IFilterCriteriaProps) {
    return (
        <div className="row mb-2">
            <div className="col-sm-2">
                <label className="text-gray text-lg">Criteria{props.isRequired ? '*' : ''}</label>
                &nbsp;
                <Help
                    type={ApplicationHelpType.Info}
                    title="Criteria"
                    helpText="Select fields and values to identify the subset of cases that fit that criteria; the criteria tells Checkmate which cases you want to see"
                />
            </div>
            <div className="col-sm-10">
                <CheckmateSelect
                    isMulti
                    options={[...props.queryFilterCriteriaAll]}
                    value={props.queryFilterCriteriaSelected}
                    onChange={props.onQueryCriteriaSelected}
                />
                <span className="text-danger">{props.validation.criteria}</span>
            </div>
        </div>
    );
}
