import * as React from 'react';

import Authorization from '../../stores/Authorization';
import { ICaseTitleModel } from '../../interfaces/ICase';
import { IUserModel } from '../../interfaces/IUser';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { UserRightsEnum } from '../../utilities/Constants';

interface ICaseNameProps {
    user: IUserModel;
    caseTitle: ICaseTitleModel;
    additionalText?: string;
    openLinkInNewTab?: boolean;
}

export class CaseName extends React.Component<ICaseNameProps, any> {
    constructor(props: ICaseNameProps) {
        super(props);
    }

    getSQClassName = () => {
        if (!this.props.caseTitle.SQScore && !this.props.caseTitle.SQProjection) return '';

        if (this.props.caseTitle.SQProjection)
            return 'btn-' + this.props.caseTitle.SQProjection.toLowerCase() + ' ';
        else if (this.props.caseTitle.SQScore) {
            return 'btn-white ';
        }
    };

    render() {
        if (!this.props.caseTitle.caseName) return null;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span
                    className={this.getSQClassName() + 'case-score-display'}
                    style={{ marginRight: '10px', fontSize: '15px' }}
                >
                    {this.props.caseTitle.SQScore ? this.props.caseTitle.SQScore : <span>-</span>}
                </span>
                {this.props.caseTitle.guid ? (
                    <Link
                        target={this.props.openLinkInNewTab ? '_blank' : ''}
                        to={LocalRoutes.CaseFile.replace(':guid', this.props.caseTitle.guid)}
                    >
                        {this.props.caseTitle.caseName}
                    </Link>
                ) : (
                    <span>{this.props.caseTitle.caseName}</span>
                )}
                {this.props.caseTitle.priority ? (
                    <i
                        className="fal fa-exclamation-circle color-yellow"
                        style={{ marginLeft: '10px' }}
                    />
                ) : null}
                {Authorization.userHasRight(UserRightsEnum.ViewThreatScore, this.props.user) ? (
                    <span className="btn-green case-score-display">
                        {this.props.caseTitle.threatScore ? (
                            this.props.caseTitle.threatScore
                        ) : (
                            <span>-</span>
                        )}
                    </span>
                ) : null}
                &nbsp;
                {this.props.additionalText ? <span>{this.props.additionalText}</span> : null}
            </div>
        );
    }
}
