import { Stack } from 'react-bootstrap';

export function NotFoundPage() {
    return (
        <Stack>
            <h1>Not Found</h1>
            <div>The page you are looking for has moved or no longer exists.</div>
        </Stack>
    );
}
