import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';

import { ApplicationHelpType } from '../../utilities/Constants';
import CheckmateApiClient from '../../services/apiClient';
import CheckmateSelect from '../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { Help } from '../shared/Help';
import { IAssociatedOrganizationModel } from '../../interfaces/IOrganization';
import { IDepositionModel } from '../../interfaces/ICase';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Modal } from 'react-bootstrap';
import ValidateUtils from '../../shared/validations';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { useLoading } from '../../contexts/LoadingContext';
import { useState } from 'react';

const _apiClient = new CheckmateApiClient();

export interface IDepositionDetailsModalProps {
    addNew: boolean;
    caseGuid: string;
    currentCaseMeta?: {
        guid: string;
        caseName: string;
    };
    currentDepo?: IDepositionModel;
    onDepositionCreated: (deposition: IDepositionModel) => void;
    onDepositionUpdated: (deposition: IDepositionModel) => void;
    onHide: () => void;
    open: boolean;
    allDefenseCounselsForZone: IAssociatedOrganizationModel[];
    categories: ILookupModel[];
    statusTypes: ILookupModel[];
    zoneUsers: IUserModel[];
}

export function DepositionDetailsModal(props: IDepositionDetailsModalProps) {
    const loading = useLoading();

    const [localDeposition, setLocalDeposition] = useState<IDepositionModel>({
        ...(cloneDeep(props.currentDepo) ?? ({} as IDepositionModel)),
        case: {
            guid: props.currentCaseMeta?.guid || props.currentDepo?.case.guid,
        },
    });

    const [validation, setValidation] = useState<IValidation>({});

    const handleCategoryChange = (category: IMultiSelectOptions) => {
        setLocalDeposition((prev) => ({
            ...prev,
            category: category ? { id: category.id, displayName: category.label } : undefined,
        }));

        if (category) {
            setValidation((prev) => ({ ...prev, category: [''] }));
        }
    };

    const handleStatusChange = (status: IMultiSelectOptions) => {
        setLocalDeposition((prev) => ({
            ...prev,
            status: status ? { id: status.id, displayName: status.label } : undefined,
        }));

        if (status) {
            setValidation((prev) => ({ ...prev, status: [''] }));
        }
    };

    const handleWitnessChange = (witness: string) => {
        setLocalDeposition((prev) => ({ ...prev, witness }));
        setValidation((prev) => ({ ...prev, witness: [''] }));
    };

    const handleTitleChange = (title: string) => {
        setLocalDeposition((prev) => ({ ...prev, title }));
    };

    const handleDateChange = (date: string) => {
        setLocalDeposition((prev) => ({ ...prev, date }));
    };

    const handleDefenseCounselOrgChange = (defenseCounselOrganization: IMultiSelectOptions) => {
        const newDefenseCounselOrganization = props.allDefenseCounselsForZone.find(
            (dc) => dc.guid === defenseCounselOrganization?.value
        );

        setLocalDeposition((prev) => {
            const defenseCounselUser =
                !defenseCounselOrganization ||
                (defenseCounselOrganization &&
                    defenseCounselOrganization.value !== prev.defenseCounselOrganization?.guid)
                    ? undefined
                    : prev.defenseCounselUser;

            return {
                ...prev,
                defenseCounselOrganization: newDefenseCounselOrganization,
                defenseCounselUser,
            };
        });
    };

    const handleDefenseCounselUserChange = (defenseCounselUser: IMultiSelectOptions) => {
        const newUser = props.zoneUsers.find((user) => user?.guid === defenseCounselUser?.value);
        setLocalDeposition((prev) => ({ ...prev, defenseCounselUser: newUser }));
    };

    const handlePlaintiffsFirmAttorneyChange = (plaintiffsFirmAttorney: string) => {
        setLocalDeposition((prev) => ({ ...prev, plaintiffsFirmAttorney }));
    };

    const handleSaveClick = async () => {
        // Validation
        const localValidation: IValidation = {};
        let hasError = false;

        if (!localDeposition.category) {
            localValidation.category = ['Deposition is Required'];
            hasError = true;
        }

        if (!localDeposition.status) {
            localValidation.status = ['Status is Required'];
            hasError = true;
        }

        if (!localDeposition.witness?.trim()) {
            localValidation.witness = ['Witness is Required'];
            hasError = true;
        }

        if (hasError) {
            setValidation(localValidation);
            return;
        }

        loading.showLoading();

        if (props.addNew) {
            const response = await _apiClient.createDeposition(localDeposition);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }

            if (response.errorMessage) {
                setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
                loading.hideLoading();
                return;
            }

            const newDeposition = response.payload!;
            props.onDepositionCreated(newDeposition);
        } else {
            const response = await _apiClient.updateDeposition(localDeposition);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }

            if (response.errorMessage) {
                setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
                loading.hideLoading();
                return;
            }

            const updatedDeposition = response.payload!;
            props.onDepositionUpdated(updatedDeposition);
        }

        loading.hideLoading();
    };

    const handleCancelClick = () => {
        props.onHide();
    };

    return (
        <Modal centered size="lg" show={props.open} onHide={props.onHide} backdrop={false}>
            <Modal.Header>
                <Modal.Title>Deposition</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span className="text-danger">{validation?.model}</span>
                </div>
                <div className="row py-1">
                    <div className="col-sm-4">
                        <label className="control-label">Deposition*</label>
                    </div>
                    <div className="col-sm-8">
                        <CheckmateSelect
                            onChange={handleCategoryChange}
                            value={CheckmateSelectHelper.getSelectedValueById(
                                props.categories,
                                localDeposition?.category?.id ?? 0
                            )}
                            options={CheckmateSelectHelper.getLookupOptions(props.categories)}
                        />
                        <span className="text-danger">{validation?.category}</span>
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-sm-4">
                        <label className="control-label">Status*</label>
                    </div>
                    <div className="col-sm-6">
                        <CheckmateSelect
                            onChange={handleStatusChange}
                            value={CheckmateSelectHelper.getSelectedValueById(
                                props.statusTypes,
                                localDeposition?.status?.id ?? 0
                            )}
                            options={CheckmateSelectHelper.getLookupOptions(props.statusTypes)}
                        />
                        <span className="text-danger">{validation?.status}</span>
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-sm-4">
                        <label className="control-label">Witness*</label>
                    </div>
                    <div className="col-sm-8">
                        <input
                            className="form-control"
                            type="text"
                            maxLength={200}
                            value={localDeposition?.witness ?? ''}
                            onChange={(e) => handleWitnessChange(e.target.value)}
                        />
                        <span className="text-danger">{validation?.witness}</span>
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-sm-4">
                        <label className="control-label">Title/ Relationship</label>
                    </div>
                    <div className="col-sm-8">
                        <input
                            className="form-control"
                            type="text"
                            maxLength={200}
                            value={localDeposition?.title ?? ''}
                            onChange={(e) => handleTitleChange(e.target.value)}
                        />
                        <span className="text-danger">{validation?.title}</span>
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-sm-4">
                        <label className="control-label">Date</label>
                        <Help
                            type={ApplicationHelpType.Info}
                            title="Date"
                            helpText="Input the date (or starting date) the deposition is scheduled.  If the deposition starts and will be ongoing for consecutive days, update the status to In Process/Continued but do not change the date.  If the deposition is continued to a later date, then update the status to In Process/Continued and update the date to reflect the new scheduled date."
                        />
                    </div>
                    <div className="col-sm-8">
                        <input
                            type="date"
                            className={classNames('form-control', {
                                unselectClass: !Common.isValidDate(localDeposition?.date ?? ''),
                            })}
                            value={localDeposition ? Common.dateFormat(localDeposition?.date) : ''}
                            onChange={(e) => handleDateChange(e.target.value)}
                        />
                        <span className="text-danger">{validation?.date}</span>
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-sm-4">
                        <label className="control-label">Defense Firm</label>
                    </div>
                    <div className="col-sm-8">
                        <CheckmateSelect
                            onChange={handleDefenseCounselOrgChange}
                            value={CheckmateSelectHelper.getSelectedOrgValue(
                                props.allDefenseCounselsForZone,
                                localDeposition.defenseCounselOrganization?.guid ?? ''
                            )}
                            options={CheckmateSelectHelper.getOrgOptions(
                                props.allDefenseCounselsForZone
                            )}
                        />
                        <span className="text-danger">{validation?.defenseCounselUser}</span>
                    </div>
                </div>
                {localDeposition?.defenseCounselOrganization?.guid && (
                    <div className="row py-1">
                        <div className="col-sm-4">
                            <label className="control-label">
                                Defense Firm Attorney appearing on behalf of this client
                            </label>
                        </div>
                        <div className="col-sm-8">
                            <CheckmateSelect
                                onChange={handleDefenseCounselUserChange}
                                value={CheckmateSelectHelper.getSelectedUserValue(
                                    props.zoneUsers.filter(
                                        (x) =>
                                            x.homeOrganization &&
                                            x.homeOrganization.guid ===
                                                localDeposition!.defenseCounselOrganization!.guid
                                    ),
                                    localDeposition && localDeposition.defenseCounselUser
                                        ? localDeposition.defenseCounselUser.guid || ''
                                        : ''
                                )}
                                options={CheckmateSelectHelper.getUserOptions(
                                    props.zoneUsers.filter(
                                        (x) =>
                                            x.homeOrganization?.guid ===
                                            localDeposition!.defenseCounselOrganization!.guid
                                    )
                                )}
                            />
                            <span className="text-danger">{validation?.defenseCounselUser}</span>
                        </div>
                    </div>
                )}
                <div className="row py-1">
                    <div className="col-sm-4">
                        <label className="control-label">Plaintiff Firm Attorney taking depo</label>
                    </div>
                    <div className="col-sm-8">
                        <input
                            className="form-control"
                            type="text"
                            maxLength={200}
                            value={localDeposition?.plaintiffsFirmAttorney ?? ''}
                            onChange={(e) => handlePlaintiffsFirmAttorneyChange(e.target.value)}
                        />
                        <span className="text-danger">{validation?.plaintiffsFirmAttorney}</span>
                    </div>
                </div>
                <div className="dialog-btn-div margin-top-sm">
                    <button className="btn btn-default float-end" onClick={handleCancelClick}>
                        Cancel
                    </button>
                    <button className="btn btn-orange float-end" onClick={handleSaveClick}>
                        Save
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
