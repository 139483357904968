import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useCaseManagersInZone = () => {
    return useQuery({
        queryKey: ['get-case-managers-in-zone'],
        queryFn: async () => {
            const response = await _apiClient.getCaseManagersInZone();

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve case managers.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        staleTime: 1 * 60 * 1000,
    });
};
