import { DataScopesEnum, StrategyTypes, UserRightsEnum } from '../utilities/Constants';
import { IOrganizationRoleModel, IUserModel } from '../interfaces/IUser';

import Auth from '../stores/authentication';
import { ILookupModel } from '../interfaces/ILookup';
import { LocalRoutes } from '../utilities/LocalRoutes';

export default class Authorization {
    static getUserActiveZoneRights(user?: IUserModel): ILookupModel[] {
        const currentDefaultZone = Auth.getUserDefaultOrganization();
        let defaultOrgMatch: IOrganizationRoleModel = {};
        let userRights: ILookupModel[] = [];

        if (
            user &&
            currentDefaultZone &&
            user.organizationRoles &&
            user.organizationRoles.filter((x) => x.guid == currentDefaultZone!.guid).length > 0
        ) {
            defaultOrgMatch = user.organizationRoles.filter(
                (x) => x.guid == currentDefaultZone!.guid
            )[0];
            userRights = defaultOrgMatch.rights || [];
        }
        return userRights;
    }

    static isAuthorizedToAtLeastOneRoute = (routes: string[], user?: IUserModel) =>
        routes.some((route) => Authorization.isAuthorizedToRoute(route, user));

    static isAuthorizedToRoute(route: string, user?: IUserModel) {
        const isSystemAdmin = user ? Auth.isSystemAdmin(user) : false;
        const userRights = this.getUserActiveZoneRights(user);
        const isOrganizationAdmin = user ? Auth.isOrganizationAdmin(user) : false;
        const isMasterAdmin = user ? Auth.isMasterAdmin(user) : false;

        switch (route) {
            case LocalRoutes.CreateInvitation:
                return isSystemAdmin || isOrganizationAdmin || isMasterAdmin;
            case LocalRoutes.Zones:
            case LocalRoutes.ZoneDetail:
            case LocalRoutes.ZoneNew:
            case LocalRoutes.OrganizationDetail:
                return isSystemAdmin;
            case LocalRoutes.Users:
                return isSystemAdmin || isOrganizationAdmin || isMasterAdmin;
            case LocalRoutes.UserDetail:
                return isSystemAdmin || isOrganizationAdmin || isMasterAdmin;
            case LocalRoutes.AssertionDescriptors:
                return isSystemAdmin;
            case LocalRoutes.AssertionDescriptorDetail:
                return isSystemAdmin;
            case LocalRoutes.Cases:
                if (userRights.filter((x) => x.id == UserRightsEnum.ViewCaseList).length > 0)
                    return true;
                break;
            case LocalRoutes.CaseFile:
                if (
                    userRights.filter(
                        (x) =>
                            x.id == UserRightsEnum.ViewCaseFileBasic ||
                            x.id == UserRightsEnum.ViewCaseFile
                    ).length > 0
                )
                    return true;
                break;
            case LocalRoutes.CaseFileNew:
                if (userRights.filter((x) => x.id == UserRightsEnum.AddNewCase).length > 0)
                    return true;
                break;
            case LocalRoutes.CaseEasyUpdate:
                // Easy Update Access is based on whether you have access to edit case File at the zone level
                if (userRights.filter((x) => x.id == UserRightsEnum.EasyUpdate).length > 0)
                    return true;
                break;
            case LocalRoutes.ReportTypeList:
                if (userRights.filter((x) => x.id == UserRightsEnum.Reports).length > 0)
                    return true;
                break;
            case LocalRoutes.ThreatReport:
                if (userRights.filter((x) => x.id == UserRightsEnum.Reports).length > 0)
                    return true;
                break;
            case LocalRoutes.ReportDetail:
                if (userRights.filter((x) => x.id == UserRightsEnum.Reports).length > 0)
                    return true;
                break;
            case LocalRoutes.UserReportList:
                return true;
            case LocalRoutes.UserZoneProfile:
                return true;
            case LocalRoutes.CasePlan:
                if (
                    userRights.filter(
                        (x) =>
                            x.id == UserRightsEnum.FullPlanningAndExecution ||
                            x.id == UserRightsEnum.ReadOnlyPlanningAndExecution ||
                            x.id == UserRightsEnum.FirmLevelPlanningAndExecution
                    ).length > 0
                )
                    return true;
                break;
            case LocalRoutes.CasePlanTaskDetail:
                if (
                    userRights.filter(
                        (x) =>
                            x.id == UserRightsEnum.FullPlanningAndExecution ||
                            x.id == UserRightsEnum.ReadOnlyPlanningAndExecution ||
                            x.id == UserRightsEnum.FirmLevelPlanningAndExecution
                    ).length > 0
                )
                    return true;
                break;
            case LocalRoutes.UserTasks:
                return true;
            case LocalRoutes.WorkProductDetail:
                return false;
            case LocalRoutes.TaskBarSetting:
                return true;
            case LocalRoutes.Board:
                if (userRights.filter((x) => x.id == UserRightsEnum.Board).length > 0) return true;
                break;
            case LocalRoutes.BoardCaseDetail:
                if (userRights.filter((x) => x.id == UserRightsEnum.Board).length > 0) return true;
                break;
            case LocalRoutes.BoardViewDetail:
                if (userRights.filter((x) => x.id == UserRightsEnum.Board).length > 0) return true;
                break;
            case LocalRoutes.Query:
                if (
                    userRights.filter(
                        (x) =>
                            x.id == UserRightsEnum.ViewCaseFile ||
                            x.id == UserRightsEnum.ViewCaseFileBasic ||
                            x.id == UserRightsEnum.ViewCaseNotes ||
                            x.id == UserRightsEnum.ViewStrategicPlan ||
                            x.id == UserRightsEnum.ViewExecutionPlan ||
                            x.id == UserRightsEnum.ViewPlaintiffsFirmStrategy ||
                            x.id == UserRightsEnum.ViewJurisdictionStrategy ||
                            x.id == UserRightsEnum.ViewProjects ||
                            x.id == UserRightsEnum.ViewLocalCounselStrategy ||
                            x.id == UserRightsEnum.ViewDeposition ||
                            x.id == UserRightsEnum.ViewZoneNotes
                    ).length > 0
                )
                    return true;
                break;
            case LocalRoutes.ZoneNotes:
                if (userRights.filter((x) => x.id == UserRightsEnum.ViewZoneNotes).length > 0)
                    return true;
                break;
            case LocalRoutes.PackageSettlements:
            case LocalRoutes.PackageSettlementDetails:
            case LocalRoutes.PackageSettlementDetailExport:
            case LocalRoutes.PackageSettlementDetailExportID:
                if (
                    userRights.filter((x) => x.id == UserRightsEnum.ViewPackageSettlement).length >
                    0
                )
                    return true;
                break;
            case LocalRoutes.PackageSettlementNew:
                if (
                    userRights.filter((x) => x.id == UserRightsEnum.AddEditDeletePackageSettlement)
                        .length > 0
                )
                    return true;
                break;
            case LocalRoutes.StrategicPlan:
                return (
                    userRights.filter((x) => x.id == UserRightsEnum.ViewStrategicPlan).length > 0
                );
            case LocalRoutes.StrategyExecutionPlan:
                return (
                    userRights.filter((x) => x.id == UserRightsEnum.ViewExecutionPlan).length > 0
                );
            case LocalRoutes.Experts:
                return userRights.some((x) => x.id === UserRightsEnum.ViewExpert);
            case LocalRoutes.Snapshot:
                return userRights.filter((x) => x.id == UserRightsEnum.Snapshot).length > 0;
            case LocalRoutes.Trends:
                return userRights.filter((x) => x.id == UserRightsEnum.KPIView).length > 0;
            case LocalRoutes.Alerts:
                return userRights.filter((x) => x.id == UserRightsEnum.Alerts).length > 0;
            case LocalRoutes.WeeklyRecap:
                return userRights.some((x) => x.id == UserRightsEnum.Reports);
            case LocalRoutes.Triggers:
                return !isSystemAdmin && userRights.filter((x) => x.id == UserRightsEnum.CaseTriggerRules).length > 0;
            case LocalRoutes.TriggerNew:
            case LocalRoutes.TriggerDetails:
                return !isSystemAdmin;
            default:
                return false;
        }

        return false;
    }

    static userHasRight(userRightValue: number, user: IUserModel): boolean {
        if (!user.organizationRoles) return false;

        const defaultOrg = Auth.getUserDefaultOrganization();
        let userRights: ILookupModel[] = [];

        if (
            defaultOrg &&
            user.organizationRoles &&
            user.organizationRoles.filter((x) => x.guid == defaultOrg!.guid).length > 0
        ) {
            const orgMatch = user.organizationRoles.filter((x) => x.guid == defaultOrg!.guid)[0];
            userRights = orgMatch.rights || [];
        }

        return userRights.filter((x) => x.id == userRightValue).length > 0;
    }

    static getUserDefaultOrganizationDataScope(user: IUserModel): number {
        if (!user.organizationRoles) return 0;
        const defaultOrg = Auth.getUserDefaultOrganization();
        if (
            defaultOrg &&
            user.organizationRoles &&
            user.organizationRoles.filter((x) => x.guid == defaultOrg!.guid).length > 0
        ) {
            const orgMatch = user.organizationRoles.filter((x) => x.guid == defaultOrg!.guid)[0];
            if (orgMatch.dataScope) return orgMatch.dataScope.id;
        }
        return 0;
    }

    static isLocalScope(user: IUserModel): boolean {
        if (this.getUserDefaultOrganizationDataScope(user) === DataScopesEnum.LocalBasic.Value)
            return true;
        return false;
    }

    static showStrategyMenuItem(user?: IUserModel): boolean {
        const userRights = this.getUserActiveZoneRights(user);
        return (
            userRights.filter(
                (x) =>
                    x.id == UserRightsEnum.ViewStrategicPlan ||
                    x.id == UserRightsEnum.ViewExecutionPlan ||
                    x.id == UserRightsEnum.ViewPlaintiffsFirmStrategy ||
                    x.id == UserRightsEnum.ViewJurisdictionStrategy ||
                    x.id == UserRightsEnum.ViewProjects ||
                    x.id == UserRightsEnum.ViewLocalCounselStrategy
            ).length > 0
        );
    }

    static showReviewMenuItem(user?: IUserModel): boolean {
        const userRights = this.getUserActiveZoneRights(user);
        return (
            userRights.filter(
                (x) =>
                    x.id == UserRightsEnum.Board ||
                    x.id == UserRightsEnum.Snapshot ||
                    x.id == UserRightsEnum.Alerts ||
                    x.id == UserRightsEnum.KPIView
            ).length > 0
        );
    }

    static userHasViewStrategyNotesRight(user: IUserModel): boolean {
        const userRights = this.getUserActiveZoneRights(user);
        return (
            userRights.filter(
                (x) =>
                    x.id == UserRightsEnum.ViewStrategicPlan ||
                    x.id == UserRightsEnum.ViewExecutionPlan ||
                    x.id == UserRightsEnum.ViewPlaintiffsFirmStrategy ||
                    x.id == UserRightsEnum.ViewJurisdictionStrategy ||
                    x.id == UserRightsEnum.ViewProjects ||
                    x.id == UserRightsEnum.ViewLocalCounselStrategy
            ).length > 0
        );
    }

    static AllowOrganizationSwitch(user?: IUserModel): boolean {
        const isSystemAdmin = user ? Auth.isSystemAdmin(user) : false;
        const isOrganizationAdmin = user ? Auth.isOrganizationAdmin(user) : false;
        const isMasterAdmin = user ? Auth.isMasterAdmin(user) : false;

        return !isSystemAdmin && !isOrganizationAdmin && !isMasterAdmin;
    }

    static isAuthorizedToStrategyType(strategyType: string, user?: IUserModel) {
        const userRights = this.getUserActiveZoneRights(user);
        switch (strategyType) {
            case StrategyTypes.PlaintiffsFirm.Name:
                return (
                    userRights.filter((x) => x.id == UserRightsEnum.ViewPlaintiffsFirmStrategy)
                        .length > 0
                );
            case StrategyTypes.Jurisdiction.Name:
                return (
                    userRights.filter((x) => x.id == UserRightsEnum.ViewJurisdictionStrategy)
                        .length > 0
                );
            case StrategyTypes.LocalCounsel.Name:
                return (
                    userRights.filter((x) => x.id == UserRightsEnum.ViewLocalCounselStrategy)
                        .length > 0
                );
            case StrategyTypes.Projects.Name:
                return userRights.filter((x) => x.id == UserRightsEnum.ViewProjects).length > 0;
            case StrategyTypes.Experts.Name:
                return userRights.filter((x) => x.id == UserRightsEnum.ViewExpert).length > 0;
            default:
                break;
        }
    }
}
