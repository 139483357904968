import {
    IQueryFilterCriteria,
    IQueryFilterCriteriaSelectionValue,
} from '../../../../interfaces/IQuery';

import { CaseFileStaticItems } from '../../../../utilities/Constants';
import Common from '../../../../stores/Common';
import { IItemSummaryProps } from './TriggerCriteriaCell';
import { hasBlankCondition } from './tiggerCriteriaSummaries';

export function StaticItemSummary(props: IItemSummaryProps) {
    const getSummary = (item: IQueryFilterCriteria) => {
        let fieldSummary = item.label + ': ';

        const findStatus = (value: IQueryFilterCriteriaSelectionValue) => {
            const match = props.caseStatuses?.find((x) => x.id == value.id);
            return match?.label ?? '';
        };

        const getSelectedValuesSummary = () => {
            if (hasBlankCondition(item)) {
                fieldSummary += item.condition ? 'Blank' : 'Not Blank';
            } else {
                const fieldSummaries =
                    item?.selectedValues?.map((selectedValue) => {
                        let { label } = selectedValue;

                        if (!label && item?.staticItemId === CaseFileStaticItems.CaseNumber.Id) {
                            label = findStatus(selectedValue);
                        }

                        return label;
                    }) ?? [];

                if (fieldSummaries.length === 0) {
                    fieldSummary += 'Not Set';
                } else {
                    fieldSummary += fieldSummaries.join(' | ');
                }
            }
        };

        const getDateRangeSummary = () => {
            if (hasBlankCondition(item)) {
                fieldSummary += item.condition ? 'Blank' : 'Not Blank';
            } else {
                if (item?.startDate && !item?.endDate)
                    fieldSummary += 'After ' + Common.dateFormat(item.startDate);
                else if (!item?.startDate && item?.endDate)
                    fieldSummary += 'Before ' + Common.dateFormat(item.endDate);
                else if (item?.startDate && item?.endDate)
                    fieldSummary +=
                        'Between ' +
                        Common.dateFormat(item.startDate) +
                        ' and ' +
                        Common.dateFormat(item.endDate);
            }
        };

        const getTextSummary = () => {
            if (hasBlankCondition(item)) {
                fieldSummary += item.condition == true ? 'Blank' : 'Not Blank';
            } else {
                fieldSummary += item.text ?? 'Not Set';
            }
        };

        const getBooleanSummary = () => {
            if (hasBlankCondition(item)) {
                fieldSummary += item.condition ? 'Blank' : 'Not Blank';
            } else {
                fieldSummary += item.booleanValue ? 'Yes' : 'No';
            }
        };

        switch (item.staticItemId) {
            case CaseFileStaticItems.CaseNumber.Id:
            case CaseFileStaticItems.UniqueCaseID.Id:
                getTextSummary();
                break;
            case CaseFileStaticItems.CasePriority.Id:
                getBooleanSummary();
                break;
            case CaseFileStaticItems.CaseStatusAsOfDate.Id:
            case CaseFileStaticItems.ResolutionDate.Id:
                getDateRangeSummary();
                break;
            case CaseFileStaticItems.State.Id:
            case CaseFileStaticItems.LocalCounsel.Id:
            case CaseFileStaticItems.OtherDefenseCounsels.Id:
            case CaseFileStaticItems.CaseStatus.Id:
            case CaseFileStaticItems.CaseManager.Id:
            case CaseFileStaticItems.AlternateCaseManager.Id:
            default:
                getSelectedValuesSummary();
                break;
        }

        return fieldSummary;
    };

    return (
        <>
            <div>{getSummary(props.trigger)}</div>
            {/* <pre>{JSON.stringify(props.trigger, undefined, 2)}</pre> */}
        </>
    );
}
