import { useLocation, useParams } from 'react-router-dom';

import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IPackageSettlementModel } from '../../interfaces/ICase';
import { IUserModel } from '../../interfaces/IUser';
import { PackageSettlementDetail } from '../case/packagesettlement/PackageSettlementDetail';

function PackageSettlementDetailExportWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    const location: { state: any } = useLocation();
    let redirect = false;
    let newPackageSettlementStateFromRedirectedComponent: IPackageSettlementModel = {
        notes: [],
        locked: false,
        earliestSettledResolutionDate: '',
        valueAddDetails: [],
    };
    let selectedGuidsForFromRedirectedComponent: string[] = [];

    if (location.state) {
        newPackageSettlementStateFromRedirectedComponent =
            location.state.redirectComponentCurrentState;
        selectedGuidsForFromRedirectedComponent = location.state.selectedGuids;
        redirect = location.state.redirect;
    }

    return (
        <>
            <DocumentTitle title="Package Deal" />
            <PackageSettlementDetail
                guid={guid || ''}
                user={props.user}
                selectedGuids={selectedGuidsForFromRedirectedComponent}
                redirect={redirect}
                redirectComponentCurrentState={
                    redirect ? newPackageSettlementStateFromRedirectedComponent : undefined
                }
            />
        </>
    );
}

export default PackageSettlementDetailExportWrapper;
