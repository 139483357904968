import { CSSProperties } from 'react';
import SortData from './SortData';
import classNames from 'classnames';

export function SortableHeader({
    dataObjectIdentifier,
    headerComponent,
    headerText,
    noWrap,
    onSort,
    sortKey,
    subGrandKey,
    subKey,
    thClassName,
    thStyle,
}: {
    dataObjectIdentifier?: number;
    headerComponent?: JSX.Element;
    headerText?: string;
    noWrap?: boolean;
    onSort: (
        key: string,
        order: string,
        subKey?: string | undefined,
        subGrandKey?: string | undefined
    ) => void;
    sortKey: string;
    subGrandKey?: string | undefined;
    subKey?: string | undefined;
    thClassName?: string;
    thStyle?: CSSProperties | undefined;
}) {
    const thClass = classNames('sortable-header', thClassName);

    return (
        <th className={thClass} style={thStyle}>
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 3,
                    paddingRight: '5px',
                }}
            >
                <div style={{ flex: '0 0', whiteSpace: noWrap ? 'nowrap' : 'normal' }}>
                    {headerComponent ? headerComponent : headerText}
                </div>
                <div style={{ flex: '0 0', paddingRight: 5 }}>
                    <SortData
                        fieldName={sortKey}
                        sortData={onSort}
                        subKey={subKey}
                        subGrandKey={subGrandKey}
                        stackControls
                        dataObjectIdentifier={dataObjectIdentifier}
                    />
                </div>
            </div>
        </th>
    );
}
