import { Badge, Stack } from 'react-bootstrap';

import Common from '../../stores/Common';
import { IEntityConversationModel } from '../../interfaces/Report/IReport';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';

export interface IChatMessageProps {
    actionTargets: IMultiSelectOptions[];
    currentUserGuid: string;
    hasError: boolean;
    message: IEntityConversationModel;
    temporary?: boolean;
}

export function ChatMessage(props: IChatMessageProps) {
    const getNameDisplay = (item: IEntityConversationModel) => {
        const {
            author: { profile },
        } = item;

        const fullname = `${profile?.firstName} ${profile?.lastName}`;
        return (
            <Stack direction="horizontal" gap={2}>
                <label className="bold">{fullname}</label>
                <span style={{ fontSize: '0.9em', opacity: 0.6 }}>
                    {props.temporary ? '' : ' ' + Common.dateTimeFormatToLocal(item.dateUTC)}
                </span>
                {props.hasError && (
                    <Stack
                        direction="horizontal"
                        gap={1}
                        className="text-danger"
                        style={{ fontSize: '0.9em' }}
                    >
                        <i className="fal fa-exclamation-triangle" />
                        <span>Message Failed</span>
                    </Stack>
                )}
            </Stack>
        );
    };

    return (
        <div className="pb-3" style={{ opacity: props.temporary ? 0.5 : 1 }}>
            <Stack direction="horizontal">
                {getNameDisplay(props.message)}
                {props.temporary && <i className="fa-spinner fa-solid fa-spin" />}
            </Stack>
            <Stack direction="vertical" gap={2} style={{ flexWrap: 'wrap', marginTop: 3 }}>
                {(props.message.actionTargets?.length ?? 0) > 0 && (
                    <ActionTargets
                        actionTargets={props.message.actionTargets}
                        currentUserGuid={props.currentUserGuid}
                    />
                )}
                <div style={{ paddingLeft: 2, fontSize: '0.9em', whiteSpace: 'pre-wrap' }}>
                    {props.message.text}
                </div>
            </Stack>
        </div>
    );
}

export interface IActionTargetsProps {
    actionTargets: IUserModel[];
    currentUserGuid: string;
}

export function ActionTargets(props: IActionTargetsProps) {
    const sortedActionTagets = props.actionTargets?.sort((a, b) =>
        (a.profile?.firstName ?? '') < (b.profile?.firstName ?? '') ? -1 : 1
    );

    return (
        <Stack gap={1} style={{ paddingRight: 8, flexWrap: 'wrap' }} direction="horizontal">
            {sortedActionTagets.map((user) => {
                const isCurrentUser = props.currentUserGuid === user.guid;

                return (
                    <Badge
                        key={user.guid}
                        bg="light"
                        style={{
                            backgroundColor: isCurrentUser ? 'orange !important' : '',
                            color: isCurrentUser ? 'white !important' : '',
                            border:
                                props.currentUserGuid === user.guid
                                    ? '1px solid orange'
                                    : '1px solid lightgray',
                            borderRadius: 5,
                            fontWeight: 300,
                        }}
                        className="color-orange"
                    >
                        @{user.profile?.firstName} {user.profile?.lastName}
                    </Badge>
                );
            })}
        </Stack>
    );
}
