import { CaseFileStaticItems } from '../../../../utilities/Constants';
import { CaseManagerSelector } from '../../../shared/criteria-value-renderers/CaseManageSelector';
import { CaseStatusSelector } from '../../../shared/criteria-value-renderers/CaseStatusSelector';
import { DateRangePicker } from '../../../../pages/query2/DateRangePicker';
import { IValueControlsProps } from './ValueControls';
import { LocalCounselSelector } from '../../../shared/criteria-value-renderers/LocalCounselSelector';
import { OccupationSelector } from '../../../shared/criteria-value-renderers/OccupationSelector';
import { PlaintiffFirmSelector } from '../../../shared/criteria-value-renderers/PlaintiffFirmSelector';
import { UniqueCaseIdInput } from '../../../shared/criteria-value-renderers/UniqueCaseIdInput';
import { UsStateSelector } from '../../../shared/criteria-value-renderers/UsStateSelector';
import { YesNoSelector } from '../../../shared/criteria-value-renderers/YesNoSelector';

interface IStaticItemValueControlsProps extends IValueControlsProps {
    disabled: boolean;
}

export function StaticItemValueControls(props: IStaticItemValueControlsProps) {
    const { disabled, item, onSelectorChange } = props;

    switch (item.staticItemId) {
        case CaseFileStaticItems.CaseStatus.Id:
            return (
                <CaseStatusSelector
                    disabled={disabled}
                    multi
                    onChange={(selectedOptions) => onSelectorChange(item, selectedOptions)}
                    validationError={item.validationError}
                    value={item.selectedValues}
                />
            );
        case CaseFileStaticItems.State.Id:
            return (
                <UsStateSelector
                    disabled={disabled}
                    multi
                    onChange={(selectedOptions) => onSelectorChange(item, selectedOptions)}
                    validationError={item.validationError}
                    value={item.selectedValues}
                />
            );
        case CaseFileStaticItems.CaseManager.Id:
        case CaseFileStaticItems.AlternateCaseManager.Id:
            return (
                <CaseManagerSelector
                    disabled={disabled}
                    multi
                    onChange={(selectedOptions) => onSelectorChange(item, selectedOptions)}
                    validationError={item.validationError}
                    value={item.selectedValues}
                />
            );
        case CaseFileStaticItems.LocalCounsel.Id:
            return (
                <LocalCounselSelector
                    disabled={disabled}
                    multi
                    onChange={(selectedOptions) => onSelectorChange(item, selectedOptions)}
                    validationError={item.validationError}
                    value={item.selectedValues}
                />
            );
        case CaseFileStaticItems.OtherDefenseCounsels.Id:
            return (
                <LocalCounselSelector
                    disabled={disabled}
                    multi
                    onChange={(selectedOptions) => onSelectorChange(item, selectedOptions)}
                    validationError={item.validationError}
                    value={item.selectedValues}
                    isSecondary
                />
            );
        case CaseFileStaticItems.CaseNumber.Id:
        case CaseFileStaticItems.UniqueCaseID.Id:
            return (
                <UniqueCaseIdInput
                    onChange={(e) => props.onTextChange(item, e.target.value)}
                    value={item.text ?? ''}
                    disabled={disabled}
                    inputName={item.value}
                    validationError={item.validationError}
                />
            );
        case CaseFileStaticItems.CasePriority.Id:
            return (
                <YesNoSelector
                    disabled={disabled}
                    multi={false}
                    onChange={(optionalBool) => {
                        props.onYesNoChange(item, optionalBool);
                    }}
                    value={item.booleanValue}
                    validationError={item.validationError}
                />
            );
        case CaseFileStaticItems.ResolutionDate.Id:
            return (
                <DateRangePicker
                    disableEndDate={item.readOnly || disabled}
                    disableStartDate={item.readOnly || disabled}
                    endDate={item.endDate}
                    onEndDateChange={(e) =>
                        props.onDateRangeChange(item, 'endDate', e.target.value)
                    }
                    onStartDateChange={(e) =>
                        props.onDateRangeChange(item, 'startDate', e.target.value)
                    }
                    startDate={item.startDate}
                    validationError={item.validationError}
                    endInputFieldName="resolutionDateEndDate"
                    startInputFieldName="resolutionDateStartDate"
                />
            );
        case CaseFileStaticItems.ExpertsSection.Id:
        case CaseFileStaticItems.DefenseCounselSection.Id:
        case CaseFileStaticItems.DepositionSection.Id:
        case CaseFileStaticItems.NotesSection.Id:
        case CaseFileStaticItems.PackageSettlement.Id:
        case CaseFileStaticItems.CaseStatusAsOfDate.Id:
            return (
                <div>
                    <i className="fa fa-exclamation-triangle text-danger" aria-hidden="true" />
                    &nbsp; Not Supported
                </div>
            );
        default:
            return (
                <div>
                    <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: 'orange' }}
                    />
                    &nbsp;Not Implemented
                </div>
            );
    }
}
