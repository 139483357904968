import { IErrorMessage, IValidation } from '../interfaces/IError';

export default class ValidateUtils {
    static validateEmail(email: string): string {
        const error = '';
        // let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i; /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i; /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i; /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        // if (!email) {
        //     error = "This field is required.";
        // } else if (!re.test(email)) {
        //     error = "Current entry is not a valid email address.";
        // }
        return error;
    }

    static validatePassword(password: string): string {
        let error = '';
        if (!password) {
            error = 'This field is required.';
        } else if (password.length < 6) {
            error = 'Password must be 6 or more characters.';
        }
        return error;
    }

    static validatePasswordRequirements(password: string): string[] {
        const errors: string[] = [];
        if (!/(?=.{8,})/.test(password)) {
            errors.push('Your password must be at least 8 characters');
        }

        if (!/(?=.*[a-z])/.test(password)) {
            errors.push("Passwords must have at least one lowercase ('a'-'z').");
        }

        if (!/(?=.*[A-Z])/.test(password)) {
            errors.push("Passwords must have at least one uppercase ('A'-'Z').");
        }

        if (!/(?=.*[\d])/.test(password)) {
            errors.push('Your password must contain at least one digit.');
        }
        return errors;
    }

    static matchPassword(password: string, confirmPassword: string): string[] {
        const error: string[] = [];
        if (password !== confirmPassword) {
            error.push('Passwords do not match');
        }
        return error;
    }

    static validatePhoneNumber(phNumber?: string): string {
        const re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        let msg = '';
        msg = phNumber ? (!re.test(phNumber) && 'Invalid phone number.') || '' : '';
        return msg;
    }

    static isValidSSN(text: string): boolean {
        const ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
        return ssnPattern.test(text);
    }

    static parseErrors(errors: (string | IErrorMessage)[], errorMessage?: string) {
        const validation: IValidation = {};
        if (errors) {
            // case when errors are empty but a errorMessage exists
            if (errors.length == 0 && errorMessage) {
                if (validation.model === undefined) {
                    validation.model = [];
                }
                validation.model.push(errorMessage);
                return validation;
            }
            for (let i = 0; i < errors.length; i++) {
                const error = errors[i];
                if (typeof error !== 'string') {
                    if (error.field) {
                        const field: string = error.field.toLowerCase();
                        if (validation[field] === undefined) {
                            validation[field] = [];
                        }
                        validation[field].push(error.message);
                    }
                } else {
                    if (validation.model === undefined) {
                        validation.model = [];
                    }
                    if (errorMessage) {
                        // display generic message rather than actual server side exception
                        validation.model.push(errorMessage);
                    } else {
                        validation.model.push(error);
                    }
                }
            }
        }
        return validation;
    }

    static parseModelErrors = (model: string[]) => {
        const returnValue: string[] = [];
        if (!model) return returnValue;

        model.forEach((item) => {
            const temp = item.split('|');
            temp.forEach((value) => {
                returnValue.push(value);
            });
        });

        return returnValue;
    };
}
