import * as React from 'react';
import { Navigate } from 'react-router-dom';
import ApiClient from '../../services/apiClient';
import moment from 'moment';
import { ILookupModel } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { IThreatReportParametersModel } from '../../interfaces/Report/IThreatReportParameters';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import Auth from '../../stores/authentication';
import ValidateUtils from '../../shared/validations';
import { OrganizationTypesEnum } from '../../utilities/Constants';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Authorization from '../../stores/Authorization';
import { Loader } from '../../components/shared/Loader';
import { IOrganizationModel } from '../../interfaces/IOrganization';
import { CheckmateDialog } from '../../components/shared/dialog';

let _apiClient = new ApiClient();

interface IThreatReportProps {
    user: IUserModel;
}

interface IThreatReportState {
    threatReportParameters: IThreatReportParametersModel;
    validation: IValidation;
    redirect: boolean;
    reportOptions: ILookupModel[];
    pendingResponse: boolean;
    displayDialog: boolean;
    displayIncludeTrialDate: boolean;
    organizationPortfolios: IOrganizationModel[];
}

export class ThreatReport extends React.Component<IThreatReportProps, IThreatReportState> {
    constructor(props: any) {
        super(props);

        this.state = {
            threatReportParameters: { options: [] },
            validation: {},
            redirect: false,
            reportOptions: [],
            pendingResponse: false,
            displayDialog: false,
            displayIncludeTrialDate: false,
            organizationPortfolios: [],
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.ThreatReport, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadPortfolioRiskOptions();
        this.loadOrganizations();
    }

    loadOrganizations = async () => {
        try {
            var res = await _apiClient.getOrganizations(undefined, [
                OrganizationTypesEnum.Portfolio.Value,
            ]);
            if (res.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (res.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                });
                return;
            }
            if (res.payload) {
                this.setState({ organizationPortfolios: res.payload });
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    loadPortfolioRiskOptions = async () => {
        var response = await _apiClient.getReportOptions();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        this.setState({ reportOptions: response.payload! });
    };

    handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        let name = event.target.name;
        let value = event.target.value;
        let threatReportParameters = this.state.threatReportParameters;
        let displayIncludeTrialDate = this.state.displayIncludeTrialDate;
        let validation = this.state.validation;

        switch (name) {
            case 'organizationGuid':
                threatReportParameters.organizationGuid = value;
                break;
            case 'startDate':
                threatReportParameters.startTrialDate = value;
                threatReportParameters.includeNoTrialDate = true;
                validation.starttrialdate = [''];
                if (value || threatReportParameters.endTrialDate) {
                    displayIncludeTrialDate = true;
                } else {
                    displayIncludeTrialDate = false;
                }
                break;
            case 'endDate':
                threatReportParameters.endTrialDate = value;
                threatReportParameters.includeNoTrialDate = true;
                validation.endtrialdate = [''];
                if (value || threatReportParameters.startTrialDate) {
                    displayIncludeTrialDate = true;
                } else {
                    displayIncludeTrialDate = false;
                }
                break;

            default:
                break;
        }
        let item: any = document.getElementById('includeTrialDate');
        if (value) {
            item.checked = true;
        }
        this.setState({
            threatReportParameters: threatReportParameters,
            displayIncludeTrialDate: displayIncludeTrialDate,
            validation: validation,
        });
    };

    handleIncludeTrialDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const Checked = target.checked;
        let threatReportParameters = this.state.threatReportParameters;

        if (Checked) {
            threatReportParameters.includeNoTrialDate = true;
        } else {
            threatReportParameters.includeNoTrialDate = false;
        }

        this.setState({ threatReportParameters: threatReportParameters });
    };

    handleOptionsCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        optionId: number,
        optionType: string
    ) => {
        const target = event.target;
        const Checked = target.checked;
        let threatReportParameters = this.state.threatReportParameters;
        let index = threatReportParameters.options.findIndex((x) => x == optionId);
        let validation = this.state.validation;

        if (Checked && index == -1) {
            threatReportParameters.options.push(optionId);
            validation.caseoptions = [''];
        }

        if (!Checked && index != -1) {
            threatReportParameters.options.splice(index, 1);
            // uncheck select all
            if (optionType == 'portfolioRiskOption') {
                let element: any = document.getElementById('selectAllPortfolioRisk');
                element.checked = false;
            } else {
                let element: any = document.getElementById('selectAllCaseDetail');
                element.checked = false;
            }
        }

        this.setState({ threatReportParameters: threatReportParameters, validation: validation });
    };

    SelectAll = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        let checkboxes: NodeListOf<any> = document.getElementsByName(name);
        let threatReportParameters = this.state.threatReportParameters;
        let select = event.target.checked;
        let validation = this.state.validation;

        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = select;
        }

        switch (name) {
            case 'portfolioRiskOption':
                if (select) {
                    this.state.reportOptions
                        .filter((x) => x.type == 'Portfolio Risk')
                        .map((portfolioRiskOption: ILookupModel) => {
                            if (
                                threatReportParameters.options.findIndex(
                                    (x) => x == portfolioRiskOption.id
                                ) == -1
                            ) {
                                threatReportParameters.options.push(portfolioRiskOption.id);
                            }
                        });
                    validation.caseoptions = [''];
                } else {
                    this.state.reportOptions
                        .filter((x) => x.type == 'Portfolio Risk')
                        .map((portfolioRiskOption: ILookupModel) => {
                            if (
                                threatReportParameters.options.findIndex(
                                    (x) => x == portfolioRiskOption.id
                                ) != -1
                            ) {
                                threatReportParameters.options.splice(
                                    threatReportParameters.options.findIndex(
                                        (x) => x == portfolioRiskOption.id
                                    ),
                                    1
                                );
                            }
                        });
                }
                break;
            case 'caseDetailOption':
                if (select) {
                    this.state.reportOptions
                        .filter((x) => x.type == 'Case Details')
                        .map((caseDetailOption: ILookupModel) => {
                            if (
                                threatReportParameters.options.findIndex(
                                    (x) => x == caseDetailOption.id
                                ) == -1
                            ) {
                                threatReportParameters.options.push(caseDetailOption.id);
                            }
                        });
                    validation.caseoptions = [''];
                } else {
                    this.state.reportOptions
                        .filter((x) => x.type == 'Case Details')
                        .map((caseDetailOption: ILookupModel) => {
                            if (
                                threatReportParameters.options.findIndex(
                                    (x) => x == caseDetailOption.id
                                ) != -1
                            ) {
                                threatReportParameters.options.splice(
                                    threatReportParameters.options.findIndex(
                                        (x) => x == caseDetailOption.id
                                    ),
                                    1
                                );
                            }
                        });
                }
                break;
            default:
                break;
        }

        this.setState({ threatReportParameters: threatReportParameters, validation: validation });
    };

    checkForMinRequirements = (): boolean => {
        let threatReportParameters = this.state.threatReportParameters;
        let validation = this.state.validation;
        let error = false;
        if (threatReportParameters.options.length == 0) {
            validation.caseoptions = [DisplayMessages.CaseOptionError];
            error = true;
        }
        if (
            threatReportParameters.startTrialDate &&
            threatReportParameters.startTrialDate.length > 0 &&
            !moment(threatReportParameters.startTrialDate!, 'YYYY-MM-DD', true).isValid()
        ) {
            validation.starttrialdate = [DisplayMessages.StartTrialDateError];
            error = true;
        }
        if (
            threatReportParameters.endTrialDate &&
            threatReportParameters.endTrialDate.length > 0 &&
            !moment(threatReportParameters.endTrialDate!, 'YYYY-MM-DD', true).isValid()
        ) {
            validation.endtrialdate = [DisplayMessages.EndTrialDateError];
            error = true;
        }

        this.setState({ validation: validation });

        return error;
    };

    generateReport = async () => {
        let validate = await this.checkForMinRequirements();

        if (validate) {
            return;
        }

        let validation = this.state.validation;
        validation.model = [''];
        this.setState({ pendingResponse: true, validation: validation });

        var response = await _apiClient.generateReportRequest(this.state.threatReportParameters);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({
            pendingResponse: false,
            displayDialog: true,
        });

        return;
    };

    cancelDetail = () => {
        this.setState({ redirect: true });
    };

    handleDialogClose = () => {
        this.setState({ redirect: true });
    };

    handleDialogConfirm = () => {
        let elementNames: string[] = ['portfolioRiskOption', 'caseDetailOption'];
        elementNames.map((name: string) => {
            let checkboxes: NodeListOf<any> = document.getElementsByName(name);

            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
        });

        let elementIds: string[] = [
            'selectAllPortfolioRisk',
            'selectAllCaseDetail',
            'includeTrialDate',
        ];

        elementIds.map((id: string) => {
            let scope: any = document.getElementById(id);
            scope.checked = false;
        });

        this.setState({
            displayDialog: false,
            threatReportParameters: {
                organizationGuid: undefined,
                startTrialDate: '',
                endTrialDate: '',
                includeNoTrialDate: undefined,
                options: [],
            },
        });
    };

    render() {
        if (this.state.redirect) {
            return <Navigate to="/" />;
        }
        const isSystemAdmin = this.props.user ? Auth.isSystemAdmin(this.props.user) : false;
        const displayParameters = isSystemAdmin
            ? this.state.threatReportParameters.organizationGuid
                ? true
                : false
            : true;
        return (
            <div id="requestType modal-container">
                {this.state.pendingResponse ? <Loader /> : null}
                <h1>
                    {' '}
                    Generating Threat Reports
                    {isSystemAdmin ? (
                        <div className="col-sm-3 no-padding float-end">
                            {/* AR - TO DO - Report no longer used. Will update to use CheckmateSelect if/when this report is enabled again */}
                            {/*  <select required className="form-select text-gray" name="organizationGuid" onChange={this.handleChange}
                                value={this.state.threatReportParameters ? this.state.threatReportParameters.organizationGuid : ""}>
                                <option value="" >-- Select --</option>
                                {this.state.organizationPortfolios.map((org: IOrganizationModel, i: number) => {
                                    return <option key={i} value={org.guid}>{org.displayName}</option>;
                                })}
                            </select>  */}
                        </div>
                    ) : (
                        <span className="float-end">
                            <input
                                type="button"
                                className="btn btn-orange float-end"
                                onClick={this.generateReport}
                                value="Run"
                            />
                            <input
                                type="button"
                                className="btn btn-default float-end"
                                onClick={this.cancelDetail}
                                value="Cancel"
                            />
                            {/*<span className="btn btn-blue float-end"> <Link to={LocalRoutes.UserReportList} style={{ color: "white" }}>View Existing Reports</Link></span>*/}
                        </span>
                    )}
                    {isSystemAdmin && displayParameters ? (
                        <div className="col-sm-12 no-padding">
                            <input
                                type="button"
                                className="btn btn-orange float-end"
                                onClick={this.generateReport}
                                value="Run"
                            />
                            <input
                                type="button"
                                className="btn btn-default float-end"
                                onClick={this.cancelDetail}
                                value="Cancel"
                            />
                            {/*<span className="btn btn-blue float-end"> <Link to={LocalRoutes.UserReportList} style={{ color: "white" }}>View Existing Reports</Link></span>*/}
                        </div>
                    ) : null}
                </h1>

                <div
                    className="col-sm-12 no-padding"
                    style={{ display: displayParameters ? 'block' : 'none' }}
                >
                    <hr />
                    <div className="col-sm-4 no-padding">
                        <div className="col-sm-12 border-gray">
                            <div>
                                <h4>
                                    Trial date range
                                    <span
                                        className="horizontal-padding-lg"
                                        style={{
                                            display: this.state.displayIncludeTrialDate
                                                ? 'inline-block'
                                                : 'none',
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            defaultChecked={true}
                                            className="form-check-input"
                                            id="includeTrialDate"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleIncludeTrialDate(e);
                                            }}
                                        />
                                        <label
                                            className="form-check-label text-gray font-italic horizontal-margin "
                                            htmlFor="includeTrialDate"
                                        >
                                            Include No Trial Date
                                        </label>
                                    </span>
                                </h4>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label className="control-label" htmlFor="startDate">
                                            Start date
                                        </label>
                                        <input
                                            className={
                                                this.state.threatReportParameters.startTrialDate ===
                                                ''
                                                    ? 'unselectClass form-control'
                                                    : 'form-control'
                                            }
                                            type="date"
                                            name="startDate"
                                            value={this.state.threatReportParameters.startTrialDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <label className="control-label" htmlFor="endDate">
                                            End date
                                        </label>
                                        <input
                                            className={
                                                this.state.threatReportParameters.endTrialDate ===
                                                ''
                                                    ? 'unselectClass form-control'
                                                    : 'form-control'
                                            }
                                            type="date"
                                            name="endDate"
                                            value={this.state.threatReportParameters.endTrialDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row"
                                    style={{ margin: '1px', marginBottom: '10px' }}
                                >
                                    <span className="no-margin font-italic">
                                        When no dates are selected, cases with all trial dates
                                        including cases with no trial dates will be included in the
                                        report.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 vertical-padding">
                            <div className="text-danger">{this.state.validation.model}</div>
                            <div className="text-danger">{this.state.validation.casescope}</div>
                            <div className="text-danger">{this.state.validation.caseoptions}</div>
                            <div className="text-danger">
                                {this.state.validation.starttrialdate}
                            </div>
                            <div className="text-danger">{this.state.validation.endtrialdate}</div>
                        </div>
                    </div>
                    <div className="col-sm-4" style={{ paddingLeft: '60px' }}>
                        <h4 style={{ marginTop: '0px' }}>
                            Porfolio Risk
                            <span className="horizontal-padding-lg">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="selectAllPortfolioRisk"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.SelectAll(e, 'portfolioRiskOption');
                                    }}
                                />
                                <label
                                    className="form-check-label text-gray font-italic horizontal-margin "
                                    htmlFor="selectAllPortfolioRisk"
                                >
                                    Select all
                                </label>
                            </span>
                        </h4>
                        {this.state.reportOptions
                            .filter((x) => x.type == 'Portfolio Risk')
                            .map((portfolioRiskOption: ILookupModel, index: number) => {
                                return (
                                    <div
                                        key={`reportOption${index}`}
                                        className="horizontal-padding-lg vertical-margin"
                                    >
                                        <input
                                            type="checkbox"
                                            className="input-checkbox"
                                            name="portfolioRiskOption"
                                            id={portfolioRiskOption.displayName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleOptionsCheckedChange(
                                                    e,
                                                    portfolioRiskOption.id,
                                                    'portfolioRiskOption'
                                                );
                                            }}
                                        />
                                        <label
                                            className="form-check-label horizontal-margin-lg display-initial"
                                            htmlFor={portfolioRiskOption.displayName}
                                        >
                                            {portfolioRiskOption.displayName}
                                        </label>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="col-sm-4">
                        <h4 style={{ marginTop: '0px' }}>
                            Case Details
                            <span className="horizontal-padding-lg">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="selectAllCaseDetail"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.SelectAll(e, 'caseDetailOption');
                                    }}
                                />
                                <label
                                    className="form-check-label text-gray font-italic horizontal-margin "
                                    htmlFor="selectAllCaseDetails"
                                >
                                    Select all
                                </label>
                            </span>
                        </h4>
                        {this.state.reportOptions
                            .filter((x) => x.type == 'Case Details')
                            .map((caseDetailOption: ILookupModel, index: number) => {
                                return (
                                    <div
                                        key={`caseDetailOption${index}`}
                                        className="horizontal-padding-lg vertical-margin"
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="caseDetailOption"
                                            id={caseDetailOption.displayName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleOptionsCheckedChange(
                                                    e,
                                                    caseDetailOption.id,
                                                    'caseDetailOption'
                                                );
                                            }}
                                        />
                                        <label
                                            className="form-check-label horizontal-margin-lg display-initial"
                                            htmlFor={caseDetailOption.displayName}
                                        >
                                            {caseDetailOption.displayName}
                                        </label>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <CheckmateDialog
                    isShowingModal={this.state.displayDialog}
                    body={DisplayMessages.ReportCreated}
                    handleClose={this.handleDialogClose}
                    handleConfirm={this.handleDialogConfirm}
                    dialogClassName="create-report-dialog"
                    cancelText="No"
                    confirmText="Yes"
                    confirmButtonClassName="btn btn-black float-end"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
