import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetReportHistory = (reportGuid: string) => {
    return useQuery({
        queryKey: ['get-collab-report-history', { reportGuid }],
        queryFn: async () => {
            const response = await _apiClient.getCollaborationReportHistory(reportGuid);

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve Collab Report history.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
    });
};
