import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { Help } from '../../components/shared/Help';
import { ApplicationHelpType } from '../../utilities/Constants';
import { IQueryFilterCriteria } from '../../interfaces/IQuery';
import { IValidation } from '../../interfaces/IError';

export interface IDisplayColumnSelectorProps {
    availableColumns: IQueryFilterCriteria[];
    onQueryColumnsSelected: (optionsList: any) => void;
    selectedColumns: IQueryFilterCriteria[];
    validation: IValidation;
}

export function DisplayColumnSelector(props: IDisplayColumnSelectorProps) {
    return (
        <div className="row mb-2">
            <div className="col-sm-2">
                <label className="text-gray text-lg">Display</label>
                &nbsp;
                <Help
                    type={ApplicationHelpType.Info}
                    title="Display"
                    helpText="Select fields that you want to see displayed for your subset of cases; the display fields tell Checkmate what you want to see about the cases"
                />
            </div>
            <div className="col-sm-10">
                <CheckmateSelect
                    isMulti
                    options={props.availableColumns}
                    value={props.selectedColumns}
                    onChange={props.onQueryColumnsSelected}
                />
            </div>
            <span className="text-danger">{props.validation.columns}</span>
        </div>
    );
}
